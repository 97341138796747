/* istanbul ignore file */
import { withValidations, checkTextInput } from 'pages/profile/utils';
const userDetails = [
  {
    id: 1,
    name: 'Address',
    type: 'text',
    placeholder: 'Enter Address',
    customClass: 'w-[100%]',
    isRequired: true,
    propertyName: 'address',
    disabled: (formData) => {
      return !formData.editProfileFlag;
    },
  },
  {
    id: 2,
    name: 'City',
    type: 'text',
    placeholder: 'Enter City',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'cityName',
    disabled: (formData) => {
      return !formData.editProfileFlag;
    },
    validator: checkTextInput,
  },
  {
    id: 3,
    name: 'State',
    type: 'select',
    placeholder: 'Select State',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'stateName',
    disabled: (formData) => {
      return !formData.editProfileFlag;
    },
    options: [],
  },
  {
    id: 4,
    name: 'Zip Code',
    type: 'number',
    placeholder: 'Enter Zip Code',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'zipCode',
    disabled: (formData) => {
      return !formData.editProfileFlag;
    },
  },
];
const userDetailsFields = withValidations(userDetails);
export default userDetailsFields;
