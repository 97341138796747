import React, { useState } from 'react';

// icons
import lockIcon from 'assets/icons/lockIcon.svg';
import eyeIcon from 'assets/icons/eyeIcon.svg';
import eyeOff from 'assets/icons/eyeOff.svg';

// RTK query
import { useUpdatePasswordMutation } from 'redux_store/services/update-password';

//libs
import { customToast } from 'components/toast';

// icons
import fundmoreai from 'assets/fundmoreai2.png'

const VerifyPassword = () => {
  // states
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  // query
  const [updatePassword] = useUpdatePasswordMutation();

  const searchParams = new URLSearchParams(window.location?.search);
  const userId = searchParams.get('user_id');
  const code = searchParams.get('code');
  const email = searchParams.get('email');

  // password validator
  const validatePassword = (password) => {
    const lengthCriteria = password.length >= 8;
    const lowerCaseCriteria = /[a-z]/.test(password);
    const upperCaseCriteria = /[A-Z]/.test(password);
    const numberCriteria = /[0-9]/.test(password);
    const specialCharCriteria = /[!@#$%^&*]/.test(password);

    return {
      lengthCriteria,
      lowerCaseCriteria,
      upperCaseCriteria,
      numberCriteria,
      specialCharCriteria,
    };
  };

  const validation = validatePassword(password);

  // update password submission
  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    } else if (
      validation?.lengthCriteria &&
      validation?.lowerCaseCriteria &&
      validation?.numberCriteria &&
      validation?.specialCharCriteria &&
      validation?.upperCaseCriteria
    ) {
      setPasswordsMatch(true);
    }

    const userData = {
      user_id: userId,
      code: code,
      password: password,
    };
    const response = await updatePassword({ data: userData });
    if (
      response?.data?.status === 200 &&
      response?.data?.message === 'Password changed successfully'
    ) {
      window.history.replaceState(null, null, window.location.origin);
      window.location.href = window.location.origin;
      customToast.success(
        'Your account is verified, Please login with your email and updated password'
      );
    }
    if (
      response?.data?.status === 200 &&
      response?.data?.message === 'User already verified'
    ) {
      window.history.replaceState(null, null, window.location.origin);
      window.location.href = window.location.origin;
      customToast.error(
        'Your account is already verified, Please login with existing password'
      );
    }
  };

  return (
    <div
      className="bg-cover bg-center h-screen flex flex-col justify-center items-center w-[100vw] h-[100vh]"
      style={{ backgroundImage: 'url("/mainbg.png")' }}
    >
      <div className="w-[500px] h-[134px] bg-secondary100 flex flex-col justify-center items-center rounded-t-xl">
        <img
          src={fundmoreai}
          alt=""
          className="h-28"
          style={{ width: '250px', height: '65px' }}
        />
        <h3 className="text-neutral900 h4-m mt-2">Setup your Password</h3>
      </div>
      <div className="w-[500px] h-[230px] bg-white flex flex-col justify-between py-6 items-center relative">
        <div className="text-center mb-4">
          <div className="p-sm-regular leading-none ">
            Please setup password for
          </div>
          <span className="p-sm-bold leading-none ">{email}</span>
        </div>

        <div className="w-[400px] relative">
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            className={`pl-16 px-2 font-medium py-[13px] rounded-[6px] border ${!passwordsMatch ? 'border-error' : 'border-neutral100'} placeholder:font-medium w-[400px] relative outline-none`}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder="Please enter your new password"
          />
          <div className="absolute top-1/2 transform -translate-y-1/2 left-[1px]">
            <img src={lockIcon} alt="" />
          </div>
          <div
            className="absolute top-1/2 transform -translate-y-1/2 right-[1rem] cursor-pointer"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            <img src={isPasswordVisible ? eyeIcon : eyeOff} alt="" />
          </div>
          {isFocused && (
            <div className="absolute top-[-10rem] mt-2 p-4 bg-neutralBg_1 rounded shadow-lg w-full">
              <p
                className={`${validation.lengthCriteria ? 'text-green_success' : 'text-error-red'} p-xs-med p-0.5`}
              >
                At least 8 characters in length
              </p>
              <p
                className={`${validation.lowerCaseCriteria ? 'text-green_success' : 'text-error-red'} p-xs-med p-0.5`}
              >
                Lower case letters (a-z)
              </p>
              <p
                className={`${validation.upperCaseCriteria ? 'text-green_success' : 'text-error-red'} p-xs-med p-0.5`}
              >
                Upper case letters (A-Z)
              </p>
              <p
                className={`${validation.numberCriteria ? 'text-green_success' : 'text-error-red'} p-xs-med p-0.5`}
              >
                Numbers (i.e. 0-9)
              </p>
              <p
                className={`${validation.specialCharCriteria ? 'text-green_success' : 'text-error-red'} p-xs-med p-0.5`}
              >
                Special characters (e.g. !@#$%^&*)
              </p>
            </div>
          )}
        </div>
        <div className="w-[400px] relative">
          <input
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            placeholder="Confirm your password"
            className={`pl-16 px-2 font-medium py-[13px] rounded-[6px] border ${!passwordsMatch ? 'border-error' : 'border-neutral100'} placeholder:font-medium w-[400px] relative outline-none`}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="absolute top-1/2 transform -translate-y-1/2 left-[1px]">
            <img src={lockIcon} alt="" />
          </div>
          <div
            className="absolute top-1/2 transform -translate-y-1/2 right-[1rem] cursor-pointer"
            onClick={() =>
              setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
            }
          >
            <img src={isConfirmPasswordVisible ? eyeIcon : eyeOff} alt="" />
          </div>
        </div>
      </div>
      <div
        onClick={handleSubmit}
        className="w-[500px] h-[80px] bg-primary500 hover:bg-primary600 flex flex-col justify-center items-center rounded-b-xl cursor-pointer"
      >
        <h3 className="p-md-bold mt-2 text-white">Setup password &gt;</h3>
      </div>
    </div>
  );
};

export default VerifyPassword;
