export const domain = import.meta.env.VITE_APP_DOMAIN;
export const consumer_app_client_id = import.meta.env
  .VITE_APP_CONSUMER_CLIENT_ID;
export const loan_officer_app_client_id = import.meta.env
  .VITE_APP_LOAN_OFFICER_CLIENT_ID;
export const chat_end_point = import.meta.env.VITE_APP_CHAT_END_POINT;
// export const loan_service_api = 'http://127.0.0.1:5001/';
export const loan_service_api = import.meta.env.VITE_APP_BASE_URI + 'loan';

export const api = import.meta.env.VITE_APP_BASE_URI;
export const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
export const sentry_dsn = import.meta.env.VITE_SENTRY_DSN;

export const roles = {
  loanOfficer: 'loanOfficer',
  consumer: 'consumer',
  organization: 'organization',
};
