import { useRef, useEffect } from 'react'

function usePageTitle(title, prevailOnUnmount = true) {
  const defaultTitle = useRef(document.title);
  const formattedTitle = title.split(" ").map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(" ");

  useEffect(() => {
    document.title = title ? `${formattedTitle} | FundMore.ai` : "FundMore.ai";
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default usePageTitle