/* istanbul ignore file */
import {
  withValidations,
  checkPhoneNumberValidation,
  checkEmailValidation,
  checkTextInput,
} from 'pages/profile/utils';

const userInfo = [
  {
    id: 1,
    name: 'Email',
    type: 'text',
    placeholder: 'Enter Email',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'email',
    checkValidation: [checkEmailValidation],
    disabled: (formData) => {
      return !formData.editProfileFlag;
    },
  },
  {
    id: 2,
    name: 'Mobile Number',
    type: 'phone',
    placeholder: 'Enter Phone Number',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'phoneNumber',
    disabled: (formData) => {
      return !formData.editProfileFlag;
    },
    checkValidation: [checkPhoneNumberValidation],
    pattern: '(###) ###-####',
  },
  {
    id: 3,
    name: 'Mortgage Broker Name',
    type: 'text',
    placeholder: 'Enter Mortgage Broker Name',
    customClass: 'w-1/2',
    isRequired: true,
    disabled: (formData) => {
      return !formData.editProfileFlag;
    },
    propertyName: 'mortgageBrokerName',
    validator: checkTextInput,
  },
  {
    id: 4,
    name: 'Loan Officer',
    type: 'select',
    placeholder: 'Select Mortgage Broker',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'loanOfficer',
    disabled: (formData) => {
      return !formData.editProfileFlag;
    },
    value: 'dkfdkk',
  },
];
const userInfoFields = withValidations(userInfo);
export default userInfoFields;
