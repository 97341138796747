import React from 'react';
// Components
import Radio from 'components/radio';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import H1 from 'components/h1-typography';

// Slices
import {
  useCreateDeclarationDetailsMutation,
  useGetApplicationQuery,
  useUpdateDeclarationDetailsMutation,
} from 'redux_store/services/client/profile';
// Sentry
import * as Sentry from '@sentry/browser';
import { useSelector } from 'react-redux';
import { declarationDataSelector } from 'redux_store/slices/application';

const DeclarationForm = ({ setPage, setPreviousPage, token }) => {
  // States and Refs
  const [formData, setFormData] = React.useState({
    occupyProperty: '',
    ownershipInterest: '',
    propertyType: '',
    propertyTitle: '',
    purchaseTransaction: '',
    borrowingAnyMoney: '',
    amountOfMoney: '',
    applicationId: 0,
    applyingOtherMortgageLoan: '',
    applyingNewCredit: '',
    subjectToLien: '',
    coSigner: '',
    outstandingJudgements: '',
    defaultOnFederalDebt: '',
    partyToLawsuit: '',
    conveyedTitle: '',
    completedShortSale: '',
    propertyForeclosed: '',
    declaredBankruptcy: '',
    bankruptcyType: [],
  });
  const [btnTxt, setBtnTxt] = React.useState('Next: Demographics');
  const isEdit = React.useRef(false);

  //Selectors
  const declarationDataAI = useSelector(declarationDataSelector);

  // Slices
  const [createDeclarationForm] = useCreateDeclarationDetailsMutation();
  const [updateDelcarationForm] = useUpdateDeclarationDetailsMutation();

  const convertZeroOneToBoolean = (formData) =>
    Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [
        key,
        value === '1' || value === true
          ? true
          : value === '0' || value === false
            ? false
            : value
              ? value
              : null,
      ])
    );

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setBtnTxt('Please Wait...');
    try {
      const data = {
        ...convertZeroOneToBoolean(formData),
        propertyType: formData?.propertyType,
        propertyTitle: formData?.propertyTitle,
        amountOfMoney: formData?.amountOfMoney,
        bankruptcyType: formData?.bankruptcyType,
        userProfileId: applicationData?.data?.personal_details?.userProfileId,
        applicationId: token,
      };
      if (isEdit?.current) {
        const resp = await updateDelcarationForm({
          applicationId: token,
          data,
        });
        await refetch();
        if (resp?.data?.status === 200) {
          setPage();
        }
      } else {
        const resp = await createDeclarationForm(data);
        await refetch();
        if (resp?.data?.status === 200) {
          setPage();
        }
      }
    } catch (error) {
      Sentry.captureException(error);

      console.log(error);
    } finally {
      setBtnTxt('Next: Demographics');
    }
  };

  const { data: applicationData, refetch } = useGetApplicationQuery(
    {
      application_id: token,
    },
    {
      skip: !token,
    }
  );

  React.useEffect(() => {
    if (applicationData?.data?.user_declarations_details) {
      isEdit.current = true;
      const formData = applicationData?.data?.user_declarations_details;
      setFormData({
        ...formData,
        bankruptcyType: formData?.bankruptcyType
          ? formData?.bankruptcyType
          : [],
      });
    } else {
      setFormData({
        ...formData,
        ...declarationDataAI,
        bankruptcyType: declarationDataAI?.bankruptcyType
          ? declarationDataAI?.bankruptcyType
          : formData?.bankruptcyType ?? [],
      });
    }
  }, [applicationData, declarationDataAI]);

  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  return (
    <div>
      <form onSubmit={onSubmitHandler}>
        <H1>Declaration</H1>
        <p className="antialiased">
          Provide declaration about the property, loan monies and your finances.
        </p>

        <div className=" max-h-custom-declaration overflow-auto">
          <div className="bg-black text-tertiary w-full px-2 py-1 rounded-t-lg">
            <p className="mx-auto">
              5a. About this Property and Your Money for this Loan
            </p>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              Will you occupy the property as your primary residence?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'occupyProperty-yes'}
                    name={`occupyProperty`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        occupyProperty: true,
                      });
                    }}
                    checked={
                      formData?.occupyProperty === '1' ||
                      formData?.occupyProperty === true
                    }
                  />
                  <label
                    htmlFor="occupyProperty-yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'occupyProperty-no'}
                    name={`occupyProperty`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        occupyProperty: false,
                      });
                    }}
                    checked={
                      formData?.occupyProperty === '0' ||
                      formData?.occupyProperty === false
                    }
                  />
                  <label
                    htmlFor="occupyProperty-no"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4   py-2">
              If YES, have you had an ownership interest in another property in
              the last three years? If YES, complete (1) and (2) below:
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'ownershipInterest-yes'}
                    name={`ownershipInterest`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        ownershipInterest: true,
                      });
                    }}
                    checked={
                      formData?.ownershipInterest === '1' ||
                      formData?.ownershipInterest === true
                    }
                  />
                  <label
                    htmlFor="ownershipInterest-yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'ownershipInterest-no'}
                    name={`ownershipInterest`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        ownershipInterest: false,
                      });
                    }}
                    checked={
                      formData?.ownershipInterest === '0' ||
                      formData?.ownershipInterest === false
                    }
                  />
                  <label
                    htmlFor="ownershipInterest-no"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4   py-2 ">
              (1) What type of property did you own: primary residence (PR), FHA
              secondary residence (SR), second home (SH),or investment property
              (IP)?
            </div>
            <div className="w-1/4 py-2 pl-4">
              {' '}
              <input
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    propertyType: e.target.value,
                  });
                }}
                value={formData?.propertyType}
                className="outline outline-none w-1/2  border-dotted border-b-2"
              />
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              (2) How did you hold title to the property: by yourself (S),
              jointly with your spouse (SP), or jointly with another person (O)?
            </div>
            <div className="w-1/4 py-2 pl-4 h-full">
              <input
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    propertyTitle: e.target.value,
                  });
                }}
                value={formData?.propertyTitle}
                className="outline outline-none w-1/2  border-dotted border-b-2"
              />
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4   py-4">
              B. If this is a Purchase Transaction: Do you have a family
              relationship or business aff iliation with the seller of the
              property?
            </div>
            <div className="w-1/4 py-4">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'purchaseTransaction-Yes'}
                    name={`purchaseTransaction`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        purchaseTransaction: true,
                      });
                    }}
                    checked={
                      formData?.purchaseTransaction === '1' ||
                      formData?.purchaseTransaction === true
                    }
                  />
                  <label
                    htmlFor="purchaseTransaction-Yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'purchaseTransaction-No'}
                    name={`purchaseTransaction`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        purchaseTransaction: false,
                      });
                    }}
                    checked={
                      formData?.purchaseTransaction === '0' ||
                      formData?.purchaseTransaction === false
                    }
                  />
                  <label
                    htmlFor="purchaseTransaction-No"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4   py-2">
              C. Are you borrowing any money for this real estate transaction
              (e.g., money for your closing costs or down payment) or obtaining
              any money from another party, such as the seller or realtor, that
              you have not disclosed on this loan application?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'borrowingAnyMoney-yes'}
                    name={`borrowingAnyMoney`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        borrowingAnyMoney: true,
                      });
                    }}
                    checked={
                      formData?.borrowingAnyMoney === '1' ||
                      formData?.borrowingAnyMoney === true
                    }
                  />
                  <label
                    htmlFor="borrowingAnyMoney-yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'borrowingAnyMoney-no'}
                    name={`borrowingAnyMoney`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        borrowingAnyMoney: false,
                      });
                    }}
                    checked={
                      formData?.borrowingAnyMoney === '0' ||
                      formData?.borrowingAnyMoney === false
                    }
                  />
                  <label
                    htmlFor="borrowingAnyMoney-no"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4   py-2 ">
              If YES, what is the amount of this money?
            </div>
            <div className="w-1/4 py-2 pl-4">
              {' '}
              <input
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    amountOfMoney: e.target.value,
                  });
                }}
                value={formData?.amountOfMoney}
                className="outline outline-none w-1/2  border-dotted border-b-2"
              />
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              D. 1. Have you or will you be applying for a mortgage loan on
              another property (not the property securing this loan) on or
              before closing this transaction that is not disclosed on this loan
              application? 
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'applyingOtherMortgageLoan-yes'}
                    name={`applyingOtherMortgageLoan`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        applyingOtherMortgageLoan: true,
                      });
                    }}
                    checked={
                      formData?.applyingOtherMortgageLoan === '1' ||
                      formData?.applyingOtherMortgageLoan === true
                    }
                  />
                  <label
                    htmlFor="applyingOtherMortgageLoan-yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'applyingOtherMortgageLoan-no'}
                    name={`applyingOtherMortgageLoan`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        applyingOtherMortgageLoan: false,
                      });
                    }}
                    checked={
                      formData?.applyingOtherMortgageLoan === '0' ||
                      formData?.applyingOtherMortgageLoan === false
                    }
                  />
                  <label
                    htmlFor="applyingOtherMortgageLoan-no"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              2. Have you or will you be applying for any new credit (e.g.,
              installment loan, credit card, etc.) on or before closing this
              loan that is not disclosed on this application? 
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'applyingNewCredit-Yes'}
                    name={`applyingNewCredit`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        applyingNewCredit: true,
                      });
                    }}
                    checked={
                      formData?.applyingNewCredit === '1' ||
                      formData?.applyingNewCredit === true
                    }
                  />
                  <label
                    htmlFor="applyingNewCredit-Yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'applyingNewCredit-No'}
                    name={`applyingNewCredit`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        applyingNewCredit: false,
                      });
                    }}
                    checked={
                      formData?.applyingNewCredit === '0' ||
                      formData?.applyingNewCredit === false
                    }
                  />
                  <label
                    htmlFor="applyingNewCredit-No"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              E. Will this property be subject to a lien that could take
              priority over the first mortgage lien, such as a clean energy lien
              paid through your property taxes (e.g., the Property Assessed
              Clean Energy Program)?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'subjectToLien-Yes'}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        subjectToLien: true,
                      });
                    }}
                    name={`subjectToLien`}
                    checked={
                      formData?.subjectToLien === '1' ||
                      formData?.subjectToLien === true
                    }
                  />
                  <label
                    htmlFor="subjectToLien-Yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'subjectToLien-No'}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        subjectToLien: false,
                      });
                    }}
                    checked={
                      formData?.subjectToLien === '0' ||
                      formData?.subjectToLien === false
                    }
                    name={`subjectToLien`}
                  />
                  <label
                    htmlFor="subjectToLien-No"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black text-tertiary w-full px-2 py-1">
            <p className="mx-auto">5b. About Your Finances </p>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              F. Are you a co-signer or guarantor on any debt or loan that is
              not disclosed on this application?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'coSigner-yes'}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        coSigner: true,
                      });
                    }}
                    checked={
                      formData?.coSigner === '1' || formData?.coSigner === true
                    }
                    name={`coSigner`}
                  />
                  <label
                    htmlFor="coSigner-yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        coSigner: false,
                      });
                    }}
                    checked={
                      formData?.coSigner === '0' || formData?.coSigner === false
                    }
                    id={'coSigner-no'}
                    name={`coSigner`}
                  />
                  <label
                    htmlFor="coSigner-no"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              G. Are there any outstanding judgments against you?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'outstandingJudgements-yes'}
                    name={`outstandingJudgements`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        outstandingJudgements: true,
                      });
                    }}
                    checked={
                      formData?.outstandingJudgements === '1' ||
                      formData?.outstandingJudgements === true
                    }
                  />
                  <label
                    htmlFor="outstandingJudgements-yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'outstandingJudgements-no'}
                    name={`outstandingJudgements`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        outstandingJudgements: false,
                      });
                    }}
                    checked={
                      formData?.outstandingJudgements === '0' ||
                      formData?.outstandingJudgements === false
                    }
                  />
                  <label
                    htmlFor="outstandingJudgements-no"
                    className="pl-1 font-medium text-neutral700"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              H. Are you currently delinquent or in default on a Federal debt?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'defaultOnFederalDebt-Yes'}
                    name={`defaultOnFederalDebt`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        defaultOnFederalDebt: true,
                      });
                    }}
                    checked={
                      formData?.defaultOnFederalDebt === '1' ||
                      formData?.defaultOnFederalDebt === true
                    }
                  />
                  <label
                    htmlFor="defaultOnFederalDebt-Yes"
                    className="pl-1 font-medium text-neutral700"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'defaultOnFederalDebt-No'}
                    name={`defaultOnFederalDebt`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        defaultOnFederalDebt: false,
                      });
                    }}
                    checked={
                      formData?.defaultOnFederalDebt === '0' ||
                      formData?.defaultOnFederalDebt === false
                    }
                  />
                  <label
                    htmlFor="defaultOnFederalDebt-No"
                    className="font-medium text-neutral700 pl-1"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              I. Are you a party to a lawsuit in which you potentially have any
              personal financial liability?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'partyToLawsuit-YES'}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        partyToLawsuit: true,
                      });
                    }}
                    checked={
                      formData?.partyToLawsuit === '1' ||
                      formData?.partyToLawsuit === true
                    }
                    name={`partyToLawsuit`}
                  />
                  <label
                    htmlFor="partyToLawsuit-YES"
                    className="font-medium text-neutral700 pl-1"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        partyToLawsuit: false,
                      });
                    }}
                    id={'partyToLawsuit-NO'}
                    name={`partyToLawsuit`}
                    checked={
                      formData?.partyToLawsuit === '0' ||
                      formData?.partyToLawsuit === false
                    }
                  />
                  <label
                    htmlFor="partyToLawsuit-NO"
                    className="font-medium text-neutral700 pl-1"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              J. Have you conveyed title to any property in lieu of foreclosure
              in the past 7 years?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        conveyedTitle: true,
                      });
                    }}
                    id={'conveyedTitle--Yes'}
                    name={`conveyedTitle`}
                    checked={
                      formData?.conveyedTitle === '1' ||
                      formData?.conveyedTitle === true
                    }
                  />
                  <label
                    htmlFor="conveyedTitle--Yes"
                    className="font-medium text-neutral700 pl-1"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        conveyedTitle: false,
                      });
                    }}
                    id={'conveyedTitle--No'}
                    name={`conveyedTitle`}
                    checked={
                      formData?.conveyedTitle === '0' ||
                      formData?.conveyedTitle === false
                    }
                  />
                  <label
                    htmlFor="conveyedTitle--No"
                    className="font-medium text-neutral700 pl-1"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              K. Within the past 7 years, have you completed a pre-foreclosure
              sale or short sale, whereby the property was sold to a third party
              and the Lender agreed to accept less than the outstanding mortgage
              balance due?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'completedShortSale--Yes'}
                    name={`completedShortSale`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        completedShortSale: true,
                      });
                    }}
                    checked={
                      formData?.completedShortSale === '1' ||
                      formData?.completedShortSale === true
                    }
                  />
                  <label
                    htmlFor="completedShortSale--Yes"
                    className="font-medium text-neutral700 pl-1"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'completedShortSale--NO'}
                    name={`completedShortSale`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        completedShortSale: false,
                      });
                    }}
                    checked={
                      formData?.completedShortSale === '0' ||
                      formData?.completedShortSale === false
                    }
                  />
                  <label
                    htmlFor="completedShortSale--NO"
                    className="font-medium text-neutral700 pl-1"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              L. Have you had property foreclosed upon in the last 7 years?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'propertyForeclosed--yes'}
                    name={`propertyForeclosed`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        propertyForeclosed: true,
                      });
                    }}
                    checked={
                      formData?.propertyForeclosed === '1' ||
                      formData?.propertyForeclosed === true
                    }
                  />
                  <label
                    htmlFor="propertyForeclosed--yes"
                    className="font-medium text-neutral700 pl-1"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'propertyForeclosed--no'}
                    name={`propertyForeclosed`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        propertyForeclosed: false,
                      });
                    }}
                    checked={
                      formData?.propertyForeclosed === '0' ||
                      formData?.propertyForeclosed === false
                    }
                  />
                  <label
                    htmlFor="propertyForeclosed--no"
                    className="font-medium text-neutral700 pl-1"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              M. Have you declared bankruptcy within the past 7 years?
            </div>
            <div className="w-1/4 py-2">
              <div className="flex items-center gap-4 pl-4">
                <div className="flex items-center">
                  <Radio
                    id={'declaration-property-YES'}
                    name={`declaration-property`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        declaredBankruptcy: true,
                      });
                    }}
                    checked={
                      formData?.declaredBankruptcy === '1' ||
                      formData?.declaredBankruptcy === true
                    }
                  />
                  <label
                    htmlFor="declaration-property-YES"
                    className="font-medium text-neutral700 pl-1"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'declaration-property-NO'}
                    name={`declaration-property`}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        declaredBankruptcy: false,
                      });
                    }}
                    checked={
                      formData?.declaredBankruptcy === '0' ||
                      formData?.declaredBankruptcy === false
                    }
                  />
                  <label
                    htmlFor="declaration-property-NO"
                    className="font-medium text-neutral700 pl-1"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-wrap ">
            <div className="w-3/4  py-2 p-xs-medium">
              If yes, Identify the type(s) of bankruptcy:
            </div>
            <div className="w-1/4 py-2">
              <div className="flex flex-col gap-4 pl-4">
                <div className={`px-2 flex items-center`}>
                  <Checkbox
                    id={`form-field-chapter-7`}
                    value={'chapter-7'}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        bankruptcyType: e.target.checked
                          ? [...formData.bankruptcyType, e.target.value]
                          : formData?.bankruptcyType?.filter(
                              (x) => x !== e.target.value
                            ),
                      });
                    }}
                    checked={formData?.bankruptcyType?.includes('chapter-7')}
                  />

                  <label
                    className="pl-2 font-medium text-neutral700 "
                    htmlFor={`form-field-chapter-7`}
                  >
                    Chapter 07
                  </label>
                </div>
                <div className={`px-2 flex items-center`}>
                  <Checkbox
                    id={`form-field=chapter-11`}
                    value={'chapter-11'}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        bankruptcyType: e.target.checked
                          ? [...formData.bankruptcyType, e.target.value]
                          : formData?.bankruptcyType?.filter(
                              (x) => x !== e.target.value
                            ),
                      });
                    }}
                    checked={formData?.bankruptcyType?.includes('chapter-11')}
                  />

                  <label
                    className="pl-2 font-medium text-neutral700 "
                    htmlFor={`form-field=chapter-11`}
                  >
                    Chapter 11
                  </label>
                </div>
                <div className={`px-2 flex items-center`}>
                  <Checkbox
                    id={`form-field=chapter-12`}
                    checked={formData?.bankruptcyType?.includes('chapter-12')}
                    value={'chapter-12'}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        bankruptcyType: e.target.checked
                          ? [...formData.bankruptcyType, e.target.value]
                          : formData?.bankruptcyType?.filter(
                              (x) => x !== e.target.value
                            ),
                      });
                    }}
                  />

                  <label
                    className="pl-2 font-medium text-neutral700 "
                    htmlFor={`form-field=chapter-12`}
                  >
                    Chapter 12
                  </label>
                </div>
                <div className={`px-2  flex items-center`}>
                  <Checkbox
                    id={`form-field=chapter-13`}
                    checked={formData?.bankruptcyType?.includes('chapter-13')}
                    value={'chapter-13'}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        bankruptcyType: e.target.checked
                          ? [...formData.bankruptcyType, e.target.value]
                          : formData?.bankruptcyType?.filter(
                              (x) => x !== e.target.value
                            ),
                      });
                    }}
                  />

                  <label
                    className="pl-2 font-medium text-neutral700 "
                    htmlFor={`form-field=chapter-13`}
                  >
                    Chapter 13
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-right mt-2">
          <div className="text-right mt-2">
            <Button
              variant="tertiary"
              onClick={() => {
                setPreviousPage();
              }}
              style={{ marginRight: '10px' }}
              type="button"
            >
              {' '}
              Back: Real Estate Owned
            </Button>
            <Button type="submit" variant={'primary'}>
              {btnTxt}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DeclarationForm;
