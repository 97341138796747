import react, { useState } from 'react';
//components
import Tabs from 'components/tabs';
import H1 from 'components/h1-typography';
//children components or tabs
import OneClickAus from './oneClickAus';
import CreditHistory from './creditHistory';
const Aus = ({setPage}) => {
  const [tab, setTab] = react.useState(1);
  return (
    <>
      <div className="pt-5 h-[65vh]">
        <Tabs
          onClickTab={(tab) => {
            setTab(tab?.id);
          }}
          tabs={[
            {
              id: 1,
              name: 'One-Click AUS',
            },
            {
              id: 2,
              name: 'Credit History',
            },
          ]}
          selectedTabId={tab}
          variant="primary_sm"
        />
        {tab === 1 && <OneClickAus setPage={setPage} />}
        {tab === 2 && <CreditHistory />}
      </div>
    </>
  );
};

export default Aus;
