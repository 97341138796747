export function FormatTimeDifference(updatedOn) {
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  const dateObject = new Date(updatedOn);
  const unixTimestamp = Math.floor(dateObject.getTime() / 1000);
  const timeDifferenceInSeconds = currentTimestamp - unixTimestamp;

  if (timeDifferenceInSeconds < 60 && timeDifferenceInSeconds >= 0) {
    return `${timeDifferenceInSeconds} ${timeDifferenceInSeconds !== 1 ? 'seconds' : 'second'} ${'ago'}`;
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutes} ${minutes !== 1 ? 'minutes' : 'minute'} ${'ago'}`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hours} ${hours !== 1 ? 'hours' : 'hour'} ${'ago'}`;
  } else if (timeDifferenceInSeconds < 604800) {
    const days = Math.floor(timeDifferenceInSeconds / 86400);
    return `${days} ${days !== 1 ? 'days' : 'day'} ${'ago'}`;
  } else if (timeDifferenceInSeconds < 2628000) {
    const weeks = Math.floor(timeDifferenceInSeconds / 604800);
    return `${weeks} ${weeks !== 1 ? 'weeks' : 'week'} ${'ago'}`;
  } else if (timeDifferenceInSeconds < 31536000) {
    const months = Math.floor(timeDifferenceInSeconds / 2628000);
    return `${months} ${months !== 1 ? 'months' : 'month'} ${'ago'}`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / 31536000);
    return `${years} ${years !== 1 ? 'years' : 'year'} ${'ago'}`;
  }
}

export function formatTimeDifferenceUTC(utcTimeString) {
  // Parse the UTC time string
  const updatedOn = new Date(utcTimeString + 'Z');
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const unixTimestamp = Math.floor(updatedOn.getTime() / 1000);
  const timeDifferenceInSeconds = currentTimestamp - unixTimestamp;

  if (timeDifferenceInSeconds < 60 && timeDifferenceInSeconds >= 0) {
    return `${timeDifferenceInSeconds} ${timeDifferenceInSeconds !== 1 ? 'seconds' : 'second'} ago`;
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutes} ${minutes !== 1 ? 'minutes' : 'minute'} ago`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hours} ${hours !== 1 ? 'hours' : 'hour'} ago`;
  } else if (timeDifferenceInSeconds < 604800) {
    const days = Math.floor(timeDifferenceInSeconds / 86400);
    return `${days} ${days !== 1 ? 'days' : 'day'} ago`;
  } else if (timeDifferenceInSeconds < 2628000) {
    const weeks = Math.floor(timeDifferenceInSeconds / 604800);
    return `${weeks} ${weeks !== 1 ? 'weeks' : 'week'} ago`;
  } else if (timeDifferenceInSeconds < 31536000) {
    const months = Math.floor(timeDifferenceInSeconds / 2628000);
    return `${months} ${months !== 1 ? 'months' : 'month'} ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / 31536000);
    return `${years} ${years !== 1 ? 'years' : 'year'} ago`;
  }
}
