import React, { useEffect, useState } from 'react';
//components
import H1 from 'components/h1-typography';
import Tabs from 'components/tabs';
import { bgColors, borderColors, textColors } from '../../themes/colors';
import Select from 'react-select';
import Loader from 'wrapper/loader';
import Breadcrumb from 'components/breadcrumb';
//utils
import { getInitials } from 'constants/getFirstChar';
//RTK query
import { useGetUsersQuery } from 'redux_store/services/admin/adminTools';
//child components
import LoanComparison from './loanComparison';
import Amortization from './amortizationSchedule';
import { roles } from 'constants/constant';

const Tools = () => {
  const [selectedTabId, setSelectedTabId] = useState(1);
  const [applicationId, setApplicationId] = useState(0);
  const breadCrumbs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Tools',
      path: '/tools',
    },
  ];
  const {
    data: userProperty,
    isLoading: isUsersLoading,
    isFetching,
  } = useGetUsersQuery({}, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (Array.isArray(userProperty?.data)) {
      setApplicationId(userProperty?.data[0]?.applicationId);
    }
  }, [userProperty?.data]);

  return applicationId && !isFetching ? (
    <div>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <Breadcrumb breadcrumbs={breadCrumbs} />
          <H1>Tools</H1>
        </div>
        <div className="flex gap-2">
          <Select
            menuShouldScrollIntoView={true}
            menuShouldBlockScroll={true}
            menuPlacement="auto"
            menuPosition="fixed"
            style={{
              cursor: 'pointer',
            }}
            value={
              Array.isArray(userProperty?.data)
                ? userProperty?.data?.find(
                    (x) => x?.applicationId === applicationId
                  )
                : {}
            }
            isLoading={isUsersLoading}
            loadingMessage={'Loading Users'}
            getOptionValue={(option) => option?.applicationId}
            onChange={(option) => {
              setApplicationId(option?.applicationId);
            }}
            isSearchable
            options={userProperty?.data ? userProperty?.data : []}
            getOptionLabel={(e) => (
              <div className="flex items-center">
                <div
                  className={`w-6 h-6 bg-profile rounded-full flex justify-center items-center mr-2`}
                >
                  {getInitials(e.firstName)}
                </div>
                <span className="mt-0.5 whitespace-nowrap truncate w-[14rem]">
                  {e.firstName} {e.lastName}{' '}
                </span>
              </div>
            )}
            styles={{
              control: (provided) => ({
                ...provided,
                fontSize: '14px',
                position: 'relative',
                fontWeight: '500',
                borderRadius: '5px',
                boxShadow: 'none',
                border: `1px solid ${borderColors.primary}`,
                '&:hover': {
                  cursor: 'pointer',
                  border: `1px solid ${borderColors.primary500}`,
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? bgColors.primary100
                  : bgColors.tertiary_bg,
                borderBottom: `1px solid ${borderColors.primary}`,
                color: textColors.black,
                fontSize: '14px',
                fontWeight: '500',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: bgColors.primary50,
                },
              }),
              menu: (provided, state) => ({
                ...provided,
                borderRadius: '5px',
                color: textColors.black,
                fontSize: '12px',
                fontWeight: '400',
              }),
              indicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral80: textColors.black,
              },
            })}
            placeholder="Select User"
            className="w-[20rem]"
          />
        </div>
      </div>
      <Tabs
        tabs={[
          {
            id: 1,
            name: 'Loan Comparison',
          },
          {
            id: 2,
            name: 'Amortization Schedule',
          },
        ]}
        selectedTabId={selectedTabId}
        onClickTab={(tab) => {
          setSelectedTabId(tab?.id);
        }}
      />
      {selectedTabId === 1 && (
        <LoanComparison
          applicationId={applicationId}
          role={roles?.loanOfficer}
        />
      )}
      {selectedTabId === 2 && (
        <Amortization applicationId={applicationId} role={roles?.loanOfficer} />
      )}
    </div>
  ) : userProperty?.data?.length === 0 ? (
    <div
      className="w-full bg-contain rounded mt-6 bg-center bg-no-repeat flex items-center justify-center text-black"
      style={{
        backgroundImage: 'url("/tools.png")',
        minHeight: 'calc(100vh - 20vh)',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      }}
    >
      <div className="bg-white max-w-[450px] p-4 font-bold">
        Please be advised that access to various tools will be granted only
        after a lead has been successfully assigned or added to the system.
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Tools;
