import React, { useMemo, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import loader from 'assets/icons/loader.svg';
import { useNavigate } from 'react-router-dom';

const Table = ({
  isLoading = false,
  columns,
  data,
  errorMessage,
  height,
  globalFilter,
  setGlobalFilter,
  showPagination = false,
  pagination = {},
  onPageChange = () => {},
  onNavigate = () => {},
}) => {
  const navigate = useNavigate();
  const tableInstance = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: pagination.totalPages,
  });

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      onPageChange(pagination.currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      onPageChange(pagination.currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handleLastPage = () => {
    onPageChange(pagination.totalPages);
  };

  return (
    <div>
      <div
        className={`overflow-auto ${height} ${isLoading ? 'hiddenScroll' : ''}`}
      >
        <table className="min-w-full relative">
          <thead className="bg-custom-primary400">
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    colSpan={header.colSpan}
                    key={header.id}
                    className={`bg-custom-primary400 p-3 text-secondary800 max-w-auto p-xs-bold whitespace-nowrap ${
                      header.column.columnDef.meta?.className ?? ''
                    } ${header.column.columnDef.meta?.width ?? ''} `}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {tableInstance.getRowModel().rows.length > 0 && !isLoading ? (
            <tbody>
              {tableInstance.getRowModel().rows.map((row) => {
                return (
                  <motion.tr
                    initial={{
                      y: -20,
                    }}
                    animate={{
                      x: 0,
                      y: 0,
                      scale: 1,
                      rotate: 0,
                    }}
                    key={row.id}
                    className="hover:bg-primary50 cursor-pointer group"
                    onClick={() => {
                      onNavigate(row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={`${
                          cell.column.columnDef.meta?.className ?? ''
                        } ${cell.column.columnDef.meta?.style ?? ''}`}
                      >
                        <div
                          className={`text-center p-xs-regular m-3   
                      ${cell.column.columnDef.meta?.width ?? ''} `}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </div>
                      </td>
                    ))}
                  </motion.tr>
                );
              })}
            </tbody>
          ) : isLoading ? (
            <div className="flex justify-center mt-52 w-[67vw]">
              <img src={loader} alt="" className="animate-spin" />
            </div>
          ) : (
            <div className="flex justify-center absolute left-[40%] top-[50%] mt-10 font-medium">
              {errorMessage}
            </div>
          )}
        </table>
        {showPagination && !isLoading && (
          <div className="border-t border-grey my-4"></div>
        )}
      </div>
      {showPagination && !isLoading && (
        <div className="flex justify-center relative py-4">
          <span className="flex items-center gap-1 absolute left-[2rem] top-[40%]">
            <div className="p-xs-regular">
              Showing{' '}
              {pagination.currentPage * pagination.pageSize -
                pagination.pageSize +
                1}{' '}
              -{' '}
              {Math.min(
                pagination.currentPage * pagination.pageSize,
                pagination.totalItemsCount
              )}
            </div>

            <span className="p-xs-regular">
              of {pagination.totalItemsCount}
            </span>
          </span>

          <div className="flex items-center gap-6">
            <button
              onClick={handleFirstPage}
              disabled={pagination.currentPage === 1}
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.293701 5.29365C-0.0969238 5.68428 -0.0969238 6.31865 0.293701 6.70928L5.2937 11.7093C5.68433 12.0999 6.3187 12.0999 6.70933 11.7093C7.09995 11.3187 7.09995 10.6843 6.70933 10.2937L2.41558 5.9999L6.7062 1.70615C7.09683 1.31553 7.09683 0.681152 6.7062 0.290527C6.31558 -0.100098 5.6812 -0.100098 5.29058 0.290527L0.290576 5.29053L0.293701 5.29365ZM11.2937 0.293652L6.2937 5.29365C5.90308 5.68428 5.90308 6.31865 6.2937 6.70928L11.2937 11.7093C11.6843 12.0999 12.3187 12.0999 12.7093 11.7093C13.1 11.3187 13.1 10.6843 12.7093 10.2937L8.41558 5.9999L12.7062 1.70615C13.0968 1.31553 13.0968 0.681152 12.7062 0.290527C12.3156 -0.100098 11.6812 -0.100098 11.2906 0.290527L11.2937 0.293652Z"
                  fill={pagination.currentPage === 1 ? '#9C9C9C' : '#151314'}
                />
              </svg>
            </button>
            <button
              onClick={handlePreviousPage}
              disabled={pagination.currentPage === 1}
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.293701 5.29365C-0.0969238 5.68428 -0.0969238 6.31865 0.293701 6.70928L5.2937 11.7093C5.68433 12.0999 6.3187 12.0999 6.70933 11.7093C7.09995 11.3187 7.09995 10.6843 6.70933 10.2937L2.41558 5.9999L6.7062 1.70615C7.09683 1.31553 7.09683 0.681152 6.7062 0.290527C6.31558 -0.100098 5.6812 -0.100098 5.29058 0.290527L0.290576 5.29053L0.293701 5.29365Z"
                  fill={pagination.currentPage === 1 ? '#9C9C9C' : '#151314'}
                />
              </svg>
            </button>
            <button
              onClick={handleNextPage}
              disabled={pagination.currentPage === pagination.totalPages}
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.70627 5.29365C7.0969 5.68428 7.0969 6.31865 6.70627 6.70928L1.70627 11.7093C1.31565 12.0999 0.681274 12.0999 0.290649 11.7093C-0.0999756 11.3187 -0.0999756 10.6843 0.290649 10.2937L4.5844 5.9999L0.293774 1.70615C-0.0968506 1.31553 -0.0968506 0.681152 0.293774 0.290527C0.684399 -0.100098 1.31877 -0.100098 1.7094 0.290527L6.7094 5.29053L6.70627 5.29365Z"
                  fill={
                    pagination.currentPage === pagination.totalPages
                      ? '#9C9C9C'
                      : '#151314'
                  }
                />
              </svg>
            </button>
            <button
              onClick={handleLastPage}
              disabled={pagination.currentPage === pagination.totalPages}
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7063 6.70615C13.0969 6.31553 13.0969 5.68115 12.7063 5.29053L7.70627 0.290527C7.31565 -0.100098 6.68127 -0.100098 6.29065 0.290527C5.90002 0.681152 5.90002 1.31553 6.29065 1.70615L10.5844 5.9999L6.29377 10.2937C5.90315 10.6843 5.90315 11.3187 6.29377 11.7093C6.6844 12.0999 7.31877 12.0999 7.7094 11.7093L12.7094 6.70928L12.7063 6.70615ZM1.70627 11.7062L6.70627 6.70615C7.0969 6.31553 7.0969 5.68115 6.70627 5.29053L1.70627 0.290527C1.31565 -0.100098 0.681274 -0.100098 0.290649 0.290527C-0.0999756 0.681152 -0.0999756 1.31553 0.290649 1.70615L4.5844 5.9999L0.293774 10.2937C-0.0968506 10.6843 -0.0968506 11.3187 0.293774 11.7093C0.684399 12.0999 1.31877 12.0999 1.7094 11.7093L1.70627 11.7062Z"
                  fill={
                    pagination.currentPage === pagination.totalPages
                      ? '#9C9C9C'
                      : '#151314'
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
