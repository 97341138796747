import Cookies from 'js-cookie';
export let token;

//decodes jwt token
export function parseJwt(token) {
  if (!token) return null;
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

//get stored token data from cookies
export const CheckAuth = () => {
  let userData = Cookies.get('idToken');
  userData = userData && JSON.parse(userData);
  token = userData && userData?.token;
  return userData;
};

//get headers
export const getHeaders = () => {
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  };
  return headers;
};

// userDetails function
export const getUserDetails = () => {
  const {
    user_id: userId,
    user_email: userEmail,
    first_name: firstName,
    last_name: lastName,
    pending_application_id: pendingApplicationId,
    token,
    permissions: permissions,
  } = CheckAuth() || {};

  return {
    userId,
    userEmail,
    firstName,
    lastName,
    pendingApplicationId,
    token,
    permissions,
  };
};
