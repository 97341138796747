import React, { useEffect, useState } from 'react';

//components
import Progressbar from 'components/progressbar';
import Loader from 'wrapper/loader';

//libs
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';

//animations
import { scaleMotionProps } from 'constants/animationProps';

//constants
import { getUserDetails } from 'auth/auth';

//pages
import Page0 from './sub-pages/Page-0';
import Page1 from './sub-pages/Page-1';
import Page2 from './sub-pages/Page-2';
import Page3 from './sub-pages/Page-3';
import Page4 from './sub-pages/Page-4';
import Page5 from './sub-pages/Page-5';

//API queries
import {
  useGetOnboardingDataQuery,
  useCreateOnboardingMutation,
  useUpdateOnboardingMutation,
} from 'redux_store/services/client/onboarding';

// icons
import fundmoreai from 'assets/fundmoreai2.png'

const Onboarding = ({ setIsOnboarding }) => {
  //sates of all pages
  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState(null);
  const [pageState, setPageState] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOfficer, setSelectedOfficer] = useState('');
  const [selectedOfficerObj, setSelectedOfficerObj] = useState(null);
  const [address, setAddress] = useState('');
  const [loanPurpose, setLoanPurpose] = useState('');
  const [employment, setEmployment] = useState('');

  //get user details from token
  const { firstName } = getUserDetails();
  const user = firstName;

  //API queries
  const { data, error, isFetching } = useGetOnboardingDataQuery();
  const [createOnboarding, { isLoading: createLoading }] =
    useCreateOnboardingMutation();
  const [updateOnboarding, { isLoading: updateLoading }] =
    useUpdateOnboardingMutation();

  //update progress bar for each page
  useEffect(() => {
    switch (pageState) {
      case 1:
        setProgress(0);
        break;
      case 2:
        setProgress(20);
        break;
      case 3:
        setProgress(40);
        break;
      case 4:
        setProgress(60);
        break;
      case 5:
        setProgress(80);
        break;
      default:
        break;
    }
  }, [pageState]);
  
  const saveOnboardingData = async () => {
    // Initialize an empty object
    let onboarding = {};

    // Conditionally add fields to the object
    if (selectedOption !== undefined && selectedOption !== null) {
      onboarding.isWorkingWithAnyLoanOfficer = selectedOption;
    }

    if (address && address.trim() !== '') {
      onboarding.address = address;
    }
    if (!address && page === 3) {
      onboarding.address = null;
      onboarding.lenderId = null;
    }

    if (selectedOfficerObj?.userId) {
      onboarding.lenderId = selectedOfficerObj.userId;
    }
    if (!selectedOfficerObj && page === 2) {
      onboarding.lenderId = null;
    }

    if (loanPurpose) {
      onboarding.loanPurposeId =
        loanPurpose === 'refinance' ? 2 : loanPurpose === 'purchase' ? 1 : 0;
    }

    if (!selectedOfficerObj && (page === 2 || page === 3)) {
      onboarding.loanPurposeId = null;
    }
    if (employment) {
      onboarding.employmentTypeId =
        employment === 'employed'
          ? 2
          : employment === 'self'
            ? 3
            : employment === 'retired'
              ? 1
              : 0;
    }

    // Always include these fields
    // onboarding.statusId = 1;
    onboarding.pageNumber = page > 0 ? page : 1;

    if (error?.status === 404) {
      if (page === 1) {
        setPageState(page);
      }
      if (
        onboarding?.isWorkingWithAnyLoanOfficer === true ||
        onboarding?.isWorkingWithAnyLoanOfficer === false
      ) {
        await createOnboarding({ data: onboarding });
        setPageState(page);
      }
    } else if (data?.status === 200) {
      await updateOnboarding({ data: onboarding });
      setPageState(page);
    }
  };

  //populate data from localStorage if it exists
  useEffect(() => {
    if (data?.data) {
      setPage(data?.data?.pageNumber);
      setSelectedOption(
        data?.data?.isWorkingWithAnyLoanOfficer === '0' ? false : true
      );
      setSelectedOfficerObj(
        data?.data?.lenderId ? { userId: data?.data?.lenderId } : null
      );
      setAddress(data?.data?.address ? data?.data?.address : '');
      setLoanPurpose(
        data?.data?.loanPurposeId === 2
          ? 'refinance'
          : data?.data?.loanPurposeId === 1
            ? 'purchase'
            : ''
      );
      setEmployment(
        data?.data.employmentTypeId === 2
          ? 'employed'
          : data?.data.employmentTypeId === 3
            ? 'self'
            : data?.data.employmentTypeId === 1
              ? 'retired'
              : ''
      );
    }
  }, [data]);

  // save onboarding data on page change
  useEffect(() => {
    saveOnboardingData();
  }, [page]);

  // if onboarding data is false show page 0 for 2 seconds and start onboarding
  useEffect(() => {
    if (error?.status === 404) {
      const timer = setTimeout(() => {
        setPage(1);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div
      className="bg-cover bg-center min-h-screen max-h-full flex justify-center items-center p-6 w-[100vw]"
      style={{ backgroundImage: 'url("/mainbg.png")' }}
    >
      <AnimatePresence>
        <motion.div
          {...scaleMotionProps}
          className="flex flex-col items-center"
        >
          <div className="w-[85vw] h-full min-h-[90vh] pb-6 flex flex-col tertiary-bg rounded-lg justify-center">
            <div className="flex  items-center gap-4 p-4">
            <img
              src={fundmoreai}
              alt=""
              className="h-28"
              style={{width: '250px', height: '65px'}}
            />
              <AnimatePresence>
                <motion.div {...scaleMotionProps}>
                  {page !== 0 && (
                    <div className="w-[25vw] absolute left-[39%]">
                      <Progressbar
                        value={progress}
                        valueString={`${progress}%`}
                      />
                    </div>
                  )}
                </motion.div>
              </AnimatePresence>
            </div>
            {isFetching && <Loader />}
            <div className=" flex flex-grow justify-center items-center ">
              {' '}
              {pageState === 0 && error?.status === 404 && (
                <Page0 user={user} />
              )}
              {pageState === 1 && !isFetching && (
                <Page1
                  user={user}
                  isLoading={createLoading || updateLoading}
                  selectedOption={selectedOption}
                  setPage={setPage}
                  setSelectedOption={setSelectedOption}
                  setAddress={setAddress}
                  setSelectedOfficer={setSelectedOfficer}
                  setSelectedOfficerObj={setSelectedOfficerObj}
                  setLoanPurpose={setLoanPurpose}
                  setEmployment={setEmployment}
                  data={data?.data}
                  setDirection={setDirection}
                  direction={direction}
                />
              )}
              {pageState === 2 && !isFetching && (
                <Page2
                  isLoading={updateLoading}
                  user={user}
                  setAddress={setAddress}
                  address={address}
                  setPage={setPage}
                  setDirection={setDirection}
                  direction={direction}
                />
              )}
              {pageState === 3 && !isFetching && (
                <Page3
                  isLoading={createLoading || updateLoading}
                  user={user}
                  address={address}
                  selectedOfficer={selectedOfficer}
                  setSelectedOfficer={setSelectedOfficer}
                  setSelectedOfficerObj={setSelectedOfficerObj}
                  selectedOfficerObj={selectedOfficerObj}
                  selectedOption={selectedOption}
                  setPage={setPage}
                  setDirection={setDirection}
                  direction={direction}
                />
              )}
              {pageState === 4 && !isFetching && (
                <Page4
                  isLoading={createLoading || updateLoading}
                  user={user}
                  setPage={setPage}
                  loanPurpose={loanPurpose}
                  setLoanPurpose={setLoanPurpose}
                  setDirection={setDirection}
                  direction={direction}
                />
              )}
              {pageState === 5 && !isFetching && (
                <Page5
                  isLoading={createLoading || updateLoading}
                  user={user}
                  setPage={setPage}
                  employment={employment}
                  setEmployment={setEmployment}
                  setIsOnboarding={setIsOnboarding}
                  dataForSubmission={{
                    loanPurpose: loanPurpose,
                    lenderId: selectedOfficerObj?.userId,
                    address: address ? address : 'N/A',
                  }}
                  saveOnboardingData={saveOnboardingData}
                  setDirection={setDirection}
                  direction={direction}
                />
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Onboarding;
