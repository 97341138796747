import React, { useState } from 'react';

//icons
import closeIcon from 'assets/icons/closeIcon.svg';
import searchIcon from 'assets/icons/search.svg';

//component
import InputGroup from 'components/input-group';
import SvgIcon from 'assets/icons/svg';
import Button from 'components/button';
import { useGetLoanOfficersQuery } from 'redux_store/services/client/onboarding';
import Loader from 'wrapper/loader';

const FilterModule = ({
  setFilterPopup,
  onApplyCheckedItems,
  checkedLoanOfficerIds,
}) => {
  const [loanOfficer, setLoanOfficer] = useState('');
  const [checkedItems, setCheckedItems] = useState([...checkedLoanOfficerIds]);

  const { data: loanOfficers, isLoading } = useGetLoanOfficersQuery();

  const handleItemClick = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  const handleSelectAll = () => {
    if (checkedItems.length === loanOfficers?.data?.users?.length) {
      setCheckedItems([]);
    } else {
      const allIds = loanOfficers?.data?.users?.map((item) => item.userId);
      setCheckedItems(allIds);
    }
  };

  const onApplyButton = () => {
    onApplyCheckedItems(checkedItems);
  };

  const filteredLoanOfficers = loanOfficers?.data?.users?.filter((officer) =>
    `${officer.firstName} ${officer.lastName}`
      .toLowerCase()
      .includes(loanOfficer.toLowerCase())
  );

  return (
    <div className="max-w-[325px] bg-white shadow-lg rounded-xl border border-neutral200">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div className="h6-m mb-4 w-[15rem]">Loan Officer</div>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-4"
            onClick={() => {
              setFilterPopup(false);
            }}
          />
        </div>
        {isLoading ? (
          <div className="h-[10rem]">
            <Loader />
          </div>
        ) : (
          <>
            {' '}
            <div className="border-t border-grey my-4"></div>
            <div className="w-[16rem] relative">
              <InputGroup
                size="sm_2"
                position="center"
                placeholder="Search Mortgage Broker"
                svg={searchIcon}
                value={loanOfficer}
                onChange={(e) => setLoanOfficer(e.target.value)}
              />
              {loanOfficer && (
                <img
                  src={closeIcon}
                  alt=""
                  className="absolute top-3 right-4 cursor-pointer"
                  onClick={() => setLoanOfficer('')}
                />
              )}
            </div>
            {!loanOfficer && (
              <div className="flex items-center gap-4 mt-4">
                <SvgIcon
                  checked={
                    checkedItems.length === loanOfficers?.data?.users?.length
                  }
                  onClick={handleSelectAll}
                  className="cursor-pointer h-[20px]"
                />
                <div
                  className="p-sm-bold text-custom-greengray cursor-pointer"
                  onClick={handleSelectAll}
                >
                  Select All
                </div>
              </div>
            )}
            {filteredLoanOfficers?.length === 0 && (
              <div className=" my-8 text-center text-sm">No results found</div>
            )}
            {!loanOfficer && <div className="border-t border-grey my-4"></div>}
            <div className="max-h-60 overflow-auto scroll-hidden">
              {filteredLoanOfficers?.map((item) => (
                <div
                  key={item.userId}
                  className="flex items-center gap-4 mb-4 hover:bg-primary50"
                >
                  <SvgIcon
                    onClick={() => handleItemClick(item.userId)}
                    checked={checkedItems.includes(item.userId)}
                    className="cursor-pointer h-[20px]"
                  />
                  <div
                    className="font-medium-2 text-custom-greengray cursor-pointer"
                    onClick={() => handleItemClick(item.userId)}
                  >
                    {item.firstName + ' ' + item?.lastName}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end gap-4 ">
              <Button
                variant="tertiary_sm"
                onClick={() => {
                  setFilterPopup(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary_sm" onClick={onApplyButton}>
                Apply
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FilterModule;
