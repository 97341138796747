import H1 from 'components/h1-typography';
import React from 'react';

const TermsAndConditions = () => {
  return (
    <>
      <div className="py-2">
        <H1>Privacy Act Notice: </H1>
        <p className="p-xs-medium">
          This information is to be used by the agency collecting it or its
          assignees in determining whether you qualify as a prospective
          mortgagor under its program. It will not be disclosed outside the
          agency except as required and permitted by law. You do not have to
          provide this information, but if you do not your application for
          approval as a prospective mortgagor or borrower may be delayed or
          rejected. The information requested in this form is authorized by
          Title 38, USC, Chapter 37 (if VA); by 12 USC, Section 1701 et. seq.
          (if HUD/FHA); by 42 USC, Section 1452b (if HUD/CPD); and Title 42 USC,
          1471 et. seq., or 7 USC, 1921 et. seq. (if USDA/FmHA).
        </p>
      </div>
      <div className="py-2">
        <H1>Borrower Authorization:</H1>
        <p className="p-xs-medium">
          I hereby authorize the Lender/Broker to verify my past and present
          employment earnings records, bank accounts, stock holdings, and any
          other asset balances that are needed to process my mortgage loan
          application. I further authorize the Lender/Broker to order a consumer
          credit report and verify other credit information, including past and
          present mortgage and landlord references. It is understood that a copy
          of this form will also serve as authorization. The information the
          Lender/Broker obtains is only to be used in the processing of my
          application for a mortgage loan.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditions;
