import React from 'react';
import { colorsBox } from './data';
import { PieChart } from 'react-minimal-pie-chart';
// const dataMock = [
//     { title: 'One', value: 10, color: '#E38627' },
//     { title: 'Two', value: 15, color: '#C13C37' },
//     { title: 'Three', value: 20, color: '#6A2135' },
//   ];
function DoughnutGraph({ graphData = [], position }) {
  const allValuesZero = graphData.every((data) => data.value === 0);
  const updatedGraphData = allValuesZero
    ? [{ value: 1, color: '#D9D9D9' }]
    : graphData;
  return (
    <div
      className={`flex ${position && position === 'bottom' && 'flex-col'} items-center justify-between w-full`}
    >
      <div
        className={`${position && position === 'bottom' ? 'w-[80%]' : 'w-1/2 p-4'} my-div `}
      >
        <PieChart
          data={updatedGraphData}
          lineWidth={40}
          animate={true}
          radius={35}
        />
      </div>
      <div className="w-1/2 flex">
        <ul>
          {graphData?.map((data, i) => (
            <div className="flex self-center items-center" key={i + 'graph'}>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: data?.color || colorsBox[i],
                }}
              ></p>
              <li
                className="pl-1 self-start text-disabled"
                style={{ fontSize: '14px' }}
              >
                {data?.title}
                {` ${data?.unit ?? ''}  ${position ? data?.value : ''}`}
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DoughnutGraph;
