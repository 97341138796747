/* istanbul ignore file */
export const EventEnums = {
  CONSUMER_PROFILE_ID_CREATED: 'profile_id_created_consumer',
  CONSUMER_PROFILE_COMPLETED: 'profile_completed_consumer',
  CONSUMER_ELIGIBLE: 'eligible_consumer',
  CONSUMER_PRE_APPROVED: 'pre_approved_consumer',
  CONSUMER_NOT_ELIGIBLE: 'not_eligible_consumer',
  CONSUMER_PRE_APPROVAL_REJECTION: 'pre_approval_rejection_consumer',
  CONSUMER_QUOTE: 'quote_consumer',
  CONSUMER_EMAIL_CHANGE_VERIFICATION: 'email_change_verification_consumer',
  LOAN_OFFICER_CONSUMER_COMPLETED_ONBOARDING:
    'consumer_completed_onboarding_loan_officer',
  LOAN_OFFICER_PROFILE_COMPLETED: 'profile_completed_loan_officer',
  LOAN_OFFICER_ADD_LEAD: 'add_lead_loan_officer',
  LOAN_OFFICER_ELIGIBLE: 'eligible_loan_officer',
  LOAN_OFFICER_PRE_APPROVED: 'pre_approved_loan_officer',
  LOAN_OFFICER_PRE_APPROVAL_REJECTION: 'pre_approval_rejection_loan_officer',
  LOAN_OFFICER_NOT_ELIGIBLE: 'not_eligible_loan_officer',
};

export const NotificationTypeMap = {
  1: EventEnums.CONSUMER_PROFILE_ID_CREATED,
  2: EventEnums.CONSUMER_PROFILE_COMPLETED,
  3: EventEnums.CONSUMER_ELIGIBLE,
  4: EventEnums.CONSUMER_PRE_APPROVED,
  5: EventEnums.CONSUMER_NOT_ELIGIBLE,
  6: EventEnums.CONSUMER_PRE_APPROVAL_REJECTION,
  7: EventEnums.CONSUMER_QUOTE,
  8: EventEnums.CONSUMER_EMAIL_CHANGE_VERIFICATION,
  9: EventEnums.LOAN_OFFICER_CONSUMER_COMPLETED_ONBOARDING,
  10: EventEnums.LOAN_OFFICER_PROFILE_COMPLETED,
  11: EventEnums.LOAN_OFFICER_ADD_LEAD,
  12: EventEnums.LOAN_OFFICER_ELIGIBLE,
  13: EventEnums.LOAN_OFFICER_PRE_APPROVED,
  14: EventEnums.LOAN_OFFICER_PRE_APPROVAL_REJECTION,
  15: EventEnums.LOAN_OFFICER_NOT_ELIGIBLE,
};
