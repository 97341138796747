/**
 * Functional component for managing assets and other credits in a borrower profile.
 * Handles form submission, validation, and updating profile information.
 * Utilizes customToast for success and error notifications.
 * Uses DynamicForm component for rendering dynamic form fields.
 * Makes use of RTK Queries for fetching and updating data.
 * Props:
 * - readOnly: boolean to determine if the form is in read-only mode
 * - setReadOnly: function to set the read-only mode
 * - resetId: number to trigger form reset
 * - setResetId: function to set the reset id
 * @returns JSX element for managing assets and other credits in a borrower profile
 */

// React and Components
import React, { useState, useEffect } from 'react';
import { customToast } from 'components/toast';
import Button from 'components/button';
import Loader from 'wrapper/loader';
import FormWrapper from 'wrapper/form-wrapper';
import DeletePopup from 'components/delete-popup';
//icon's
import DeleteIcon from 'assets/icons/DeleteBin';
import plus from 'assets/icons/bluePlus.svg';
//Sentry
import * as Sentry from '@sentry/browser';
import {
  assetData,
  otherAssets as otherAssetsData,
  otherCredits,
} from 'pages/profile/formfields/assetAndOtherCredits';
// Utils
import {
  checkValidations,
  createFormObject,
  removeEmptyStrings,
} from 'pages/profile/utils';
import DynamicForm from 'pages/profile/DynamicForm';
// RTK Queries
import {
  useGetBorrowerProfileAdminQuery,
  useCreateAssetDetailsAdminMutation,
  useDeleteAssetsDetailsAdminMutation,
} from '../../../../redux/services/borrower-profile';
import { useGetAssetTypeQuery } from '../../../../redux/services/client/profile';

import { useLocation } from 'react-router-dom';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';
const AssetsAndOtherCredits = ({
  readOnly,
  setReadOnly,
  resetId,
  setResetId,
}) => {
  // States
  const isEdit = React.useRef(false);
  const [assets, setAssets] = React.useState([createFormObject(assetData)]);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [otherAssets, setOtherAssets] = React.useState(
    createFormObject(otherAssetsData)
  );
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [otherCreditsInfo, setOtherCreditsInfo] = React.useState(
    createFormObject(otherCredits)
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');

  // Selectors

  const { data: applicationData, isLoading } = useGetBorrowerProfileAdminQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [createAssets, { isLoading: isCreateAssetsLoading }] =
    useCreateAssetDetailsAdminMutation();
  const [deleteAssets] = useDeleteAssetsDetailsAdminMutation();
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let uniqueId;
    /* if (assetsDataAI && Object.keys(assetsDataAI).length > 0) {
      const refetchedData = await refetch();
      if (refetchedData?.isSuccess) {
        uniqueId =
          refetchedData?.data?.data?.user_asset_details?.[0]?.userAssetId;
      }
    } */
    let hasErrors = false;
    const allErrors = {};

    assets.forEach((asset, index) => {
      const assetErrors = checkValidations(assetData, asset);
      if (Object.keys(assetErrors).length > 0) {
        hasErrors = true;
        allErrors[`asset_${index}`] = assetErrors;
      }
    });

    const otherAssetErrors = checkValidations(otherAssetsData, otherAssets);
    if (Object.keys(otherAssetErrors).length > 0) {
      hasErrors = true;
      allErrors.otherAssets = otherAssetErrors;
    }

    const creditErrors = checkValidations(otherCredits, otherCreditsInfo);
    if (Object.keys(creditErrors).length > 0) {
      hasErrors = true;
      allErrors.credits = creditErrors;
    }

    if (hasErrors) {
      setErrors(allErrors);
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
      setErrors({});
      try {
        const incrementOrder = (arr) => {
          // Find the highest existing order
          const highestOrder = arr.reduce((max, obj) => {
            return obj.order ? Math.max(max, obj.order) : max;
          }, 0);

          // Start assigning orders from the next value after the highest existing order
          let nextOrder = highestOrder + 1;

          return arr.map((obj) => {
            if (!obj.order) {
              obj.order = nextOrder++;
            }
            return obj;
          });
        };

        const updatedArray = incrementOrder(assets);

        const data = updatedArray.map((asset) => ({
          ...asset,
          assetTypeId: asset?.assetTypeId?.id,
        }));

        const otherAssetsData = {
          ...otherAssets,
          otherAssetTypeId: otherAssets.otherAssetTypeId?.id,
        };

        const otherCredits = { ...otherCreditsInfo };

        const maxLength = Math.max(data.length);

        const mergedArray = Array.from({ length: maxLength }, (_, index) => {
          const baseObject = {
            assetTypeId: data[index]?.assetTypeId ?? null,
            bankName: data[index]?.bankName ?? null,
            accountNumber: data[index]?.accountNumber
              ? String(data[index].accountNumber)
              : null,
            balance: data[index]?.balance ? Number(data[index].balance) : 0,
            otherAssetTypeId: otherAssetsData?.otherAssetTypeId ?? null,
            otherAccountSource: otherAssetsData?.otherAccountSource ?? null,
            otherAccountBalance: otherAssetsData?.otherAccountBalance
              ? Number(otherAssetsData.otherAccountBalance)
              : 0,
            realtorCredit: otherCredits?.realtorCredit
              ? Number(otherCredits.realtorCredit)
              : 0,
            sellerCredit: otherCredits?.sellerCredit
              ? Number(otherCredits.sellerCredit)
              : 0,
            lenderCredit: otherCredits?.lenderCredit
              ? Number(otherCredits.lenderCredit)
              : 0,
            otherCredit: otherCredits?.otherCredit
              ? Number(otherCredits.otherCredit)
              : 0,
            applicationId,
            userProfileId:
              applicationData?.data?.personal_details?.userProfileId ?? null,
            userId: applicationData?.data?.personal_details?.userId ?? null,
            isCompleted: true,
            order: data[index].order,
          };

          // Conditionally add userAssetId if it exists
          if (index === 0 && uniqueId) {
            baseObject.userAssetId = assets[index].userAssetId || uniqueId;
          } else if (assets[index]?.userAssetId) {
            baseObject.userAssetId = assets[index].userAssetId;
          }

          return baseObject;
        });
        const resp = await createAssets(mergedArray);
        //await refetch();

        if (resp?.data?.status === 200) {
          setIsFormSubmitted(false);
          setReadOnly(true);
          dispatch(
            resetPageInfo({
              name: 'assetData',
            })
          );
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (resetId === 4) {
      setAssets([createFormObject(assetData)]);

      setOtherAssets(createFormObject(otherAssets));

      setOtherCreditsInfo(createFormObject(otherCredits));
      setResetId(0);
    }
  }, [resetId]);

  //slices
  const { data: accountTypeData } = useGetAssetTypeQuery();

  React.useLayoutEffect(() => {
    if (applicationData?.data?.user_asset_details.length > 0) {
      isEdit.current = true;
      const formData = applicationData?.data?.user_asset_details[0];
      const assetsFormData = applicationData?.data?.user_asset_details;

      const structuredAssets = assetsFormData.map((formData) => ({
        ...formData,
        assetTypeId: formData.assetTypeId ? { id: formData.assetTypeId } : null,
      }));

      setAssets(structuredAssets);
      setOtherAssets({
        otherAssetTypeId: formData?.otherAssetTypeId
          ? { id: formData?.otherAssetTypeId }
          : '',
        otherAccountSource: formData?.otherAccountSource
          ? formData?.otherAccountSource
          : '',
        otherAccountBalance: formData?.otherAccountBalance
          ? formData?.otherAccountBalance
          : '',
      });
      setOtherCreditsInfo({
        realtorCredit: formData.realtorCredit ? formData.realtorCredit : '',
        sellerCredit: formData.sellerCredit ? formData.sellerCredit : '',
        lenderCredit: formData.lenderCredit ? formData.lenderCredit : '',
        otherCredit: formData.otherCredit ? formData.otherCredit : '',
      });
    }
  }, [applicationData, readOnly]);

  const formatData = (data, property) => {
    return (
      data?.map((x) => {
        return {
          ...x,
          id: x[property],
        };
      }) ?? []
    );
  };

  const dropdowns = {
    assetTypeId: formatData(accountTypeData?.data, 'assetTypeId'),
    otherAssetTypeId: formatData(accountTypeData?.data, 'assetTypeId'),
  };
  const addAsset = () => {
    if (readOnly) return;
    if (assets?.length < 5) {
      customToast.success(
        `Additional asset ${assets.length} added successfully`
      );
      setAssets([...assets, createFormObject(assetData)]);
    } else {
      customToast.error('Cannot add more than 5 assets');
    }
  };
  const removeAsset = async (index, asset) => {
    if (index === 0) return;
    if (asset) {
      try {
        await deleteAssets({
          assetId: asset,
          userId: applicationData?.data?.personal_details?.userId,
        });
        customToast.success(`Additional asset ${index} removed successfully.`);
        //refetch();
        setIsDelete(false);
      } catch (error) {
        customToast.error('Failed to delete');
      }
    } else if (!asset) {
      const newAssets = assets.filter((_, i) => i !== index);
      customToast.success(`Additional asset ${index} removed successfully.`);
      setAssets(newAssets);
      setIsDelete(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <div className=" overflow-auto">
          <FormWrapper
            className={
              'my-3 mx-2 max-h-borrower-profile overflow-hidden overflow-y-scroll min-w-[63vw] pb-3'
            }
          >
            <form onSubmit={onSubmitHandler}>
              <div className="flex flex-row justify-end">
                <div
                  className="mb-3 self-end w-fit flex flex-col justify-center py-2 cursor-pointer p-sm-regular text-primary500 rounded-md"
                  onClick={addAsset}
                >
                  <>
                    <div className="flex flex-row justify-center gap-3 w-fit px-2">
                      <img src={plus} alt="+" className="h-[16px]" />
                      <p className="font-medium">Add Additional Asset</p>
                    </div>
                  </>
                </div>
              </div>
              {assets?.map((asset, index) => {
                return (
                  <div key={index} className="mb-6">
                    <h1
                      className={`text-lg px-4 py-3 p-sm-bold flex justify-between ${index > 0 ? 'bg-custom-primary400' : 'bg-custom-primary400'} `}
                    >
                      {index > 0 ? `Asset - ${index}` : 'Assets'}
                      {index > 0 && (
                        <>
                          <button
                            onClick={() => {
                              if (readOnly) return;
                              setIsDelete(true);
                              setDeleteId(index);
                            }}
                            type="button"
                          >
                            <DeleteIcon />
                          </button>
                          {isDelete && deleteId === index && (
                            <DeletePopup
                              onSubmitHandler={() =>
                                removeAsset(index, asset?.userAssetId)
                              }
                              deletePopup={isDelete}
                              setDeletePopup={setIsDelete}
                              data={{ name: `Asset - ${index}` }}
                            />
                          )}
                        </>
                      )}
                    </h1>
                    <DynamicForm
                      data={assetData}
                      formData={asset}
                      setFormData={(newData) => {
                        // Check if newData is a function and execute it to get the updated data
                        const updatedData =
                          typeof newData === 'function'
                            ? newData(assets[index])
                            : newData;

                        // Create a copy of the current assets array
                        const newAssets = [...assets];

                        // Update the specific asset at the given index with the new data
                        newAssets[index] = {
                          ...newAssets[index],
                          ...updatedData,
                        };

                        // Set the updated assets array back into the state
                        setAssets(newAssets);
                      }}
                      dropdowns={dropdowns}
                      isSubmitted={isFormSubmitted}
                    />
                  </div>
                );
              })}
              <h1 className="text-lg px-4 py-3 p-sm-bold bg-custom-primary400">
                Other Assets
              </h1>
              <DynamicForm
                data={otherAssetsData}
                formData={otherAssets}
                setFormData={setOtherAssets}
                isSubmitted={isFormSubmitted}
                dropdowns={dropdowns}
                errors={errors}
                readOnly={readOnly}
              />
              <h1 className="text-lg px-4 mt-6 py-3 p-sm-bold bg-custom-primary400">
                Other Credits
              </h1>
              <DynamicForm
                data={otherCredits}
                formData={otherCreditsInfo}
                setFormData={setOtherCreditsInfo}
                errors={errors.credits}
                isSubmitted={isFormSubmitted}
                readOnly={readOnly}
              />
              {!readOnly && (
                <div className="text-right mt-2">
                  <Button
                    variant="tertiary"
                    style={{ marginRight: '10px' }}
                    type="button"
                    onClick={() => {
                      setReadOnly(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary">
                    {isCreateAssetsLoading ? (
                      <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                        <Loader loaderText="Saving" FullScreen={false} />
                      </div>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              )}
            </form>
          </FormWrapper>
        </div>
      )}
    </>
  );
};

export default AssetsAndOtherCredits;
