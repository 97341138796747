import { format } from "date-fns";
/**
 * Converts a Unix timestamp to a custom date format.
 *
 * @param {number} unixTimestamp - The Unix timestamp to convert.
 * @param {string} formatType - The format type for the output date.
 * @returns {string} The formatted date string.
 */
export function convertUnixToCustomDate(unixTimestamp, formatType) {
  // Check if unixTimestamp is a valid number
  if (isNaN(unixTimestamp) || unixTimestamp <= 0) {
    return '';
  }
  // Create a new Date object using the Unix timestamp
  const date = new Date(unixTimestamp * 1000);
  if (isNaN(date.getTime())) {
    return '';
  }
  const formattedDate = format(date, formatType);
  return formattedDate;
}

