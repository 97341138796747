import React, { useState, useEffect } from 'react';
import { RxCross2 } from 'react-icons/rx';
import Button from 'components/button/index';
import Modal from 'components/modal/index';
import Tabs from 'components/tabs/index';
import compareOffer from 'pages/borrower-profile/fees-worksheet/formData/compareOffer.js';
import DynamicForm from 'pages/profile/DynamicForm';
import { FiUploadCloud } from 'react-icons/fi';
import UploadCloud from 'assets/icons/file.svg';
import { customToast } from 'components/toast';
import * as Sentry from '@sentry/browser';

// icons
import closeIcon from 'assets/icons/closeIcon.svg';
import fileIcon from 'assets/icons/file.svg';
import deleteIcon from 'assets/icons/delete.svg';

//query
import {
  useCompareOfferMutation,
  useGetLoanProgramQuery,
  useCompareOfferDocumentUploadMutation,
} from 'redux_store/services/admin/product-pricing';

//utils
import {
  checkValidations,
  createFormObject,
  formatData,
} from 'pages/profile/utils/index.js';

//logs
import DragAndDrop from 'components/drag-and-drop';
import Progressbar from 'components/progressbar';
import Loader from 'wrapper/loader';

const CompareOffer = ({
  setCompareOfferVisibility,
  setAllQuotes,
  products,
  setCustomQuote,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [files, setFiles] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState({});
  const [uploadSuccess, setUploadSuccess] = useState(true);
  const [submitCompareOffer] = useCompareOfferMutation();
  const [uploadDocumentOffer] = useCompareOfferDocumentUploadMutation();
  const { data: loanProgram } = useGetLoanProgramQuery();
  const [formData, setFormData] = useState({
    ...createFormObject(compareOffer),
  });

  const [selectedTabId, setSelectedTabId] = useState(1);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const errors = checkValidations(compareOffer, formData);
    if (Object.keys(errors)?.length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
    } else {
      setIsFormSubmitted(false);
      setErrors({});
      try {
        let data = {
          application_id: applicationId,
          products: products,
          loan_details: {
            loan_amount: Number(formData?.originalLoanAmount),
            rate: Number(formData?.interestRate),
            start_date: formData?.loanStartDate,
            number_of_payments: Number(formData?.noofPayments) || 0,
            mortgage_insurance: Number(formData?.mortgageInsurance),
            loan_term: Number(formData?.originalLoanTerms),
            loan_program_id: formData?.loanProgram?.loanProgramId,
          },
        };
        const resp = await submitCompareOffer({
          data: data,
        }).unwrap();

        if (resp?.status === 200) {
          setAllQuotes((prevQuotes) => {
            const updatedQuoteDetails = {
              ...prevQuotes.quote_details,
              product_details: [...prevQuotes.quote_details.product_details],
            };
            const newProductDetails = resp.data.quote_details.product_details;

            const emptyHeadingIndex =
              updatedQuoteDetails.product_details.findIndex(
                (product) => product.heading === ''
              );

            if (emptyHeadingIndex !== -1) {
              // Replace the empty heading product with the new data
              updatedQuoteDetails.product_details[emptyHeadingIndex] =
                newProductDetails.find((product) => product.heading === '');
            } else {
              // If no empty heading found, push the new empty heading product
              const newEmptyHeadingProduct = newProductDetails.find(
                (product) => product.heading === ''
              );
              if (newEmptyHeadingProduct) {
                updatedQuoteDetails.product_details.push(
                  newEmptyHeadingProduct
                );
              }
            }

            return {
              ...prevQuotes,
              quote_details: updatedQuoteDetails,
            };
          });
          setCustomQuote(resp?.data);
          customToast.success(resp?.message);
          setCompareOfferVisibility(false);
        }
      } catch (error) {
        customToast.error(
          error?.data?.message ? error?.data?.message : 'an error occurred'
        );
        Sentry.captureException(error);
      }
    }
  };

  const onFilesChange = async () => {
    const file = files[0];
    try {
      setIsUploading(true);
      const data = {
        application_id: applicationId,
        products: products,
      };
      const formData = new FormData();
      formData.append('body', JSON.stringify(data));
      formData.append('file', file);
      const resp = await uploadDocumentOffer({ data: formData }).unwrap();
      if (resp?.status === 200) {
        setAllQuotes((prevQuotes) => {
          const updatedQuoteDetails = {
            ...prevQuotes.quote_details,
            product_details: [...prevQuotes.quote_details.product_details],
          };
          const newProductDetails = resp.data.quote_details.product_details;

          const emptyHeadingIndex =
            updatedQuoteDetails.product_details.findIndex(
              (product) => product.heading === ''
            );

          if (emptyHeadingIndex !== -1) {
            // Replace the empty heading product with the new data
            updatedQuoteDetails.product_details[emptyHeadingIndex] =
              newProductDetails.find((product) => product.heading === '');
          } else {
            // If no empty heading found, push the new empty heading product
            const newEmptyHeadingProduct = newProductDetails.find(
              (product) => product.heading === ''
            );
            if (newEmptyHeadingProduct) {
              updatedQuoteDetails.product_details.push(newEmptyHeadingProduct);
            }
          }

          return {
            ...prevQuotes,
            quote_details: updatedQuoteDetails,
          };
        });
        setCustomQuote(resp?.data);
        customToast.success(resp?.message);
        setCompareOfferVisibility(false);
        setIsUploading(false);
        setUploadSuccess(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      customToast.error(error?.data?.message);
      setIsUploading(false);
      setUploadSuccess(false);
    }
  };

  //map dropdowns with its id's
  const dropdowns = {
    loanProgram: formatData(loanProgram?.data, 'loanProgramId'),
  };

  const form = (
    <>
      <div className="sm:w-[40vw] md:w-[40vw] bg-white h-fit self-center rounded-md flex flex-col justify-center p-3">
        <div className="h-[10%] w-[100%] flex flex-row justify-between border-b border-b-grey100">
          <div className="h-[100%] flex flex-col justify-end p-2">
            <h1 className="p-md-bold">Compare</h1>
          </div>
          <div
            className="h-[100%] flex flex-col justify-end p-2 cursor-pointer"
            onClick={() => {
              setCompareOfferVisibility(false);
            }}
          >
            <RxCross2 size={20} className="p-xs-semibold self-center" />
          </div>
        </div>
        <div className="h-[80%] w-[100%] p-2 flex flex-col gap-1">
          <Tabs
            tabs={[
              {
                id: 1,
                name: 'Compare with Current Loan',
              },
              {
                id: 2,
                name: 'Compare with another Offer',
              },
            ]}
            selectedTabId={selectedTabId}
            onClickTab={(tab) => {
              setSelectedTabId(tab?.id);
            }}
            variant="secondary"
          />
          {selectedTabId === 1 ? (
            <div className="h-fit">
              <form>
                <DynamicForm
                  data={compareOffer}
                  formData={formData}
                  setFormData={setFormData}
                  dropdowns={dropdowns}
                  errors={errors}
                  isSubmitted={isFormSubmitted}
                />
              </form>
            </div>
          ) : (
            <div className="h- w-[100%] flex flex-col justify-center p-4 my-4">
              {!files && (
                <DragAndDrop
                  onFilesChange={setFiles}
                  currentFile={files}
                  textElement={
                    <p className="m-2 text-center text-sm text-gray-500 dark:text-gray-400 p-2">
                      <span className="text-custom-green font-semibold">
                        Click to upload
                      </span>{' '}
                      or Drag & Drop PDF, JPEG, JPG or PNG document (Max 10 MB)
                    </p>
                  }
                />
              )}

              {files && (
                <div
                  className={`rounded-md ${
                    [
                      'application/pdf',
                      'image/jpeg',
                      'image/jpg',
                      'image/png',
                      'image/gif',
                    ].includes(files[0]?.type) &&
                    (files[0]?.size / 1048576).toFixed(2) < 10 &&
                    uploadSuccess
                      ? 'border-grey border'
                      : 'border-danger100 border-2'
                  }  flex items-center p-3 gap-2`}
                >
                  <img src={fileIcon} alt="" />
                  <div className="flex-grow">
                    <div className="flex justify-between">
                      <div className="font-medium-2 truncate overflow-hidden max-w-[252px]">
                        {files[0]?.name}
                      </div>
                      {[
                        'application/pdf',
                        'image/jpeg',
                        'image/jpg',
                        'image/png',
                        'image/gif',
                      ].includes(files[0]?.type) ? (
                        <img
                          src={deleteIcon}
                          alt=""
                          className="cursor-pointer"
                          onClick={() => {
                            setFiles(null);
                            setUploadSuccess(true);
                          }}
                        />
                      ) : (
                        <img
                          src={closeIcon}
                          alt=""
                          className="cursor-pointer"
                          onClick={() => setFiles(null)}
                        />
                      )}
                    </div>

                    <p className="p-xs-regular text-neutral600">
                      {(files[0]?.size / 1048576).toFixed(2)} MB
                    </p>

                    {[
                      'application/pdf',
                      'image/jpeg',
                      'image/jpg',
                      'image/png',
                      'image/gif',
                    ].includes(files[0]?.type) ? (
                      <Progressbar
                        value={100}
                        valueString="100 %"
                        valuePosition="right"
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              )}
              {files && (files[0]?.size / 1048576).toFixed(2) > 10 && (
                <p className="font-medium-2 text-danger100 mt-2 mb-2">
                  File size too large. Document size should be below 10 MB.
                </p>
              )}
              {files &&
                ![
                  'application/pdf',
                  'image/jpeg',
                  'image/jpg',
                  'image/png',
                  'image/gif',
                ].includes(files[0]?.type) && (
                  <p className="font-medium-2 text-danger100 mt-2 mb-2">
                    This file type is not supported. Please check and upload the
                    correct file type.
                  </p>
                )}
            </div>
          )}
        </div>

        <div className="h-[15%] w-[100%] flex flex-row justify-end p-2">
          <div className="h-[100%] flex flex-row justify-end gap-3">
            <Button
              variant="white_sm"
              onClick={() => {
                setCompareOfferVisibility(false);
              }}
              type="button"
              // btnClassName="border border-grey-100"
            >
              Cancel
            </Button>
            <Button
              variant="primary_sm"
              onClick={selectedTabId === 1 ? onSubmitHandler : onFilesChange}
              type="button"
            >
              {isUploading ? (
                <>
                  <div className="h-[100%] -mb-1 w-[100%] min-w-[100px] flex flex-col justify-center">
                    <Loader loaderText="Uploading" FullScreen={false} />
                  </div>
                </>
              ) : (
                'Add'
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <Modal show={true} children={form} width="sm:[60%] md:w-[40%] lg:-[40%]" />
  );
};

export default CompareOffer;
