import replaceBlankValue from 'utils/replaceBlankValue';
const RateAdjustment = ({
  rateAdjustmentData = {
    first_adjustment_cap: null,
    first_change: null,
    adjust_cap: null,
    adjust_period: null,
    life_cap: null,
    margin: null,
    index: null,
    rounding: null,
  },
}) => {
  if (rateAdjustmentData)
    return (
      <>
        <h2 className="font-bold text-start p-2">Rate Adjustment</h2>
        <table>
          <tbody>
            <tr className={`w-[100%]`}>
              <td className={`font-medium text-start p-2`}>
                {'First Adj. Cap'}
              </td>
              <td className={`text-start w-[50%]`}>
                {replaceBlankValue(rateAdjustmentData?.first_adjustment_cap)}
              </td>
            </tr>
            <tr className={`w-[100%]`}>
              <td className={`font-medium text-start p-2`}>{'First Change'}</td>
              <td className={`text-start`}>
                {replaceBlankValue(rateAdjustmentData?.first_change)}
              </td>
            </tr>
            <tr className={`w-[100%]`}>
              <td className={`font-medium text-start p-2`}>{'Adjust Cap'}</td>
              <td className={`text-start`}>
                {replaceBlankValue(rateAdjustmentData?.adjust_cap)}
              </td>
            </tr>
            <tr className={`w-[100%]`}>
              <td className={`font-medium text-start p-2`}>
                {'Adjust Period'}
              </td>
              <td className={`text-start`}>
                {replaceBlankValue(rateAdjustmentData?.adjust_period)}
              </td>
            </tr>
            <tr className={`w-[100%]`}>
              <td className={`font-medium text-start p-2`}>{'Life Cap'}</td>
              <td className={`text-start`}>
                {replaceBlankValue(rateAdjustmentData?.life_cap)}
              </td>
            </tr>
            <tr className={`w-[100%]`}>
              <td className={`font-medium text-start p-2`}>{'Margin'}</td>
              <td className={`text-start`}>
                {replaceBlankValue(rateAdjustmentData?.margin)}
              </td>
            </tr>
            <tr className={`w-[100%]`}>
              <td className={`font-medium text-start p-2`}>{'Index'}</td>
              <td className={`text-start`}>
                {replaceBlankValue(rateAdjustmentData?.index)}
              </td>
            </tr>
            <tr className={`w-[100%]`}>
              <td className={`font-medium text-start p-2`}>{'Rounding'}</td>
              <td className={`text-start`}>
                {replaceBlankValue(rateAdjustmentData?.rounding)}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
};

export default RateAdjustment;
