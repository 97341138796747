import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import * as Sentry from '@sentry/browser';

//components
import Checkbox from 'components/checkbox';
import Button from 'components/button';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
//icons
import angleDown from 'assets/icons/angleDown.svg';
import arrowDownBlue from 'assets/icons/arrowDownBlue.svg';
//children components
import AddLeads from 'pages/loan-information/modals/leads';
import MortageQuote from '../mortgageQuote';
//utils
import { FormatTimeDifference } from 'constants/updatedAt';
import { formatNumberIntoCurrency } from 'constants/formatNumber';

//RTK query
import { useCompareProductsMutation } from 'redux_store/services/admin/quickPrice';
const ManageScenarios = ({ quoteData, formData, setBreadCrumbs }) => {
  const [productData, setProductData] = useState([quoteData[0]]);
  const animateKey = 0;
  const [selectedRow, setSelectedRow] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [checkedRates, setCheckedRates] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [compensationAmounts, setCompensationAmounts] = useState({});
  //const productData = [];
  const [leadsPopup, setLeadsPopup] = useState(false);
  const [mortgageQuoteData, setMortgageQuoteData] = useState();
  const [isMortgageQuoteActive, setIsMortgageQuoteActive] = useState(false);
  //RTK
  const [
    compareProducts,
    { isLoading: isCompareProductsLoading, error: errorData },
  ] = useCompareProductsMutation();
  // set borrower compensation data
  useEffect(() => {
    const initialCompensationAmounts = productData?.reduce((acc, item) => {
      acc[item.product_id] = item.broker_compensation_percent;
      return acc;
    }, {});
    setCompensationAmounts(initialCompensationAmounts);
  }, [productData]);

  //filter product ids
  useEffect(() => {
    const data = Object.entries(checkedRates)
      .filter(([productId, rateId]) => rateId !== undefined) // Filter out undefined rateIds
      .map(([productId, rateId]) => {
        const product = productData?.find(
          (item) => item.product_id === parseInt(productId, 10)
        );
        const rate = product?.more_rates?.find(
          (rateItem) => rateItem.rate_id === rateId
        )?.rate;
        return {
          product_id: parseInt(productId, 10), // Convert productId to integer
          rate: rate,
        };
      });

    // Set the array of objects to productData
    setProductIds(data);

    const details = Object.entries(checkedRates)
      .filter(([productId, rateId]) => rateId !== undefined) // Filter out undefined rateIds
      .map(([productId, rateId]) => {
        const product = productData?.find(
          (item) => item.product_id === parseInt(productId, 10)
        );
        return {
          ...product,
          selectedRate: rateId,
        };
      });

    setProductDetails(details);
  }, [checkedRates]);

  // compare offer
  const handleCompareOffer = async () => {
    if (checkedRates.length === 0) {
      customToast.error('Please select at least one product to compare.');
    } else if (checkedRates.length > 3) {
      customToast.error('Cannot compare more than 3 offers.');
    } else {
      try {
        const products = checkedRates.map((rate) => ({
          product_id: rate.productId,
          rate: productData
            .find((product) => product.product_id === rate.productId)
            ?.more_rates.find((moreRate) => moreRate.rate_id === rate.rateId)
            ?.rate,
          base_points: productData
            .find((product) => product.product_id === rate.productId)
            ?.more_rates.find((moreRate) => moreRate.rate_id === rate.rateId)
            ?.base_points,
        }));
        const data = {
          vendor_id: formData.lenderType?.lenderTypeId,
          loan_type_id: formData.loanType?.loanTypeId,
          loan_amount: formData.loanAmount,
          sales_price: formData.salesPrice,
          appraised_value: formData.appraisedValue,
          loan_purpose_id: formData.purposeType.loanPurposeId,
          occupancy_type_id: formData.occupancyType.occupancyTypeId,
          property_type_id: formData.propertyType.propertyTypeId,
          number_of_units: formData.noOfUnits,
          property_state: formData.propertyState.name,
          zip_code: formData.zipCode,
          credit_score: formData.creditScore,
          commitment_period_id:
            formData.commitmentPeriodType.commitmentPeriodId,
          escrow_waiver_type_id:
            formData.escrowImpoundWaiverType.escrowWaiverTypeId,
          loan_term_id: formData.loanTermsType.loanTermId,
          property_state_id: formData?.propertyState.stateId, //property state id should be implemented than passed here
          product_list: products,
        };
        const response = await compareProducts({ data }).unwrap();
        if (response?.status === 200) {
          customToast.success(response?.message);
          setMortgageQuoteData(response?.data);
          setIsMortgageQuoteActive(true);
          setBreadCrumbs([
            {
              name: 'Dashboard',
              path: '/dashboard',
            },
            {
              name: 'Quick Price',
              path: '/quickPrice',
            },
            {
              name: 'Mortgage Quote',
              path: '/quickPrice',
            },
          ]);
        }
      } catch (error) {
        Sentry.captureException(error);
        customToast.error(error?.data?.message);
      }
    }
  };

  //parent checkbox
  const handleParentCheckboxChange = (productId) => {
    const productRates = checkedRates.filter(
      (item) => item?.productId === productId
    );
    const otherProductRates = checkedRates.filter(
      (item) => item?.productId !== productId
    );

    if (productRates.length > 0) {
      // If any rates for this product are selected, remove them all
      setCheckedRates(otherProductRates);
      return;
    }
    const product = productData?.find((item) => item?.product_id === productId);
    // If no rates are selected for this product, try to add the first rate
    const firstRateId = product?.more_rates.find(
      (rate) => rate.rate === product.rate
    );

    if (!firstRateId) {
      // If there's no first rate, do nothing
      return;
    }

    if (otherProductRates.length >= 3) {
      customToast.error(
        'You can select a maximum of 3 rates across all products.'
      );
      return;
    }

    // At this point, we know we can add the first rate
    setCheckedRates([
      ...otherProductRates,
      { productId, rateId: firstRateId.rate_id },
    ]);
  };

  //more rates checkbox
  const handleCheckboxChange = (productId, rateId) => {
    const existingIndex = checkedRates.findIndex(
      (item) => item.productId === productId && item.rateId === rateId
    );

    if (existingIndex !== -1) {
      // If already selected, remove it
      setCheckedRates((prevRates) =>
        prevRates.filter((_, index) => index !== existingIndex)
      );
      return;
    }

    const productRates = checkedRates.filter(
      (item) => item.productId === productId
    );
    const otherProductRates = checkedRates.filter(
      (item) => item.productId !== productId
    );
    const totalSelections = checkedRates.length;

    if (productRates.length >= 3) {
      customToast.error(
        'You can select a maximum of 3 rates for a single product.'
      );
      return;
    }

    if (productRates.length > 0 && otherProductRates.length > 0) {
      customToast.error(
        'You can only select multiple rates from a single product or single rates from multiple products.'
      );
      return;
    }

    if (totalSelections >= 3) {
      customToast.error(
        'You can select a maximum of 3 rates across all products.'
      );
      return;
    }

    // If all checks pass, add the new rate
    setCheckedRates((prevRates) => [...prevRates, { productId, rateId }]);
  };
  return (
    <>
      {isMortgageQuoteActive && mortgageQuoteData ? (
        <MortageQuote
          setIsMortgageQuoteActive={setIsMortgageQuoteActive}
          quoteData={mortgageQuoteData}
          consumerDetails={{
            name: formData?.borrowerName,
            emailId: formData?.emailId,
          }}
          setBreadCrumbs={setBreadCrumbs}
        />
      ) : (
        <div className="h-[78vh] w-[100%] flex flex-col justify-center">
          <div className=" h-[100%] w-[100%] self-center flex flex-col gap-2 p-3 bg-white">
            <div className="h-fit w-[100%] flex flex-row justify-between border-b-2 border-neutral100 p-1">
              <div className="w-fit flex flex-row gap-3">
                <p className="p-xs-regular text-primary500">Finance</p>

                <p className="p-xs-semibold">${formData?.loanAmount}</p>
                <p className="p-xs-regular border-r-2 border-neutral100 pr-2">
                  Loan Amount at {formData?.ltv} LTV -{' '}
                  {formData?.loanTermsType.name} Years
                </p>
                <p className="p-xs-semibold">Adjustment</p>
                <p className="p-xs-semibold">{productIds?.length} Selected</p>
              </div>
            </div>

            <div className="h-fit w-[100%] flex flex-row justify-end p-2">
              <div>
                <Button
                  variant="primary"
                  onClick={handleCompareOffer}
                  type="button"
                  btnClassName="bg-primary500 hover:bg-primary600"
                >
                  {isCompareProductsLoading ? (
                    <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                      <Loader loaderText="Compare" FullScreen={false} />
                    </div>
                  ) : (
                    <div className="w-[100%] flex flex-row gap-2">
                      <p>Compare</p>
                      {productIds?.length > 0 && (
                        <p className="rounded-full bg-white px-2 text-primary500">
                          {productIds?.length}
                        </p>
                      )}
                    </div>
                  )}
                </Button>
              </div>
            </div>
            <div className="h-[100%] w-[100%] overflow-y-scroll scrollbarHidden">
              {
                <table className="w-[100%] border-separate border-spacing-0">
                  <tr className="h-12 w-[100%] text-center bg-chatbot_bg p-xs-semibold">
                    <td className="w-[25%] p-2 text-start">Product</td>
                    <td colSpan={1} className="w-[10%] break-words px-2">
                      Rate/APR
                    </td>
                    <td colSpan={1} className="w-[10%] break-words px-2">
                      Discounts PTS
                    </td>
                    <td colSpan={1} className="w-[8%] break-words">
                      Broker comp-LP
                    </td>
                    <td colSpan={1} className="w-[10%] break-words px-2">
                      EST Total Fees
                    </td>
                    <td colSpan={1} className="w-[10%] break-words px-2">
                      MI
                    </td>
                    <td colSpan={1} className="w-[10%] break-words px-2">
                      Monthly Payment
                    </td>
                    <td className="w-[15%]">Action</td>
                  </tr>
                  {productData?.map((item, index) => {
                    const more_rates_array = item?.more_rates.map(
                      (pts, index) => {
                        return pts;
                      }
                    );
                    const closestToZeroPositive = (arr) => {
                      if (!arr) return null;
                      return arr?.reduce((acc, current) => {
                        if (
                          !acc ||
                          (current.discount_point > 0 &&
                            (Math.abs(current.discount_point) <
                              Math.abs(acc.discount_point) ||
                              acc.discount_point < 0))
                        ) {
                          return current;
                        }
                        return acc;
                      }, null);
                    };
                    return (
                      <>
                        <motion.tr
                          className="h-16 w-[100%] text-center bg-[#FAFAFA] p-xs-semibold"
                          key={animateKey}
                          initial={{
                            y: -10,
                          }}
                          animate={{
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotate: 0,
                          }}
                        >
                          <td className="w-[25%] p-3 text-start">
                            <div className="flex flex-row gap-4">
                              <div>
                                <Checkbox
                                  checked={checkedRates?.some(
                                    (rate) => rate.productId === item.product_id
                                  )}
                                  onClick={() =>
                                    handleParentCheckboxChange(item.product_id)
                                  }
                                />
                              </div>

                              <div className="h-fit flex flex-col">
                                <p>{item.product_name}</p>
                                <p className=" text-disabled">
                                  {item.updated_at
                                    ? FormatTimeDifference(item.updated_at)
                                    : 'Not Updated'}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td
                            colSpan={1}
                            className="w-[10%] break-words px-2 cursor-pointer"
                            onClick={() => {
                              setSelectedRow((prev) =>
                                prev.includes(index)
                                  ? prev.filter((val) => val !== index)
                                  : [...prev, index]
                              );
                            }}
                          >
                            <div className="h-fit flex flex-col">
                              <p>{item.rate} %</p>
                              <p className=" text-disabled">{item.APR} APR</p>
                              <div className="h-fit flex flex-col justify-center">
                                {!selectedRow.includes(index) ? (
                                  <img
                                    className={`w-[10px] h-[10px] self-center`}
                                    src={angleDown}
                                    alt="^"
                                  />
                                ) : (
                                  <motion.img
                                    initial={{
                                      y: -10,
                                    }}
                                    animate={{
                                      y: 0,
                                    }}
                                    className={`w-[8px] h-[8px] self-center`}
                                    src={arrowDownBlue}
                                    alt="^"
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td colSpan={1} className="w-[10%] break-words px-2">
                            <div className="h-fit flex flex-col">
                              <p>{item?.discount_percent} %</p>
                              <p className=" text-disabled">
                                $ {item?.discount_amount}
                              </p>
                            </div>
                          </td>
                          <td colSpan={1} className="w-[10%] break-words">
                            <div className="h-fit flex justify-center items-center flex-col">
                              <p>{item.broker_compensation_percent} %</p>
                              <p className=" text-disabled">
                                $ {item.broker_compensation_amount}
                              </p>
                            </div>
                          </td>
                          <td
                            colSpan={1}
                            className="w-[10%] break-words px-2 cursor-pointer"
                            onClick={() => {
                              setSelectedRow((prev) =>
                                prev.includes(index)
                                  ? prev.filter((val) => val !== index)
                                  : [...prev, index]
                              );
                            }}
                          >
                            <div className="h-fit flex flex-col items-center">
                              <p>{`$${item.estimated_total_fees}`}</p>
                              <p
                                className={`text-disabled self-start text-start`}
                              >
                                Closing Cost
                              </p>
                              {!selectedRow.includes(index) ? (
                                <img
                                  className={`w-[10px] h-[10px] self-center`}
                                  src={angleDown}
                                  alt="^"
                                />
                              ) : (
                                <motion.img
                                  initial={{
                                    y: -10,
                                  }}
                                  animate={{
                                    y: 0,
                                  }}
                                  className={`w-[8px] h-[8px] self-center`}
                                  src={arrowDownBlue}
                                  alt="^"
                                />
                              )}
                            </div>
                          </td>
                          <td colSpan={1} className="w-[10%] break-words px-2">
                            <div className="h-fit flex flex-col">
                              <p>{`$${item.MI}`}</p>
                              <p className=" text-disabled">{'monthly'}</p>
                            </div>
                          </td>
                          <td
                            colSpan={1}
                            className="w-[10%] break-words px-2 cursor-pointer"
                            onClick={() => {
                              setSelectedRow((prev) =>
                                prev.includes(index)
                                  ? prev.filter((val) => val !== index)
                                  : [...prev, index]
                              );
                            }}
                          >
                            <div className="h-fit flex flex-col">
                              <p>{`$${item.monthly_payment}`}</p>
                              <p className=" text-disabled">
                                PITI ${item.PITI}
                              </p>
                            </div>
                          </td>
                          <td className="w-[15%]">
                            <div className="h-fit flex flex-col justify-center">
                              <Button
                                variant=""
                                disabled={
                                  checkedRates?.length > 1 ? true : false
                                }
                                onClick={() => {
                                  setLeadsPopup(true);
                                }}
                                type="button"
                                btnClassName={`${checkedRates?.length > 1 ? 'cursor-not-allowed' : 'cursor-pointer'} self-center border-2 border-disabled text-disabled px-3 rounded-md py-1 text-[#5A5555]`}
                              >
                                Create Profile
                              </Button>
                            </div>
                          </td>
                        </motion.tr>
                        {selectedRow.includes(index) && (
                          <>
                            <td colSpan={8}>
                              <table className="w-full border-separate border-spacing-x-1 mt-2">
                                <tr
                                  key={`header-${index}`}
                                  className="text-[12px] font-bold bg-white"
                                >
                                  <td
                                    colSpan={1}
                                    className="p-2 px-3 text-start border-x border-t border-neutral100 rounded-t-md "
                                  >
                                    Guidelines
                                    <div className="border-b pb-4 border-neutral100 w-[100%] flex justify-center"></div>
                                  </td>

                                  <td
                                    colSpan={3}
                                    className="p-2 text-center border-x border-t border-neutral100 rounded-t-md"
                                  >
                                    More Rates
                                    <div className="border-b pb-4 border-neutral100 w-[100%] flex justify-center"></div>
                                  </td>

                                  <td
                                    colSpan={4}
                                    className="p-2 text-center border-x border-t border-neutral100 rounded-t-md"
                                  >
                                    Adjustments
                                    <div className="border-b pb-4 border-neutral100 w-[100%] flex justify-center"></div>
                                  </td>
                                </tr>
                                <tr
                                  key={`subheader-${index}`}
                                  className="text-[12px] font-bold "
                                >
                                  <td
                                    colSpan={1}
                                    className="p-2 px-3 text-start font-semibold border-x border-neutral100 "
                                  >
                                    -
                                  </td>
                                  <td
                                    colSpan={1}
                                    className="p-2 text-center border-l border-neutral100"
                                  >
                                    <div className="flex flex-row gap-1">
                                      {/* <Checkbox /> */}
                                      <div className="h-fit flex flex-col">
                                        <p>Rate/APR</p>
                                      </div>
                                    </div>
                                  </td>
                                  <td colSpan={1} className="p-2 text-center ">
                                    Discount Points
                                  </td>
                                  <td
                                    colSpan={1}
                                    className="p-2 text-center border-r border-neutral100"
                                  >
                                    Mo. P+I
                                  </td>
                                  <td
                                    colSpan={1}
                                    className="p-2 text-center border-l border-neutral100"
                                  >
                                    Factor
                                  </td>
                                  <td colSpan={1} className="p-2 text-center">
                                    Rate
                                  </td>
                                  <td colSpan={1} className="p-2 text-center">
                                    Margin
                                  </td>
                                  <td
                                    colSpan={1}
                                    className="p-2 text-center border-r border-neutral100"
                                  >
                                    Points
                                  </td>
                                </tr>

                                {item?.more_rates &&
                                  item?.adjustments &&
                                  item?.more_rates.map(
                                    (moreRate, rateIndex) => {
                                      const isLastElement =
                                        rateIndex ===
                                        item.more_rates.length - 1;
                                      const isLastAdj =
                                        rateIndex ===
                                        item.adjustments.length - 1;
                                      return (
                                        <motion.tr
                                          key={`more_rates-${index}-${rateIndex}`}
                                          className="p-xs-regular"
                                          initial={{ y: -10 }}
                                          animate={{
                                            x: 0,
                                            y: 0,
                                            scale: 1,
                                            rotate: 0,
                                          }}
                                        >
                                          <td
                                            colSpan={1}
                                            className={`p-2 px-3 text-start font-semibold border-x border-neutral100`}
                                          >
                                            {moreRate.guideline}
                                          </td>
                                          <td
                                            colSpan={1}
                                            className={`p-2 text-center border-l border-neutral100`}
                                          >
                                            <div className="flex flex-row gap-3">
                                              <Checkbox
                                                checked={checkedRates?.some(
                                                  (rate) =>
                                                    rate.productId ===
                                                      item?.product_id &&
                                                    rate.rateId ===
                                                      moreRate.rate_id
                                                )}
                                                onClick={() =>
                                                  handleCheckboxChange(
                                                    item?.product_id,
                                                    moreRate.rate_id
                                                  )
                                                }
                                              />
                                              <div className="h-fit flex text-nowrap">
                                                <p>
                                                  {moreRate.rate}% /{' '}
                                                  {moreRate.APR}%
                                                </p>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            colSpan={1}
                                            className={`p-2 text-center text-nowrap border-neutral100`}
                                          >
                                            <div>
                                              <p>{moreRate.discount_point} %</p>
                                              <p className=" text-disabled">
                                                $ {moreRate.discount_amount}
                                              </p>
                                            </div>
                                          </td>
                                          <td
                                            colSpan={1}
                                            className={`p-2 text-center text-nowrap border-r border-neutral100`}
                                          >{`$ ${moreRate.p_and_i}`}</td>

                                          {/* Adjustments Data */}
                                          {item?.adjustments[rateIndex] && (
                                            <>
                                              <td
                                                colSpan={1}
                                                className={`p-2 text-start border-neutral100 ${item?.adjustments[rateIndex].factor.includes('Final Price') ? 'p-xs-bold' : ''} ${isLastAdj ? 'rounded-bl-md border-b' : ''} border-l border-neutral100`}
                                              >
                                                {
                                                  item?.adjustments[rateIndex]
                                                    .factor
                                                }
                                              </td>
                                              <td
                                                colSpan={1}
                                                className={`p-2 text-center ${isLastAdj ? 'border-b' : ''}  border-neutral100 ${item?.adjustments[rateIndex].factor.includes('Final Price') ? 'p-xs-bold' : ''}`}
                                              >
                                                {
                                                  item?.adjustments[rateIndex]
                                                    .rate
                                                }
                                              </td>
                                              <td
                                                colSpan={1}
                                                className={`p-2 text-center  ${isLastAdj ? 'border-b ' : ''}  border-neutral100 ${item?.adjustments[rateIndex].factor.includes('Final Price') ? 'p-xs-bold' : ''}`}
                                              >
                                                {
                                                  item?.adjustments[rateIndex]
                                                    .margin
                                                }
                                              </td>
                                              <td
                                                colSpan={1}
                                                className={`p-2 text-center  ${item?.adjustments[rateIndex].factor.includes('Final Price') ? 'p-xs-bold' : ''} ${isLastAdj ? 'rounded-br-md border-b' : ''} border-r border-neutral100`}
                                              >
                                                {
                                                  item?.adjustments[rateIndex]
                                                    .points
                                                }
                                              </td>
                                            </>
                                          )}
                                        </motion.tr>
                                      );
                                    }
                                  )}
                                <tr>
                                  <td
                                    className="border-b border-x border-neutral100 rounded-b-md h-2"
                                    colSpan={1}
                                  ></td>
                                  <td
                                    className="border-b border-x border-neutral100 rounded-b-md h-2"
                                    colSpan={3}
                                  ></td>
                                </tr>
                              </table>
                            </td>
                          </>
                        )}
                      </>
                    );
                  })}
                </table>
              }
            </div>
          </div>
        </div>
      )}
      {leadsPopup && (
        <AddLeads leadsPopup={leadsPopup} setLeadsPopup={setLeadsPopup} />
      )}
    </>
  );
};

export default ManageScenarios;
