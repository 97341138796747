import React from 'react';

function Document({ color = '#CC7914' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      fill="none"
      viewBox="0 0 13 16"
    >
      <path
        fill={color}
        d="M3.2 11.2h6.4v1.6H3.2v-1.6zm0-3.2h6.4v1.6H3.2V8zM8 0H1.6C.72 0 0 .72 0 1.6v12.8c0 .88.712 1.6 1.592 1.6H11.2c.88 0 1.6-.72 1.6-1.6V4.8L8 0zm3.2 14.4H1.6V1.6h5.6v4h4v8.8z"
      ></path>
    </svg>
  );
}

export default Document;
