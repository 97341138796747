import React from 'react';
/**
 * Renders a custom select component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isError - Indicates if there is an error with the select.
 * @param {ReactNode} props.children - The options to be rendered inside the select.
 * @returns {ReactNode} The rendered select component.
 * @param {...any} props.props - Additional props to be spread onto the select element.
 */
const Select = ({ readOnly, isError, children, ...props }) => {
  return (
    <select
      className={`appearance-null cursor-pointer bg-gray-50 ${readOnly ? 'pointer-events-none' : ''} ${
        isError ? 'border-error-red' : 'border-grey'
      } ${
        props?.value ? 'text-black' : 'text-disabled'
      } border-2 text-sm rounded-lg ${
        isError
          ? 'focus-visible:outline-error-red'
          : 'focus-visible:outline-grey'
      } focus-visible:${isError ? 'border-error-red' : 'border-green'}  ${
        isError ? 'focus:border-error-red' : 'focus:border-green'
      } block w-full disabled:bg-frameDisabled p-xs-regular p-2.5 leading-none truncate`}
      {...props}
    >
      {children}
    </select>
  );
};

export default Select;
