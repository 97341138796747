import React, { Suspense } from 'react';
import Login from 'pages/login/Login';
import LoanInformation from 'pages/loan-information';
import NotFound from 'components/404';
import BorrowerProfile from 'pages/borrower-profile';
import PipelineAdmin from 'pages/pipeline-admin';
import Admin from 'pages/admin';
import Dashboard from 'pages/dashboard-admin';
import Reports from 'pages/reports-admin/reports';
import Tools from 'pages/tools-admin';
import QuickPrice from 'pages/quickPrice';
const adminRoutes = [
  {
    path: '/',
    element: <Login />,
    children: [
      {
        path: 'dashboard',
        element: (
          <Suspense>
            <Dashboard />
          </Suspense>
        ),
        children: [],
      },
      {
        path: 'loanInformation',
        element: (
          <Suspense>
            <LoanInformation />
          </Suspense>
        ),
        children: [],
      },
      {
        path: ':param/lead',
        element: (
          <Suspense>
            <BorrowerProfile />
          </Suspense>
        ),
      },
      {
        path: 'pipeline',
        element: (
          <Suspense>
            <PipelineAdmin />
          </Suspense>
        ),
      },
      {
        path: 'reports',
        element: (
          <Suspense>
            <Reports />
          </Suspense>
        ),
        children: [],
      },
      {
        path: 'tools',
        element: (
          <Suspense>
            <Tools />
          </Suspense>
        ),
      },
      {
        path: 'quickPrice',
        element: (
          <Suspense>
            <QuickPrice />
          </Suspense>
        ),
      },
      {
        path: 'admin',
        element: (
          <Suspense>
            <Admin />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
];

export default adminRoutes;
