import React from 'react';
import { useState, useEffect } from 'react';
//components
import Modal from 'components/modal';
import InputGroup from 'components/input-group';
import Button from 'components/button';
import { customToast } from 'components/toast';

//assets
import closeIcon from 'assets/icons/closeIcon.svg';

//RTK queries
import { useDeleteDocumentMutation } from 'redux_store/services/admin/document';

//logs
import * as Sentry from '@sentry/browser';
/**
 * Function: DeleteDocumentPopup
 *
 * Description: This function renders a modal popup for deleting a document. It takes in the necessary data and functions to handle the deletion process.
 *
 * @param {Object} deletePopup - Boolean value to control the visibility of the delete popup.
 * @param {Function} setDeletePopup - Function to set the visibility of the delete popup.
 * @param {Object} data - Data object containing information about the document to be deleted.
 * @param {Function} resetState - Function to reset the state after deleting the document.
 * @param {string} userId - The ID of the user who owns the document.
 * @param {string} documentId - The ID of the document to be deleted.
 * @param {Function} setDocumentId - Function to set the document ID.
 *
 * @returns {JSX.Element} A modal component displaying the delete document popup with input fields and buttons for confirmation.
 */
const DeleteDocumentPopup = ({
  deletePopup,
  setDeletePopup,
  data,
  resetState,
  userId,
  documentId,
  setDocumentId,
}) => {
  //states
  const [input, setInput] = useState('');
  const [error, setError] = useState(false);

  //delete document RTK Query
  const [deleteDocument] = useDeleteDocumentMutation();

  //handle button trigger
  const handleSubmit = async () => {
    if (input !== 'Delete') {
      setError(true);
    } else {
      setError(false);
      try {
        const response = await deleteDocument({
          documentId: documentId
            ? documentId
            : data?.documents[0]?.userDocumentId,
          userId: userId,
        });
        if (response?.data?.status === 200) {
          setDeletePopup(false);
          setDocumentId(null);
          customToast.success(`${data?.name} Document deleted successfully`);
          resetState();
        } else {
          customToast.error(`Failed to delete ${data?.name} Document`);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  // update error state
  useEffect(() => {
    if (input === 'Delete') {
      setError(false);
    }
  }, [input]);

  return (
    <Modal show={deletePopup} width="max-w-[392px]">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div className="p-sm-bold mb-4 w-[15rem]">
            Delete {data?.name} document
          </div>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-3"
            onClick={() => {
              setDeletePopup(false);
              resetState();
            }}
          />
        </div>
        <p className="p-xs-regular mb-2">
          You are about to delete{' '}
          <span className="p-xs-bold">{data?.name} document</span>. This
          document will no longer be available on the portal and you will have
          to upload the document again.
        </p>
        <div>
          <label className={`p-xs-bold text-custom-greengray mb-1`}>
            Delete Document
            <span className="text-error pl-1">*</span>
          </label>
          <InputGroup
            onChange={(e) => {
              const filteredValue = e.target.value.replace(/[^a-zA-Z]/g, '');
              setInput(filteredValue);
            }}
            value={input}
            isError={error}
            size="sm"
            placeholder="Enter Delete to remove the document"
          />
          {error && (
            <p className="text-error-red text-xs font-normal pt-1 h-[24px]">
              Incorrect input. Please check and try again.
            </p>
          )}
        </div>
        <div className="flex justify-end gap-2 mt-12">
          <Button
            variant="tertiary"
            onClick={() => {
              setDeletePopup(false);
              resetState();
            }}
          >
            Cancel
          </Button>
          <Button
            variant={input ? 'danger' : 'disabled'}
            onClick={handleSubmit}
            disabled={input ? false : true}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocumentPopup;
