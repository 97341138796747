import React from 'react';
import Card from 'components/card';
import DoughnutGraph from 'components/doughnut';
import Button from 'components/button';
import RightArrow from 'assets/icons/RightArrow';
import { color } from 'framer-motion';

const DocumentManager = ({ docData, documentManager }) => {
  return (
    <Card className="w-1/3 ml-2 p-4 pb-3">
      <h4 className="text-neutral1000 font-bold">Document Manager</h4>
      <DoughnutGraph graphData={documentManager} />
      <div className="flex">
        <div className="flex border-r border-grey p-4">
          <div>
            <h4
              className="font-bold text-xl"
              data-testid="docData-totalCompleted"
            >
              {docData?.totalCompleted}
            </h4>
            <p className="text-sm">
              Document <br /> Uploaded
            </p>
          </div>
        </div>
        <div className="flex p-4">
          <div>
            <h4 className="font-bold text-xl" data-testid="docData-status">
              {docData?.totalPendingAndCompleted - docData?.totalCompleted}
            </h4>
            <p className="text-sm">
              Document <br /> Pending
            </p>
          </div>
        </div>
      </div>
      <Button
        variant="link"
        btnClassName={'flex items-center gap-2'}
        onClick={(e) => {
          navigate('/documents');
        }}
        style={{ fontSize: '14px', color: '#01B23C'}}
      >
        View Documents <RightArrow />{' '}
      </Button>
    </Card>
  );
};

export default DocumentManager;
