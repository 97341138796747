import React, { useEffect, useState } from 'react';
//components
import Button from 'components/button';
import DoughnutGraph from 'components/doughnut';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';

//child components
import CompareOffer from 'pages/borrower-profile/product-pricing/CompareOffer.jsx';
import ClosingCostForm from 'pages/borrower-profile/product-pricing/EditClosingCostRawForm.jsx';
//libs
import * as Sentry from '@sentry/browser';
//utils
import { formatNumberIntoCurrency } from 'constants/formatNumber';
//RTL query
import { useSendQuoteMutation } from 'redux_store/services/admin/quickPrice';
import { downloadQuickPriceQuote } from 'redux_store/services/documentDownloads';

//custom hook
import useDownloadPDFHandler from 'hooks/pdfViewer';

const MortgageQuote = ({
  setBreadCrumbs,
  setIsMortgageQuoteActive,
  quoteData = null,
  consumerDetails,
}) => {
  // States
  const [compareOfferVisibility, setCompareOfferVisibility] = useState(false);
  const [allQuotes, setAllQuotes] = useState(quoteData);

  //RTK query
  const [sendQuote, { isLoading: sendQuoteLoading }] = useSendQuoteMutation();

  const products = quoteData?.quote_details?.product_details?.map((item) => {
    const rateObj = item.children.find((child) => child.key === 'Rate');
    return {
      product_id: item.product_id,
      rate: rateObj ? rateObj.value : 0,
    };
  });
  //custom hook to view/download pdf
  const { downloadPDF } = useDownloadPDFHandler();
  // update format to match send and download payload
  const transformData = (data) => {
    return data?.map((item) => ({
      title: item.heading,
      quote_data: item.children.map((child) => ({
        key: child.key,
        value: child.value,
        unit: child.unit,
      })),
      fees_data: [],
    }));
  };
  //download quote
  const handleDownloadQuote = async () => {
    const payload = transformData(allQuotes?.quote_details?.product_details);
    try {
      customToast.promise(
        downloadPDF(
          downloadQuickPriceQuote,
          {
            data: payload,
          },
          { download: true }
        ),
        'Downloading your file',
        'Downloaded successfully'
      );
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error downloading the PDF:', error);
    }
  };

  //send quote as mail
  const handleSendMail = async () => {
    const payload = transformData(allQuotes?.quote_details?.product_details);
    try {
      const response = await sendQuote({
        data: payload,
        name: consumerDetails.name,
        emailId: consumerDetails.emailId,
      });
      if (response?.data?.status === 200) {
        customToast.success(response?.data?.message);
      } else {
        customToast.error('Failed to send quote');
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const uniqueKeys = new Set();
  allQuotes?.quote_details?.product_details?.forEach((child) => {
    child.children?.forEach((grandChild) => {
      uniqueKeys.add(grandChild.key);
    });
  });
  const daysMapper = (unit, value) => {
    if (unit === 'days' && value === 1) {
      return `${value} day`;
    }
    if (unit === 'year(s)' && value === 1) {
      return `${value} year`;
    }
    if (value > 1) {
      return `${value} ${unit}`;
    }
  };

  const valueMapper = (value) => {
    if (value.unit === '$') {
      return (
        <div className="flex flex-row justify-center min-w-[30%]">
          <p className="p-xs-semibold text-secondary900 w-[100%] text-start">
            {`${formatNumberIntoCurrency(value.value)}`}
          </p>
        </div>
      );
    }
    if (value.unit === '%') {
      return (
        <div className="flex flex-row justify-start min-w-[30%]">
          <p className="p-xs-semibold text-secondary900 w-fit flex flex-row gap-4">
            <div className="w-fit p-2 bg-secondary100 flex flex-row gap-2 rounded-md">
              <p className="text-secondary600">{value.value.toFixed(3)}</p>
              {value.unit}
            </div>
          </p>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row justify-start min-w-[30%]">
          <p className="p-xs-semibold text-secondary900 flex flex-row gap-3">
            {daysMapper(value.unit, value.value)}
          </p>
        </div>
      );
    }
  };
  const uniqueKeysArray = Array.from(uniqueKeys);
  return (
    <>
      <div className="h-[85vh] w-[100%] flex flex-col justify-center">
        <div className=" h-[100%] w-[100%] self-center flex flex-col gap-3 bg-white">
          <div className="h-fit w-[100%] flex flex-row justify-between p-2">
            <div className="w-fit flex flex-row gap-4">
              <h1 className="p-md-bold">Mortgage Quote</h1>
            </div>
            <div className="flex flex-row gap-3">
              {/* <Button
                variant={`${quoteData?.quote_details?.product_details.length >= 3 ? 'tertiary_sm' : 'primary'}`}
                onClick={() => setCompareOfferVisibility(true)}
                type="button"
                btnClassName={`${quoteData?.quote_details?.product_details.length >= 3 ? 'cursor-not-allowed' : ''}`}
                disabled={
                  quoteData?.quote_details?.product_details.length >= 3
                    ? true
                    : false
                }
              >
                Compare Offer
              </Button> */}
            </div>
          </div>
          {/* main quote mapping div */}
          <div className="h-[100%] w-[100%] overflow-y-scroll">
            <table className="w-[100%]">
              <tr className="test-start">
                <td className="min-w-[25%] w-[25%] max-w-[25%]"></td>
                {allQuotes?.quote_details?.product_details?.map(
                  (item, index) => {
                    return (
                      <>
                        <td
                          className="border border-[#F5F5F5]  text-start"
                          key={index}
                        >
                          <div className="h-fit w-[100%] flex flex-col">
                            <div className="flex flex-col justify-center items-center p-3 bg-chatbot_bg h-[65px]">
                              <p className="p-xs-bold text-start text-secondary900">
                                {item?.heading ?? '-'}
                              </p>
                            </div>
                            <div className="self-center w-[300px]">
                              <DoughnutGraph
                                graphData={[
                                  {
                                    title: 'P&I: ',
                                    //value: 10,
                                    value: item?.children[1]?.value ?? 0,
                                    color: '#A4D9D9',
                                    unit: item?.children[1]?.unit ?? '',
                                  },
                                  {
                                    title: 'PMI: ',
                                    //value: 10,
                                    value: item?.children[0]?.value ?? 0,
                                    color: '#AFBEFF',
                                    unit: item?.children[0]?.unit ?? '',
                                  },
                                  {
                                    title: 'Taxes: ',
                                    //value: 10,
                                    value: item?.children[2]?.value ?? 0,
                                    color: '#3F66FB',
                                    unit: item?.children[2]?.unit ?? '',
                                  },
                                  {
                                    title: 'HAZ Ins: ',
                                    //value: 10,
                                    value: item?.children[3]?.value ?? 0,
                                    color: '#FFDAB9',
                                    unit: item?.children[3]?.unit ?? '',
                                  },
                                ]}
                                position={'bottom'}
                              />
                            </div>
                          </div>
                        </td>
                      </>
                    );
                  }
                )}
              </tr>
              {uniqueKeysArray.slice(4)?.map((key, idx) => (
                <tr key={idx}>
                  <td className="min-w-[25%] w-[25%] max-w-[25%] border border-[#F5F5F5] p-4">
                    <div className="flex flex-row gap-3">
                      <p className="p-xs-semibold text-secondary900">{key}</p>
                    </div>
                  </td>
                  {allQuotes?.quote_details?.product_details?.map(
                    (child, childIndex) => {
                      const grandChild = child.children?.find(
                        (gc) => gc.key === key
                      );
                      return (
                        <td
                          key={`value-${childIndex}`}
                          className="border border-[#F5F5F5] p-4"
                        >
                          <div className="flex flex-row justify-center">
                            {valueMapper(grandChild)}
                          </div>
                        </td>
                      );
                    }
                  )}
                </tr>
              ))}
            </table>
          </div>
          <div className="h-fit w-[100%] flex flex-row justify-end p-2">
            <div className="h-[100%] flex flex-row justify-end gap-5">
              <Button
                variant="white_sm"
                onClick={() => {
                  setIsMortgageQuoteActive(false);
                  setBreadCrumbs([
                    {
                      name: 'Dashboard',
                      path: '/dashboard',
                    },
                    {
                      name: 'Quick Price',
                      path: '/quickPrice',
                    },
                    {
                      name: 'Manage Scenario',
                      path: '/quickPrice',
                    },
                  ]);
                }}
                type="button"
                btnClassName="hover:border hover:border-error200 hover:text-error200"
              >
                Back
              </Button>
              <Button
                variant="white_sm"
                onClick={handleDownloadQuote}
                type="button"
                btnClassName="hover:border hover:border-primary500 hover:text-primary500"
              >
                Download
              </Button>
              <Button
                variant="primary"
                onClick={handleSendMail}
                type="button"
                btnClassName="rounded-md"
              >
                {sendQuoteLoading ? (
                  <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                    <Loader loaderText="Sending" FullScreen={false} />
                  </div>
                ) : (
                  'Send'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {compareOfferVisibility && (
        <CompareOffer
          setCompareOfferVisibility={setCompareOfferVisibility}
          setAllQuotes={setAllQuotes}
          products={products}
        />
      )}
    </>
  );
};

export default MortgageQuote;
