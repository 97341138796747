import React from 'react';
import { useState, useEffect } from 'react';
//components
import Modal from 'components/modal';
import InputGroup from 'components/input-group';
import Button from 'components/button';
import DragAndDrop from 'components/drag-and-drop';
import Progressbar from 'components/progressbar';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
//assets
import closeIcon from 'assets/icons/closeIcon.svg';
import fileIcon from 'assets/icons/file.svg';
import deleteIcon from 'assets/icons/delete.svg';
import downloadIcon from 'assets/icons/download.svg';

//logs
import * as Sentry from '@sentry/browser';

//custom hook
import useDownloadPDFHandler from 'hooks/pdfViewer';

//API queries
import {
  useUploadPredefinedDocsMutation,
  useUpdatePredefinedDocsMutation,
} from 'redux_store/services/client/document';
import { fetchClientDocument } from 'redux_store/services/documentDownloads';

//utils
import { convertUnixToCustomDate } from 'constants/unixToDate';

/**
 * Function to handle the upload document popup functionality.
 *
 * @param {Object} uploadPopup - Boolean to control the visibility of the upload popup.
 * @param {Function} setUploadPopup - Function to set the visibility of the upload popup.
 * @param {Object} data - Selected document data.
 * @param {Boolean} isAddNewDocument - Boolean to indicate if it's adding a new document.
 * @param {Number} applicationId - ID of the application.
 * @param {Function} resetState - Function to reset the state.
 * @param {Number} userProfileId - ID of the user profile.
 * @param {Number} userId - ID of the user (default is 0).
 * @returns {JSX.Element} - Returns the JSX element for the upload document popup.
 */

const UploadDocumentPopup = ({
  uploadPopup,
  setUploadPopup,
  data: selectedDoc,
  isAddNewDocument,
  applicationId,
  resetState,
  userProfileId,
  userId = 0,
}) => {
  //states
  const [files, setFiles] = useState(null);
  const [input, setInput] = useState('');
  const [error, setError] = useState(false);

  //custom hook to download/view pdf
  const { downloadPDF } = useDownloadPDFHandler();

  //API query
  const [uploadPredefinedDocument, { isLoading }] =
    useUploadPredefinedDocsMutation();
  const [updatePredefinedDocument, { isLoading: isUpdateLoading }] =
    useUpdatePredefinedDocsMutation();

  //handle file submit (POST/PUT)
  const handleSubmit = async () => {
    const documentParams = {
      applicationId: applicationId,
      documentTypeId: input ? '' : selectedDoc?.documentTypeId,
      documentSubTypeId: input ? '' : selectedDoc?.documentSubTypeId,
      ...(input ? { documentTitle: input } : {}),
      userProfileId: userProfileId,
      userId: userId,
    };
    //upload file from table without custom title
    if (
      input === '' &&
      files &&
      !selectedDoc?.userDocumentId &&
      !isAddNewDocument
    ) {
      setError(true);
      try {
        const response = await uploadPredefinedDocument({
          data: files[0],
          additionalParams: documentParams,
        });

        if (response?.data?.status === 200) {
          setUploadPopup(false);
          customToast.success(
            `${selectedDoc.name} document uploaded successfully`
          );
        } else {
          customToast.error(`Failed to upload ${selectedDoc.name} Document`);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
      //update file from table without custom title
    } else if (
      input === '' &&
      files &&
      selectedDoc?.userDocumentId &&
      !isAddNewDocument
    ) {
      setError(true);
      try {
        const response = await updatePredefinedDocument({
          data: files[0],
          additionalParams: { applicationId: applicationId },
          documentId: selectedDoc?.userDocumentId,
        });

        if (response?.data?.status === 200) {
          setUploadPopup(false);
          customToast.success(
            `${selectedDoc.name} document uploaded successfully`
          );
        } else {
          customToast.error(`Failed to upload ${selectedDoc.name} Document`);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
      //update file for misc
    } else if (selectedDoc?.documentTypeId === 7 && !isAddNewDocument) {
      setError(true);
      try {
        const response = await updatePredefinedDocument({
          data: files[0],
          additionalParams: {
            applicationId: applicationId,
            documentTitle: selectedDoc?.name,
          },
          documentId: selectedDoc?.userDocumentId,
        });

        if (response?.data?.status === 200) {
          setUploadPopup(false);
          customToast.success(
            `${selectedDoc.name} document uploaded successfully`
          );
        } else {
          customToast.error(`Failed to upload ${selectedDoc.name} Document`);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
      //upload misc file
    } else if (input && isAddNewDocument) {
      try {
        const response = await uploadPredefinedDocument({
          data: files[0],
          additionalParams: documentParams,
        });

        if (response?.data?.status === 200) {
          setUploadPopup(false);
          customToast.success(`${input} document uploaded successfully`);
        } else {
          customToast.error(`Failed to upload ${input} Document`);
        }
      } catch (e) {}
    } else {
      setError(true);
    }
  };

  // update error
  useEffect(() => {
    if (input !== '') {
      setError(false);
    }
  }, [input]);

  // handle pdf download
  const handleDownload = (documentId) => {
    try {
      customToast.promise(
        downloadPDF(
          fetchClientDocument,
          {
            documentId: documentId,
          },
          { download: true }
        ),
        'Downloading your file',
        'Downloaded successfully'
      );
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error downloading the PDF:', error);
    }
  };

  const isValid = () => {
    return (
      files &&
      [
        'application/pdf',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
      ].includes(files[0]?.type) &&
      (files[0]?.size / 1048576).toFixed(2) < 10
    );
  };

  return (
    <Modal show={uploadPopup} width="max-w-[392px]">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div className="p-sm-bold mb-3 w-[15rem]">
            {isAddNewDocument
              ? 'Upload a document'
              : `${selectedDoc?.userDocumentId ? 'Update' : 'Upload'} ${selectedDoc.name} document`}
          </div>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-3"
            onClick={() => {
              setUploadPopup(false);
              resetState();
            }}
          />
        </div>
        <p className="font-medium mb-2 text-disabled">
          Drag and drop or attach file
        </p>
        {isAddNewDocument && (
          <div className="my-3">
            <label className={`p-xs-bold text-custom-greengray mb-1`}>
              Document Name
              <span className="text-error pl-1">*</span>
            </label>
            <InputGroup
              onChange={(e) => {
                const filteredValue = e.target.value.replace(
                  /[^a-zA-Z0-9 ]/g,
                  ''
                );
                setInput(filteredValue);
              }}
              value={input}
              isError={error}
              size="sm"
              placeholder="Enter name of the document"
            />
            {error && (
              <p className="text-error-red text-xs font-normal pt-1 h-[24px]">
                Document name cannot be empty
              </p>
            )}
          </div>
        )}

        {!files && (
          <DragAndDrop
            onFilesChange={setFiles}
            currentFile={files}
            textElement={
              <p className="m-2 text-sm text-gray-500 dark:text-gray-400 text-center p-2">
                <span className="text-custom-green font-semibold">Click to upload</span>{' '}
                or Drag & Drop PDF, JPEG, JPG or PNG document (Max 10 MB)
              </p>
            }
          />
        )}

        {files && (
          <div
            className={` rounded-md ${isValid() ? 'border-grey border' : 'border-danger100 border-2'}  flex items-center p-3 gap-2`}
          >
            <img src={fileIcon} alt="" />
            <div className="flex-grow">
              <div className="flex justify-between">
                <div className="font-medium-2 truncate overflow-hidden max-w-[252px]">
                  {files[0]?.name}
                </div>
                {[
                  'application/pdf',
                  'image/jpeg',
                  'image/jpg',
                  'image/png',
                  'image/gif',
                ].includes(files[0]?.type) ? (
                  <img
                    src={deleteIcon}
                    alt=""
                    className="cursor-pointer"
                    onClick={() => setFiles(null)}
                  />
                ) : (
                  <img
                    src={closeIcon}
                    alt=""
                    className="cursor-pointer"
                    onClick={() => setFiles(null)}
                  />
                )}
              </div>

              <p className="p-xs-regular text-neutral600">
                {(files[0]?.size / 1048576).toFixed(2)} MB
              </p>

              {[
                'application/pdf',
                'image/jpeg',
                'image/jpg',
                'image/png',
                'image/gif',
              ].includes(files[0]?.type) ? (
                <Progressbar
                  value={100}
                  valueString="100 %"
                  valuePosition="right"
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        )}
        {files && (files[0]?.size / 1048576).toFixed(2) > 10 && (
          <p className="font-medium-2 text-danger100 mt-2 mb-2">
            File size too large. Document size should be below 10 MB.
          </p>
        )}
        {files &&
          ![
            'application/pdf',
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/gif',
          ].includes(files[0]?.type) && (
            <p className="font-medium-2 text-danger100 mt-2 mb-2">
              This file type is not supported. Please check and upload the
              correct file type.
            </p>
          )}
        {selectedDoc?.documents?.length > 0 && (
          <div className="p-sm-bold my-4 w-[15rem]">Uploaded files</div>
        )}
        {selectedDoc?.documents?.map((data, index) => (
          <div
            key={data.userDocumentId}
            className={`rounded-md border-grey border flex items-center p-3 gap-2 mb-4 cursor-pointer hover:bg-chatbot_bg`}
          >
            <img src={fileIcon} alt="" />
            <div className="flex-grow">
              <div className="flex justify-between">
                <div className="font-medium-2 truncate overflow-hidden max-w-[225px]">
                  {data?.name}
                </div>
              </div>
              <p className="p-xs-regular text-neutral600">{data?.size}</p>
              <p className="p-xs-regular text-neutral600">
                Uploaded on &nbsp;
                {convertUnixToCustomDate(data.timestamp, 'MM-dd-yy, KK:mm bb')}
              </p>
            </div>
            <img
              src={downloadIcon}
              alt=""
              onClick={() => {
                handleDownload(data.userDocumentId);
              }}
            />
          </div>
        ))}

        <div className="flex justify-end gap-2 mt-12">
          <Button
            variant="tertiary"
            onClick={() => {
              setUploadPopup(false);
              resetState();
            }}
          >
            Cancel
          </Button>
          <Button
            variant={isValid() ? 'primary' : 'disabled'}
            disabled={
              (!isLoading || !isUpdateLoading) && isValid() ? false : true
            }
            onClick={handleSubmit}
          >
            {isLoading || isUpdateLoading ? (
              <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                <Loader loaderText="Uploading" FullScreen={false} />
              </div>
            ) : (
              'Add'
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadDocumentPopup;
