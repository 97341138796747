/* istanbul ignore file */
import {
  withValidations,
  checkTextInput,
  validatePercentageInput,
  checkAlphanumeric,
} from '../utils';
import { token, parseJwt } from 'auth/auth';

// Loan Information Fields
export const loanInformationData = [
  {
    id: 1,
    name: 'Occupancy',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: true,

    propertyName: 'occupancyTypeId',
  },
  {
    id: 2,
    name: 'Subject Property Address',
    type: 'text',
    placeholder: 'Enter Subject Property Address',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'propertyAddress',
  },
  {
    id: 3,
    type: 'container',
    customClass: 'w-1/3 flex',
    formFields: [
      {
        id: 1,
        name: 'City',
        type: 'text',
        placeholder: 'Enter City',
        customClass: 'w-1/2',
        isRequired: true,

        propertyName: 'cityName',
        validator: checkTextInput,
      },
      {
        id: 2,
        name: 'State',
        type: 'select',
        placeholder: 'Select State',
        customClass: 'w-1/2',
        isRequired: true,
        propertyName: 'stateName',
      },
    ],
  },
  {
    id: 47,
    name: 'County',
    type: 'text',
    placeholder: 'Enter County',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'county',
    validator: checkTextInput,
  },
  {
    id: 4,
    name: 'Zip',
    type: 'number',
    placeholder: 'Enter Zip Code',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'zipCode',
  },

  {
    id: 5,
    name: 'Are you a first-time homebuyer?',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'firstTimeBuying',
    options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
  },
  {
    id: 6,
    name: 'Sales Price',
    type: 'number',
    placeholder: 'Enter Sales Price Amount',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'salePrice',
    isCurrency: true,
  },
  {
    id: 7,
    name: 'Loan Type',
    type: 'select',
    placeholder: 'Select Loan Type',
    customClass: 'w-1/3',
    isRequired: true,

    propertyName: 'loanTypeId',
  },
  {
    id: 8,
    name: 'Down Payment',
    type: 'number',
    placeholder: 'Enter Down Payment Amount',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'downpayment',
    isCurrency: true,
  },
  {
    id: 9,
    name: 'LTV',
    type: 'number',
    placeholder: 'Enter LTV Percentage',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'LTV',
    disabled: true,
  },
  {
    id: 10,
    name: 'Loan Amount',
    type: 'number',
    placeholder: 'Enter Loan Amount',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'loanAmount',
    disabled: true,
  },
  {
    id: 11,
    name: 'Appraised Value',
    type: 'number',
    placeholder: 'Enter Appraised Value',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'appraisedValue',
    disabled: true,
  },
  {
    id: 12,
    name: 'Type of Property',
    type: 'select',
    placeholder: 'Select Property Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'propertyTypeId',
  },
  {
    id: 13,
    name: 'Property Rights',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    propertyName: 'propertyRightsId',
    options: [],
    disabled: () => {
      return parseJwt(token)?.role === 'consumer';
    },
  },
  {
    id: 14,
    name: 'Manner Held',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'mannerHeldId',
    options: [],
  },
  {
    id: 15,
    name: 'Escrowed',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'escrowed',
    options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
  },

  {
    id: 16,
    name: 'Loan Program',
    type: 'select',
    placeholder: 'Select Loan Program',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'loanProgramId',
  },
  {
    id: 17,
    name: 'Term of Loan (Years)',
    type: 'select',
    placeholder: 'Enter Term of Loan (Years)',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'loanTermId',
    options: [],
  },
  {
    id: 18,
    name: 'Closing Date',
    type: 'date',
    placeholder: 'MM/DD/YYYY',
    customClass: 'px-2 my-2 block w-1/3',
    isRequired: false,
    propertyName: 'closingDate',
  },
  {
    id: 19,
    name: 'No. of Units',
    type: 'number',
    placeholder: 'Enter No. of Units',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'numberOfUnits',
  },
  {
    id: 20,
    name: 'Mixed Use of Property',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'isMixUseProperty',
    options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
  },
  {
    id: 21,
    name: 'Est. Credit Score',
    type: 'number',
    placeholder: 'Enter Est. Credit Score',
    customClass: 'px-2 my-2 block w-1/3',
    isRequired: true,
    propertyName: 'estCreditScore',
    min: 100,
    max: 999,
  },
  {
    id: 22,
    name: 'Title to be vested in',
    type: 'text',
    placeholder: 'Enter Title to be vested in',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'purchaseHomeVestIn',
    validator: checkTextInput,
  },
  {
    id: 23,
    name: 'Spouse Name',
    type: 'text',
    placeholder: 'Enter Spouse Name',
    customClass: 'w-1/3',
    isRequired: (_, additionInfo) => {
      return additionInfo?.personal_details?.maritalStatusId === 1;
    },
    disabled: (_, additionInfo) => {
      return additionInfo?.personal_details?.maritalStatusId !== 1;
    },
    propertyName: 'spouseName',
    validator: checkTextInput,
  },
];

export const loanInformation = withValidations(loanInformationData);

const refinanceInformationData = [
  {
    id: 24,
    name: 'Occupancy',
    type: 'select',
    placeholder: 'Select Occupancy',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'occupancyTypeId',
  },
  {
    id: 25,
    name: 'Subject Property Address',
    type: 'text',
    placeholder: 'Enter Subject Property Address',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'propertyAddress',
  },
  {
    id: 26,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'City',
        type: 'text',
        placeholder: 'Enter City',
        customClass: 'w-1/2',
        isRequired: true,

        propertyName: 'cityName',
        validator: checkTextInput,
      },
      {
        id: 2,
        name: 'State',
        type: 'select',
        placeholder: 'Select State',
        customClass: 'w-1/2',
        isRequired: true,
        propertyName: 'stateName',
      },
    ],
  },
  {
    id: 46,
    name: 'County',
    type: 'text',
    placeholder: 'Enter County',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'county',
    validator: checkTextInput,
  },
  {
    id: 27,
    name: 'Zip',
    type: 'number',
    placeholder: 'Enter Zip Code',
    customClass: 'w-1/3',
    isRequired: true,

    propertyName: 'zipCode',
  },

  {
    id: 28,
    name: 'Are you a first-time homebuyer?',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: false,
    disabled: true,
    propertyName: 'firstTimeBuying',
    /* options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ], */
  },
  {
    id: 29,
    name: 'Sales Price',
    type: 'number',
    placeholder: 'Enter Sales Price Amount',
    customClass: 'w-1/3',
    isRequired: false,
    disabled: true,
    propertyName: 'salePrice',
  },
  {
    id: 30,
    name: 'Appraised Value',
    type: 'number',
    placeholder: 'Enter Appraised Value',
    customClass: 'w-1/3',
    isRequired: true,

    propertyName: 'appraisedValue',
  },
  {
    id: 31,
    name: 'Loan Type',
    type: 'select',
    placeholder: 'Select Loan type',
    customClass: 'w-1/3',
    isRequired: true,

    propertyName: 'loanTypeId',
  },
  {
    id: 32,
    name: 'Refinance Purpose',
    type: 'select',
    placeholder: 'Select Refinance Purpose',
    customClass: 'w-1/3',
    isRequired: true,

    propertyName: 'refinancePurposeId',
  },
  {
    id: 33,
    name: 'Down Payment',
    type: 'number',
    placeholder: 'Enter Down Payment Amount',
    customClass: 'w-1/3',
    isRequired: false,
    disabled: true,
    propertyName: 'downpayment',
  },

  {
    id: 34,
    name: 'Loan Amount',
    type: 'number',
    placeholder: 'Enter Loan Amount',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'loanAmount',
  },
  {
    id: 35,
    name: 'LTV',
    type: 'number',
    placeholder: 'Enter LTV Percentage',
    customClass: 'w-1/3',
    isRequired: false,
    disabled: true,
    propertyName: 'LTV',
  },

  {
    id: 36,
    name: 'Type of Property',
    type: 'select',
    placeholder: 'Select Type of Property',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'propertyTypeId',
  },
  {
    id: 37,
    name: 'Property Rights',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    propertyName: 'propertyRightsId',
    options: [],
    disabled: () => {
      return parseJwt(token)?.role === 'consumer';
    },
  },
  {
    id: 38,
    name: 'Manner Held',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'mannerHeldId',
    options: [],
  },
  {
    id: 39,
    name: 'Escrowed',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: true,

    propertyName: 'escrowed',
    options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
  },
  {
    id: 40,
    name: 'Estimated Closing Date',
    type: 'date',
    placeholder: 'MM/DD/YYYY',
    customClass: 'px-2 my-2 block w-1/3',
    isRequired: false,
    propertyName: 'closingDate',
  },
  {
    id: 41,
    name: 'Term of Loan (Years)',
    type: 'select',
    placeholder: 'Enter Term of Loan (Years)',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'loanTermId',
  },

  {
    id: 42,
    name: 'Mixed Use of Property',
    type: 'select',
    placeholder: 'Select',
    customClass: 'w-1/3',
    isRequired: false,

    propertyName: 'isMixUseProperty',
    options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
  },

  {
    id: 43,
    name: 'Est. Credit Score',
    type: 'number',
    placeholder: 'Enter Est. Credit Score',
    customClass: 'px-2 my-2 block w-1/3',
    isRequired: true,
    min: 100,
    max: 999,
    propertyName: 'estCreditScore',
  },
  {
    id: 44,
    name: 'Title to be vested in',
    type: 'text',
    placeholder: 'Enter Title to be vested in',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'purchaseHomeVestIn',
    validator: checkTextInput,
  },
  {
    id: 45,
    name: 'Spouse Name',
    type: 'text',
    placeholder: 'Enter Spouse Name',
    customClass: 'w-1/3',
    isRequired: (_, additionInfo) => {
      return additionInfo?.personal_details?.maritalStatusId === 1;
    },
    disabled: (_, additionInfo) => {
      return additionInfo?.personal_details?.maritalStatusId !== 1;
    },
    validator: checkTextInput,
    propertyName: 'spouseName',
  },
];

export const refinanceInformation = withValidations(refinanceInformationData);
