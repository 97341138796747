import { api } from 'constants/constant';
import { getHeaders, getUserDetails } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//admin API'S

export const productAndPricingAPI = createApi({
  reducerPath: 'producAndPricing',
  tagTypes: ['fetchProduct'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    // get loan type
    getLoanType: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/loan_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get loan purpose
    getLoanPurpose: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/loan_purpose`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get refinance purpose
    getRefinancePurpose: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/refinance_purpose`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get loan program
    getLoanProgram: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/loan_program`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get occupancy
    getOccupancy: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/occupancy`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get property type
    getPropertyType: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/property_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get commitment period
    getCommitmentPeriod: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/commitment_period`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get compensation payer type
    getCompensationPayerType: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/compensation_payer_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get escrow
    getEscrow: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/escrow_waiver_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get loan terms
    getLoanTerms: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/loan_term`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get flex term
    getFlexTerm: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/flex_term`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get is first time homebuyer
    getFirstTimeHomebuyer: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/first_time_home_buyer_options`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    getBuydownType: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/buydown_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    //get processing option
    getProcessingOption: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/processing_options`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get country option
    getCountryOption: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/countries`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get pricing details
    getPricingDetails: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `/loan/product/get_pricing_details/application/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get quote
    getQuote: builder.query({
      query: ({ quoteNumber }) => {
        return {
          url: `/loan/product/get_quote/quote/${quoteNumber}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get lender
    getRateVendors: builder.query({
      query: () => {
        return {
          url: `/loan/product/rate_vendors`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //generate quote
    generateQuote: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/loan/product/generate_quote`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
      // invalidatesTags: ['creditUpdate'],
    }),
    sendQuote: builder.mutation({
      query: ({ applicationId, quoteNumber, data }) => {
        return {
          url: `/loan/product/send_quote/application/${applicationId}?quote_number=${quoteNumber}`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    manageScenarios: builder.query({
      query: ({ quoteData }) => {
        return {
          url: `/loan/product/get_all_products`,
          method: 'POST',
          body: quoteData,
          headers: getHeaders(),
        };
      },
      providesTags: ['fetchProduct'],
    }),
    compareProducts: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/loan/product/compare_products`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    assignRates: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/loan/product/assign_rate`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['fetchProduct'],
    }),
    editBorrowerCompensation: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/loan/product/modify_broker_compensation`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    compareOffer: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/loan/product/compare_offer_by_user_input`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    // get state
    getStates: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/states`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    compareOfferDocumentUpload: builder.mutation({
      query: ({ data }) => {
        const { token } = getUserDetails();

        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        return {
          url: `/loan/tools/compare_offer_document_upload`,
          body: data,
          headers: headers,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetCommitmentPeriodQuery,
  useGetCompensationPayerTypeQuery,
  useGetEscrowQuery,
  useGetFirstTimeHomebuyerQuery,
  useGetFlexTermQuery,
  useGetLoanPurposeQuery,
  useGetLoanTermsQuery,
  useGetLoanTypeQuery,
  useGetRateVendorsQuery,
  useGetOccupancyQuery,
  useGetProcessingOptionQuery,
  useGetPropertyTypeQuery,
  useGetRefinancePurposeQuery,
  useGetPricingDetailsQuery,
  useGenerateQuoteMutation,
  useGetBuydownTypeQuery,
  useGetQuoteQuery,
  useGetCountryOptionQuery,
  useSendQuoteMutation,
  useManageScenariosQuery,
  useCompareProductsMutation,
  useAssignRatesMutation,
  useEditBorrowerCompensationMutation,
  useCompareOfferMutation,
  useGetLoanProgramQuery,
  useGetStatesQuery,
  useCompareOfferDocumentUploadMutation,
} = productAndPricingAPI;
