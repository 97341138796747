/* istanbul ignore file */
const lpaTableData = [
    // {
    //     requestDate: '2/19/2024',
    //     time: '11:25:05AM',
    //     status: 'Processed Data',
    //     recommendation: 'Approve/Eligible',
    //     findings: 'View DU Findings',
    //     creditReport: 'View Credit Report',
    // },
    // {
    //     requestDate: '2/6/2024',
    //     time: '14:12:34 ',
    //     status: 'Processed Data',
    //     recommendation: 'Approve/Eligible',
    //     findings: 'View DU Findings',
    //     creditReport: 'View Credit Report',
    // }
]
export default lpaTableData;