import React from 'react';
import Card from 'components/card';
import DoughnutGraph from 'components/doughnut';

const CurrentOffer = ({ pieData, currentOfferValue }) => {
  return (
    <Card className="w-1/3 p-4">
      <h4 className="text-neutral1000 font-bold">Current Offer</h4>
      <DoughnutGraph graphData={pieData} />

      <div>
        {currentOfferValue?.map((offer) => (
          <div
            className="flex items-center my-2"
            key={offer?.name}
            data-testid={offer.name.split(' ').join('')}
          >
            <p className="w-1/2 text-sm">{offer?.name}</p>
            <p> {offer?.value} </p>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default CurrentOffer;
