/* istanbul ignore file */
import { validatePercentageInput, withValidations } from 'pages/profile/utils';
const compareOfferData = [
  {
    id: 1,
    name: 'Original Loan Amount',
    type: 'number',
    placeholder: 'Enter Original Loan Amount',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'loan_amount',
  },
  {
    id: 2,
    name: 'Interest Rate',
    type: 'number',
    placeholder: 'Interest Rate',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'rate',
  },
  {
    id: 14,
    type: 'container',
    customClass: 'w-1/2 flex items-end',
    formFields: [
      {
        id: 3,
        name: 'Closing Date',
        type: 'date',
        placeholder: 'Enter Closing Date',
        customClass: 'w-1/2 px-2 my-2',
        isRequired: true,
        propertyName: 'start_date',
      },
      {
        id: 4,
        name: 'No. of Payments',
        type: 'number',
        placeholder: 'Enter No. of Payments',
        customClass: 'w-1/2',
        isRequired: false,
        propertyName: 'number_of_payments',
      },
    ],
  },

  {
    id: 5,
    name: 'Mortgage Insurance (Monthly)',
    type: 'number',
    placeholder: 'Enter Mortgage Insurance',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'mortgage_insurance',
  },
  {
    id: 6,
    name: 'Original Loan Terms (In Years)',
    type: 'number',
    placeholder: 'Enter Original Loan Terms',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'loan_term',
  },
  {
    id: 7,
    name: 'Loan Program',
    type: 'select',
    placeholder: 'Select Loan Program',
    customClass: 'w-2/4',
    isRequired: true,

    propertyName: 'loan_program_id',
    options: [
      {
        id: 1,
        name: 'Porgram 1',
      },
      {
        id: 2,
        name: 'Program 2',
      },
    ],
  },
];
const compareOffer = withValidations(compareOfferData);
export default compareOffer;
