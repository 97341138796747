import React from 'react';
//assets
import NotificationBlueIcon from 'assets/icons/notificationBlueIcon.svg';
//query
import { useUpdateNotificationsMutation } from 'redux_store/services/notifications';
//utils
import { formatTimeDifferenceUTC } from 'constants/updatedAt';
import { EventEnums, NotificationTypeMap } from './constant';
//hooks
import { useNavigate } from 'react-router-dom';
//component
import ToggleButton from 'components/toggle';
import Button from 'components/button';
import Loader from 'wrapper/loader';

const NotificationTab = ({
  notifications,
  isFetching,
  showUnread,
  setShowUnread,
  setNotification,
}) => {
  const navigateTo = useNavigate();

  // rtk query
  const [updateNotification] = useUpdateNotificationsMutation();

  // redirect to pages based on notification type
  const handleClick = async (
    typeId,
    application_id,
    userNotificationId,
    isRead
  ) => {
    if (isRead === '0') {
      await updateNotification({
        data: { notification_ids: [userNotificationId] },
      });
    }
    const eventType = NotificationTypeMap[typeId];
    if (eventType) {
      switch (eventType) {
        case EventEnums.CONSUMER_PROFILE_ID_CREATED:
          navigateTo('/profile');
          break;
        case EventEnums.CONSUMER_PROFILE_COMPLETED:
          navigateTo('/pipeline');
          break;
        case EventEnums.CONSUMER_ELIGIBLE:
          navigateTo('/pipeline');
          break;
        case EventEnums.CONSUMER_PRE_APPROVED:
          navigateTo('/pipeline');
          break;
        case EventEnums.CONSUMER_NOT_ELIGIBLE:
          navigateTo('/profile');
          break;
        case EventEnums.CONSUMER_PRE_APPROVAL_REJECTION:
          navigateTo('/profile');
          break;
        case EventEnums.CONSUMER_QUOTE:
          break;
        case EventEnums.CONSUMER_EMAIL_CHANGE_VERIFICATION:
          break;
        case EventEnums.LOAN_OFFICER_CONSUMER_COMPLETED_ONBOARDING:
          navigateTo(`/loanInformation/lead?applicationId=${application_id}`);
          break;
        case EventEnums.LOAN_OFFICER_PROFILE_COMPLETED:
          navigateTo(`/loanInformation/lead?applicationId=${application_id}`);
          break;
        case EventEnums.LOAN_OFFICER_ADD_LEAD:
          navigateTo(`/loanInformation/lead?applicationId=${application_id}`);
          break;
        case EventEnums.LOAN_OFFICER_ELIGIBLE:
          navigateTo(`/loanInformation/lead?applicationId=${application_id}`);
          break;
        case EventEnums.LOAN_OFFICER_PRE_APPROVED:
          navigateTo(`/pipeline/lead?applicationId=${application_id}`);
          break;
        case EventEnums.LOAN_OFFICER_PRE_APPROVAL_REJECTION:
          navigateTo(`/pipeline/lead?applicationId=${application_id}`);
          break;
        case EventEnums.LOAN_OFFICER_NOT_ELIGIBLE:
          navigateTo(`/loanInformation/lead?applicationId=${application_id}`);
          break;
        default:
          console.error(`Unknown notification type: ${eventType}`);
          break;
      }
    } else {
      console.error(`Unknown notification type ID: ${typeId}`);
    }
    setNotification(false);
  };

  // Update the state based on the toggle state
  const handleToggle = (e) => {
    setShowUnread(e.target.checked);
  };

  return (
    <div className="w-[348px]  bg-white border border-grey rounded-md absolute right-[-1rem] top-10 shadow-xl z-50">
      <div className="flex justify-between px-3 py-2">
        <h3 className="p-sm-bold">Notifications</h3>
        <div className="p-xs-regular flex gap-2 text-secondary400">
          Show only unread
          <ToggleButton checked={showUnread} onChange={handleToggle} />
        </div>
      </div>
      <div className="border border-neutral100"></div>

      <div className=" px-2.5 py-3 overflow-scroll h-[550px]">
        {isFetching && <Loader />}
        {!isFetching && notifications?.length === 0 && (
          <div className="flex flex-col justify-center mt-28 items-center">
            <img src={NotificationBlueIcon} />
            <h4 className="font-medium-bold mt-4">
              You have no new notifications at this time.
            </h4>
            <p className="p-xs-regular w-[14rem] mt-2 text-center text-secondary400">
              You'll be notified here when something relevant happens.
            </p>
          </div>
        )}
        {!isFetching &&
          notifications?.length > 0 &&
          notifications?.map((item, index) => {
            return (
              <div
                className="mb-2 p-2.5 border border-base_white w-auto h-auto rounded-xl hover:bg-base_white cursor-pointer"
                key={item.userNotificationId}
                onClick={() =>
                  handleClick(
                    item.typeId,
                    item.applicationId,
                    item.userNotificationId,
                    item.isRead
                  )
                }
              >
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center truncate w-[80%]">
                    <span className="p-xs-bold truncate">{item.subject}</span>
                    {item.isRead === '0' && (
                      <div className="w-1.5 h-1.5 rounded-full bg-error-red" />
                    )}
                  </div>
                  <div>
                    <span className="p-xs-regular text-neutral600 text-nowrap">
                      {formatTimeDifferenceUTC(item?.createdAt)}
                    </span>
                  </div>
                </div>
                <p className="p-xs-regular text-neutral600 my-2">
                  {item.message}
                </p>
                {item?.isRead === '0' && (
                  <Button
                    variant="primary_sm"
                    type="button"
                    btnClassName="!py-1.5 px-2"
                    onClick={() =>
                      handleClick(
                        item.typeId,
                        item.applicationId,
                        item.userNotificationId,
                        item.isRead
                      )
                    }
                  >
                    Review
                  </Button>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NotificationTab;
