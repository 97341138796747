import { useEffect, useState } from 'react';

const ToolsErrorDisplay = ({ selectedTabId }) => {
  return (
    <div
      className="w-full bg-contain rounded  bg-center bg-no-repeat flex items-center justify-center text-black"
      style={{
        backgroundImage:
          selectedTabId === 1
            ? 'url("/Lightbox.png")'
            : 'url("/lightbox-amortization.png")',
        minHeight: 'calc(100vh - 25vh)',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      }}
    >
      <div className="bg-white max-w-[450px] p-4 font-bold">
        {`Data will be available when the Mortgage Broker assigns Interest Rate and
          updates Closing Cost, to run ${selectedTabId === 1 ? 'Loan Comparison' : 'Amortization Schedule'}.`}
      </div>
    </div>
  );
};
export default ToolsErrorDisplay;
