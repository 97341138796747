import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//components
import Table from 'components/table';
import Breadcrumb from 'components/breadcrumb';
import H1 from 'components/h1-typography';
import Badge from 'components/badge';
import ToUSD from 'components/num-to-usd';
import User from 'components/user';
import Progressbar from 'components/progressbar';
import Loader from 'wrapper/loader';
import { customToast } from 'components/toast';
import {
  useGetPipelineQuery,
  useGetPipelineStatusQuery,
  useUpdatePipelineStatusMutation,
} from 'redux_store/services/client/pipeline';
//utils
import { getUserDetails } from 'auth/auth';
//libs
import Select from 'react-select';
import * as Sentry from '@sentry/browser';
//theme
import { textColors, bgColors } from '../../themes/colors';
//slice
import { setChatMessage } from 'redux_store/slices/chatMessage';
//hooks
import { useDispatch } from 'react-redux';
//constants
import { chatQuery } from 'constants/chatQuery';
import { statusVariantMap } from './data/constants';
//icons
import Amortization from 'assets/icons/Amortization';
import Compare from 'assets/icons/Compare';
import dropdown from 'assets/icons/dropdown.svg';
import { Link } from 'react-router-dom';

//custom hooks
import usePageTitle from 'constants/PageTitle';

const Pipeline = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [applicationStatusId, setApplicationStatusId] = useState(null);
  const [options, setOptions] = useState([
    {
      value: 'Run Eligibility',
      label: 'Run Eligibility',
      id: 3,
      isDisabled: false,
    },
    {
      value: 'Request Pre-approval',
      label: 'Request Pre-approval',
      id: 6,
      isDisabled: true,
    },
    {
      value: 'Start Loan Application',
      label: 'Start Loan Application',
      id: 9,
      isDisabled: true,
    },
  ]);
  usePageTitle('pipeline');
  const [updatePipeline, { isLoading, isError }] =
    useUpdatePipelineStatusMutation();
  const { userId } = getUserDetails();
  const {
    data: pipelineData,
    isLoading: pipelineLoading,
    isError: pipelineError,
    refetch,
  } = useGetPipelineQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: pipelineStatusData,
    isLoading: pipelineStatusLoading,
    isError: pipelineStatusError,
  } = useGetPipelineStatusQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    Sentry.captureException(isError);
    Sentry.captureException(pipelineError);
    Sentry.captureException(pipelineStatusError);
  }, []);

  const [selectedAction, setSelectedAction] = useState({
    value: 'Run Eligibility',
    label: 'Run Eligibility',
  });

  const breadCrumbs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Pipeline',
      path: '/pipeline',
    },
  ];

  const handleActionChange = async (selectedVal, row) => {
    setSelectedAction(selectedVal);
    const data = {
      applicationStatusId: selectedVal.id,
      //userId,
    };
    if (selectedVal.id) {
      const response = await updatePipeline({
        data,
        applicationId: row?.application_details?.applicationId,
      });
      if (response?.data?.success) {
        customToast.success(response.data.message);
        setApplicationStatusId(response?.data?.data?.applicationStatusId);
        await refetch();
      }
      switch (selectedVal.id) {
        case 3:
          dispatch(
            setChatMessage({
              message: chatQuery.eligibilityMessage,
              code: chatQuery.eligibilityCode,
              userProfileId: row.user_profile.id,
              applicationId: row.application_details.applicationId,
            })
          );
          break;
        case 6:
          dispatch(
            setChatMessage({
              message: chatQuery.preApprovalMessage,
              code: chatQuery.preApprovalCode,
              userProfileId: row.user_profile.id,
              applicationId: row.application_details.applicationId,
            })
          );
          break;
        case 9:
          dispatch(
            setChatMessage({
              message: chatQuery.loanApplicationMessage,
              code: chatQuery.loanApplicationCode,
              userProfileId: row.user_profile.id,
              applicationId: row.application_details.applicationId,
            })
          );
          break;
        default:
          dispatch(setChatMessage(''));
          break;
      }
      if (response?.data?.status !== 200) {
        customToast.error('You have a higher priority status active');
      }
    }
  };

  const columns = [
    {
      header: 'Profile ID',
      accessorKey: 'user_profile.id',
      cell: ({ row }) => {
        return (
          <Link to="/profile" className="text-custom-green font-semibold ">
            {row?.original?.user_profile.id}
          </Link>
        );
      },
    },
    {
      header: 'Name',
      accessorKey: 'user_profile.firstName',
      cell: ({ row }) => {
        return (
          <User
            size="sm"
            title={row?.original?.user_profile?.firstName}
            subtitle={row?.original?.user_profile?.lastName}
          />
        );
      },
    },
    {
      header: 'Property Address',
      accessorKey: 'application_details.propertyAddress',
      meta: {
        width: 'min-w-[200px]',
      },
    },
    {
      header: 'Loan Officer',
      accessorKey: 'loan_officer_details.firstName',
      cell: ({ row }) => {
        return (
          <User
            size="sm"
            title={row?.original?.loan_officer_details?.firstName}
            subtitle={row?.original?.loan_officer_details?.lastName}
          />
        );
      },
    },
    {
      header: 'Amount',
      accessorKey: 'application_details.loanAmount',
      cell: ({ row }) => {
        return (
          <ToUSD
            currencyValue={row?.original?.application_details?.loanAmount}
          />
        );
      },
    },
    {
      header: 'Completion Percentage',
      accessorKey: 'application_details.completionStatus',
      cell: ({ row }) => {
        return (
          <div className="w-32 ">
            <Progressbar
              value={row?.original?.application_details?.completionStatus}
              valueString={`${row?.original?.application_details?.completionStatus}%`}
              valuePosition="left"
              color={
                row?.original?.application_details?.completionStatus == 100
                  ? 'bg-primary500'
                  : 'bg-warning100'
              }
            />
          </div>
        );
      },
    },
    {
      header: 'Status',
      accessorKey: 'application_details.statusId',
      cell: ({ row }) => {
        const statusId = row?.original?.application_details.statusId;
        const statusObject = pipelineStatusData?.data?.find(
          (data) => data.applicationStatusId === statusId
        );
        setApplicationStatusId(statusObject?.applicationStatusId);
        const statusText = statusObject ? statusObject.name : '';
        return (
          <div className="w-[8rem]">
            <Badge
              title={statusText}
              variant={statusVariantMap[statusId] || 'primary'}
            />
          </div>
        );
      },
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      meta: {
        className: 'sticky right-[120px]', //sticky column drop-shadow-xl
        style: 'bg-white group-hover:bg-primary50',
        width: 'min-w-fit',
      },
      cell: ({ row }) => {
        return (
          <div className="flex flex-row justify-center">
            <Select
              isDisabled={
                row?.original?.application_details?.completionStatus < 75
                  ? true
                  : false
              }
              menuShouldScrollIntoView={true}
              menuShouldBlockScroll={true}
              menuPlacement="auto"
              menuPosition="fixed"
              components={customSearchIcon}
              isSearchable={false}
              //defaultValue={selectedAction}
              onChange={(selectedOption) =>
                handleActionChange(selectedOption, row.original)
              }
              options={options}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,

                  neutral80: textColors.tertiary,
                  neutral40: textColors.tertiary,
                },
              })}
              className=" placeholder:text-white"
              placeholder={
                <>
                  {isLoading || pipelineStatusLoading ? (
                    <div
                      style={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: '15px',
                      }}
                      className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center"
                    >
                      <Loader loaderText="Checking" FullScreen={false} />
                    </div>
                  ) : (
                    <div
                      style={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: '15px',
                        width: '100%',
                        paddingRight: 15,
                      }}
                    >
                      {StatusBasedPlaceholder()}
                    </div>
                  )}
                </>
              }
              styles={{
                control: (provided) => ({
                  ...provided,
                  position: 'relative',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  fontWeight: 700,
                  background: `${row?.original?.application_details?.completionStatus < 75 ? bgColors.disabled : bgColors.primary_bg}`,
                  boxShadow: 'none',
                  cursor: 'pointer',
                  width: '200px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isDisabled
                    ? bgColors.grey
                    : state.isSelected
                      ? bgColors.primary100
                      : 'white',
                  borderTop: `1px solid ${bgColors.tertiary_bg}`,
                  margin: '0px',
                  color: textColors.black,
                  boxShadow: 'none',
                  fontWeight: 500,
                  textAlign: 'center',
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: state.isDisabled
                      ? bgColors.grey
                      : bgColors.primary50,
                  },
                }),
                indicatorSeparator: () => null,
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  fontSize: '12px',
                }),
              }}
              menuPortalTarget={document.body}
            />
          </div>
        );
      },
    },
    {
      header: 'Tools',
      accessorKey: 'tools',
      meta: {
        className: 'sticky right-0', //sticky column
        style: 'bg-custom-primary300 group-hover:bg-primary50',
        width: 'min-w-[100px]',
      },
      cell: () => {
        const [hoverCompare, setHoverCompare] = useState(false);
        const [hoverAmortization, setHoverAmortization] = useState(false);
        return (
          <div className="flex gap-4 justify-evenly">
            <div
              className="group cursor-pointer"
              onMouseOver={() => setHoverCompare(true)}
              onMouseOut={() => setHoverCompare(false)}
              onClick={() =>
                navigate('/tools', { state: { NavigateToTab: 1 } })
              }
            >
              <Compare color={hoverCompare ? '#1340FF' : '#000'} />

              {hoverCompare && (
                <div className="p-xs-med flex px-2.5 py-1.5 bg-primary100 absolute rounded-lg whitespace-nowrap left-[-1.5rem] bottom-[-1rem] ">
                  compare loan
                </div>
              )}
            </div>
            <div
              className="group cursor-pointer"
              onMouseOver={() => setHoverAmortization(true)}
              onMouseOut={() => setHoverAmortization(false)}
              onClick={() =>
                navigate('/tools', { state: { NavigateToTab: 2 } })
              }
            >
              <Amortization color={hoverAmortization ? '#1340FF' : '#000'} />

              {hoverAmortization && (
                <div className="p-xs-med flex px-2.5 py-1.5 bg-primary100 absolute rounded-lg whitespace-nowrap left-[-3.5rem] bottom-[-1rem] ">
                  Amortization Schedule
                </div>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  useLayoutEffect(() => {
    if (applicationStatusId) {
      if (applicationStatusId >= 5) {
        let updatedOptions = [];
        options.map((item) => {
          if (item.id === 3) {
            updatedOptions.push({ ...item, isDisabled: true });
          }
          if (item.id === 6) {
            updatedOptions.push({ ...item, isDisabled: false });
          }
          if (item.id === 9) {
            updatedOptions.push({ ...item, isDisabled: true });
          }
        });
        setOptions(updatedOptions);
      }
      if (applicationStatusId >= 8 && applicationStatusId < 10) {
        let updatedOptions = [];
        options.map((item) => {
          if (item.id === 3) {
            updatedOptions.push({ ...item, isDisabled: true });
          }
          if (item.id === 6) {
            updatedOptions.push({ ...item, isDisabled: true });
          }
          if (item.id === 9) {
            updatedOptions.push({ ...item, isDisabled: false });
          }
        });
        setOptions(updatedOptions);
      }
      if (applicationStatusId >= 10) {
        let updatedOptions = [];
        options.map((item) => {
          if (item.id === 3) {
            updatedOptions.push({ ...item, isDisabled: true });
          }
          if (item.id === 6) {
            updatedOptions.push({ ...item, isDisabled: true });
          }
          if (item.id === 9) {
            updatedOptions.push({ ...item, isDisabled: true });
          }
        });
        setOptions(updatedOptions);
      }
    }
  }, [applicationStatusId]);
  const StatusBasedPlaceholder = () => {
    if (applicationStatusId <= 4) {
      return 'Run Eligibility';
    }
    if (applicationStatusId > 4 && applicationStatusId < 7) {
      return 'Request Pre-approval';
    }
    if (
      (applicationStatusId > 7 && applicationStatusId <= 9) ||
      applicationStatusId === 11
    ) {
      return 'Start Loan Application';
    }
    return 'Loan Application Submitted';
  };
  const filteredPurchases = pipelineData?.data?.applications
    ? pipelineData.data.applications.filter(
        (application) => application.loan_purpose_id === 1
      )
    : [];

  const filteredRefinancing = pipelineData?.data?.applications
    ? pipelineData.data.applications.filter(
        (application) => application.loan_purpose_id === 2
      )
    : [];

  return pipelineData?.data && pipelineStatusData?.data ? (
    <div className="w-full">
      <Breadcrumb breadcrumbs={breadCrumbs} />
      <div className="flex flex-col justify-between h-auto gap-6 overflow-auto  max-h-custom-pipeline ">
        <div>
          <H1>Purchase</H1>
          <Table
            data={filteredPurchases}
            columns={columns}
            errorMessage="No relevant data found"
            height="min-h-[30vh]"
          />
        </div>
        <div>
          <H1>Refinance</H1>
          <Table
            data={filteredRefinancing}
            columns={columns}
            errorMessage="No relevant data found"
            height="min-h-[30vh]"
          />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Pipeline;

const customSearchIcon = {
  DropdownIndicator: () => {
    return (
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2 pointer-events-none pr-3">
        <img src={dropdown} alt="icon" />
      </div>
    );
  },
};
