// React
import React, { useEffect, useState } from 'react';
//components
import DynamicForm from '../DynamicForm';
import Button from 'components/button';
import H1 from 'components/h1-typography';
import Loader from 'wrapper/loader';
import DynamicTabs from 'components/dynamicTabs';
import ConfirmDeleteTab from 'wrapper/deleteTab';
import { customToast } from 'components/toast';
import FormWrapper from 'wrapper/form-wrapper';
//icons
import plus from 'assets/icons/bluePlus.svg';
// RTK Query
import {
  useGetLiabilityTypeQuery,
  useGetDynamicApplicationQuery,
  useCreateDynamicLiabilityMutation,
  useUpdateDynamicLiabilityMutation,
  useDeleteDynamicLiabilityMutation,
} from 'redux_store/services/client/profile';
// Utils
import {
  checkValidations,
  createFormObject,
  removeEmptyStrings,
} from '../utils';
import { liabilitiesData } from '../consumerFormFields/liabilitiesData';
import { getUserDetails } from 'auth/auth';
// sentry for logs
import * as Sentry from '@sentry/browser';
import { useSelector, useDispatch } from 'react-redux';
import {
  liabilitiesDataSelector,
  resetPageInfo,
} from 'redux_store/slices/application';

const Liabilities = ({ setPage, setPreviousPage, token }) => {
  // states and refs
  const [formData, setFormData] = React.useState(
    createFormObject(liabilitiesData)
  );
  const dispatch = useDispatch();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const isEdit = React.useRef(false);
  const liabilitiesDataAI = useSelector(liabilitiesDataSelector);
  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false);
  const [allLiabilityArray, setAllLiabilityArray] = useState([]);
  const [isError, setIsError] = useState(false);
  const [tab, setTab] = useState(0);
  const userId = getUserDetails()?.userId;
  const [tabsArray, setTabsArray] = useState([
    {
      id: 0,
      name: 'Primary Liability',
      isActive: true,
      isError: false,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  // RTK Query
  const { data: liabilityData } = useGetLiabilityTypeQuery();
  const [createLiabilities, { isLoading: isCreateLiabilitiesLoading }] =
    useCreateDynamicLiabilityMutation();
  const [updateLiabilities, { isLoading: isUpdateLiabilitiesLoading }] =
    useUpdateDynamicLiabilityMutation();
  const [deleteLiabilityDetails, { isLoading: isLiabilityDeleteLoading }] =
    useDeleteDynamicLiabilityMutation();
  const { data: applicationData, refetch } = useGetDynamicApplicationQuery(
    {
      application_id: token,
      userId,
    },
    {
      skip: !token,
    }
  );
  const handleDeleteLiability = async () => {
    setIsError(false);
    setConfirmDeleteVisibility(false);
    const userLiabilityId = allLiabilityArray[tab]?.userLiabilityId ?? null;
    if (userLiabilityId > 0) {
      try {
        let resp = await customToast.promise(
          deleteLiabilityDetails({
            userLiabilityId,
          }),
          'Deleting',
          tab === 0
            ? `Primary Liability tab removed successfully.`
            : `Additional Liability ${tab} tab removed successfully.`,
          'Error While Deleting'
        );
        if (resp?.data?.status === 200) {
          setTab(tab - 1);
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
      return;
    }
    let newTabArray = tabsArray?.filter((tabs) => {
      return tabs.id !== tab;
    });
    setTab(tab - 1);
    newTabArray[tab - 1].isActive = true;
    setTabsArray(newTabArray);
    UpdateFormDataOnLoad(allLiabilityArray[tab - 1]);
    setIsError(false);
  };
  const UpdateFormOnAi = () => {
    if (!Boolean(Object.keys(liabilitiesDataAI).length)) return false;
    setFormData((prev) => ({
      ...prev,
      ...liabilitiesDataAI,
      liabilityTypeId: liabilitiesDataAI?.liabilityTypeId
        ? {
            id: liabilitiesDataAI?.liabilityTypeId,
          }
        : formData?.liabilityTypeId ?? '',
      isExclude:
        liabilitiesDataAI?.isExclude === 'Yes' ||
        liabilitiesDataAI?.isExclude === 'No' ||
        liabilitiesDataAI?.isExclude === true ||
        liabilitiesDataAI?.isExclude === false
          ? {
              id:
                liabilitiesDataAI?.isExclude === 'Yes' ||
                liabilitiesDataAI?.isExclude === true
                  ? 1
                  : 2,
            }
          : formData?.isExclude ?? '',
      isPayoffRequired:
        liabilitiesDataAI?.isPayoffRequired === 'True' ||
        liabilitiesDataAI?.isPayoffRequired === 'Yes' ||
        liabilitiesDataAI?.isPayoffRequired === true
          ? true
          : liabilitiesDataAI?.isPayoffRequired === 'False' ||
              liabilitiesDataAI?.isPayoffRequired === 'No' ||
              liabilitiesDataAI?.isPayoffRequired === false
            ? false
            : prev?.isPayoffRequired,
      isIncludeBalance:
        liabilitiesDataAI?.isIncludeBalance === 'True' ||
        liabilitiesDataAI?.isIncludeBalance === 'Yes' ||
        liabilitiesDataAI?.isIncludeBalance === true
          ? true
          : liabilitiesDataAI?.isIncludeBalance === 'False' ||
              liabilitiesDataAI?.isIncludeBalance === 'No' ||
              liabilitiesDataAI?.isIncludeBalance === false
            ? false
            : prev?.isIncludeBalance,
      isIncludePayment:
        liabilitiesDataAI?.isIncludePayment === 'True' ||
        liabilitiesDataAI?.isIncludePayment === 'Yes' ||
        liabilitiesDataAI?.isIncludePayment === true
          ? true
          : liabilitiesDataAI?.isIncludePayment === 'False' ||
              liabilitiesDataAI?.isIncludePayment === 'No' ||
              liabilitiesDataAI?.isIncludePayment === false
            ? false
            : prev?.isIncludePayment,
    }));
  };
  const UpdateFormDataOnLoad = (data) => {
    isEdit.current = true;
    const formData = data;

    setFormData({
      ...formData,
      ...liabilitiesDataAI,
      liabilityTypeId: liabilitiesDataAI?.liabilityTypeId
        ? {
            id: liabilitiesDataAI?.liabilityTypeId,
          }
        : formData?.liabilityTypeId
          ? {
              id: formData?.liabilityTypeId,
            }
          : '',
      isExclude:
        liabilitiesDataAI?.isExclude === 'Yes' ||
        liabilitiesDataAI?.isExclude === 'No' ||
        liabilitiesDataAI?.isExclude === true ||
        liabilitiesDataAI?.isExclude === false
          ? {
              id:
                liabilitiesDataAI?.isExclude === 'Yes' ||
                liabilitiesDataAI?.isExclude === true
                  ? 1
                  : 2,
            }
          : formData?.isExclude
            ? {
                id: formData?.isExclude === '1' ? 1 : 2,
              }
            : '',
      isPayoffRequired:
        liabilitiesDataAI?.isPayoffRequired === 'True' ||
        liabilitiesDataAI?.isPayoffRequired === 'Yes' ||
        liabilitiesDataAI?.isPayoffRequired === true
          ? true
          : liabilitiesDataAI?.isPayoffRequired === 'False' ||
              liabilitiesDataAI?.isPayoffRequired === 'No' ||
              liabilitiesDataAI?.isPayoffRequired === false
            ? false
            : formData?.isPayoffRequired === '1'
              ? true
              : false,

      isIncludeBalance:
        liabilitiesDataAI?.isIncludeBalance === 'True' ||
        liabilitiesDataAI?.isIncludeBalance === 'Yes' ||
        liabilitiesDataAI?.isIncludeBalance === true
          ? true
          : liabilitiesDataAI?.isIncludeBalance === 'False' ||
              liabilitiesDataAI?.isIncludeBalance === 'No' ||
              liabilitiesDataAI?.isIncludeBalance === false
            ? false
            : formData?.isIncludeBalance === '1'
              ? true
              : false,
      isIncludePayment:
        liabilitiesDataAI?.isIncludePayment === 'True' ||
        liabilitiesDataAI?.isIncludePayment === 'Yes' ||
        liabilitiesDataAI?.isIncludePayment === true
          ? true
          : liabilitiesDataAI?.isIncludePayment === 'False' ||
              liabilitiesDataAI?.isIncludePayment === 'No' ||
              liabilitiesDataAI?.isIncludePayment === false
            ? false
            : formData?.isIncludePayment === '1'
              ? true
              : false,
    });
  };
  React.useEffect(() => {
    if (applicationData?.success) {
      const liabilityArray = applicationData?.data?.user_libality_details;
      const persistedTab = tab ?? 0;
      setAllLiabilityArray(liabilityArray);
      if (liabilityArray?.length > 1) {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Liability',
            isActive: false,
            isError: false,
          },
        ];
        if (tab === liabilityArray.length) {
          for (let i = 1; i <= tab; i++) {
            newTabsArray.push({
              id: i,
              name: `Additional Liability ${i}`,
              isActive: false,
              isError: false,
            });
          }
          setTab(persistedTab);
          newTabsArray[persistedTab].isActive = true;
          setTabsArray(newTabsArray);
          UpdateFormDataOnLoad(liabilityArray[persistedTab]);
          return;
        }
        for (let i = 1; i <= liabilityArray.length - 1; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Liability ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(persistedTab);
        newTabsArray[persistedTab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(liabilityArray[persistedTab]);
      } else {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Liability',
            isActive: false,
            isError: false,
          },
        ];
        for (let i = 1; i <= tab; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Liability ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(persistedTab);
        newTabsArray[persistedTab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(liabilityArray[persistedTab]);
      }
    }
  }, [applicationData]);

  const formHasNoErrors = () => {
    const errors = checkValidations(liabilitiesData, formData, {
      ...applicationData?.data,
      loan_details: applicationData?.data?.loan_details,
    });
    if (Object.keys(errors)?.length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
      setIsError(true);
      return false;
    } else {
      setErrors({});
      setIsFormSubmitted(false);
      setIsError(false);
      return true;
    }
  };
  const getNextOrder = () => {
    return (
      allLiabilityArray.reduce((max, obj) => Math.max(max, obj.order), 0) + 1
    );
  };
  const ApiFormatData = () => {
    return {
      ...formData,
      userLiabilityId: formData?.userLiabilityId ?? null,
      liabilityTypeId: formData?.liabilityTypeId
        ? formData?.liabilityTypeId?.id
        : null,
      isExclude:
        formData?.isExclude?.id === 1
          ? true
          : formData?.isExclude?.id === 2
            ? false
            : null,
      remainingMonths: formData?.remainingMonths || 0,
      monthlyPayment: formData?.monthlyPayment || 0,
      totalBalance: formData?.totalBalance || 0,
      isPayoffRequired: !!formData?.isPayoffRequired,
      isIncludeBalance: !!formData?.isIncludeBalance,
      isIncludePayment: !!formData?.isIncludePayment,
      assetTypeId: 1,
      accountNumber: String(formData?.accountNumber ?? ''),
      order: formData.order ?? getNextOrder(),
      userId,
      applicationId: token,
      userProfileId: applicationData?.data?.personal_details?.userProfileId,
    };
  };
  const handleUpdateFunction = async () => {
    if (formHasNoErrors()) {
      setIsFormSubmitted(false);
      try {
        if (isLoading) {
          return false;
        }
        //condition to update the Form when AI is involved in form filling
        if (Object.keys(liabilitiesDataAI).length > 0) {
          const refetchedData = await refetch();
          if (refetchedData?.isSuccess) {
            const uniqueId =
              refetchedData?.data?.data?.user_libality_details?.[0]
                ?.userLiabilityId;
            const data = {
              ...ApiFormatData('update'),
              userLiabilityId: uniqueId,
            };
            let resp = await customToast.promise(
              updateLiabilities({
                data: removeEmptyStrings(data),
              }),
              'Saving',
              tab === 0
                ? `Primary Liability tab updated successfully.`
                : `Additional Liability ${tab} tab updated successfully.`,
              'Error While Saving'
            );
            if (resp?.data?.status === 200) {
              setIsLoading(false);
              dispatch(
                resetPageInfo({
                  name: 'liabilitiesData',
                })
              );
              return true;
            }
            return false;
          }
        }
        if (!formData?.userLiabilityId) {
          //create liability if id does'nt exist
          let resp = await customToast.promise(
            createLiabilities({
              data: removeEmptyStrings(ApiFormatData('create')),
            }),
            'Saving',
            tab === 0
              ? `Primary Liability tab added successfully.`
              : `Additional Liability ${tab} tab added successfully.`,
            'Error While Saving'
          );
          if (resp.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        } else {
          // if liability id exist then update the existing employee
          setIsLoading(true);
          let resp = await customToast.promise(
            updateLiabilities({
              data: removeEmptyStrings(ApiFormatData('update')),
            }),
            'Saving',
            tab === 0
              ? `Primary Liability tab updated successfully.`
              : `Additional Liability ${tab} tab updated successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (Object.keys(liabilitiesDataAI).length > 0) {
      UpdateFormOnAi();
    }
  }, [liabilitiesDataAI]);
  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  const formatData = (data, property) => {
    return (
      data?.map((x) => {
        return {
          ...x,
          id: x[property],
        };
      }) ?? []
    );
  };

  const dropDowns = {
    liabilityTypeId: formatData(liabilityData?.data, 'liabilityTypeId'),
  };
  const handleAddTab = async () => {
    if (isUpdateLiabilitiesLoading || isCreateLiabilitiesLoading) return;
    if (tabsArray.length < 5) {
      if (await handleUpdateFunction()) {
        setTabsArray((prev) => {
          let newIndex = prev.length;
          setTab(newIndex);
          tabsArray.map((item) => {
            if (item.isActive) item.isActive = false;
            allLiabilityArray[newIndex]
              ? UpdateFormDataOnLoad(allLiabilityArray[newIndex])
              : setFormData(createFormObject(liabilitiesData));
          });
          return [
            ...prev,
            {
              id: newIndex,
              name: `Additional Liability ${newIndex}`,
              isActive: true,
              isError: false,
            },
          ];
        });
      }
    } else {
      customToast.error("Maximum 5 Liability's allowed !");
    }
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (await handleUpdateFunction()) {
      setPage();
    }
  };
  return (
    <div className=" overflow-auto">
      <H1>Liabilities</H1>
      <p className="antialiased mb-3">
        Provide Borrower's existing loan liabilities.
      </p>
      <FormWrapper
        className={
          'my-3 mx-2 max-h-custom-profile-forms overflow-hidden overflow-y-scroll max-w-[63vw] pb-3'
        }
      >
        <div className="flex flex-row justify-between border-b border-grey">
          <DynamicTabs
            selectedTab={tab}
            isError={isError}
            handleUpdateFunction={handleUpdateFunction}
            onClickTab={async (selectedTab) => {
              //updated the current tab data and then switch's to new one
              setTab(selectedTab);
              allLiabilityArray[selectedTab]
                ? UpdateFormDataOnLoad(allLiabilityArray[selectedTab])
                : setFormData(createFormObject(liabilitiesData));
              return true;
            }}
            tabsArray={tabsArray}
            variant="primary"
            onDelete={() => {
              if (formData?.userLiabilityId) {
                setConfirmDeleteVisibility(true);
                return false;
              }
              handleDeleteLiability();
            }}
          />
          <div
            className="flex flex-col justify-center py-2 cursor-pointer p-sm-regular text-primary500 rounded-md"
            onClick={handleAddTab}
          >
            <>
              <div className="flex flex-row justify-center gap-3 min-w-[150px]">
                <img src={plus} alt="+" className="h-[16px]" />
                <p className="font-medium">Add Liability</p>
              </div>
            </>
          </div>
        </div>
        <div className="w-full p-3 rounded-lg">
          <form onSubmit={onSubmitHandler}>
            <DynamicForm
              data={liabilitiesData}
              formData={formData}
              setFormData={setFormData}
              dropdowns={dropDowns}
              errors={errors}
              isSubmitted={isFormSubmitted}
              additionInfo={applicationData?.data ?? {}}
            />
            {/* <p className="py-2 px-2 text-disabled">
              <span className="text-error font-bold pr-0.5">*</span>Payoff is
              mandatory for Refinance Loan Application.
            </p> */}
            <div className="text-right mt-2">
              <Button
                variant="tertiary"
                onClick={() => {
                  setPreviousPage();
                }}
                style={{ marginRight: '10px' }}
                type="button"
              >
                {' '}
                Back: Assets and Other Credits
              </Button>
              <Button type="submit" variant="primary">
                {'Next: Real Estate Owned'}
              </Button>
            </div>
          </form>
        </div>
      </FormWrapper>
      {confirmDeleteVisibility && (
        <ConfirmDeleteTab
          setConfirmDeleteVisibility={setConfirmDeleteVisibility}
          tabName={`Additional Liability ${tab}`}
          selectedTab={tab}
          onDelete={handleDeleteLiability}
        />
      )}
    </div>
  );
};

export default Liabilities;
