import { useState } from 'react';
//components
import Tabs from 'components/tabs';
import H1 from 'components/h1-typography';
//children components or tabs
import AdminConsumer from './consumer';
import AdminEmployee from './employee';

//custon hooks
import usePageTitle from 'constants/PageTitle';
import Breadcrumb from 'components/breadcrumb';

const Admin = () => {
  const [tab, setTab] = useState(1);
  usePageTitle(tab === 1 ? 'Admin | consumer' : 'Admin | Employee');
  const breadCrumbs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Admin',
      path: '/admin',
    },
  ];
  return (
    <>
      <Breadcrumb breadcrumbs={breadCrumbs} />
      <H1>Admin</H1>
      <Tabs
        onClickTab={(tab) => {
          setTab(tab?.id);
        }}
        tabs={[
          {
            id: 1,
            name: 'Consumer',
          },
          {
            id: 2,
            name: 'Employee',
          },
        ]}
        selectedTabId={tab}
        variant="primary"
      />
      <div className="h-[70vh] w-[100%] flex flex-col">
        {tab === 1 && <AdminConsumer />}
        {tab === 2 && <AdminEmployee />}
      </div>
    </>
  );
};
export default Admin;
