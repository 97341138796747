import React from 'react';

const ViewIcon = ({ color = '#9c9c9c' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      fill="none"
      viewBox="0 0 22 16"
    >
      <path
        stroke={color}
        strokeWidth="2"
        d="M1.91 9.172a2.808 2.808 0 010-2.344A10.002 10.002 0 0111 1c4.034 0 7.51 2.388 9.091 5.828.342.743.342 1.6 0 2.344A10.001 10.001 0 0111.001 15c-4.034 0-7.51-2.388-9.091-5.828z"
      ></path>
      <path
        stroke={color}
        strokeWidth="2"
        d="M14 8a3 3 0 11-6 0 3 3 0 016 0z"
      ></path>
    </svg>
  );
};

export default ViewIcon;
