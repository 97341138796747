/* istanbul ignore file */
import { checkTextInput } from 'pages/profile/utils';

const DuformFields = [
  {
    id: 1,
    name: 'Credit Company',
    type: 'select',
    placeholder: 'Select Credit Company',
    customClass: 'w-2/4',
    isRequired: false,
    propertyName: 'duCreditCompanyId',
    options: [],
  },
  {
    id: 24,
    name: 'FHA Case Number',
    type: 'number',
    placeholder: 'Enter FHA Case Number',
    customClass: 'w-2/4',
    isRequired: false,
    propertyName: 'fhaCaseNumber',
  },
  {
    id: 24,
    name: 'Credit Username',
    type: 'text',
    placeholder: 'Enter Credit Username',
    customClass: 'w-2/4',
    isRequired: false,
    propertyName: 'creditUsername',
    validator: checkTextInput,
  },
  {
    id: 24,
    name: 'Credit Password',
    type: 'text',
    placeholder: 'Enter Credit Password',
    customClass: 'w-2/4',
    isRequired: false,
    propertyName: 'creditPassword',
  },
  {
    id: 24,
    name: 'Credit Ref. - Borrower',
    type: 'text',
    placeholder: 'Enter Credit Ref. - Borrower',
    customClass: 'w-2/4',
    isRequired: false,
    propertyName: 'duCreditReference',
    validator: checkTextInput,
  },
  // {
  //     id: 24,
  //     name: 'Credit Ref. - Borrower 2',
  //     type: 'number',
  //     placeholder: 'Enter Credit Ref. - Borrower 2',
  //     customClass: 'w-2/4',
  //     isRequired: false,
  //     propertyName: 'creditRefBorrower2',
  // },
];
export default DuformFields;
