import React, { useEffect, useState } from 'react';

//icons
import locationIcon from 'assets/icons/location.svg';
import closeIcon from 'assets/icons/closeIcon.svg';
import editIcon from 'assets/icons/edit.svg';
import searchIcon from 'assets/icons/search.svg';
import loanOfficer from '/loanOfficer.png';

//components
import InputGroup from 'components/input-group';
import UserProfile from 'components/user-profile';
import Button from 'components/button';

//libraries
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';

//framer animations
import { scaleMotionProps2 } from 'constants/animationProps';

//API queries
import { useGetLoanOfficersQuery } from 'redux_store/services/client/onboarding';
/**
 * Page3 function
 *
 * This function is a React component that represents the third page of a multi-step form.
 * It takes several props as input and renders a form with various input fields and buttons.
 *
 * Props:
 * - user: The user's name
 * - address: The user's address
 * - selectedOfficer: The selected loan officer
 * - setSelectedOfficer: A function to set the selected loan officer
 * - setSelectedOfficerObj: A function to set the selected loan officer object
 * - selectedOfficerObj: The selected loan officer object
 * - selectedOption: A boolean indicating whether a loan officer is selected
 * - setPage: A function to set the current page
 * - isLoading: A boolean indicating whether data is being loaded
 * - setDirection: A function to set the direction of the form navigation
 * - direction: A boolean indicating the direction of the form navigation
 *
 * Returns:
 * The rendered React component.
 *
 * Usage:
 * <Page3
 *   user={user}
 *   address={address}
 *   selectedOfficer={selectedOfficer}
 *   setSelectedOfficer={setSelectedOfficer}
 *   setSelectedOfficerObj={setSelectedOfficerObj}
 *   selectedOfficerObj={selectedOfficerObj}
 *   selectedOption={selectedOption}
 *   setPage={setPage}
 *   isLoading={isLoading}
 *   setDirection={setDirection}
 *   direction={direction}
 * />
 */
const Page3 = ({
  user,
  address,
  selectedOfficer,
  setSelectedOfficer,
  setSelectedOfficerObj,
  selectedOfficerObj,
  selectedOption,
  setPage,
  isLoading: isUpdateLoading,
  setDirection,
  direction,
}) => {
  //states
  const { data, isLoading, refetch } = useGetLoanOfficersQuery();
  const [showComponent, setShowComponent] = useState(false);
  const [enteredData, setEnteredData] = useState('');
  const [filteredProfiles, setFilteredProfiles] = useState([]);

  const handleOfficerChange = (event) => {
    setSelectedOfficer(event.target.value);
  };

  useEffect(() => {
    if (enteredData) {
      const enteredDataLower = enteredData.toLowerCase();
      const matchingProfiles = data?.data?.users?.filter((profile) => {
        const fullName =
          `${profile.firstName} ${profile.lastName}`.toLowerCase();
        return fullName.includes(enteredDataLower);
      });

      setFilteredProfiles(matchingProfiles);
      setShowComponent(matchingProfiles.length > 0);
    } else if (!address && selectedOfficerObj) {
      setShowComponent(true);
    } else {
      setFilteredProfiles([]);
      setShowComponent(false);
    }
  }, [enteredData]);

  const handleSearchChange = () => {
    setEnteredData(selectedOfficer);
  };

  useEffect(() => {
    if (data?.data?.users) {
      let filteredProfiles = data.data.users.filter((profile) => {
        const fullName = `${profile.firstName} ${profile.lastName}`;
        return fullName.toLowerCase().includes(selectedOfficer?.toLowerCase());
      });

      if (selectedOfficerObj) {
        filteredProfiles = filteredProfiles.filter((profile) => {
          return profile.userId === selectedOfficerObj.userId;
        });
      }

      setFilteredProfiles(filteredProfiles);
    }
  }, [data, selectedOfficer, selectedOfficerObj]);

  return (
    <div className="flex flex-col  justify-center items-center h-full">
      <AnimatePresence mode="wait">
        <motion.div
          {...scaleMotionProps2}
          className="flex flex-col items-center"
        >
          <h2 className="h5-b text-neutral mb-3">Hello {user}!</h2>
          <p className="h2 text-secondary w-[42rem] mb-6 text-center">
            Search or select a Mortgage Broker, and we'll assign them to your
            profile.
          </p>
          {address && (
            <div className="flex gap-2 mb-6">
              {' '}
              <img src={locationIcon} alt="" />{' '}
              <span className="font-medium text-black">{address}</span>{' '}
              <img
                src={editIcon}
                alt=""
                onClick={() => setPage(2)}
                className="cursor-pointer"
              />
            </div>
          )}
          {selectedOption && (
            <div className="w-[24rem] relative">
              <InputGroup
                size="m"
                position="center"
                placeholder="Search Mortgage Broker Name"
                svg={searchIcon}
                value={selectedOfficer}
                onChange={handleOfficerChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSearchChange();
                  }
                }}
              />
              {selectedOfficer && (
                <img
                  src={closeIcon}
                  alt=""
                  className="absolute top-4 right-4 cursor-pointer"
                  onClick={() => {
                    setSelectedOfficer('');
                    setSelectedOfficerObj(null);
                    setEnteredData('');
                    setShowComponent(false);
                  }}
                />
              )}
            </div>
          )}
          {!selectedOption && (
            <div className="w-[24rem] relative">
              <InputGroup
                size="m"
                position="center"
                placeholder="Search"
                svg={searchIcon}
                value={selectedOfficer}
                onChange={handleOfficerChange}
              />
              {selectedOfficer && (
                <img
                  src={closeIcon}
                  alt=""
                  className="absolute top-4 right-4 cursor-pointer"
                  onClick={() => {
                    setSelectedOfficer('');
                    setSelectedOfficerObj(null);
                  }}
                />
              )}
            </div>
          )}

          {showComponent && (
            <div
              className={`flex mt-6 gap-6 cursor-pointer overflow-x-scroll w-[50rem] ${
                filteredProfiles.length <= 6
                  ? 'justify-center'
                  : 'justify-start'
              }`}
            >
              {' '}
              {filteredProfiles?.map((profile, index) => (
                <UserProfile
                  key={index}
                  firstName={profile.firstName}
                  lastName={profile.lastName}
                  loanOfficer={''}
                  selected={selectedOfficerObj?.userId === profile?.userId}
                  className={`hover:primary400-bg hover:rounded ${
                    selectedOfficerObj?.userId === profile?.userId
                      ? 'primary400-bg rounded transition-colors duration-300'
                      : ''
                  } group items-center flex flex-col p-2 transition-colors duration-300`}
                  onClick={() => {
                    setSelectedOfficer(
                      profile.firstName + ' ' + profile.lastName
                    );
                    setSelectedOfficerObj(profile);
                  }}
                />
              ))}
            </div>
          )}
          {address && (
            <div
              className={`flex mt-6 gap-6 cursor-pointer overflow-x-scroll w-[50rem] ${
                filteredProfiles.length <= 6
                  ? 'justify-center'
                  : 'justify-start'
              }`}
            >
              {filteredProfiles?.map((profile, index) => (
                <UserProfile
                  key={index}
                  firstName={profile.firstName}
                  lastName={profile.lastName}
                  loanOfficer={''}
                  selected={selectedOfficerObj?.userId === profile?.userId}
                  className={`hover:primary400-bg hover:rounded ${
                    selectedOfficerObj?.userId === profile?.userId
                      ? 'primary400-bg rounded transition-colors duration-300'
                      : ''
                  } group items-center flex flex-col p-2 transition-colors duration-300`}
                  onClick={() => {
                    setSelectedOfficer(
                      profile.firstName + ' ' + profile.lastName
                    );
                    setSelectedOfficerObj(profile);
                  }}
                />
              ))}
            </div>
          )}
          {!showComponent && enteredData && (
            <div className="mt-3">No results found for “{enteredData}” </div>
          )}
          {filteredProfiles?.length === 0 && address && data && (
            <div className="mt-3">
              No results found for “{selectedOfficer}”{' '}
            </div>
          )}

          <div className="flex gap-4 mt-6">
            <Button
              variant="enabled"
              onClick={
                selectedOption
                  ? () => {
                      setPage(1);
                      setDirection(false);
                    }
                  : () => {
                      setPage(2);
                      setDirection(false);
                    }
              }
            >
              Back
            </Button>
            <Button
              variant={!selectedOfficerObj ? 'disabled' : 'primary'}
              disabled={!selectedOfficerObj}
              onClick={() => {
                setPage(4);
                setDirection(true);
              }}
            >
              {isUpdateLoading && direction ? 'Please wait...' : 'Next'}
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Page3;
