import React from 'react';

const Filter = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color === 'secondary' ? '#151314' : '#365DFF'}
        fillRule="evenodd"
        d="M12.374 19.573l-.008.001-.055.027-.015.003-.01-.003-.056-.027c-.007-.003-.014 0-.018.004l-.003.008-.013.329.004.015.007.01.08.057.012.003.01-.003.08-.057.008-.012.003-.013-.013-.328c-.001-.008-.007-.014-.013-.014zm.204-.087l-.01.001-.142.072-.008.008-.002.008.014.33.003.01.007.005.154.072a.02.02 0 00.022-.007l.004-.01-.027-.472c-.002-.01-.007-.016-.015-.017zm-.55.001a.017.017 0 00-.01 0 .017.017 0 00-.01.005l-.005.01-.026.473c0 .009.005.015.013.018l.011-.001.155-.072.008-.006.003-.008.013-.33-.003-.01-.007-.008-.142-.07z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color === 'secondary' ? '#151314' : '#365DFF'}
        fillRule="evenodd"
        d="M5 5.153A1.153 1.153 0 016.153 4h11.53a1.153 1.153 0 011.154 1.153v1.604c0 .407-.162.798-.45 1.087l-4.162 4.161v5.707A.845.845 0 0113 18.47l-2.857-1.428a.96.96 0 01-.532-.86v-4.176L5.45 7.844A1.537 1.537 0 015 6.757V5.153zm1.537.384v1.22l4.275 4.274a1.154 1.154 0 01.338.815v3.978l1.537.77v-4.748c0-.306.122-.6.338-.815L17.3 6.756V5.537H6.537z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Filter;
