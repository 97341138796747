import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//components
import Tabs from 'components/tabs';
import Profile from './profile';
import Documents from './documents';
import Breadcrumb from 'components/breadcrumb';
//tabs or child components
import FeesWorksheet from './fees-worksheet';
import ProductPricing from './product-pricing/ProductPricing';
import MortageQuote from './product-pricing/MortageQuote';
import ProductAndPricing from './product-pricing/ProductAndPricing';
import mortageQuoteDynamicData from 'pages/borrower-profile/fees-worksheet/formData/MortageQuoteDynamicData';
import CreditSetup from './creditSetup';
import Aus from './aus';
import PreUnderwriting from './preUnderwriting';
import usePageTitle from 'constants/PageTitle';
// query
import { useCheckProfileCompletionQuery } from 'redux_store/services/borrower-profile';

const BorrowerProfile = () => {
  const location = useLocation();
  const state = location.state;

  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');

  //states
  const [componentName, setComponentName] = useState('Product Pricing');
  const [updateChildPath, setUpdateChildPath] = useState(false);
  const [componentQuote, setComponentQuote] = useState({});
  const [tab, setTab] = useState(state?.page ? state?.page : 1);
  const [mortageQuoteData, setMortageQuoteData] = useState(
    mortageQuoteDynamicData
  );
  const [manageScenarioActive, setManageScenarioActive] = useState(false);
  const [mortageQuoteActive, setMortageQuoteActive] = useState(false);
  const [initialPath, setInitialPath] = useState({ name: '', path: '' });
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const pageParentTitle = location.pathname.startsWith('/loanInformation')
    ? 'Loan Information'
    : 'Pipeline';
  const [tabName, setTabName] = useState('Borrower Profile');

  usePageTitle(`${pageParentTitle} | ${tabName}`);

  const { currentData: isProfileCompleted } = useCheckProfileCompletionQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // set dynamic pathname
  useEffect(() => {
    if (location.pathname.startsWith('/loanInformation')) {
      setInitialPath({ name: 'Loan Information', path: '/loanInformation' });
    } else if (location.pathname.startsWith('/pipeline')) {
      setInitialPath({ name: 'Pipeline', path: '/pipeline' });
    }
  }, []);

  //set dynamic breadcrumb data
  useEffect(() => {
    setComponentName('Product Pricing');
    setUpdateChildPath(false);
    if (tab === 1) {
      setTabName('Borrower Profile');
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Borrower Profile',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (tab === 2) {
      setTabName('Documents');
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Documents',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (tab === 3) {
      setTabName('Fees Worksheet');
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Fees Worksheet',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (tab === 3) {
      setTabName('Products & Pricing');
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Products & Pricing',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (tab === 4) {
      setTabName('Fees Worksheet');
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Fees Worksheet',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (tab === 5) {
      setTabName('Credit Setup');
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Credit Setup',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (tab === 6) {
      setTabName('AUS');
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'AUS',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (tab === 7) {
      setTabName('Pre-underwriting');
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Pre-underwriting',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
  }, [tab, initialPath]);

  const switchComponents = () => {
    switch (componentName) {
      case 'Product Pricing':
        return (
          <ProductPricing
            setComponentName={setComponentName}
            setUpdateChildPath={setUpdateChildPath}
            setComponentQuote={setComponentQuote}
          />
        );

      case 'Mortage Quote':
        return <MortageQuote quoteData={componentQuote} />;

      case 'Manage Scenarios':
        return (
          <ProductAndPricing
            setComponentName={setComponentName}
            setUpdateChildPath={setUpdateChildPath}
            quoteData={componentQuote}
            setComponentQuote={setComponentQuote}
          />
        );

      default:
        return (
          <ProductPricing
            setUpdateChildPath={setUpdateChildPath}
            setComponentName={setComponentName}
            setComponentQuote={setComponentQuote}
          />
        );
    }
  };
  //set breadcrumbs for other modules
  useEffect(() => {
    if (componentName === 'Product Pricing' && updateChildPath) {
      setManageScenarioActive(false);
      setMortageQuoteActive(false);
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Products & Pricing',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (componentName === 'Mortage Quote' && updateChildPath) {
      setMortageQuoteActive(true);
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Products & Pricing',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
        {
          name: 'Mortage Quote',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    if (componentName === 'Manage Scenarios' && updateChildPath) {
      setManageScenarioActive(true);
      setBreadCrumbs([
        {
          name: initialPath.name,
          path: initialPath.path,
        },
        {
          name: 'Products & Pricing',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
        {
          name: 'Manage Scenarios',
          path: `${location.pathname}?applicationId=${applicationId}`,
        },
      ]);
    }
    switchComponents();
  }, [componentName, updateChildPath]);

  const handleClearSwitchComponent = () => {
    setUpdateChildPath(true);
    setComponentName('Product Pricing');
  };

  //all tabs
  const allTabs = [
    { id: 1, name: 'Borrower Profile' },
    { id: 2, name: 'Documents' },
    {
      id: 3,
      name: 'Products & Pricing',
      disabled: isProfileCompleted?.data ? false : true,
      message:
        'Please complete your Profile before starting with Product and Pricing',
    },
    {
      id: 4,
      name: 'Fees Worksheet',
      disabled: isProfileCompleted?.data ? false : true,
      message:
        'Please complete your Profile before starting with Fees worksheet',
    },
    { id: 5, name: 'Credit Setup' },
    { id: 6, name: 'AUS' },
    { id: 7, name: 'Pre-underwriting' },
  ];

  // filter tab 5 and 6 if initial path is not pipeline
  const tabs =
    initialPath.name === 'Pipeline'
      ? allTabs
      : allTabs.filter((tab) => tab.id < 5);

  return (
    <div>
      <div className="mb-3">
        <Breadcrumb
          breadcrumbs={breadCrumbs}
          clearSwitchComponent={handleClearSwitchComponent}
        />{' '}
      </div>
      <Tabs
        onClickTab={(tab) => {
          setTab(tab?.id);
        }}
        tabs={tabs}
        selectedTabId={tab}
        key={tab}
        variant="secondary"
      />
      {tab === 1 && <Profile />}
      {tab === 2 && <Documents state={location?.state} />}
      {tab === 3 && switchComponents()}
      {tab === 4 && <FeesWorksheet />}

      {tab === 5 && initialPath.name === 'Pipeline' && (
        <CreditSetup setPage={setTab} />
      )}
      {tab === 6 && initialPath.name === 'Pipeline' && <Aus setPage={setTab} />}
      {tab === 7 && initialPath.name === 'Pipeline' && <PreUnderwriting />}
    </div>
  );
};

export default BorrowerProfile;
