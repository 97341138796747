import React, { Suspense } from 'react';
import Login from 'pages/login/Login';
import Documents from 'pages/documents';
import Tools from 'pages/tools';
import Profile from 'pages/profile';
import Pipeline from 'pages/pipeline';
import Onboarding from 'pages/onboarding/Onboarding';
import Dashboard from 'pages/dashboard';
import NotFound from 'components/404';
const consumerRoutes = [
  {
    path: '/',
    element: <Login />,
    children: [
      {
        path: 'documents',
        element: (
          <Suspense>
            <Documents />
          </Suspense>
        ),
      },
      {
        path: 'tools',
        element: (
          <Suspense>
            <Tools />
          </Suspense>
        ),
      },
      {
        path: 'onboarding',
        element: (
          <Suspense>
            <Onboarding />
          </Suspense>
        ),
      },
      {
        path: 'dashboard',
        element: (
          <Suspense>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: 'profile',
        element: (
          <Suspense>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: 'pipeline',
        element: (
          <Suspense>
            <Pipeline />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
];

export default consumerRoutes;
