import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//admin API'S
export const adminPipelineAPI = createApi({
  reducerPath: 'adminPipeline',
  tagTypes: ['updateAdminPipeline', 'creditUpdate'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    //get loan officer list
    getLoanOfficers: builder.query({
      query: () => {
        return {
          url: `/user/loan_officers/`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get loan program
    getLoanProgram: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/loan_program`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get loan term
    getLoanTerm: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/loan_term`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get loan type
    getLoanType: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/loan_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get loan purpose
    getLoanPurpose: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/loan_purpose`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get credit company
    getCreditCompany: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/credit_company`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get leads pipeline table
    getAllPipelineLeads: builder.query({
      query: ({ data, paging, searchFilter }) => {
        return {
          url: `/loan/loa/lead_info?page_size=${paging?.limit}&page=${paging?.offset}&search=${searchFilter}&pipeline_mode=true`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
      providesTags: ['updateAdminPipeline'],
    }),
    //get leads detail
    getLeadDetails: builder.query({
      query: ({ user_id, application_id }) => {
        return {
          url: `/loan/pipeline/view_lead_details?user_id=${user_id}&application_id=${application_id}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    getCreditDetails: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `/loan/pipeline/credit_setup/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['creditUpdate'],
    }),
    postCreditDetails: builder.mutation({
      query: ({ data, applicationId }) => {
        return {
          url: `/loan/pipeline/${applicationId}`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
      invalidatesTags: ['creditUpdate'],
    }),
    getUnderWrite: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `ai/underwrite?application_id=${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    postUnderWrite: builder.mutation({
      query: ({ applicationId }) => {
        return {
          url: `ai/underwrite?application_id=${applicationId}`,
          method: 'POST',
          headers: getHeaders(),
        };
      },
      //invalidatesTags: ['creditUpdate'],
    }),
  }),
});

export const {
  useGetLoanOfficersQuery,
  useGetAllPipelineLeadsQuery,
  useLazyGetLeadDetailsQuery,
  useGetCreditDetailsQuery,
  usePostCreditDetailsMutation,
  useGetUnderWriteQuery,
  usePostUnderWriteMutation,
  useGetLoanProgramQuery,
  useGetLoanTermQuery,
  useGetLoanTypeQuery,
  useGetLoanPurposeQuery,
  useGetCreditCompanyQuery,
} = adminPipelineAPI;
