/**
 * Function to handle employee and income data for a borrower profile.
 *
 * @param {boolean} readOnly - Flag to indicate if the form is read-only.
 * @param {function} setReadOnly - Function to set the read-only state of the form.
 * @param {number} resetId - ID used to reset the form.
 * @param {function} setResetId - Function to set the reset ID.
 * @returns {JSX.Element} JSX element containing the form for employee and income data.
 */

// React and Components
import React, { useState, useEffect } from 'react';
import Button from 'components/button';
import Radio from 'components/radio';
import { customToast } from 'components/toast';
import DynamicTabs from 'components/dynamicTabs';
import FormWrapper from 'wrapper/form-wrapper';
import ConfirmDeleteTab from 'wrapper/deleteTab';
import Loader from 'wrapper/loader';
//icons
import plus from 'assets/icons/bluePlus.svg';
// RTK QUERY
import {
  useGetEmploymentTypeQuery,
  useGetIncomeTypeQuery,
  useGetStatesQuery,
} from '../../../../redux/services/client/profile';
import {
  useGetBorrowerProfileQuery,
  useUpdateEmployeeAndIncomeMutation,
  useCreateEmploymentAndIncomeMutation,
  useDeleteEmploymentDetailsMutation,
} from '../../../../redux/services/borrower-profile';
// Utils// Sentry for logs
import * as Sentry from '@sentry/browser';
import {
  checkValidations,
  removeEmptyStrings,
  createFormObject,
} from 'pages/profile/utils';
import DynamicForm from 'pages/profile/DynamicForm';
import {
  employmentAndIncome,
  selfEmployed,
} from 'pages/profile/formfields/employeeAndIncome';
import { useLocation } from 'react-router-dom';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';
const EmployeeAndIncome = ({ readOnly, setReadOnly, resetId, setResetId }) => {
  // States and Refs
  const [formData, setFormData] = React.useState({
    ...createFormObject(employmentAndIncome),
    employmentTypeId: 2,
  });
  const [btnTxt, setBtnTxt] = useState('Save');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isError, setIsError] = useState(false);
  const [tab, setTab] = React.useState(0);
  const [employmentUpdateId, setEmploymentUpdateId] = useState(null);
  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allEmployeeArray, setAllEmployeeArray] = useState([]);
  const [tabsArray, setTabsArray] = useState([
    {
      id: 0,
      name: 'Primary Employment',
      isActive: true,
      isError: false,
    },
  ]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  // RTK QUERY
  const { data: employeeTypeData } = useGetEmploymentTypeQuery();
  const { data: incomeTypeData } = useGetIncomeTypeQuery();
  const { data: stateType } = useGetStatesQuery();
  const { data: applicationData, isLoading: isGetApplicationDataLoading } =
    useGetBorrowerProfileQuery(
      { applicationId },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const [updateEmployeeDetails] = useUpdateEmployeeAndIncomeMutation();
  const [createEmployeeDetails] = useCreateEmploymentAndIncomeMutation();
  const [deleteEmployeeDetails, { isLoading: isDeleteEmployeeDetailsLoading }] =
    useDeleteEmploymentDetailsMutation();
  const formatData = (data, property) => {
    return (
      data?.map((x) => {
        return {
          ...x,
          id: x[property],
        };
      }) ?? []
    );
  };

  // dropdown for form fields
  const dropdowns = {
    incomeTypeId: formatData(incomeTypeData?.data, 'incomeTypeId'),
    previousEmployerStateName: formatData(stateType?.data, 'stateId'),
    stateName: formatData(stateType?.data, 'stateId'),
  };
  //Set useEffect to set tabs array and their data on first load
  useEffect(() => {
    if (applicationData?.success) {
      const employeeArray = applicationData?.data?.employment_and_income;
      setAllEmployeeArray(employeeArray);
      if (employeeArray?.length > 1) {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Employment',
            isActive: false,
            isError: false,
          },
        ];
        for (let i = 1; i <= employeeArray.length - 1; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Employment ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(tab);
        newTabsArray[tab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(employeeArray[tab]);
      } else {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Employment',
            isActive: false,
            isError: false,
          },
        ];
        for (let i = 1; i <= tab; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Employment ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(tab);
        newTabsArray[tab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(employeeArray[tab]);
      }
    }
  }, [applicationData, readOnly]);
  //Update id setter
  useEffect(() => {
    setEmploymentUpdateId(
      allEmployeeArray[tab]?.userEmploymentAndIncomeId ?? null
    );
  }, [formData]);
  // Form submit handler
  const onSubmitHandler = async () => {
    if (await handleUpdateFunction()) {
      setBtnTxt('Save');
      setIsFormSubmitted(false);
      setReadOnly(true);
    }
  };
  useEffect(() => {
    if (!formData?.presentEmployerLessThanTwoYears) {
      setFormData((prev) => ({
        ...prev,
        previousEmployerName:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerName
            : null,
        previousEmployerDesignation:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerDesignation
            : null,
        previousEmployerAddress:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerAddress
            : null,
        previousEmployerCityName:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerCityName
            : null,
        previousEmployerStateName:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerStateId
            : null,
        previousEmployerExperienceYears:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerExperienceYears
            : null,
        previousEmployerExperienceMonths:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerExperienceMonths
            : null,
      }));
    }
  }, [formData?.presentEmployerLessThanTwoYears]);

  const UpdateFormDataOnLoad = (data) => {
    setIsFormSubmitted(false);
    if (data) {
      const formData = data;
      setFormData({
        ...formData,
        presentEmployerLessThanTwoYears:
          formData?.presentEmployerLessThanTwoYears === '1',
        employedByOtherParty: formData?.employedByOtherParty === '1',
        selfEmployed: formData?.selfEmployed === '1',
        employmentTypeId: formData?.employmentTypeId,
        incomeTypeId: formData?.incomeTypeId
          ? {
              id: formData?.incomeTypeId,
            }
          : '',

        employerExperienceYears:
          formData?.employerExperienceYears ||
          formData?.employerExperienceYears === 0
            ? {
                id: formData?.employerExperienceYears + 1,
              }
            : '',

        employerExperienceMonths:
          formData?.employerExperienceMonths ||
          formData?.employerExperienceMonths === 0
            ? {
                id: parseInt(formData?.employerExperienceMonths) + 1,
              }
            : '',

        previousEmployerExperienceYears:
          formData?.previousEmployerExperienceYears ||
          formData?.previousEmployerExperienceYears === 0
            ? {
                id: formData?.previousEmployerExperienceYears + 1,
              }
            : '',

        previousEmployerExperienceMonths:
          formData?.previousEmployerExperienceMonths ||
          formData?.previousEmployerExperienceMonths === 0
            ? {
                id: formData?.previousEmployerExperienceMonths + 1,
              }
            : '',

        LOBWorkMonths:
          formData?.LOBWorkMonths || formData?.LOBWorkMonths === 0
            ? {
                id: formData?.LOBWorkMonths + 1,
              }
            : '',

        LOBWorkYears:
          formData?.LOBWorkYears || formData?.LOBWorkYears === 0
            ? {
                id: formData?.LOBWorkYears + 1,
              }
            : '',
        stateName: formData?.stateId ? { id: formData?.stateId } : 0,
        previousStateName: formData?.previousStateId
          ? { id: formData?.previousStateId }
          : '',
        previousEmployerStateName: formData?.previousEmployerStateId
          ? { id: formData?.previousEmployerStateId }
          : '',
      });
    } else {
      setFormData((formData) => ({
        ...(readOnly
          ? {
              ...createFormObject(employmentAndIncome),
              employmentTypeId:
                applicationData?.data?.user_onboarding?.employmentTypeId,
              employerName:
                applicationData?.data?.user_onboarding?.employmentTypeId === 1
                  ? 'Retired'
                  : '',
            }
          : {
              ...formData,
              employmentTypeId:
                applicationData?.data?.user_onboarding?.employmentTypeId,
              employerName:
                applicationData?.data?.user_onboarding?.employmentTypeId === 1
                  ? 'Retired'
                  : '',
            }),
      }));
    }
  };

  useEffect(() => {
    if (resetId === 3) {
      setFormData((formData) => ({
        ...createFormObject(
          formData?.employmentTypeId === 3 ? selfEmployed : employmentAndIncome
        ),
        employmentTypeId: 3,
      }));
      setResetId(0);
    }
  }, [resetId]);
  const handleAddTab = async () => {
    if (isLoading || readOnly) {
      return false;
    }
    if (tabsArray.length < 2) {
      if (await handleUpdateFunction()) {
        setTabsArray((prev) => {
          let newIndex = tab + 1;
          setTab(newIndex);
          tabsArray.map((item) => {
            if (item.isActive) item.isActive = false;
            allEmployeeArray[newIndex]
              ? UpdateFormDataOnLoad(allEmployeeArray[newIndex])
              : setFormData({
                  ...createFormObject(employmentAndIncome),
                  employmentTypeId: 2,
                });
          });
          return [
            ...prev,
            {
              id: newIndex,
              name: `Additional Employment ${newIndex}`,
              isActive: true,
            },
          ];
        });
      }
    } else {
      customToast.error("Maximum 2 Employee's allowed !");
    }
  };
  const formHasNoErrors = () => {
    if (!formData?.employmentTypeId) {
      setIsFormSubmitted(true);
      setIsError(true);
      return false;
    } else if (
      formData?.employmentTypeId &&
      Object.keys(
        checkValidations(
          formData?.employmentTypeId === 3 ? selfEmployed : employmentAndIncome,
          formData
        )
      )?.length > 0
    ) {
      setIsFormSubmitted(true);
      const errors = checkValidations(
        formData?.employmentTypeId === 3 ? selfEmployed : employmentAndIncome,
        formData
      );
      setIsError(true);
      setErrors(errors);
      return false;
    } else {
      setIsError(false);
      setIsFormSubmitted(false);
      setErrors({});
      return true;
      //statement to be executed white no errors
    }
  };
  const getNextOrder = () => {
    return (
      allEmployeeArray.reduce((max, obj) => Math.max(max, obj.order), 0) + 1
    );
  };
  // Function to return the Data as Per API format
  const ApiFormatData = (type) => {
    return {
      ...formData,
      employerExperienceMonths: formData?.employerExperienceMonths
        ? formData?.employerExperienceMonths?.id - 1
        : 0,
      employerExperienceYears: formData?.employerExperienceYears
        ? formData?.employerExperienceYears?.id - 1
        : 0,
      presentEmployerLessThanTwoYears:
        !!formData?.presentEmployerLessThanTwoYears,
      previousEmployerExperienceYears: formData?.previousEmployerExperienceYears
        ? formData?.previousEmployerExperienceYears?.id - 1
        : 0,
      previousEmployerExperienceMonths:
        formData?.previousEmployerExperienceMonths
          ? formData?.previousEmployerExperienceMonths?.id - 1
          : 0,
      LOBWorkYears: formData?.LOBWorkYears
        ? formData?.LOBWorkYears?.id - 1
        : null,
      LOBWorkMonths: formData?.LOBWorkMonths
        ? formData?.LOBWorkMonths?.id - 1
        : null,
      OTIncome: formData?.OTIncome ? formData?.OTIncome : 0,
      incomeTypeId:
        formData.otherIncome != 0 && formData?.incomeTypeId
          ? formData?.incomeTypeId?.id
          : null,
      stateName: formData?.stateName?.name,
      stateId: formData?.stateName ? formData?.stateName?.id : null,
      previousEmployerStateName: formData?.previousEmployerStateName?.name,
      previousEmployerStateId: formData?.previousEmployerStateName
        ? formData?.previousEmployerStateName?.id
        : null,
      bonusIncome: formData?.bonusIncome ? formData?.bonusIncome : 0,
      otherIncome: formData?.otherIncome ? formData?.otherIncome : 0,
      employedByOtherParty: !!formData?.employedByOtherParty,
      selfEmployed: !!formData?.selfEmployed,
      userProfileId: applicationData?.data?.user_profile?.userProfileId,
      userId: applicationData?.data?.user_profile?.userId,
      order: formData.order ?? getNextOrder(),
      applicationId,
      userProfileId:
        type === 'create'
          ? applicationData?.data?.user_profile?.userProfileId
          : null,
      userEmploymentAndIncomeId: type === 'create' ? null : employmentUpdateId,
    };
  };
  const handleUpdateFunction = async () => {
    if (isLoading) {
      return false;
    }
    if (formHasNoErrors()) {
      setIsFormSubmitted(false);
      try {
        setIsLoading(true);
        //condition to update the Form when AI is involved in form filling
        /*         if (Object.keys(incomeData)?.length > 0) {
          const refetchedData = await refetch();
          if (refetchedData?.isSuccess) {
            const uniqueId =
              refetchedData?.data?.data?.employment_and_income?.[0]
                ?.userEmploymentAndIncomeId;
            const data = {
              ...ApiFormatData('update'),
              userEmploymentAndIncomeId: uniqueId,
            };
            const resp = await customToast.promise(
              updateEmployeeDetails({
                data: removeEmptyStrings(data),
              }),
              'Saving',
              tab === 0
                ? `Primary employment tab updated successfully.`
                : `Additional employment ${tab} tab updated successfully.`,
              'Error While Saving'
            );
            if (resp?.data?.status === 200) {
              setIsLoading(false);
              dispatch(
                resetPageInfo({
                  name: 'incomeData',
                })
              );
              return true;
            }
            return false;
          }
        } */
        //If employee update id does not exist then create an employee
        //add new employee function's
        if (!employmentUpdateId) {
          const resp = await customToast.promise(
            createEmployeeDetails({
              data: removeEmptyStrings(ApiFormatData('create')),
            }),
            'Saving',
            tab === 0
              ? `Primary employment tab added successfully.`
              : `Additional employment ${tab} tab added successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        } else {
          // if employee id exist then update the existing employee
          const resp = await customToast.promise(
            updateEmployeeDetails({
              data: removeEmptyStrings(ApiFormatData('update')),
            }),
            'Saving',
            tab === 0
              ? `Primary employment tab updated successfully.`
              : `Additional employment ${tab} tab updated successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        }
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
        setIsLoading(false);
        return false;
      }
    }
  };
  const handleDeleteEmployee = async () => {
    setIsError(false);
    setConfirmDeleteVisibility(false);
    const employmentIncomeId =
      allEmployeeArray[tab]?.userEmploymentAndIncomeId ?? null;
    if (employmentIncomeId) {
      try {
        const resp = await customToast.promise(
          deleteEmployeeDetails({
            employmentIncomeId,
            userId: applicationData?.data?.user_profile?.userId,
          }),
          'Deleting',
          tab === 0
            ? `Primary employment tab removed successfully.`
            : `Additional employment ${tab} tab removed successfully.`,

          'Error While Deleting'
        );
        if (resp?.data?.success) {
          setTab(tab - 1);
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
      return;
    }
    let newTabArray = tabsArray?.filter((tabs) => {
      return tabs.id !== tab;
    });
    setTab(tab - 1);
    newTabArray[tab - 1].isActive = true;
    setTabsArray(newTabArray);
    UpdateFormDataOnLoad(allEmployeeArray[tab - 1]);
    setIsError(false);
  };
  return (
    <>
      {isGetApplicationDataLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <FormWrapper
          className={
            'my-3 mx-2 max-h-borrower-profile overflow-hidden overflow-y-scroll pr-3 pb-3'
          }
        >
          <div className="flex flex-row justify-between border-b border-grey">
            <DynamicTabs
              isError={isError}
              selectedTab={tab}
              handleUpdateFunction={handleUpdateFunction}
              onClickTab={async (selectedTab) => {
                setTab(selectedTab);
                allEmployeeArray[selectedTab]
                  ? UpdateFormDataOnLoad(allEmployeeArray[selectedTab])
                  : setFormData({
                      ...createFormObject(employmentAndIncome),
                      employmentTypeId: 2,
                    });
                return true;
              }}
              tabsArray={tabsArray}
              variant="primary"
              onDelete={() => {
                if (readOnly) return;
                if (employmentUpdateId) {
                  setConfirmDeleteVisibility(true);
                  return;
                }
                handleDeleteEmployee();
              }}
            />
            <div
              className="flex flex-col justify-center py-2 cursor-pointer p-sm-regular text-primary500 rounded-md"
              onClick={handleAddTab}
            >
              <>
                <div className="flex flex-row justify-center gap-3 min-w-[230px]">
                  <img src={plus} alt="+" className="h-[16px]" />
                  <p className="font-medium">Add Additional Employment</p>
                </div>
              </>
            </div>
          </div>

          <div className="flex gap-2 px-2">
            <label className="p-xs-bold pr-3">Employment Type</label>
            {formatData(employeeTypeData?.data, 'employmentTypeId')?.map(
              (radio) => (
                <div className="flex items-center">
                  <Radio
                    onChange={(e) => {
                      if (readOnly) {
                        e.preventDefault();
                        return;
                      }
                      if (
                        allEmployeeArray?.[tab]?.employmentTypeId === radio?.id
                      ) {
                        UpdateFormDataOnLoad(allEmployeeArray?.[tab]);
                        setIsFormSubmitted(false);
                        setErrors({});
                        return 0;
                      }
                      setFormData({
                        ...createFormObject(
                          radio?.id === 3 ? selfEmployed : employmentAndIncome
                        ),
                        employmentTypeId: radio?.id,
                        employerName: radio?.id === 1 ? 'Retired' : '',
                        userProfileId: formData?.userProfileId,
                        userId: formData?.userId,
                      });
                      setIsFormSubmitted(false);
                      setErrors({});
                    }}
                    id={`employment-${radio?.id}`}
                    name={`radio-employment-type`}
                    checked={radio?.id == formData?.employmentTypeId}
                  />
                  <label
                    htmlFor={`employment-${radio?.id}`}
                    className="pl-1 text-black p-xs-bold"
                  >
                    {radio?.name}
                  </label>
                </div>
              )
            )}
          </div>
          {formData?.employmentTypeId === 3 && (
            <DynamicForm
              data={selfEmployed}
              setFormData={setFormData}
              formData={formData}
              errors={errors}
              isSubmitted={isFormSubmitted}
              dropdowns={dropdowns}
              readOnly={readOnly}
            />
          )}
          {formData?.employmentTypeId !== 3 && (
            <DynamicForm
              data={employmentAndIncome}
              setFormData={setFormData}
              formData={formData}
              errors={errors}
              isSubmitted={isFormSubmitted}
              dropdowns={dropdowns}
              readOnly={readOnly}
            />
          )}
          {!readOnly && (
            <div className="text-right mt-2">
              <Button
                variant="tertiary"
                style={{ marginRight: '10px' }}
                type="button"
                onClick={() => {
                  setReadOnly(true);
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" onClick={onSubmitHandler}>
                {' '}
                {btnTxt}
              </Button>
            </div>
          )}
        </FormWrapper>
      )}
      {confirmDeleteVisibility && (
        <ConfirmDeleteTab
          setConfirmDeleteVisibility={setConfirmDeleteVisibility}
          tabName={`Additional Employment ${tab}`}
          selectedTab={tab}
          onDelete={handleDeleteEmployee}
        />
      )}
    </>
  );
};

export default EmployeeAndIncome;
