/* istanbul ignore file */
import { checkTextInput } from 'pages/profile/utils';

const LpaformFields = [
  {
    id: 1,
    name: 'Manage Credit Company',
    type: 'select',
    placeholder: 'Select Credit Company',
    customClass: 'w-2/4',
    isRequired: false,
    propertyName: 'lpaCreditCompanyId',
    options: [],
  },
  {
    id: 24,
    name: 'Credit Ref. - Borrower',
    type: 'text',
    placeholder: 'Enter Credit Ref. - Borrower',
    customClass: 'w-2/4',
    isRequired: false,
    propertyName: 'lpaCreditReference',
    validator: checkTextInput,
  },
];
export default LpaformFields;
