import axios from 'axios';
import { getUserDetails } from 'auth/auth';
import { api } from 'constants/constant';

/**
 * Service function to fetch a client document PDF.
 *
 * @param {Object} params - The parameters for fetching the document.
 * @param {string} params.documentId - The ID of the document.
 * @returns {Promise<Blob>} - A promise that resolves to the PDF document blob.
 */
export const fetchClientDocument = async ({ documentId }) => {
  const { token } = getUserDetails();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const url = `${api}document/file/read_file/${documentId}`;
  const response = await axios.get(url, {
    headers,
    responseType: 'blob',
  });

  const contentDisposition = response.headers['content-disposition'];
  let mimeType = 'application/octet-stream'; // Default MIME type

  if (contentDisposition) {
    const fileTypeMatch = contentDisposition.match(/fileType=([^;]+)/);
    if (fileTypeMatch) {
      const fileType = fileTypeMatch[1].toLowerCase();

      // Map fileType to the corresponding MIME type
      switch (fileType) {
        case 'jpg':
        case 'jpeg':
          mimeType = 'image/jpeg';
          break;
        case 'png':
          mimeType = 'image/png';
          break;
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        // Add more cases as needed
        default:
          mimeType = 'application/octet-stream';
      }
    }
  }

  return new Blob([response.data], { type: mimeType });
};

/**
 * Service function to fetch an admin document PDF.
 *
 * @param {Object} params - The parameters for fetching the document.
 * @param {string} params.documentId - The ID of the document.
 * @param {string} params.userId - The ID of the user.
 * @returns {Promise<Blob>} - A promise that resolves to the PDF document blob.
 */
export const fetchAdminDocument = async ({ documentId, userId }) => {
  const { token } = getUserDetails();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const url = `${api}document/loan_officer/read_file?document_id=${documentId}&user_id=${userId}`;
  const response = await axios.get(url, {
    headers,
    responseType: 'blob',
  });

  const contentDisposition = response.headers['content-disposition'];
  let mimeType = 'application/octet-stream'; // Default MIME type

  if (contentDisposition) {
    const fileTypeMatch = contentDisposition.match(/fileType=([^;]+)/);
    if (fileTypeMatch) {
      const fileType = fileTypeMatch[1].toLowerCase();

      // Map fileType to the corresponding MIME type
      switch (fileType) {
        case 'jpg':
        case 'jpeg':
          mimeType = 'image/jpeg';
          break;
        case 'png':
          mimeType = 'image/png';
          break;
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        // Add more cases as needed
        default:
          mimeType = 'application/octet-stream';
      }
    }
  }

  return new Blob([response.data], { type: mimeType });
};

/**
 * Service function to fetch an admin document PDF.
 *
 * @param {Object} params - The parameters for fetching the document.
 * @param {string} params.quoteNumber - The quote number.
 * @returns {Promise<Blob>} - A promise that resolves to the PDF document blob.
 */
export const downloadQuote = async ({ applicationId, data }) => {
  const { token } = getUserDetails();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  //update this url for product and pricing
  const url = `${api}/loan/product/download_quote/application/${applicationId}`;
  const response = await axios.post(url, data, {
    headers,
    responseType: 'blob',
  });

  return new Blob([response.data], { type: 'application/pdf' });
};
//API to download mortgage quote
export const downloadQuickPriceQuote = async ({ data }) => {
  const { token } = getUserDetails();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  //update this url for quick price
  const url = `${api}loan/price/download_quote`;
  const response = await axios.post(url, data, {
    headers,
    responseType: 'blob',
  });

  return new Blob([response.data], { type: 'application/pdf' });
};

export const downloadOffer = async ({ data, applicationId }) => {
  const { token } = getUserDetails();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const url = `${api}loan/tools/download_quote/application/${applicationId}`;
  const response = await axios.post(url, data, {
    headers,
    responseType: 'blob',
  });

  return new Blob([response.data], { type: 'application/pdf' });
};

export const downloadConsumerOffer = async ({ data, applicationId }) => {
  const { token } = getUserDetails();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const url = `${api}loan/tools/download_quote_consumer/application/${applicationId}`;
  const response = await axios.post(url, data, {
    headers,
    responseType: 'blob',
  });

  return new Blob([response.data], { type: 'application/pdf' });
};
