/* istanbul ignore file */
import {
  checkNMLSidValidation,
  checkPhoneNumberValidation,
  withValidations,
  checkTextInput,
  checkEmailValidation,
  checkAlphanumeric,
} from 'pages/profile/utils';
const addNewEmployee = [
  {
    id: 1,
    name: 'First Name',
    type: 'text',
    placeholder: 'Enter First Name',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'firstName',
    validator: checkTextInput,
  },
  {
    id: 2,
    name: 'Last Name',
    type: 'text',
    placeholder: 'Enter Last Name',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'lastName',
    validator: checkTextInput,
  },
  {
    id: 3,
    name: 'Email',
    type: 'text',
    placeholder: 'Enter Email',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'email',
    checkValidation: [checkEmailValidation],
  },
  {
    id: 4,
    name: 'Date of Birth',
    type: 'date',
    placeholder: 'MM/DD/YYYY',
    customClass: 'w-[31.5%] mx-2 my-2',
    isRequired: true,
    propertyName: 'dateOfBirth',
    disableFutureDate: true,
  },
  {
    id: 5,
    name: 'Phone Number',
    type: 'phone',
    placeholder: 'Enter Phone Number',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'phoneNumber',
    checkValidation: [checkPhoneNumberValidation],
    pattern: '(###) ###-####',
  },
  {
    id: 6,
    name: 'NMLS ID',
    type: 'number',
    placeholder: 'Enter NMLS ID',
    customClass: 'w-1/3',
    isRequired: true,
    checkValidation: [checkNMLSidValidation],
    pattern: '[0-9]{0,7}',
    propertyName: 'NMLSID',
  },
  {
    id: 7,
    name: 'Company',
    type: 'text',
    placeholder: 'Enter Company Name',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'companyName',
  },
  {
    id: 8,
    name: 'Employee Type',
    type: 'select',
    placeholder: 'Select Employee Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'employeeTypeId',
  },
  {
    id: 9,
    name: 'Branch Name',
    type: 'text',
    placeholder: 'Enter Branch Name',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'branch',
    validator: checkAlphanumeric,
  },
  {
    id: 10,
    name: 'Address',
    type: 'text',
    placeholder: 'Enter Address',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'address',
  },
  {
    id: 11,
    name: 'City',
    type: 'text',
    placeholder: 'Enter City',
    customClass: 'w-1/6',
    isRequired: true,
    propertyName: 'cityName',
    validator: checkTextInput,
  },
  {
    id: 12,
    name: 'State',
    type: 'select',
    placeholder: 'Select state',
    customClass: 'w-1/6',
    isRequired: true,
    propertyName: 'stateName',
    options: [],
  },
  {
    id: 13,
    name: 'Zip Code',
    type: 'number',
    placeholder: 'Enter Zip Code',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'zipCode',
  },
];
const addNewEmployeeFields = withValidations(addNewEmployee);
export default addNewEmployeeFields;
