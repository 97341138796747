import Button from 'components/button';
import CircularProgressBar from 'components/circular-progress-bar';
import Select from 'react-select';
import Tabs from 'components/tabs';
import User from 'components/user';
import PersonalDetails from './forms/PersonalDetails';
import React, { useEffect, useState } from 'react';
import LoanInformation from './forms/LoanInformation';
import EmployeeAndIncome from './forms/EmploymentIncome';
import AssetsAndOtherCredits from './forms/Assets';
import Liabilities from './forms/Liabilities';
import RealEstateOwned from './forms/RealEstate';
import DeclarationForm from './forms/Declaration';
import Demographics from './forms/Demographics';
import HousingExpense from './forms/HousingExpense';
import TransactionDetails from './forms/TransactionDetails';
import {
  useGetBorrowerProfileQuery,
  useUpdateStatusMutation,
} from '../../../redux/services/borrower-profile';
import { useLocation } from 'react-router-dom';
import { customToast } from 'components/toast';
import { useGetApplicationStatusQuery } from '../../../redux/services/client/profile';
import { toast } from 'react-toastify';
import { bgColors, textColors, borderColors } from '../../../themes/colors';
const Profile = () => {
  const location = useLocation();
  const state = location?.state;
  const [profileStage, setProfileStage] = useState(1);
  const [readOnly, setReadOnly] = useState(state?.isEdit ? false : true);
  const [resetId, setResetId] = useState(0);

  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const [profileStatusId, setProfileStatusId] = useState('');

  const { data: applicationData, refetch: refetchBorrowerInfo } =
    useGetBorrowerProfileQuery(
      { applicationId },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const leadName =
    (applicationData?.data?.user_profile?.firstName || '') +
    (applicationData?.data?.user_profile?.lastName || '');
  const email = applicationData?.data?.user_profile?.email || '';
  const percentage = applicationData?.data?.completionStatus || 0;

  const { data: applicationStatusData } = useGetApplicationStatusQuery();
  const [updateStatus] = useUpdateStatusMutation();

  const updateApplicationStatus = async (statusId) => {
    statusId = statusId.applicationStatusId;
    const loadingId = customToast.loading('Updating Profile Status...');
    try {
      const data = {
        applicationStatusId: statusId,
        userId: applicationData?.data?.user_profile?.userId,
      };

      /* customToast.promise(
        updateStatus({ data, applicationId }),
        '',
        'Profile Status updated successfully',
        'Error while updating the status'
      ); */

      const resp = await updateStatus({ data, applicationId });
      if (resp?.data?.status === 200) {
        customToast.success('Profile Status updated successfully');
        setProfileStatusId(+statusId);
      } else {
        customToast.error(resp?.error?.data?.message);
      }
    } catch (error) {
    } finally {
      toast.dismiss(loadingId);
    }
  };

  useEffect(() => {
    if (applicationData?.data?.applicationStatusId) {
      setProfileStatusId(applicationData?.data?.applicationStatusId);
    }
  }, [applicationData?.data?.applicationStatusId]);

  const onCancel = (bool) => {
    setReadOnly(bool);
    setResetId(0);
  };
  const placeholderMapper = (id) => {
    if (applicationStatusData?.data) {
      let placeholder = applicationStatusData?.data?.filter((item) => {
        return item.applicationStatusId === id;
      });
      return placeholder[0]?.name ?? 'Select';
    }
  };
  return (
    <>
      <div className="py-2 flex gap-4 items-center justify-between my-2">
        <div className="flex items-center gap-4">
          <User title={leadName} subtitle={email} size="md" />
          <div>
            <Select
              menuShouldScrollIntoView={true}
              menuShouldBlockScroll={true}
              menuPlacement="auto"
              menuPosition="fixed"
              defaultValue={profileStatusId}
              onChange={updateApplicationStatus}
              options={applicationStatusData?.data}
              isClearable={false}
              getOptionLabel={(option) => {
                return option?.name;
              }}
              getOptionValue={(value) => {
                return value?.applicationStatusId;
              }}
              placeholder={placeholderMapper(profileStatusId)}
              className="w-full"
              noOptionsMessage={() => 'No Date found'}
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: '12px',
                  position: 'relative',
                  fontWeight: '400',
                  borderRadius: '5px',
                  boxShadow: 'none',
                  paddingRight: '9px',
                  border: `2px solid ${borderColors.grey}`,
                  '&:hover': {
                    cursor: 'pointer',
                    border: `2px solid ${borderColors.grey}`,
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: bgColors.tertiary_bg,
                  color: textColors.black,
                  fontSize: '12px',
                  fontWeight: '400',
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: bgColors.primary50,
                  },
                  '&:active': {
                    backgroundColor: bgColors.primary50,
                  },
                }),
                menu: (provided, state) => ({
                  ...provided,
                  borderRadius: '5px',
                  color: textColors.black,
                  fontSize: '12px',
                  fontWeight: '400',
                }),
                indicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral80: textColors.black,
                },
              })}
            />
          </div>
          <div
            className={`h-[35px] w-[183px] ${percentage === 100 ? 'bg-primary100' : 'bg-warning_light'} rounded-md flex justify-between items-center gap-1 px-4`}
          >
            <CircularProgressBar
              strokeWidth={3}
              sqSize={25}
              percentageColor={`${percentage === 100 ? 'stroke-primary500' : 'stroke-warning300'}`}
              circleColor={`${percentage === 100 ? 'stroke-primary100' : 'stroke-warning100'}`}
              percentage={percentage}
            />
            <span
              className={`font-medium-bold ${percentage === 100 ? 'text-primary500' : 'text-warning300'}`}
            >
              {percentage}% Completed
            </span>
          </div>
        </div>

        <div>
          {readOnly && (
            <Button
              variant="primary_sm"
              onClick={() => {
                setReadOnly(false);
              }}
            >
              Edit Profile
            </Button>
          )}
          {/* {!readOnly && (
            <Button
              variant="primary_sm"
              onClick={() => {
                setResetId(profileStage);
              }}
            >
              Reset
            </Button>
          )} */}
        </div>
      </div>
      <div className="py-4">
        <Tabs
          tabs={[
            {
              id: 1,
              name: 'Personal Details',
            },
            {
              id: 2,
              name: 'Loan Info',
            },
            {
              id: 3,
              name: 'Employment',
            },
            {
              id: 4,
              name: 'Assets',
            },
            {
              id: 5,
              name: 'Liabilities',
            },
            {
              id: 6,
              name: 'REO',
            },
            {
              id: 7,
              name: 'Housing Expense',
            },
            {
              id: 8,
              name: 'Details of Transaction',
            },
            {
              id: 9,
              name: 'Declaration',
            },
            {
              id: 10,
              name: 'Demographics',
            },
          ]}
          isScrollable={true}
          selectedTabId={profileStage}
          variant="primary"
          onClickTab={(tab) => {
            setResetId(0);
            setProfileStage(tab?.id);
          }}
        />
      </div>
      {profileStage === 1 && (
        <PersonalDetails
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
        />
      )}
      {profileStage === 2 && (
        <LoanInformation
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
        />
      )}
      {profileStage === 3 && (
        <EmployeeAndIncome
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
        />
      )}
      {profileStage === 4 && (
        <AssetsAndOtherCredits
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
        />
      )}
      {profileStage === 5 && (
        <Liabilities
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
        />
      )}
      {profileStage === 6 && (
        <RealEstateOwned
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
        />
      )}
      {profileStage === 7 && (
        <HousingExpense
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
          applicationData={applicationData}
        />
      )}
      {profileStage === 8 && (
        <TransactionDetails
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
          applicationData={applicationData}
        />
      )}
      {profileStage === 9 && (
        <DeclarationForm
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
        />
      )}
      {profileStage === 10 && (
        <Demographics
          readOnly={readOnly}
          setReadOnly={onCancel}
          resetId={resetId}
          setResetId={setResetId}
        />
      )}
    </>
  );
};

export default Profile;
