// React and Components
import React, { useState, useEffect } from 'react';
import Button from 'components/button';
import { customToast } from 'components/toast';
import { useLocation } from 'react-router-dom';
import FormWrapper from 'wrapper/form-wrapper';
import ConfirmDeleteTab from 'wrapper/deleteTab';
import DynamicTabs from 'components/dynamicTabs';
import Loader from 'wrapper/loader';
//icons
import plus from 'assets/icons/bluePlus.svg';
// Utils
import DynamicForm from 'pages/profile/DynamicForm';
import {
  checkValidations,
  createFormObject,
  removeEmptyStrings,
} from 'pages/profile/utils';
import { liabilitiesData } from 'pages/profile/formfields/liabilitiesData';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';
// sentry for logs
import * as Sentry from '@sentry/browser';
// RTK Queries
import {
  useGetBorrowerProfileAdminQuery,
  useUpdateLiabilitiesAdminMutation,
  useCreateLiabilitiesAdminMutation,
  useDeleteLiabilitiesDetailsAdminMutation,
} from '../../../../redux/services/borrower-profile';
import { useGetLiabilityTypeQuery } from '../../../../redux/services/client/profile';
/**
 * Function to handle liabilities form submission and update/create liabilities data.
 *
 * @param {boolean} readOnly - Flag to indicate if the form is in read-only mode.
 * @param {function} setReadOnly - Function to set the read-only mode of the form.
 * @param {number} resetId - ID used to reset the form.
 * @param {function} setResetId - Function to set the reset ID.
 * @returns {JSX.Element} - Returns the liabilities form component.
 */
const Liabilities = ({ readOnly, setReadOnly, resetId, setResetId }) => {
  // states and refs
  const [formData, setFormData] = React.useState(
    createFormObject(liabilitiesData)
  );
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [tab, setTab] = useState(0);
  const [isError, setIsError] = useState(false);
  const [allLiabilityArray, setAllLiabilityArray] = useState([]);
  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false);
  const [tabsArray, setTabsArray] = useState([
    {
      id: 0,
      name: 'Primary Liability',
      isActive: true,
      isError: false,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const isEdit = React.useRef(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');

  // RTK Query
  const { data: liabilityData } = useGetLiabilityTypeQuery();
  const { data: applicationData, isLoading: isGetApplicationDataLoading } =
    useGetBorrowerProfileAdminQuery(
      { applicationId },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const [updateLiabilities, { isLoading: isUpdateLiabilitiesLoading }] =
    useUpdateLiabilitiesAdminMutation();
  const [createLiabilities, { isLoading: isCreateLiabilitiesLoading }] =
    useCreateLiabilitiesAdminMutation();
  const [deleteLiabilityDetails] = useDeleteLiabilitiesDetailsAdminMutation();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (await handleUpdateFunction()) {
      setIsFormSubmitted(false);
      setReadOnly(true);
    }
  };
  useEffect(() => {
    if (applicationData?.success) {
      const liabilityArray = applicationData?.data?.user_libality_details;
      const persistedTab = tab ?? 0;
      setAllLiabilityArray(liabilityArray);
      if (liabilityArray?.length > 1) {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Liability',
            isActive: false,
            isError: false,
          },
        ];
        if (tab === liabilityArray.length) {
          for (let i = 1; i <= tab; i++) {
            newTabsArray.push({
              id: i,
              name: `Additional Liability ${i}`,
              isActive: false,
              isError: false,
            });
          }
          setTab(persistedTab);
          newTabsArray[persistedTab].isActive = true;
          setTabsArray(newTabsArray);
          UpdateFormDataOnLoad(liabilityArray[persistedTab]);
          return;
        }
        for (let i = 1; i <= liabilityArray.length - 1; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Liability ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(persistedTab);
        newTabsArray[persistedTab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(liabilityArray[persistedTab]);
      } else {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Liability',
            isActive: false,
            isError: false,
          },
        ];
        for (let i = 1; i <= tab; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Liability ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(persistedTab);
        newTabsArray[persistedTab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(liabilityArray[persistedTab]);
      }
    }
  }, [applicationData, readOnly]);

  const UpdateFormDataOnLoad = (data) => {
    setIsFormSubmitted(false);
    if (data) {
      isEdit.current = true;
      const formData = data;
      setFormData({
        ...formData,
        isPayoffRequired: formData?.isPayoffRequired === '1' ? true : false,
        isIncludeBalance: formData?.isIncludeBalance === '1' ? true : false,
        isIncludePayment: formData?.isIncludePayment === '1' ? true : false,
        liabilityTypeId: formData?.liabilityTypeId
          ? {
              id: formData?.liabilityTypeId,
            }
          : '',
        isExclude: formData?.isExclude
          ? {
              id: formData?.isExclude === '1' ? 1 : 2,
            }
          : '',
      });
    } else {
      setFormData(() => ({
        ...createFormObject(liabilitiesData),
      }));
    }
  };

  const formatData = (data, property) => {
    return (
      data?.map((x) => {
        return {
          ...x,
          id: x[property],
        };
      }) ?? []
    );
  };

  useEffect(() => {
    if (resetId === 5) {
      setFormData((formData) => ({
        ...createFormObject(liabilitiesData),
      }));
      setResetId(0);
    }
  }, [resetId]);

  const dropDowns = {
    liabilityTypeId: formatData(liabilityData?.data, 'liabilityTypeId'),
  };
  const formHasNoErrors = () => {
    const errors = checkValidations(liabilitiesData, formData, {
      ...applicationData?.data,
      loan_details: applicationData?.data?.loan_details,
    });
    if (Object.keys(errors)?.length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
      setIsError(true);
      return false;
    } else {
      setErrors({});
      setIsFormSubmitted(false);
      setIsError(false);
      return true;
    }
  };
  const getNextOrder = () => {
    return (
      allLiabilityArray.reduce((max, obj) => Math.max(max, obj.order), 0) + 1
    );
  };
  const ApiFormatData = () => {
    return {
      ...formData,
      liabilityTypeId: formData?.liabilityTypeId
        ? formData?.liabilityTypeId?.id
        : null,
      isExclude:
        formData?.isExclude?.id === 1
          ? true
          : formData?.isExclude?.id === 2
            ? false
            : null,
      remainingMonths: formData?.remainingMonths || 0,
      monthlyPayment: formData?.monthlyPayment || 0,
      totalBalance: formData?.totalBalance || 0,
      isPayoffRequired: !!formData?.isPayoffRequired,
      isIncludeBalance: !!formData?.isIncludeBalance,
      isIncludePayment: !!formData?.isIncludePayment,
      assetTypeId: 1,
      accountNumber: String(formData?.accountNumber ?? ''),
      order: formData.order ?? getNextOrder(),
      userId: applicationData?.data?.personal_details?.userId,
      applicationId,
      userProfileId: applicationData?.data?.personal_details?.userProfileId,
    };
  };
  const handleUpdateFunction = async () => {
    if (formHasNoErrors()) {
      setIsFormSubmitted(false);
      try {
        if (isLoading) {
          return false;
        }
        //condition to update the Form when AI is involved in form filling
        /* if (Object.keys(liabilitiesDataAI).length > 0) {
          const refetchedData = await refetch();
          if (refetchedData?.isSuccess) {
            const uniqueId =
              refetchedData?.data?.data?.user_libality_details?.[0]
                ?.userLiabilityId;
            const data = {
              ...ApiFormatData('update'),
              userLiabilityId: uniqueId,
            };
            let resp = await customToast.promise(
              updateLiabilities({
                data: removeEmptyStrings(data),
              }),
              'Saving',
              tab === 0
                ? `Primary Liability tab updated successfully.`
                : `Additional Liability ${tab} tab updated successfully.`,
              'Error While Saving'
            );
            if (resp?.data?.status === 200) {
              setIsLoading(false);
              dispatch(
                resetPageInfo({
                  name: 'liabilitiesData',
                })
              );
              return true;
            }
            return false;
          }
        } */
        if (!formData?.userLiabilityId) {
          //create liability if id does'nt exist
          let resp = await customToast.promise(
            createLiabilities({
              data: removeEmptyStrings(ApiFormatData('create')),
            }),
            'Saving',
            tab === 0
              ? `Primary Liability tab added successfully.`
              : `Additional Liability ${tab} tab added successfully.`,
            'Error While Saving'
          );
          if (resp.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        } else {
          // if liability id exist then update the existing employee
          setIsLoading(true);
          let resp = await customToast.promise(
            updateLiabilities({
              data: removeEmptyStrings(ApiFormatData('update')),
            }),
            'Saving',
            tab === 0
              ? `Primary Liability tab updated successfully.`
              : `Additional Liability ${tab} tab updated successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        setIsLoading(false);
      }
    }
  };
  const handleAddTab = async () => {
    if (isUpdateLiabilitiesLoading || isCreateLiabilitiesLoading || readOnly)
      return;
    if (tabsArray.length < 10) {
      if (await handleUpdateFunction()) {
        setTabsArray((prev) => {
          let newIndex = prev.length;
          setTab(newIndex);
          tabsArray.map((item) => {
            if (item.isActive) item.isActive = false;
            allLiabilityArray[newIndex]
              ? UpdateFormDataOnLoad(allLiabilityArray[newIndex])
              : setFormData(createFormObject(liabilitiesData));
          });
          return [
            ...prev,
            {
              id: newIndex,
              name: `Additional Liability ${newIndex}`,
              isActive: true,
              isError: false,
            },
          ];
        });
      }
    } else {
      customToast.error("Maximum 10 Liability's allowed !");
    }
  };
  const handleDeleteLiability = async () => {
    setIsError(false);
    setConfirmDeleteVisibility(false);
    const userLiabilityId = allLiabilityArray[tab]?.userLiabilityId ?? null;
    if (userLiabilityId > 0) {
      try {
        let resp = await customToast.promise(
          deleteLiabilityDetails({
            userLiabilityId,
            userId: applicationData?.data?.personal_details?.userId,
          }),
          'Deleting',
          tab === 0
            ? `Primary Liability tab removed successfully.`
            : `Additional Liability ${tab} tab removed successfully.`,
          'Error While Deleting'
        );
        if (resp?.data?.status === 200) {
          setTab(tab - 1);
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
      return;
    }
    let newTabArray = tabsArray?.filter((tabs) => {
      return tabs.id !== tab;
    });
    setTab(tab - 1);
    newTabArray[tab - 1].isActive = true;
    setTabsArray(newTabArray);
    UpdateFormDataOnLoad(allLiabilityArray[tab - 1]);
    setIsError(false);
  };
  return (
    <>
      {isGetApplicationDataLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <FormWrapper
          className={
            'my-3 mx-2 max-h-custom-profile-forms overflow-hidden overflow-y-scroll max-w-[63vw] pb-3'
          }
        >
          <div className="flex flex-row justify-between border-b border-grey">
            <DynamicTabs
              selectedTab={tab}
              isError={isError}
              handleUpdateFunction={handleUpdateFunction}
              onClickTab={async (selectedTab) => {
                //updated the current tab data and then switch's to new one
                if (isLoading) return;
                setTab(selectedTab);
                allLiabilityArray[selectedTab]
                  ? UpdateFormDataOnLoad(allLiabilityArray[selectedTab])
                  : setFormData(createFormObject(liabilitiesData));
                return true;
              }}
              tabsArray={tabsArray}
              variant="primary"
              onDelete={() => {
                if (readOnly) return;
                if (formData?.userLiabilityId) {
                  setConfirmDeleteVisibility(true);
                  return false;
                }
                handleDeleteLiability();
              }}
            />
            <div
              className="flex flex-col justify-center py-2 cursor-pointer p-sm-regular text-primary500 rounded-md"
              onClick={handleAddTab}
            >
              <>
                <div className="flex flex-row justify-center gap-3 min-w-[150px]">
                  <img src={plus} alt="+" className="h-[16px]" />
                  <p className="font-medium">Add Liability</p>
                </div>
              </>
            </div>
          </div>
          <DynamicForm
            data={liabilitiesData}
            formData={formData}
            setFormData={setFormData}
            dropdowns={dropDowns}
            errors={errors}
            isSubmitted={isFormSubmitted}
            additionInfo={
              applicationData?.data
                ? {
                    ...applicationData?.data,
                    loan_details: applicationData?.data?.loan_details,
                  }
                : {}
            }
            readOnly={readOnly}
          />
          {/* <p className="py-2 px-2 text-disabled">
            <span className="text-error font-bold pr-0.5">*</span>Payoff is
            mandatory for Refinance Loan Application.
          </p> */}
          {!readOnly && (
            <div className="text-right mt-2">
              <Button
                variant="tertiary"
                style={{ marginRight: '10px' }}
                type="button"
                onClick={() => {
                  setReadOnly(true);
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" onClick={onSubmitHandler}>
                {' '}
                {'Save'}
              </Button>
            </div>
          )}
        </FormWrapper>
      )}
      {confirmDeleteVisibility && (
        <ConfirmDeleteTab
          setConfirmDeleteVisibility={setConfirmDeleteVisibility}
          tabName={`Additional Liability ${tab}`}
          selectedTab={tab}
          onDelete={handleDeleteLiability}
        />
      )}
    </>
  );
};

export default Liabilities;
