const requiredPermissionsForLoanOfficer = [
  'create:loan',
  'get:lead',
  'update:loan',
];
const requiredPermissionsForConsumer = ['create:loan', 'update:loan'];
const requiredPermissionsForOrganization = [
  'create:loan',
  'get:lead',
  'get:reports',
  'update:loan',
];

export const hasPermissions = (permissions = [], userType) => {
  const checkExactPermissions = (requiredPermissions) => {
    return (
      permissions.length === requiredPermissions.length &&
      requiredPermissions.every((permission) =>
        permissions.includes(permission)
      )
    );
  };

  switch (userType) {
    case 'consumer':
      return checkExactPermissions(requiredPermissionsForConsumer);
    case 'loanOfficer':
      return checkExactPermissions(requiredPermissionsForLoanOfficer);
    case 'organization':
      return checkExactPermissions(requiredPermissionsForOrganization);
    default:
      return false;
  }
};
