import * as Sentry from '@sentry/browser';

export const valueNullValidation = (text) => {
  if (text) {
    return {
      isValid: true,
      errorMessage: '',
    };
  }
  return {
    isValid: false,
    errorMessage: 'Field cannot be empty',
  };
};

export const checkTextInput = (value) => {
  const regex = '^[A-Za-z ]*$';
  if (value) {
    return removeSpecialCharacters(value);
  }
};

function removeSpecialCharacters(str) {
  return str.replace(/[^A-Za-z ]/g, '');
}

export const checkAlphanumeric = (value) => {
  if (value) {
    return value.replace(/[^a-zA-Z0-9]/g, '');
  }
};

export const checkSSN = (input) => {
  // Remove all non-digit characters
  let numericInput = input.replace(/\D/g, '');

  // Limit to 9 digits
  if (numericInput.length > 9) {
    numericInput = numericInput.slice(0, 9);
  }

  // Format the SSN
  let formattedSSN = numericInput.replace(
    /^(\d{0,3})(\d{0,2})(\d{0,4})$/,
    (match, p1, p2, p3) => {
      let parts = [p1, p2, p3].filter((part) => part !== '');
      return parts.join('-');
    }
  );

  return formattedSSN;
};

export const validatePercentageInput = (event) => {
  const value = event.target.value;
  if (value) {
    const regex = /^\d{0,6}(\.\d{0,3})?%?$/;
    return value.replace(regex, '');
  }

  // Update the input value with the validated value
  return value;
};

export const numberValidation = (text) => {
  if (parseFloat(text) === 0 || !text) {
    return {
      isValid: false,
      errorMessage: 'Field cannot be empty or 0',
    };
  } else if (isNaN(+text)) {
    return {
      isValid: false,
      errorMessage: 'Enter valid value',
    };
  } else if (
    String(text)?.includes('-') ||
    String(text)?.includes('e') ||
    String(text)?.includes('+')
  ) {
    return {
      isValid: false,
      errorMessage: 'Enter valid number',
    };
  }
  return {
    isValid: true,
    errorMessage: '',
  };
};

export const isLessThanZeroValidation = (text) => {
  if (parseFloat(text) < 0) {
    return {
      isValid: false,
      errorMessage: 'Value should not be less than 0',
    };
  }
  return {
    isValid: true,
    errorMessage: '',
  };
};

export const checkPhoneNumberValidation = (text) => {
  try {
    if (text) {
      if (
        (typeof text === 'string' || typeof text === 'number') &&
        String(text)?.match(/\d/g).length === 10
      ) {
        return {
          isValid: true,
          errorMessage: '',
        };
      }
      return {
        isValid: false,
        errorMessage: 'Enter valid Phone Number',
      };
    }
    return {
      isValid: false,
      errorMessage: 'Field cannot be empty',
    };
  } catch (error) {
    Sentry.captureException(error);

    return {
      isValid: false,
      errorMessage: 'Field cannot be empty',
    };
  }
};

export const checkEmailValidation = (text) => {
  try {
    if (text) {
      // Updated regex to disallow spaces
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        typeof text === 'string' &&
        emailRegex.test(text) &&
        !/\s/.test(text)
      ) {
        return {
          isValid: true,
          errorMessage: '',
        };
      }
      return {
        isValid: false,
        errorMessage: 'Enter a valid Email Address without spaces',
      };
    }
    return {
      isValid: false,
      errorMessage: 'Field cannot be empty',
    };
  } catch (error) {
    Sentry.captureException(error);

    return {
      isValid: false,
      errorMessage: 'Field cannot be empty',
    };
  }
};

export const selectValidation = (data) => {
  if (data?.id) {
    return {
      isValid: true,
      errorMessage: '',
    };
  }
  return {
    isValid: false,
    errorMessage: 'Field cannot be empty',
  };
};

export const createFormObject = (data) => {
  return data?.reduce((acc, value) => {
    if (value.type === 'container') {
      const formFields = value.formFields?.reduce(
        (acc, value) => ({ ...acc, [value.propertyName]: '' }),
        {}
      );
      return { ...acc, ...formFields };
    }
    return { ...acc, [value.propertyName]: '' };
  }, {});
};

export const checkMinAndMax = (value, config) => {
  if (config?.min && config?.max) {
    const isValid =
      parseFloat(value) <= config?.max && parseFloat(value) >= config?.min;
    return {
      isValid,
      errorMessage: isValid
        ? ''
        : `${value} should be between ${config?.min} and ${config?.max}`,
    };
  }
  if (config?.min) {
    const isValid = parseFloat(value) <= config?.min;
    return {
      isValid,
      errorMessage: isValid
        ? ''
        : `${value} should be greater than ${config?.min}`,
    };
  }

  if (config?.max) {
    const isValid = parseFloat(value) <= config?.max;
    return {
      isValid,
      errorMessage: isValid
        ? ''
        : `${value} should be less than ${config?.max}`,
    };
  }
  return {
    isValid: true,
    errorMessage: '',
  };
};

function applyValidations(obj) {
  switch (obj.type) {
    case 'text':
      return [valueNullValidation];
    case 'number':
      return [
        valueNullValidation,
        numberValidation,
        isLessThanZeroValidation,
        checkMinAndMax,
      ];
    case 'select':
      return [selectValidation];
    case 'checkbox':
      return [valueNullValidation];
    default:
      return [valueNullValidation];
  }
}

function applyValidationsToFields(fields) {
  return fields.map((field) => ({
    ...field,
    checkValidation: Array.isArray(field?.checkValidation)
      ? [...field?.checkValidation, ...applyValidations(field)]
      : applyValidations(field),
  }));
}

// Function to add validations based on type
export const withValidations = (array) => {
  return array.map((obj) => {
    if (obj.type === 'container' && obj.formFields) {
      return {
        ...obj,
        formFields: applyValidationsToFields(obj.formFields),
      };
    } else {
      return {
        ...obj,
        checkValidation: Array.isArray(obj?.checkValidation)
          ? [...obj?.checkValidation, ...applyValidations(obj)]
          : applyValidations(obj),
      };
    }
  });
};

function transformErrorsToObject(errors) {
  const errorObj = {};

  errors.forEach((error) => {
    if (!(error.fieldName in errorObj)) {
      errorObj[error.fieldName] = error.errorMessage;
    }
  });

  return errorObj;
}

export const checkValidations = (array, formData, additionalInfo) => {
  const errors = [];

  array.forEach((obj) => {
    let fieldValue, validationFunctions;

    // Determine the validation function to use
    if (
      (typeof obj.isRequired === 'function' &&
        obj.isRequired(formData, additionalInfo)) ||
      (typeof obj.isRequired !== 'function' && obj.isRequired) ||
      obj.type === 'container'
    ) {
      if (obj.type === 'container' && obj.formFields) {
        obj.formFields.forEach((field) => {
          fieldValue = formData[field.propertyName];
          validationFunctions = field.checkValidation;
          if (
            (typeof field.isRequired === 'function' &&
              field.isRequired(formData, additionalInfo)) ||
            (typeof field.isRequired !== 'function' && field.isRequired)
          ) {
            validateField(
              field.propertyName,
              fieldValue,
              validationFunctions,
              field
            );
          }
        });
      } else {
        fieldValue = formData[obj.propertyName];
        validationFunctions = obj.checkValidation;
        validateField(obj.propertyName, fieldValue, validationFunctions, obj);
      }
    }
  });

  return transformErrorsToObject(errors);

  // Function to validate a field and push error if validation fails
  function validateField(
    fieldName,
    fieldValue,
    validationFunctions,
    fieldConfig
  ) {
    validationFunctions.forEach((validationFnc, index) => {
      if (validationFnc) {
        const validationResult = validationFnc(fieldValue, fieldConfig);
        if (!validationResult.isValid) {
          errors.push({
            fieldName: fieldName,
            errorMessage: validationResult.errorMessage,
          });
        }
      }
    });
  }
};

export const formatData = (data, property) => {
  return (
    data?.map((x) => {
      return {
        ...x,
        id: x[property],
      };
    }) ?? []
  );
};

export function removeEmptyStrings(obj) {
  const newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== '') {
      newObj[key] = value;
    }
  }
  return newObj;
}

export function formatPhoneNumber(number, format) {
  // Remove all non-digits from the number
  const cleaned = ('' + number).replace(/\D/g, '');

  let index = 0;
  let formattedNumber = '';

  // Iterate over the format and replace each # with a digit from the number
  for (let i = 0; i < format.length; i++) {
    if (format[i] === '#') {
      if (index < cleaned.length) {
        formattedNumber += cleaned[index];
        index++;
      } else {
        formattedNumber += '#'; // If number is shorter than format, replace with #
      }
    } else {
      formattedNumber += format[i]; // Keep non-# characters from the format
    }
  }

  return formattedNumber;
}
export const checkNMLSidValidation = (number) => {
  try {
    if (number) {
      if (number.length === 6 || number.length === 7) {
        return {
          isValid: true,
          errorMessage: '',
        };
      }
      return {
        isValid: false,
        errorMessage: 'Enter valid NMLS ID Number',
      };
    }
    return {
      isValid: false,
      errorMessage: 'Field cannot be empty',
    };
  } catch (error) {
    Sentry.captureException(error);

    return {
      isValid: false,
      errorMessage: 'Field cannot be empty',
    };
  }
};
