import React, { useState, useEffect } from 'react';
import { formatPhoneNumber } from 'pages/profile/utils';
/**
 * Renders an input component.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.size='m'] - The size of the input group. Possible values are 'sm', 'sm_2', 'm', 'l', 'xl'.
 * @param {string} [props.position='right'] - The position of the icon within the input group. Possible values are 'left', 'right', 'center'.
 * @param {boolean} [props.isError=false] - Indicates whether the input group has an error.
 * @param {string} props.svg - The SVG image source for the icon.
 * @returns {JSX.Element} The rendered input group component.
 */
const InputPhoneGroup = ({
  size = 'm',
  position = 'right',
  isError = false,
  svg,
  value = '',
  onChange,
  formatPattern,
  ...props
}) => {
  const [formattedInputValue, setFormattedInputValue] = useState(value);
  const [unformattedValue, setUnformattedValue] = useState(value);

  // checks for count of '#' character
  const countOccurrences = (str) => {
    const regex = /#/g;
    const count = (str.match(regex) || []).length;
    return count;
  };

  // Synchronize the internal state with the value prop
  useEffect(() => {
    setFormattedInputValue(value);
    setUnformattedValue(value);
  }, [value]);

  // formats the number if it meets countOccurrences requirement and formats according to format pattern ex: (###) ###-####
  useEffect(() => {
    if (unformattedValue?.length === countOccurrences(formatPattern)) {
      const formattedValue = formatPhoneNumber(unformattedValue, formatPattern);
      setFormattedInputValue(formattedValue);
      onChange &&
        onChange({
          target: { value: formattedValue, original: unformattedValue },
        });
    } else {
      setUnformattedValue(value);
    }
  }, [unformattedValue, value]);

  // handles input change
  const handleInputChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setFormattedInputValue(onlyNums);
    setUnformattedValue(onlyNums);
    onChange && onChange({ target: { value: onlyNums, original: onlyNums } });
  };

  //disables keypress on all buttons except 0-9 and backspace
  const handleKeyDown = (e) => {
    if (!/[0-9]/.test(e.key) && !['Backspace', 'Tab'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const sizeClasses = {
    sm: 'text-sm px-2 py-1 rounded-lg border-2 placeholder:p-xs-regular',
    sm_2: 'font-medium-2 px-2 py-2 rounded-[5px] border-2 placeholder:font-medium-2',
    m: 'px-2 p-xs-regular py-2.5 rounded-lg border-2 placeholder:p-xs-regular',
    l: 'text-lg px-4 py-3 rounded-lg border-2 placeholder:p-xs-regular',
    xl: 'text-xl px-5 py-4 rounded-lg border-2 placeholder:p-xs-regular',
  };
  const iconPositionClasses = {
    left: 'left-3',
    right: 'right-3',
    center: 'left-3',
  };

  const paddingPosition = {
    left: 'pl-10',
    right: 'pr-10',
    center: 'px-9',
  };

  const colorClassName = isError ? 'text-error' : 'text-black';
  const borderClassName = isError ? 'border-error' : 'border-primary';
  const borderOnFocus = isError ? 'border-error' : 'border-secondary';

  return (
    <div className="relative">
      {position === 'left' && (
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 ${iconPositionClasses[position]}`}
        >
          <img src={svg} alt="" />
        </div>
      )}
      <input
        type="text"
        className={`${paddingPosition[position]} ${sizeClasses[size]}  ${borderClassName}  disabled:bg-frameDisabled w-full ${colorClassName} font-normal focus:outline-none focus:${borderOnFocus} focus:border-2`}
        value={formattedInputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
      {position === 'right' && (
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 ${iconPositionClasses[position]}`}
        >
          <img src={svg} alt="" />
        </div>
      )}
      {position === 'center' && (
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 ${iconPositionClasses[position]}`}
        >
          <img src={svg} alt="" />
        </div>
      )}
    </div>
  );
};

export default InputPhoneGroup;
