import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const reportsAPI = createApi({
  reducerPath: 'reports',
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    getReportsData: builder.query({
      query: ({paging,searchFilter, date, dateString}) => {
        return {
          url: `loan/report/mis-report?page=${paging?.page}&page_size=${paging?.pageSize}&search=${searchFilter}` + dateString,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),    
    getPurposeInformation: builder.query({
      query: () => {
        return {
          url: `loan/application_dropdowns/loan_purpose`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
   downloadReportsData: builder.mutation({
      query: ({paging,searchFilter, date, dateString}) => {
        return {
          url: `/loan/report/download-mis-report?page=${paging?.page}&page_size=${paging?.pageSize}&search=${searchFilter}` + dateString,
          method: 'GET',
          headers: getHeaders(),
          responseHandler: (response) => response.blob(),
        };       
      },
      providesTags: ['downloadReports'],
    }),
    
  }),
});

export const { useGetReportsDataQuery ,useGetPurposeInformationQuery, useDownloadReportsDataMutation } = reportsAPI;
