// React and Components
import React, { useEffect, useState } from 'react';
import Button from 'components/button';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
import FormWrapper from 'wrapper/form-wrapper';
// RTK Queries
import {
  useGetCitizenshipStatusQuery,
  useGetMartialStatusQuery,
  useGetOwnerShipQuery,
  useGetStatesQuery,
} from '../../../../redux/services/client/profile';
import {
  useGetBorrowerProfileQuery,
  useUpdatePersonalDetailsMutation,
} from '../../../../redux/services/borrower-profile';

// Utils
import DynamicForm from 'pages/profile/DynamicForm';
import { personalInformation } from 'pages/profile/formfields/personalInformation';
import {
  checkValidations,
  createFormObject,
  formatData,
} from 'pages/profile/utils';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';

//Sentry
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';
import { isPast } from 'date-fns';
/**
 * Function to handle personal details form submission and update.
 *
 * @param {Object} props - The props object containing readOnly, setReadOnly, resetId, and setResetId.
 * @returns {JSX.Element} - Returns a JSX element with the personal details form.
 */
const personalDetails = ({ readOnly, setReadOnly, resetId, setResetId }) => {
  // States
  const [formData, setFormData] = useState({
    ...createFormObject(personalInformation),
  });
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const isEdit = React.useRef(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');

  //slices
  const { data: ownershipDropdown } = useGetOwnerShipQuery();

  const { data: citizenShipData } = useGetCitizenshipStatusQuery();
  const { data: martialData } = useGetMartialStatusQuery();
  const { data: stateType } = useGetStatesQuery();
  const [updatePersonalDetails, { isLoading: isUpdatePersonalDetailsLoading }] =
    useUpdatePersonalDetailsMutation();
  // Selectors
  const {
    data: applicationData,
    refetch: refetchBorrowerInfo,
    isLoading,
  } = useGetBorrowerProfileQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  React.useLayoutEffect(() => {
    setIsFormSubmitted(false);
    if (applicationData?.data?.user_profile) {
      isEdit.current = true;
      const formData = applicationData?.data?.user_profile;
      setFormData({
        ...formData,
        yearsLiving: {
          id: parseInt(formData?.yearsLiving) + 1,
        },
        monthsLiving: {
          id: parseInt(formData?.monthsLiving) + 1,
        },
        ownershipId: {
          id: formData?.ownershipId,
        },
        citizenshipStatusId: {
          id: formData?.citizenshipStatusId,
        },
        maritalStatusId: {
          id: formData?.maritalStatusId,
        },
        previousStateName: {
          id: formData?.previousStateId,
        },
        stateName: {
          id: formData?.stateId,
        },
        presentAddressLessThanTwoYears:
          formData?.presentAddressLessThanTwoYears === '1' ? true : false,
      });
    } else {
      setFormData({
        ...createFormObject(personalInformation),
      });
    }
  }, [applicationData, readOnly]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const errors = checkValidations(personalInformation, formData);
    if (Object.keys(errors)?.length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
    } else {
      setIsFormSubmitted(false);
      setErrors({});
      try {
        let data = {
          ...formData,
          yearsLiving: formData?.yearsLiving
            ? formData?.yearsLiving?.id - 1
            : 0,
          monthsLiving: formData?.monthsLiving
            ? formData?.monthsLiving?.id - 1
            : 0,
          ownershipId: formData?.ownershipId ? formData?.ownershipId?.id : 0,
          previousStateName: formData?.previousStateName?.name,
          previousStateId: formData?.previousStateName
            ? formData?.previousStateName?.id
            : null,
          stateName: formData?.stateName?.name,
          stateId: formData?.stateName ? formData?.stateName?.id : null,
          citizenshipStatusId: formData?.citizenshipStatusId
            ? formData?.citizenshipStatusId?.id
            : 0,
          maritalStatusId: formData?.maritalStatusId
            ? formData?.maritalStatusId?.id
            : 0,
          presentAddressLessThanTwoYears:
            !!formData?.presentAddressLessThanTwoYears,
          applicationId,
          phoneNumber: String(formData?.phoneNumber_Unformatted ?? ''),
          zipCode: String(formData?.zipCode ?? ''),
          rent: String(formData?.rent ?? ''),
          previousZipCode: String(formData?.previousZipCode ?? ''),
        };
        if (!formData.DOB) {
          delete data.DOB;
        }
        if (!formData?.rent) {
          delete data.rent;
        }
        if (formData?.phoneNumber_Unformatted) {
          delete data.phoneNumber_Unformatted;
        }
        const resp = await updatePersonalDetails({
          applicationId,
          data: replaceEmptyStringWithNull(data),
        });
        await refetchBorrowerInfo();
        if (resp?.data?.status === 200) {
          customToast.success('Profile Updated Successfully');
        } else {
          customToast.error('Error while updating the Profile');
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      } finally {
        setReadOnly(true);
      }
    }
  };
  React.useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      previousAddress:
        prev?.presentAddressLessThanTwoYears === true ||
        prev?.presentAddressLessThanTwoYears === '1'
          ? prev.previousAddress
          : null,
      previousCityName:
        prev?.presentAddressLessThanTwoYears === true ||
        prev?.presentAddressLessThanTwoYears === '1'
          ? prev.previousCityName
          : null,
      previousStateName:
        prev?.presentAddressLessThanTwoYears === true ||
        prev?.presentAddressLessThanTwoYears === '1'
          ? prev.previousStateName
          : null,
      previousZipCode:
        prev?.presentAddressLessThanTwoYears === true ||
        prev?.presentAddressLessThanTwoYears === '1'
          ? prev.previousZipCode
          : null,
    }));
  }, [formData?.presentAddressLessThanTwoYears]);
  useEffect(() => {
    if (resetId === 1) {
      setFormData((formData) => ({
        ...createFormObject(personalInformation),
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        email: formData?.email,
        phoneNumber: formData?.phoneNumber,
      }));
      setResetId(0);
    }
  }, [resetId]);

  const dropdowns = {
    ownershipId: formatData(ownershipDropdown?.data, 'ownershipId'),
    citizenshipStatusId: formatData(
      citizenShipData?.data,
      'citizenshipStatusId'
    ),
    maritalStatusId: formatData(martialData?.data, 'maritalStatusId'),
    stateName: formatData(stateType?.data, 'stateId'),
    previousStateName: formatData(stateType?.data, 'stateId'),
  };

  return (
    <>
      {isLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <FormWrapper
          className={
            'my-2 mx-2 max-h-borrower-profile overflow-hidden overflow-y-scroll p-3'
          }
        >
          <form onSubmit={onSubmitHandler}>
            <DynamicForm
              data={personalInformation}
              formData={formData}
              setFormData={setFormData}
              dropdowns={dropdowns}
              isSubmitted={isFormSubmitted}
              errors={errors}
              readOnly={readOnly}
            />
            {!readOnly && (
              <div className="text-right mt-2">
                <Button
                  variant="tertiary"
                  style={{ marginRight: '10px' }}
                  type="button"
                  onClick={() => {
                    setReadOnly(true);
                  }}
                >
                  {' '}
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  {isUpdatePersonalDetailsLoading ? (
                    <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                      <Loader loaderText="Saving" FullScreen={false} />
                    </div>
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            )}
          </form>
        </FormWrapper>
      )}
    </>
  );
};

export default personalDetails;
