import React, { Suspense } from 'react';
import Login from 'pages/login/Login';
import LoanInformation from 'pages/loan-information-officer';
import NotFound from 'components/404';
import BorrowerProfile from 'pages/borrower-profile-officer';
import PipelineAdmin from 'pages/pipeline-officer';
import Dashboard from 'pages/dashboard-officer';
import Tools from 'pages/tools-officer';
import QuickPrice from 'pages/quickPrice-officer';

const officerRoutes = [
  {
    path: '/',
    element: <Login />,
    children: [
      {
        path: 'dashboard',
        element: (
          <Suspense>
            <Dashboard />
          </Suspense>
        ),
        children: [],
      },
      {
        path: 'loanInformation',
        element: (
          <Suspense>
            <LoanInformation />
          </Suspense>
        ),
        children: [],
      },
      {
        path: ':param/lead',
        element: (
          <Suspense>
            <BorrowerProfile />
          </Suspense>
        ),
      },
      {
        path: 'pipeline',
        element: (
          <Suspense>
            <PipelineAdmin />
          </Suspense>
        ),
      },
      {
        path: 'tools',
        element: (
          <Suspense>
            <Tools />
          </Suspense>
        ),
      },
      {
        path: 'quickPrice',
        element: (
          <Suspense>
            <QuickPrice />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
];

export default officerRoutes;
