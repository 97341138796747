import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
//auth
import { parseJwt } from 'auth/auth';
import { CheckAuth } from 'auth/auth';
//libs
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';
//icons
import Employed from 'assets/icons/Employed';
import angleDownWhite from 'assets/icons/angleDownWhite.svg';
//components
import TitleCard from 'components/title-card';
import CardLoader from 'wrapper/card-loader';
import VerifyPassword from 'pages/verify-password';
import { AppShell } from '../app/AppShell';
import InviteExpire from 'wrapper/invite-expire';
import EmailVerified from 'wrapper/email-verified';
import VerifyEmail from 'wrapper/verify-email';
//constants
import { audience, roles } from 'constants/constant.js';
import { useDispatch } from 'react-redux';
//redux
import { setUserRole } from 'redux_store/slices/userRole';
import { useGetApplicationQuery } from 'redux_store/services/client/profile';
//utils
import { hasPermissions } from 'constants/permissionChecker';
import PersonalInfoCard from 'wrapper/personal-info';
import SessionExpire from 'wrapper/session-expire/SessionExpire';
import * as Sentry from '@sentry/browser';

//this is the main component that validates login and renders dashboard (i.e : AppShell.jsx)

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  //extract variables from search params
  const searchParams = new URLSearchParams(location?.search);
  const userId = searchParams.get('user_id');
  const code = searchParams.get('code');
  const email = searchParams.get('email');

  const {
    user,
    isAuthenticated,
    isLoading,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated$,
  } = useAuth0();

  const [isOnboarding, setIsOnboarding] = useState(false);
  const [data, setData] = useState(undefined);

  const navigationDoneRef = useRef(false);

  //handle hover state for icon

  //fetch token, decode it and store in cookie
  useEffect(() => {
    const setTokenInCookie = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const decoded = parseJwt(token);
          setData({ ...decoded, token: token });
          Cookies.set('idToken', JSON.stringify({ ...decoded, token: token }), {
            expires: 1,
          });
        } catch (error) {
          Sentry.captureException(error);

          console.log(error);
        }
      }
    };
    setTokenInCookie();
  }, [isAuthenticated]);

  //refetch every 500 ms until set cookie is available
  useEffect(() => {
    const fetchData = async () => {
      const newData = CheckAuth();
      if (newData !== undefined) {
        setData(newData);
        clearInterval(timer);
      }
    };

    const timer = setInterval(fetchData, 500);
    if (data !== undefined) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [data]);

  const { data: applicationData } = useGetApplicationQuery(
    {
      application_id: data?.pending_application_id,
    },
    {
      skip: !data?.pending_application_id,
      refetchOnMountOrArgChange: true,
    }
  );

  const order = [
    {
      id: 1,
      name: 'personal_details',
    },
    {
      id: 2,
      name: 'loan_details',
    },
    {
      id: 3,
      name: 'employment_and_income_details',
    },
    {
      id: 4,
      name: 'user_asset_details',
    },
    {
      id: 5,
      name: 'user_libality_details',
    },
    {
      id: 6,
      name: 'user_reos_details',
    },
    // {
    //   id: 7,
    //   name: 'user_declarations_details',
    // },
    // {
    //   id: 8,
    //   name: 'user_demographics_details',
    // },
  ];

  //onboarding page conditions
  useEffect(() => {
    if (isAuthenticated) {
      if (
        data &&
        !navigationDoneRef?.current &&
        hasPermissions(data?.permissions, roles.consumer) &&
        ((data?.pending_application_id && applicationData) ||
          !data?.pending_application_id)
      ) {
        navigationDoneRef.current = true;
        dispatch(setUserRole('consumer'));
        localStorage.setItem('applicationId', data.pending_application_id);
        if (data?.new_user) {
          navigate('/', { replace: true });
          setIsOnboarding(true);
        } else {
          if (data?.user_profile_exists && !data?.pending_application_id) {
            setIsOnboarding(false);
            navigate('/pipeline');
          } else if (
            data?.user_profile_exists &&
            data?.pending_application_id
          ) {
            setIsOnboarding(false);
            const allFormsFilled = order?.every(
              (form) => applicationData?.data?.[form?.name]
            );
            if (allFormsFilled) {
              navigate('/pipeline');
            } else {
              navigate('/profile');
            }
          } else {
            if (data?.pending_application_id) {
              setIsOnboarding(false);
              navigate('/profile');
            } else {
              navigate('/', { replace: true });
              setIsOnboarding(true);
            }
          }
        }
      } else if (hasPermissions(data?.permissions, roles?.loanOfficer)) {
        dispatch(setUserRole('loanOfficer'));
        setIsOnboarding(false);
        navigate('/loanInformation');
      } else if (hasPermissions(data?.permissions, roles?.organization)) {
        dispatch(setUserRole('organization'));
        setIsOnboarding(false);
        navigate('/loanInformation');
      }
    }
  }, [data, applicationData, isAuthenticated]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isAuthenticated) {
        Cookies.remove('idToken');
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
          const [name] = cookie.split('=');
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
        // Clear local storage
        localStorage.clear();
        // Clear session storage
        sessionStorage.clear();
        // Redirect to the base URL
        window.location.href = window.location.origin;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isAuthenticated]);

  // render verify password screen
  if (userId && code && email) {
    return <VerifyPassword />;
  }
  //render invitation expired screen
  if (location?.pathname === '/verified') {
    return <VerifyPassword userId={userId} code={code} email={email} />;
  }
  // render email verified page
  if (location?.pathname === '/result_page') {
    return <EmailVerified />;
  }

  if (location?.pathname === '/personal-info') {
    return <PersonalInfoCard />;
  }

  if (location.pathname === '/verification-email') {
    return <VerifyEmail />;
  }

  if (location?.pathname === '/session-timeout') {
    return <SessionExpire />;
  }

  // render verify email page
  // if (data?.email_verified === 0) {
  //   return <VerifyEmail />;
  // }

  return isAuthenticated ? (
    <AppShell
      logout={logout}
      isOnboarding={isOnboarding}
      setIsOnboarding={setIsOnboarding}
      user={user}
      data={data}
    />
  ) : (
    <></>
  );
};

export default Login;
