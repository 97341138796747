import replaceBlankValue from 'utils/replaceBlankValue';
const PaymentAdjustment = ({
  paymentAdjustmentData = {
    initial_payment_rate: null,
    first_adjustment_cap: null,
    first_change: null,
    recast_period: null,
    recast_stop: null,
    max_balance: null,
    interest_only: null,
    gpm: null,
  },
}) => {
  return (
    <>
      <h2 className="font-bold text-start p-2">Payment Adjustment</h2>
      <table>
        <tbody>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>
              {'Initial Payment Rate'}
            </td>
            <td className={`text-start w-[50%]`}>
              {replaceBlankValue(paymentAdjustmentData?.initial_payment_rate)}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'First Adj. Cap'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(paymentAdjustmentData?.first_adjustment_cap)}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'First Change'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(paymentAdjustmentData?.first_change)}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'Recast Period'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(paymentAdjustmentData?.recast_period)}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'Recast Stop'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(paymentAdjustmentData?.recast_stop)}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'Max Balance'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(paymentAdjustmentData?.max_balance)}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'Interest Only'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(paymentAdjustmentData?.interest_only)}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'GPM'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(paymentAdjustmentData?.gpm)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PaymentAdjustment;
