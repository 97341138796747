import { hasPermissions } from 'constants/permissionChecker';
import { parseJwt } from 'auth/auth';
import { useGetApplicationStatusQuery } from 'redux_store/services/client/profile';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserRole } from 'redux_store/slices/userRole';
import { roles } from 'constants/constant';
import * as Sentry from '@sentry/browser';

export const useGetAuth0Authorization = () => {
  const [data, setData] = useState(undefined);
  const dispatch = useDispatch();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const setTokenInCookie = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const decoded = parseJwt(token);
          setData({ ...decoded, token: token });
        } catch (error) {
          console.log(error);
          Sentry.captureException(error);
        }
      }
    };
    setTokenInCookie();
  }, [isAuthenticated]);

  const { data: applicationData } = useGetApplicationStatusQuery(
    {
      application_id: data?.pending_application_id,
    },
    {
      skip: !data?.pending_application_id,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (hasPermissions(data?.permissions, roles?.consumer)) {
        dispatch(setUserRole('consumer'));
      } else if (hasPermissions(data?.permissions, roles.loanOfficer)) {
        dispatch(setUserRole('loanOfficer'));
      } else if (hasPermissions(data?.permissions, roles.organization)) {
        dispatch(setUserRole('organization'));
      }
    }
  }, [data, applicationData, isAuthenticated]);
};
