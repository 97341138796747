import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
const Typewriter = ({ text, delay, setIsTypeWriterActive, setTyping }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (currentIndex < text.length) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex] ?? '');
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setIsTypeWriterActive(currentText.length); // manupulates the value to trigger scroll down
      }, delay);
    }

    if (currentIndex == text.length) {
      setTyping(); //setting typing as false
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, text]);
  return (
    <Markdown
      className="max-w-[100%] markdown-container"
      components={{
        a: CustomLink,
      }}
    >
      {currentText ?? ''}
    </Markdown>
  );
};

const CustomLink = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
export default Typewriter;
