import {
  formatNumberIntoCurrency,
  formatNumberIntoPercentage,
} from 'constants/formatNumber';
import { format, parse } from 'date-fns';
import replaceBlankValue from 'utils/replaceBlankValue';
const LoanProgram = ({
  loanProgramData = {
    loan_amount: null,
    interest_rate: null,
    term: null,
    due_in: null,
    first_payment_date: null,
    qual_rate: null,
    apr: null,
  },
}) => {
  const getDate = (inputDate) => {
    if (!inputDate) {
      return '';
    }
    const formattedDate = format(
      parse(inputDate, 'yyyy-MM-dd', new Date()),
      'MM-dd-yyyy'
    );
    return formattedDate;
  };
  return (
    <>
      <h2 className="font-bold text-start p-2">Loan Program</h2>
      <table>
        <tbody>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2 max-w-[50%]`}>
              {'Loan Amount Buydown'}
            </td>
            <td className={`text-start w-[50%]`}>
              {replaceBlankValue(
                formatNumberIntoCurrency(loanProgramData?.loan_amount, '-')
              )}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'Interest Rate'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(
                formatNumberIntoPercentage(loanProgramData?.interest_rate, '-')
              )}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'Term/Due In'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(
                `${loanProgramData?.term?.toString() ?? 0}/${loanProgramData?.due_in?.toString() ?? 0}`
              )}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'1st PMT Date'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(getDate(loanProgramData?.first_payment_date))}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'Qual Rate'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(
                formatNumberIntoPercentage(loanProgramData?.qual_rate, '-')
              )}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'APR'}</td>
            <td className={`text-start`}>
              {replaceBlankValue(
                formatNumberIntoPercentage(loanProgramData?.apr, '-')
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default LoanProgram;
