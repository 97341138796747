import React from 'react';
import { getInitials } from 'constants/getFirstChar';
import { profileBgMapping } from '../../themes/colors';
/**
 * Renders a user profile component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.firstName - The first name of the user.
 * @param {string} props.lastName - The last name of the user.
 * @param {boolean} props.selected - Indicates if the user profile is selected.
 * @param {Object} props... - Additional props for the component.
 * @returns {JSX.Element} The rendered user profile component.
 */
const UserProfile = ({
  firstName,
  lastName,
  selected,
  loanOfficer,
  ...props
}) => {
  const getColorForTitle = (title) => {
    // hash the title and use it to pick a color from the mapping
    const index = title?.charCodeAt(0) % profileBgMapping?.length;
    return profileBgMapping[index];
  };

  const backgroundColor = getColorForTitle(firstName);
  return (
    <div className="w-[120px] h-[155px] flex flex-col items-center " {...props}>
      <div
        className={`h-[80px] w-[80px] rounded-full flex justify-center items-center group-hover:border-2 group-hover:border-primary500 ${
          selected ? 'border-primary500 border-2' : ''
        }`}
        style={{ backgroundColor }}
      >
        {loanOfficer && <img src={loanOfficer} alt="loanOfficer" />}
        {!loanOfficer && (
          <div className="p-md-bold">
            {' '}
            {getInitials(firstName)}
            {getInitials(lastName)}
          </div>
        )}
      </div>
      <div
        className={`p-sm-bold mt-3  ${
          selected ? 'text-tertiary' : 'text-black'
        }  group-hover:text-tertiary`}
      >
        {firstName}
      </div>
      <div
        className={`p-sm-regular mt-1  ${
          selected ? 'text-tertiary' : 'text-black'
        } group-hover:text-tertiary`}
      >
        {lastName}
      </div>
    </div>
  );
};

export default UserProfile;
