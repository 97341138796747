import { api } from 'constants/constant';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const passwordAPI = createApi({
  reducerPath: 'updatePassword',
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    // update password
    updatePassword: builder.mutation({
      query: ({ data }) => {
        return {
          url: `user/change_password`,
          method: 'POST',
          body: data,
        };
      },
    }),

    resendEmail: builder.mutation({
      query: ({ data, token }) => {
        return {
          url: `user/resend-mail`,
          method: 'POST',
          body: data,
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        };
      },
    }),

    updateProfileInfo: builder.mutation({
      query: ({ data, token }) => {
        return {
          url: `user/personal-info`,
          method: 'POST',
          body: data,
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        };
      },
    }),
  }),
});

export const {
  useUpdatePasswordMutation,
  useUpdateProfileInfoMutation,
  useResendEmailMutation,
} = passwordAPI;
