/* istanbul ignore file */
import { checkAlphanumeric, checkTextInput, withValidations } from '../utils';

const realEstateOwnedData = [
  {
    id: 1,
    name: 'Occupancy',
    type: 'select',
    placeholder: 'Enter Occupancy',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'occupancyTypeId',
    disabled: (formData, additionInfo) => {
      return (
        formData.tab === 0 && additionInfo?.personal_details?.ownershipId === 1
      );
    },
  },
  {
    id: 2,
    name: "Owned by (Borrower's name)",
    type: 'text',
    placeholder: "Enter Borrower's Name",
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'ownedBy',
    disabled: (formData, additionInfo) => {
      /* return (
        (formData.tab === 0 &&
          additionInfo?.personal_details?.[0]?.ownershipId === 1) ||
        additionInfo?.personal_details?.[0]?.ownershipId === 2 ||
        additionInfo?.personal_details?.[0]?.ownershipId === null
      ); */
      return formData?.tab === 0;
    },
    validator: checkTextInput,
  },
  {
    id: 3,
    name: 'Property Address',
    type: 'text',
    placeholder: 'Enter Property Address',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'propertyAddress',
    disabled: (formData, additionInfo) => {
      return (
        formData.tab === 0 && additionInfo?.personal_details?.ownershipId === 1
      );
    },
  },
  {
    id: 4,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'City',
        type: 'text',
        placeholder: 'Enter City',
        customClass: 'w-1/2',
        isRequired: false,
        propertyName: 'cityName',
        disabled: (formData, additionInfo) => {
          return (
            formData.tab === 0 &&
            additionInfo?.personal_details?.ownershipId === 1
          );
        },
        validator: checkTextInput,
      },
      {
        id: 2,
        name: 'State',
        type: 'select',
        placeholder: 'Select State',
        customClass: 'w-1/2',
        isRequired: false,
        propertyName: 'stateName',
        disabled: (formData, additionInfo) => {
          return (
            formData.tab === 0 &&
            additionInfo?.personal_details?.ownershipId === 1
          );
        },
      },
    ],
  },
  {
    id: 5,
    name: 'Zip',
    type: 'number',
    placeholder: 'Enter Zip',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'zipCode',
    disabled: (formData, additionInfo) => {
      return (
        formData.tab === 0 && additionInfo?.personal_details?.ownershipId === 1
      );
    },
  },
  {
    id: 6,
    name: 'Rental for Income',
    type: 'select',
    placeholder: 'Select Rental for Income',
    customClass: 'w-1/3',
    isRequired: false,

    propertyName: 'isRentalForIncome',
    options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
  },
  {
    id: 7,
    name: 'Rental Income',
    type: 'number',
    placeholder: 'Enter Rental Income',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'rentalIncome',
    disabled: (formData) => {
      return formData?.isRentalForIncome?.id === 2;
    },
  },
  {
    id: 8,
    name: 'Mortgage Balance',
    type: 'number',
    placeholder: 'Enter Mortgage Balance',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'mortgageBalance',
  },
  {
    id: 9,
    name: 'Monthly Payment',
    type: 'number',
    placeholder: 'Enter Monthly Payment',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'monthlyPayment',
  },
  {
    id: 10,
    name: 'Escrowed Mortgage Payment',
    type: 'select',
    placeholder: 'Select Escrowed Mortgage Payment',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'escrowedPayment',
    isRequired: (formData, additionInfo) => {
      return additionInfo?.personal_details?.ownershipId == 1 ?? false;
    },
    disabled: (formData, additionInfo) => {
      return additionInfo?.personal_details?.ownershipId !== 1 ?? true;
    },
    options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
  },
  {
    id: 11,
    name: 'Mortgage Payment',
    type: 'number',
    placeholder: 'Enter Mortgage Payment',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      if (
        formData?.escrowedPayment?.id === 1 ||
        formData?.escrowedPayment?.id === 2
      ) {
        return true;
      }
      return false;
    },
    disabled: (formData, additionInfo) => {
      return !formData?.escrowedPayment?.id;
    },
    propertyName: 'mortgagePayment',
  },
  {
    id: 12,
    name: 'Taxes',
    type: 'number',
    placeholder: 'Enter Taxes',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      if (formData?.escrowedPayment?.id === 2) {
        return true;
      }
      return false;
    },
    disabled: (formData, additionInfo) => {
      return formData?.escrowedPayment?.id !== 2;
    },
    propertyName: 'taxes',
  },

  {
    id: 13,
    name: 'Insurance',
    type: 'number',
    placeholder: 'Enter Insurance',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'insurance',
    isRequired: (formData) => {
      if (formData?.escrowedPayment?.id === 2) {
        return true;
      }
      return false;
    },
    disabled: (formData) => {
      return formData?.escrowedPayment?.id !== 2;
    },
  },
  {
    id: 14,
    name: 'HOA',
    type: 'number',
    placeholder: 'Enter HOA',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'HOA',
    isRequired: (formData) => {
      if (formData?.escrowedPayment?.id === 2) {
        return true;
      }
      return false;
    },
    disabled: (formData) => {
      return formData?.escrowedPayment?.id !== 2;
    },
  },
  {
    id: 15,
    name: 'Occupancy Rate',
    type: 'number',
    placeholder: 'Enter Occupancy Rate',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'occupancyRate',
    disabled: true,
  },
  {
    id: 16,
    name: 'Net Rental Income (If Available)',
    type: 'number',
    placeholder: 'Enter Net Rental Income',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'netRentalIncome',
    disabled: (formData) => {
      return formData?.isRentalForIncome?.id === 2;
    },
  },
  {
    id: 17,
    name: 'Associated with Liabilities',
    type: 'text',
    placeholder: 'Enter Bank Name',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'associateLiability',
    validator: checkTextInput,
  },
];

export const realEstateOwned = withValidations(realEstateOwnedData);
