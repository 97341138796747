import Button from 'components/button';
import * as Sentry from '@sentry/browser';

import { customToast } from 'components/toast';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useResendEmailMutation } from 'redux_store/services/update-password';
import fundmoreai from 'assets/fundmoreai2.png'

const VerifyEmail = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const stateId = searchParams.get('state');
  const sessionToken = searchParams.get('session_token');
  const [
    resendEmailAPI,
    { isLoading: isResendEmailLoading, isSuccess: isResendEmailSentSuccess },
  ] = useResendEmailMutation();

  //redirect to main page
  const handleRedirect = async () => {
    window.location.href = window.location.origin;
  };

  const resendEmail = async () => {
    try {
      const resp = await resendEmailAPI({
        data: {},
        token: sessionToken,
      });
      if (resp?.data?.status === 200) {
        customToast.success('Email is sent successfully.');
      } else if (resp?.data?.status === 204) {
        customToast.success('User is verified. Please continue to Login');
      } else if (resp?.error?.status === 401) {
        window.location.href = `${window.location.origin}/session-timeout`;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <div
      className="bg-cover bg-center h-screen flex flex-col justify-center items-center w-[100vw]"
      style={{ backgroundImage: 'url("/mainbg.png")' }}
    >
      <div className="w-[500px] h-[134px] bg-secondary100 flex flex-col justify-center items-center rounded-t-xl">
      <img
              src={fundmoreai}
              alt=""
              className="h-28"
              style={{width: '250px', height: '65px'}}
            />
        <h3 className="text-neutral900 h4-m mt-2">Pending verification</h3>
      </div>
      <div className="w-[500px] h-[42px] bg-green_success flex items-center justify-center text-white p-sm-bold">
        {isResendEmailSentSuccess
          ? 'VERIFICATION EMAIL RE-SENT'
          : 'VERIFICATION EMAIL SENT'}
      </div>
      <div className="w-[500px] h-[170px] bg-white flex flex-col justify-between py-6 items-center relative">
        <div className="text-center mb-4 px-7">
          <div className="font-medium leading-none ">
            A verification email has been sent to your registered email address.
            Please check your email and click on{' '}
            <span className="font-medium-bold">“Confirm my account”</span> to
            verify your account.
          </div>
          <div className="font-medium leading-none mt-14">
            Didn't receive the email?{' '}
            <Button variant="link" style={{ padding: 0 }} onClick={resendEmail}>
              {isResendEmailLoading ? 'Please wait...' : 'Resend Email'}
            </Button>
          </div>
        </div>
      </div>
      <button
        onClick={handleRedirect}
        className="w-[500px] h-[70px] bg-primary500 hover:bg-custom-gray flex flex-col justify-center items-center rounded-b-xl cursor-pointer"
      >
        <button className="p-md-bold mt-2 text-white">
          Click here to Login &gt;
        </button>
      </button>
    </div>
  );
};

export default VerifyEmail;
