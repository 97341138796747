import replaceBlankValue from 'utils/replaceBlankValue';
const LoanClosure = ({
  loanClosureData = {
    closing_cost: null,
    other_closing_cost: null,
    net_closing_cost: null,
  },
}) => {
  return (
    <>
      <h2 className="font-bold text-start p-2">Loan Closure</h2>
      <table>
        <tbody>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>{'Closing Cost'}</td>
            <td className={`text-start w-[50%]`}>
              {replaceBlankValue(loanClosureData?.closing_cost) /*  ?? '-' */}
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>
              {'Other Closing Cost'}
            </td>
            <td className={`text-start`}>
              {
                replaceBlankValue(
                  loanClosureData?.other_closing_cost
                ) /*  ?? '-' */
              }
            </td>
          </tr>
          <tr className={`w-[100%]`}>
            <td className={`font-medium text-start p-2`}>
              {'Net Closing Cost'}
            </td>
            <td className={`text-start`}>
              {
                replaceBlankValue(
                  loanClosureData?.net_closing_cost
                ) /*  ?? '-' */
              }
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default LoanClosure;
