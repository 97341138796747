//react
import React from 'react';
//components
import DynamicForm from '../DynamicForm';
import Button from 'components/button';
import Loader from 'wrapper/loader';
import H1 from 'components/h1-typography';
import FormWrapper from 'wrapper/form-wrapper';
//RTK query
import {
  useCreatePersonalDetailsMutation,
  useGetApplicationQuery,
  useGetCitizenshipStatusQuery,
  useGetMartialStatusQuery,
  useGetOwnerShipQuery,
  useUpdatePersonalDetailsMutation,
  useGetStatesQuery,
} from 'redux_store/services/client/profile';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import { useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux_store/slices/application';
//Utils ans constatn's
import { checkValidations, formatData, createFormObject } from '../utils';
import { format, parse } from 'date-fns';
import { personalInformation } from '../consumerFormFields/personalInformation';

// utils
import { replaceEmptyStringWithNull } from 'utils/stringToNull';

const PersonalDetails = ({ setPage, token }) => {
  const userData = JSON.parse(Cookies.get('idToken'));
  // create formdata for the personal details
  const [formData, setFormData] = React.useState({
    ...createFormObject(personalInformation),
    // firstName: userData?.first_name ?? '',
    // lastName: userData?.last_name ?? '',
    // email: userData?.user_email ?? '',
    // phoneNumber: userData?.user_phone_number ?? '',
  });

  // Selector For Redux
  const rawPersonalInfoData = useSelector(personalInfoSelector);
  const personalInfoData =
    Object.keys(rawPersonalInfoData)?.length > 0 ? rawPersonalInfoData : null;
  // states for the form
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const isEdit = React.useRef(false);

  // RTK Queries to get data
  const [createPersonalDetails, { isLoading: isCreatePersonalDetailsLoading }] =
    useCreatePersonalDetailsMutation();
  const [updatePersonalDetails, { isLoading: isUpdatePersonalDetailsLoading }] =
    useUpdatePersonalDetailsMutation();
  const { data: ownershipDropdown } = useGetOwnerShipQuery();
  const { data: stateType } = useGetStatesQuery();
  const {
    data: applicationData,
    refetch,
    error,
  } = useGetApplicationQuery(
    {
      application_id: token,
    },
    {
      skip: !token,
    }
  );
  const { data: citizenShipData } = useGetCitizenshipStatusQuery();
  const { data: martialData } = useGetMartialStatusQuery();

  const formatDate = (dateString) => {
    try {
      const date = parse(dateString, 'MM/dd/yyyy', new Date());
      const formattedDate = format(date, 'yyyy-MM-dd');
      return formattedDate;
    } catch (error) {
      Sentry.captureException(error);
      return '';
    }
  };
  React.useEffect(() => {
    if (applicationData?.data) {
      let personalDetails = applicationData?.data?.personal_details;
      if (applicationData?.data?.personal_details) {
        isEdit.current = true;
        const formData = applicationData?.data?.personal_details;
        setFormData({
          ...formData,
          presentAddressLessThanTwoYears:
            formData?.presentAddressLessThanTwoYears === '1' ? true : false,
          ...personalInfoData,
          rent: personalInfoData?.rent
            ? personalInfoData?.rent
            : formData?.rent,
          DOB: personalInfoData?.DOB
            ? formatDate(personalInfoData?.DOB)
            : formData?.DOB ?? '',
          citizenshipStatusId: personalInfoData?.citizenshipStatusId
            ? {
                id: personalInfoData?.citizenshipStatusId,
              }
            : formData?.citizenshipStatusId
              ? {
                  id: formData?.citizenshipStatusId,
                }
              : '',
          maritalStatusId: personalInfoData?.maritalStatusId
            ? {
                id: personalInfoData?.maritalStatusId,
              }
            : formData?.maritalStatusId
              ? {
                  id: formData?.maritalStatusId,
                }
              : '',
          ownershipId: personalInfoData?.ownershipId
            ? {
                id: personalInfoData?.ownershipId,
              }
            : formData?.ownershipId
              ? {
                  id: formData?.ownershipId,
                }
              : '',
          yearsLiving:
            personalInfoData?.yearsLiving || personalInfoData?.yearsLiving === 0
              ? {
                  id: parseInt(personalInfoData?.yearsLiving) + 1,
                }
              : formData?.yearsLiving || formData?.yearsLiving === 0
                ? {
                    id: parseInt(formData?.yearsLiving) + 1,
                  }
                : '',
          monthsLiving:
            personalInfoData?.monthsLiving ||
            personalInfoData?.monthsLiving === 0
              ? {
                  id: parseInt(personalInfoData?.monthsLiving) + 1,
                }
              : formData?.monthsLiving || formData?.monthsLiving === 0
                ? {
                    id: parseInt(formData?.monthsLiving) + 1,
                  }
                : '',
          previousStateId: personalInfoData?.previousStateId
            ? {
                id: personalInfoData?.previousStateId,
              }
            : formData?.previousStateId
              ? {
                  id: formData?.previousStateId,
                }
              : '',
          previousStateName: personalInfoData?.previousStateId
            ? {
                id: personalInfoData?.previousStateId,
              }
            : formData?.previousStateId ?? '',
          stateName: personalInfoData?.stateId
            ? {
                id: personalInfoData?.stateId,
              }
            : formData?.stateId
              ? {
                  id: formData?.stateId,
                }
              : '',
        });
      } else {
        setFormData((formData) => ({
          ...formData,
          ...personalInfoData,
          DOB: personalInfoData?.DOB
            ? formatDate(personalInfoData?.DOB)
            : formData?.DOB ?? '',
          rent: personalDetails.ownershipId === 2 ? personalDetails.rent : null,
          citizenshipStatusId: personalInfoData?.citizenshipStatusId
            ? {
                id: personalInfoData?.citizenshipStatusId,
              }
            : formData?.citizenshipStatusId ?? '',
          maritalStatusId: personalInfoData?.maritalStatusId
            ? {
                id: personalInfoData?.maritalStatusId,
              }
            : formData?.maritalStatusId ?? '',
          ownershipId: personalInfoData?.ownershipId
            ? {
                id: personalInfoData?.ownershipId,
              }
            : formData?.ownershipId ?? '',
          yearsLiving:
            personalInfoData?.yearsLiving || personalInfoData?.yearsLiving === 0
              ? {
                  id: parseInt(personalInfoData?.yearsLiving) + 1,
                }
              : formData?.yearsLiving ?? '',
          monthsLiving:
            personalInfoData?.monthsLiving ||
            personalInfoData?.monthsLiving === 0
              ? {
                  id: parseInt(personalInfoData?.monthsLiving) + 1,
                }
              : formData?.monthsLiving ?? '',
          previousStateName: personalInfoData?.previousStateId
            ? {
                id: personalInfoData?.previousStateId,
              }
            : formData?.previousStateId ?? '',
          stateName: personalInfoData?.stateId
            ? {
                id: personalInfoData?.stateId,
              }
            : formData?.stateId,
        }));
      }
    }
  }, [applicationData, personalInfoData]);
  React.useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        previousAddress:
          prev?.presentAddressLessThanTwoYears === true ||
          prev?.presentAddressLessThanTwoYears === '1'
            ? prev.previousAddress
            : null,
        previousCityName:
          prev?.presentAddressLessThanTwoYears === true ||
          prev?.presentAddressLessThanTwoYears === '1'
            ? prev.previousCityName
            : null,
        previousStateName:
          prev?.presentAddressLessThanTwoYears === true ||
          prev?.presentAddressLessThanTwoYears === '1'
            ? prev.previousStateId
            : null,
        previousStateId:
          prev?.presentAddressLessThanTwoYears === true ||
          prev?.presentAddressLessThanTwoYears === '1'
            ? prev.previousStateId
            : null,
        previousZipCode:
          prev?.presentAddressLessThanTwoYears === true ||
          prev?.presentAddressLessThanTwoYears === '1'
            ? prev.previousZipCode
            : null,
      };
    });
  }, [formData?.presentAddressLessThanTwoYears]);
  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const errors = checkValidations(personalInformation, formData);
    if (Object.keys(errors)?.length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
    } else {
      setIsFormSubmitted(false);
      setErrors({});
      try {
        let data = {
          ...formData,
          yearsLiving: formData?.yearsLiving
            ? formData?.yearsLiving?.id - 1
            : 0,
          monthsLiving: formData?.monthsLiving
            ? formData?.monthsLiving?.id - 1
            : 0,
          ownershipId: formData?.ownershipId ? formData?.ownershipId?.id : 0,
          citizenshipStatusId: formData?.citizenshipStatusId
            ? formData?.citizenshipStatusId?.id
            : 0,
          maritalStatusId: formData?.maritalStatusId
            ? formData?.maritalStatusId?.id
            : 0,
          presentAddressLessThanTwoYears:
            !!formData?.presentAddressLessThanTwoYears,
          applicationId: token,
          phoneNumber: String(formData?.phoneNumber_Unformatted ?? ''),
          zipCode: String(formData?.zipCode ?? ''),
          previousStateName: formData?.previousStateName?.name,
          previousStateId: formData?.previousStateName
            ? formData?.previousStateName?.id
            : null,
          stateName: formData?.stateName?.name,
          stateId: formData?.stateName ? formData?.stateName?.id : null,
          rent: String(formData?.rent ?? ''),
          previousZipCode: String(formData?.previousZipCode ?? ''),
        };
        if (!formData.DOB) {
          delete data.DOB;
        }
        if (!formData?.rent) {
          delete data.rent;
        }
        if (formData?.phoneNumber_Unformatted) {
          delete data.phoneNumber_Unformatted;
        }
        if (isEdit.current) {
          const res = await updatePersonalDetails({
            applicationId: token,
            data: replaceEmptyStringWithNull(data),
          });
          await refetch();
          if (res.data?.status === 200) {
            setPage();
          }
        } else {
          const res = await createPersonalDetails(
            replaceEmptyStringWithNull(data)
          );
          await refetch();
          if (res.data?.status === 200) {
            setPage();
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };

  //dropdowns for form fields
  const dropdowns = {
    ownershipId: formatData(ownershipDropdown?.data, 'ownershipId'),
    citizenshipStatusId: formatData(
      citizenShipData?.data,
      'citizenshipStatusId'
    ),
    maritalStatusId: formatData(martialData?.data, 'maritalStatusId'),
    stateName: formatData(stateType?.data, 'stateId'),
    previousStateName: formatData(stateType?.data, 'stateId'),
  };

  return (
    <div className="overflow-auto">
      <H1>Personal Details</H1>
      <p className="antialiased">
        Provide the borrower's personal information.
      </p>
      <FormWrapper
        className={
          'my-2 mx-2 max-h-custom-profile-forms overflow-hidden overflow-y-scroll pb-3'
        }
      >
        <div className=" w-full p-3 rounded-lg">
          <form onSubmit={onSubmitHandler}>
            <DynamicForm
              data={personalInformation}
              formData={formData}
              setFormData={setFormData}
              dropdowns={dropdowns}
              isSubmitted={isFormSubmitted}
              errors={errors}
            />
            <div className="text-right mt-2">
              <Button type="submit" variant="primary">
                {isUpdatePersonalDetailsLoading ||
                isCreatePersonalDetailsLoading ? (
                  <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                    <Loader loaderText="Saving" FullScreen={false} />
                  </div>
                ) : (
                  'Next: Loan information'
                )}
              </Button>
            </div>
          </form>
        </div>
      </FormWrapper>
    </div>
  );
};

export default PersonalDetails;
