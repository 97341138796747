export const transactionDetailsArray = [
  {
    description: 'A. Sales Contract Price',
    amount: null,
    propertyName: 'sales_contract_price',
    isInputTrue: true,
  },
  {
    description: 'B. Improvements, Renovations and Repairs',
    amount: null,
    propertyName: 'improvements_cost',
    isInputTrue: true,
  },
  {
    description: 'C. Land (If acquired separately)',
    amount: null,
    propertyName: 'land_cost',
    isInputTrue: true,
  },
  {
    description:
      'D. For Refinance: Balance of Mortgage Loans on the Property to be paid off in the Transaction ',
    amount: null,
    propertyName: 'mortgage_balance',
    isInputTrue: true,
  },
  {
    description: 'E. Credit Cards and Other Debts Paid off',
    amount: null,
    propertyName: 'other_debts',
  },
  {
    description:
      'F. Borrower Closing cost (Including Prepaid and Initial Escrow Payment)',
    amount: null,
    propertyName: 'closing_cost',
    children: [
      {
        description: 'F1.  Estimated Prepaid Items',
        amount: null,
      },
      {
        description: 'F2. Estimated Closing Costs',
        amount: null,
      },
      {
        description: 'F3. PMI, MIP, Funding Fee',
        amount: null,
      },
    ],
  },
  {
    description: 'G. Discount (if borrower will pay)',
    amount: null,
    propertyName: 'discount',
  },
  {
    description: 'H. TOTAL DUE FROM BORROWER(s) (Total of A thru G)',
    amount: null,
    propertyName: 'total_due_from_borrower',
  },
  {
    description: 'I. Loan Amount',
    amount: null,
    propertyName: 'loan_amount',
    children: [
      {
        description:
          'Loan Amount Excluding Financed Mortgage Insurance (or Mortgage Insurance Equipment) $400,800.00',
        amount: null,
      },
      {
        description:
          'Financed Mortgage Insurance (or Mortgage Insurance Equivalent) Amount $0.00',
        amount: null,
      },
    ],
  },
  {
    description:
      'J. Other New Mortgage Loans on the Property the Borrower(s) is Buying or Refinancing',
    amount: null,
    propertyName: 'other_loan_amount',
    isInputTrue: true,
  },
  {
    description: 'K. TOTAL MORTGAGE (Total of I and J)',
    amount: null,
    propertyName: 'total_mortgage_loans',
  },
  {
    description:
      'L. Seller Credits (Borrower(s) costs paid by the property seller)',
    amount: null,
    propertyName: 'seller_credits',
  },
  {
    description:
      'M. Other Credits (Enter the Sum of all other credits - Borrower Paid Fees, Earnest Money, Employer Assisted Housing, Lease Purchase Fund, Lot Equity, Relocation Funds, Sweat Equity, Trade Equity, Other)',
    amount: null,
    isChildTable: true,
    propertyName: 'other_credits',
    children: [
      {
        options: [
          'Other Payee Credit',
          'Option Two',
          'Option Three',
          'Option Four',
        ],
        amount: null,
      },
      {
        options: ['cash deposit', 'Option Two', 'Option Three', 'Option Four'],
        amount: null,
      },
      {
        options: [
          'Borrower paid fees',
          'Option Two',
          'Option Three',
          'Option Four',
        ],
        amount: null,
      },
      {
        options: [],
        amount: '',
      },
      {
        options: [],
        amount: '',
      },
    ],
  },
  {
    description: 'N. TOTAL CREDITS (Total of L and M)',
    amount: null,
    propertyName: 'total_credits',
  },
];

export const calculationDataArray = [
  {
    description: 'CALCULATION',
    amount: null,
  },
  {
    description: 'TOTAL DUE FROM BORROWER(s) (Line H)',
    propertyName: 'total_due_from_borrower',
    amount: null,
  },
  {
    description:
      'LESS TOTAL MORTGAGE LOANS (Line K) AND TOTAL CREDITS (Line N)',
    propertyName: 'less_total_mortgage_and_credit',
    amount: null,
  },
  {
    description: 'Cash From/To the Borrower (Line H minus Line K and Line N) ',
    propertyName: 'cash_from_borrower_without_credit',
    points: [
      'Note: This amount does not include reserves or other funds that may be required by the Lender to be verified.',
    ],
    amount: null,
  },
];
export const availableAssetsArray = [
  {
    description: 'Available Assets:',
    propertyName: 'available_assets',
    amount: null,
  },
  {
    description: 'Cash From/To Borrower:',
    propertyName: 'cash_from_to_borrower',
    amount: null,
  },
  {
    description: 'Surplus:',
    propertyName: 'surplus',
    amount: null,
    isHighlighted: true,
  },
];
