import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dashboardAPI = createApi({
  reducerPath: 'dashboard',
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    getApplicationDashboardInfo: builder.query({
      query: (applicationId) => {
        return {
          url: `loan/dashboard/loan_info/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
  }),
});

export const { useGetApplicationDashboardInfoQuery } = dashboardAPI;
