import React, { useEffect, useMemo, useState } from 'react';

//components
import NestedTable from 'components/nested-table';
import Badge from 'components/badge';
import Button from 'components/button';
import InputGroup from 'components/input-group';
import { customToast } from 'components/toast';
import Loader from '../../../wrapper-component/loader';
//icons
import arrowUp from 'assets/icons/arrowUp.svg';
import arrowDown from 'assets/icons/arrowDown.svg';
import Document from 'assets/icons/Document';
import view from 'assets/icons/view.svg';
import upload from 'assets/icons/upload.svg';
import deleteIcon from 'assets/icons/delete.svg';
import searchIcon from 'assets/icons/search.svg';
import closeIcon from 'assets/icons/closeIcon.svg';
import plus from 'assets/icons/plus.svg';

//libraries
import Select from 'react-select';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';

//themes
import { bgColors, textColors, borderColors } from '../../../themes/colors';

//modals
import DeleteDocumentPopup from 'pages/borrower-profile/documents/modals/delete/index';
import UploadDocumentPopup from 'pages/borrower-profile/documents/modals/upload';
import ViewDocuments from 'pages/borrower-profile/documents/modals/view';
import BatchDelete from 'pages/borrower-profile/documents/modals/batch-delete';
import VerifyDocument from 'pages/borrower-profile/documents/modals/verify';

//utility function
import { documentMapper } from './utils/mapDocuments';
import { convertUnixToCustomDate } from 'constants/unixToDate';
import { getInitials } from 'constants/getFirstChar';

//API queries
import {
  useGetDocumentListQuery,
  useGetUserPropertyQuery,
} from 'redux_store/services/admin/document';
import { fetchAdminDocument } from 'redux_store/services/documentDownloads';

//custom hook
import useDownloadPDFHandler from 'hooks/pdfViewer';

const Documents = ({ state }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  //states
  const [selectedAction, setSelectedAction] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [deletePopup, setDeletePopup] = useState(false);
  const [batchDeletePopup, setBatchDeletePopup] = useState(false);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [addNewDocument, setAddNewDocument] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [tableState, setTableState] = useState([]);
  const [key, setKey] = useState(0);
  const [viewVerify, setViewVerify] = useState(false);
  const [verifyDocumentData, setVerifyDocumentData] = useState({});

  //query for documents list for table
  const {
    data: docListData,
    isLoading: docListLoading,
    refetch: docListRefetch,
    isError: docListError,
  } = useGetDocumentListQuery(
    { applicationId: applicationId, userId: state?.userId },
    { refetchOnMountOrArgChange: true }
  );

  // query to fetch user details to be mapped to dropdown
  const {
    data: userProperty,
    isLoading: userPropertyLoading,
    isError: userPropertyError,
  } = useGetUserPropertyQuery(
    { applicationId: applicationId },
    { refetchOnMountOrArgChange: true }
  );

  //custom hook to view/download pdf
  const { downloadPDF } = useDownloadPDFHandler();

  // logs
  useEffect(() => {
    Sentry.captureException(docListError);
    Sentry.captureException(userPropertyError);
  }, []);

  // handle view document
  const handleFetchDocumentList = async (rowData) => {
    if (rowData.documents.length > 1) {
      setViewPopup(true);
      setSelectedType(rowData);
    } else {
      try {
        customToast.promise(
          downloadPDF(
            fetchAdminDocument,
            {
              documentId: rowData?.documents[0]?.userDocumentId,
              userId: state?.userId,
            },
            { download: false }
          ),
          'Fetching your file',
          'Your file fetched successfully'
        );
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error downloading the PDF:', error);
      }
    }
  };
  //function to handle the verify document button
  const handleVeryDocument = async (rowData) => {
    setVerifyDocumentData(rowData);
    setViewVerify(true);
  };
  //set add new document as false upon popup close (clearing state)
  useEffect(() => {
    if (!uploadPopup) {
      setAddNewDocument(false);
    }
  }, [uploadPopup]);

  // map the documents to relevant structure using documentMapper
  useEffect(() => {
    if (docListData) {
      const result = documentMapper(docListData?.data);
      setTableState(result);
    }
    if (userProperty?.data) {
      setSelectedAction([userProperty?.data]);
      //need key to force update react select state to set default value
      setKey((prev) => prev + 1);
    }
  }, [docListData, userProperty]);

  //reset existing state on certain component unmount
  const resetState = () => {
    setSelectedType('');
    setDocumentId(null);
  };

  //useMemo will not cause an infinite loop of re-renders because `columns` is a stable reference
  const columns = useMemo(
    () => [
      {
        header: 'Document Name',
        accessorKey: 'name',
        cell: ({ row, getValue }) => {
          return (
            <div
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: {
                  cursor: 'pointer',
                  paddingLeft: `${row.depth * 3}rem`,
                },
              }}
            >
              <div>
                {row.getCanExpand() ? (
                  <button>
                    {row.getIsExpanded() ? (
                      <img src={arrowUp} className="pr-3" alt="" />
                    ) : (
                      <img src={arrowDown} className="pr-3" alt="" />
                    )}
                  </button>
                ) : (
                  ''
                )}{' '}
                {getValue()}
              </div>
            </div>
          );
        },
      },
      {
        header: 'Date uploaded',
        accessorKey: 'timestamp',
        cell: ({ row }) => {
          return convertUnixToCustomDate(
            row.original.timestamp,
            'MM-dd-yy, KK:mm bb'
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status.statusId',
        cell: ({ row }) => {
          return !row.getIsExpanded() && row.original.documentStatusId ? (
            <div className="flex gap-3 items-center">
              <Document
                color={
                  row.original.documentStatusId === 3
                    ? '#374062'
                    : row.original.documentStatusId === 1
                      ? '#CC7914'
                      : row.original.documentStatusId === 2
                        ? '#0B7B69'
                        : row.original.documentStatusId === 4
                          ? '#E4626F'
                          : row.original.documentStatusId === 5
                            ? '#8C1823'
                            : ''
                }
              />
              <div className="w-[10rem] text-center">
                <Badge
                  title={
                    row.original.documentStatusId === 3
                      ? 'Pending Verification'
                      : row.original.documentStatusId === 1
                        ? 'Pending'
                        : row.original.documentStatusId === 2
                          ? 'Uploaded'
                          : row.original.documentStatusId === 4
                            ? 'Verification rejected'
                            : row.original.documentStatusId === 5
                              ? `${row.original.pending} Document Required`
                              : ''
                  }
                  variant={
                    row.original.documentStatusId === 3
                      ? 'verifying'
                      : row.original.documentStatusId === 1
                        ? 'secondary'
                        : row.original.documentStatusId === 2
                          ? 'success'
                          : row.original.documentStatusId === 4
                            ? 'rejected'
                            : row.original.documentStatusId === 5
                              ? 'tertiary'
                              : ''
                  }
                />
              </div>
            </div>
          ) : (
            <div></div>
          );
        },
      },
      {
        header: 'Actions',
        accessorKey: 'actions',
        meta: {
          className: ' sticky right-0', //sticky column
        },
        cell: ({ row }) => {
          const isChildRow = row.depth > 0;
          if (isChildRow) {
            const { documentStatusId, documentTypeId } = row.original;
            const showDeleteAndView = documentStatusId !== 1;
            if (row.original.documentStatusId === 3) {
              return (
                <>
                  <div className="flex gap-5 justify-center w-[150px]">
                    <Button
                      variant="primary"
                      onClick={() => handleVeryDocument(row?.original)}
                    >
                      Verify Document
                    </Button>
                  </div>
                </>
              );
            }
            return (
              <div className="flex gap-5 justify-center w-[120px]">
                {showDeleteAndView && (
                  <>
                    <div
                      onClick={() => handleFetchDocumentList(row.original)}
                      className="hover:bg-chatbot_bg h-[24px] w-[24px] rounded-full hover:h-[24px] hover:w-[24px] hover:rounded-full cursor-pointer flex justify-center items-center"
                    >
                      <img src={view} alt="" className="cursor-pointer" />
                    </div>
                  </>
                )}
                {documentTypeId !== 9 && (
                  <div
                    onClick={() => {
                      setUploadPopup(true);
                      setSelectedType(row.original);
                    }}
                    className="hover:bg-chatbot_bg h-[24px] w-[24px] rounded-full hover:h-[24px] hover:w-[24px] hover:rounded-full cursor-pointer flex justify-center items-center"
                  >
                    <img src={upload} alt="" className="cursor-pointer" />
                  </div>
                )}
                {showDeleteAndView && (
                  <>
                    <div
                      onClick={() => {
                        if (row.original.documents.length > 1) {
                          setBatchDeletePopup(true);
                          setSelectedType(row.original);
                        } else {
                          setDeletePopup(true);
                          setSelectedType(row.original);
                        }
                      }}
                      className="hover:bg-error_light h-[24px] w-[24px] rounded-full hover:h-[24px] hover:w-[24px] hover:rounded-full cursor-pointer flex justify-center items-center"
                    >
                      <img src={deleteIcon} alt="" className="cursor-pointer" />
                    </div>
                  </>
                )}
              </div>
            );
          }

          return null;
        },
      },
    ],
    []
  );

  return (
    <>
      {docListLoading ? (
        <Loader />
      ) : (
        <div className="w-full h-[60vh]">
          <div className="flex flex-col justify-between h-auto gap-6 overflow-auto  max-h-custom-pipeline mt-6 ">
            <div className="flex gap-2 justify-between items-center">
              <div className="flex gap-2">
                <Select
                  key={key}
                  defaultValue={selectedAction}
                  onChange={setSelectedAction}
                  menuShouldScrollIntoView={true}
                  menuShouldBlockScroll={true}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  options={[userProperty?.data] ? [userProperty?.data] : []}
                  getOptionLabel={(e) => (
                    <div className="flex items-center">
                      <div
                        className={`w-6 h-6 bg-profile rounded-full flex justify-center items-center mr-2`}
                      >
                        {getInitials(e.user_profile?.firstName)}
                      </div>
                      <span className="mt-0.5 whitespace-nowrap truncate w-[14rem]">
                        {e?.user_profile?.firstName}{' '}
                        {e?.user_profile?.last_name}{' '}
                        {e.loan_information?.propertyAddress
                          ? `- ${e.loan_information?.propertyAddress}`
                          : ''}
                      </span>
                    </div>
                  )}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      fontSize: '14px',
                      position: 'relative',
                      fontWeight: '500',
                      borderRadius: '5px',
                      boxShadow: 'none',
                      border: `1px solid ${borderColors.primary}`,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? bgColors.primary100
                        : bgColors.tertiary_bg,
                      borderBottom: `1px solid ${borderColors.primary}`,
                      color: textColors.black,
                      fontSize: '14px',
                      fontWeight: '500',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }),
                    indicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral80: textColors.black,
                    },
                  })}
                  placeholder="Select Property"
                  className="w-[20rem]"
                />
              </div>
              <div className="flex gap-2">
                <div className="w-[16rem] relative">
                  <InputGroup
                    size="sm_2"
                    position="center"
                    placeholder="Search Document"
                    svg={searchIcon}
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                  {globalFilter && (
                    <img
                      src={closeIcon}
                      alt=""
                      className="absolute top-3 right-4 cursor-pointer"
                      onClick={() => setGlobalFilter('')}
                    />
                  )}
                </div>
                <Button
                  variant="primary_sm"
                  onClick={() => {
                    setAddNewDocument(true);
                    setUploadPopup(true);
                  }}
                >
                  <img src={plus} alt="" /> Add Document
                </Button>
              </div>
            </div>
            <NestedTable
              data={tableState}
              columns={columns}
              errorMessage={`${globalFilter ? 'No document found with the search criteria. Please check and try again' : 'No documents found.'}`}
              height="h-[72vh]"
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>

          {deletePopup && (
            <DeleteDocumentPopup
              setDeletePopup={setDeletePopup}
              deletePopup={deletePopup}
              data={selectedType}
              userId={state.userId}
              resetState={resetState}
              documentId={documentId}
              setDocumentId={setDocumentId}
            />
          )}
          {uploadPopup && (
            <UploadDocumentPopup
              setUploadPopup={setUploadPopup}
              uploadPopup={uploadPopup}
              isAddNewDocument={addNewDocument}
              data={selectedType}
              resetState={resetState}
              applicationId={state.applicationId}
              userId={state.userId}
              userProfileId={selectedAction[0]?.user_profile?.userProfileId}
            />
          )}
          {viewPopup && (
            <ViewDocuments
              setViewPopup={setViewPopup}
              viewPopup={viewPopup}
              resetState={resetState}
              documents={selectedType}
              userId={state.userId}
            />
          )}
          {batchDeletePopup && (
            <BatchDelete
              setBatchPopup={setBatchDeletePopup}
              batchPopup={batchDeletePopup}
              setDeletePopup={setDeletePopup}
              documents={selectedType}
              setDocumentId={setDocumentId}
              resetState={resetState}
            />
          )}
          {viewVerify && (
            <VerifyDocument
              setViewVerify={setViewVerify}
              viewVerify={viewVerify}
              documents={verifyDocumentData}
              userId={state.userId}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Documents;
