import { api } from 'constants/constant';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getUserDetails } from 'auth/auth';

export const documentUploadAPI = createApi({
  reducerPath: 'uploadDocs',
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    uploadChatDocs: builder.mutation({
      query: ({ data, additionalParams, userProfileId, userRole }) => {
        const { token } = getUserDetails();
        const formData = new FormData();
        formData.append('file', data);
        // Object.keys(additionalParams).forEach((key) => {
        //   formData.append(key, additionalParams[key]);
        // });
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        const roleBasedEndpoint = userRole === 'loanOfficer' ? '-lo' : '';
        return {
          url: `/ai/doc-agent${roleBasedEndpoint}?${userProfileId ? `user_profile_id=${userProfileId}` : ''}`,
          method: 'POST',
          headers: headers,
          body: formData,
        };
      },
    }),
  }),
});

export const { useUploadChatDocsMutation } = documentUploadAPI;
