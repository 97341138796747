import React, { useEffect, useState, useMemo } from 'react';

//components
import Breadcrumb from 'components/breadcrumb';
import H1 from 'components/h1-typography';
import NestedTable from 'components/nested-table';
import Badge from 'components/badge';
import Button from 'components/button';
import InputGroup from 'components/input-group';
import CircularProgressBar from 'components/circular-progress-bar';
import { customToast } from 'components/toast';

//icons
import arrowUp from 'assets/icons/arrowUp.svg';
import arrowDown from 'assets/icons/arrowDown.svg';
import Document from 'assets/icons/Document';
import view from 'assets/icons/view.svg';
import upload from 'assets/icons/upload.svg';
import deleteIcon from 'assets/icons/delete.svg';
import searchIcon from 'assets/icons/search.svg';
import closeIcon from 'assets/icons/closeIcon.svg';
import plus from 'assets/icons/plus.svg';

//libraries
import Select from 'react-select';
import * as Sentry from '@sentry/browser';

//themes
import { bgColors, textColors, borderColors } from '../../themes/colors';

//modals
import DeleteDocumentPopup from 'pages/documents/modals/delete';
import UploadDocumentPopup from './modals/upload';

//utility function
import { documentMapper } from './utils/mapDocuments';
import { convertUnixToCustomDate } from 'constants/unixToDate';
import { getInitials } from 'constants/getFirstChar';

//API queries
import {
  useGetDocumentListQuery,
  useGetUserPropertyQuery,
} from 'redux_store/services/client/document';
import { fetchClientDocument } from 'redux_store/services/documentDownloads';

//custom hook
import useDownloadPDFHandler from 'hooks/pdfViewer';
import ViewDocuments from './modals/view';
import BatchDelete from './modals/batch-delete';
import usePageTitle from 'constants/PageTitle';

const Documents = () => {
  usePageTitle('documents');
  //states
  const [selectedAction, setSelectedAction] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [deletePopup, setDeletePopup] = useState(false);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [addNewDocument, setAddNewDocument] = useState(false);
  const [batchDeletePopup, setBatchDeletePopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [tableState, setTableState] = useState([]);
  const [key, setKey] = useState(0);
  const [percentage, setPercentage] = useState(0);
  //query
  const {
    data: docListData,
    isLoading: docListLoading,
    refetch: docListRefetch,
    isError: docListError,
  } = useGetDocumentListQuery(
    selectedAction ? { applicationId: selectedAction?.application_id } : null,
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: userProperty,
    isLoading: userPropertyLoading,
    isError: userPropertyError,
  } = useGetUserPropertyQuery({}, { refetchOnMountOrArgChange: true });

  const { downloadPDF } = useDownloadPDFHandler();

  //breadcrumbs
  const breadCrumbs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Documents',
      path: '/documents',
    },
  ];

  useEffect(() => {
    Sentry.captureException(docListError);
    Sentry.captureException(userPropertyError);
  }, []);

  const handleFetchDocumentList = async (rowData) => {
    if (rowData.documents.length > 1) {
      setViewPopup(true);
      setSelectedType(rowData);
    } else {
      try {
        customToast.promise(
          downloadPDF(
            fetchClientDocument,
            {
              documentId: rowData?.documents[0]?.userDocumentId,
            },
            { download: false }
          ),
          'Fetching your file',
          'Your file fetched successfully'
        );
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error downloading the PDF:', error);
      }
    }
  };

  //set add new document as false upon popup close (clearing state)
  useEffect(() => {
    if (!uploadPopup) {
      setAddNewDocument(false);
    }
  }, [uploadPopup]);

  useEffect(() => {
    if (docListData) {
      const result = documentMapper(docListData?.data);
      setTableState(result);
    }
    if (userProperty?.data) {
      setSelectedAction(userProperty?.data[0]);
      //need key to force update react select state to set default value
      setKey((prev) => prev + 1);
    }
  }, [docListData, userProperty]);

  //completed percent
  useEffect(() => {
    let totalCompleted = 0;
    let totalPendingAndCompleted = 0;

    tableState?.forEach((documentType) => {
      totalCompleted += documentType.completed;
      totalPendingAndCompleted += documentType.pending + documentType.completed;
    });

    const percent = (totalCompleted / totalPendingAndCompleted) * 100;

    setPercentage(percent ? Number(percent.toFixed(0)) : 0);
  }, [tableState]);

  //reset existing state on certain component unmount
  const resetState = () => {
    setSelectedType('');
    setDocumentId(null);
  };
  //useMemo will not cause an infinite loop of re-renders because `columns` is a stable reference
  const columns = useMemo(
    () => [
      {
        header: 'Document Name',
        accessorKey: 'name',
        cell: ({ row, getValue }) => {
          return (
            <div
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: {
                  cursor: 'pointer',
                  paddingLeft: `${row.depth * 3}rem`,
                },
              }}
            >
              <div>
                {row.getCanExpand() ? (
                  <button>
                    {row.getIsExpanded() ? (
                      <img src={arrowUp} className="pr-3" alt="" />
                    ) : (
                      <img src={arrowDown} className="pr-3" alt="" />
                    )}
                  </button>
                ) : (
                  ''
                )}{' '}
                {getValue()}
              </div>
            </div>
          );
        },
      },
      {
        header: 'Date uploaded',
        accessorKey: 'timestamp',
        cell: ({ row }) => {
          return convertUnixToCustomDate(
            row.original.timestamp,
            'MM-dd-yy, KK:mm bb'
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status.statusId',
        cell: ({ row }) => {
          return !row.getIsExpanded() && row.original.documentStatusId ? (
            <div className="flex gap-3 items-center">
              <Document
                color={
                  row.original.documentStatusId === 3
                    ? '#374062'
                    : row.original.documentStatusId === 1
                      ? '#CC7914'
                      : row.original.documentStatusId === 2
                        ? '#0B7B69'
                        : row.original.documentStatusId === 4
                          ? '#E4626F'
                          : row.original.documentStatusId === 5
                            ? '#8C1823'
                            : ''
                }
              />
              <div className="w-[10rem] text-center">
                <Badge
                  title={
                    row.original.documentStatusId === 3
                      ? 'Verification in progress'
                      : row.original.documentStatusId === 1
                        ? 'Pending'
                        : row.original.documentStatusId === 2
                          ? 'Uploaded'
                          : row.original.documentStatusId === 4
                            ? 'Verification rejected'
                            : row.original.documentStatusId === 5
                              ? `${row.original.pending} Document Required`
                              : ''
                  }
                  variant={
                    row.original.documentStatusId === 3
                      ? 'verifying'
                      : row.original.documentStatusId === 1
                        ? 'secondary'
                        : row.original.documentStatusId === 2
                          ? 'success'
                          : row.original.documentStatusId === 4
                            ? 'rejected'
                            : row.original.documentStatusId === 5
                              ? 'tertiary'
                              : ''
                  }
                />
              </div>
            </div>
          ) : (
            <div></div>
          );
        },
      },
      {
        header: 'Actions',
        accessorKey: 'actions',
        meta: {
          className: ' sticky right-0', //sticky column
        },
        cell: ({ row }) => {
          const isChildRow = row.depth > 0;
          if (isChildRow) {
            const { documentStatusId, documentTypeId } = row.original;
            const showDeleteAndView = documentStatusId !== 1;
            return (
              <div className="flex gap-5 justify-center w-[120px]">
                {showDeleteAndView && (
                  <>
                    <div
                      onClick={() => handleFetchDocumentList(row.original)}
                      className="hover:bg-chatbot_bg h-[24px] w-[24px] rounded-full hover:h-[24px] hover:w-[24px] hover:rounded-full cursor-pointer flex justify-center items-center"
                    >
                      <img src={view} alt="" className="cursor-pointer" />
                    </div>
                  </>
                )}
                {documentTypeId !== 9 && (
                  <div
                    onClick={() => {
                      setUploadPopup(true);
                      setSelectedType(row.original);
                    }}
                    className="hover:bg-chatbot_bg h-[24px] w-[24px] rounded-full hover:h-[24px] hover:w-[24px] hover:rounded-full cursor-pointer flex justify-center items-center"
                  >
                    <img src={upload} alt="" className="cursor-pointer" />
                  </div>
                )}
                {showDeleteAndView && (
                  <>
                    <div
                      onClick={() => {
                        if (row.original.documents.length > 1) {
                          setBatchDeletePopup(true);
                          setSelectedType(row.original);
                        } else {
                          setDeletePopup(true);
                          setSelectedType(row.original);
                        }
                      }}
                      className="hover:bg-error_light h-[24px] w-[24px] rounded-full hover:h-[24px] hover:w-[24px] hover:rounded-full cursor-pointer flex justify-center items-center"
                    >
                      <img src={deleteIcon} alt="" className="cursor-pointer" />
                    </div>
                  </>
                )}
              </div>
            );
          }

          return null;
        },
      },
    ],
    []
  );

  return (
    <div className="w-full">
      <Breadcrumb breadcrumbs={breadCrumbs} />
      <div className="flex flex-col justify-between h-auto gap-6 overflow-auto  max-h-custom-pipeline ">
        <div className="flex justify-between items-center">
          <H1>Documents</H1>

          <div
            className={`h-[48px] w-[183px] ${percentage === 100 ? 'bg-primary100' : 'bg-warning_light'} rounded-xl flex justify-between items-center gap-1 px-4`}
          >
            <CircularProgressBar
              strokeWidth={3}
              sqSize={25}
              percentageColor={`${percentage === 100 ? 'stroke-primary500' : 'stroke-warning300'}`}
              circleColor={`${percentage === 100 ? 'stroke-primary100' : 'stroke-warning100'}`}
              percentage={percentage}
            />
            <span
              className={`font-medium-bold ${percentage === 100 ? 'text-primary500' : 'text-warning300'}`}
            >
              {percentage}% Completed
            </span>
          </div>
        </div>

        <div className="flex gap-2 justify-between items-center">
          <div className="flex gap-2">
            <Select
              key={key}
              menuShouldScrollIntoView={true}
              menuShouldBlockScroll={true}
              menuPlacement="auto"
              menuPosition="fixed"
              defaultValue={selectedAction}
              onChange={setSelectedAction}
              options={userProperty?.data ? userProperty?.data : []}
              getOptionLabel={(e) => (
                <div className="flex items-center">
                  <div
                    className={`w-6 h-6 bg-profile rounded-full flex justify-center items-center mr-2`}
                  >
                    {getInitials(e.first_name)}
                  </div>
                  <span className="mt-0.5 whitespace-nowrap truncate w-[14rem]">
                    {e.first_name} {e.last_name}{' '}
                    {e.property_address ? `- ${e.property_address}` : ''}
                  </span>
                </div>
              )}
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: '14px',
                  position: 'relative',
                  fontWeight: '500',
                  borderRadius: '5px',
                  boxShadow: 'none',
                  border: `1px solid ${borderColors.primary}`,
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? bgColors.primary100
                    : bgColors.tertiary_bg,
                  borderBottom: `1px solid ${borderColors.primary}`,
                  color: textColors.black,
                  fontSize: '14px',
                  fontWeight: '500',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }),
                indicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral80: textColors.black,
                },
              })}
              placeholder="Select Property"
              className="w-[20rem]"
            />
          </div>
          <div className="flex gap-2">
            <div className="w-[16rem] relative">
              <InputGroup
                size="sm_2"
                position="center"
                placeholder="Search Document"
                svg={searchIcon}
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              {globalFilter && (
                <img
                  src={closeIcon}
                  alt=""
                  className="absolute top-3 right-4 cursor-pointer"
                  onClick={() => setGlobalFilter('')}
                />
              )}
            </div>
            <Button
              variant="primary_sm"
              onClick={() => {
                setAddNewDocument(true);
                setUploadPopup(true);
              }}
            >
              <img src={plus} alt="" /> Add Document
            </Button>
          </div>
        </div>
        <NestedTable
          data={tableState}
          columns={columns}
          errorMessage={`${globalFilter ? 'No document found with the search criteria. Please check and try again' : 'No documents found.'}`}
          height="min-h-[60vh]"
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>

      {deletePopup && (
        <DeleteDocumentPopup
          setDeletePopup={setDeletePopup}
          deletePopup={deletePopup}
          data={selectedType}
          resetState={resetState}
          documentId={documentId}
          setDocumentId={setDocumentId}
          userId={selectedAction?.user_id}
        />
      )}
      {uploadPopup && (
        <UploadDocumentPopup
          setUploadPopup={setUploadPopup}
          uploadPopup={uploadPopup}
          isAddNewDocument={addNewDocument}
          data={selectedType}
          resetState={resetState}
          applicationId={selectedAction?.application_id}
          userProfileId={selectedAction?.user_profile_id}
          userId={selectedAction?.user_id}
        />
      )}
      {viewPopup && (
        <ViewDocuments
          setViewPopup={setViewPopup}
          viewPopup={viewPopup}
          resetState={resetState}
          documents={selectedType}
          userId={selectedAction?.user_id}
        />
      )}
      {batchDeletePopup && (
        <BatchDelete
          setBatchPopup={setBatchDeletePopup}
          batchPopup={batchDeletePopup}
          setDeletePopup={setDeletePopup}
          documents={selectedType}
          setDocumentId={setDocumentId}
          resetState={resetState}
        />
      )}
    </div>
  );
};

export default Documents;
