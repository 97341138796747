/* istanbul ignore file */
import { checkTextInput, withValidations } from '../utils';

// Dynamic form fields with their configurations.
const assetDataInfo = [
  {
    id: 1,
    name: 'Asset Type',
    type: 'select',
    placeholder: 'Select Asset Type',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'assetTypeId',
  },
  {
    id: 2,
    name: 'Bank Name',
    type: 'text',
    placeholder: 'Enter Bank Name',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'bankName',
    validator: checkTextInput,
  },
  {
    id: 3,
    name: 'Account Number',
    type: 'number',
    placeholder: 'Enter Account Number',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'accountNumber',
  },
  {
    id: 4,
    name: 'Balance',
    type: 'number',
    placeholder: 'Enter Balance',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'balance',
  },
];

export const assetData = withValidations(assetDataInfo);

const otherAssetsInfo = [
  {
    id: 1,
    name: 'Asset Type',
    type: 'select',
    placeholder: 'Select Asset Type',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'otherAssetTypeId',
  },
  {
    id: 6,
    name: 'Source',
    type: 'text',
    placeholder: 'Enter Source',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'otherAccountSource',
    validator: checkTextInput,
  },
  {
    id: 7,
    name: 'Balance',
    type: 'number',
    placeholder: 'Enter Balance',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'otherAccountBalance',
  },
];

export const otherAssets = withValidations(otherAssetsInfo);

export const otherCreditsInfo = [
  {
    id: 8,
    name: 'Realtor Credit',
    type: 'number',
    placeholder: 'Enter Realtor Credit',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'realtorCredit',
  },
  {
    id: 9,
    name: 'Seller Credit',
    type: 'number',
    placeholder: 'Enter Seller Credit',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'sellerCredit',
  },
  {
    id: 10,
    name: 'Lender Credit',
    type: 'number',
    placeholder: 'Enter Lender Credit',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'lenderCredit',
  },
  {
    id: 11,
    name: 'Other Credit',
    type: 'number',
    placeholder: 'Enter Other Credit',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'otherCredit',
  },
];

export const otherCredits = withValidations(otherCreditsInfo);
