import { configureStore } from '@reduxjs/toolkit';
import { applicationAPI } from './services/client/profile';
import application from './slices/application';
import onboarding from './slices/onboarding';
import chatMessage from './slices/chatMessage';
import userRole from './slices/userRole';
import { onboardingAPI } from './services/client/onboarding';
import { documentUploadAPI } from './services/client/documentUpload';
import { pipelineAPI } from './services/client/pipeline';
import { documentAPI } from './services/client/document';
import { dashboardAPI } from './services/client/dashboard';
import { loanInformationAPI } from './services/admin/loanInformation';
import { borrowerProfileAPI } from './services/borrower-profile';
import { passwordAPI } from './services/update-password';
import { adminFeesworksheetAPI } from './services/admin/feesworksheet';
import { adminPipelineAPI } from './services/admin/pipeline';
import { productAndPricingAPI } from './services/admin/product-pricing';
import { adminConsumerAPI } from './services/admin/adminConsumer';
import { adminEmployeeAPI } from './services/admin/adminEmployee';
import { adminDocumentAPI } from './services/admin/document';
import { adminToolsAPI } from './services/admin/adminTools';
import { quickPriceAPI } from './services/admin/quickPrice';
import { reportsAPI } from './services/admin/reports';
import { notificationsAPI } from './services/notifications';
//loan officer
import { officerLoanInformationAPI } from './services/officer/loanInformation';
import { officerPipelineAPI } from './services/officer/pipeline';

export const store = configureStore({
  reducer: {
    application,
    onboard: onboarding,
    chatMessage,
    userRole,
    [onboardingAPI.reducerPath]: onboardingAPI.reducer,
    [applicationAPI.reducerPath]: applicationAPI.reducer,
    [pipelineAPI.reducerPath]: pipelineAPI.reducer,
    [documentUploadAPI.reducerPath]: documentUploadAPI.reducer,
    [documentAPI.reducerPath]: documentAPI.reducer,
    [dashboardAPI.reducerPath]: dashboardAPI.reducer,
    [loanInformationAPI.reducerPath]: loanInformationAPI.reducer,
    [adminFeesworksheetAPI.reducerPath]: adminFeesworksheetAPI.reducer,
    [borrowerProfileAPI.reducerPath]: borrowerProfileAPI.reducer,
    [passwordAPI.reducerPath]: passwordAPI.reducer,
    [adminPipelineAPI.reducerPath]: adminPipelineAPI.reducer,
    [productAndPricingAPI.reducerPath]: productAndPricingAPI.reducer,
    [adminConsumerAPI.reducerPath]: adminConsumerAPI.reducer,
    [adminEmployeeAPI.reducerPath]: adminEmployeeAPI.reducer,
    [adminDocumentAPI.reducerPath]: adminDocumentAPI.reducer,
    [adminToolsAPI.reducerPath]: adminToolsAPI.reducer,
    [quickPriceAPI.reducerPath]: quickPriceAPI.reducer,
    [reportsAPI.reducerPath]: reportsAPI.reducer,
    [notificationsAPI.reducerPath]: notificationsAPI.reducer,
    [officerLoanInformationAPI.reducerPath]: officerLoanInformationAPI.reducer,
    [officerPipelineAPI.reducerPath]: officerPipelineAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      onboardingAPI.middleware,
      applicationAPI.middleware,
      pipelineAPI.middleware,
      documentUploadAPI.middleware,
      documentAPI.middleware,
      dashboardAPI.middleware,
      loanInformationAPI.middleware,
      borrowerProfileAPI.middleware,
      passwordAPI.middleware,
      adminFeesworksheetAPI.middleware,
      adminPipelineAPI.middleware,
      productAndPricingAPI.middleware,
      adminConsumerAPI.middleware,
      adminEmployeeAPI.middleware,
      adminDocumentAPI.middleware,
      reportsAPI.middleware,
      adminToolsAPI.middleware,
      quickPriceAPI.middleware,
      notificationsAPI.middleware,
      officerLoanInformationAPI.middleware,
      officerPipelineAPI.middleware
    ),
});
