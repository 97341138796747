/* istanbul ignore file */
export const statusVariantMap = {
  12: 'primary',
  8: 'primary',
  1: 'primary',
  2: 'secondary',
  3: 'secondary',
  6: 'secondary',
  9: 'secondary',
  10: 'secondary',
  4: 'tertiary',
  7: 'tertiary',
  11: 'tertiary',
};

export const summaryMapping = {
  'Property Address': { key: 'property_address' },
  'Application Date': { key: 'application_date', format: 'date' },
  'Est. Closing Date': { key: 'closing_date', format: 'date' },
  'Loan Amount': { key: 'loan_amount', format: 'currency' },
  'Credit Score': { key: 'credit_score' },
  'Program Name': { key: 'loan_program_id' },
  'Appraised Value': { key: 'appraised_value', format: 'currency' },
  'Purchase Type': { key: 'purchase_type_id' },
  'Base Loan Amount': { key: 'base_loan_amount', format: 'currency' },
  'Interest Rate': { key: 'interest_rate', format: 'percentage' },
  'LT/CTV': { key: 'ltv', format: 'percentage' },
  'Loan Type': { key: 'loan_type_id' },
  'Terms in months': { key: 'loan_term_id' },
  'Property Type': { key: 'property_type_id' },
  'Total Loan Amount': { key: 'total_loan_amount', format: 'currency' },
  'DTI/Bottom': { key: 'dti_bottom', format: 'percentage' },
  'Total PITI': { key: 'total_piti', format: 'currency' },
  'APR Disclosed': { key: 'apr_disclosed', format: 'percentage' },
  'QM Results': { key: 'qm_results' },
  'Tolerance Cure': { key: 'tolerance_cure', format: 'currency' },
  'HPML Results': { key: 'hpml_result' },
};

export const summaryCards = {
  gridA: ['Property Address'],
  gridB: [
    'Application Date',
    'Est. Closing Date',
    'Loan Amount',
    'Credit Score',
    'Program Name',
  ],
  gridC: [
    'Appraised Value',
    'Purchase Type',
    'Base Loan Amount',
    'Interest Rate',
    'LT/CTV',
    'Loan Type',
    'Terms in months',
    'Property Type',
    'Total Loan Amount',
    'DTI/Bottom',
    'Total PITI',
    'APR Disclosed',
    'QM Results',
    'Tolerance Cure',
    'HPML Results',
  ],
};
