import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
//components
import Button from 'components/button/index';
import { customToast } from 'components/toast';
import InputGroup from 'components/input-group';
import { toast } from 'react-toastify';
import Loader from 'wrapper/loader';
import DatePicker from 'react-datepicker';

//icons
import expandVector from 'assets/icons/expandVector.svg';
import shrinkVector from 'assets/icons/shrinkVector.svg';
import angleDown from 'assets/icons/angleDown.svg';

//fees data
import {
  addObjectToChildren,
  getAppropriateValue,
  initializeInputValues,
  mixBothData,
  removeObjectFromChildren,
  sumPropertyValues,
  updateCombinedArray,
  calculateAndFormatSum,
  updateLoanCosts,
  validateCustomTypes,
} from 'pages/borrower-profile/fees-worksheet/formData/feesWorksheet';

//RTK query
import { useGetBorrowerProfileQuery } from 'redux_store/services/borrower-profile';
import { useGetStatesQuery } from 'redux_store/services/admin/product-pricing.js';
import {
  useGetLoanCostTypesQuery,
  useGetUserFeesWorksheetQuery,
  useSaveUserFeesWorksheetMutation,
  useUpdateUserFeesWorksheetMutation,
  useGetLoanAndRateQuery,
  useGetLoadstarDataQuery,
  useGetRateVendorsQuery,
} from 'redux_store/services/admin/feesworksheet';
//utils
import { v4 as uuidv4 } from 'uuid';

const dropdownVal = [
  { name: 'Charge', value: 'Charge', id: 1 },
  { name: 'Credit', value: 'Credit', id: 2 },
];
const FeesWorksheet = () => {
  const [combinedData, setCombinedData] = useState([]);
  const [closingDate, setClosingDate] = useState({
    date: '',
    uniqueId: '',
  });
  const [firstPaymentDate, setFirstPaymentDate] = useState({
    date: '',
    uniqueId: '',
  });
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [expanded, setExpanded] = useState({
    pIndex: [],
    cIndex: [],
    MCindex: [],
  });
  const [expandedState, setExpandedState] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [cashFromBorrower, setCashFromBorrower] = useState('-');
  const [loanAmount, setLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(0);

  //dynamic fees
  const [loadstarData, setLoadstarData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [mergedData, setMergedData] = useState([]);

  //set expanded state
  useEffect(() => {
    const isAnyNotEmpty = Object?.values(expanded)?.some(
      (arr) => arr.length > 0
    );
    setExpandedState(isAnyNotEmpty);
  }, [expanded]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const [selectedCell, setSelectedCell] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [editable, setEditable] = useState(false);
  const [tableCellValues, setTableCellValues] = useState({});
  const [isTexas, setIsTexas] = useState(false);
  const [staticIds, setStaticIds] = useState({
    loanCostIds: [],
    otherCostIds: [],
  });

  const handleEditDetails = () => {
    setSelectedCell('');
    setEditable((prev) => !prev);
  };

  const resetCalendar = () => {
    setClosingDate({
      date: '',
      uniqueId: '',
    });
    setFirstPaymentDate({
      date: '',
      uniqueId: '',
    });
    setNumberOfDays(0);
  };
  const {
    data: feesWorksheetData,
    error: errorMessage,
    isLoading: isFeesworkSheetLoading,
  } = useGetUserFeesWorksheetQuery(
    {
      application_Id: applicationId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: loanCostTypes } = useGetLoanCostTypesQuery();
  const { data: stateType } = useGetStatesQuery();
  const { data: loadstarAPIData, isError } = useGetLoadstarDataQuery(
    {
      applicationId: applicationId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: vendorAPIData } = useGetRateVendorsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: amountAndRate } = useGetLoanAndRateQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [saveFeesData, { isLoading: isSaveFeesDataLoading }] =
    useSaveUserFeesWorksheetMutation();
  const [updateFeesData, { isLoading: isUpdateFeesDataLoading }] =
    useUpdateUserFeesWorksheetMutation();
  const { data: applicationData, refetch: refetchBorrowerInfo } =
    useGetBorrowerProfileQuery(
      { applicationId },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (amountAndRate?.status === 200) {
      setLoanAmount(
        amountAndRate?.data?.loan_amount ? amountAndRate?.data?.loan_amount : 0
      );
      setInterestRate(
        amountAndRate?.data?.rate ? amountAndRate?.data?.rate : 0
      );
    }

    if (amountAndRate?.status === 200 && stateType?.data) {
      const stateId = amountAndRate?.data?.state_id;
      const matchingState = stateType?.data?.find(
        (state) => state.stateId === stateId
      );

      if (matchingState?.code === 'TX') {
        setIsTexas(true);
      } else {
        setIsTexas(false);
      }
    }
  }, [amountAndRate, stateType]);

  // 3rd party fees
  useEffect(() => {
    setLoadstarData(loadstarAPIData?.data || []);
    setVendorData(vendorAPIData?.data[0]?.defaultFeesJson || []);
  }, [loadstarAPIData?.data, vendorAPIData?.data[0].defaultFeesJson]);

  useEffect(() => {
    if (loadstarData.length && vendorData.length) {
      const combinedData = [...loadstarData, ...vendorData];

      const mergedDataResult = combinedData.reduce((acc, current) => {
        const existing = acc.find(
          (item) => item.loan_cost_type_id === current.loan_cost_type_id
        );
        if (existing) {
          existing.amount += current.amount;
        } else {
          acc.push({
            loan_cost_type_id: current.loan_cost_type_id,
            amount: current.amount,
          });
        }
        return acc;
      }, []);

      if (isTexas && amountAndRate?.status === 200 && stateType?.data) {
        setMergedData(mergedDataResult);
      } else if (amountAndRate?.status === 200 && stateType?.data) {
        const modifiedData = mergedDataResult?.map((cost) =>
          cost.loan_cost_type_id === 19 ? { ...cost, amount: 0 } : cost
        );
        setMergedData(modifiedData);
      }
    }
  }, [loadstarData, vendorData, isTexas, amountAndRate, stateType]);

  useEffect(() => {
    if (loanCostTypes?.data && feesWorksheetData?.data) {
      const combineBothData = mixBothData(
        feesWorksheetData?.data?.fees_json ?? []
      );

      let customInputData = combineBothData.map((data) => ({
        ...data,
        children:
          data.children?.map((child) => ({
            ...child,
            chargeDropdown: child.chargeDropdown ?? true, // Set chargeDropdown to true if it doesn't exist
          })) || [], // Fallback to an empty array if children are undefined
      }));

      combineBothData.forEach((data) => {
        if (data?.children[data?.children?.length - 1]?.name) {
          customInputData = addObjectToChildren(
            customInputData,
            data?.uniqueId,
            {
              uniqueId: uuidv4(),
              parentId: 0,
              name: '',
              isCollapsible: false,
              children: [],
              type: 'custom',
              borrowerPaidAtClosingValue: 0,
              borrowerPaidBeforeClosingValue: 0,
              otherPaidAtClosingValue: 0,
              otherPaidBeforeClosingValue: 0,
              creditType: 1,
              chargeDropdown: true,
            }
          );
        }
      });
      const inputValues = initializeInputValues(customInputData);
      setTableCellValues(inputValues);
      setCombinedData(customInputData);
    } else if (errorMessage?.status === 404 && loanCostTypes?.data) {
      const combineBothData = mixBothData(loanCostTypes?.data ?? []);
      let customInputData = combineBothData.map((data) => ({
        ...data,
        children:
          data.children?.map((child) => ({
            ...child,
            chargeDropdown: child.chargeDropdown ?? true, // Set chargeDropdown to true if it doesn't exist
          })) || [], // Fallback to an empty array if children are undefined
      }));
      combineBothData.forEach((data) => {
        if (data?.children[data?.children?.length - 1]?.name) {
          customInputData = addObjectToChildren(
            customInputData,
            data?.uniqueId,
            {
              uniqueId: uuidv4(),
              parentId: 0,
              name: '',
              isCollapsible: false,
              children: [],
              type: 'custom',
              borrowerPaidAtClosingValue: 0,
              borrowerPaidBeforeClosingValue: 0,
              otherPaidAtClosingValue: 0,
              otherPaidBeforeClosingValue: 0,
              creditType: 1,
              chargeDropdown: true,
            }
          );
        }
      });

      const inputValues = initializeInputValues(customInputData);
      setTableCellValues(inputValues);
      setCombinedData(customInputData);
    }
  }, [loanCostTypes?.data, feesWorksheetData, errorMessage, triggerUseEffect]);

  // calculates cash from borrower
  useEffect(() => {
    const totalValue = calculateAndFormatSum(
      combinedData,
      tableCellValues,
      [],
      'borrowerPaidAtClosingValue',
      'borrowerPaidBeforeClosingValue',
      'otherPaidBeforeClosingValue',
      'otherPaidAtClosingValue'
    );
    setCashFromBorrower(totalValue);
  }, [tableCellValues, combinedData]);

  // converts strings with characters to number
  function convertToNumber(input) {
    const cleanedInput = input.replace(/[^0-9.-]/g, '');
    // Convert the cleaned input to a floating point number
    const number = parseFloat(cleanedInput);
    if (isNaN(number)) {
      return 0;
    }

    return parseInt(number.toFixed(3));
  }

  // submit fees worksheet data
  const saveFeesWorksheetData = async () => {
    if (isError) {
      return customToast.error(
        'Please fill loan information details in your profile'
      );
    }
    const feesData = updateCombinedArray(combinedData, tableCellValues);
    const hasError = validateCustomTypes(feesData);

    if (hasError) {
      customToast.error(
        'It looks like the fee name is missing. Please check again and try again.'
      );
      return;
    }

    const loadingId = customToast.loading('Updating Fees Worksheet...');
    try {
      let resp;
      if (errorMessage?.status === 404) {
        resp = await saveFeesData({
          data: {
            userId: applicationData?.data?.user_profile?.userId,
            userProfileId: applicationData?.data?.user_profile?.userProfileId,
            fees_json: feesData,
            cash_from_borrower: convertToNumber(cashFromBorrower),
          },
          applicationId,
        });
      } else {
        resp = await updateFeesData({
          data: {
            userId: applicationData?.data?.user_profile?.userId,
            userProfileId: applicationData?.data?.user_profile?.userProfileId,
            fees_json: feesData,
            cash_from_borrower: convertToNumber(cashFromBorrower),
          },
          applicationId,
        });
      }
      if (resp?.data?.status === 200) {
        customToast.success('Smart Fees updated successfully');
      } else {
        customToast.error(resp?.error?.data?.message);
      }
    } catch (error) {
    } finally {
      setEditable(false);
      toast.dismiss(loadingId);
    }
  };

  // update  table data
  const onChangeTableCell = (uniqueId, propertyName, value, type) => {
    setTableCellValues({
      ...tableCellValues,
      [uniqueId]: {
        ...tableCellValues[uniqueId],
        [propertyName]: type === 'text' ? value : parseFloat(value),
      },
    });
  };

  // extract unique id's for loan and other costs
  useEffect(() => {
    if (combinedData?.length > 0) {
      const loanCostIds = combinedData.slice(0, 3).map((obj) => obj.uniqueId);
      const otherCostIds = combinedData.slice(-6).map((obj) => obj.uniqueId);

      setStaticIds({
        loanCostIds: loanCostIds,
        otherCostIds: otherCostIds,
      });
    }
  }, [combinedData]);

  // calculate date difference
  const calculateDifferenceInDays = (date1, date2) => {
    if (!date1 || !date2) return 0; // Return 0 if either date is invalid
    const timeDiff = date2.getTime() - date1.getTime();

    // Check if timeDiff is NaN or less than zero, return 0 if true
    if (isNaN(timeDiff)) return 0;

    return Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
  };

  // calculate prepaid interest
  useEffect(() => {
    const closingDateObj = new Date(closingDate?.date);
    const firstPaymentDateObj = new Date(firstPaymentDate?.date);
    const days = calculateDifferenceInDays(closingDateObj, firstPaymentDateObj);

    // Calculate the daily interest rate
    const dailyInterestRate = interestRate / 100 / 365;
    // Calculate the prepaid interest for the number of days
    const prepaidInterest = loanAmount * dailyInterestRate * days;
    setNumberOfDays(days);
    setTableCellValues((prevValues) => ({
      ...prevValues,
      [closingDate?.uniqueId || firstPaymentDate.uniqueId]: {
        ...prevValues[closingDate?.uniqueId || firstPaymentDate.uniqueId],
        borrowerPaidAtClosingValue: Math.abs(prepaidInterest),
        borrowerPaidBeforeClosingValue: 0,
        otherPaidAtClosingValue: 0,
        otherPaidBeforeClosingValue: 0,
        creditType: prepaidInterest > 0 ? 1 : 2,
        numberOfDays: days,
        estimatedClosing: closingDate.date,
        firstPayment: firstPaymentDate.date,
      },
    }));
  }, [closingDate.date, firstPaymentDate.date]);

  // get values based on percentage
  const calculateLoanAmountPercent = (inputValue, uniqueId) => {
    const percentageValue = (inputValue / 100) * loanAmount;

    setTableCellValues((prevValues) => {
      return {
        ...prevValues,
        [uniqueId]: {
          ...prevValues[uniqueId],
          borrowerPaidAtClosingValue: percentageValue,
          borrowerPaidBeforeClosingValue: 0,
          otherPaidAtClosingValue: 0,
          otherPaidBeforeClosingValue: 0,
          percentageValue: inputValue,
        },
      };
    });
  };
  const getTotalAmount = (costs) => {
    return costs?.reduce((total, cost) => total + cost.amount, 0);
  };
  useEffect(() => {
    if (
      errorMessage?.status === 404 &&
      mergedData.length > 0 &&
      loadstarAPIData?.data
    ) {
      const finalData = updateLoanCosts(combinedData, mergedData);
      saveFeesData({
        data: {
          userId: applicationData?.data?.user_profile?.userId,
          userProfileId: applicationData?.data?.user_profile?.userProfileId,
          fees_json: finalData,
          cash_from_borrower: getTotalAmount(mergedData),
        },
        applicationId,
      });
    }
  }, [mergedData, errorMessage, loadstarAPIData?.data]);

  // static rows
  const renderStaticTableRow = (title, ids, rowType) => {
    return (
      <motion.tr
        key={rowType}
        initial={{
          y: -10,
        }}
        animate={{
          x: 0,
          y: 0,
          scale: 1,
          rotate: 0,
        }}
        className="h-fit w-[100%] text-center border-2 text-nowrap border-[#F5F5F5] p-xs-semibold"
      >
        <td
          className={`w-[30%] p-3 text-start p-xs-bold ${rowType === 'totalLoanCost' ? 'flex' : ''}`}
        >
          {rowType === 'totalLoanCost' && (
            <img className="pr-3 " src={shrinkVector} alt="-" />
          )}
          {title}
        </td>

        <td colSpan={2} className="w-[15%] border-2 border-[#F5F5F5]">
          {calculateAndFormatSum(
            combinedData,
            tableCellValues,
            ids,
            'borrowerPaidAtClosingValue',
            'borrowerPaidBeforeClosingValue'
          )}
        </td>
        <td colSpan={2} className="w-[15%] border-2 border-[#F5F5F5]">
          {calculateAndFormatSum(
            combinedData,
            tableCellValues,
            ids,
            'otherPaidAtClosingValue',
            'otherPaidBeforeClosingValue'
          )}
        </td>
      </motion.tr>
    );
  };
  return (
    <>
      {isFeesworkSheetLoading ? (
        <Loader />
      ) : (
        <div className="h-[78vh] w-[100%] self-center flex flex-col p-3 bg-white">
          <div className="h-fit w-[100%] flex flex-row justify-end p-1 mb-3">
            <div className="h-[100%] flex flex-row justify-end gap-5">
              {!editable && (
                <Button
                  variant="white_sm"
                  onClick={handleEditDetails}
                  type="button"
                  btnClassName={`hover:border hover:border-primary500 hover:text-primary500 ${editable && 'border border-primary500 bg-primary100 text-primary500'}  rounded-md`}
                >
                  Edit Details
                </Button>
              )}
            </div>
          </div>
          <div className="h-fit w-[100%] flex flex-row justify-normal gap-[1px] bg-[#F5F5F5]">
            <div
              className={`flex flex-col py-3 ${expandedState ? 'w-[50%]' : 'w-[100%]'}  bg-chatbot_bg`}
            >
              <p className="p-xs-semibold p-3">Fees</p>
            </div>
            <div className="h-fit flex flex-col justify-end gap-[1px] w-[100%]">
              <div className="w-[100%] flex flex-row gap-[1px]">
                <div className="w-[100%] h-[100%] p-3 flex flex-row justify-center self-center bg-chatbot_bg">
                  <p className="p-xs-semibold self-center ">Borrower Paid</p>
                </div>
                <div className="w-[100%] h-[100%] p-3 flex flex-col justify-end bg-chatbot_bg">
                  <p className="p-xs-semibold self-center ">Other Paid</p>
                </div>
              </div>
              <div className="w-[100%] flex flex-row gap-[1px]">
                <div className="w-[100%] h-[100%] p-3 flex flex-col justify-end bg-chatbot_bg text-nowrap">
                  <p className="p-xs-semibold self-center ">At Closing</p>
                </div>
                <div className="w-[100%] h-[100%] p-3 flex flex-col justify-end bg-chatbot_bg text-nowrap">
                  <p className="p-xs-semibold self-center ">Before Closing</p>
                </div>
                <div className="w-[100%] h-[100%] p-3 flex flex-col justify-end bg-chatbot_bg text-nowrap">
                  <p className="p-xs-semibold self-center ">At Closing</p>
                </div>
                <div className="w-[100%] h-[100%] p-3 flex flex-col justify-end bg-chatbot_bg text-nowrap">
                  <p className="p-xs-semibold self-center ">Before Closing</p>
                </div>
              </div>
            </div>
          </div>
          <div className="min-[100%] h-[100%] max-h-[100%] w-[100%] overflow-y-scroll hiddenScroll">
            <table className="h-[100%] w-[100%]">
              {combinedData
                ?.filter((_, index) => index !== 3)
                .map((item, pIndex) => {
                  return (
                    <>
                      {' '}
                      {/* renders loan cost static  table for index 0 */}
                      {pIndex === 0 &&
                        renderStaticTableRow(
                          'Loan Costs',
                          staticIds.loanCostIds,
                          'loanCost'
                        )}
                      {/* renders other cost static  table for index 3 */}
                      {pIndex === 3 && (
                        <>
                          {renderStaticTableRow(
                            'D. Total Loan Costs (A+B+C)',
                            staticIds.loanCostIds,
                            'totalLoanCost'
                          )}
                          {renderStaticTableRow(
                            'Other Costs',
                            staticIds.otherCostIds,
                            'otherCost'
                          )}
                        </>
                      )}
                      <motion.tr
                        key={item?.uniqueId}
                        /* variants={sliderVariants}  */
                        initial={{
                          y: -10,
                        }}
                        animate={{
                          x: 0,
                          y: 0,
                          scale: 1,
                          rotate: 0,
                        }}
                        className="h-fit w-[100%] text-center border-2 border-[#F5F5F5] p-xs-semibold"
                      >
                        <td
                          className="p-3 text-start cursor-pointer flex flex-row gap-3 "
                          onClick={() => {
                            if (expanded.pIndex.includes(pIndex)) {
                              let tempArray = expanded.pIndex;
                              const index = tempArray.indexOf(pIndex);
                              if (index > -1) {
                                tempArray.splice(index, 1);
                                setExpanded((prev) => ({
                                  ...prev,
                                  pIndex: tempArray,
                                }));
                              }
                            } else {
                              let tempArray = expanded.pIndex;
                              tempArray.push(pIndex);
                              setExpanded((prev) => ({
                                ...prev,
                                pIndex: tempArray,
                              }));
                            }
                          }}
                        >
                          {expanded.pIndex.includes(pIndex) ? (
                            <img className="" src={shrinkVector} alt="+" />
                          ) : (
                            <img className="" src={expandVector} alt="-" />
                          )}
                          <p className="p-xs-bold">
                            {item.name}{' '}
                            {/* {item?.customText && (
                          <span className="text-primary500 p-xs-bold">
                            {item?.customText}
                          </span>
                        )} */}
                          </p>
                        </td>

                        <td
                          colSpan={2}
                          className="w-[35%] border-2 border-[#F5F5F5]"
                        >
                          {calculateAndFormatSum(
                            combinedData,
                            tableCellValues,
                            [item?.uniqueId],
                            'borrowerPaidAtClosingValue',
                            'borrowerPaidBeforeClosingValue'
                          )}
                        </td>
                        <td
                          colSpan={2}
                          className="w-[35%] border-2 border-[#F5F5F5]"
                        >
                          {calculateAndFormatSum(
                            combinedData,
                            tableCellValues,
                            [item?.uniqueId],
                            'otherPaidAtClosingValue',
                            'otherPaidBeforeClosingValue'
                          )}
                        </td>
                      </motion.tr>
                      {item?.children &&
                        expanded.pIndex.includes(pIndex) &&
                        item?.children.map((child, cIndex, childArray) => {
                          if (child?.type === 'custom' && editable) {
                            return (
                              <motion.tr
                                key={child?.uniqueId}
                                initial={{
                                  y: -10,
                                }}
                                animate={{
                                  x: 0,
                                  y: 0,
                                  scale: 1,
                                  rotate: 0,
                                }}
                                className={`h-fit w-[100%] text-center border-2 border-[#F5F5F5] p-xs-semibold align-middle`}
                              >
                                <td className="h-[100%] flex flex-col px-1 justify-center py-2">
                                  <div
                                    className={`flex gap-3 items-center flex-wrap font-xs-regular text-start w-[95%] self-center`}
                                  >
                                    <InputGroup
                                      size="sm"
                                      placeholder="Provide Fee Type"
                                      value={
                                        tableCellValues[child?.uniqueId]
                                          ?.name || ''
                                      }
                                      name="name"
                                      onChange={(e) => {
                                        onChangeTableCell(
                                          child?.uniqueId,
                                          e.target.name,
                                          e.target.value,
                                          'text'
                                        );
                                      }}
                                    />
                                    {cIndex !== childArray.length - 1 && (
                                      <Button
                                        variant="link"
                                        style={{ padding: 0 }}
                                        btnClassName="w-[10%] p-0"
                                        onClick={() => {
                                          setCombinedData([
                                            ...removeObjectFromChildren(
                                              combinedData,
                                              item?.uniqueId,
                                              child?.uniqueId
                                            ),
                                          ]);
                                        }}
                                      >
                                        <svg
                                          width="12"
                                          height="14"
                                          viewBox="0 0 12 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M2.5 14C2.0875 14 1.73438 13.8531 1.44062 13.5594C1.14687 13.2656 1 12.9125 1 12.5V2.5H0V1H4V0H8V1H12V2.5H11V12.491C11 12.9137 10.8531 13.2708 10.5594 13.5625C10.2656 13.8542 9.9125 14 9.5 14H2.5ZM9.5 2.5H2.5V12.5H9.5V2.5ZM4 11H5.5V4H4V11ZM6.5 11H8V4H6.5V11Z"
                                            fill="#1C1B1F"
                                          />
                                        </svg>
                                      </Button>
                                    )}
                                    {cIndex === childArray.length - 1 && (
                                      <Button
                                        variant="link"
                                        style={{ padding: 0 }}
                                        btnClassName="w-[10%] p-0"
                                        onClick={() => {
                                          setCombinedData([
                                            ...addObjectToChildren(
                                              combinedData,
                                              item?.uniqueId,
                                              {
                                                uniqueId: uuidv4(),
                                                parentId: 0,
                                                name: '',
                                                isCollapsible: false,
                                                children: [],
                                                type: 'custom',
                                                chargeDropdown: true,
                                                borrowerPaidAtClosingValue: '-',
                                                borrowerPaidBeforeClosingValue:
                                                  '-',
                                                otherPaidAtClosingValue: '-',
                                                otherPaidBeforeClosingValue:
                                                  '-',
                                              }
                                            ),
                                          ]);
                                        }}
                                      >
                                        <svg
                                          width="15"
                                          height="15"
                                          viewBox="0 0 100 100"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <line
                                            x1="10"
                                            y1="50"
                                            x2="90"
                                            y2="50"
                                            stroke="black"
                                            stroke-width="10"
                                          />
                                          <line
                                            x1="50"
                                            y1="10"
                                            x2="50"
                                            y2="90"
                                            stroke="black"
                                            stroke-width="10"
                                          />
                                        </svg>
                                      </Button>
                                    )}
                                    {expandedState && child.chargeDropdown && (
                                      <td
                                        colSpan={1}
                                        className={`min-w-[100px] border-2 border-[#F5F5F5]  ${editable ? 'bg-chatbot_bg' : 'bg-frameDisabled'}`}
                                      >
                                        <select
                                          className={`appearance-null cursor-pointer bg-gray-50 border-none p-xs-semibold rounded-lg focus-visible:outline-none
                   block w-full disabled:bg-frameDisabled p-2.5 leading-none truncate`}
                                          name="creditType"
                                          disabled={!editable}
                                          value={
                                            tableCellValues[child?.uniqueId]
                                              ?.creditType || ''
                                          }
                                          onChange={(e) => {
                                            onChangeTableCell(
                                              child?.uniqueId,
                                              e.target.name,
                                              e.target.value,
                                              'text'
                                            );
                                          }}
                                        >
                                          {' '}
                                          {dropdownVal?.map((application) => (
                                            <option
                                              value={application?.id}
                                              key={application?.id}
                                            >
                                              {application?.value}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    )}
                                  </div>
                                </td>

                                <td className=" border-2 border-[#F5F5F5]">
                                  <p
                                    className={`p-xs-regular text-start w-[100%] px-4`}
                                  >
                                    <InputGroup
                                      size="sm"
                                      type="number"
                                      value={
                                        tableCellValues[child?.uniqueId]
                                          ?.borrowerPaidAtClosingValue || ''
                                      }
                                      name="borrowerPaidAtClosingValue"
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === '-' ||
                                          e.key === '+' ||
                                          e.key === 'e' ||
                                          e.key === 'E'
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        onChangeTableCell(
                                          child?.uniqueId,
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </p>
                                </td>
                                <td
                                  colSpan={1}
                                  className="w-[15%] border-2 border-[#F5F5F5]"
                                >
                                  <p
                                    className={`p-xs-regular text-start w-[100%] px-4`}
                                  >
                                    <InputGroup
                                      size="sm"
                                      value={
                                        tableCellValues[child?.uniqueId]
                                          ?.borrowerPaidBeforeClosingValue || ''
                                      }
                                      name="borrowerPaidBeforeClosingValue"
                                      type="number"
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === '-' ||
                                          e.key === '+' ||
                                          e.key === 'e' ||
                                          e.key === 'E'
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        onChangeTableCell(
                                          child?.uniqueId,
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </p>
                                </td>
                                <td
                                  colSpan={1}
                                  className="w-[15%] border-2 border-[#F5F5F5]"
                                >
                                  <p
                                    className={`p-xs-regular text-start w-[100%] px-4`}
                                  >
                                    <InputGroup
                                      size="sm"
                                      value={
                                        tableCellValues[child?.uniqueId]
                                          ?.otherPaidAtClosingValue || ''
                                      }
                                      name="otherPaidAtClosingValue"
                                      type="number"
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === '-' ||
                                          e.key === '+' ||
                                          e.key === 'e' ||
                                          e.key === 'E'
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        onChangeTableCell(
                                          child?.uniqueId,
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </p>
                                </td>
                                <td
                                  colSpan={1}
                                  className="w-[15%] border-2 border-[#F5F5F5]"
                                >
                                  <p
                                    className={`p-xs-regular text-start w-[100%] px-4`}
                                  >
                                    <InputGroup
                                      size="sm"
                                      value={
                                        tableCellValues[child?.uniqueId]
                                          ?.otherPaidBeforeClosingValue || ''
                                      }
                                      name="otherPaidBeforeClosingValue"
                                      type="number"
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === '-' ||
                                          e.key === '+' ||
                                          e.key === 'e' ||
                                          e.key === 'E'
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        onChangeTableCell(
                                          child?.uniqueId,
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </p>
                                </td>
                              </motion.tr>
                            );
                          } else if (!child?.name) {
                            return null;
                          }
                          return (
                            <>
                              <motion.tr
                                key={child?.uniqueId}
                                initial={{
                                  y: -10,
                                }}
                                animate={{
                                  x: 0,
                                  y: 0,
                                  scale: 1,
                                  rotate: 0,
                                }}
                                className={`h-fit w-[100%] text-center border-2 border-[#F5F5F5] p-xs-semibold align-middle ${child?.children?.length > 0 && 'cursor-pointer'}`}
                              >
                                <td
                                  className="flex flex-row p-3"
                                  onClick={() => {
                                    if (
                                      expanded.cIndex.includes(cIndex + pIndex)
                                    ) {
                                      let tempArray = expanded.cIndex;
                                      const index = tempArray.indexOf(
                                        cIndex + pIndex
                                      );
                                      if (index > -1) {
                                        tempArray.splice(index, 1);
                                        setExpanded((prev) => ({
                                          ...prev,
                                          cIndex: tempArray,
                                        }));
                                      }
                                    } else {
                                      let tempArray = expanded.cIndex;
                                      tempArray.push(cIndex + pIndex);
                                      setExpanded((prev) => ({
                                        ...prev,
                                        cIndex: tempArray,
                                      }));
                                    }
                                  }}
                                >
                                  {child?.children?.length > 0 && (
                                    <img
                                      className={`mx-4 ${expanded.cIndex.includes(cIndex + pIndex) && 'rotate-180'}`}
                                      src={angleDown}
                                      alt="^"
                                    />
                                  )}
                                  {child?.isAPR && (
                                    <p className={`text-start p-xs-bold`}>
                                      {'APR '}&nbsp;
                                    </p>
                                  )}

                                  <p
                                    className={`p-xs-bold text-neutral1000 justify-center items-center text-start ${child?.children?.length > 0 ? 'p-xs-bold' : 'font-normal'} `}
                                  >
                                    {child.name}
                                    {child?.calendar && (
                                      <>
                                        {' '}
                                        <div className="flex gap-4 mt-3">
                                          <div>
                                            {' '}
                                            <label
                                              className={`block text-custom-greengray p-xs-bold`}
                                            >
                                              Estimated Closing Date
                                            </label>
                                            <DatePicker
                                              selected={
                                                closingDate?.date ||
                                                tableCellValues[child?.uniqueId]
                                                  .estimatedClosing
                                              }
                                              wrapperClassName="w-full"
                                              onChange={(e) =>
                                                setClosingDate({
                                                  date:
                                                    e ||
                                                    tableCellValues[
                                                      child?.uniqueId
                                                    ].estimatedClosing,
                                                  uniqueId: child.uniqueId,
                                                })
                                              }
                                              dropdownMode={'select'}
                                              showMonthDropdown={true}
                                              showYearDropdown={true}
                                              placeholderText={'select date'}
                                              className={`cursor-pointer border-2 w-[6rem] p-xs-regular max-h-[39px] focus:border-2 focus:outline-none rounded-lg text-base px-3 py-1.5 text-black border-primary focus:border-secondary`}
                                              scrollableYearDropdown
                                              readOnly={!editable}
                                              onKeyDown={(e) => {
                                                e.preventDefault();
                                              }}
                                            />
                                          </div>
                                          <div>
                                            {' '}
                                            <label
                                              className={`block text-custom-greengray p-xs-bold`}
                                            >
                                              First Payment
                                            </label>
                                            <DatePicker
                                              selected={
                                                firstPaymentDate?.date ||
                                                tableCellValues[child?.uniqueId]
                                                  .firstPayment
                                              }
                                              wrapperClassName="w-full"
                                              onChange={(e) =>
                                                setFirstPaymentDate({
                                                  date:
                                                    e ||
                                                    tableCellValues[
                                                      child?.uniqueId
                                                    ].firstPayment,
                                                  uniqueId: child.uniqueId,
                                                })
                                              }
                                              dropdownMode={'select'}
                                              showMonthDropdown={true}
                                              showYearDropdown={true}
                                              placeholderText={'select date'}
                                              className={`cursor-pointer border-2 w-[6rem] p-xs-regular max-h-[39px] focus:border-2 focus:outline-none rounded-lg text-base px-3 py-1.5 text-black border-primary focus:border-secondary`}
                                              scrollableYearDropdown
                                              readOnly={!editable}
                                              onKeyDown={(e) => {
                                                e.preventDefault();
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="flex mt-3">
                                          <div>
                                            {' '}
                                            <label
                                              className={`block text-custom-greengray p-xs-bold`}
                                            >
                                              Number of days
                                            </label>
                                            <p
                                              className={`block text-custom-greengray p-xs-bold`}
                                            >
                                              {numberOfDays
                                                ? numberOfDays
                                                : tableCellValues[
                                                    child?.uniqueId
                                                  ].numberOfDays}
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </p>
                                  {child?.percentage && (
                                    <div className="ml-3 flex items-center gap-2 font-bold">
                                      <InputGroup
                                        size="xsm"
                                        type="number"
                                        position="left"
                                        value={
                                          tableCellValues[child?.uniqueId]
                                            ?.percentageValue || ''
                                        }
                                        disabled={!editable}
                                        readOnly={!editable}
                                        placeholder="Enter Percent"
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          calculateLoanAmountPercent(
                                            e.target.value,
                                            child.uniqueId
                                          );
                                        }}
                                      />
                                      %
                                    </div>
                                  )}
                                  <p>
                                    {expandedState &&
                                      child?.children?.length === 0 &&
                                      child.chargeDropdown && (
                                        <td
                                          colSpan={1}
                                          className={`min-w-[100px] border-2 border-[#F5F5F5]  ${editable ? 'bg-chatbot_bg' : 'bg-frameDisabled'}`}
                                        >
                                          <select
                                            className={`appearance-null cursor-pointer bg-gray-50 border-none rounded-lg focus-visible:outline-none
                   block w-full disabled:bg-frameDisabled p-xs-regular p-2.5 leading-none truncate`}
                                            value={
                                              tableCellValues[child?.uniqueId]
                                                ?.creditType || ''
                                            }
                                            disabled={!editable}
                                            name="creditType"
                                            onChange={(e) => {
                                              onChangeTableCell(
                                                child?.uniqueId,
                                                e.target.name,
                                                e.target.value,
                                                'text'
                                              );
                                            }}
                                          >
                                            {' '}
                                            {dropdownVal?.map((application) => (
                                              <option
                                                value={application?.id}
                                                key={application?.id}
                                              >
                                                {application?.value}
                                              </option>
                                            ))}
                                          </select>
                                        </td>
                                      )}
                                  </p>
                                </td>

                                <td
                                  colSpan={1}
                                  className="text-neutral1000 w-[15%] border-2 border-[#F5F5F5]"
                                  onClick={() => {
                                    setSelectedCell(child?.uniqueId);
                                    setSelectedColumn(
                                      'borrowerPaidAtClosingValue'
                                    );
                                  }}
                                >
                                  {editable &&
                                  child?.uniqueId === selectedCell &&
                                  !child.percentage &&
                                  !child.calendar &&
                                  selectedColumn ===
                                    'borrowerPaidAtClosingValue' &&
                                  !child?.children?.length > 0 ? (
                                    <p
                                      className={`p-xs-regular text-start w-[100%] px-4`}
                                    >
                                      <InputGroup
                                        size="sm"
                                        type="number"
                                        value={
                                          tableCellValues[child?.uniqueId]
                                            ?.borrowerPaidAtClosingValue || ''
                                        }
                                        name="borrowerPaidAtClosingValue"
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          onChangeTableCell(
                                            child?.uniqueId,
                                            e.target.name,
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </p>
                                  ) : (
                                    getAppropriateValue(
                                      child,
                                      'borrowerPaidAtClosingValue',
                                      combinedData,
                                      tableCellValues
                                    )
                                  )}
                                </td>

                                <td
                                  colSpan={1}
                                  className="text-neutral1000 w-[15%] border-2 border-[#F5F5F5]"
                                  onClick={() => {
                                    setSelectedCell(child?.uniqueId);
                                    setSelectedColumn(
                                      'borrowerPaidBeforeClosingValue'
                                    );
                                  }}
                                >
                                  {editable &&
                                  child?.uniqueId === selectedCell &&
                                  !child.percentage &&
                                  !child.calendar &&
                                  selectedColumn ===
                                    'borrowerPaidBeforeClosingValue' &&
                                  !child?.children?.length > 0 ? (
                                    <p
                                      className={`p-xs-regular text-start w-[100%] px-4`}
                                    >
                                      <InputGroup
                                        size="sm"
                                        value={
                                          tableCellValues[child?.uniqueId]
                                            ?.borrowerPaidBeforeClosingValue ||
                                          ''
                                        }
                                        name="borrowerPaidBeforeClosingValue"
                                        type="number"
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          onChangeTableCell(
                                            child?.uniqueId,
                                            e.target.name,
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </p>
                                  ) : (
                                    getAppropriateValue(
                                      child,
                                      'borrowerPaidBeforeClosingValue',
                                      combinedData,
                                      tableCellValues
                                    )
                                  )}
                                </td>

                                <td
                                  colSpan={1}
                                  className="text-neutral1000 w-[15%] border-2 border-[#F5F5F5]"
                                  onClick={() => {
                                    setSelectedCell(child?.uniqueId);
                                    setSelectedColumn(
                                      'otherPaidAtClosingValue'
                                    );
                                  }}
                                >
                                  {editable &&
                                  child?.uniqueId === selectedCell &&
                                  !child.percentage &&
                                  !child.calendar &&
                                  selectedColumn ===
                                    'otherPaidAtClosingValue' &&
                                  !child?.children?.length > 0 ? (
                                    <p
                                      className={`p-xs-regular text-start w-[100%] px-4`}
                                    >
                                      <InputGroup
                                        size="sm"
                                        value={
                                          tableCellValues[child?.uniqueId]
                                            ?.otherPaidAtClosingValue || ''
                                        }
                                        name="otherPaidAtClosingValue"
                                        type="number"
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          onChangeTableCell(
                                            child?.uniqueId,
                                            e.target.name,
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </p>
                                  ) : (
                                    getAppropriateValue(
                                      child,
                                      'otherPaidAtClosingValue',
                                      combinedData,
                                      tableCellValues
                                    )
                                  )}
                                </td>

                                <td
                                  colSpan={1}
                                  className="text-neutral1000 w-[15%] border-2 border-[#F5F5F5]"
                                  onClick={() => {
                                    setSelectedCell(child?.uniqueId);
                                    setSelectedColumn(
                                      'otherPaidBeforeClosingValue'
                                    );
                                  }}
                                >
                                  {editable &&
                                  child?.uniqueId === selectedCell &&
                                  !child.percentage &&
                                  !child.calendar &&
                                  selectedColumn ===
                                    'otherPaidBeforeClosingValue' &&
                                  !child?.children?.length > 0 ? (
                                    <p
                                      className={`p-xs-regular text-start w-[100%] px-4`}
                                    >
                                      <InputGroup
                                        size="sm"
                                        value={
                                          tableCellValues[child?.uniqueId]
                                            ?.otherPaidBeforeClosingValue || ''
                                        }
                                        name="otherPaidBeforeClosingValue"
                                        type="number"
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          onChangeTableCell(
                                            child?.uniqueId,
                                            e.target.name,
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </p>
                                  ) : (
                                    getAppropriateValue(
                                      child,
                                      'otherPaidBeforeClosingValue',
                                      combinedData,
                                      tableCellValues
                                    )
                                  )}
                                </td>
                              </motion.tr>
                              {child?.children &&
                                expanded.cIndex.includes(cIndex + pIndex) &&
                                child.children.map((Mchild, MCindex) => {
                                  return (
                                    <>
                                      <motion.tr
                                        key={Mchild?.uniqueId}
                                        initial={{
                                          y: -10,
                                        }}
                                        animate={{
                                          x: 0,
                                          y: 0,
                                          scale: 1,
                                          rotate: 0,
                                        }}
                                        className="h-fit w-[100%] text-center border-2 border-[#F5F5F5] p-xs-semibold"
                                      >
                                        <td className="p-3 text-start flex flex-row ">
                                          {Mchild.isAPR && (
                                            <p
                                              className={`ml-10 w-fit p-xs-bold px-2`}
                                            >
                                              {'APR '}
                                            </p>
                                          )}
                                          <p
                                            className={`text-neutral1000 w-full flex items-center justify-between text-start ${Mchild?.children?.length > 0 ? 'p-xs-bold' : 'p-xs-regular'}   `}
                                          >
                                            {Mchild.name}
                                            {child?.customText && (
                                              <span className="text-primary500 p-xs-bold">
                                                {Mchild?.customText}
                                              </span>
                                            )}
                                            {expandedState &&
                                              Mchild.chargeDropdown && (
                                                <td
                                                  colSpan={1}
                                                  className={`min-w-[100px] border-2 border-[#F5F5F5]  ${editable ? 'bg-chatbot_bg' : 'bg-frameDisabled'}`}
                                                >
                                                  <select
                                                    className={`appearance-null cursor-pointer bg-gray-50 border-none p-xs-semibold rounded-lg focus-visible:outline-none
                   block w-full disabled:bg-frameDisabled p-xs-regular p-2.5 leading-none truncate`}
                                                    value={
                                                      tableCellValues[
                                                        Mchild?.uniqueId
                                                      ]?.creditType || ''
                                                    }
                                                    disabled={!editable}
                                                    name="creditType"
                                                    onChange={(e) => {
                                                      onChangeTableCell(
                                                        Mchild?.uniqueId,
                                                        e.target.name,
                                                        e.target.value,
                                                        'text'
                                                      );
                                                    }}
                                                  >
                                                    {' '}
                                                    {dropdownVal?.map(
                                                      (application) => (
                                                        <option
                                                          value={
                                                            application?.id
                                                          }
                                                          key={application?.id}
                                                        >
                                                          {application?.value}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                </td>
                                              )}
                                          </p>
                                        </td>

                                        <td
                                          colSpan={1}
                                          className="text-neutral1000 w-[15%] border-2 border-[#F5F5F5]"
                                          onClick={() => {
                                            setSelectedCell(Mchild?.uniqueId);
                                            setSelectedColumn(
                                              'borrowerPaidAtClosingValue'
                                            );
                                          }}
                                        >
                                          {editable &&
                                          Mchild?.uniqueId === selectedCell &&
                                          selectedColumn ===
                                            'borrowerPaidAtClosingValue' ? (
                                            <p
                                              className={`p-xs-regular text-start w-[100%] px-4`}
                                            >
                                              <InputGroup
                                                size="sm"
                                                value={
                                                  tableCellValues[
                                                    Mchild?.uniqueId
                                                  ]
                                                    ?.borrowerPaidAtClosingValue ||
                                                  ''
                                                }
                                                name="borrowerPaidAtClosingValue"
                                                type="number"
                                                onKeyDown={(e) => {
                                                  if (
                                                    e.key === '-' ||
                                                    e.key === '+' ||
                                                    e.key === 'e' ||
                                                    e.key === 'E'
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  onChangeTableCell(
                                                    Mchild?.uniqueId,
                                                    e.target.name,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </p>
                                          ) : (
                                            getAppropriateValue(
                                              Mchild,
                                              'borrowerPaidAtClosingValue',
                                              combinedData,
                                              tableCellValues
                                            )
                                          )}
                                        </td>
                                        <td
                                          colSpan={1}
                                          className="text-neutral1000 w-[15%] border-2 border-[#F5F5F5]"
                                          onClick={() => {
                                            setSelectedCell(Mchild?.uniqueId);
                                            setSelectedColumn(
                                              'borrowerPaidBeforeClosingValue'
                                            );
                                          }}
                                        >
                                          {editable &&
                                          Mchild?.uniqueId === selectedCell &&
                                          selectedColumn ===
                                            'borrowerPaidBeforeClosingValue' ? (
                                            <p
                                              className={`p-xs-regular text-start w-[100%] px-4`}
                                            >
                                              <InputGroup
                                                size="sm"
                                                value={
                                                  tableCellValues[
                                                    Mchild?.uniqueId
                                                  ]
                                                    ?.borrowerPaidBeforeClosingValue ||
                                                  ''
                                                }
                                                name="borrowerPaidBeforeClosingValue"
                                                type="number"
                                                onKeyDown={(e) => {
                                                  if (
                                                    e.key === '-' ||
                                                    e.key === '+' ||
                                                    e.key === 'e' ||
                                                    e.key === 'E'
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  onChangeTableCell(
                                                    Mchild?.uniqueId,
                                                    e.target.name,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </p>
                                          ) : (
                                            getAppropriateValue(
                                              Mchild,
                                              'borrowerPaidBeforeClosingValue',
                                              combinedData,
                                              tableCellValues
                                            )
                                          )}
                                        </td>
                                        <td
                                          colSpan={1}
                                          className="text-neutral1000 w-[15%] border-2 border-[#F5F5F5]"
                                          onClick={() => {
                                            setSelectedCell(Mchild?.uniqueId);
                                            setSelectedColumn(
                                              'otherPaidAtClosingValue'
                                            );
                                          }}
                                        >
                                          {editable &&
                                          Mchild?.uniqueId === selectedCell &&
                                          selectedColumn ===
                                            'otherPaidAtClosingValue' ? (
                                            <p
                                              className={`p-xs-regular text-start w-[100%] px-4`}
                                            >
                                              <InputGroup
                                                size="sm"
                                                value={
                                                  tableCellValues[
                                                    Mchild?.uniqueId
                                                  ]?.otherPaidAtClosingValue ||
                                                  ''
                                                }
                                                name="otherPaidAtClosingValue"
                                                type="number"
                                                onKeyDown={(e) => {
                                                  if (
                                                    e.key === '-' ||
                                                    e.key === '+' ||
                                                    e.key === 'e' ||
                                                    e.key === 'E'
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  onChangeTableCell(
                                                    Mchild?.uniqueId,
                                                    e.target.name,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </p>
                                          ) : (
                                            getAppropriateValue(
                                              Mchild,
                                              'otherPaidAtClosingValue',
                                              combinedData,
                                              tableCellValues
                                            )
                                          )}
                                        </td>
                                        <td
                                          colSpan={1}
                                          className="text-neutral1000 w-[15%] border-2 border-[#F5F5F5]"
                                          onClick={() => {
                                            setSelectedCell(Mchild?.uniqueId);
                                            setSelectedColumn(
                                              'otherPaidBeforeClosingValue'
                                            );
                                          }}
                                        >
                                          {editable &&
                                          Mchild?.uniqueId === selectedCell &&
                                          selectedColumn ===
                                            'otherPaidBeforeClosingValue' ? (
                                            <p
                                              className={`p-xs-regular text-start w-[100%] px-4`}
                                            >
                                              <InputGroup
                                                size="sm"
                                                value={
                                                  tableCellValues[
                                                    Mchild?.uniqueId
                                                  ]
                                                    ?.otherPaidBeforeClosingValue ||
                                                  ''
                                                }
                                                name="otherPaidBeforeClosingValue"
                                                type="number"
                                                onKeyDown={(e) => {
                                                  if (
                                                    e.key === '-' ||
                                                    e.key === '+' ||
                                                    e.key === 'e' ||
                                                    e.key === 'E'
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  onChangeTableCell(
                                                    Mchild?.uniqueId,
                                                    e.target.name,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </p>
                                          ) : (
                                            getAppropriateValue(
                                              Mchild,
                                              'otherPaidBeforeClosingValue',
                                              combinedData,
                                              tableCellValues
                                            )
                                          )}
                                        </td>
                                      </motion.tr>
                                    </>
                                  );
                                })}
                            </>
                          );
                        })}
                    </>
                  );
                })}
            </table>
          </div>
          <div className="h-fit mt-2 w-[100%] flex justify-end items-center gap-8">
            <div className="h-[100%] flex flex-col justify-center">
              <div className="w-[100%] flex flex-row justify-end">
                <p className="p-sm-bold">Cash from Borrower:</p>
                &nbsp;
                <p className="p-sm-bold text-primary500">{cashFromBorrower}</p>
              </div>
            </div>
            {editable && (
              <motion.div
                className="text-right"
                initial={{
                  x: 50,
                }}
                animate={{
                  x: 0,
                  y: 0,
                  scale: 1,
                  rotate: 0,
                }}
              >
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setEditable((prev) => !prev);
                    setTriggerUseEffect((prev) => !prev);
                    resetCalendar();
                  }}
                  type="button"
                  style={{ background: 'transparent' }}
                  btnClassName="border border-disabled text-disabled rounded-md"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={saveFeesWorksheetData}
                  type="button"
                  btnClassName="rounded-md ml-2"
                >
                  {isSaveFeesDataLoading || isUpdateFeesDataLoading ? (
                    <div className="h-[100%] p-[2.5px] w-[100%] min-w-[100px] flex flex-col justify-center">
                      <Loader loaderText="Editing" FullScreen={false} />
                    </div>
                  ) : (
                    'Save'
                  )}
                </Button>
              </motion.div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FeesWorksheet;
