import React from 'react';
/**
 * Radio component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isError - Flag indicating if there is an error.
 * @param {string} [props.color='primary-accent'] - The color of the radio.
 * @param {string} [props.errorColor='border-error-red'] - The color of the radio when there is an error.
 * @returns {React.Element} The rendered radio input.
 */
const Radio = ({
  isError,
  color = 'primary-accent',
  errorColor = 'border-error-red',
  checked = false,
  ...props
}) => {
  return (
    <input
      type="radio"
      //defaultChecked={checked}
      checked={checked}
      {...props}
      className={`cursor-pointer ${color} ${
        isError
          ? `appearance-none ${errorColor} border w-3 h-3 rounded-full`
          : ''
      }`}
    />
  );
};

export default Radio;
