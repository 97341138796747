import react, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import DoughnutGraph from 'components/doughnut';
import H1 from 'components/h1-typography';
import DynamicForm from 'pages/profile/DynamicForm';
import compareOffer from './loanCompareOffer';
import {
  createFormObject,
  checkValidations,
  formatData,
} from 'pages/profile/utils';
import Button from 'components/button';
import Modal from 'components/modal';
//import UploadCloud from "../assets/icons/file.svg"
import { FiUploadCloud } from 'react-icons/fi';
import ComparedLoanInfo from './ComparedLoanInformation';
import { customToast } from 'components/toast';
import { useGetLoanProgramQuery } from 'redux_store/services/admin/pipeline';
import {
  useCompareLoanOfficerMutation,
  useCompareOfferDocumentUploadMutation,
  useCompareUserInputConsumerMutation,
  useConsumerOfferDocumentMutation,
  useDownloadQuoteApplicationMutation,
  useGetCurrentOfferConsumerQuery,
  useGetCurrentOfferQuery,
} from 'redux_store/services/admin/adminTools';
import Loader from 'wrapper/loader';
import DragAndDrop from 'components/drag-and-drop';
import fileDownload from 'js-file-download';
import {
  downloadOffer,
  downloadConsumerOffer,
} from 'redux_store/services/documentDownloads';
import useDownloadPDFHandler from 'hooks/pdfViewer';
import ToolsErrorDisplay from '../toolsErrorDisplay';
import Progressbar from 'components/progressbar';

import closeIcon from 'assets/icons/closeIcon.svg';
import fileIcon from 'assets/icons/file.svg';
import deleteIcon from 'assets/icons/delete.svg';
import downloadIcon from 'assets/icons/download.svg';
import React from 'react';
import { roles } from 'constants/constant';

const LoanComparison = ({ applicationId, role }) => {
  const [uploadSuccess, setUploadSuccess] = useState(true);
  const offerDetails = [
    { title: '', value: 0, color: '#A4D9D9', key: 'P&I' },
    { title: '', value: 0, color: '#3F66FB', key: 'Taxes' },
    { title: '', value: 0, color: '#AFBEFF', key: 'PMI' },
    { title: '', value: 0, color: '#FFDAB9', key: 'Hazard Ins' },
  ];

  const offerData = [
    {
      name: 'Estimate Value',
      value: '$650,000',
      key: 'Estimated Value',
    },
    {
      name: 'Loan Balance/LTV',
      value: '$411,000/63%',
      key: '',
      func: (productOptions) => {
        const loanBalanceData = productOptions?.find(
          (x) => x?.key === 'Loan Balance'
        );
        const LTVData = productOptions?.find((x) => x?.key === 'LTV');
        return `${loanBalanceData?.value < 0 ? '-' : ''}${loanBalanceData?.unit}${Math.abs(loanBalanceData?.value)?.toLocaleString('en-US')}/${LTVData?.value?.toLocaleString('en-US')}${LTVData?.unit}`;
      },
    },
    {
      name: 'Rate/ APR',
      value: '5.750%/5.775%',
      key: '',
      func: (productOptions) => {
        const rateData = productOptions?.find((x) => x?.key === 'Rate');
        const aprData = productOptions?.find((x) => x?.key === 'APR');
        return `${rateData?.value}${rateData?.unit}/${aprData?.value}${aprData?.unit}`;
      },
    },
    {
      name: 'Monthly Payment',
      value: '$2399',
      key: 'Monthly Payment',
    },
    {
      name: 'Points or Credit',
      value: '-0.328%/-$0',
      key: '',
      func: (productOptions) => {
        const pointsData = productOptions?.find((x) => x?.key === 'Points');
        const creditData = productOptions?.find((x) => x?.key === 'Credit');
        return `${pointsData?.value}${pointsData?.unit}/${creditData?.value < 0 ? '-' : ''}${creditData?.unit}${Math.abs(creditData?.value)}`;
        return 'abc';
      },
    },
    {
      name: 'Term',
      value: '30 Years',
      key: 'Term',
    },
    {
      name: 'Lock Period',
      value: '30 Days',
      key: 'Lock Period',
    },
    {
      name: 'Prepayment Penalty',
      value: '$0',
      key: '',
      func: (productOptions) => {
        return '-';
      },
    },
    {
      name: 'Lender Fees',
      value: '$1495',
      key: 'Lender Fees',
    },
    {
      name: 'Other Closing Costs',
      value: '$2594',
      key: 'Other Closing Costs',
    },
    {
      name: 'Estimated Cash to Close',
      value: '$0',
      key: 'Estimated Cash to Close',
    },
  ];

  const [formData, setFormData] = useState({
    ...createFormObject(compareOffer),
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsFormSubmitted] = useState(false);
  const [compareVisibility, setCompareVisibility] = useState(false);
  const [uploadFileBoolean, setUploadFileBoolean] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [currentOfferValue, setCurrentOfferValue] = useState(offerData);
  const [compareData, setCompareData] = useState([]);
  const { data: loanProgram } = useGetLoanProgramQuery();
  const [buttonText, setButtonText] = useState('Compare');
  const [uploadButtonText, setUploadButtonText] = useState('Compare');
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    data: currentOffer,
    isLoading: isCurrentOfferLoading,
    isFetching: isCurrentOfferFetching,
    isSuccess: isCurrentOfferSuccess,
  } = role === roles?.loanOfficer
    ? useGetCurrentOfferQuery(
        { applicationId },
        {
          skip: !applicationId && role !== roles.consumer,
        }
      )
    : useGetCurrentOfferConsumerQuery(
        { applicationId },
        {
          skip: !applicationId && role === roles.consumer,
        }
      );

  const [compareLoanWithUserInput] = useCompareLoanOfficerMutation();
  const [compareConsumerWithUserInput] = useCompareUserInputConsumerMutation();
  const [uploadDocumentOffer] = useCompareOfferDocumentUploadMutation();
  const [uploadConsumerOffer] = useConsumerOfferDocumentMutation();
  const { downloadPDF } = useDownloadPDFHandler();
  const [isRateAvailable, setIsRateAvailable] = useState();
  const [isUploadError, setIsUploadError] = useState(false);
  const [files, setFiles] = useState(null);

  const productDetails =
    currentOffer?.data.quote_details?.product_details?.[0] ?? {};

  const getPieChartData = (children) => {
    const offerChildren = children?.filter((x) =>
      offerDetails.some((offer) => offer.key === x?.key)
    );
    const pieData = offerDetails?.map((offer) => {
      const productChildrenData = offerChildren?.find(
        (x) => x?.key === offer.key
      );
      if (productChildrenData) {
        return {
          ...offer,
          title: `${productChildrenData?.key}: ${productChildrenData?.unit} ${
            productChildrenData?.value === 0
              ? productChildrenData?.value
              : Number(productChildrenData?.value ?? 0).toLocaleString('en-US')
          }`,
          value: productChildrenData?.value || 0,
        };
      }
      return {
        ...offer,
        title: `${offer?.key}: 0`,
        value: 0,
      };
    });
    return pieData;
  };

  const getOfferMappingData = (children) => {
    const productOfferData = offerData?.map((x) => {
      const productOffer = children?.find((offer) => offer?.key === x?.key);
      if (productOffer) {
        return {
          ...x,
          value: `${productOffer?.unit === '$' ? `${productOffer?.unit} ` : ''} ${productOffer?.unit === '$' ? Number(productOffer?.value || 0)?.toLocaleString('en-US') : productOffer?.value || 0} ${productOffer?.unit !== '$' ? `${productOffer?.unit}` : ''}`,
        };
      }
      return {
        ...x,
        value: x?.func && x?.func(children),
      };
    });
    return productOfferData;
  };

  useEffect(() => {
    if (currentOffer?.data && isCurrentOfferSuccess) {
      const productDetails =
        currentOffer?.data.quote_details?.product_details?.[0] ?? {};
      const children = productDetails?.children || [];
      setPieData(getPieChartData(children));
      setCurrentOfferValue(getOfferMappingData(children));
      const rate = children?.find((x) => x?.key === 'Rate');
      if (rate?.value > 0) {
        setIsRateAvailable(true);
      } else {
        setIsRateAvailable(false);
      }
    }
  }, [currentOffer, isCurrentOfferSuccess]);

  useEffect(() => {
    if (!isCurrentOfferSuccess) {
      setIsRateAvailable(false);
    }
  }, [isCurrentOfferSuccess]);

  useEffect(() => {
    setIsFormSubmitted(false);
    setIsUploadError(false);
  }, [applicationId]);

  const handleCompare = async () => {
    try {
      setButtonText('Comparing...');
      const errors = checkValidations(compareOffer, formData);
      if (Object.keys(errors)?.length > 0) {
        setIsFormSubmitted(true);
        setErrors(errors);
        customToast.error(
          'Failed to compare loan. Compare offer field cannot be empty.'
        );
      } else {
        const rate = productDetails?.children?.find(
          (x) => x?.key === 'Rate'
        )?.value;
        const payload = {
          application_id: applicationId,
          products: [
            {
              product_id: productDetails?.product_id,
              rate: rate,
            },
          ],
          loan_details: {
            ...formData,
            loan_program_id: formData?.loan_program_id?.id || null,
            number_of_payments: parseInt(formData?.number_of_payments) || 0,
          },
        };
        if (role === roles?.consumer) {
          delete payload.application_id;
        }
        const response =
          role === roles?.loanOfficer
            ? await compareLoanWithUserInput({ data: payload })
            : await compareConsumerWithUserInput({ data: payload });

        if (response?.error?.data?.message) {
          customToast.error(response?.error?.data?.message);
        }

        if (response?.data?.status === 200) {
          const userInputProductDetails =
            response?.data?.data?.quote_details?.product_details[1] ?? {};
          const userInputChildren = userInputProductDetails?.children || [];

          const currentOfferProductDetails =
            response?.data?.data?.quote_details?.product_details[0] ?? {};
          const currentOfferChildren =
            currentOfferProductDetails?.children || [];
          setCompareData([
            {
              title: currentOfferProductDetails?.heading,
              dataMock: getPieChartData(currentOfferChildren),
              currentOfferValue: getOfferMappingData(currentOfferChildren),
              rawData: currentOfferChildren,
              heading: currentOfferProductDetails?.heading || '',
            },
            {
              title: userInputProductDetails?.heading || <>&nbsp;</>,
              dataMock: getPieChartData(userInputChildren),
              currentOfferValue: getOfferMappingData(userInputChildren),
              rawData: userInputChildren,
              heading: userInputProductDetails?.heading || '',
            },
          ]);
          setCompareVisibility(true);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log({ error });
    } finally {
      setButtonText('Compare');
    }
  };
  const handleReset = () => {
    setFormData({
      ...createFormObject(compareOffer),
      loan_program_id: 0,
    });
    setIsFormSubmitted(false);
    setErrors({});
  };

  const dropdowns = {
    loan_program_id: formatData(loanProgram?.data, 'loanProgramId'),
  };

  const onFilesChange = async (files) => {
    if (
      files &&
      ![
        'application/pdf',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
      ].includes(files[0]?.type)
    ) {
      customToast.error('Invalid Document. No offer to compare');
      setIsUploadError(true);
      setFiles(files);
      return;
    }
    setFiles(files);
    setIsUploadError(false);
  };

  const onDownloadQuote = async () => {
    try {
      setIsDownloading(true);
      const payload = compareData?.map((x) => {
        return {
          title: x?.heading,
          quote_data: x?.rawData?.map((data) => {
            if (data?.key === 'Lock Period') {
              return {
                ...data,
                value: data?.value ? data.value : 0,
              };
            }
            return data;
          }),
          fees_data: [],
        };
      });

      customToast.promise(
        downloadPDF(
          role === roles?.loanOfficer ? downloadOffer : downloadConsumerOffer,
          {
            data: payload,
            applicationId,
          },
          {
            download: true,
          }
        ),
        'Fetching your file',
        'Your file fetched successfully'
      );
    } catch (error) {
      Sentry.captureException(error);

      console.log({ error });
    } finally {
      setIsDownloading(false);
    }
  };

  const isValid = () => {
    return (
      files &&
      [
        'application/pdf',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
      ].includes(files[0]?.type) &&
      (files[0]?.size / 1048576).toFixed(2) < 10
    );
  };

  return (
    <>
      {!(isCurrentOfferLoading || isCurrentOfferFetching) ? (
        <>
          {!isRateAvailable && (
            <div className="h-[65vh]">
              <ToolsErrorDisplay selectedTabId={{ selectedTabId: 1 }} />
            </div>
          )}
          {isRateAvailable && (
            <div className="w-[100%] h-[70vh] flex flex-col overflow-y-scroll">
              <div className="w-[100%] h-[100%] p-3 self-center flex flex-row gap-1">
                {/* <CurrentOffer currentOfferData={[currentOfferData[0]]}/> */}
                <div className="w-[35%] h-[100%] border-r border-[#E5E5E5] flex flex-col justify-start py-2">
                  <H1>Current Offer</H1>
                  <div className="self-center w-[130%] ml-3  h-fit">
                    <DoughnutGraph graphData={pieData} />
                  </div>
                  <div>
                    <table>
                      {currentOfferValue?.map((item, index) => {
                        return (
                          <React.Fragment key={item?.key}>
                            <tr>
                              <td className="text-start p-2">
                                <p className="p-xs-regular text-[#151314]">
                                  {item.name}
                                </p>
                              </td>
                              <td className="text-start p-2">
                                <p className="p-xs-regular text-[#151314]">
                                  {item.value}
                                </p>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </table>
                  </div>
                </div>
                <div className="w-[65%] h-[100%] flex flex-col gap-2 p-3">
                  <div className="h-fit">
                    <H1>Compare Offer</H1>
                    <form
                    //</div>onSubmit={onSubmitHandler}
                    >
                      <DynamicForm
                        data={compareOffer}
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                        isSubmitted={isSubmitted}
                        dropdowns={dropdowns}
                        //additionInfo={applicationData?.data}
                      />
                    </form>
                  </div>
                  <div className="h-fit w-[100%] flex flex-row justify-end p-2">
                    <div className="h-[100%] flex flex-row justify-end gap-3">
                      <Button
                        variant="tertiary"
                        type="button"
                        onClick={handleReset}
                        btnClassName="rounded-md"
                      >
                        Reset
                      </Button>
                      <Button
                        variant="primary"
                        onClick={handleCompare}
                        type="button"
                        btnClassName="rounded-md"
                      >
                        {buttonText}
                      </Button>
                    </div>
                  </div>
                  <div className="h-[100%] flex flex-col gap-3 p-2">
                    <H1 className="mx-2 font-bold text-[18px]">
                      Compare with another Offer
                    </H1>
                    {!files && (
                      <DragAndDrop
                        containerClass={`!border-solid ${isUploadError ? 'border-danger100' : 'border-neutral100'}`}
                        onFilesChange={onFilesChange}
                        textElement={
                          !isUploading ? (
                            <p className="m-2 text-sm text-center text-gray-500 dark:text-gray-400 p-2">
                              <span className="text-custom-green font-semibold">
                                Click to upload
                              </span>{' '}
                              or Drag & Drop PDF, JPEG, JPG or PNG document (Max
                              10 MB)
                            </p>
                          ) : (
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              Uploading...
                            </p>
                          )
                        }
                      />
                    )}
                    {files && (
                      <div
                        className={` rounded-md ${isValid() && uploadSuccess ? 'border-grey border' : 'border-danger100 border-2'}  flex items-center p-3 gap-2`}
                      >
                        <img src={fileIcon} alt="" />
                        <div className="flex-grow">
                          <div className="flex justify-between">
                            <div className="font-medium-2 truncate overflow-hidden max-w-[252px]">
                              {files[0]?.name}
                            </div>
                            <img
                              src={deleteIcon}
                              alt=""
                              className="cursor-pointer"
                              onClick={() => {
                                setFiles(null);
                                setIsUploadError(false);
                                setUploadSuccess(true);
                              }}
                            />
                          </div>

                          <p className="p-xs-regular text-neutral600">
                            {(files[0]?.size / 1048576).toFixed(2)} MB
                          </p>

                          {[
                            'application/pdf',
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'image/gif',
                          ].includes(files[0]?.type) ? (
                            <Progressbar
                              value={100}
                              valueString="100 %"
                              valuePosition="right"
                            />
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    )}
                    {files && (files[0]?.size / 1048576).toFixed(2) > 10 && (
                      <p className="font-medium-2 text-danger100 mt-2 mb-2">
                        File size too large. Document size should be below 10
                        MB.
                      </p>
                    )}
                    {files &&
                      ![
                        'application/pdf',
                        'image/jpeg',
                        'image/jpg',
                        'image/png',
                        'image/gif',
                      ].includes(files[0]?.type) && (
                        <p className="font-medium-2 text-danger100 mb-2">
                          This file type is not supported. Please check and
                          upload the correct file type.
                        </p>
                      )}
                    <div className="h-fit w-[100%] flex flex-row justify-end p-2">
                      <div className="h-[100%] flex flex-row justify-end gap-3">
                        <Button
                          variant="primary"
                          type="button"
                          btnClassName="rounded-md"
                          onClick={async () => {
                            if (!files) {
                              setIsUploadError(true);
                              customToast.error(
                                'Failed to compare loan. No offer uploaded'
                              );
                              return;
                            }
                            setIsUploadError(false);
                            try {
                              setUploadButtonText('Comparing...');
                              setIsUploading(true);
                              const data = {
                                application_id: applicationId,
                                products: [
                                  {
                                    product_id: productDetails?.product_id,
                                    rate:
                                      productDetails?.children?.find(
                                        (x) => x?.key === 'Rate'
                                      )?.value ?? 0,
                                  },
                                ],
                              };
                              const formData = new FormData();
                              formData.append('body', JSON.stringify(data));
                              formData.append('file', files[0]);
                              const response =
                                role === roles?.loanOfficer
                                  ? await uploadDocumentOffer({
                                      data: formData,
                                    })
                                  : await uploadConsumerOffer({
                                      data: formData,
                                    });
                              if (response?.error?.data?.message) {
                                setUploadSuccess(false);
                                customToast.error(
                                  response?.error?.data?.message
                                );
                              }
                              if (response?.data?.status === 200) {
                                setUploadSuccess(true);
                                const userInputProductDetails =
                                  response?.data?.data?.quote_details
                                    ?.product_details[1] ?? {};
                                const userInputChildren =
                                  userInputProductDetails?.children || [];

                                const currentOfferProductDetails =
                                  response?.data?.data?.quote_details
                                    ?.product_details[0] ?? {};
                                const currentOfferChildren =
                                  currentOfferProductDetails?.children || [];
                                setCompareData([
                                  {
                                    title: currentOfferProductDetails?.heading,
                                    dataMock:
                                      getPieChartData(currentOfferChildren),
                                    currentOfferValue:
                                      getOfferMappingData(currentOfferChildren),
                                    rawData: currentOfferChildren,
                                    heading:
                                      currentOfferProductDetails?.heading || '',
                                  },
                                  {
                                    title: userInputProductDetails?.heading || (
                                      <>&nbsp;</>
                                    ),
                                    dataMock:
                                      getPieChartData(userInputChildren),
                                    currentOfferValue:
                                      getOfferMappingData(userInputChildren),
                                    rawData: userInputChildren,
                                    heading: userInputChildren?.heading || '',
                                  },
                                ]);
                                setCompareVisibility(true);
                              }
                            } catch (error) {
                              Sentry.captureException(error);

                              console.log({ error });
                            } finally {
                              setIsUploading(false);
                              setUploadButtonText('Compare');
                            }

                            setUploadFileBoolean(true);
                          }}
                        >
                          {uploadButtonText}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
      {compareVisibility && (
        <Modal
          show={compareVisibility}
          children={
            <ComparedLoanInfo
              setCompareVisibility={setCompareVisibility}
              currentOfferData={compareData}
              onDownloadQuote={onDownloadQuote}
              isLoading={isDownloading}
            />
          }
          width="max-w-[800px] w-[65vw]"
        />
      )}
    </>
  );
};

export default LoanComparison;
