import React from 'react';
/**
 * Checkbox component.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isError - Flag indicating if there is an error.
 * @param {string} [props.color='primary-accent'] - The color of the checkbox.
 * @param {string} [props.errorColor='border-error'] - The color of the error border.
 * @returns {JSX.Element} - The rendered checkbox component.
 */
const Checkbox = ({
  isError,
  color = 'primary-accent',
  errorColor = 'border-error',
  size = 'w-4 h-4',
  ...props
}) => {
  return (
    <input
      type="checkbox"
      {...props}
      className={` ${size} ${color} cursor-pointer ${
        isError ? `appearance-none ${errorColor} border-2 rounded` : ''
      }`}
    />
  );
};

export default Checkbox;
