import { loan_service_api as loanServiceAPI } from 'constants/constant';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getHeaders } from 'auth/auth';
export const applicationAPI = createApi({
  reducerPath: 'profile',
  baseQuery: fetchBaseQuery({
    baseUrl: loanServiceAPI,
  }),
  tagTypes: ['getApplication'],
  endpoints: (builder) => ({
    createPersonalDetails: builder.mutation({
      query: (data) => {
        return {
          url: `application/personal_details`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    updatePersonalDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `application/personal_details/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    createLoanDetails: builder.mutation({
      query: (data) => {
        return {
          url: `application/loan`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    updateLoanDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `application/loan/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    createEmploymentDetails: builder.mutation({
      query: (data) => {
        return {
          url: `application/employment_and_income`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    updateEmploymentDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `application/employment_and_income/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    createAssetDetails: builder.mutation({
      query: (data) => {
        return {
          url: `/v1/application/assets`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    deleteAssetDetails: builder.mutation({
      query: ({ assetId }) => {
        return {
          url: `/v1/application/assets/${assetId}`,
          method: 'DELETE',

          headers: getHeaders(),
        };
      },
    }),
    createLiabilitiesDetails: builder.mutation({
      query: (data) => {
        return {
          url: `application/liabilities`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    updateLiabilitiesDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `application/liabilities/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    createRealEstateDetails: builder.mutation({
      query: (data) => {
        return {
          url: `application/reos`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    updateRealEstateDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `application/reos/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    createDeclarationDetails: builder.mutation({
      query: (data) => {
        return {
          url: `application/declaration`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    updateDeclarationDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `application/declaration/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    createDemographicsDetails: builder.mutation({
      query: (data) => {
        return {
          url: `application/demographics`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    updateDemographicsDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `application/demographics/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),

    getApplication: builder.query({
      query: ({ application_id }) => {
        return {
          url: `application/${application_id}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['get-application'],
    }),

    /*     getApplicationV1: builder.query({
      query: ({ application_id }) => {
        return {
          url: `v1/application/${application_id}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['get-application'],
    }),
 */
    getOwnerShip: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/ownership`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getLoanPurpose: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/loan_purpose`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getLoanType: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/loan_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getOccupancy: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/occupancy`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getLoanProgram: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/loan_program`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getEmploymentType: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/employment_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getPropertyType: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/property_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getAccountType: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/account_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getLiabilityType: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/liability_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getRefinancePurpose: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/refinance_purpose`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getMartialStatus: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/marital_status`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getCitizenshipStatus: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/citizenship_status`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getUserRace: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/race`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getAssetType: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/asset_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getPropertyRight: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/property_right`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getMannerHeld: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/manner_held`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getLoanTermYears: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/loan_term`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getIncomeType: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/income_type`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getApplicationStatus: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/application_status`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getEthnicity: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/ethnicity`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get state
    getStates: builder.query({
      query: () => {
        return {
          url: `application_dropdowns/states`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get new dynamic application
    getDynamicApplication: builder.query({
      query: ({ application_id, userId }) => {
        return {
          url: `v1/application/${application_id}?user_id=${userId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['getApplication'],
    }),
    //create new dynamic employee
    createDynamicEmploymentDetails: builder.mutation({
      query: ({ data }) => {
        return {
          url: `v1/application/employment_and_income`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //update exsiting dynamic employee
    updateDynamicEmploymentDetails: builder.mutation({
      query: ({ data }) => {
        return {
          url: `v1/application/employment_and_income`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //delete exsiting dynamic employee
    deleteDynamicEmploymentDetails: builder.mutation({
      query: ({ employmentIncomeId }) => {
        return {
          url: `v1/application/employment_and_income/${employmentIncomeId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //create new dynamic liability
    createDynamicLiability: builder.mutation({
      query: ({ data }) => {
        return {
          url: `v1/application/liabilities`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //update exsiting dynamic liability
    updateDynamicLiability: builder.mutation({
      query: ({ data }) => {
        return {
          url: `v1/application/liabilities`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //delete exsiting dynamic liability
    deleteDynamicLiability: builder.mutation({
      query: ({ userLiabilityId }) => {
        return {
          url: `v1/application/liabilities/${userLiabilityId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    createRealEstateDetailsV1: builder.mutation({
      query: ({ data }) => {
        return {
          url: `v1/application/reos`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    deleteRealEstateById: builder.mutation({
      query: ({ reosId }) => {
        return {
          url: `v1/application/reos/${reosId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    updateRealEstateDetailsV1: builder.mutation({
      query: ({ data }) => {
        return {
          url: `v1/application/reos`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
  }),
});

export const {
  useCreatePersonalDetailsMutation,
  useUpdatePersonalDetailsMutation,
  useCreateLoanDetailsMutation,
  useUpdateLoanDetailsMutation,
  useCreateEmploymentDetailsMutation,
  useUpdateEmploymentDetailsMutation,
  useCreateAssetDetailsMutation,
  useDeleteAssetDetailsMutation,
  useCreateLiabilitiesDetailsMutation,
  useUpdateLiabilitiesDetailsMutation,
  useCreateRealEstateDetailsMutation,
  useUpdateRealEstateDetailsMutation,
  useCreateDeclarationDetailsMutation,
  useUpdateDeclarationDetailsMutation,
  useCreateDemographicsDetailsMutation,
  useUpdateDemographicsDetailsMutation,
  useGetApplicationQuery,
  useGetOwnerShipQuery,
  useGetLoanPurposeQuery,
  useGetLoanTypeQuery,
  useGetOccupancyQuery,
  useGetLoanProgramQuery,
  useGetPropertyTypeQuery,
  useGetAccountTypeQuery,
  useGetLiabilityTypeQuery,
  useGetRefinancePurposeQuery,
  useGetEthnicityQuery,
  useGetUserRaceQuery,
  useGetEmploymentTypeQuery,
  useGetMartialStatusQuery,
  useGetCitizenshipStatusQuery,
  useGetAssetTypeQuery,
  useGetLoanTermYearsQuery,
  useGetIncomeTypeQuery,
  useGetMannerHeldQuery,
  useGetPropertyRightQuery,
  useGetApplicationStatusQuery,
  useGetStatesQuery,
  useUpdateRealEstateDetailsV1Mutation,
  useCreateRealEstateDetailsV1Mutation,
  useDeleteRealEstateByIdMutation,
  useGetDynamicApplicationQuery,
  useUpdateDynamicEmploymentDetailsMutation,
  useCreateDynamicEmploymentDetailsMutation,
  useDeleteDynamicEmploymentDetailsMutation,
  useCreateDynamicLiabilityMutation,
  useUpdateDynamicLiabilityMutation,
  useDeleteDynamicLiabilityMutation,
} = applicationAPI;
