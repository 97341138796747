/* istanbul ignore file */
import {
  checkTextInput,
  withValidations,
  checkAlphanumeric,
  checkEmailValidation,
} from 'pages/profile/utils';
const productPricingData = [
  {
    id: 1,
    name: 'Correspondent',
    type: 'checkbox',
    placeholder: '',
    customClass: 'w-full',
    isRequired: false,
    propertyName: 'correspondent',
  },
  {
    id: 2,
    name: 'Company Name',
    type: 'text',
    placeholder: 'Enter Company Name',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'companyName',
  },
  {
    id: 3,
    name: 'Loan Officer',
    type: 'text',
    placeholder: 'Enter Mortgage Broker',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'loanOfficer',
    disabled: true,
    validator: checkTextInput,
  },
  {
    id: 4,
    name: 'Borrower Name',
    type: 'text',
    placeholder: 'Enter Borrower Name',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'borrowerName',
    validator: checkTextInput,
  },
  {
    id: 5,
    name: 'Loan Type',
    type: 'select',
    placeholder: 'Select Loan Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'loanType',
    options: [],
  },
  {
    id: 6,
    name: 'Buydown Type',
    type: 'select',
    placeholder: 'Select Buydown Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'buydownType',
    options: [],
  },
  {
    id: 7,
    name: 'Lender Type',
    type: 'select',
    placeholder: 'Select Lender Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'lenderType',
  },
  {
    id: 8,
    name: 'Loan Amount',
    type: 'number',
    placeholder: 'Enter Loan Amount',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'loanAmount',
  },
  {
    id: 9,
    name: 'Second Loan Amount',
    type: 'number',
    placeholder: 'Enter Second Loan Amount',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      return parseInt(formData?.secondLoanAmount) !== 0;
    },
    propertyName: 'secondLoanAmount',
  },
  {
    id: 10,
    name: 'Sales Price',
    type: 'number',
    placeholder: 'Enter Sales Price',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      return formData?.purposeType?.id === 1;
    },
    propertyName: 'salesPrice',
    disabled: (formData) => {
      return formData?.purposeType?.id === 2;
    },
  },
  {
    id: 11,
    name: 'Appraised Value',
    type: 'number',
    placeholder: 'Enter Appraised Value',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'appraisedValue',
    isRequired: (formData) => {
      return formData?.purposeType?.id === 2;
    },
    disabled: (formData) => {
      return formData?.purposeType?.id === 1;
    },
  },
  {
    id: 12,
    name: 'LTV',
    type: 'number',
    placeholder: 'Enter LTV',
    customClass: 'w-1/3',
    isRequired: true,
    disabled: true,
    propertyName: 'ltv',
  },
  {
    id: 13,
    name: 'CLTV',
    type: 'number',
    placeholder: 'Enter CLTV',
    customClass: 'w-1/3',
    isRequired: true,
    disabled: true,
    propertyName: 'cltv',
  },
  {
    id: 14,
    name: 'Purpose',
    type: 'select',
    placeholder: 'Select Purpose',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'purposeType',
    disabled: true,
    options: [],
  },
  {
    id: 15,
    name: 'Refinance Purpose',
    type: 'select',
    placeholder: 'Enter Refinance Purpose',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      return formData?.purposeType?.id === 2;
    },
    disabled: (formData) => {
      return formData?.purposeType?.id !== 2;
    },
    propertyName: 'refinancePurposeType',
    options: [],
  },
  {
    id: 16,
    name: 'Occupancy',
    type: 'select',
    placeholder: 'Select Occupancy',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'occupancyType',
    options: [],
  },
  {
    id: 17,
    name: 'Property Type',
    type: 'select',
    placeholder: 'Select Property Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'propertyType',
    options: [],
  },
  {
    id: 18,
    name: 'No. of units',
    type: 'number',
    placeholder: 'Enter No. of units',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'noOfUnits',
  },
  {
    id: 19,
    name: 'County',
    type: 'text',
    placeholder: 'Enter County',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'county',
    isRequired: (formData, _) => {
      return true;
    },
    propertyName: 'county',
    disabled: (formData, _) => {
      return false;
    },
    validator: checkTextInput,
  },
  {
    id: 20,
    name: 'Property State',
    type: 'select',
    placeholder: 'Enter Property State',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'propertyState',
    options: [],
  },
  {
    id: 21,
    name: 'Zip Code',
    type: 'number',
    placeholder: 'Enter Zip Code',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'zipCode',
  },
  {
    id: 22,
    name: 'Country',
    type: 'select',
    placeholder: 'Select Country',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'countryType',
    options: [],
  },
  {
    id: 23,
    name: 'Financed Properties',
    type: 'number',
    placeholder: 'Enter Financed Properties',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'financedProperties',
  },
  {
    id: 24,
    name: 'Credit Score',
    type: 'number',
    placeholder: 'Enter Credit Score',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'creditScore',
  },
  {
    id: 25,
    name: 'Monthly Income',
    type: 'number',
    placeholder: 'Enter Monthly Income',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'monthlyIncome',
  },
  {
    id: 26,
    name: 'DTI Over 45% (Higher MI)',
    type: 'checkbox',
    placeholder: '',
    customClass: 'w-full',
    isRequired: false,
    propertyName: 'dtiOver45',
  },
  {
    id: 27,
    name: 'New Construction',
    type: 'checkbox',
    placeholder: '',
    customClass: 'w-[33.33333%]',
    isRequired: false,
    propertyName: 'newConstruction',
  },
  {
    id: 28,
    name: 'Self Employed',
    type: 'checkbox',
    placeholder: '',
    customClass: 'w-[66.6666%]',
    isRequired: false,
    propertyName: 'selfEmployed',
  },
  {
    id: 29,
    name: 'Commitment Period',
    type: 'select',
    placeholder: 'Select Commitment Period',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'commitmentPeriodType',
    options: [],
  },
  {
    id: 30,
    name: 'Compensation Payer Type',
    type: 'select',
    placeholder: 'Select Compensation Payer Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'compensationPayerType',
    disabled: (formData) => {
      return formData?.correspondent;
    },
    options: [],
  },
  {
    id: 31,
    name: 'Escrow/ Impound Waiver Type',
    type: 'select',
    placeholder: 'Select Escrow/ Impound Waiver Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'escrowImpoundWaiverType',
    options: [],
  },
  {
    id: 32,
    name: 'Loan Terms',
    type: 'select',
    placeholder: 'Select Loan Terms',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'loanTermsType',
    options: [],
  },
  {
    id: 33,
    name: 'Flex Terms',
    type: 'select',
    placeholder: 'Select Flex Terms',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'flexTermsType',
    options: [],
  },
  {
    id: 34,
    name: 'First Time Homebuyer',
    type: 'select',
    placeholder: 'Select First Time Homebuyer',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'firstTimeHomebuyerType',
    options: [],
  },
  {
    id: 35,
    name: 'Processing Option',
    type: 'select',
    placeholder: 'Select Processing Option',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'processingOptionType',
    options: [],
  },
];
const productPricing = withValidations(productPricingData);
export default productPricing;
