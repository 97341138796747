import { createSlice } from '@reduxjs/toolkit';

const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    timelineData: [],
    page: 1,
    maxStage: 1,
    personalInfoData: {},
    loanInfoData: {},
    incomeData: {},
    assetData: {},
    liabilitiesData: {},
    REOData: {},
    declarationData: {},
    demographicsData: {},
  },
  reducers: {
    setApplicationTimeline: (state, action) => {
      state.timelineData = action?.payload;
    },
    setProfileState: (state, action) => {
      state.page = action?.payload;
    },
    setMaxStage: (state, action) => {
      state.maxStage = action?.payload;
    },
    setPageInfo: (state, action) => {
      // Needs this for debugging for Until stabilized.
      console.log({ AIPayload: action?.payload });
      state[action?.payload?.name] = {
        ...state[action?.payload?.name],
        ...action?.payload?.data,
      };
    },
    resetPageInfo: (state, action) => {
      state[action?.payload?.name] = {};
    },
  },
});

export const {
  setApplicationTimeline,
  setProfileState,
  setMaxStage,
  setPageInfo,
  resetPageInfo,
} = applicationSlice.actions;

export const selectTimelineData = (state) => state.application.timelineData;
export const profileState = (state) => state.application.page;
export const maxStageSelector = (state) => state.application.maxStage;
export const personalInfoSelector = (state) =>
  state?.application?.personalInfoData;
export const loanInfoSelector = (state) => state?.application?.loanInfoData;
export const incomeDataSelector = (state) => state?.application?.incomeData;
export const assetDataSelector = (state) => state?.application?.assetData;
export const liabilitiesDataSelector = (state) =>
  state?.application?.liabilitiesData;
export const REODataSelector = (state) => state?.application?.REOData;
export const declarationDataSelector = (state) =>
  state?.application?.declarationData;
export const demographicsDataSelector = (state) =>
  state?.application?.demographicsData;

export default applicationSlice.reducer;
