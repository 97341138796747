import React from 'react';
import { useState } from 'react';
import Button from 'components/button';
import Purchase from 'assets/icons/Purchase';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { scaleMotionProps2 } from 'constants/animationProps';
import TitleCard from 'components/title-card';
/**
 * Page4 function handles the rendering and functionality of the fourth page of the application.
 *
 * @param {Object} props - The props object containing the necessary data and functions.
 * @param {string} props.user - The name of the user.
 * @param {function} props.setLoanPurpose - The function to set the loan purpose.
 * @param {string} props.loanPurpose - The current loan purpose.
 * @param {function} props.setPage - The function to set the current page.
 * @param {boolean} props.isLoading - A flag indicating if the page is currently loading.
 * @param {function} props.setDirection - The function to set the direction of the page transition.
 * @param {boolean} props.direction - The current direction of the page transition.
 * @returns {JSX.Element} - The JSX element representing the fourth page of the application.
 */
const Page4 = ({
  user,
  setLoanPurpose,
  loanPurpose,
  setPage,
  isLoading,
  setDirection,
  direction,
}) => {
  const [hoverStates, setHoverStates] = useState({
    purchase: false,
    refinance: false,
  });
  const handleHover = (type, isHovering) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [type]: isHovering,
    }));
  };

  return (
    <div className="flex flex-col  justify-center items-center h-full">
      <AnimatePresence mode="wait">
        <motion.div
          {...scaleMotionProps2}
          className="flex flex-col items-center"
        >
          <h2 className="h5-b text-neutral mb-3">Hello {user}!</h2>
          <p className="h2 text-secondary w-[32rem] mb-6 text-center">
            What’s the purpose of your loan?
          </p>
          <div className="flex gap-3">
            <TitleCard
              selected={loanPurpose === 'purchase'}
              onClick={() => setLoanPurpose('purchase')}
              label="Purchase"
              type="purchase"
              onMouseEnter={() => handleHover('purchase', true)}
              onMouseLeave={() => handleHover('purchase', false)}
              iconComponent={
                loanPurpose !== 'purchase' ? (
                  <Purchase
                    variant={hoverStates.purchase ? 'secondary' : 'primary'}
                  />
                ) : (
                  <Purchase variant="tertiary" />
                )
              }
              description="Need a mortgage because I'm buying a house."
            />
            <TitleCard
              selected={loanPurpose === 'refinance'}
              onClick={() => setLoanPurpose('refinance')}
              label="Refinance"
              onMouseEnter={() => handleHover('refinance', true)}
              onMouseLeave={() => handleHover('refinance', false)}
              iconComponent={
                loanPurpose !== 'refinance' ? (
                  <Purchase
                    variant={hoverStates.refinance ? 'secondary' : 'primary'}
                  />
                ) : (
                  <Purchase variant="tertiary" />
                )
              }
              type="refinance"
              description="Want to refinance my existing mortgage."
            />
          </div>

          <div className="flex gap-4 mt-6">
            <Button
              variant="enabled"
              onClick={() => {
                setPage(3);
                setDirection(false);
              }}
            >
              Back
            </Button>
            <Button
              variant={loanPurpose === '' ? 'disabled' : 'primary'}
              disabled={loanPurpose === ''}
              onClick={() => {
                setPage(5);
                setDirection(true);
              }}
            >
              {isLoading && direction ? 'Please wait...' : 'Next'}
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Page4;
