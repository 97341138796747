import React, { useEffect } from 'react';
//components
import DynamicForm from './DynamicForm';
import Radio from '../../components/radio';
import Breadcrumb from '../../components/breadcrumb';
//forms and child imports
import DeclarationForm from './forms/DeclarationForm';
import PersonalDetails from './forms/PersonalDetails';
import LoanInformation from './forms/LoanInformation';
import EmployeeAndIncome from './forms/EmployeeAndIncome';
import AssetsAndOtherCredits from './forms/AssetsAndOtherCredits';
import Liabilities from './forms/Liabilities';
import RealEstateOwned from './forms/RealEstateOwned';
import Demographics from './forms/Demographics';
//redux and RTK
import { useDispatch, useSelector } from 'react-redux';
import {
  maxStageSelector,
  profileState,
  setApplicationTimeline,
  setMaxStage,
  setProfileState,
} from 'redux_store/slices/application';
import {
  useGetUserInfoQuery,
  useIsFirstTimeUserMutation,
} from 'redux_store/services/client/onboarding';
import { useGetApplicationQuery } from 'redux_store/services/client/profile';
//wrapper components
import Loader from 'wrapper/loader';
import * as Sentry from '@sentry/browser';
//utils
import { checkValidations, createFormObject } from './utils';
//form fields
import { personalInformation } from './formfields/personalInformation';
import {
  loanInformation,
  refinanceInformation,
} from './formfields/loanInformation';
import {
  employmentAndIncome,
  selfEmployed,
} from './formfields/employeeAndIncome';
import {
  assetData,
  otherAssets,
  otherCredits,
} from './formfields/assetAndOtherCredits';
import { liabilitiesData } from './formfields/liabilitiesData';
import { realEstateOwned } from './formfields/realEstateOwned';
//custom hookes
import usePageTitle from 'constants/PageTitle';

const Profile = () => {
  usePageTitle('Profile');
  const [profileStage, setProfileStage] = React.useState(0);
  const [token, setToken] = React.useState('');
  const progressRef = React.useRef(false);
  const dispatch = useDispatch();
  const breadCrumbs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Profile',
      path: '/profile',
    },
  ];
  const page = useSelector(profileState);
  const maxStage = useSelector(maxStageSelector);
  const dispatchTimelineStatus = (page) => {
    const pageNames = [
      'Personal Details',
      'Loan Info',
      'Income',
      'Assets',
      'Liabilities',
      'REO',
      // 'Declaration',
      // 'Demographics',
    ];

    const timeStatus = Array.from({ length: 6 }, () => ({}));
    const updatedTimeline = timeStatus.map((x, i) => {
      const name = pageNames[i] || '';
      return {
        ...x,
        state:
          page === i + 1
            ? 'progress'
            : i + 1 < page
              ? 'done'
              : i + 1 < maxStage
                ? 'done'
                : 'notDone',
        name: name,
      };
    });
    dispatch(setProfileState(page));
    dispatch(setApplicationTimeline(updatedTimeline));
  };

  useEffect(() => {
    if (page && page <= maxStage) {
      setProfileStage(page - 1);
    } else {
      setProfileStage(0);
    }
  }, [page, maxStage]);

  const order = [
    {
      id: 1,
      name: 'personal_details',
      shouldRedirectHere: (formData) => {
        const updatedFormData = {
          ...formData,
          yearsLiving: {
            id: parseInt(formData?.yearsLiving) + 1,
          },
          monthsLiving: {
            id: parseInt(formData?.monthsLiving) + 1,
          },
          ownershipId: {
            id: formData?.ownershipId,
          },
          citizenshipStatusId: {
            id: formData?.citizenshipStatusId,
          },
          maritalStatusId: {
            id: formData?.maritalStatusId,
          },
          presentAddressLessThanTwoYears:
            formData?.presentAddressLessThanTwoYears === '1' ? true : false,
        };
        const errors = checkValidations(
          personalInformation,
          updatedFormData,
          applicationData?.data
        );
        return Object.keys(errors)?.length > 0;
      },
    },
    {
      id: 2,
      name: 'loan_details',
      shouldRedirectHere: (formData) => {
        if (!formData?.loanPurposeId) {
          return true;
        } else {
          const updatedForm = {
            ...formData,
            refinancePurposeId: {
              id: formData?.refinancePurposeId,
            },
            occupancyTypeId: {
              id: formData?.occupancyTypeId,
            },
            propertyTypeId: {
              id: formData?.propertyTypeId,
            },
            loanProgramId: {
              id: formData?.loanProgramId,
            },
            loanTypeId: {
              id: formData?.loanTypeId,
            },
            propertyRightsId: {
              id: formData?.propertyRightsId,
            },
            mannerHeldId: {
              id: formData?.mannerHeldId,
            },
            loanTermId: {
              id: formData?.loanTermId,
            },
            escrowed: {
              id: formData?.escrowed === '1' ? 1 : 2,
            },
            firstTimeBuying: {
              id: formData?.firstTimeBuying === '1' ? 1 : 2,
            },
            isMixUseProperty: {
              id: formData?.isMixUseProperty === '1' ? 1 : 2,
            },
          };
          const errors = checkValidations(
            formData?.loanPurposeId === 1
              ? loanInformation
              : refinanceInformation,
            updatedForm,
            applicationData?.data
          );
          return Object.keys(errors)?.length > 0;
        }
      },
    },
    {
      id: 3,
      name: 'employment_and_income_details',
      shouldRedirectHere: (formData) => {
        if (!formData?.employmentTypeId) {
          return true;
        } else {
          const updatedForm = {
            ...formData,
            employerExperienceMonths: {
              id: formData?.employerExperienceMonths + 1,
            },
            employerExperienceYears: {
              id: formData?.employerExperienceYears + 1,
            },
            previousEmployerExperienceYears: {
              id: formData?.previousEmployerExperienceYears + 1,
            },
            previousEmployerExperienceMonths: {
              id: formData?.previousEmployerExperienceMonths + 1,
            },

            LOBWorkMonths: {
              id: formData?.LOBWorkMonths + 1,
            },
            LOBWorkYears: {
              id: formData?.LOBWorkYears + 1,
            },

            incomeTypeId: {
              id: formData?.incomeTypeId,
            },
            presentEmployerLessThanTwoYears:
              formData?.presentEmployerLessThanTwoYears === '1',
            employedByOtherParty: formData?.employedByOtherParty === '1',
            selfEmployed: formData?.selfEmployed === '1',
          };
          const errors = checkValidations(
            formData?.employmentTypeId === 3
              ? selfEmployed
              : employmentAndIncome,
            updatedForm
          );
          return Object.keys(errors)?.length > 0;
        }
      },
    },
    {
      id: 4,
      name: 'user_asset_details',
      shouldRedirectHere: (formData) => {
        const assetFormData = {
          ...formData,
          assetTypeId: {
            id: formData?.assetTypeId,
          },
        };
        const otherAssetFormData = {
          ...Object.keys(createFormObject(otherAssets))?.reduce(
            (acc, value) => ({ ...acc, [value]: formData[value] }),
            {}
          ),
          otherAssetTypeId: {
            id: formData?.otherAssetTypeId,
          },
        };

        const otherCreditFormData = Object.keys(
          createFormObject(otherCredits)
        )?.reduce((acc, value) => ({ ...acc, [value]: formData[value] }), {});
        const assetErrors = checkValidations(assetData, assetFormData);
        const otherErrors = checkValidations(otherAssets, otherAssetFormData);
        const creditErrors = checkValidations(
          otherCredits,
          otherCreditFormData
        );
        if (
          Object.keys(assetErrors)?.length > 0 ||
          Object.keys(otherErrors)?.length > 0 ||
          Object.keys(creditErrors)?.length > 0
        ) {
          return true;
        }
        return false;
      },
    },
    {
      id: 5,
      name: 'user_libality_details',
      shouldRedirectHere: (formData) => {
        const updatedFormData = {
          ...formData,
          liabilityTypeId: {
            id: formData?.liabilityTypeId,
          },
          isExclude: formData?.isExclude
            ? {
                id: formData?.isExclude === '1' ? 1 : 2,
              }
            : null,
          isPayoffRequired: formData?.isPayoffRequired === '1' ? true : false,
          isIncludeBalance: formData?.isIncludeBalance === '1' ? true : false,
          isIncludePayment: formData?.isIncludePayment === '1' ? true : false,
        };
        const errors = checkValidations(
          liabilitiesData,
          updatedFormData,
          applicationData?.data
        );
        if (Object.keys(errors)?.length > 0) {
          return true;
        }
        return false;
      },
    },
    {
      id: 6,
      name: 'user_reos_details',
      shouldRedirectHere: (formData) => {
        const updatedForm = {
          ...formData,
          isRentalForIncome: {
            id: formData?.isRentalForIncome === '1' ? 1 : 2,
          },
          escrowedPayment: {
            id:
              formData?.escrowedPayment === '1'
                ? 1
                : formData?.escrowedPayment === '0'
                  ? 2
                  : '',
          },
        };
        const errors = checkValidations(
          realEstateOwned,
          updatedForm,
          applicationData?.data
        );
        if (Object.keys(errors)?.length > 0) {
          return true;
        }
        return false;
      },
    },
    // {
    //   id: 7,
    //   name: 'user_declarations_details',
    // },
    // {
    //   id: 8,
    //   name: 'user_demographics_details',
    // },
  ];

  const [getUserData] = useIsFirstTimeUserMutation();
  const { data: profileData } = useGetUserInfoQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  React.useEffect(() => {
    fetchUserData();
  }, [profileData]);

  const fetchUserData = async () => {
    try {
      const resp = await getUserData();
      if (resp?.data?.data?.pending_application_id) {
        setToken(resp?.data?.data?.pending_application_id);
      } else if (profileData?.data?.user_profiles?.[0]?.applicationId) {
        setToken(profileData.data.user_profiles[0].applicationId);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const { data: applicationData, refetch } = useGetApplicationQuery(
    {
      application_id: token,
    },
    {
      skip: !token,
      refetchOnMountOrArgChange: true,
    }
  );
  React.useEffect(() => {
    if (applicationData && !progressRef.current) {
      progressRef.current = true;
      let found = false;
      order?.forEach((page, index) => {
        if (!applicationData?.data?.[page?.name] && !found) {
          found = true;
          let errorsFound = false;
          if (index !== 0) {
            const previousPage = order[index - 1];
            errorsFound = previousPage.shouldRedirectHere(
              applicationData?.data?.[previousPage?.name]
            );
            if (errorsFound) {
              dispatch(setMaxStage(index));
              dispatchTimelineStatus(index);
            } else {
              dispatch(setMaxStage(index + 1));
              dispatchTimelineStatus(index + 1);
            }
          } else {
            dispatch(setMaxStage(index + 1));
            dispatchTimelineStatus(index + 1);
          }
        }
      });
      if (!found) {
        dispatch(setMaxStage(7));
        dispatchTimelineStatus(6);
      }
    }
  }, [applicationData]);

  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  return applicationData ? (
    <div className="max-h-custom w-full flex justify-between flex-nowrap">
      <div className="w-full">
        <Breadcrumb breadcrumbs={breadCrumbs} />
        {profileStage === 0 && (
          <PersonalDetails
            setPage={() => {
              maxStage <= 1 && dispatch(setMaxStage(2));
              dispatchTimelineStatus(2);
            }}
            token={token}
          />
        )}
        {profileStage === 1 && (
          <LoanInformation
            setPreviousPage={() => {
              dispatchTimelineStatus(1);
            }}
            setPage={() => {
              maxStage <= 2 && dispatch(setMaxStage(3));
              dispatchTimelineStatus(3);
            }}
            token={token}
          />
        )}
        {profileStage === 2 && (
          <EmployeeAndIncome
            setPreviousPage={() => {
              dispatchTimelineStatus(2);
            }}
            setPage={() => {
              maxStage <= 3 && dispatch(setMaxStage(4));
              dispatchTimelineStatus(4);
            }}
            token={token}
          />
        )}
        {profileStage === 3 && (
          <AssetsAndOtherCredits
            setPreviousPage={() => {
              dispatchTimelineStatus(3);
            }}
            setPage={() => {
              maxStage <= 4 && dispatch(setMaxStage(5));
              dispatchTimelineStatus(5);
            }}
            token={token}
          />
        )}
        {profileStage === 4 && (
          <Liabilities
            setPreviousPage={() => {
              dispatchTimelineStatus(4);
            }}
            setPage={() => {
              maxStage <= 5 && dispatch(setMaxStage(6));
              dispatchTimelineStatus(6);
            }}
            token={token}
          />
        )}
        {profileStage === 5 && (
          <RealEstateOwned
            setPreviousPage={() => {
              dispatchTimelineStatus(5);
            }}
            setPage={() => {
              // maxStage <= 6 && dispatch(setMaxStage(7));
              // dispatchTimelineStatus(7);
            }}
            token={token}
          />
        )}
        {profileStage === 6 && (
          <DeclarationForm
            setPreviousPage={() => {
              dispatchTimelineStatus(6);
            }}
            setPage={() => {
              maxStage <= 7 && dispatch(setMaxStage(8));
              dispatchTimelineStatus(8);
            }}
            token={token}
          />
        )}
        {profileStage >= 7 && (
          <Demographics
            setPreviousPage={() => {
              dispatchTimelineStatus(7);
            }}
            setPage={() => {
              maxStage <= 8 && dispatch(setMaxStage(9));
              dispatchTimelineStatus(9);
            }}
            token={token}
          />
        )}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Profile;
