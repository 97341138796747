import dashboard from 'assets/icons/dashboard.svg';
import profile from 'assets/icons/profile.svg';
import pipeline from 'assets/icons/pipeline.svg';
import documents from 'assets/icons/documents.svg';
import tools from 'assets/icons/tools.svg';
import reportsIcon from 'assets/icons/reportsIcon.svg';
import adminIcon from 'assets/icons/adminIcon.svg';
import consumerIcon from 'assets/icons/consumerIcon.svg';

export const navItems = [
  {
    title: 'Dashboard',
    icon: <img src={dashboard} alt="dashboard"></img>,
    path: '/dashboard',
    role: ['consumer', 'loanOfficer', 'organization'],
  },
  {
    title: 'Profile',
    icon: <img src={profile} alt="profile"></img>,
    path: '/profile',
    role: ['consumer'],
  },
  {
    title: 'Loan Information',
    icon: <img src={profile} alt="loan"></img>,
    path: '/loanInformation',
    role: ['loanOfficer', 'organization'],
    subPaths: ['/loanInformation'],
  },
  {
    title: 'Pipeline',
    icon: <img src={pipeline} alt="pipeline"></img>,
    path: '/pipeline',
    role: ['consumer', 'loanOfficer', 'organization'],
    subPaths: ['/pipeline'],
  },
  {
    title: 'Quick Price',
    icon: <img src={documents} alt="quickPrice"></img>,
    path: '/quickPrice',
    role: ['loanOfficer', 'organization'],
  },
  {
    title: 'Documents',
    icon: <img src={documents} alt="documents"></img>,
    path: '/documents',
    role: ['consumer'],
  },
  {
    title: 'Tools',
    icon: <img src={tools} alt="tools"></img>,
    path: '/tools',
    role: ['consumer', 'organization', 'loanOfficer'],
  },
  {
    title: 'Admin',
    icon: <img src={adminIcon} alt="admin"></img>,
    path: '/admin',
    role: ['organization'],
  },
  {
    title: 'Reports',
    icon: <img src={reportsIcon} alt="reports"></img>,
    path: '/reports',
    role: ['organization'],
  },
];
