import React, { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getExpandedRowModel,
} from '@tanstack/react-table';
/**
 * Renders a nested table component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.columns - The columns configuration for the table.
 * @param {Array} props.data - The data to be displayed in the table.
 * @param {string} props.errorMessage - The error message to be displayed if there is no data.
 * @param {string} props.height - The height of the table container.
 * @param {string} props.globalFilter - The global filter value.
 * @param {function} props.setGlobalFilter - The function to set the global filter value.
 * @returns {JSX.Element} - The rendered nested table component.
 */
const NestedTable = ({
  columns,
  data,
  errorMessage,
  height,
  globalFilter,
  setGlobalFilter,
}) => {
  const [expanded, setExpanded] = useState({});

  // Filter the data based on globalFilter for both parent and nested children
  const filteredData = useMemo(() => {
    if (!globalFilter || globalFilter === '') return data;

    return data
      .map((row) => ({
        ...row,
        sub_types: row.sub_types.filter((subRow) =>
          subRow.name.toLowerCase().includes(globalFilter.toLowerCase())
        ),
      }))
      .filter((row) => row.sub_types.length > 0); // Filter out parent rows with no matching children
  }, [data, globalFilter]);

  const tableInstance = useReactTable({
    data: filteredData,
    columns,
    state: {
      expanded,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.sub_types,
    getExpandedRowModel: getExpandedRowModel(),
  });

  //expand parents when filter exists
  useEffect(() => {
    if (globalFilter) {
      const newExpanded = {};
      filteredData.forEach((row, index) => {
        newExpanded[index] = true;
      });
      setExpanded(newExpanded);
    } else {
      setExpanded({});
    }
  }, [globalFilter]);

  return (
    <div className={`overflow-auto ${height}`}>
      <table className="min-w-full border-collapse relative">
        <thead>
          {tableInstance.getHeaderGroups().map((headerGroup) => {
            return (
              <motion.tr 
              initial={{
                y: -20,
              }}
              animate={{
                x: 0,
                y: 0,
                scale: 1,
                rotate: 0,
              }}
              key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      colSpan={header.colSpan}
                      key={header.id}
                      className={`bg-chatbot_bg p-3 text-start text-secondary800 max-w-auto p-xs-bold whitespace-nowrap ${
                        header.column.columnDef.meta?.className ?? ''
                      } ${header.column.columnDef.meta?.width ?? ''} `}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </motion.tr>
            );
          })}
        </thead>
        {tableInstance.getRowModel().rows.length > 0 ? (
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => {
              return (
                <motion.tr
                initial={{
                  y: -20,
                }}
                animate={{
                  x: 0,
                  y: 0,
                  scale: 1,
                  rotate: 0,
                }}
                key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={`text-start p-xs-regular p-4 bg-white ${row.depth === 0 ? 'border-t border-grey' : ''} ${
                          cell.column.columnDef.meta?.className ?? ''
                        } ${cell.column.columnDef.meta?.style ?? ''} 
                      ${cell.column.columnDef.meta?.width ?? ''} `}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </motion.tr>
              );
            })}
          </tbody>
        ) : (
          <div className="absolute left-[40%] w-[18rem] mt-10 font-medium text-center">
            {errorMessage}
          </div>
        )}
      </table>
    </div>
  );
};

export default NestedTable;
