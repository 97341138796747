/* istanbul ignore file */
const mortageQuoteDynamicData = [
    {
        heading: "UWM - CONV ELITE 310 Year Fixed",
        Children: [
            {
                name: "Estimated Value",
                value: 650000,
            },
            {
                name: "Loan Balance/ LTV",
                value: 650000,
            },
            {
                name: "Rate/ APR",
                value: 650000,
            },
            {
                name: "Monthly Payment",
                value: 650000,
            },
            {
                name: "Points or Credit",
                value: 650000,
            },
            {
                name: "Term",
                value: 650000,
            },
            {
                name: "Lender Fees",
                value: 650000,
            },
            {
                name: "Other Closing Costs",
                value: 650000,
            },
            {
                name: "Estimated Cash to Close",
                value: 650000,
            },
            {
                name: "Estimated Cash to Close",
                value: 650000,
            },
            {
                name: "Total Interest",
                value: 650000,
                checkbox: true,
            },
            {
                name: "Interest Savings",
                value: 650000,
                checkbox: true,
            },
            {
                name: "Lifetime Payment",
                value: 650000,
                checkbox: true,
            },
        ]
    },
    // {
    //     heading: "UWM - CONV ELITE 30 Year Fixed",
    //     Children: [
    //         {//
    //             name: "Estimated Value",
    //             value: 650000,
    //         },
    //         {
    //             name: "Loan Balance/ LTV",
    //             value: 650000,
    //         },
    //         {
    //             name: "Rate/ APR",
    //             value: 650000,
    //         },
    //         {
    //             name: "Monthly Payment",
    //             value: 650000,
    //         },
    //         {
    //             name: "Points or Credit",
    //             value: 650000,
    //         },
    //         {
    //             name: "Term",
    //             value: 650000,
    //         },
    //         {
    //             name: "Lender Fees",
    //             value: 650000,
    //         },
    //         {
    //             name: "Other Closing Costs",
    //             value: 650000,
    //         },
    //         {
    //             name: "Estimated Cash to Close",
    //             value: 650000,
    //         },
    //         {
    //             name: "Estimated Cash to Close",
    //             value: 650000,
    //         },
    //         {
    //             name: "Total Interest",
    //             value: 650000,
    //             checkbox: true,
    //         },
    //         {
    //             name: "Interest Savings",
    //             value: 650000,
    //             checkbox: true,
    //         },
    //         {
    //             name: "Lifetime Payment",
    //             value: 650000,
    //             checkbox: true,
    //         },
    //     ]
    // },    
    // {
    //     heading: "UWM - CONV ELITE 30 Year Fixed",
    //     Children: [
    //         {
    //             name: "Estimated Value",
    //             value: 650000,
    //         },
    //         {
    //             name: "Loan Balance/ LTV",
    //             value: 650000,
    //         },
    //         {
    //             name: "Rate/ APR",
    //             value: 650000,
    //         },
    //         {
    //             name: "Monthly Payment",
    //             value: 650000,
    //         },
    //         {
    //             name: "Points or Credit",
    //             value: 650000,
    //         },
    //         {
    //             name: "Term",
    //             value: 650000,
    //         },
    //         {
    //             name: "Lender Fees",
    //             value: 650000,
    //         },
    //         {
    //             name: "Other Closing Costs",
    //             value: 650000,
    //         },
    //         {
    //             name: "Estimated Cash to Close",
    //             value: 650000,
    //         },
    //         {
    //             name: "Estimated Cash to Close",
    //             value: 650000,
    //         },
    //         {
    //             name: "Total Interest",
    //             value: 650000,
    //             checkbox: true,
    //         },
    //         {
    //             name: "Interest Savings",
    //             value: 650000,
    //             checkbox: true,
    //         },
    //         {
    //             name: "Lifetime Payment",
    //             value: 650000,
    //             checkbox: true,
    //         },
    //     ]
    // }
    
]
export default mortageQuoteDynamicData;