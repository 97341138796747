import React, { useState } from 'react';

//components
import Button from 'components/button';
import Modal from 'components/modal';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
import Badge from 'components/badge';
//assets
import closeIcon from 'assets/icons/closeIcon.svg';
import fileIcon from 'assets/icons/file.svg';
import ViewIcon from 'assets/icons/ViewIcon';
//utils
import { convertUnixToCustomDate } from 'constants/unixToDate';
//logs
import * as Sentry from '@sentry/browser';

//custom hooks
import useDownloadPDFHandler from 'hooks/pdfViewer';
//query
import { fetchAdminDocument } from 'redux_store/services/documentDownloads';
import {
  useVerifyDocumentMutation,
  useRejectDocumentMutation,
} from 'redux_store/services/admin/document';

/**
 * Renders a modal to view documents and allows the user to select and view a specific document.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {boolean} viewVerify - Flag to control the visibility of the modal.
 * @param {function} setViewVerify - Function to set the visibility of the modal.
 * @param {Object} documents - Object containing the list of documents to be displayed.
 * @param {string} userId - The ID of the user viewing the documents.
 * @param {function} resetState - Function to reset the state of the component.
 *
 * @returns {JSX.Element} A modal component with the ability to view and select documents.
 */

const VerifyDocument = ({ viewVerify, setViewVerify, documents, userId }) => {
  //custom hook for download/view pdf
  const { downloadPDF } = useDownloadPDFHandler();
  const [verifyDocument, { isLoading: isVerifyDocumentLoading }] =
    useVerifyDocumentMutation();
  const [rejectDocument, { isLoading: isRejectDocumentLoading }] =
    useRejectDocumentMutation();
  //states
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  //handle view document
  const handleFetchDocumentList = async (data) => {
    try {
      customToast.promise(
        downloadPDF(
          fetchAdminDocument,
          {
            documentId: data?.userDocumentId,
            userId,
          },
          { download: false }
        ),
        'Fetching your file',
        'Your file fetched successfully'
      );
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error downloading the PDF:', error);
    }
  };
  const handleVerifyDocument = async () => {
    if (!selectedDocumentId) {
      customToast.error('Please select a document to verfiy.');
      return false;
    }
    try {
      const resp = await verifyDocument({
        documentId: selectedDocumentId?.userDocumentId,
        userId,
      });
      if (resp?.data?.success) {
        setViewVerify(false);
        customToast.success(`${documents.name} Document Approved.`);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };
  const handleRejectDocument = async () => {
    if (!selectedDocumentId) {
      customToast.error('Please select a document to verfiy.');
      return false;
    }
    try {
      const resp = await rejectDocument({
        documentId: selectedDocumentId?.userDocumentId,
        userId,
      });
      if (resp?.data?.success) {
        setViewVerify(false);
        customToast.error(`${documents.name} Document Rejected.`);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };
  return (
    <Modal show={viewVerify} width="max-w-[392px]">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div className="p-sm-bold mb-3 w-[15rem]">{`Verify ${documents.name}`}</div>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-3"
            onClick={() => {
              setViewVerify(false);
            }}
          />
        </div>
        <p className="font-medium mb-4 text-disabled">
          The following document's have been uploaded and are pending
          verification
        </p>

        {documents?.documents?.map((data, index) => (
          <div
            key={data.userDocumentId}
            className={`rounded-md border-grey border flex items-center p-3 gap-2 mb-4 cursor-pointer ${
              selectedDocumentId?.userDocumentId === data?.userDocumentId
                ? 'bg-chatbot_bg'
                : ''
            }`}
            onClick={() => setSelectedDocumentId(data)}
          >
            <img src={fileIcon} alt="" />
            <div className="flex-grow">
              <div className="flex justify-between">
                <div className="w-full flex flex-row justify-around">
                  <p className="font-medium-2 overflow-hidden min-w-[150px] max-w-[150px] text-nowrap text-ellipsis">
                    {data?.name}{' '}
                  </p>
                  <div className="flex flex-col justify-center">
                    <Badge
                      title={
                        data?.status === 3
                          ? 'Pending'
                          : data?.status === 2
                            ? 'Approved'
                            : data?.status === 4
                              ? 'Rejected'
                              : ''
                      }
                      variant={
                        data?.status === 3
                          ? 'verifying'
                          : data?.status === 2
                            ? 'success'
                            : data?.status === 4
                              ? 'rejected'
                              : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <p className="p-xs-regular text-neutral600">{data?.size}</p>
              <p className="p-xs-regular text-neutral600">
                Uploaded on &nbsp;
                {convertUnixToCustomDate(data.timestamp, 'MM-dd-yy, KK:mm bb')}
              </p>
            </div>
            <div onClick={() => handleFetchDocumentList(data)}>
              <ViewIcon />
            </div>
          </div>
        ))}

        <div className="flex justify-end gap-2 mt-12">
          <Button variant="danger" onClick={handleRejectDocument}>
            {isRejectDocumentLoading ? (
              <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                <Loader loaderText="Rejecting" FullScreen={false} />
              </div>
            ) : (
              'Reject'
            )}
          </Button>
          <Button variant="primary" onClick={handleVerifyDocument}>
            {isVerifyDocumentLoading ? (
              <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                <Loader loaderText="Approving" FullScreen={false} />
              </div>
            ) : (
              'Approve'
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyDocument;
