import React from 'react';
import loader from 'assets/icons/loader.svg';
// icons
import fundmoreai from 'assets/fundmoreai2.png'
/**
 * Renders a loading card component.
 *
 * @returns {JSX.Element} The loading card component.
 */
const CardLoader = () => {
  return (
    <div
      className="bg-cover bg-center h-screen flex justify-center items-center w-[100vw]"
      style={{ backgroundImage: 'url("/mainbg.png")' }}
    >
      {' '}
      <div className="w-[470px] h-[338px] tertiary-bg rounded-lg flex flex-col justify-items-center items-center">
        <img
          src={fundmoreai}
          alt=""
          className="h-28"
          style={{ width: '250px', height: '65px' }}
        />

        <img src={loader} alt="" className="animate-spin" />
        <p className="p-sm-regular text-black mt-6">
          Please wait while we log you in.
        </p>
      </div>
    </div>
  );
};

export default CardLoader;
