import React from 'react';
import loader from 'assets/icons/loader.svg';
/**
 * Renders a loader component with a loading spinner and optional text.
 *
 * @param {Object} props - The component props.
 * @param {string} props.loaderText - The text to display alongside the loading spinner.
 * @returns {JSX.Element} The loader component.
 */
const Loader = ({ loaderText, FullScreen = true }) => {
  if (FullScreen) {
    return (
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
      >
        <div className="flex gap-2 justify-center items-center">
          <img src={loader} alt="" className={`animate-spin`} />
          <div className="p-sm-regular">{loaderText}</div>
        </div>
      </div>
    );
  }
  return (
    <div className={`h-[20px] w-[20px] self-center  mb-[5px]`}>
      <div className="flex gap-2 flex-row-reverse justify-center items-center">
        <img src={loader} alt="" className={`animate-spin`} />
        <div className="p-xs-medium">{loaderText}</div>
      </div>
    </div>
  );
};

export default Loader;
