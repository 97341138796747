export const chatQuery = {
  eligibilityMessage: 'Check eligibility on my profile',
  eligibilityCode: 'Check eligibility on my profile',

  preApprovalMessage:
    'Review my profile and documents and submit pre-approval request to loan officer',
  preApprovalCode:
    'Review my profile and documents and submit pre-approval request to loan officer',

  loanApplicationMessage: 'Please proceed with my loan application',
  loanApplicationCode: 'Please proceed with my loan application',

  documentUploadMessage: 'Document Uploaded!',
  documentUploadCode: 'Document Uploaded!',
};

export const removeMarkersAndJson = (rawMessage) => {
  // Remove %S% and %E% markers from the content
  const content = rawMessage.replace(/%S%|%E%/g, '');
  return content;
};
