import React, { useState } from 'react';

//components
import Button from 'components/button';
import Modal from 'components/modal';
import { customToast } from 'components/toast';

//assets
import closeIcon from 'assets/icons/closeIcon.svg';
import fileIcon from 'assets/icons/file.svg';

//utils
import { convertUnixToCustomDate } from 'constants/unixToDate';

//logs
import * as Sentry from '@sentry/browser';

//custom hooks
import useDownloadPDFHandler from 'hooks/pdfViewer';
//query
import { fetchAdminDocument } from 'redux_store/services/documentDownloads';

/**
 * Renders a modal to view documents and allows the user to select and view a specific document.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {boolean} viewPopup - Flag to control the visibility of the modal.
 * @param {function} setViewPopup - Function to set the visibility of the modal.
 * @param {Object} documents - Object containing the list of documents to be displayed.
 * @param {string} userId - The ID of the user viewing the documents.
 * @param {function} resetState - Function to reset the state of the component.
 *
 * @returns {JSX.Element} A modal component with the ability to view and select documents.
 */

const ViewDocuments = ({
  viewPopup,
  setViewPopup,
  documents,
  userId,
  resetState,
}) => {
  //custom hook for download/view pdf
  const { downloadPDF } = useDownloadPDFHandler();

  //states
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  //handle view document
  const handleFetchDocumentList = async (rowData) => {
    try {
      customToast.promise(
        downloadPDF(
          fetchAdminDocument,
          {
            documentId: selectedDocumentId?.userDocumentId,
            userId: userId,
          },
          { download: false }
        ),
        'Fetching your file',
        'Your file fetched successfully'
      );
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error downloading the PDF:', error);
    }
  };

  return (
    <Modal show={viewPopup} width="max-w-[392px]">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div className="p-sm-bold mb-3 w-[15rem]">View Document</div>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-3"
            onClick={() => {
              setViewPopup(false);
              resetState();
            }}
          />
        </div>
        <p className="font-medium mb-4 text-disabled">
          There are {documents?.documents?.length || 0} files uploaded in the
          document. Please select the file that you want to view.
        </p>

        {documents?.documents?.map((data, index) => (
          <div
            key={data.userDocumentId}
            className={`rounded-md border-grey border flex items-center p-3 gap-2 mb-4 cursor-pointer ${
              selectedDocumentId?.userDocumentId === data?.userDocumentId
                ? 'bg-chatbot_bg'
                : ''
            }`}
            onClick={() => setSelectedDocumentId(data)}
          >
            <img src={fileIcon} alt="" />
            <div className="flex-grow">
              <div className="flex justify-between">
                <div className="font-medium-2 truncate overflow-hidden max-w-[252px]">
                  {data?.name}
                </div>
              </div>
              <p className="p-xs-regular text-neutral600">{data?.size}</p>
              <p className="p-xs-regular text-neutral600">
                Uploaded on &nbsp;
                {convertUnixToCustomDate(data.timestamp, 'MM-dd-yy, KK:mm bb')}
              </p>
            </div>
          </div>
        ))}

        <div className="flex justify-end gap-2 mt-12">
          <Button
            variant="tertiary"
            onClick={() => {
              setViewPopup(false);
              resetState();
            }}
          >
            Cancel
          </Button>
          <Button
            variant={selectedDocumentId ? 'primary' : 'disabled'}
            disabled={selectedDocumentId ? false : true}
            onClick={handleFetchDocumentList}
          >
            View
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewDocuments;
