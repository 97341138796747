import React, { useEffect, useState } from 'react';

// Icons
import RightArrow from 'assets/icons/RightArrow';
import { statusVariantMap } from 'pages/pipeline/data/constants';

// Components
import ToUSD from 'components/num-to-usd';
import Loader from 'wrapper/loader';
import DoughnutGraph from 'components/doughnut';
import H1 from 'components/h1-typography';
import Badge from 'components/badge';
import Card from 'components/card';
import HorizontalTimeline from 'components/horizontal-timeline';
import User from 'components/user';
import CircularProgressBar from 'components/circular-progress-bar';
import Button from 'components/button';
import Table from 'components/table';

// RTK Queries to fetch the data
import {
  useGetDocumentListQuery,
  useGetUserPropertyQuery,
} from 'redux_store/services/client/document';
import { useGetPipelineStatusQuery } from 'redux_store/services/client/pipeline';
import { useGetApplicationDashboardInfoQuery } from 'redux_store/services/client/dashboard';

// libraries
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';

// Utils
import { getInitials } from '../../constants/getFirstChar';
import { documentMapper } from 'pages/documents/utils/mapDocuments';
import { bgColors, borderColors, textColors } from '../../themes/colors';

//custom hooks
import usePageTitle from 'constants/PageTitle';
import {
  useGetAmortizationScheduleConsumerQuery,
  useGetCurrentOfferConsumerQuery,
} from 'redux_store/services/admin/adminTools';
import LoanAmountBuydown from './LoanAmountBuydown';
import DocumentManager from './DocumentManager';
import CurrentOffer from './CurrentOffer';
import { color } from 'framer-motion';

const Dashboard = () => {
  const offerData = [
    {
      name: 'Estimate Value',
      value: '-',
      key: 'Estimated Value',
    },
    {
      name: 'Loan Balance/LTV',
      value: '-',
      key: '',
      func: (productOptions) => {
        const loanBalanceData = productOptions?.find(
          (x) => x?.key === 'Loan Balance'
        );
        const LTVData = productOptions?.find((x) => x?.key === 'LTV');
        return `${loanBalanceData?.value < 0 ? '-' : ''}${loanBalanceData?.unit}${Math.abs(loanBalanceData?.value)?.toLocaleString('en-US')}/${LTVData?.value?.toLocaleString('en-US')}${LTVData?.unit}`;
      },
    },
    {
      name: 'Rate/ APR',
      value: '-',
      key: '',
      func: (productOptions) => {
        const rateData = productOptions?.find((x) => x?.key === 'Rate');
        const aprData = productOptions?.find((x) => x?.key === 'APR');
        return `${rateData?.value}${rateData?.unit}/${aprData?.value}${aprData?.unit}`;
      },
    },
  ];
  usePageTitle('dashboard');
  // State Variables
  const [selectedAction, setSelectedAction] = useState(null);
  const [tableState, setTableState] = useState([]);
  const [key, setKey] = useState(0);
  const [docUploadPercentage, setDocUploadPercentage] = useState(0);
  const [docData, setDocData] = useState({
    totalCompleted: 0,
    totalPendingAndCompleted: 0,
  });
  const [currentOfferValue, setCurrentOfferValue] = useState(offerData);
  const [pieData, setPieData] = useState([]);
  const [loanProgramData, setLoanProgramData] = useState();

  const offerDetails = [
    { title: 'P&I', value: 0, color: '#A4D9D9', key: 'P&I' },
    { title: 'Taxes', value: 0, color: '#3F66FB', key: 'Taxes' },
    { title: 'PMI', value: 0, color: '#AFBEFF', key: 'PMI' },
    { title: 'Hazard Ins', value: 0, color: '#FFDAB9', key: 'Hazard Ins' },
  ];

  const {
    data: currentOffer,
    isLoading: isCurrentOfferLoading,
    isSuccess: isCurrentOfferSuccess,
  } = useGetCurrentOfferConsumerQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    currentData: amortizationData,
    isLoading: isAmortizationScheduleLoading,
  } = useGetAmortizationScheduleConsumerQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (amortizationData?.success) {
      setLoanProgramData(amortizationData?.data?.loan_program);
    }
  }, [amortizationData]);

  const getOfferMappingData = (children) => {
    const productOfferData = offerData?.map((x) => {
      const productOffer = children?.find((offer) => offer?.key === x?.key);
      if (productOffer) {
        return {
          ...x,
          value: `${productOffer?.unit === '$' ? `${productOffer?.unit} ` : ''} ${productOffer?.unit === '$' ? Number(productOffer?.value || 0)?.toLocaleString('en-US') : productOffer?.value || 0} ${productOffer?.unit !== '$' ? `${productOffer?.unit}` : ''}`,
        };
      }
      return {
        ...x,
        value: x?.func && x?.func(children),
      };
    });
    return productOfferData;
  };

  const getPieChartData = (children) => {
    const offerChildren = children?.filter((x) =>
      offerDetails.some((offer) => offer.key === x?.key)
    );
    const pieData = offerDetails?.map((offer) => {
      const productChildrenData = offerChildren?.find(
        (x) => x?.key === offer.key
      );
      if (productChildrenData) {
        return {
          ...offer,
          title: `${productChildrenData?.key}: ${productChildrenData?.unit} ${
            productChildrenData?.value === 0
              ? productChildrenData?.value
              : Number(productChildrenData?.value ?? 0).toLocaleString('en-US')
          }`,
          value: productChildrenData?.value || 0,
        };
      }
      return {
        ...offer,
        title: `${offer?.key}: 0`,
        value: 0,
      };
    });
    return pieData;
  };

  useEffect(() => {
    if (currentOffer?.data && isCurrentOfferSuccess) {
      const productDetails =
        currentOffer?.data.quote_details?.product_details?.[0] ?? {};
      const children = productDetails?.children || [];
      const donutData = getPieChartData(children);
      setPieData(donutData?.length > 0 ? donutData : offerDetails);
      setCurrentOfferValue(getOfferMappingData(children));
    } else {
      setPieData(offerDetails);
    }
  }, [currentOffer, isCurrentOfferSuccess]);

  // react-router-dom navigation
  const navigate = useNavigate();

  // Queries to fetch data
  const { data: dashboardInfo } = useGetApplicationDashboardInfoQuery(
    selectedAction?.application_id,
    {
      skip: !selectedAction?.application_id,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: docListData,
    isLoading: docListLoading,
    refetch: docListRefetch,
    isError: docListError,
  } = useGetDocumentListQuery(
    selectedAction ? { applicationId: selectedAction?.application_id } : null,
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: pipelineStatusData,
    isLoading: pipelineStatusLoading,
    refetch: pipelineStatusRefetch,
    isError: pipelineStatusError,
  } = useGetPipelineStatusQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: userProperty,
    isLoading: userPropertyLoading,
    isError: userPropertyError,
  } = useGetUserPropertyQuery({}, { refetchOnMountOrArgChange: true });

  React.useEffect(() => {
    if (docListData) {
      const result = documentMapper(docListData?.data);
      setTableState(result);
    }
  }, [docListData]);

  useEffect(() => {
    if (userProperty?.data) {
      if (userProperty?.data?.length > 0) {
        setSelectedAction(userProperty?.data[0]);
        //need key to force update react select state to set default value
        setKey((prev) => prev + 1);
      } else {
        setSelectedAction({});
      }
    }
  }, [userProperty]);

  useEffect(() => {
    let totalCompleted = 0;
    let totalPendingAndCompleted = 0;

    tableState?.forEach((documentType) => {
      totalCompleted += documentType.completed;
      totalPendingAndCompleted += documentType.pending + documentType.completed;
    });

    setDocData({
      totalCompleted: totalCompleted,
      totalPendingAndCompleted: totalPendingAndCompleted,
    });

    const percent = (totalCompleted / totalPendingAndCompleted) * 100;
    setDocUploadPercentage(percent ? Number(percent.toFixed(0)) : 0);
  }, [tableState]);

  // Stages for Timeline
  const stages = [
    {
      id: 1,
      name: 'Profile ID Created',
    },
    {
      id: 2,
      name: 'Eligible',
    },
    {
      id: 3,
      name: 'Pre-approved',
    },
    {
      id: 4,
      name: 'Application Started',
    },
    {
      id: 5,
      name: 'Application Submitted',
    },
    {
      id: 6,
      name: 'Processing',
    },
    {
      id: 7,
      name: 'Underwriting',
    },
    {
      id: 8,
      name: 'Pre-closing',
    },
    {
      id: 9,
      name: 'Closing',
    },
    {
      id: 10,
      name: 'Funded',
    },
    {
      id: 11,
      name: 'Post Closed',
    },
  ];

  // Table columns for Pipeline Card
  const columns = [
    {
      header: 'Profile ID',
      accessorKey: 'user_profile?.id',
      cell: ({ row }) => {
        return (
          <Link to="/profile" className="text-custom-green font-semibold ">
            {row?.original?.user_profile?.id}
          </Link>
        );
      },
    },
    {
      header: 'Name',
      accessorKey: 'user_profile.firstName',
      cell: ({ row }) => {
        return (
          <User
            size="sm"
            title={row?.original?.user_profile?.firstName}
            subtitle={row?.original?.user_profile?.lastName}
          />
        );
      },
    },
    {
      header: 'Amount',
      accessorKey: 'application_details.loanAmount',
      cell: ({ row }) => {
        return (
          <ToUSD
            currencyValue={row?.original?.application_details?.loanAmount}
          />
        );
      },
    },

    {
      header: 'Loan Officer',
      accessorKey: 'loan_officer_details.firstName',
      cell: ({ row }) => {
        return (
          <User
            size="sm"
            title={row?.original?.loan_officer_details?.firstName}
            subtitle={row?.original?.loan_officer_details?.lastName}
          />
        );
      },
    },

    {
      header: 'Status',
      accessorKey: 'application_details.statusId',
      cell: ({ row }) => {
        const statusId = row?.original?.application_details.statusId;
        const statusObject = pipelineStatusData?.data?.find(
          (data) => data.applicationStatusId === statusId
        );
        const statusText = statusObject ? statusObject.name : '';
        return (
          <div className="w-[8rem]">
            <Badge
              title={statusText}
              variant={statusVariantMap[statusId] || 'primary'}
            />
          </div>
        );
      },
    },
    {
      header: 'Property Address',
      accessorKey: 'application_details.property_address',
      meta: {
        width: 'min-w-[200px]',
      },
    },
  ];
  // graph data for Offer
  const dataMock = [
    {
      title: 'P&I: 0%',
      //value: 10,
      value: 0,
      color: '#A4D9D9',
    },
    {
      title: 'Taxes: 0%',
      //value: 10,
      value: 0,
      color: '#AFBEFF',
    },
    {
      title: 'PMI: 0%',
      //value: 10,
      value: 0,
      color: '#3F66FB',
    },
  ];

  // graph data for Document
  const documentManager = [
    {
      title: `Uploaded: ${docUploadPercentage}%`,
      value: docData?.totalCompleted,
      color: '#3DC13C',
    },
    {
      title: `Pending: ${100 - docUploadPercentage}%`,
      value: docData?.totalPendingAndCompleted - docData?.totalCompleted,
      color: '#F3BB1B',
    },
  ];

  return selectedAction &&
    !isCurrentOfferLoading &&
    !isAmortizationScheduleLoading ? (
    <div
      className="w-full"
      style={{
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <div className="flex items-center justify-between mb-2 w-full">
        <H1>Dashboard</H1>
        <Select
          menuShouldScrollIntoView={true}
          menuShouldBlockScroll={true}
          menuPlacement="auto"
          menuPosition="fixed"
          key={key}
          defaultValue={selectedAction}
          onChange={setSelectedAction}
          options={userProperty?.data ? userProperty?.data : []}
          getOptionLabel={(e) => (
            <div>
              <div className="flex items-center">
                <div
                  className={`w-6 h-6 bg-profile rounded-full flex justify-center items-center mr-2`}
                >
                  {getInitials(e.first_name)}
                </div>
                <div className="mt-0.5 whitespace-nowrap truncate w-[14rem]">
                  <div>
                    {e.first_name} {e.last_name} - {e.property_address}
                  </div>
                  <div>({e?.user_profile_id})</div>
                </div>
              </div>
            </div>
          )}
          styles={{
            control: (provided) => ({
              ...provided,
              fontSize: '14px',
              position: 'relative',
              fontWeight: '500',
              borderRadius: '5px',
              boxShadow: 'none',
              border: `1px solid ${borderColors.primary}`,
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected
                ? bgColors.primary100
                : bgColors.tertiary_bg,
              borderBottom: `1px solid ${borderColors.primary}`,
              color: textColors.black,
              fontSize: '14px',
              fontWeight: '500',
              '&:hover': {
                cursor: 'pointer',
              },
            }),
            indicatorSeparator: () => null,
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
              fontSize: '12px',
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral80: textColors.black,
            },
          })}
          menuPortalTarget={document.body}
          placeholder="Select Property"
          className="w-[20rem]"
        />
      </div>
      <Card className={'pt-[70px] flex items-center justify-center'}>
        <HorizontalTimeline
          align="top"
          showFullText={true}
          data={stages?.map((stage, i) => {
            if (i === 0) {
              return {
                ...stage,
                state: 'done',
              };
            }
            if (i === 1) {
              return {
                ...stage,
                state: 'progress',
              };
            }
            return {
              ...stage,
              state: 'notDone',
            };
          })}
        />
      </Card>
      <div className="flex w-full mt-2">
        <Card className="w-1/3 p-4">
          <h4 className="text-neutral1000 font-bold mb-2">Profile</h4>
          <div className="flex items-center">
            <User
              title={
                dashboardInfo?.data?.user_profile?.firstName +
                ' ' +
                dashboardInfo?.data?.user_profile?.lastName
              }
              subtitle={dashboardInfo?.data?.user_profile?.email}
              size="md"
              className="w-4/5"
            />
            <CircularProgressBar
              percentageFontWeight="bold"
              showPercentage={true}
              percentage={
                dashboardInfo?.data?.application_details?.completionStatus
              }
              sqSize={50}
            />
          </div>
          <Button
            variant="link"
            btnClassName={'flex items-center gap-2'}
            onClick={(e) => {
              navigate('/profile');
            }}
            style={{ fontSize: '14px', color: '#01B23C' }}
          >
            Complete Profile <RightArrow />{' '}
          </Button>
        </Card>
        <Card className="w-2/3 ml-2 p-4">
          <h4 className="text-neutral1000 font-bold mb-2">Pipeline</h4>
          <Table
            data={[dashboardInfo?.data]}
            columns={columns}
            errorMessage="No relevant data found"
            height="h-auto"
          />
        </Card>
      </div>
      <div className="flex w-full mt-2">
        <CurrentOffer pieData={pieData} currentOfferValue={currentOfferValue} />
        <LoanAmountBuydown loanProgramData={loanProgramData} />
        <DocumentManager docData={docData} documentManager={documentManager} />
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Dashboard;
