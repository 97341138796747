import React from 'react';

const SvgIcon = ({ checked = false, ...props }) => {
  return !checked ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect width="23" height="23" x="0.5" y="0.5" fill="#fff" rx="3.5"></rect>
      <rect
        width="23"
        height="23"
        x="0.5"
        y="0.5"
        stroke="#D0D5DD"
        rx="3.5"
      ></rect>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect
        width="23"
        height="23"
        x="0.5"
        y="0.5"
        fill="#3F66FB"
        fillOpacity="0.1"
        rx="3.5"
      ></rect>
      <rect
        width="23"
        height="23"
        x="0.5"
        y="0.5"
        stroke="#3F66FB"
        rx="3.5"
      ></rect>
      <path
        stroke="#3F66FB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.6 7.8l-7.7 7.7L6.4 12"
      ></path>
    </svg>
  );
};

export default SvgIcon;
