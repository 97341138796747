import React, { Suspense } from 'react';
import Login from 'pages/login/Login';
import NotFound from 'components/404';
import SessionExpire from 'wrapper/session-expire/SessionExpire';
import InviteExpire from 'wrapper/invite-expire';
import EmailVerified from 'wrapper/email-verified';
import VerifyPassword from 'pages/verify-password';
const authRoutes = [
  {
    path: '/',
    element: <Login />,
    children: [
      {
        path: 'setup-password',
        element: (
          <Suspense>
            <VerifyPassword />
          </Suspense>
        ),
      },
      {
        path: 'session-timeout',
        element: (
          <Suspense>
            <SessionExpire />
          </Suspense>
        ),
      },
      {
        path: 'verified',
        element: (
          <Suspense>
            <InviteExpire />
          </Suspense>
        ),
      },
      {
        path: 'result_page',
        element: (
          <Suspense>
            <EmailVerified />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
];

export default authRoutes;
