// Components
import React, { useEffect } from 'react';
import DynamicForm from '../DynamicForm';
import {
  loanInformation as tempLoanInformation,
  refinanceInformation as tempRefinanceInformation,
} from '../consumerFormFields/loanInformation';
import Button from 'components/button';
import Select from 'react-select';
import H1 from 'components/h1-typography';
import Loader from 'wrapper/loader';
import FormWrapper from 'wrapper/form-wrapper';
// RTK Queries
import {
  useCreateLoanDetailsMutation,
  useGetApplicationQuery,
  useGetLoanProgramQuery,
  useGetLoanPurposeQuery,
  useGetLoanTermYearsQuery,
  useGetLoanTypeQuery,
  useGetMannerHeldQuery,
  useGetOccupancyQuery,
  useGetPropertyRightQuery,
  useGetPropertyTypeQuery,
  useGetRefinancePurposeQuery,
  useUpdateLoanDetailsMutation,
  useGetStatesQuery,
} from 'redux_store/services/client/profile';

// Utils
import { checkValidations, formatData, createFormObject } from '../utils';
import * as Sentry from '@sentry/browser';
import { addDays, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import { loanInfoSelector } from 'redux_store/slices/application';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';

//themes
import { bgColors, textColors, borderColors } from '../../../themes/colors';
// libraries
import { format } from 'date-fns';
import { useGetOnboardingDataQuery } from 'redux_store/services/client/onboarding';

const LoanInformation = ({ setPage, setPreviousPage, token }) => {
  //remove county
  const loanInformation = tempLoanInformation?.filter((obj) => obj.id !== 47);
  const refinanceInformation = tempRefinanceInformation?.filter(
    (obj) => obj.id !== 46
  );
  // States
  //filtering the property rights from consumer refinance info
  const filteredRefinanceInformation = refinanceInformation.filter((item) => {
    return item.propertyName !== 'propertyRightsId';
  });
  //filtering the property rights from consumer purchase info
  const filteredPurchaseInformation = loanInformation.filter((item) => {
    return item.propertyName !== 'propertyRightsId';
  });
  const [formData, setFormData] = React.useState({
    ...createFormObject(filteredPurchaseInformation),
    loanPurposeId: 1,
    numberOfUnits: 1,
  });

  // Selectors
  const loanInfoData = useSelector(loanInfoSelector);

  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState(false);
  const isEdit = React.useRef(false);

  // RTK Queries
  const [createLoanInformation, { isLoading: isCreateLoanInformationLoading }] =
    useCreateLoanDetailsMutation();
  const [updateLoanInformation, { isLoading: isUpdateLoanInformationLoading }] =
    useUpdateLoanDetailsMutation();
  const { data: loanPurposeData } = useGetLoanPurposeQuery();
  const { data: loanTypeData } = useGetLoanTypeQuery();
  const { data: refinancePurposeData } = useGetRefinancePurposeQuery();
  const { data: occupancyData } = useGetOccupancyQuery();
  const { data: propertyTypeData } = useGetPropertyTypeQuery();
  const { data: loanProgramData } = useGetLoanProgramQuery();
  const { data: lonTermYears } = useGetLoanTermYearsQuery();
  const { data: mannerHeldData } = useGetMannerHeldQuery();
  const { data: propertyRightsData } = useGetPropertyRightQuery();
  const { data: onBoardingData } = useGetOnboardingDataQuery();
  const { data: stateType } = useGetStatesQuery();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!formData?.loanPurposeId) {
      setIsFormSubmitted(true);
    } else if (
      formData?.loanPurposeId &&
      Object.keys(
        checkValidations(
          String(formData?.loanPurposeId) === '1'
            ? filteredPurchaseInformation
            : filteredRefinanceInformation,
          formData,
          applicationData?.data
        )
      ).length > 0
    ) {
      setIsFormSubmitted(true);
      const errors = checkValidations(
        formData?.loanPurposeId === 1
          ? filteredPurchaseInformation
          : filteredRefinanceInformation,
        formData
      );
      setErrors(errors);
    } else {
      try {
        const data = {
          ...formData,
          refinancePurposeId: formData?.refinancePurposeId
            ? formData?.refinancePurposeId?.id
            : null,
          occupancyTypeId: formData?.occupancyTypeId
            ? formData?.occupancyTypeId?.id
            : null,
          propertyTypeId: formData?.propertyTypeId
            ? formData?.propertyTypeId?.id
            : null,
          loanProgramId: formData?.loanProgramId
            ? formData?.loanProgramId?.id
            : null,
          propertyRightsId: formData?.propertyRightsId
            ? formData?.propertyRightsId?.id
            : null,
          mannerHeldId: formData?.mannerHeldId
            ? formData?.mannerHeldId?.id
            : null,
          loanTermId: formData?.loanTermId ? formData?.loanTermId?.id : null,
          loanTypeId: formData?.loanTypeId ? formData?.loanTypeId?.id : null,
          salePrice: formData?.salePrice ? formData?.salePrice : 0,
          downpayment: formData?.downpayment ? formData?.downpayment : 0,
          closingDate: formData?.closingDate ? formData?.closingDate : null,
          escrowed: formData?.escrowed?.id === 1 ? true : false,
          firstTimeBuying: formData?.firstTimeBuying?.id === 1 ? true : false,
          isMixUseProperty: formData?.isMixUseProperty?.id === 1 ? true : false,
          stateName: formData?.stateName?.name,
          stateId: formData?.stateName ? formData?.stateName?.id : null,
          numberOfUnits: formData?.numberOfUnits ? formData?.numberOfUnits : 1,
          userProfileId: applicationData?.data?.personal_details?.userProfileId,
          applicationId: token,
          /*For local 
          applicationId: 4,
          userProfileId: 1, */
          LTV: parseFloat(formData?.LTV) < 0 ? 0 : formData?.LTV,
          zipCode: String(formData?.zipCode ?? ''),
        };

        if (!data?.closingDate) {
          const currentDate = new Date();
          const futureDate = addDays(currentDate, 45);
          data.closingDate = futureDate;
        }

        if (isEdit?.current) {
          const resp = await updateLoanInformation({
            applicationId: token,
            data: replaceEmptyStringWithNull(data),
          });
          await refetch();
          if (resp.data?.status === 200) {
            setPage();
          }
        } else {
          const resp = await createLoanInformation(
            replaceEmptyStringWithNull(data)
          );
          await refetch();
          if (resp.data?.status === 200) {
            setPage();
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };

  const { data: applicationData, refetch } = useGetApplicationQuery(
    {
      application_id: token,
    },
    {
      skip: !token,
    }
  );

  const getFullName = () => {
    return (
      (applicationData?.data?.personal_details?.firstName ?? '') +
      ' ' +
      (applicationData?.data?.personal_details?.middleName ?? '') +
      ' ' +
      (applicationData?.data?.personal_details?.lastName ?? '')
    );
  };

  React.useEffect(() => {
    if (applicationData?.data?.loan_details) {
      isEdit.current = true;
      const formData = applicationData?.data?.loan_details;
      const onboardingDetails = applicationData?.data?.user_onboarding_details;
      setFormData({
        ...formData,
        loanPurposeId:
          formData?.loanPurposeId || onboardingDetails?.loanPurposeId,
        purchaseHomeVestIn: formData?.purchaseHomeVestIn || getFullName(),
        ...loanInfoData,
        refinancePurposeId: loanInfoData?.refinancePurposeId
          ? {
              id: loanInfoData?.refinancePurposeId,
            }
          : formData?.refinancePurposeId
            ? {
                id: formData?.refinancePurposeId,
              }
            : '',

        occupancyTypeId: loanInfoData?.occupancyTypeId
          ? {
              id: loanInfoData?.occupancyTypeId,
            }
          : formData?.occupancyTypeId
            ? {
                id: formData?.occupancyTypeId,
              }
            : '',

        propertyTypeId: loanInfoData?.propertyTypeId
          ? {
              id: loanInfoData?.propertyTypeId,
            }
          : formData?.propertyTypeId
            ? {
                id: formData?.propertyTypeId,
              }
            : '',

        loanProgramId: loanInfoData?.loanProgramId
          ? {
              id: loanInfoData?.loanProgramId,
            }
          : formData?.loanProgramId
            ? {
                id: formData?.loanProgramId,
              }
            : '',

        loanTypeId: loanInfoData?.loanTypeId
          ? {
              id: loanInfoData?.loanTypeId,
            }
          : formData?.loanTypeId
            ? {
                id: formData?.loanTypeId,
              }
            : '',

        propertyRightsId: loanInfoData?.propertyRightsId
          ? {
              id: loanInfoData?.propertyRightsId,
            }
          : formData?.propertyRightsId
            ? {
                id: formData?.propertyRightsId,
              }
            : '',

        mannerHeldId: loanInfoData?.mannerHeldId
          ? {
              id: loanInfoData?.mannerHeldId,
            }
          : formData?.mannerHeldId
            ? {
                id: formData?.mannerHeldId,
              }
            : '',

        loanTermId: loanInfoData?.loanTermId
          ? {
              id: loanInfoData?.loanTermId,
            }
          : formData?.loanTermId
            ? {
                id: formData?.loanTermId,
              }
            : '',
        stateName: loanInfoData?.stateId
          ? {
              id: loanInfoData?.stateId,
            }
          : formData?.stateId
            ? {
                id: formData?.stateId,
              }
            : '',

        escrowed:
          loanInfoData?.escrowed === 'Yes' ||
          loanInfoData?.escrowed === 'No' ||
          loanInfoData?.escrowed === true ||
          loanInfoData?.escrowed === false
            ? {
                id:
                  loanInfoData?.escrowed === 'Yes' ||
                  loanInfoData?.escrowed === true
                    ? 1
                    : 2,
              }
            : formData?.escrowed
              ? {
                  id: formData?.escrowed === '1' ? 1 : 2,
                }
              : '',

        firstTimeBuying:
          loanInfoData?.firstTimeBuying === true ||
          loanInfoData?.firstTimeBuying === false ||
          loanInfoData?.firstTimeBuying === 'Yes' ||
          loanInfoData?.firstTimeBuying === 'No'
            ? {
                id:
                  loanInfoData?.firstTimeBuying === true ||
                  loanInfoData?.firstTimeBuying === 'Yes'
                    ? 1
                    : 2,
              }
            : formData?.firstTimeBuying
              ? {
                  id: formData?.firstTimeBuying === '1' ? 1 : 2,
                }
              : '',

        isMixUseProperty:
          loanInfoData?.isMixUseProperty === true ||
          loanInfoData?.isMixUseProperty === false ||
          loanInfoData?.isMixUseProperty === 'Yes' ||
          loanInfoData?.isMixUseProperty === 'No'
            ? {
                id:
                  loanInfoData?.isMixUseProperty === true ||
                  loanInfoData?.isMixUseProperty === 'Yes'
                    ? 1
                    : 2,
              }
            : formData?.isMixUseProperty
              ? {
                  id: formData?.isMixUseProperty === '1' ? 1 : 2,
                }
              : '',
        closingDate: loanInfoData?.closingDate
          ? formatDate(loanInfoData?.closingDate)
          : formData?.closingDate ?? '',
      });
    } else if (
      applicationData?.data?.personal_details &&
      onBoardingData?.data?.loanPurposeId
    ) {
      setFormData((prevData) => ({
        ...prevData,
        purchaseHomeVestIn: getFullName(),
        loanPurposeId: onBoardingData?.data?.loanPurposeId,
        ...loanInfoData,
        refinancePurposeId: loanInfoData?.refinancePurposeId
          ? {
              id: loanInfoData?.refinancePurposeId,
            }
          : prevData?.refinancePurposeId ?? '',

        occupancyTypeId: loanInfoData?.occupancyTypeId
          ? {
              id: loanInfoData?.occupancyTypeId,
            }
          : prevData?.occupancyTypeId ?? '',

        propertyTypeId: loanInfoData?.propertyTypeId
          ? {
              id: loanInfoData?.propertyTypeId,
            }
          : prevData?.propertyTypeId ?? '',
        stateName: loanInfoData?.stateId
          ? {
              id: loanInfoData?.stateId,
            }
          : prevData?.stateId,

        loanProgramId: loanInfoData?.loanProgramId
          ? {
              id: loanInfoData?.loanProgramId,
            }
          : prevData?.loanProgramId ?? '',

        loanTypeId: loanInfoData?.loanTypeId
          ? {
              id: loanInfoData?.loanTypeId,
            }
          : prevData?.loanTypeId ?? '',

        propertyRightsId: loanInfoData?.propertyRightsId
          ? {
              id: loanInfoData?.propertyRightsId,
            }
          : prevData?.propertyRightsId ?? '',

        mannerHeldId: loanInfoData?.mannerHeldId
          ? {
              id: loanInfoData?.mannerHeldId,
            }
          : prevData?.mannerHeldId ?? '',

        loanTermId: loanInfoData?.loanTermId
          ? {
              id: loanInfoData?.loanTermId,
            }
          : prevData?.loanTermId ?? '',

        escrowed:
          loanInfoData?.escrowed === 'Yes' ||
          loanInfoData?.escrowed === 'No' ||
          loanInfoData?.escrowed === true ||
          loanInfoData?.escrowed === false
            ? {
                id:
                  loanInfoData?.escrowed === 'Yes' ||
                  loanInfoData?.escrowed === true
                    ? 1
                    : 2,
              }
            : prevData?.escrowed ?? '',

        firstTimeBuying:
          loanInfoData?.firstTimeBuying === true ||
          loanInfoData?.firstTimeBuying === false ||
          loanInfoData?.firstTimeBuying === 'Yes' ||
          loanInfoData?.firstTimeBuying === 'No'
            ? {
                id:
                  loanInfoData?.firstTimeBuying === true ||
                  loanInfoData?.firstTimeBuying === 'Yes'
                    ? 1
                    : 2,
              }
            : prevData?.firstTimeBuying ?? '',

        isMixUseProperty:
          loanInfoData?.isMixUseProperty === true ||
          loanInfoData?.isMixUseProperty === false ||
          loanInfoData?.isMixUseProperty === 'Yes' ||
          loanInfoData?.isMixUseProperty === 'No'
            ? {
                id:
                  loanInfoData?.isMixUseProperty === true ||
                  loanInfoData?.isMixUseProperty === 'Yes'
                    ? 1
                    : 2,
              }
            : prevData?.isMixUseProperty ?? '',
        closingDate: loanInfoData?.closingDate
          ? formatDate(loanInfoData?.closingDate)
          : prevData?.closingDate ?? '',
      }));
    }
  }, [applicationData, loanInfoData, onBoardingData?.data?.loanPurposeId]);

  const formatDate = (dateString) => {
    try {
      const date = parse(dateString, 'MM/dd/yyyy', new Date());
      const formattedDate = format(date, 'yyyy-MM-dd');
      return formattedDate;
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    if (
      formData?.loanPurposeId === 1 &&
      formData?.salePrice &&
      formData?.downpayment &&
      !isNaN(+formData?.salePrice) &&
      !isNaN(+formData?.downpayment)
    ) {
      const LTVValue = Number(
        ((parseFloat(formData?.salePrice) - parseFloat(formData?.downpayment)) /
          parseFloat(formData?.salePrice)) *
          100
      ).toFixed(3);
      setFormData((formData) => ({
        ...formData,
        LTV: parseFloat(LTVValue) < 0 ? 0 : LTVValue,
        loanAmount:
          parseFloat(formData?.salePrice) - parseFloat(formData?.downpayment),
        appraisedValue: formData?.salePrice,
      }));
    } else if (
      formData?.loanPurposeId !== 1 &&
      formData?.loanAmount &&
      formData?.appraisedValue &&
      !isNaN(formData?.loanAmount) &&
      !isNaN(formData?.appraisedValue)
    ) {
      const LTVValue = Number(
        (parseFloat(formData?.loanAmount) /
          parseFloat(formData?.appraisedValue)) *
          100
      ).toFixed(3);
      setFormData((formData) => ({
        ...formData,
        LTV: parseFloat(LTVValue) < 0 ? 0 : LTVValue,
      }));
    }
  }, [
    formData?.salePrice,
    formData?.downpayment,
    formData?.loanPurposeId,
    formData?.loanAmount,
    formData?.appraisedValue,
  ]);

  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  // dropdown for form fields
  const dropdowns = {
    loanTypeId: formatData(loanTypeData?.data, 'loanTypeId'),
    refinancePurposeId: formatData(
      refinancePurposeData?.data,
      'refinancePurposeId'
    ),
    occupancyTypeId: formatData(occupancyData?.data, 'occupancyTypeId'),
    propertyTypeId: formatData(propertyTypeData?.data, 'propertyTypeId'),
    loanProgramId: formatData(loanProgramData?.data, 'loanProgramId'),
    propertyRightsId: formatData(propertyRightsData?.data, 'propertyRightsId'),
    mannerHeldId: formatData(mannerHeldData?.data, 'mannerHeldId'),
    loanTermId: formatData(lonTermYears?.data, 'loanTermId'),
    stateName: formatData(stateType?.data, 'stateId'),
  };
  const placeholderMapper = (id) => {
    if (loanPurposeData?.data) {
      let placeholder = loanPurposeData?.data.filter((item) => {
        return item.loanPurposeId === id;
      });
      return placeholder[0]?.name ?? 'Select';
    }
  };
  return (
    <div className=" overflow-auto">
      <H1>Loan Information</H1>
      <p className="antialiased">
        Provide details regarding the loan amount and its associated terms.
      </p>
      <FormWrapper
        className={
          'my-3 mx-2 max-h-custom-profile-forms  overflow-hidden  overflow-y-scroll pb-3'
        }
      >
        <div className="w-full p-3 rounded-lg">
          <form onSubmit={onSubmitHandler}>
            <div className={`px-2 flex items-center my-2 w-2/4`}>
              <label
                className="font-bold text-custom-greengray block w-2/4"
                htmlFor={`form-field-loan-purpose`}
              >
                Purpose of Loan
              </label>
              <Select
                menuShouldScrollIntoView={true}
                menuShouldBlockScroll={true}
                menuPlacement="auto"
                menuPosition="fixed"
                defaultValue={formData?.loanPurposeId}
                onChange={(e) => {
                  setFormData((old) => ({
                    ...createFormObject(
                      e.loanPurposeId === 1
                        ? filteredPurchaseInformation
                        : filteredRefinanceInformation
                    ),
                    loanPurposeId: parseInt(e.loanPurposeId),
                    purchaseHomeVestIn: old?.purchaseHomeVestIn,
                    numberOfUnits: 1,
                  }));
                  setErrors({});
                  setIsFormSubmitted(false);
                }}
                options={loanPurposeData?.data}
                isDisabled
                isClearable={false}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionValue={(value) => {
                  return value.loanPurposeId;
                }}
                placeholder={placeholderMapper(formData?.loanPurposeId)}
                className="w-full"
                noOptionsMessage={() => 'No Data found'}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    fontSize: '12px',
                    position: 'relative',
                    fontWeight: '400',
                    borderRadius: '5px',
                    boxShadow: 'none',
                    paddingRight: '9px',
                    border: `2px solid ${
                      isFormSubmitted && !!!formData?.loanPurposeId
                        ? borderColors.errorColor
                        : borderColors.grey
                    }`,
                    '&:hover': {
                      cursor: 'pointer',
                      border: `2px solid ${
                        isFormSubmitted && !!!formData?.loanPurposeId
                          ? borderColors.errorColor
                          : borderColors.grey
                      }`,
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: bgColors.tertiary_bg,
                    color: textColors.black,
                    fontSize: '12px',
                    fontWeight: '400',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: bgColors.primary50,
                    },
                    '&:active': {
                      backgroundColor: bgColors.primary50,
                    },
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    borderRadius: '5px',
                    color: textColors.black,
                    fontSize: '12px',
                    fontWeight: '400',
                  }),
                  indicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral80: textColors.black,
                  },
                })}
              />
            </div>
            {String(formData?.loanPurposeId) === '1' ? (
              <DynamicForm
                data={filteredPurchaseInformation}
                formData={formData}
                setFormData={setFormData}
                dropdowns={dropdowns}
                errors={errors}
                isSubmitted={isFormSubmitted}
                additionInfo={applicationData?.data}
              />
            ) : (
              String(formData?.loanPurposeId) !== '1' && (
                <DynamicForm
                  data={filteredRefinanceInformation}
                  formData={formData}
                  setFormData={setFormData}
                  dropdowns={dropdowns}
                  errors={errors}
                  isSubmitted={isFormSubmitted}
                  additionInfo={applicationData?.data}
                />
              )
            )}
            <div className="text-right mt-2">
              <Button
                variant="tertiary"
                onClick={() => {
                  setPreviousPage();
                }}
                style={{ marginRight: '10px' }}
                type="button"
              >
                {' '}
                Back: Personal Details
              </Button>
              <Button type="submit" variant="primary">
                {isCreateLoanInformationLoading ||
                isUpdateLoanInformationLoading ? (
                  <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                    <Loader loaderText="Saving" FullScreen={false} />
                  </div>
                ) : (
                  'Next: Employment and income'
                )}
              </Button>
            </div>
          </form>
        </div>
      </FormWrapper>
    </div>
  );
};

export default LoanInformation;
