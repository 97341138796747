import React, { useState, useEffect } from 'react';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import {
  domain,
  audience,
  loan_officer_app_client_id,
  consumer_app_client_id,
  roles,
} from 'constants/constant.js';
import 'react-datepicker/dist/react-datepicker.css';
import * as Sentry from '@sentry/react';
import { sentry_dsn } from './constants/constant.js';
import { ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/toast';
import 'react-toastify/dist/ReactToastify.css';
import consumerRoutes from 'routes/pages/consumer';
import officerRoutes from 'routes/pages/loan-officer';
import authRoutes from 'routes/pages/auth';
import organizationRoutes from 'routes/pages/organization';
import { createBrowserRouter } from 'react-router-dom';
import { setUserRole, userRoleData } from 'redux_store/slices/userRole';
import { useDispatch, useSelector } from 'react-redux';
//icons
import angleDownWhite from 'assets/icons/angleDownWhite.svg';
import fundmoreai from 'assets/fundmoreai2.png'
//components
import ConsumerCard from 'wrapper/login-page/consumer-card';
import LoanOfficerCard from 'wrapper/login-page/loan-officer-card';

import PersonalInfoCard from 'wrapper/personal-info';
import VerifyEmail from 'wrapper/verify-email';
import VerifyPassword from 'pages/verify-password';
import usePageTitle from 'constants/PageTitle';
Sentry.init({
  dsn: sentry_dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

//used to update title of web app as per DEV, UAT and production build
document.title = import.meta.env.VITE_APP_TITLE;

const AppRouter = ({ isRoute }) => {
  const role = useSelector(userRoleData);
  const [router, setRouter] = useState(createBrowserRouter([...authRoutes]));

  useEffect(() => {
    if (role?.userRole === roles.consumer) {
      setRouter(createBrowserRouter([...consumerRoutes]));
    } else if (role?.userRole === roles.loanOfficer) {
      setRouter(createBrowserRouter([...officerRoutes]));
    } else if (role?.userRole === roles.organization) {
      setRouter(createBrowserRouter([...organizationRoutes]));
    }
  }, [role]);

  return isRoute ? <RouterProvider router={router} /> : <></>;
};

const App = () => {
  usePageTitle('');
  const dispatch = useDispatch();
  const [hoverStates, setHoverStates] = useState({
    consumer: false,
    loanOfficer: false,
  });
  const [selection, setSelection] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState('');

  //extract variables from search params
  const urlSearchString = window.location.search;
  const searchParams = new URLSearchParams(urlSearchString);
  const userId = searchParams.get('user_id');
  const code = searchParams.get('code');
  const email = searchParams.get('email');

  const handleHover = (type, isHovering) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [type]: isHovering,
    }));
  };

  useEffect(() => {}, []);
  const role = useSelector(userRoleData);

  const getWidthAndHeight = () => {
    if (role?.userRole || isOneOfThePaths()) {
      return 'w-[100vw] h-[100vh]';
    }
    if (!selection) {
      return 'w-[483px] h-[383px] justify-center';
    }
    return 'w-[645px] h-[447px]';
  };

  const pathNames = [
    '/verified',
    '/result_page',
    '/personal-info',
    '/verification-email',
    '/session-timeout',
    '/auth0-callback-v2',
    '/auth0-callback',
    '/setup-password',
  ];

  const isOneOfThePaths = () => {
    if (pathNames.includes(window.location.pathname)) {
      !selection && setSelection(true);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (window.location.pathname === '/auth0-callback-v2') {
      dispatch(setUserRole('consumer'));
    }
    if (window.location.pathname === '/auth0-callback') {
      dispatch(setUserRole('loanOfficer'));
    }
    isOneOfThePaths();
  }, []);

  if (
    location?.pathname === '/auth0-callback-v2/verification-email' ||
    location?.pathname === '/auth0-callback/verification-email'
  ) {
    return <VerifyEmail />;
  }

  if (
    location?.pathname === '/auth0-callback-v2/personal-info' ||
    location?.pathname === '/auth0-callback/personal-info'
  ) {
    return <PersonalInfoCard />;
  }

  if (location?.pathname === '/setup-password') {
    return <VerifyPassword />;
  }

  const locationPathName = ['/session-timeout', '/verified', '/result_page'];

  const checkUrlforSetupPassword = () => {
    if (userId && code && email) {
      return false;
    }
    if (code) {
      return false;
    }

    return true;
  };

  // if (location?.pathname === '/session-timeout') {
  //   return <SessionExpire />;
  // }

  return (
    <div
      className="bg-cover bg-center h-screen flex justify-center items-center"
      style={{ backgroundImage: 'url("/mainbg.png")' }}
    >
      <div
        className={`w-[100vw] h-[100vh] justify-center ${getWidthAndHeight()} tertiary-bg rounded-lg flex flex-col items-center`}
      >
        {!role?.userRole && (
          <RouterProvider router={createBrowserRouter([...authRoutes])} />
        )}
        {!selection && checkUrlforSetupPassword() && (
          <>
            <img
              src={fundmoreai}
              alt=""
              className="h-28"
              style={{width: '250px', height: '65px'}}
            />
            <h3 className="h3-b text-secondary mt-2 mb-2">Welcome to Fundmore!</h3>
            {/* <h3 className="h3-b text-secondary mt-2 mb-2">Welcome to Fundmore!</h3> */}
            <p className="p-sm-regular text-secondary">
            Loan services powered by AI.
            </p>
            <button
              className="btn-primary p-sm-bold text-tertiary mt-12 flex flex-row gap-2"
              // onClick={handleRedirect}
              style={{background: '#00B23C'}}
              onClick={() => setSelection(true)}
            >
              Let's Begin
              <div className="h-[100%] flex flex-col justify-end">
                <img src={angleDownWhite} className="-rotate-90" alt="" />
              </div>
            </button>
          </>
        )}
        {(selection || role?.userRole) && (
          <>
            {' '}
            {!role?.userRole && !isOneOfThePaths() && (
              <>
                <div className="bg-custom-primary300 w-full flex justify-center rounded-t-lg">
                  <img
                    src={fundmoreai}
                    alt=""
                    className="h-28"
                    style={{width: "219", height: '65px'}}
                  />
                </div>
                <div className="h3-b mb-16 mt-8">Choose your account type?</div>
              </>
            )}
            <div className="flex gap-20">
              <Auth0Provider
                domain={domain}
                clientId={consumer_app_client_id}
                authorizationParams={{
                  redirect_uri: `${window.location.origin}/auth0-callback-v2`,
                  audience: audience,
                  // ...(type === 'consumer' ? {} : { admin: true }),
                }}
                skipRedirectCallback={
                  window.location.pathname !== '/auth0-callback-v2'
                }
              >
                {!role?.userRole &&
                  !locationPathName.includes(window.location?.pathname) && (
                    <ConsumerCard
                      selectedUserRole={selectedUserRole}
                      setSelectedUserRole={setSelectedUserRole}
                      hoverStates={hoverStates}
                      handleHover={handleHover}
                    />
                  )}
                {!locationPathName.some((path) =>
                  location.pathname.startsWith(path)
                ) && <AppRouter isRoute={role?.userRole === roles.consumer} />}
              </Auth0Provider>
              <Auth0Provider
                domain={domain}
                clientId={loan_officer_app_client_id}
                authorizationParams={{
                  redirect_uri: `${window.location.origin}/auth0-callback`,
                  audience: audience,
                  // ...(type === 'consumer' ? {} : { admin: true }),
                }}
                skipRedirectCallback={
                  window.location.pathname !== '/auth0-callback'
                }
              >
                {!role?.userRole &&
                  !locationPathName.includes(window.location?.pathname) && (
                    <LoanOfficerCard
                      selectedUserRole={selectedUserRole}
                      setSelectedUserRole={setSelectedUserRole}
                      hoverStates={hoverStates}
                      handleHover={handleHover}
                    />
                  )}
                {!locationPathName.some((path) =>
                  location.pathname.startsWith(path)
                ) && (
                  <AppRouter
                    isRoute={
                      role?.userRole === roles?.loanOfficer ||
                      roles.organization
                    }
                  />
                )}
              </Auth0Provider>
            </div>
          </>
        )}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        closeButton={CloseButton}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default App;
