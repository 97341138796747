import react from 'react';
//components
import oneClickTableData from './oneClickTableData';
import Button from 'components/button';
//libs
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
const OneClickAus = ({setPage}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');

  const oneClickAUSButton = () => {
    setPage(5);
  };
  return (
    <>
      <div className="h-[75vh] w-[100%] flex flex-col py-3 overflow-y-scroll overflow-x-scroll">
        <table>
          <motion.tr className="w-[100%] p-3 bg-chatbot_bg">
            <td className="w-[33%] p-3">
              <p className="mx-2 p-xs-bold">AUS Result</p>
            </td>
            <td className="w-[33%] p-3">
              <p className="mx-2 p-xs-med">Desktop Underwriting</p>
            </td>
            <td className="w-[33%] p-3">
              <p className="mx-2 p-xs-med">Loan Product Advisor</p>
            </td>
          </motion.tr>
          {oneClickTableData?.map((item, index) => {
            return (
              <motion.tr
                initial={{
                  y: -10,
                }}
                animate={{
                  x: 0,
                  y: 0,
                  scale: 1,
                  rotate: 0,
                }}
                className="w-[100%] px-3 border-t border-t-secondary100"
                key={index}
              >
                <td className="w-[33%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.ausResult}</p>
                </td>
                <td className="w-[33%] p-3 p-xs-med">
                  {item?.link ? (
                    <p className="mx-2 text-primary500 p-xs-med">
                      {item.desktopUnderwriting}
                    </p>
                  ) : (
                    <p className="mx-2 p-xs-med">{item.desktopUnderwriting}</p>
                  )}
                </td>
                <td className="w-[33%] p-3 p-xs-med">
                  {item?.link ? (
                    <p className="mx-2 text-primary500 p-xs-med">
                      {item.loanProductAdvisor}
                    </p>
                  ) : (
                    <p className="mx-2">{item.loanProductAdvisor}</p>
                  )}
                </td>
              </motion.tr>
            );
          })}
        </table>
        <div className="h-fit w-[100%] flex flex-row justify-end p-3 mb-3 border-b border-b-secondary100">
          <div className="h-[100%] flex flex-row justify-end gap-3">
            <Button
              variant="white_sm"
              onClick={oneClickAUSButton}
              type="button"
              btnClassName={`border border-primary500 text-primary500 rounded-md active:bg-primary500 active:text-white`}
            >
              Re-Try One-Click AUS
            </Button>
            <Button
              variant="tertiary_sm"
              //onClick={handleEditDetails}
              type="button"
              disabled={true}
              btnClassName={`rounded-md cursor-not-allowed`}
            >
              Select DU
            </Button>
            <Button
              variant="tertiary_sm"
              //onClick={handleEditDetails}
              type="button"
              disabled={true}
              btnClassName={`rounded-md cursor-not-allowed`}
            >
              Select LPA
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OneClickAus;
