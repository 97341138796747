/* istanbul ignore file */
import { checkTextInput, withValidations } from '../utils';

// form fields for liabilities
export const liabilitiesDataInfo = [
  {
    id: 1,
    name: 'Owned by',
    type: 'text',
    placeholder: 'Enter Owned by',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'ownedBy',
    validator: checkTextInput,
  },
  {
    id: 2,
    name: "Creditor's Name",
    type: 'text',
    placeholder: "Enter Creditor's Name",
    customClass: 'w-1/3',
    isRequired: false,

    propertyName: 'creditorName',
    validator: checkTextInput,
  },
  {
    id: 3,
    name: 'Liability Type',
    type: 'select',
    placeholder: 'Select Liability Type',
    customClass: 'w-1/3',
    isRequired: false,

    propertyName: 'liabilityTypeId',
  },
  {
    id: 4,
    name: 'Account Number',
    type: 'number',
    placeholder: 'Enter Account Number',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'accountNumber',
  },
  {
    id: 5,
    name: 'Total Balance',
    type: 'number',
    placeholder: 'Enter Total Balance',
    customClass: 'w-1/3',
    isRequired: false,

    propertyName: 'totalBalance',
  },
  {
    id: 6,
    name: 'Monthly Payment',
    type: 'number',
    placeholder: 'Enter Monthly Payment',
    customClass: 'w-1/3',
    isRequired: false,

    propertyName: 'monthlyPayment',
  },
  {
    id: 7,
    name: 'Remaining Months',
    type: 'number',
    placeholder: 'Enter Remaining Months',
    customClass: 'w-1/3',
    isRequired: false,

    propertyName: 'remainingMonths',
  },
  {
    id: 8,
    type: 'container',
    customClass: 'w-2/3',
    formFields: [],
  },
  {
    id: 9,
    name: 'Exclude',
    type: 'radioBox-option',
    placeholder: 'Exclude',
    customClass: 'w-1/3 px-2',
    isRequired: false,

    options: [
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
    propertyName: 'isExclude',
  },
  {
    id: 10,
    type: 'container',
    customClass: 'w-2/3',
    formFields: [],
  },
  {
    id: 11,
    name: 'Payoff required?',
    type: 'checkbox-right',
    placeholder: 'Exclude',
    customClass: 'w-1/5 px-2',
    isRequired: (_, additionalInfo) => {
      return false;
      return additionalInfo?.loan_details?.loanPurposeId === 2;
    },
    propertyName: 'isPayoffRequired',
  },
  {
    id: 12,
    name: 'Include Balance',
    type: 'checkbox-right',
    placeholder: 'Exclude',
    customClass: 'w-1/5 px-2',
    isRequired: false,
    propertyName: 'isIncludeBalance',
  },
  {
    id: 13,
    name: 'Include Payment',
    type: 'checkbox-right',
    placeholder: 'Exclude',
    customClass: 'w-1/5 px-2',
    isRequired: false,
    propertyName: 'isIncludePayment',
  },
];

export const liabilitiesData = withValidations(liabilitiesDataInfo);
