import React from 'react';
import './index.css';

const ToggleButton = ({ checked, onChange, disabled, className }) => {
  return (
    <div>
      <label className={`switch ${className}`}>
        <input 
          type="checkbox" 
          checked={checked} 
          onChange={onChange} 
          disabled={disabled}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToggleButton;

