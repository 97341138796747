import sessionExpire from 'assets/icons/sessionExpire.svg';
import angelsRightWhite from 'assets/icons/angelsRightWhite.svg';
const SessionExpire = () => {
  const onSubmitButton = () => {
    window.location.href = window.location.origin;
  };
  return (
    <>
      <div
        className="bg-cover bg-center w-[100vw] h-screen flex flex-col justify-center items-center"
        style={{ backgroundImage: 'url("/mainbg.png")' }}
      >
        <div className="flex flex-col justify-between pt-5 overflow-hidden gap-3 w-[30vw] h-[45%] bg-white rounded-xl">
          <img
            src={sessionExpire}
            alt="session expire"
            className="self-center"
          />
          <h1 className="self-center h5-b mt-3">Your Session has expired</h1>
          <p className="font-medium self-center w-[90%] text-center text-secondary900">
            Your session has expired due to inactivity. Please log in again to
            continue.
          </p>
          <button
            className="btn-primary p-sm-bold h-[65px] text-tertiary w-[110%] flex flex-col justify-center self-center "
            onClick={onSubmitButton}
          >
            <div className="self-center flex flex-row gap-2">
              Log in Back
              <img src={angelsRightWhite} alt="" />
            </div>
          </button>{' '}
        </div>
      </div>
    </>
  );
};
export default SessionExpire;
