import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const notificationsAPI = createApi({
  reducerPath: 'notifications',
  tagTypes: ['fetch-notifications'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: ({ showUnread }) => {
        return {
          url: `loan/notification/?page=1&page_size=100&is_unread=${showUnread}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['fetch-notifications'],
    }),

    updateNotifications: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/notification/`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['fetch-notifications'],
    }),
  }),
});

export const { useGetNotificationsQuery, useUpdateNotificationsMutation } =
  notificationsAPI;
