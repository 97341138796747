import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//admin API'S

export const officerLoanInformationAPI = createApi({
  reducerPath: 'loanInformation',
  tagTypes: ['updateLoanInfo'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    // add leads
    addLeads: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/lead`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['updateLoanInfo'],
    }),
    //get loan officer list
    getLoanOfficers: builder.query({
      query: () => {
        return {
          url: `/user/loan_officers/`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get leads table
    getAllLeads: builder.query({
      query: ({ data, paging, searchFilter }) => {
        return {
          url: `/loan/dashboard/lead_info?page_size=${paging?.limit}&page=${paging?.offset}&search=${searchFilter}`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
      providesTags: ['updateLoanInfo'],
    }),
    //get leads detail
    getLeadDetails: builder.query({
      query: ({ user_id, application_id }) => {
        return {
          url: `/loan/dashboard/view_lead_details?user_id=${user_id}&application_id=${application_id}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    getDashboardInformation: builder.query({
      query: () => {
        return {
          url: `loan/dashboard/dashboard_info`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
  }),
});

export const {
  useAddLeadsMutation,
  useGetLoanOfficersQuery,
  useGetAllLeadsQuery,
  useLazyGetLeadDetailsQuery,
  useGetDashboardInformationQuery,
} = officerLoanInformationAPI;
