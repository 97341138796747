import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
//components
import Button from 'components/button/index';
import InputGroup from 'components/input-group';
import Select from 'react-select';
import { bgColors, textColors } from '../../../themes/colors';
import DatePicker from 'react-datepicker';
//RTK query
import { useGetUserFeesWorksheetQuery } from 'redux_store/services/admin/feesworksheet';

//fees data
import {
  addObjectToChildren,
  initializeInputValues,
  mixBothData,
  removeObjectFromChildren,
  updateCombinedArray,
  restructureData,
  calculateQuotationValues,
} from 'pages/borrower-profile/fees-worksheet/formData/feesWorksheet';

//utils
import { v4 as uuidv4 } from 'uuid';
import { formatNumberIntoCurrency } from 'constants/formatNumber';

//icons
import expandVector from 'assets/icons/expandVector.svg';
import shrinkVector from 'assets/icons/shrinkVector.svg';

const ClosingCostForm = ({
  editFees,
  setUpdatedFeeJson,
  length,
  calculateQuotationUpdate,
  quoteLoanAmount,
  quoteRate,
  setDidFeesRender,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');

  // States
  const [combinedData, setCombinedData] = useState([]);
  const [closingDate, setClosingDate] = useState({
    date: '',
    uniqueId: '',
  });
  const [firstPaymentDate, setFirstPaymentDate] = useState({
    date: '',
    uniqueId: '',
  });
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [childrenIndices, setChildrenIndices] = useState([]);
  const [updatedFeeJsonTotals, setUpdatedFeeJsonTotals] = useState([]);
  const [tableCellValues, setTableCellValues] = useState({});

  //calling RTK query
  const {
    data: feesWorksheetData,
    error: errorMessage,
    isLoading: isFeesworkSheetLoading,
  } = useGetUserFeesWorksheetQuery(
    {
      application_Id: applicationId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (feesWorksheetData?.data) {
      setDidFeesRender(true);
      const combineBothData = mixBothData(
        feesWorksheetData?.data?.fees_json ?? []
      );

      let customInputData = combineBothData.map((data) => ({
        ...data,
        children:
          data.children?.map((child) => ({
            ...child,
            chargeDropdown: child.chargeDropdown ?? true, // Set chargeDropdown to true if it doesn't exist
          })) || [],
      }));
      combineBothData.forEach((data) => {
        if (data?.children[data?.children?.length - 1]?.name) {
          customInputData = addObjectToChildren(
            customInputData,
            data?.uniqueId,
            {
              uniqueId: uuidv4(),
              parentId: 0,
              name: '',
              isCollapsible: false,
              children: [],
              type: 'custom',
              borrowerPaidAtClosingValue: 0,
              borrowerPaidBeforeClosingValue: 0,
              otherPaidAtClosingValue: 0,
              otherPaidBeforeClosingValue: 0,
              creditType: 1,
              chargeDropdown: true,
            }
          );
        }
      });
      const inputValues = initializeInputValues(customInputData);
      setTableCellValues(inputValues);
      setCombinedData(customInputData);
    } else {
      setDidFeesRender(false);
    }
  }, [feesWorksheetData, errorMessage]);

  const credChargeOptions = [
    { id: 1, name: 'Charge' },
    { id: 2, name: 'Credit' },
  ];

  const onChangeTableCell = (uniqueId, propertyName, value, type) => {
    setTableCellValues({
      ...tableCellValues,
      [uniqueId]: {
        ...tableCellValues[uniqueId],
        [propertyName]: type === 'text' ? value : parseFloat(value),
      },
    });
  };

  // modify section D to have totals of A+B+C
  function updateLoanCosts(data) {
    return data?.map((section, index) => {
      // If the section is "D. Total Loan Costs(A+B+C)"
      if (section.heading === 'D. Total Loan Costs(A+B+C)') {
        const total = data[0].total + data[1].total + data[2].total;
        return { heading: section.heading, total: total, fields: [] };
      }

      // For all other sections, return the section unchanged
      return section;
    });
  }
  useEffect(() => {
    const fees_json = updateCombinedArray(combinedData, tableCellValues);
    const formattedData = restructureData(fees_json ?? []);
    const updatedData = updateLoanCosts(formattedData);

    setUpdatedFeeJson(updatedData);
    const quotationValues = calculateQuotationValues(fees_json, formattedData);
    calculateQuotationUpdate(quotationValues);
  }, [combinedData, tableCellValues]);

  // calculate date difference
  const calculateDifferenceInDays = (date1, date2) => {
    if (!date1 || !date2) return 0; // Return 0 if either date is invalid
    const timeDiff = date2.getTime() - date1.getTime();

    // Check if timeDiff is NaN or less than zero, return 0 if true
    if (isNaN(timeDiff)) return 0;

    return Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
  };

  // calculate prepaid interest
  useEffect(() => {
    const closingDateObj = new Date(closingDate?.date);
    const firstPaymentDateObj = new Date(firstPaymentDate?.date);
    const days = calculateDifferenceInDays(closingDateObj, firstPaymentDateObj);

    // Calculate the daily interest rate
    const dailyInterestRate = quoteRate / 100 / 365;
    // Calculate the prepaid interest for the number of days
    const prepaidInterest = quoteLoanAmount * dailyInterestRate * days;
    setNumberOfDays(days);
    setTableCellValues((prevValues) => ({
      ...prevValues,
      [closingDate?.uniqueId || firstPaymentDate.uniqueId]: {
        ...prevValues[closingDate?.uniqueId || firstPaymentDate.uniqueId],
        borrowerPaidAtClosingValue: Math.abs(prepaidInterest),
        borrowerPaidBeforeClosingValue: 0,
        otherPaidAtClosingValue: 0,
        otherPaidBeforeClosingValue: 0,
        creditType: prepaidInterest > 0 ? 1 : 2,
        numberOfDays: days,
        estimatedClosing: closingDate.date,
        firstPayment: firstPaymentDate.date,
      },
    }));
  }, [closingDate.date, firstPaymentDate.date]);

  // get values based on percentage
  const calculateLoanAmountPercent = (inputValue, uniqueId) => {
    const percentageValue = (inputValue / 100) * quoteLoanAmount;

    setTableCellValues((prevValues) => {
      return {
        ...prevValues,
        [uniqueId]: {
          ...prevValues[uniqueId],
          borrowerPaidAtClosingValue: percentageValue,
          borrowerPaidBeforeClosingValue: 0,
          otherPaidAtClosingValue: 0,
          otherPaidBeforeClosingValue: 0,
          percentageValue: inputValue,
        },
      };
    });
  };

  // calculate totals

  useEffect(() => {
    const fees_json = updateCombinedArray(combinedData, tableCellValues);
    const formattedData = restructureData(fees_json ?? []);
    setUpdatedFeeJsonTotals(formattedData);
  }, [combinedData, tableCellValues]);

  const form = (
    <>
      <div className="bg-white self-center rounded-md flex flex-col justify-between  gap-2">
        <div className="h-[86%] w-[100%] overflow-y-scroll">
          {combinedData &&
            combinedData
              ?.filter((_, index) => index !== 3)
              ?.map((parent, Pindex) => {
                const totalAmount = updatedFeeJsonTotals?.find(
                  (p) => p.heading === parent.name
                );
                const sumTotalofD =
                  updatedFeeJsonTotals[0]?.total +
                  updatedFeeJsonTotals[1]?.total +
                  updatedFeeJsonTotals[2]?.total;

                return (
                  <>
                    {Pindex === 3 && (
                      <div
                        className="h-fit w-[100%] flex flex-col justify-between py-3 px-3 bg-chatbot_bg cursor-pointer my-2"
                        key={Pindex}
                      >
                        <div className="flex flex-row items-center gap-3">
                          <img src={shrinkVector} alt="" />

                          <h1 className="p-xs-bold">
                            D. Total Loan Costs (A+B+C):
                          </h1>
                          <span className="p-xs-bold">
                            {formatNumberIntoCurrency(sumTotalofD || 0)}
                          </span>
                        </div>
                      </div>
                    )}
                    <div
                      className="h-fit w-[100%] flex flex-col justify-between py-3 px-3 bg-chatbot_bg cursor-pointer my-2"
                      key={Pindex}
                      onClick={() => {
                        if (childrenIndices.includes(Pindex)) {
                          let filteredIndices = childrenIndices.filter(
                            (item) => {
                              return item !== Pindex;
                            }
                          );
                          setChildrenIndices(filteredIndices);
                        } else {
                          setChildrenIndices((prev) => {
                            return [...prev, Pindex];
                          });
                        }
                      }}
                    >
                      <div className="flex flex-row items-center gap-3">
                        {childrenIndices.includes(Pindex) ? (
                          <img src={shrinkVector} alt="" />
                        ) : (
                          <img src={expandVector} alt="" />
                        )}
                        <h1 className="p-xs-bold">{parent.name}:</h1>
                        <span className="p-xs-bold">
                          {formatNumberIntoCurrency(totalAmount?.total || 0)}
                        </span>
                      </div>
                    </div>
                    {childrenIndices.includes(Pindex) &&
                      parent.children.length > 0 &&
                      parent.children
                        .filter((child) => child.type !== 'custom')
                        .map((child, Cindex) => {
                          return (
                            <>
                              <motion.div
                                initial={{
                                  y: -10,
                                }}
                                animate={{
                                  x: 0,
                                  y: 0,
                                  scale: 1,
                                  rotate: 0,
                                }}
                                className="h-fit w-[100%] flex flex-col justify-between py-2 px-4 bg-white"
                                key={Cindex}
                              >
                                <div className="w-[100%] flex flex-row justify-between border-b border-neutral100 pb-4">
                                  {child.children.length === 0 && (
                                    <div
                                      className={`w-[100%] ${!child?.calendar ? 'flex flex-row ' : ''} justify-start  gap-2 items-start`}
                                    >
                                      {child?.isAPR && (
                                        <p className={`w-fit p-xs-bold`}>
                                          {'APR '}
                                        </p>
                                      )}
                                      <div
                                        className={`${child.focus ? 'p-xs-semibold' : 'p-xs-regular'}`}
                                      >
                                        {child.name}
                                        {child?.percentage && editFees && (
                                          <div className=" flex  items-center gap-2 font-bold">
                                            <InputGroup
                                              size="sm"
                                              position="left"
                                              type="number"
                                              value={
                                                tableCellValues[child?.uniqueId]
                                                  ?.percentageValue || ''
                                              }
                                              // disabled={!editable}
                                              // readOnly={!editable}
                                              placeholder="Enter Percent"
                                              onKeyDown={(e) => {
                                                if (
                                                  e.key === '-' ||
                                                  e.key === '+' ||
                                                  e.key === 'e' ||
                                                  e.key === 'E'
                                                ) {
                                                  e.preventDefault();
                                                }
                                              }}
                                              onChange={(e) => {
                                                calculateLoanAmountPercent(
                                                  e.target.value,
                                                  child.uniqueId
                                                );
                                              }}
                                              style={{
                                                width: `${length === 3 ? '80px' : length === 2 ? '100px' : '150px'}`,
                                              }}
                                            />
                                            %
                                          </div>
                                        )}
                                        {child?.calendar && editFees && (
                                          <>
                                            {' '}
                                            <div className="flex gap-4 mt-3">
                                              <div>
                                                {' '}
                                                <label
                                                  className={`block text-custom-greengray p-xs-bold whitespace-nowrap mb-4 `}
                                                >
                                                  Estimated Closing Date
                                                </label>
                                                <DatePicker
                                                  selected={
                                                    closingDate?.date ||
                                                    tableCellValues[
                                                      child?.uniqueId
                                                    ].estimatedClosing
                                                  }
                                                  wrapperClassName="w-full"
                                                  onChange={(e) =>
                                                    setClosingDate({
                                                      date:
                                                        e ||
                                                        tableCellValues[
                                                          child?.uniqueId
                                                        ].estimatedClosing,
                                                      uniqueId: child.uniqueId,
                                                    })
                                                  }
                                                  dropdownMode={'select'}
                                                  showMonthDropdown={true}
                                                  showYearDropdown={true}
                                                  placeholderText={
                                                    'select date'
                                                  }
                                                  className={`cursor-pointer border-2 w-[6rem] p-xs-regular max-h-[39px] focus:border-2 focus:outline-none rounded-lg text-base px-3 py-1.5 text-black border-primary focus:border-secondary`}
                                                  scrollableYearDropdown
                                                  onKeyDown={(e) => {
                                                    e.preventDefault();
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="flex mt-3">
                                              <div>
                                                {' '}
                                                <label
                                                  className={`block text-custom-greengray p-xs-bold whitespace-nowrap mb-4`}
                                                >
                                                  First Payment
                                                </label>
                                                <DatePicker
                                                  selected={
                                                    firstPaymentDate?.date ||
                                                    tableCellValues[
                                                      child?.uniqueId
                                                    ].firstPayment
                                                  }
                                                  wrapperClassName="w-full"
                                                  onChange={(e) =>
                                                    setFirstPaymentDate({
                                                      date:
                                                        e ||
                                                        tableCellValues[
                                                          child?.uniqueId
                                                        ].firstPayment,
                                                      uniqueId: child.uniqueId,
                                                    })
                                                  }
                                                  dropdownMode={'select'}
                                                  showMonthDropdown={true}
                                                  showYearDropdown={true}
                                                  placeholderText={
                                                    'select date'
                                                  }
                                                  className={`cursor-pointer border-2 w-[6rem] p-xs-regular max-h-[39px] focus:border-2 focus:outline-none rounded-lg text-base px-3 py-1.5 text-black border-primary focus:border-secondary`}
                                                  scrollableYearDropdown
                                                  onKeyDown={(e) => {
                                                    e.preventDefault();
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              {' '}
                                              <label
                                                className={`block text-custom-greengray p-xs-bold text-nowrap mt-3`}
                                              >
                                                Number of days
                                              </label>
                                              <p
                                                className={`block text-custom-greengray p-xs-bold`}
                                              >
                                                {numberOfDays
                                                  ? numberOfDays
                                                  : tableCellValues[
                                                      child?.uniqueId
                                                    ].numberOfDays || 0}
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  <div className="w-[100%] h-[100%] flex flex-col justify-between gap-5 ">
                                    {child.children &&
                                      child.children.map((mChild, Mindex) => {
                                        const isLast =
                                          Mindex === child.children.length - 1;
                                        return (
                                          <>
                                            <div
                                              className={`w-[100%] flex flex-row justify-between  ${
                                                isLast
                                                  ? ''
                                                  : 'pb-4 border-b border-neutral100'
                                              } gap-2`}
                                              key={Mindex + Pindex + Cindex}
                                            >
                                              <div className="w-fit flex flex-col justify-center">
                                                <div className="w-fit flex justify-center">
                                                  {mChild?.isAPR && (
                                                    <p
                                                      className={`w-fit p-xs-bold pr-2`}
                                                    >
                                                      {'APR '}
                                                    </p>
                                                  )}
                                                  <label className="p-xs-regular">
                                                    {mChild.name}
                                                  </label>
                                                </div>
                                                {mChild.chargeDropdown &&
                                                  editFees && (
                                                    <div className="mt-2">
                                                      <Select
                                                        menuShouldScrollIntoView={
                                                          true
                                                        }
                                                        menuShouldBlockScroll={
                                                          true
                                                        }
                                                        menuPlacement="auto"
                                                        menuPosition="fixed"
                                                        name="creditType"
                                                        value={
                                                          credChargeOptions.find(
                                                            (option) =>
                                                              option.id ===
                                                              tableCellValues[
                                                                mChild?.uniqueId
                                                              ]?.creditType
                                                          ) ||
                                                          credChargeOptions[0]
                                                        }
                                                        onChange={(e) => {
                                                          onChangeTableCell(
                                                            mChild?.uniqueId,
                                                            'creditType',
                                                            e.id,
                                                            'text'
                                                          );
                                                        }}
                                                        options={
                                                          credChargeOptions
                                                        }
                                                        getOptionLabel={(
                                                          option
                                                        ) => {
                                                          return option.name;
                                                        }}
                                                        getOptionValue={(
                                                          value
                                                        ) => {
                                                          return value.id;
                                                        }}
                                                        className="w-fit"
                                                        styles={{
                                                          control: (
                                                            provided
                                                          ) => ({
                                                            ...provided,
                                                            fontSize: '12px',
                                                            position:
                                                              'relative',
                                                            fontWeight: '400',
                                                            borderRadius: '5px',
                                                            boxShadow: 'none',
                                                            paddingRight: '9px',
                                                            backgroundColor:
                                                              bgColors.primary50,
                                                            border: 'none',
                                                          }),
                                                          option: (
                                                            provided,
                                                            state
                                                          ) => ({
                                                            ...provided,
                                                            backgroundColor:
                                                              bgColors.tertiary_bg,
                                                            color:
                                                              textColors.black,
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            '&:hover': {
                                                              cursor: 'pointer',
                                                              backgroundColor:
                                                                bgColors.primary50,
                                                            },
                                                            '&:active': {
                                                              backgroundColor:
                                                                bgColors.primary50,
                                                            },
                                                          }),
                                                          menu: (
                                                            provided,
                                                            state
                                                          ) => ({
                                                            ...provided,
                                                            borderRadius: '5px',
                                                            color:
                                                              textColors.black,
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                          }),
                                                          indicatorSeparator:
                                                            () => null,
                                                        }}
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          colors: {
                                                            ...theme.colors,
                                                            neutral80:
                                                              textColors.black,
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  )}
                                              </div>
                                              <div className="mr-9 flex items-center gap-2 font-bold">
                                                {editFees ? (
                                                  <InputGroup
                                                    type={'number'}
                                                    placeholder={'$'}
                                                    value={
                                                      tableCellValues[
                                                        mChild?.uniqueId
                                                      ]
                                                        ?.borrowerPaidAtClosingValue ||
                                                      ''
                                                    }
                                                    name="borrowerPaidAtClosingValue"
                                                    onKeyDown={(e) => {
                                                      if (
                                                        e.key === '-' ||
                                                        e.key === '+' ||
                                                        e.key === 'e' ||
                                                        e.key === 'E'
                                                      ) {
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                    onChange={(e) => {
                                                      onChangeTableCell(
                                                        mChild?.uniqueId,
                                                        e.target.name,
                                                        e.target.value
                                                      );
                                                    }}
                                                    size="sm"
                                                    style={{
                                                      width: `${length === 3 ? '80px' : length === 2 ? '100px' : '150px'}`,
                                                    }}
                                                  />
                                                ) : (
                                                  <label className="p-xs-bold">
                                                    {tableCellValues[
                                                      mChild?.uniqueId
                                                    ]
                                                      ?.borrowerPaidAtClosingValue >
                                                      0 &&
                                                    (mChild?.credit ||
                                                      mChild?.creditType === 2)
                                                      ? '- $'
                                                      : '$'}
                                                    {isNaN(
                                                      tableCellValues[
                                                        mChild?.uniqueId
                                                      ]
                                                        ?.borrowerPaidAtClosingValue
                                                    )
                                                      ? 0
                                                      : tableCellValues[
                                                          mChild?.uniqueId
                                                        ]?.borrowerPaidAtClosingValue?.toFixed(
                                                          3
                                                        )}
                                                  </label>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                  </div>
                                  {child.children.length === 0 && (
                                    <div className="w-[100%] flex flex-row justify-end pr-9 gap-4">
                                      {editFees ? (
                                        <InputGroup
                                          type={'number'}
                                          value={
                                            tableCellValues[child?.uniqueId]
                                              ?.borrowerPaidAtClosingValue || ''
                                          }
                                          name="borrowerPaidAtClosingValue"
                                          onChange={(e) => {
                                            onChangeTableCell(
                                              child?.uniqueId,
                                              e.target.name,
                                              e.target.value
                                            );
                                          }}
                                          onKeyDown={(e) => {
                                            if (
                                              child?.percentage ||
                                              child.calendar
                                            ) {
                                              e.preventDefault();
                                            } else if (
                                              e.key === '-' ||
                                              e.key === '+' ||
                                              e.key === 'e' ||
                                              e.key === 'E'
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                          placeholder={'$'}
                                          size="sm"
                                          style={{
                                            width: `${length === 3 ? '80px' : length === 2 ? '100px' : '150px'}`,
                                          }}
                                        />
                                      ) : (
                                        <label className="p-xs-bold">
                                          {tableCellValues[child?.uniqueId]
                                            ?.borrowerPaidAtClosingValue > 0 &&
                                          (child?.credit ||
                                            child?.creditType === 2)
                                            ? '- $'
                                            : '$'}
                                          {isNaN(
                                            tableCellValues[child?.uniqueId]
                                              ?.borrowerPaidAtClosingValue
                                          )
                                            ? 0
                                            : tableCellValues[
                                                child?.uniqueId
                                              ]?.borrowerPaidAtClosingValue?.toFixed(
                                                3
                                              )}
                                        </label>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </motion.div>
                            </>
                          );
                        })}
                    {childrenIndices.includes(Pindex) &&
                      parent.children.length > 0 &&
                      parent.children
                        .filter(
                          (child) =>
                            child.type === 'custom' &&
                            (editFees || tableCellValues[child?.uniqueId]?.name)
                        )
                        .map((child, Cindex, childArray) => {
                          return (
                            <>
                              {' '}
                              <div className="w-[100%] flex flex-row justify-between mt-4 border-b border-neutral100 pb-3">
                                <div className="w-[50%] flex flex-row justify-around gap-3">
                                  {editFees ? (
                                    <InputGroup
                                      type={'text'}
                                      value={
                                        tableCellValues[child?.uniqueId]
                                          ?.name || ''
                                      }
                                      placeholder={'Provide Fee type'}
                                      name="name"
                                      onChange={(e) => {
                                        onChangeTableCell(
                                          child?.uniqueId,
                                          e.target.name,
                                          e.target.value,
                                          'text'
                                        );
                                      }}
                                      style={{
                                        width: `${length === 3 ? '80px' : length === 2 ? '120px' : '150px'}`,
                                      }}
                                      size="sm"
                                    />
                                  ) : (
                                    <label className="p-xs-regular">
                                      {tableCellValues[child?.uniqueId]?.name ||
                                        ''}
                                    </label>
                                  )}
                                  <Select
                                    name="creditType"
                                    value={
                                      credChargeOptions.find(
                                        (option) =>
                                          option.id ===
                                          tableCellValues[child?.uniqueId]
                                            ?.creditType
                                      ) || credChargeOptions[0]
                                    }
                                    onChange={(e) => {
                                      onChangeTableCell(
                                        child?.uniqueId,
                                        'creditType',
                                        e.id,
                                        'text'
                                      );
                                    }}
                                    isDisabled={!editFees}
                                    options={credChargeOptions}
                                    getOptionLabel={(option) => {
                                      return option.name;
                                    }}
                                    getOptionValue={(value) => {
                                      return value.id;
                                    }}
                                    className="w-fit"
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        fontSize: '12px',
                                        position: 'relative',
                                        fontWeight: '400',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                        paddingRight: '9px',
                                        backgroundColor: bgColors.primary50,
                                        border: 'none',
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: bgColors.tertiary_bg,
                                        color: textColors.black,
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        '&:hover': {
                                          cursor: 'pointer',
                                          backgroundColor: bgColors.primary50,
                                        },
                                        '&:active': {
                                          backgroundColor: bgColors.primary50,
                                        },
                                      }),
                                      menu: (provided, state) => ({
                                        ...provided,
                                        borderRadius: '5px',
                                        color: textColors.black,
                                        fontSize: '12px',
                                        fontWeight: '400',
                                      }),
                                      indicatorSeparator: () => null,
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        neutral80: textColors.black,
                                      },
                                    })}
                                  />
                                </div>
                                <div className="w-[50%] flex flex-row justify-end  gap-4">
                                  {editFees ? (
                                    <InputGroup
                                      placeholder={'$'}
                                      value={
                                        tableCellValues[child?.uniqueId]
                                          ?.borrowerPaidAtClosingValue || ''
                                      }
                                      name="borrowerPaidAtClosingValue"
                                      type="number"
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === '-' ||
                                          e.key === '+' ||
                                          e.key === 'e' ||
                                          e.key === 'E'
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        onChangeTableCell(
                                          child?.uniqueId,
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                      size="sm"
                                      style={{
                                        width: `${length === 3 ? '80px' : length === 2 ? '100px' : '150px'}`,
                                      }}
                                    />
                                  ) : (
                                    <label className="p-xs-bold">
                                      {tableCellValues[child?.uniqueId]
                                        ?.borrowerPaidAtClosingValue > 0 &&
                                      (child?.credit || child?.creditType === 2)
                                        ? '- $'
                                        : '$'}
                                      {isNaN(
                                        tableCellValues[child?.uniqueId]
                                          ?.borrowerPaidAtClosingValue
                                      )
                                        ? 0
                                        : tableCellValues[
                                            child?.uniqueId
                                          ]?.borrowerPaidAtClosingValue?.toFixed(
                                            3
                                          )}
                                    </label>
                                  )}
                                  <div className="flex flex-col justify-center h-[35px] w-[35px]">
                                    {Cindex !== childArray.length - 1 &&
                                      editFees && (
                                        <Button
                                          variant="link"
                                          style={{ padding: 0 }}
                                          btnClassName="w-[10%] p-0"
                                          onClick={() => {
                                            setCombinedData([
                                              ...removeObjectFromChildren(
                                                combinedData,
                                                parent?.uniqueId,
                                                child?.uniqueId
                                              ),
                                            ]);
                                          }}
                                        >
                                          <svg
                                            width="14"
                                            height="16"
                                            viewBox="0 0 14 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M5.32812 1.6125L4.73438 2.5H9.26562L8.67188 1.6125C8.625 1.54375 8.54688 1.5 8.4625 1.5H5.53438C5.45 1.5 5.37187 1.54062 5.325 1.6125H5.32812ZM9.92188 0.78125L11.0688 2.5H11.5H13H13.25C13.6656 2.5 14 2.83437 14 3.25C14 3.66563 13.6656 4 13.25 4H13V13.5C13 14.8813 11.8813 16 10.5 16H3.5C2.11875 16 1 14.8813 1 13.5V4H0.75C0.334375 4 0 3.66563 0 3.25C0 2.83437 0.334375 2.5 0.75 2.5H1H2.5H2.93125L4.07812 0.778125C4.40312 0.29375 4.95 0 5.53438 0H8.4625C9.04688 0 9.59375 0.29375 9.91875 0.778125L9.92188 0.78125ZM2.5 4V13.5C2.5 14.0531 2.94688 14.5 3.5 14.5H10.5C11.0531 14.5 11.5 14.0531 11.5 13.5V4H2.5ZM5 6V12.5C5 12.775 4.775 13 4.5 13C4.225 13 4 12.775 4 12.5V6C4 5.725 4.225 5.5 4.5 5.5C4.775 5.5 5 5.725 5 6ZM7.5 6V12.5C7.5 12.775 7.275 13 7 13C6.725 13 6.5 12.775 6.5 12.5V6C6.5 5.725 6.725 5.5 7 5.5C7.275 5.5 7.5 5.725 7.5 6ZM10 6V12.5C10 12.775 9.775 13 9.5 13C9.225 13 9 12.775 9 12.5V6C9 5.725 9.225 5.5 9.5 5.5C9.775 5.5 10 5.725 10 6Z"
                                              fill="#5A5555"
                                            />
                                          </svg>
                                        </Button>
                                      )}
                                    {Cindex === childArray.length - 1 &&
                                      editFees && (
                                        <Button
                                          variant="link"
                                          style={{ padding: 0 }}
                                          btnClassName="w-[10%] p-0"
                                          onClick={() => {
                                            setCombinedData([
                                              ...addObjectToChildren(
                                                combinedData,
                                                parent?.uniqueId,
                                                {
                                                  uniqueId: uuidv4(),
                                                  parentId: 0,
                                                  name: '',
                                                  isCollapsible: false,
                                                  children: [],
                                                  type: 'custom',
                                                  borrowerPaidAtClosingValue:
                                                    '-',
                                                  borrowerPaidBeforeClosingValue:
                                                    '-',
                                                  otherPaidAtClosingValue: '-',
                                                  otherPaidBeforeClosingValue:
                                                    '-',
                                                }
                                              ),
                                            ]);
                                          }}
                                        >
                                          <svg
                                            width="15"
                                            height="15"
                                            viewBox="0 0 100 100"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <line
                                              x1="10"
                                              y1="50"
                                              x2="90"
                                              y2="50"
                                              stroke="black"
                                              stroke-width="10"
                                            />
                                            <line
                                              x1="50"
                                              y1="10"
                                              x2="50"
                                              y2="90"
                                              stroke="black"
                                              stroke-width="10"
                                            />
                                          </svg>
                                        </Button>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                  </>
                );
              })}
        </div>

        {/* <div className="h-fit w-[100%] flex flex-row justify-end">
          <div className="h-[100%] flex flex-row justify-end gap-3">
            <Button
              variant="primary_sm"
              onClick={saveFeesWorksheetData}
              type="button"
            >
              Apply
            </Button>
          </div>
        </div> */}
      </div>
    </>
  );
  return <div>{form}</div>;
};

export default ClosingCostForm;
