import { useEffect, useState } from 'react';
//components
import H1 from 'components/h1-typography';
import Button from 'components/button';
import Loader from 'wrapper/loader/index.jsx';
import Modal from 'components/modal/index.jsx';
//RTK query
import {
  useGetAmortizationScheduleConsumerQuery,
  useGetAmortizationScheduleQuery,
} from 'redux_store/services/admin/adminTools.js';
//child components
import LoanProgram from './tableChildComponents/LoanProgram.jsx';
import RateAdjustment from './tableChildComponents/RateAdjustment.jsx';
import PaymentAdjustment from './tableChildComponents/PaymentAdjustment.jsx';
import LoanClosure from './tableChildComponents/LoanClosure.jsx';
import amortiizationTableData from './amortizationTableData.js';
import ToolsErrorDisplay from '../toolsErrorDisplay/index.jsx';
import { roles } from 'constants/constant.js';
import { format, parse } from 'date-fns';

import {
  formatNumberIntoCurrency,
  formatNumberIntoPercentage,
} from 'constants/formatNumber.js';
//icons
import closeIcon from 'assets/icons/closeIconSlim.svg';

const Amortization = ({ applicationId, role }) => {
  const tableHeadings = [
    'No',
    'Pmt Date',
    'Int Rate',
    'Monthly Payment',
    'Principal Paid',
    'Interest Paid',
    'Mtg Insurance',
    'Balance',
  ];
  let tempArray = amortiizationTableData;
  const [isRateAvailable, setIsRateAvailable] = useState();
  const [loanProgramData, setLoanProgramData] = useState();
  const [rateAdjustmentData, setRateAdjustmentData] = useState();
  const [paymentAdjustmentData, setPaymentAdjustmentData] = useState();
  const [loanClosureData, setLoanClosureData] = useState();
  const [summaryTableData, setSummaryTableData] = useState();
  const [summaryTotalData, setSummaryTotalData] = useState();
  const [yearlyScheduleData, setYearlyScheduleData] = useState();
  const [yearlyScheduleTotalData, setYearlyScheduleTotalData] = useState();
  const [viewAllAmortizationSchedule, setVewAllAmortizationSchedule] =
    useState(false);
  //rtk call
  const {
    currentData: amortizationData,
    isLoading: isAmortizationDataLoading,
    error,
  } = roles?.loanOfficer === role
    ? useGetAmortizationScheduleQuery(
        { applicationId },
        {
          refetchOnMountOrArgChange: true,
        }
      )
    : useGetAmortizationScheduleConsumerQuery(
        { applicationId },
        {
          refetchOnMountOrArgChange: true,
        }
      );
  useEffect(() => {
    if (amortizationData?.success) {
      setIsRateAvailable(true);
      setLoanProgramData(amortizationData?.data?.loan_program);
      setRateAdjustmentData(amortizationData?.data?.rate_adjustment);
      setPaymentAdjustmentData(amortizationData?.data?.payment_adjustment);
      setLoanClosureData(amortizationData?.data?.loan_closure);
      setSummaryTableData(amortizationData?.data?.summary);
      setSummaryTotalData(amortizationData?.data?.summary_total);
      setYearlyScheduleData(amortizationData?.data?.yearly_schedule);
      setYearlyScheduleTotalData(amortizationData?.data?.yearly_schedule_total);
    } else {
      setIsRateAvailable(false);
    }
  }, [amortizationData]);
  const [total, setTotal] = useState({
    monthlyPayment: 939939482,
    principalPaid: 304394434,
    interestPaid: 4394394039,
  });
  const totalAmountMapper = (data) => {
    return (
      <tr className="w-[100%] bg-chatbot_bg">
        <td className="p-xs-med text-center p-3">Total</td>
        <td colSpan={2}></td>
        <td className="p-xs-med text-center p-3">
          {formatNumberIntoCurrency(data?.total_monthly_payment)}
        </td>
        <td className="p-xs-med text-center p-3">
          {formatNumberIntoCurrency(data?.total_principal_paid)}
        </td>
        <td className="p-xs-med text-center p-3">
          {formatNumberIntoCurrency(data?.total_interest_paid)}
        </td>
        <td colSpan={2}></td>
      </tr>
    );
  };
  const handleViewAmortizationSchedule = () => {
    setVewAllAmortizationSchedule(true);
  };
  const allYearlyScheduleData = () => {
    return (
      <>
        <div className="w-[100%] flex flex-col justify-center overflow-y-scroll self-center">
          <div className="flex flex-row justify-between">
            <div className="py-3">
              <H1>Yearly Schedule</H1>
            </div>
            <div className="flex flex-col justify-center">
              <img
                src={closeIcon}
                alt=""
                className="cursor-pointer h-[15px]"
                onClick={() => setVewAllAmortizationSchedule(false)}
              />
            </div>
          </div>
          <table>
            <tr className="w-[100%] bg-chatbot_bg">
              {tableHeadings.map((heading, index) => {
                return (
                  <>
                    <td className="p-xs-bold text-neutral700  py-3 px-[10px] text-center">
                      {heading}
                    </td>
                  </>
                );
              })}
            </tr>
            {yearlyScheduleData?.map((item, index) => {
              return (
                <>
                  {' '}
                  <tr className="w-[100%] hover:bg-primary50">
                    <td className="p-xs-med text-center p-3">{item?.number}</td>
                    <td className="p-xs-med text-center p-3">
                      {getDate(item?.payment_date)}
                    </td>
                    <td className="p-xs-med text-center p-3">
                      {formatNumberIntoPercentage(item?.interest_rate)}
                    </td>
                    <td className="p-xs-med text-center p-3">
                      {formatNumberIntoCurrency(item?.monthly_payment)}
                    </td>
                    <td className="p-xs-med text-center p-3">
                      {formatNumberIntoCurrency(item?.principal_paid)}
                    </td>
                    <td className="p-xs-med text-center p-3">
                      {formatNumberIntoCurrency(item?.interest_paid)}
                    </td>
                    <td className="p-xs-med text-center p-3">
                      {item?.mortgage_insurance}
                    </td>
                    <td className="p-xs-med text-center p-3">
                      {formatNumberIntoCurrency(item?.balance)}
                    </td>
                  </tr>
                </>
              );
            })}
            {totalAmountMapper(yearlyScheduleTotalData)}
          </table>
        </div>
      </>
    );
  };
  const getDate = (inputDate) => {
    if (!inputDate) {
      return '';
    }
    const formattedDate = format(
      parse(inputDate, 'yyyy-MM-dd', new Date()),
      'MM-dd-yyyy'
    );
    return formattedDate;
  };

  return (
    <>
      {isAmortizationDataLoading ? (
        <Loader />
      ) : isRateAvailable ? (
        <>
          <div className="h-[75vh] w-[100%] flex flex-col justify-center">
            <div className="h-[100%] w-[100%] self-center flex flex-col justify-between">
              <div className="h-[90%] w-[100%] flex flex-row gap-3 self-center">
                <div className="h-[100%] w-[30%] border-r-2  overflow-y-scroll border-r-grey ">
                  <div className="mt-3">
                    <H1>Current Offer</H1>
                  </div>
                  <div className="w-[100%] h-fit flex flex-col">
                    {/* Table for Loan program */}
                    <LoanProgram loanProgramData={loanProgramData} />
                    {/* Table for Rate Adjustment */}
                    <RateAdjustment rateAdjustmentData={rateAdjustmentData} />
                    {/* Table for Payment Adjustment */}
                    <PaymentAdjustment
                      paymentAdjustmentData={paymentAdjustmentData}
                    />
                    {/* Table for Loan Closure */}
                    <LoanClosure loanClosureData={loanClosureData} />
                  </div>
                </div>
                <div className="h-[100%] w-[70%] flex flex-col overflow-y-scroll">
                  <div className="py-3">
                    <H1>Summary</H1>
                  </div>
                  <table>
                    <tr className="w-[100%] bg-chatbot_bg">
                      {tableHeadings.map((heading, index) => {
                        return (
                          <>
                            <td
                              className="p-xs-bold text-neutral700 py-3 px-[10px] text-center"
                              key={index}
                            >
                              {heading}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                    {summaryTableData?.map((item, index) => {
                      return (
                        <>
                          <tr className="w-[100%]">
                            <td className="p-xs-med text-center p-3">
                              {item?.number}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {getDate(item?.payment_date)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoPercentage(item?.interest_rate)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(item?.monthly_payment)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(item?.principal_paid)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(item?.interest_paid)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(
                                item?.mortgage_insurance
                              )}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(item?.balance)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    {totalAmountMapper(summaryTotalData)}
                  </table>
                  <div className="py-3">
                    <H1>Yearly Schedule</H1>
                  </div>
                  <table>
                    <tr className="w-[100%] bg-chatbot_bg">
                      {tableHeadings.map((heading, index) => {
                        return (
                          <>
                            <td className="p-xs-bold text-neutral700  py-3 px-[10px] text-center">
                              {heading}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                    {yearlyScheduleData?.slice(0, 8).map((item, index) => {
                      return (
                        <>
                          <tr className="w-[100%]">
                            <td className="p-xs-med text-center p-3">
                              {item?.number}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {getDate(item?.payment_date)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoPercentage(item?.interest_rate)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(item?.monthly_payment)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(item?.principal_paid)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(item?.interest_paid)}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {item?.mortgage_insurance}
                            </td>
                            <td className="p-xs-med text-center p-3">
                              {formatNumberIntoCurrency(item?.balance)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    {totalAmountMapper(yearlyScheduleTotalData)}
                  </table>
                </div>
              </div>
              <div className="h-fit flex flex-col justify-center">
                <div className="flex flex-row justify-end">
                  <Button
                    variant="primary"
                    onClick={handleViewAmortizationSchedule}
                    type="button"
                  >
                    View Amortization Schedule
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={viewAllAmortizationSchedule}
            width={'max-w-[45vw] min-w-[40vw]'}
          >
            <div className="h-fit flex flex-col justify-center p-3">
              {allYearlyScheduleData()}
            </div>
          </Modal>
        </>
      ) : (
        <div className="h-[65vh]">
          <ToolsErrorDisplay selectedTabId={{ selectedTabId: 2 }} />
        </div>
      )}
    </>
  );
};
export default Amortization;
