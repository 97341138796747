import React, { useState, useMemo, useEffect } from 'react';

//components
import Table from 'components/table';
import Breadcrumb from 'components/breadcrumb';
import H1 from 'components/h1-typography';
import Badge from 'components/badge';
import User from 'components/user';
import InputGroup from 'components/input-group';
import Button from 'components/button';
//query
import { useGetPipelineStatusQuery } from 'redux_store/services/client/pipeline';
import {
  useGetAllLeadsQuery,
  useLazyGetLeadDetailsQuery,
} from 'redux_store/services/officer/loanInformation';

//libs
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import usePageTitle from 'constants/PageTitle';
//constants
import { statusVariantMap } from './constants';

//icons
import EditIcon from 'assets/icons/EditIcon';
import ViewIcon from 'assets/icons/ViewIcon';
import searchIcon from 'assets/icons/search.svg';
import closeIcon from 'assets/icons/closeIcon.svg';
import plus from 'assets/icons/plus.svg';

//children components
import AddLeads from './modals/leads';
import UserSummary from 'wrapper/loan-officer-summary';

//utils
import useDebounce from 'hooks/useDebounce';
import { formatNumberIntoCurrency } from 'constants/formatNumber';

const LoanInformation = () => {
  usePageTitle('Loan Information');
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState('');
  const [leadsPopup, setLeadsPopup] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [skip, setSkip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    itemsCount: 0,
    totalItemsCount: 0,
  });

  const searchFilter = useDebounce(globalFilter, 700);

  // fetch lead status
  const { data: pipelineStatusData, isError: pipelineStatusError } =
    useGetPipelineStatusQuery(
      {},
      {
        refetchOnMountOrArgChange: true,
      }
    );
  //fetch all leads
  const {
    data: allLeadsCacheData,
    currentData: allLeadsData,
    isLoading: allLeadsLoading,
    isFetching: allLeadsFetching,
    isError: allLeadsError,
  } = useGetAllLeadsQuery(
    {
      data: {
        loanOfficerIds: [],
      },
      searchFilter,
      paging: {
        limit: 10,
        offset: page,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skip,
    }
  );

  // fetch lead summary
  const [
    getLeadInfo,
    { data: leadsData, isError: leadsError, isFetching: fetchLeadsData },
  ] = useLazyGetLeadDetailsQuery();

  useEffect(() => {
    Sentry.captureException(allLeadsError);
    Sentry.captureException(pipelineStatusError);
  }, []);

  //set pagination info to state
  useEffect(() => {
    if (allLeadsData) {
      setPagination({
        currentPage: allLeadsData?.data?.pagination.currentPage,
        totalPages: allLeadsData?.data?.pagination.totalPages,
        pageSize: allLeadsData?.data?.pagination.pageSize,
        itemsCount: allLeadsData?.data?.pagination.itemsCount,
        totalItemsCount: allLeadsData?.data?.pagination.totalItemsCount,
      });
      setIsLoading(false);
    }
  }, [allLeadsData]);

  //get pagination info from table
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  //get lead info for summary
  const handleSelectedRowData = (type, data) => {
    if (type === 'view') {
      getLeadInfo({
        user_id: data.user_info.userId,
        application_id: data.user_info.applicationId,
      });
      setOpenSummary(true);
    } else if (type === 'edit') {
      navigate(
        `/loanInformation/lead?applicationId=${data?.user_info?.applicationId}`,
        {
          state: {
            userId: data?.user_info?.userId,
            applicationId: data?.user_info?.applicationId,
            isEdit: true,
          },
        }
      );
    }
  };

  const breadCrumbs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Loan Information',
      path: '/loanInformation',
    },
  ];

  //handle table row navigation
  const handleOnNavigate = (row) => {
    navigate(
      `/loanInformation/lead?applicationId=${row?.user_info?.applicationId}`,
      {
        state: {
          userId: row?.user_info?.userId,
          applicationId: row?.user_info?.applicationId,
          isEdit: false,
        },
      }
    );
  };
  // skip api calls
  useEffect(() => {
    if (searchFilter === '' && page === 1) {
      setSkip(false);
    }
  }, [searchFilter, page]);

  //define your table
  const columns = [
    {
      header: 'Profile ID',
      accessorKey: 'user_info.applicationId',
      cell: ({ row }) => {
        return (
          <div className="text-custom-green font-semibold ">
            {row?.original?.user_info?.applicationId}
          </div>
        );
      },
      meta: {
        width: 'w-[100px]',
      },
    },
    {
      header: 'Borrower Name',
      accessorKey: 'user_info.firstName',
      cell: ({ row }) => {
        return (
          <User
            size="sm"
            title={row?.original?.user_info.firstName}
            subtitle={row?.original?.user_info?.lastName}
          />
        );
      },
      meta: {
        width: 'w-[200px]',
      },
    },
    {
      header: 'Phone',
      accessorKey: 'user_info.phoneNumber',
      meta: {
        width: 'w-[100px]',
      },
    },
    {
      header: 'Address',
      accessorKey: 'user_info.propertyAddress',
      meta: {
        width: 'w-[200px]',
        style: 'h-[2rem] text-ellipsis overflow-hidden',
      },
      cell: ({ row }) => {
        return (
          <>
            <div data-tooltip-id={`my-tooltip-${row.index}`}>
              <p className=" overflow-hidden text-ellipsis">
                {row?.original?.user_info?.propertyAddress}
              </p>
            </div>
            <ReactTooltip
              id={`my-tooltip-${row.index}`}
              place="bottom"
              style={{ maxWidth: '15rem', wordBreak: 'break-all' }}
              content={row?.original?.user_info?.propertyAddress}
            />
          </>
        );
      },
      meta: {
        width: 'w-[200px]',
      },
    },
    {
      header: 'Status',
      accessorKey: 'statusId',
      cell: ({ row }) => {
        const statusId = row?.original?.user_info?.statusId;
        const statusObject = pipelineStatusData?.data?.find(
          (data) => data.applicationStatusId === statusId
        );
        const statusText = statusObject ? statusObject.name : '';
        return (
          <div className="w-[8rem]">
            <Badge
              title={statusText}
              variant={statusVariantMap[statusId] || 'primary'}
            />
          </div>
        );
      },
      meta: {
        width: 'w-[150px]',
      },
    },
    {
      header: 'Loan Amount',
      accessorKey: 'loan_amount',
      cell: ({ row }) => {
        return (
          <div>{formatNumberIntoCurrency(row?.original?.loan_amount)}</div>
        );
      },
      meta: {
        width: 'w-[100px]',
      },
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      meta: {
        className: 'sticky right-0',
        style: 'bg-white group-hover:bg-primary50',
        width: 'w-[90px]',
      },
      cell: ({ row }) => {
        const [isEditHovered, setIsEditHovered] = useState(false);
        const [isViewHovered, setIsViewHovered] = useState(false);
        return (
          <div className="flex justify-evenly">
            <div
              className="group cursor-pointer flex flex-col justify-center"
              onMouseEnter={() => setIsEditHovered(true)}
              onMouseLeave={() => setIsEditHovered(false)}
              onClick={(event) => {
                event.stopPropagation();
                handleSelectedRowData('edit', row?.original);
              }}
            >
              <EditIcon color={isEditHovered ? '#1340FF' : '#9C9C9C'} />
            </div>
            <div
              className="group cursor-pointer flex flex-col justify-center"
              onMouseEnter={() => setIsViewHovered(true)}
              onMouseLeave={() => setIsViewHovered(false)}
              onClick={(event) => {
                event.stopPropagation();
                handleSelectedRowData('view', row?.original);
                setSelectedData(row?.original);
              }}
            >
              <ViewIcon color={isViewHovered ? '#1340FF' : '#9C9C9C'} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <Breadcrumb breadcrumbs={breadCrumbs} />
      <div className="flex flex-col justify-between h-auto gap-6 overflow-auto  max-h-custom-pipeline ">
        <div className="h-[100vh]">
          <H1>Leads</H1>
          <div className="flex  justify-between my-4 mx-2">
            <div className="w-[16rem] relative">
              <InputGroup
                size="sm_2"
                position="center"
                placeholder="Search"
                svg={searchIcon}
                value={globalFilter}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                  setPage(1);
                }}
              />
              {globalFilter && (
                <img
                  src={closeIcon}
                  alt=""
                  className="absolute top-3 right-4 cursor-pointer"
                  onClick={() => {
                    setIsLoading(true);
                    setSkip(true);
                    setGlobalFilter('');
                    setPage(1);
                  }}
                />
              )}
            </div>
            <div className="flex gap-4 relative">
              <Button
                variant="primary_sm"
                onClick={() => {
                  setLeadsPopup(!leadsPopup);
                }}
              >
                <img src={plus} alt="" /> Add Lead
              </Button>

              {leadsPopup && (
                <AddLeads
                  leadsPopup={leadsPopup}
                  setLeadsPopup={setLeadsPopup}
                />
              )}
            </div>
          </div>
          <Table
            isLoading={allLeadsFetching || isLoading}
            data={
              allLeadsData?.status === 200
                ? allLeadsData?.data?.lead_information
                : []
            }
            columns={columns}
            errorMessage="No relevant data found"
            height="min-h-[30vh]"
            showPagination={allLeadsData?.status === 200}
            pagination={pagination}
            onPageChange={handlePageChange}
            onNavigate={handleOnNavigate}
          />
        </div>
        {openSummary && (
          <UserSummary
            selectedRowData={leadsData?.data}
            openSummary={openSummary}
            isLoading={fetchLeadsData}
            isError={leadsError}
            setOpenSummary={setOpenSummary}
            selectedData={selectedData}
            handleSelectedRowData={handleSelectedRowData}
            buttonText="Edit Profile"
          />
        )}
      </div>
    </div>
  );
};

export default LoanInformation;
