/* istanbul ignore file */
import {
  checkPhoneNumberValidation,
  withValidations,
  checkTextInput,
  checkAlphanumeric,
  checkSSN,
  checkEmailValidation,
} from '../utils';

const unFormattedYears = Array.from({ length: 26 }, () => ({}));

export const years = unFormattedYears.map((_, index) => {
  return {
    id: index + 1,
    name: index,
  };
});

const unFormattedMonths = Array.from({ length: 12 }, () => ({}));

export const months = unFormattedMonths.map((_, index) => {
  return {
    id: index + 1,
    name: index,
  };
});

export const personalInformationData = [
  {
    id: 1,
    name: 'First Name',
    type: 'text',
    placeholder: 'Enter First Name',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'firstName',
    validator: checkTextInput,
  },
  {
    id: 2,
    name: 'Middle Name',
    type: 'text',
    placeholder: 'Enter Middle Name',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'middleName',
    validator: checkTextInput,
  },
  {
    id: 3,
    name: 'Last Name',
    type: 'text',
    placeholder: 'Enter Last Name',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'lastName',
    validator: checkTextInput,
  },
  {
    id: 4,
    name: 'Phone Number',
    type: 'phone',
    placeholder: 'Enter Phone Number',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'phoneNumber',
    checkValidation: [checkPhoneNumberValidation],
    pattern: '(###) ###-####',
  },
  {
    id: 5,
    name: 'Email',
    type: 'text',
    placeholder: 'Enter Email Address',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'email',
    checkValidation: [checkEmailValidation],
  },
  {
    id: 6,
    name: 'Marital Status',
    type: 'select',
    placeholder: 'Enter Marital Status',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'maritalStatusId',
    options: [],
  },

  {
    id: 7,
    name: 'Citizenship Status',
    type: 'select',
    placeholder: 'Enter Citizenship Status',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'citizenshipStatusId',
    options: [],
  },
  {
    id: 8,
    type: 'container',
    customClass: 'w-2/3 items-end',
    formFields: [],
  },
  {
    id: 9,
    name: 'Date of Birth',
    type: 'date-encrypted',
    placeholder: 'MM/DD/YYYY',
    customClass: 'px-2 my-2 block w-1/3',
    isRequired: false,
    propertyName: 'DOB',
    disableFutureDate: true,
  },
  {
    id: 10,
    name: 'SSN',
    type: 'password',
    placeholder: '000-00-0000',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'SSN',
    validator: checkSSN,
  },
  {
    id: 11,
    name: 'Ownership',
    type: 'select',
    placeholder: 'Select Ownership Type',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'ownershipId',
    options: [
      {
        id: 1,
        name: 'me',
      },
    ],
  },
  {
    id: 12,
    name: 'Present Address',
    type: 'text',
    placeholder: 'Enter Present Address',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'currentAddress',
  },
  {
    id: 13,
    name: 'Apt/Suit No. (If Available)',
    type: 'text',
    placeholder: 'Enter Apt/Suit No.',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'suitNo',
  },
  {
    id: 14,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'City',
        type: 'text',
        placeholder: 'Enter City',
        customClass: 'w-1/2',
        isRequired: true,
        propertyName: 'cityName',
        validator: checkTextInput,
      },
      {
        id: 2,
        name: 'State',
        type: 'select',
        placeholder: 'Select State',
        customClass: 'w-1/2',
        isRequired: true,
        propertyName: 'stateName',
      },
    ],
  },
  {
    id: 15,
    name: 'Zip',
    type: 'number',
    placeholder: 'Enter Zip Code',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'zipCode',
  },
  {
    id: 16,
    name: 'Rent',
    type: 'number',
    placeholder: 'Enter Rent Amount',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      return String(formData?.ownershipId?.id) === '2';
    },
    propertyName: 'rent',
    disabled: (formData) => {
      return String(formData?.ownershipId?.id) !== '2';
    },
  },
  {
    id: 17,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'Years Living from',
        type: 'select',
        placeholder: 'Year',
        customClass: 'w-1/2',
        isRequired: true,
        propertyName: 'yearsLiving',
        options: years,
      },
      {
        id: 2,
        name: '',
        type: 'select',
        placeholder: 'Month',
        customClass: 'w-1/2',
        isRequired: true,
        propertyName: 'monthsLiving',
        options: months,
      },
    ],
  },
  {
    id: 18,
    name: 'If present address is under 2 years, provide previous address details',
    type: 'checkbox',
    placeholder: '',
    customClass: 'w-full',
    isRequired: false,
    propertyName: 'presentAddressLessThanTwoYears',
  },
  {
    id: 19,
    name: 'Previous Address',
    type: 'text',
    placeholder: 'Enter Previous Address',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      return formData?.presentAddressLessThanTwoYears;
    },
    propertyName: 'previousAddress',
    disabled: (formData) => {
      return !formData?.presentAddressLessThanTwoYears;
    },
  },
  {
    id: 20,
    name: 'City',
    type: 'text',
    placeholder: 'Enter City',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'previousCityName',

    validator: checkTextInput,
    isRequired: (formData) => {
      return formData?.presentAddressLessThanTwoYears;
    },
    disabled: (formData) => {
      return !formData?.presentAddressLessThanTwoYears;
    },
  },
  {
    id: 21,
    name: 'State',
    type: 'select',
    placeholder: 'Select State',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'previousStateName',
    isRequired: (formData) => {
      return formData?.presentAddressLessThanTwoYears;
    },
    disabled: (formData) => {
      return !formData?.presentAddressLessThanTwoYears;
    },
  },
  {
    id: 22,
    name: 'Zip',
    type: 'number',
    placeholder: 'Enter Zip Code',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'previousZipCode',
    isRequired: (formData) => {
      return formData?.presentAddressLessThanTwoYears;
    },
    disabled: (formData) => {
      return !formData?.presentAddressLessThanTwoYears;
    },
  },
];

export const personalInformation = withValidations(personalInformationData);
