import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//admin API'S
export const adminConsumerAPI = createApi({
  reducerPath: 'adminConsumer',
  tagTypes: ['fetchConsumer', 'consumerUpdate'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    //get admin - consumer data
    getConsumer: builder.query({
      query: ({ data, paging, searchFilter }) => {
        const { limit, offset } = paging;
        return {
          url: `loan/consumer/?page=${offset}&page_size=${limit}&search=${searchFilter}`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
      providesTags: ['fetchConsumer'],
    }),
    resetPassword: builder.mutation({
      query: ({ email }) => {
        return {
          url: `loan/consumer/reset-password?email=${email}`,
          method: 'POST',
          headers: getHeaders(),
          //body: data,
        };
      },
      //invalidatesTags: ['creditUpdate'],
    }),
    updateConsumer: builder.mutation({
      query: ({ data, userId }) => {
        return {
          url: `loan/consumer/${userId}`,
          method: 'PUT',
          headers: getHeaders(),
          body: data,
        };
      },
      invalidatesTags: ['fetchConsumer'],
    }),
    deleteConsumer: builder.mutation({
      query: ({ userId }) => {
        return {
          url: `loan/consumer/${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['fetchConsumer'],
    }),
    // get state
    getStates: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/states`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
  }),
});

export const {
  useGetConsumerQuery,
  useResetPasswordMutation,
  useUpdateConsumerMutation,
  useDeleteConsumerMutation,
  useGetStatesQuery,
} = adminConsumerAPI;

/*
 */
