import React from 'react';

/**
 * Converts a given currency value to USD and formats it based on the specified options.
 *
 * @param {Object} options - The options for converting and formatting the currency value.
 * @param {number} options.currencyValue - The currency value to be converted and formatted.
 * @param {number} [options.decimalPlaces=0] - The number of decimal places to round the formatted value to.
 * @param {string} [options.formatType='currency'] - The type of formatting to apply ('currency' or 'decimal').
 * @returns {string} - The formatted value in USD.
 */

const ToUSD = ({ currencyValue, formatType = 'currency' }) => {
  // Convert currencyValue to a number if it's a valid number, otherwise default to 0.
  const value =
    currencyValue !== undefined &&
    currencyValue !== null &&
    !isNaN(parseFloat(currencyValue))
      ? Number(currencyValue)
      : 0;

  // Create a new Intl.NumberFormat object for formatting the value to USD.
  // If formatType is 'currency', format as currency; otherwise, format as decimal.
  let USDollar = new Intl.NumberFormat('en-US', {
    style: formatType === 'currency' ? 'currency' : 'decimal',
    currency: formatType === 'currency' ? 'USD' : undefined,
    maximumFractionDigits: 3,
    minimumFractionDigits: 0,
  });

  // Return a div containing the formatted value in USD.
  return <div>{USDollar.format(value)}</div>;
};

export default ToUSD;
