import React, { useState, useMemo, useEffect } from 'react';

//components
import Table from 'components/table';
import Badge from 'components/badge';
import User from 'components/user';
import InputGroup from 'components/input-group';
import Button from 'components/button';
//libs
import { Tooltip as ReactTooltip } from 'react-tooltip';

//icons
import EditIcon from 'assets/icons/EditIcon';
import searchIcon from 'assets/icons/search.svg';
import closeIcon from 'assets/icons/closeIconSlim.svg';
import DeleteIcon from 'assets/icons/DeleteBin.jsx';
import plus from 'assets/icons/plus.svg';

//children components
import EmployeeDelete from './EmployeeDelete';
import AddNewEmployee from './addNewEmployee';
//utils
import useDebounce from 'hooks/useDebounce';
import {
  useGetEmployeeQuery,
  useGetEmployeeTypeQuery,
} from 'redux_store/services/admin/adminEmployee';
const AdminEmployee = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [page, setPage] = useState(1);
  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false);
  const [addNewModalVisibility, setAddNewModalVisibility] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [addNewEmployeeFlag, setAddNewEmployeeFlag] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    itemsCount: 0,
    totalItemsCount: 0,
  });
  const [editData, setEditData] = useState();
  // fetch lead status

  const handleSelectedRowData = (data) => {
    setAddNewEmployeeFlag(false);
    setSelectedRowData(data);
    setAddNewModalVisibility(true);
  };
  useEffect(() => {
    !addNewModalVisibility && setEditData(null);
  }, [addNewModalVisibility]);
  const searchFilter = useDebounce(globalFilter, 700);
  //fetch all Employees
  const {
    data: allEmployeeData,
    //currentData: allEmployeeData,
    isLoading: isEmployeeDataLoading,
    isFetching: isEmployeeDataFetching,
    error: allEmplyeeError,
  } = useGetEmployeeQuery(
    {
      searchFilter: globalFilter ?? '',
      paging: {
        limit: 10,
        offset: page,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: employeeTypeData } = useGetEmployeeTypeQuery();

  //set pagination info to state
  useEffect(() => {
    if (allEmployeeData) {
      setPagination({
        currentPage: allEmployeeData?.data?.pagination?.currentPage,
        totalPages: allEmployeeData?.data?.pagination?.totalPages,
        pageSize: allEmployeeData?.data?.pagination?.pageSize,
        itemsCount: allEmployeeData?.data?.pagination?.itemsCount,
        totalItemsCount: allEmployeeData?.data?.pagination?.totalItemsCount,
      });
    }
  }, [allEmployeeData]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const blankSpaceChecker = (value) => {
    if (value === '' || value === ' ' || !value || value.length === 0)
      return null;

    return value;
  };
  const columns = [
    {
      header: 'Employee Name',
      accessorKey: 'firstName',
      cell: ({ row }) => {
        return (
          <User
            image={blankSpaceChecker(row?.original?.profilePicture)}
            size="sm"
            title={row?.original?.firstName}
            subtitle={row?.original?.lastName}
          />
        );
      },
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: ({ row }) => {
        return (
          <div className="font-medium self-start text-secondary600">
            {row?.original?.email ?? 'Not  Available'}
          </div>
        );
      },
    },
    {
      header: 'NMLS ID',
      accessorKey: 'nmlsId',
      cell: ({ row }) => {
        return (
          <div className="font-medium self-start text-secondary600">
            {blankSpaceChecker(row?.original?.NMLS) ?? 'Not  Available'}
          </div>
        );
      },
    },
    {
      header: 'Employee Type',
      accessorKey: 'employeeType',
      cell: ({ row }) => {
        let employeeTypeId = row?.original?.employeeTypeId;
        let employeeTypeName;
        employeeTypeData?.data?.filter((item) => {
          if (employeeTypeId === item.employeeTypeId)
            employeeTypeName = item.name;
        });
        return (
          <div className="font-medium self-start text-secondary600">
            {employeeTypeName ?? 'Not  Available'}
          </div>
        );
      },
    },
    {
      header: 'Address',
      accessorKey: 'propertyAddress',
      meta: {
        width: 'min-w-[200px] max-w-[200px]',
        style:
          'h-[2rem] text-ellipsis overflow-hidden font-medium self-start text-secondary600',
      },
      cell: ({ row }) => {
        return (
          <>
            <div data-tooltip-id={`my-tooltip-${row.index}`}>
              {blankSpaceChecker(row?.original?.address) ?? 'Not  Available'}
            </div>
            <ReactTooltip
              id={`my-tooltip-${row.index}`}
              place="bottom"
              style={{ maxWidth: '15rem', wordBreak: 'break-all' }}
              content={
                blankSpaceChecker(row?.original?.address) ?? 'Not  Available'
              }
            />
          </>
        );
      },
    },
    {
      header: 'Status',
      accessorKey: 'statusId',
      cell: ({ row }) => {
        const isActive = Number(row?.original?.isActive);
        const statusText = isActive ? 'Active' : 'Inactive';
        return (
          <div className="w-[8rem]">
            <Badge
              title={statusText}
              variant={isActive ? 'success' : 'rejected'}
            />
          </div>
        );
      },
    },
    {
      header: 'Action',
      accessorKey: 'actions',
      meta: {
        className: 'sticky right-0',
        style: 'bg- group-hover:bg-primary50',
        width: 'min-w-[90px]',
      },
      cell: ({ row }) => {
        const [isDeleteHovered, setIsDeleteHovered] = useState(false);
        const [isEditHovered, setIsEditHovered] = useState(false);

        return (
          <div
            className={`flex justify-evenly ${
              !Number(row?.original?.isActive) && 'opacity-50'
            }`}
          >
            <div
              className="group cursor-pointer flex flex-col justify-center"
              onMouseEnter={() => setIsEditHovered(true)}
              onMouseLeave={() => setIsEditHovered(false)}
              onClick={(event) => {
                if (Number(row?.original?.isActive)) {
                  event.stopPropagation();
                  handleSelectedRowData(row?.original);
                }
              }}
            >
              <EditIcon
                color={
                  Number(row?.original?.isActive) && isEditHovered
                    ? '#1340FF'
                    : '#5A5555'
                }
              />
            </div>
            <div
              className="group cursor-pointer flex flex-col justify-center"
              onMouseEnter={() => setIsDeleteHovered(true)}
              onMouseLeave={() => setIsDeleteHovered(false)}
              onClick={(event) => {
                if (Number(row?.original?.isActive)) {
                  event.stopPropagation();
                  setSelectedRowData(row?.original);
                  setConfirmDeleteVisibility(true);
                }
              }}
            >
              <DeleteIcon
                color={
                  Number(row?.original?.isActive) && isDeleteHovered
                    ? 'red'
                    : '#5A5555'
                }
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-[100%] h-[100vh] self-center">
      <div className="flex flex-col justify-between h-auto gap-6 overflow-auto  max-h-custom-pipeline ">
        <div>
          <div className="flex justify-between my-4 mx-2">
            <div className="w-[16rem] relative">
              <InputGroup
                size="sm_2"
                position="center"
                placeholder="Search"
                svg={searchIcon}
                value={globalFilter}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                  setPage(1);
                }}
              />
              {globalFilter && (
                <img
                  src={closeIcon}
                  alt=""
                  className="absolute top-3 right-4 cursor-pointer"
                  onClick={() => {
                    setGlobalFilter('');
                    setPage(1);
                  }}
                />
              )}
            </div>
            <div className="flex gap-4 relative">
              <Button
                variant="primary"
                btnClassName={`rounded-md`}
                onClick={() => {
                  setAddNewModalVisibility(true);
                  setAddNewEmployeeFlag(true);
                }}
              >
                <div className="w-[100%] flex flex-row gap-4">
                  <img src={plus} alt="" className="pl-2" />
                  Add New Employee
                </div>
              </Button>
            </div>
          </div>
          <div className="max-h-[65vh] w-[100%] overflow-y-scroll">
            <Table
              isLoading={isEmployeeDataFetching || isEmployeeDataLoading}
              data={
                allEmployeeData?.status === 200
                  ? allEmployeeData?.data?.employees_data
                  : []
              }
              columns={columns}
              errorMessage="No relevant data found"
              height="min-h-[30vh]"
              showPagination={allEmployeeData?.status === 200}
              pagination={pagination}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {confirmDeleteVisibility && (
          <EmployeeDelete
            setConfirmDeleteVisibility={setConfirmDeleteVisibility}
            selectedRowData={selectedRowData}
          />
        )}
        {addNewModalVisibility && (
          <AddNewEmployee
            setAddNewModalVisibility={setAddNewModalVisibility}
            addNewEmployeeFlag={addNewEmployeeFlag}
            selectedRowData={selectedRowData}
            employeeTypeData={employeeTypeData}
          />
        )}
      </div>
    </div>
  );
};

export default AdminEmployee;
