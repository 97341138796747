import react from 'react';
//icons
import user from 'assets/icons/user.svg';
import mobileIcon from 'assets/icons/mobileIcon.svg';
import organizationIcon from 'assets/icons/organizationIcon.svg';

//components
import Checkbox from 'components/checkbox';
const LoanOfficer = ({
  setLoanOfficerState,
  loanOfficerState,
  isFormSubmitted,
  validationData,
  handleMobileNumberValue,
  onKeyPressPhoneNumber,
}) => {
  //const [isOrganization, setIsOrganization] = react.useState(false);
  const onChangeData = (e) => {
    const { name, value } = e.target;

    // Regex to allow only capital letters, small letters, and spaces
    const nameRegex = /^[A-Za-z\s]*$/;

    if (name === 'firstName' || name === 'lastName') {
      if (nameRegex.test(value) || value === '') {
        setLoanOfficerState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setLoanOfficerState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const isValid = (propertyName, validationData, data) => {
    const validationFuncs = validationData[propertyName];
    let isFound = false;
    let message = '';
    validationFuncs.forEach((validationFunc) => {
      if (!isFound) {
        const resp = validationFunc(data, propertyName);
        if (!resp?.isValid) {
          isFound = true;
          message = resp?.hint;
        }
      }
    });
    return { isValid: !isFound, message };
  };

  return (
    <>
      <div className="self-center max-h-[50vh] w-[78%] flex flex-col text-center py-8 gap-4 overflow-y-scroll ">
        <div className="flex flex-col gap-2">
          <div
            className={`flex flex-row border rounded-md border-secondary100 overflow-hidden ${
              isFormSubmitted &&
              !isValid('firstName', validationData, loanOfficerState)?.isValid
                ? 'border-error'
                : 'border-neutral100'
            } `}
          >
            <div className=" flex flex-col justify-center">
              <img src={user} alt="user" />
            </div>
            <input
              type="text"
              className={`px-2 font-medium placeholder:font-medium w-[100%] relative outline-none`}
              onChange={onChangeData}
              value={loanOfficerState?.firstName || ''}
              name={'firstName'}
              placeholder="First Name"
            />
          </div>
          {isFormSubmitted &&
            !isValid('firstName', validationData, loanOfficerState)
              ?.isValid && (
              <p className="text-error text-left p-xs-med p-0.5">
                {
                  isValid('firstName', validationData, loanOfficerState)
                    ?.message
                }
              </p>
            )}
        </div>
        <div className="flex flex-col gap-2">
          <div
            className={`flex flex-row border rounded-md border-secondary100 overflow-hidden ${
              isFormSubmitted &&
              !isValid('lastName', validationData, loanOfficerState)?.isValid
                ? 'border-error'
                : 'border-neutral100'
            } `}
          >
            <div className=" flex flex-col justify-center">
              <img src={user} alt="" />
            </div>
            <input
              type="text"
              className={`px-2 font-medium placeholder:font-medium w-[100%] relative outline-none`}
              onChange={onChangeData}
              value={loanOfficerState?.lastName || ''}
              name={'lastName'}
              placeholder="Last Name"
            />
          </div>
          {isFormSubmitted &&
            !isValid('lastName', validationData, loanOfficerState)?.isValid && (
              <p className="text-error text-left p-xs-med p-0.5">
                {isValid('lastName', validationData, loanOfficerState)?.message}
              </p>
            )}
        </div>
        <div className="flex flex-col gap-2">
          <div
            className={`flex flex-row border rounded-md border-secondary100 overflow-hidden ${
              isFormSubmitted &&
              !isValid('phoneNumber', validationData, loanOfficerState)?.isValid
                ? 'border-error'
                : 'border-neutral100'
            } `}
          >
            <div className=" flex flex-col justify-center">
              <img src={mobileIcon} alt="" />
            </div>
            <input
              type="text"
              className={`px-2 font-medium placeholder:font-medium w-[100%] relative outline-none`}
              onChange={onChangeData}
              onKeyDown={onKeyPressPhoneNumber}
              value={handleMobileNumberValue(loanOfficerState?.phoneNumber)}
              name={'phoneNumber'}
              placeholder="Mobile Number"
            />
          </div>
          {isFormSubmitted &&
            !isValid('phoneNumber', validationData, loanOfficerState)
              ?.isValid && (
              <p className="text-error text-left p-xs-med p-0.5">
                {
                  isValid('phoneNumber', validationData, loanOfficerState)
                    ?.message
                }
              </p>
            )}
        </div>
        <div className="flex flex-col gap-2">
          <div
            className={`flex flex-row border rounded-md border-secondary100 overflow-hidden ${
              isFormSubmitted &&
              !isValid('NMLSID', validationData, loanOfficerState)?.isValid
                ? 'border-error'
                : 'border-neutral100'
            } `}
          >
            <div className=" flex flex-col justify-center">
              <img src={organizationIcon} alt="" />
            </div>
            <input
              type="text"
              className={`px-2 font-medium placeholder:font-medium w-[100%] relative outline-none`}
              onChange={onChangeData}
              value={loanOfficerState?.NMLSID || ''}
              name={'NMLSID'}
              maxLength="7"
              placeholder="NMLS ID"
            />
          </div>
          {isFormSubmitted &&
            !isValid('NMLSID', validationData, loanOfficerState)?.isValid && (
              <p className="text-error text-left p-xs-med p-0.5">
                {isValid('NMLSID', validationData, loanOfficerState)?.message}
              </p>
            )}
        </div>
        <div className={`h-fit flex flex-col justify-start px-3 py-1 `}>
          <div className="flex flex-row overflow-hidden">
            <div className="mx-1 flex flex-col justify-center cursor-pointer">
              <Checkbox
                onChange={(e) => {
                  //setIsOrganization((prev) => !prev);
                  if (e.target.checked) {
                    setLoanOfficerState((prev) => ({
                      ...prev,
                      isOrganization: e.target.checked,
                      organizationNMLSID: '',
                    }));
                  } else {
                    setLoanOfficerState((prev) => {
                      const { organizationNMLSID, ...rest } = prev;
                      rest.isOrganization = e.target.checked;
                      return rest;
                    });
                  }
                }}
                size={'w-4 h-4'}
                checked={loanOfficerState?.isOrganization || ''}
              />
            </div>
            <div className="h-fit mx-1 flex flex-col justify-center">
              <p className="font-medium-2 px-6 text-secondary600">
                Are you an Organization?
              </p>
            </div>
          </div>
        </div>
        {loanOfficerState?.isOrganization && (
          <div className="flex flex-col gap-2">
            <div
              className={`flex flex-row border rounded-md border-secondary100 overflow-hidden ${
                isFormSubmitted &&
                !isValid('organizationNMLSID', validationData, loanOfficerState)
                  ?.isValid
                  ? 'border-error'
                  : 'border-neutral100'
              } `}
            >
              <div className=" flex flex-col justify-center">
                <img src={organizationIcon} alt="" />
              </div>
              <input
                type="text"
                className={`px-2 font-medium placeholder:font-medium w-[100%] relative outline-none`}
                onChange={onChangeData}
                value={loanOfficerState?.organizationNMLSID || ''}
                name={'organizationNMLSID'}
                maxLength="7"
                placeholder="Organization  NMLS ID"
              />
            </div>
            {isFormSubmitted &&
              !isValid('organizationNMLSID', validationData, loanOfficerState)
                ?.isValid && (
                <p className="text-error text-left p-xs-med p-0.5">
                  {
                    isValid(
                      'organizationNMLSID',
                      validationData,
                      loanOfficerState
                    )?.message
                  }
                </p>
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default LoanOfficer;
