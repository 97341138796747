//React and Imports
import React, { useEffect, useState, useRef } from 'react';
//components
import { customToast } from 'components/toast';
//Icons
import greyArrowRight from 'assets/icons/greyArrowRight.svg';
const Tabs = ({
  selectedTabId,
  tabs = [],
  onClickTab,
  variant = 'primary',
  canClose,
  onClose,
  isScrollable = false,
}) => {
  const [selectedTab, setSelectedTab] = useState(selectedTabId ?? tabs[0]?.id);
  useEffect(() => {
    if (selectedTab !== selectedTabId) {
      setSelectedTab(selectedTabId);
    }
  }, [selectedTabId, selectedTab]);
  //Ref for scrollable div
  const scrollContainerRef = useRef(null);
  //function to make scroll on left
  const handleScrollLeft = () => {
    scrollContainerRef.current.scrollLeft -= 1000; // adjust the scroll amount as needed
  };
  //function to make scroll on right
  const handleScrollRight = () => {
    scrollContainerRef.current.scrollLeft += 1000; // adjust the scroll amount as needed
  };
  const variants = {
    primary: {
      active:
        'text-white bg-black rounded-t-lg px-5 py-1 text-base font-medium',
      inActive: 'font-medium px-3',
      errorActive:
        'text-white bg-danger100 rounded-t-lg px-5 py-1 text-base font-medium',
      errorInActive: 'text-danger100 font-medium px-3',
    },
    secondary: {
      active:
        'text-white bg-black rounded-t-lg px-5 py-1 text-base font-medium',
      inActive: 'font-medium px-3',
      errorActive:
        'text-white bg-danger100 rounded-t-lg px-5 py-1 text-base font-medium',
      errorInActive: 'text-danger100 font-medium px-3',
    },
    primary_sm: {
      active: 'text-white bg-black rounded-t-lg px-5 py-1 text-base p-xs-med',
      inActive: 'p-xs-med px-3',
      errorActive:
        'text-white bg-danger100 rounded-t-lg px-5 py-1 text-base p-xs-med',
      errorInActive: 'text-danger100 p-xs-med px-3',
    },
    secondary_sm: {
      active:
        'text-white bg-primary500 rounded-t-lg px-5 py-1 text-base p-xs-med',
      inActive: 'p-xs-med px-3',
      errorActive:
        'text-white bg-danger100 rounded-t-lg px-5 py-1 text-base p-xs-med',
      errorInActive: 'text-danger100 p-xs-med px-3',
    },
  };
  /*   useEffect(() => {
    const scrollRect = scrollContainerRef.current.getBoundingClientRect();
    const hasScrollbar =
      scrollRect.width < scrollContainerRef.current.offsetWidth;
    console.log(hasScrollbar);
  }, [scrollContainerRef]); */
  return (
    <>
      <div className="w-[100%] flex flex-row gap-1 border-b border-grey ">
        {isScrollable && (
          <div
            className="flex flex-col justify-center rotate-180 cursor-pointer mx-2"
            onClick={handleScrollLeft}
          >
            <img src={greyArrowRight} alt="<-" />
          </div>
        )}
        <div
          animate={{
            translateX: handleScrollLeft,
          }}
          className="w-[100%] flex gap-4 overflow-x-scroll hiddenScroll scroll-smooth"
          ref={scrollContainerRef}
          style={{ transform: `translateX(${handleScrollLeft}px)` }}
        >
          {tabs?.map((tab, i) => {
            return (
              <div className="flex items-center relative group" key={tab?.id}>
                <button
                  className={`${
                    variants[variant][
                      selectedTab === tab?.id
                        ? tab?.hasError
                          ? 'errorActive'
                          : 'active'
                        : tab?.hasError
                          ? 'errorInActive'
                          : 'inActive'
                    ]
                  } flex-1 overflow-hidden text-ellipsis whitespace-nowrap pl-2 ${canClose ? 'pr-7' : 'pr-2'} flex items-center`}
                  onClick={() => {
                    if (tab?.disabled) {
                      return customToast.error(`${tab.message}`);
                    } else {
                      setSelectedTab(tab?.id);
                      onClickTab && onClickTab(tab);
                    }
                  }}
                >
                  {tab?.hasError && selectedTab !== tab?.id && (
                    <span
                      className={`mr-2 ${
                        selectedTab === tab?.id
                          ? 'text-white bg-danger100'
                          : 'text-danger100'
                      } rounded-full flex items-center justify-center w-5 h-5`}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.99935 11.3332C7.41384 11.3332 8.77039 10.7713 9.77059 9.77107C10.7708 8.77088 11.3327 7.41432 11.3327 5.99984C11.3327 4.58535 10.7708 3.2288 9.77059 2.2286C8.77039 1.22841 7.41384 0.666504 5.99935 0.666504C4.58486 0.666504 3.22831 1.22841 2.22811 2.2286C1.22792 3.2288 0.666016 4.58535 0.666016 5.99984C0.666016 7.41432 1.22792 8.77088 2.22811 9.77107C3.22831 10.7713 4.58486 11.3332 5.99935 11.3332ZM5.99935 3.33317C6.27643 3.33317 6.49935 3.55609 6.49935 3.83317V6.1665C6.49935 6.44359 6.27643 6.6665 5.99935 6.6665C5.72227 6.6665 5.49935 6.44359 5.49935 6.1665V3.83317C5.49935 3.55609 5.72227 3.33317 5.99935 3.33317ZM5.33268 7.99984C5.33268 7.82303 5.40292 7.65346 5.52794 7.52843C5.65297 7.40341 5.82254 7.33317 5.99935 7.33317C6.17616 7.33317 6.34573 7.40341 6.47075 7.52843C6.59578 7.65346 6.66602 7.82303 6.66602 7.99984C6.66602 8.17665 6.59578 8.34622 6.47075 8.47124C6.34573 8.59627 6.17616 8.6665 5.99935 8.6665C5.82254 8.6665 5.65297 8.59627 5.52794 8.47124C5.40292 8.34622 5.33268 8.17665 5.33268 7.99984Z"
                          fill="#E81D1D"
                        />
                      </svg>
                    </span>
                  )}
                  {tab?.hasError && selectedTab === tab?.id && (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: '4px' }}
                    >
                      <g clip-path="url(#clip0_13399_10677)">
                        <path
                          d="M5.99935 11.3332C7.41384 11.3332 8.77039 10.7713 9.77059 9.77107C10.7708 8.77088 11.3327 7.41432 11.3327 5.99984C11.3327 4.58535 10.7708 3.2288 9.77059 2.2286C8.77039 1.22841 7.41384 0.666504 5.99935 0.666504C4.58486 0.666504 3.22831 1.22841 2.22811 2.2286C1.22792 3.2288 0.666016 4.58535 0.666016 5.99984C0.666016 7.41432 1.22792 8.77088 2.22811 9.77107C3.22831 10.7713 4.58486 11.3332 5.99935 11.3332ZM5.99935 3.33317C6.27643 3.33317 6.49935 3.55609 6.49935 3.83317V6.1665C6.49935 6.44359 6.27643 6.6665 5.99935 6.6665C5.72227 6.6665 5.49935 6.44359 5.49935 6.1665V3.83317C5.49935 3.55609 5.72227 3.33317 5.99935 3.33317ZM5.33268 7.99984C5.33268 7.82303 5.40292 7.65346 5.52794 7.52843C5.65297 7.40341 5.82254 7.33317 5.99935 7.33317C6.17616 7.33317 6.34573 7.40341 6.47075 7.52843C6.59578 7.65346 6.66602 7.82303 6.66602 7.99984C6.66602 8.17665 6.59578 8.34622 6.47075 8.47124C6.34573 8.59627 6.17616 8.6665 5.99935 8.6665C5.82254 8.6665 5.65297 8.59627 5.52794 8.47124C5.40292 8.34622 5.33268 8.17665 5.33268 7.99984Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_13399_10677">
                          <rect
                            width="10.6667"
                            height="10.6667"
                            fill="white"
                            transform="translate(0.666016 0.666504)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {tab?.name}
                </button>
                {canClose && tab?.id !== 1 && (
                  <div
                    className={`absolute right-0 top-0 bottom-0 flex items-center pr-2 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer ${
                      selectedTab === tab?.id
                        ? 'text-white'
                        : tab?.hasError
                          ? 'text-danger100'
                          : 'text-black'
                    }`}
                    onClick={() => {
                      if (onClose) {
                        onClose(tab);
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 8.586l-4.95-4.95a1 1 0 00-1.414 1.414L8.586 10l-4.95 4.95a1 1 0 101.414 1.414L10 11.414l4.95 4.95a1 1 0 001.414-1.414L11.414 10l4.95-4.95a1 1 0 00-1.414-1.414L10 8.586z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {isScrollable && (
          <div
            className="flex flex-col justify-center cursor-pointer mx-2"
            onClick={handleScrollRight}
          >
            <img src={greyArrowRight} alt="->" />
          </div>
        )}
      </div>
    </>
  );
};

export default Tabs;
