/**
 * Function to handle the demographic information form for a borrower.
 *
 * @param {boolean} readOnly - Flag to indicate if the form is in read-only mode.
 * @param {function} setReadOnly - Function to set the read-only mode of the form.
 * @param {number} resetId - ID used to trigger a reset of the form.
 * @param {function} setResetId - Function to set the reset ID.
 * @returns {JSX.Element} A form component for capturing demographic information.
 */

// React and Components
import React, { useEffect, useState } from 'react';
import Button from 'components/button';
import Select from 'react-select';
import Radio from 'components/radio';
import Checkbox from 'components/checkbox';
import Modal from 'components/modal';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
// Slices
import {
  useGetEthnicityQuery,
  useGetUserRaceQuery,
} from 'redux_store/services/client/profile';

// Sentry for logging
import * as Sentry from '@sentry/browser';
import TermsAndConditions from 'wrapper/terms-and-conditions';
import { useLocation } from 'react-router-dom';
// RTK Queries
import {
  useGetBorrowerProfileQuery,
  useUpdateDemographicsMutation,
  useCreateDemographicsMutation,
} from 'redux_store/services/borrower-profile';
//themes
import { bgColors, textColors, borderColors } from '../../../../themes/colors';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';
const Demographics = ({ readOnly, setReadOnly, resetId, setResetId }) => {
  // States and Refs
  const [formData, setFormData] = React.useState({
    ethnicityId: null,
    subEthnicity: '',
    otherEthnicity: '',
    raceId: null,
    subRace: '',
    otherRace: '',
    sex: '',
    infoMedium: '',
    isTermsAccepted: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const isEdit = React.useRef(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const [updateDemographics, { isLoading: isUpdateDemographicsLoading }] =
    useUpdateDemographicsMutation();
  const [createDemographics, { isLoading: isCreateDemographicsLoading }] =
    useCreateDemographicsMutation();

  //selector

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!formData?.isTermsAccepted) {
      setFormSubmitted(true);
      return;
    }
    setFormSubmitted(false);
    try {
      const data = {
        ...formData,
        ethnicityId: formData?.ethnicityId || null,
        raceId: formData?.raceId || null,
        userProfileId: applicationData?.data?.user_profile?.userProfileId,
        applicationId,
        userProfileId: applicationData?.data?.user_profile?.userProfileId,
        userId: applicationData?.data?.user_profile?.userId,
      };
      if (applicationData?.data?.user_demographics) {
        const resp = await updateDemographics({
          applicationId,
          data: replaceEmptyStringWithNull(data),
        });
        if (resp?.data?.status === 200) {
          customToast.success('Profile Updated Successfully');
        } else {
          customToast.error('Error while updating the Profile');
        }
      } else {
        const resp = await createDemographics({
          data: replaceEmptyStringWithNull(data),
        });
        if (resp?.data?.status === 200) {
          customToast.success('Profile Updated Successfully');
        } else {
          customToast.error('Error while updating the Profile');
        }
      }

      await refetchBorrowerInfo();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    } finally {
      setFormSubmitted(false);
      setReadOnly(true);
    }
  };

  // Sclices and RTK Query
  const { data: ethnicityData } = useGetEthnicityQuery();
  const { data: raceData } = useGetUserRaceQuery();
  const {
    data: applicationData,
    refetch: refetchBorrowerInfo,
    isLoading,
  } = useGetBorrowerProfileQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  React.useEffect(() => {
    setFormSubmitted(false);
    if (applicationData?.data?.user_demographics) {
      isEdit.current = true;
      const formData = applicationData?.data?.user_demographics;
      setFormData({
        ...formData,
        isTermsAccepted: formData?.isTermsAccepted === '1',
      });
    } else {
      setFormData({
        ethnicityId: null,
        subEthnicity: '',
        otherEthnicity: '',
        raceId: null,
        subRace: '',
        otherRace: '',
        sex: '',
        infoMedium: '',
        isTermsAccepted: '',
      });
    }
  }, [applicationData, readOnly]);

  const formatData = (data, property) => {
    return (
      data?.map((x) => {
        return {
          ...x,
          id: x[property],
        };
      }) ?? []
    );
  };

  useEffect(() => {
    if (resetId === 8) {
      setFormData({
        ethnicityId: null,
        subEthnicity: '',
        otherEthnicity: '',
        raceId: null,
        subRace: '',
        otherRace: '',
        sex: '',
        infoMedium: '',
        isTermsAccepted: '',
      });
    }
    setResetId(0);
  }, [resetId]);
  const ethnicityPlaceholderMapper = (id) => {
    if (ethnicityData?.data) {
      let placeholder = ethnicityData?.data.filter((item) => {
        return item.ethnicityId === id;
      });
      return placeholder[0]?.name ?? 'Select';
    }
  };
  const racePlaceholderMapper = (id) => {
    if (raceData?.data) {
      let placeholder = raceData?.data.filter((item) => {
        return item.raceId === id;
      });
      return placeholder[0]?.name ?? 'Select';
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <div className=" max-h-borrower-declaration">
          <form onSubmit={onSubmitHandler}>
            <div className=" max-h-borrower-declaration overflow-auto">
              <div className="bg-black text-tertiary w-full px-2 py-1 rounded-t-lg">
                <p className="mx-auto">Demographic Information of Borrower</p>
              </div>
              <p className="p-2 p-xs-medium">
                The purpose of collecting this information is to help ensure
                that all applicants are treated fairly and that the housing
                needs of communities and neighborhoods are being fulfilled. For
                residential mortgage lending, Federal law requires that we ask
                applicants for their demographic information (ethnicity, sex,
                and race) in order to monitor our compliance with equal credit
                opportunity, fair housing, and home mortgage disclosure laws.
                You are not required to provide this information, but are
                encouraged to do so. You may select one or more designations for
                "Ethnicity" and one or more designations for "Race." The law
                provides that we may not discriminate on the basis of this
                information, or on whether you choose to provide it. However, if
                you choose not to provide the information and you have made this
                application in person, Federal regulations require us to note
                your ethnicity, sex, and race on the basis of visual observation
                or surname. The law also provides that we may not discriminate
                on the basis of age or marital status information you provide in
                this application.  <br /> <b>Instructions</b>: You may select
                one or more "Hispanic or Latino" origins and one or more
                designations for "Race." If you do not wish to provide some or
                all of this information, select the applicable option. 
              </p>
              <hr className="text-grey" />
              <div className="flex items-center flex-wrap py-4 justify-evenly">
                <div className="">
                  <p className="font-medium-bold">Ethnicity</p>
                </div>
                <div className="w-1/2">
                  <div className="w-1/2">
                    <Select
                      menuShouldScrollIntoView={true}
                      menuShouldBlockScroll={true}
                      menuPlacement="auto"
                      menuPosition="fixed"
                      defaultValue={formData?.ethnicityId}
                      onChange={(e) => {
                        if (readOnly) {
                          return false;
                        }
                        setFormData({
                          ...formData,
                          ethnicityId: parseInt(e.ethnicityId),
                          subEthnicity: '',
                          otherEthnicity: '',
                        });
                      }}
                      options={ethnicityData?.data}
                      isDisabled={readOnly}
                      isClearable={false}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      getOptionValue={(value) => {
                        return value.ethnicityId;
                      }}
                      placeholder={ethnicityPlaceholderMapper(
                        formData?.ethnicityId
                      )}
                      className="w-full"
                      noOptionsMessage={() => 'No Data found'}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          fontSize: '12px',
                          position: 'relative',
                          fontWeight: '400',
                          borderRadius: '5px',
                          boxShadow: 'none',
                          paddingRight: '9px',
                          border: `2px solid ${borderColors.grey}`,
                          '&:hover': {
                            cursor: 'pointer',
                            border: `2px solid ${borderColors.grey}`,
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: bgColors.tertiary_bg,
                          color: textColors.black,
                          fontSize: '12px',
                          fontWeight: '400',
                          '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: bgColors.primary50,
                          },
                          '&:active': {
                            backgroundColor: bgColors.primary50,
                          },
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          borderRadius: '5px',
                          color: textColors.black,
                          fontSize: '12px',
                          fontWeight: '400',
                        }),
                        indicatorSeparator: () => null,
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral80: textColors.black,
                        },
                      })}
                    />
                  </div>
                  {formData?.ethnicityId === 1 && (
                    <div className="w-full flex flex-wrap gap-4 mt-3">
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subEthnicity: e.target.value,
                            });
                          }}
                          value={'Mexican'}
                          id={'Mexican'}
                          name={`subEthnicity`}
                          checked={formData?.subEthnicity === 'Mexican'}
                        />
                        <label htmlFor="Mexican" className="pl-1 font-medium">
                          Mexican
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          value={'Puerto Rican'}
                          id={'Puerto-Rican'}
                          name={`subEthnicity`}
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subEthnicity: e.target.value,
                            });
                          }}
                          checked={formData?.subEthnicity === 'Puerto Rican'}
                        />
                        <label
                          htmlFor="Puerto-Rican"
                          className="pl-1 font-medium"
                        >
                          Puerto Rican
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          value="Cuban"
                          id={'Cuban'}
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subEthnicity: e.target.value,
                            });
                          }}
                          name={`subEthnicity`}
                          checked={formData?.subEthnicity === 'Cuban'}
                        />
                        <label htmlFor="Cuban" className="pl-1 font-medium">
                          Cuban
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          value="Other"
                          id={'Other-ethnicity'}
                          name={`subEthnicity`}
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subEthnicity: e.target.value,
                            });
                          }}
                          checked={formData?.subEthnicity === 'Other'}
                        />
                        <label
                          htmlFor="Other-ethnicity"
                          className="pl-1 font-medium"
                        >
                          Other
                        </label>
                      </div>
                    </div>
                  )}
                  {formData?.subEthnicity === 'Other' && (
                    <div className="w-full flex flex-wrap items-center gap-3 mt-3">
                      <div className="">
                        <p className="">Enter Origin:</p>
                      </div>
                      <div className="w-1/2">
                        <input
                          onChange={(e) => {
                            const filteredValue = e.target.value.replace(
                              /[^a-zA-Z ]/g,
                              ''
                            );
                            setFormData({
                              ...formData,
                              otherEthnicity: filteredValue,
                            });
                          }}
                          value={formData?.otherEthnicity}
                          className="outline outline-none w-1/2  border-dotted border-b-2"
                        />
                      </div>
                    </div>
                  )}
                  {formData?.subEthnicity === 'Other' && (
                    <div className="mt-3">
                      <p className="text-disabled">
                        Examples: Argentinean, Colombian, Dominican, Nicaraguan,
                        Salvadoran, Spaniard, etc. 
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <hr className="text-grey" />
              <div className="flex items-center flex-wrap py-4 justify-evenly">
                <div className="">
                  <p className="font-medium-bold">Race</p>
                </div>
                <div className="w-1/2">
                  <div className="w-1/2 ml-2">
                    <Select
                      defaultValue={formData?.raceId}
                      onChange={(e) => {
                        if (readOnly) {
                          e.preventDefault();
                          return;
                        }
                        setFormData({
                          ...formData,
                          raceId: parseInt(e.raceId),
                          subRace: '',
                          otherRace: '',
                        });
                      }}
                      options={raceData?.data}
                      isDisabled={readOnly}
                      isClearable={false}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      getOptionValue={(value) => {
                        return value.raceId;
                      }}
                      placeholder={racePlaceholderMapper(formData?.raceId)}
                      className="w-full"
                      noOptionsMessage={() => 'No Data found'}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          fontSize: '12px',
                          position: 'relative',
                          fontWeight: '400',
                          borderRadius: '5px',
                          boxShadow: 'none',
                          paddingRight: '9px',
                          border: `2px solid ${borderColors.grey}`,
                          '&:hover': {
                            cursor: 'pointer',
                            border: `2px solid ${borderColors.grey}`,
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: bgColors.tertiary_bg,
                          color: textColors.black,
                          fontSize: '12px',
                          fontWeight: '400',
                          '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: bgColors.primary50,
                          },
                          '&:active': {
                            backgroundColor: bgColors.primary50,
                          },
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          borderRadius: '5px',
                          color: textColors.black,
                          fontSize: '12px',
                          fontWeight: '400',
                        }),
                        indicatorSeparator: () => null,
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral80: textColors.black,
                        },
                      })}
                    />
                  </div>
                  {formData?.raceId === 4 && (
                    <div className="w-full flex items-center gap-3 ml-2 mt-3">
                      <div className="">
                        <p className="">
                          Enter name of enrolled or principal tribe:
                        </p>
                      </div>
                      <div className="w-1/2">
                        <input
                          onChange={(e) => {
                            const filteredValue = e.target.value.replace(
                              /[^a-zA-Z ]/g,
                              ''
                            );
                            setFormData({
                              ...formData,
                              otherRace: filteredValue,
                            });
                          }}
                          checked={formData?.otherRace}
                          className="outline outline-none w-1/2  border-dotted border-b-2"
                        />
                      </div>
                    </div>
                  )}
                  {formData?.raceId === 1 && (
                    <div className="w-full flex flex-wrap gap-4 mt-3 ml-2">
                      <div className="flex items-center">
                        <Radio
                          id={'Asian-Indian'}
                          value="Asian Indian"
                          name={`subRace`}
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          checked={formData?.subRace === 'Asian Indian'}
                        />
                        <label
                          htmlFor="Asian-Indian"
                          className="pl-1 font-medium"
                        >
                          Asian Indian
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          checked={formData?.subRace === 'Chinese'}
                          id={'Chinese'}
                          value="Chinese"
                          name={`subRace`}
                        />
                        <label htmlFor="Chinese" className="pl-1 font-medium">
                          Chinese
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          checked={formData?.subRace === 'Filipino'}
                          value="Filipino"
                          id={'Filipino'}
                          name={`subRace`}
                        />
                        <label htmlFor="Filipino" className="pl-1 font-medium">
                          Filipino
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          checked={formData?.subRace === 'Japanese'}
                          value="Japanese"
                          id={'Japanese'}
                          name={`subRace`}
                        />
                        <label htmlFor="Japanese" className="pl-1 font-medium">
                          Japanese
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          checked={formData?.subRace === 'Korean'}
                          value="Korean"
                          id={'Korean'}
                          name={`subRace`}
                        />
                        <label htmlFor="Korean" className="pl-1 font-medium">
                          Korean
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          id={'Vietnamese'}
                          value="Vietnamese"
                          name={`subRace`}
                          checked={formData?.subRace === 'Vietnamese'}
                        />
                        <label
                          htmlFor="Vietnamese"
                          className="pl-1 font-medium"
                        >
                          Vietnamese
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          value="Other"
                          id={'Other'}
                          name={`subRace`}
                          checked={formData?.subRace === 'Other'}
                        />
                        <label htmlFor="Other" className="pl-1 font-medium">
                          Other
                        </label>
                      </div>
                    </div>
                  )}
                  {formData?.raceId === 3 && (
                    <div className="w-full flex flex-wrap gap-4 mt-3 ml-2">
                      <div className="flex items-center">
                        <Radio
                          id={'Asian-Indian'}
                          value="Native Hawaiian"
                          name={`subRace-3`}
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          checked={formData?.subRace === 'Native Hawaiian'}
                        />
                        <label
                          htmlFor="Native Hawaiian"
                          className="pl-1 font-medium"
                        >
                          Native Hawaiian
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          checked={formData?.subRace === 'Samoan'}
                          id={'Samoan'}
                          value="Samoan"
                          name={`subRace-3`}
                        />
                        <label htmlFor="Samoan" className="pl-1 font-medium">
                          Samoan
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          checked={
                            formData?.subRace === 'Guamanian or Chamorro'
                          }
                          value="Guamanian or Chamorro"
                          id={'Guamanian-or-Chamorro'}
                          name={`subRace-3`}
                        />
                        <label
                          htmlFor="Guamanian-or-Chamorro"
                          className="pl-1 font-medium"
                        >
                          Guamanian or Chamorro
                        </label>
                      </div>

                      <div className="flex items-center">
                        <Radio
                          onChange={(e) => {
                            if (readOnly) {
                              e.preventDefault();
                              return;
                            }
                            setFormData({
                              ...formData,
                              subRace: e.target.value,
                            });
                          }}
                          value="Other"
                          id={'Other'}
                          name={`subRace-3`}
                          checked={formData?.subRace === 'Other'}
                        />
                        <label htmlFor="Other" className="pl-1 font-medium">
                          Other
                        </label>
                      </div>
                    </div>
                  )}

                  {formData?.subRace === 'Other' && (
                    <div className="w-full flex flex-wrap items-center gap-3 mt-3">
                      <div className="">
                        <p className="">Enter Origin:</p>
                      </div>
                      <div className="w-1/2">
                        <input
                          onChange={(e) => {
                            const filteredValue = e.target.value.replace(
                              /[^a-zA-Z ]/g,
                              ''
                            );
                            setFormData({
                              ...formData,
                              otherRace: filteredValue,
                            });
                          }}
                          value={formData?.otherRace}
                          className="outline outline-none w-1/2  border-dotted border-b-2"
                        />
                      </div>
                    </div>
                  )}
                  {formData?.subRace === 'Other' && formData?.subRace === 1 && (
                    <div className="mt-3 ml-2">
                      <p className="text-disabled">
                        Examples: Hmong, Laot ian, Thai, Pakistani, Cambodian,
                        etc.
                      </p>
                    </div>
                  )}
                  {formData?.subRace === 'Other' && formData?.subRace === 3 && (
                    <div className="mt-3 ml-2">
                      <p className="text-disabled">
                        Examples: Fijian, Tongan, etc.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <hr className="text-grey" />
              <div className="flex items-center flex-wrap py-4 justify-evenly">
                <div className="">
                  <p className="font-medium-bold">Sex</p>
                </div>
                <div className="w-1/2">
                  <div className="w-full flex gap-4 ml-3">
                    <div className="flex items-center">
                      <Radio
                        id={'Female'}
                        value="Female"
                        name={`radio-gender-data`}
                        onChange={(e) => {
                          if (readOnly) {
                            e.preventDefault();
                            return;
                          }
                          setFormData({
                            ...formData,
                            sex: e.target.value,
                          });
                        }}
                        checked={formData?.sex === 'Female'}
                      />
                      <label htmlFor="Female" className="pl-1 font-medium">
                        Female
                      </label>
                    </div>
                    <div className="flex items-center">
                      <Radio
                        id={'Male'}
                        value={'Male'}
                        name={`radio-gender-data`}
                        onChange={(e) => {
                          if (readOnly) {
                            e.preventDefault();
                            return;
                          }
                          setFormData({
                            ...formData,
                            sex: e.target.value,
                          });
                        }}
                        checked={formData?.sex === 'Male'}
                      />
                      <label htmlFor="Male" className="pl-1 font-medium">
                        Male
                      </label>
                    </div>
                    <div className="flex items-center">
                      <Radio
                        id={'iDoNotWish'}
                        value="I do not wish to provide this information"
                        name={`radio-gender-data`}
                        onChange={(e) => {
                          if (readOnly) {
                            e.preventDefault();
                            return;
                          }
                          setFormData({
                            ...formData,
                            sex: e.target.value,
                          });
                        }}
                        checked={
                          formData?.sex ===
                          'I do not wish to provide this information'
                        }
                      />
                      <label htmlFor="iDoNotWish" className="pl-1 font-medium">
                        I do not wish to provide this information
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="text-grey" />
              <div className="flex items-center flex-wrap py-3 justify-evenly">
                <div>
                  <p className="font-medium-bold">
                    This Demographic
                    <br /> Information was provided through
                  </p>
                </div>
                <div className="w-2/3">
                  <div
                    className="flex items-center mt-2"
                    style={{ marginLeft: '3.8rem' }}
                  >
                    <Radio
                      id={'Internet'}
                      value="Email or Internet"
                      name={`radio-demographics`}
                      onChange={(e) => {
                        if (readOnly) {
                          e.preventDefault();
                          return;
                        }
                        setFormData({
                          ...formData,
                          infoMedium: e.target.value,
                        });
                      }}
                      checked={formData?.infoMedium === 'Email or Internet'}
                    />
                    <label htmlFor="Internet" className="pl-1 font-medium">
                      Email or Internet
                    </label>
                  </div>
                  <div
                    className="flex items-center mt-2"
                    style={{ marginLeft: '3.8rem' }}
                  >
                    <Radio
                      id={'faceToFace'}
                      value="Face to Face Interview(includes Electronic Media w/Video Component)"
                      name={`radio-demographics`}
                      onChange={(e) => {
                        if (readOnly) {
                          e.preventDefault();
                          return;
                        }
                        setFormData({
                          ...formData,
                          infoMedium: e.target.value,
                        });
                      }}
                      checked={
                        formData?.infoMedium ===
                        'Face to Face Interview(includes Electronic Media w/Video Component)'
                      }
                    />
                    <label htmlFor="faceToFace" className="pl-1 font-medium">
                      Face-to-Face Interview (includes Electronic Media w/Video
                      Component )
                    </label>
                  </div>
                  <div
                    className="flex items-center mt-2"
                    style={{ marginLeft: '3.8rem' }}
                  >
                    <Radio
                      id={'TelephonicInterview'}
                      value="Telephonic Interview"
                      name={`radio-demographics`}
                      onChange={(e) => {
                        if (readOnly) {
                          e.preventDefault();
                          return;
                        }
                        setFormData({
                          ...formData,
                          infoMedium: e.target.value,
                        });
                      }}
                      checked={formData?.infoMedium === 'Telephonic Interview'}
                    />
                    <label
                      htmlFor="TelephonicInterview"
                      className="pl-1 font-medium"
                    >
                      Telephonic Interview
                    </label>
                  </div>
                  <div
                    className="flex items-center mt-2"
                    style={{ marginLeft: '3.8rem' }}
                  >
                    <Radio
                      id={'faxOrMail'}
                      value="Fax or Mail"
                      name={`radio-demographics`}
                      onChange={(e) => {
                        if (readOnly) {
                          e.preventDefault();
                          return;
                        }
                        setFormData({
                          ...formData,
                          infoMedium: e.target.value,
                        });
                      }}
                      checked={formData?.infoMedium === 'Fax or Mail'}
                    />
                    <label htmlFor="faxOrMail" className="pl-1 font-medium">
                      Fax or Mail
                    </label>
                  </div>
                </div>
              </div>
              <div className={`px-2 flex items-center`}>
                <Checkbox
                  id={`form-field=Terms-and-conditions`}
                  checked={formData?.isTermsAccepted === true}
                  value={'isTermsAccepted'}
                  onChange={(e) => {
                    if (readOnly) {
                      e.preventDefault();
                      return;
                    }
                    setFormData({
                      ...formData,
                      isTermsAccepted: e.target.checked,
                    });
                  }}
                  isError={formSubmitted && !formData?.isTermsAccepted}
                />

                <label
                  className={`${formSubmitted && !formData?.isTermsAccepted ? 'text-error' : 'text-neutral700'} pl-2 font-medium `}
                  for={`form-field=Terms-and-conditions`}
                >
                  I authorize to credit check and agree to the{' '}
                  <Button
                    variant="link"
                    style={{ textDecoration: 'underline', padding: 0 }}
                    type="button"
                    onClick={() => {
                      setShowTermsAndConditions(true);
                    }}
                  >
                    Terms & Conditions.
                  </Button>
                </label>
              </div>
            </div>

            {!readOnly && (
              <div className="text-right mt-2">
                <div className="text-right mt-2">
                  <Button
                    variant="tertiary"
                    style={{ marginRight: '10px' }}
                    type="button"
                    onClick={() => {
                      setReadOnly(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary">
                    {isCreateDemographicsLoading ||
                    isUpdateDemographicsLoading ? (
                      <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                        <Loader loaderText="Saving" FullScreen={false} />
                      </div>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </div>
            )}
          </form>
          <Modal show={showTermsAndConditions} width="max-w-[50vw]">
            <div className="p-5">
              <TermsAndConditions />
              <div className="text-right">
                <Button
                  type="button"
                  variant={'primary'}
                  onClick={() => setShowTermsAndConditions(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Demographics;
