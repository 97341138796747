import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const adminFeesworksheetAPI = createApi({
  reducerPath: 'Feesworksheet',
  tagTypes: ['getFees'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    //to get fees worksheet data
    getUserFeesWorksheet: builder.query({
      query: ({ application_Id }) => {
        return {
          url: `loan/fees/${application_Id}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['getFees'],
    }),
    //to post and save fees worksheet data
    saveUserFeesWorksheet: builder.mutation({
      query: ({ data, applicationId }) => {
        return {
          url: `loan/fees/${applicationId}`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
      invalidatesTags: ['getFees'],
    }),
    //to update fees work sheet data
    updateUserFeesWorksheet: builder.mutation({
      query: ({ data, applicationId }) => {
        return {
          url: `loan/fees/${applicationId}`,
          method: 'PUT',
          headers: getHeaders(),
          body: data,
        };
      },
      invalidatesTags: ['getFees'],
    }),

    getLoanCostTypes: builder.query({
      query: () => {
        return {
          url: `loan/fees/loan_cost_types`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    getLoanAndRate: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `loan/product/rate_and_loan_amount/application/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    getLoadstarData: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `loan/fees/closing_cost_calculation/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    // get lender
    getRateVendors: builder.query({
      query: () => {
        return {
          url: `/loan/product/rate_vendors`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
  }),
});

export const {
  useGetUserFeesWorksheetQuery,
  useSaveUserFeesWorksheetMutation,
  useUpdateUserFeesWorksheetMutation,
  useGetLoanCostTypesQuery,
  useGetLoanAndRateQuery,
  useGetLoadstarDataQuery,
  useGetRateVendorsQuery,
} = adminFeesworksheetAPI;
