import React from 'react';

const RightArrow = ({ color = '#01B23C' }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.5H11.8333M7.66667 1.5L12.0774 5.91074C12.4028 6.23618 12.4028 6.76382 12.0774 7.08926L7.66667 11.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default RightArrow;
