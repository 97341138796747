export const bgColors = {
  primary_bg: '#01B23C',
  secondary_bg: '#1E2C45',
  tertiary_bg: '#FFFFFF',
  grey: '#E5E5E5',
  disabled: '#9F9C9C',
  enabled: '#FAFAFA',
  primary50: '#e9edff',
  primary100: '#D7DFFF',
  primary200: '#AFBEFF',
  primary400: '#5E7DFF',
  progressbar_bg: '#F2F4F7',
};
export const textColors = {
  primary: '#01B23C',
  secondary: '#2B2829',
  secondary500: '#9095A8',
  secondary600: '#7A8097',
  tertiary: '#FFFFFF',
  neutral: '#898384',
  gray: '#808080',
  black: '#101828',
  error: '#D92D20',
  blueGray: '#344054',
};
export const borderColors = {
  primary: '#E3E3E3',
  primary500: '#00b23b',
  secondary: '#3F66FB',
  errorColor: '#D92D20',
  grey: '#E5E5E5',
};
export const toastColor = {
  success: '#76C71E',
  error: '#E81D1D',
  promise: '#3F66FB',
};

export const profileBgMapping = [
  '#fff0f7',
  '#d9fbfb',
  '#f7f3f2',
  '#f6f2ff',
  '#e5f6ff',
  '#defbe6',
  '#f2f4f8',
  '#edf5ff',
  '#f4f4f4',
  '#fff1f1',
];

export const colorConfigs = {
  '.primary-bg': {
    background: bgColors.primary_bg,
  },
  '.secondary-bg': {
    background: bgColors.secondary_bg,
  },
  '.tertiary-bg': {
    background: bgColors.tertiary_bg,
  },
  '.bg-grey': {
    background: bgColors.grey,
  },
  '.disabled-bg': {
    background: bgColors.disabled,
  },
  '.enabled-bg': {
    background: bgColors.enabled,
  },
  '.primary50-bg': {
    background: bgColors.primary50,
  },
  '.primary100-bg': {
    background: bgColors.primary100,
  },
  '.primary200-bg': {
    background: bgColors.primary200,
  },
  '.primary400-bg': {
    background: bgColors.primary400,
  },
  '.text-secondary': {
    color: textColors.secondary,
  },
  '.text-secondary500': {
    color: textColors.secondary500,
  },
  '.text-secondary600': {
    color: textColors.secondary600,
  },
  '.text-primary': {
    color: textColors.primary,
  },
  '.text-tertiary': {
    color: textColors.tertiary,
  },
  '.text-black': {
    color: textColors.black,
  },
  '.text-error': {
    color: textColors.error,
  },
  '.text-gray': {
    color: textColors.gray,
  },
  '.text-custom-greengray': {
    color: textColors.blueGray,
  },
  '.text-neutral': {
    color: textColors.neutral,
  },
  '.border-primary': {
    borderColor: borderColors.primary,
  },
  '.border-primary500': {
    borderColor: borderColors.primary500,
  },
  '.border-secondary': {
    borderColor: borderColors.secondary,
  },
  '.border-error': {
    borderColor: borderColors.errorColor,
  },
  '.progressbar-bg': {
    background: bgColors.progressbar_bg,
  },
  '.primary-accent': {
    accentColor: bgColors.primary_bg,
  },
  '.loader-gradient': {
    background: 'linear-gradient(90deg, #e9edff 0%, #ffd0ff 121.67%)',
  },
};

export const buttonConfigs = {
  '.btn-primary': {
    background: bgColors.primary_bg,
    padding: '11px 30px 11px 30px',
    borderRadius: '6px',
  },
};
