import { createSlice } from '@reduxjs/toolkit';

const onboardingSlice = createSlice({
  name: 'onboard',
  initialState: {
    isActive: true,
  },
  reducers: {
    setOnboardingActive: (state) => {
      state.isActive = true;
    },
    setOnboardingInactive: (state) => {
      state.isActive = false;
    },
  },
});

export const { setOnboardingActive, setOnboardingInactive } =
  onboardingSlice.actions;

export const selectIsActive = (state) => state.onboard.isActive;
export default onboardingSlice.reducer;
