import react, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
//components
import Checkbox from 'components/checkbox';
import Select from 'react-select';
import Button from 'components/button';
import { customToast } from 'components/toast';
//icons
import angleDown from 'assets/icons/angleDown.svg';
import angelsRight from 'assets/icons/angelsRight.svg';
import arrowDownBlue from 'assets/icons/arrowDownBlue.svg';
import * as Sentry from '@sentry/browser';

//child components
import productAndPricing from 'pages/borrower-profile/fees-worksheet/formData/productAndPricing.js';
import ApplyProduct from './ApplyProduct';
import EditClosingCost from './EditClosingCost';
import Loader from 'wrapper/loader';
import InputGroup from 'components/input-group';
//query
import {
  useCompareProductsMutation,
  useManageScenariosQuery,
  useEditBorrowerCompensationMutation,
  useGetLoanTermsQuery,
} from 'redux_store/services/admin/product-pricing';
//utils
import { FormatTimeDifference } from 'constants/updatedAt';
import { formatNumberIntoCurrency } from 'constants/formatNumber';
const ProductAndPricing = ({
  setComponentName,
  setUpdateChildPath,
  quoteData,
  setComponentQuote = () => {},
}) => {
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const animateKey = 0;
  const [selectedRow, setSelectedRow] = useState([]);
  const [assignRateVisiblity, setAssignRateVisiblity] = useState(false);
  const [editClosingCostVisiblity, setEditClosingCostVisiblity] =
    useState(false);
  const [productIds, setProductIds] = useState([]);
  const [checkedRates, setCheckedRates] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [compensationAmounts, setCompensationAmounts] = useState({});
  const [borrowerClosing, setBorrowerClosing] = useState({});
  const [productData, setProductData] = useState([]);
  const [editClosingCostRow, setEditClosingCostRow] = useState();
  //queries
  const {
    data: manageScenarioData,
    isFetching: manageScenarioFetching,
    refetch,
  } = useManageScenariosQuery(
    { quoteData },
    { refetchOnMountOrArgChange: true }
  );
  const [compareProducts, { isLoading: isCompareProductsLoading }] =
    useCompareProductsMutation();
  const [editBorrowerCompensation] = useEditBorrowerCompensationMutation();
  const { data: loanTerms } = useGetLoanTermsQuery();

  useEffect(() => {
    setProductData([manageScenarioData?.data[0]]);
  }, [manageScenarioData?.data]);

  // set borrower compensation data
  useEffect(() => {
    const initialCompensationAmounts = productData?.reduce((acc, item) => {
      acc[item?.product_id] = item?.broker_compensation_percent;
      return acc;
    }, {});
    setCompensationAmounts(initialCompensationAmounts);
  }, [productData]);

  // handle borrower compensation data
  const handleInputChange = (e, item) => {
    const newValue = e.target.value;
    setCompensationAmounts({
      ...compensationAmounts,
      [item?.product_id]: newValue,
    });
  };

  //update product list when borrower compensation changes
  const handleBorrowerChange = async (id) => {
    try {
      const data = {
        application_id: applicationId,
        product_id: id,
        broker_compensation: compensationAmounts[id],
      };
      const response = await editBorrowerCompensation({ data }).unwrap();
      if (response.status === 200) {
        const updatedProduct = response?.data;
        setProductData((prevProductData) =>
          prevProductData.map((item) =>
            item?.product_id === updatedProduct.product_id
              ? updatedProduct
              : item
          )
        );
        customToast.success('Product data updated');
      }
    } catch (error) {
      Sentry.captureException(error);
      customToast.error('some error occurred');
    }
  };

  //filter product ids
  useEffect(() => {
    const data = Object.entries(checkedRates)
      .filter(([productId, rateId]) => rateId !== undefined) // Filter out undefined rateIds
      .map(([productId, rateId]) => {
        const product = productData?.find(
          (item) => item?.product_id === parseInt(productId, 10)
        );
        const rate = product?.more_rates?.find(
          (rateItem) => rateItem?.rate_id === rateId
        )?.rate;
        return {
          product_id: parseInt(productId, 10), // Convert productId to integer
          rate: rate,
        };
      });

    // Set the array of objects to productData
    setProductIds(data);

    const details = Object.entries(checkedRates)
      .filter(([productId, rateId]) => rateId !== undefined) // Filter out undefined rateIds
      .map(([productId, rateId]) => {
        const product = productData?.find(
          (item) => item?.product_id === parseInt(productId, 10)
        );
        return {
          ...product,
          selectedRate: rateId,
        };
      });

    setProductDetails(details);
  }, [checkedRates]);

  // compare offer
  const handleCompareOffer = async () => {
    if (checkedRates.length === 0) {
      customToast.error('Please select at least one product to compare.');
    } else if (checkedRates.length > 3) {
      customToast.error('Cannot compare more than 3 offers.');
    } else {
      try {
        const products = checkedRates.map((rate) => ({
          product_id: rate.productId,
          rate: productData
            .find((product) => product.product_id === rate.productId)
            ?.more_rates.find((moreRate) => moreRate.rate_id === rate.rateId)
            ?.rate,
          base_points: productData
            .find((product) => product.product_id === rate.productId)
            ?.more_rates.find((moreRate) => moreRate.rate_id === rate.rateId)
            ?.base_points,
        }));
        const data = {
          application_id: Number(quoteData?.applicationId),
          products: products,
        };
        const response = await compareProducts({ data }).unwrap();

        if (response?.status === 200) {
          setComponentQuote(response?.data);
          setUpdateChildPath(true);
          setComponentName('Mortage Quote');
          customToast.success(response?.message);
        }
      } catch (error) {
        Sentry.captureException(error);
        customToast.error(error?.data?.message);
      }
    }
  };

  //parent checkbox
  const handleParentCheckboxChange = (productId) => {
    const productRates = checkedRates.filter(
      (item) => item?.productId === productId
    );
    const otherProductRates = checkedRates.filter(
      (item) => item?.productId !== productId
    );

    if (productRates.length > 0) {
      // If any rates for this product are selected, remove them all
      setCheckedRates(otherProductRates);
      return;
    }
    const product = productData?.find((item) => item?.product_id === productId);
    // If no rates are selected for this product, try to add the first rate
    const firstRateId = product?.more_rates.find(
      (rate) => rate.rate === product.rate
    );

    if (!firstRateId) {
      // If there's no first rate, do nothing
      return;
    }

    if (otherProductRates.length >= 3) {
      customToast.error(
        'You can select a maximum of 3 rates across all products.'
      );
      return;
    }

    // At this point, we know we can add the first rate
    setCheckedRates([
      ...otherProductRates,
      { productId, rateId: firstRateId.rate_id },
    ]);
  };

  //more rates checkbox
  const handleCheckboxChange = (productId, rateId) => {
    const existingIndex = checkedRates.findIndex(
      (item) => item?.productId === productId && item?.rateId === rateId
    );

    if (existingIndex !== -1) {
      // If already selected, remove it
      setCheckedRates((prevRates) =>
        prevRates.filter((_, index) => index !== existingIndex)
      );
      return;
    }

    const productRates = checkedRates.filter(
      (item) => item?.productId === productId
    );
    const otherProductRates = checkedRates.filter(
      (item) => item?.productId !== productId
    );
    const totalSelections = checkedRates.length;

    if (productRates.length >= 3) {
      customToast.error(
        'You can select a maximum of 3 rates for a single product.'
      );
      return;
    }

    if (productRates.length > 0 && otherProductRates.length > 0) {
      customToast.error(
        'You can only select multiple rates from a single product or single rates from multiple products.'
      );
      return;
    }

    if (totalSelections >= 3) {
      customToast.error(
        'You can select a maximum of 3 rates across all products.'
      );
      return;
    }

    // If all checks pass, add the new rate
    setCheckedRates((prevRates) => [...prevRates, { productId, rateId }]);
  };

  // reset all data
  const handleResetState = () => {
    setProductIds([]);
    setCheckedRates([]);
    setProductDetails([]);
    setAssignRateVisiblity(false);
  };

  const getLoanTermName = (id) => {
    const term = loanTerms?.data?.find((term) => term.loanTermId === id);
    return term ? term.name : '';
  };

  return (
    <>
      <div className="h-[78vh] w-[100%] flex flex-col justify-center">
        <div className=" h-[100%] w-[100%] self-center flex flex-col gap-2 p-3 bg-white">
          <div className="h-fit w-[100%] flex flex-row justify-between border-b-2 border-neutral100 p-1">
            <div className="w-fit flex flex-row gap-3">
              <p className="p-xs-regular text-primary500">
                {quoteData?.loanPurposeId === 1 ? 'Purchase' : 'Refinance'}
              </p>

              <p className="p-xs-bold">
                {formatNumberIntoCurrency(quoteData?.loanAmount)}
              </p>
              <p className="p-xs-regular border-r-2 border-neutral100 pr-2">
                Loan Amount at {formatNumberIntoCurrency(quoteData?.LTV)} LTV -{' '}
                {getLoanTermName(quoteData?.loanTermId)} Years
              </p>
              <p className="p-xs-bold">Adjustment</p>
              <p className="p-xs-bold">{productIds?.length} Selected</p>
            </div>
          </div>

          <div className="h-fit w-[100%] flex flex-row justify-end p-2">
            <div>
              <Button
                variant="primary"
                onClick={handleCompareOffer}
                type="button"
                btnClassName="bg-primary500 hover:bg-primary600"
              >
                {isCompareProductsLoading ? (
                  <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                    <Loader loaderText="Compare" FullScreen={false} />
                  </div>
                ) : (
                  <div className="w-[100%] flex flex-row gap-2">
                    <p>Compare</p>
                    {productIds?.length > 0 && (
                      <p className="rounded-full bg-white px-2 text-primary500">
                        {productIds?.length}
                      </p>
                    )}
                  </div>
                )}
              </Button>
            </div>
          </div>
          <div className="h-[100%] w-[100%] overflow-y-scroll scrollbarHidden">
            {manageScenarioFetching ? (
              <>
                {' '}
                <Loader loaderText="" FullScreen={true} />
              </>
            ) : (
              <table className="w-[100%] border-separate border-spacing-0">
                <tr className="h-12 w-[100%] text-center bg-chatbot_bg p-xs-semibold ">
                  <td className="w-[25%] p-2 text-start">Product</td>
                  <td colSpan={1} className="w-[10%] break-words px-2">
                    Rate/APR
                  </td>
                  <td colSpan={1} className="w-[10%] break-words px-2">
                    Discounts PTS
                  </td>
                  <td colSpan={1} className="w-[8%] break-words">
                    Broker comp-LP
                  </td>
                  <td colSpan={1} className="w-[10%] break-words px-2">
                    EST Total Fees
                  </td>
                  <td colSpan={1} className="w-[10%] break-words px-2">
                    MI
                  </td>
                  <td colSpan={1} className="w-[10%] break-words px-2">
                    Monthly Payment
                  </td>
                  <td className="w-[15%]">Action</td>
                </tr>
                {productData?.map((item, index) => {
                  return (
                    <>
                      <motion.tr
                        className="h-16 w-[100%] text-center bg-[#FAFAFA] p-xs-semibold "
                        key={animateKey}
                        initial={{
                          y: -10,
                        }}
                        animate={{
                          x: 0,
                          y: 0,
                          scale: 1,
                          rotate: 0,
                        }}
                      >
                        <td className="w-[25%] p-3 text-start">
                          <div className="flex flex-row gap-4">
                            <div>
                              <Checkbox
                                checked={checkedRates?.some(
                                  (rate) => rate?.productId === item?.product_id
                                )}
                                onClick={() =>
                                  handleParentCheckboxChange(item?.product_id)
                                }
                              />
                            </div>

                            <div className="h-fit flex flex-col">
                              <p>{item?.product_name}</p>
                              <p className=" text-disabled">
                                {FormatTimeDifference(item?.updated_at)}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          colSpan={1}
                          className="w-[10%] break-words px-2 cursor-pointer"
                          onClick={() => {
                            setSelectedRow((prev) =>
                              prev.includes(index)
                                ? prev.filter((val) => val !== index)
                                : [...prev, index]
                            );
                          }}
                        >
                          <div className="h-fit flex flex-col">
                            <p>{item?.rate} %</p>
                            <p className=" text-disabled">{item?.APR} APR</p>
                            <div className="h-fit flex flex-col justify-center">
                              {!selectedRow.includes(index) ? (
                                <img
                                  className={`w-[10px] h-[10px] self-center`}
                                  src={angleDown}
                                  alt="^"
                                />
                              ) : (
                                <motion.img
                                  initial={{
                                    y: -10,
                                  }}
                                  animate={{
                                    y: 0,
                                  }}
                                  className={`w-[8px] h-[8px] self-center`}
                                  src={arrowDownBlue}
                                  alt="^"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td colSpan={1} className="w-[10%] break-words px-2">
                          <div className="h-fit flex flex-col">
                            <p>{item?.discount_percent} %</p>
                            <p className=" text-disabled">
                              $ {item?.discount_amount}
                            </p>
                          </div>
                        </td>
                        <td colSpan={1} className="w-[10%] break-words">
                          <div className="h-fit flex justify-center items-center flex-col">
                            {/* <p>{item?.broker_compensation_percent} %</p> */}
                            <div className="flex flex-row gap-2">
                              <p>{item?.broker_compensation_percent} %</p>
                              {selectedRow.includes(index) && (
                                <p
                                  className="p-xs-regular text-primary500 cursor-pointer"
                                  onClick={() => {
                                    setBorrowerClosing((prev) => ({
                                      ...prev,
                                      [item?.product_id]:
                                        !prev[item?.product_id],
                                    }));
                                    if (borrowerClosing[item?.product_id]) {
                                      handleBorrowerChange(item?.product_id);
                                    }
                                  }}
                                >
                                  {borrowerClosing[item?.product_id]
                                    ? 'Save'
                                    : 'Edit'}
                                </p>
                              )}
                            </div>
                            {borrowerClosing[item?.product_id] ? (
                              <InputGroup
                                value={
                                  compensationAmounts[item?.product_id] || ''
                                }
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === '+' ||
                                    e.key === 'e' ||
                                    e.key === 'E'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => handleInputChange(e, item)}
                                size="sm"
                                position="left"
                                type="number"
                                placeholder="Broker comp %"
                              />
                            ) : (
                              <p className=" text-disabled">
                                $ {item?.broker_compensation_amount}
                              </p>
                            )}
                          </div>
                        </td>
                        <td
                          colSpan={1}
                          className="w-[10%] break-words px-2 cursor-pointer"
                          onClick={() => {
                            setSelectedRow((prev) =>
                              prev.includes(index)
                                ? prev.filter((val) => val !== index)
                                : [...prev, index]
                            );
                          }}
                        >
                          <div className="h-fit flex flex-col items-center">
                            <div className="flex flex-row gap-2">
                              <p>{`$${item?.estimated_total_fees}`}</p>
                              {selectedRow.includes(index) && (
                                <p
                                  className="p-xs-regular text-primary500"
                                  onClick={() => {
                                    setEditClosingCostVisiblity(true);
                                    setEditClosingCostRow({
                                      rate: item?.rate,
                                      loanAmount: quoteData?.loanAmount,
                                    });
                                  }}
                                >
                                  Edit
                                </p>
                              )}
                            </div>
                            <p
                              className={`text-disabled ${editClosingCostVisiblity && 'self-start text-start'}`}
                            >
                              Closing Cost
                            </p>
                            {!selectedRow.includes(index) ? (
                              <img
                                className={`w-[10px] h-[10px] self-center`}
                                src={angleDown}
                                alt="^"
                              />
                            ) : (
                              <motion.img
                                initial={{
                                  y: -10,
                                }}
                                animate={{
                                  y: 0,
                                }}
                                className={`w-[8px] h-[8px] self-center`}
                                src={arrowDownBlue}
                                alt="^"
                              />
                            )}
                          </div>
                        </td>
                        <td colSpan={1} className="w-[10%] break-words px-2">
                          <div className="h-fit flex flex-col">
                            <p>{`$${item?.MI}`}</p>
                            <p className=" text-disabled">{'monthly'}</p>
                          </div>
                        </td>
                        <td
                          colSpan={1}
                          className="w-[10%] break-words px-2 cursor-pointer"
                          onClick={() => {
                            setSelectedRow((prev) =>
                              prev.includes(index)
                                ? prev.filter((val) => val !== index)
                                : [...prev, index]
                            );
                          }}
                        >
                          <div className="h-fit flex flex-col">
                            <p>{`$${item?.monthly_payment}`}</p>
                            <p className=" text-disabled">PITI ${item?.PITI}</p>
                          </div>
                        </td>
                        <td className="w-[15%]">
                          <div className="h-fit flex flex-col justify-center">
                            <Button
                              variant=""
                              disabled={
                                checkedRates?.length > 1 ||
                                productIds.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                if (productIds?.length > 0) {
                                  setAssignRateVisiblity(true);
                                } else {
                                  handleParentCheckboxChange(item?.product_id);
                                  setAssignRateVisiblity(true);
                                }
                              }}
                              type="button"
                              btnClassName={`${
                                checkedRates?.length > 1 ||
                                productIds.length === 0
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              } self-center border-2 border-disabled text-disabled px-3 rounded-md py-1 text-[#5A5555]`}
                            >
                              Assign Rate
                            </Button>
                          </div>
                        </td>
                      </motion.tr>
                      {selectedRow.includes(index) && (
                        <>
                          <td colSpan={8}>
                            <table className="w-full border-separate border-spacing-x-1 mt-2">
                              <tr
                                key={`header-${index}`}
                                className="text-[12px] font-bold bg-white"
                              >
                                <td
                                  colSpan={1}
                                  className="p-2 px-3 text-start border-x border-t border-neutral100 rounded-t-md "
                                >
                                  Guidelines
                                  <div className="border-b pb-4 border-neutral100 w-[100%] flex justify-center"></div>
                                </td>

                                <td
                                  colSpan={3}
                                  className="p-2 text-center border-x border-t border-neutral100 rounded-t-md"
                                >
                                  More Rates
                                  <div className="border-b pb-4 border-neutral100 w-[100%] flex justify-center"></div>
                                </td>

                                <td
                                  colSpan={4}
                                  className="p-2 text-center border-x border-t border-neutral100 rounded-t-md"
                                >
                                  Adjustments
                                  <div className="border-b pb-4 border-neutral100 w-[100%] flex justify-center"></div>
                                </td>
                              </tr>
                              <tr
                                key={`subheader-${index}`}
                                className="text-[12px] font-bold "
                              >
                                <td
                                  colSpan={1}
                                  className="p-2 px-3 text-start font-semibold border-x border-neutral100 "
                                >
                                  -
                                </td>
                                <td
                                  colSpan={1}
                                  className="p-2 text-center border-l border-neutral100"
                                >
                                  <div className="flex flex-row gap-1">
                                    {/* <Checkbox /> */}
                                    <div className="h-fit flex flex-col">
                                      <p>Rate/APR</p>
                                    </div>
                                  </div>
                                </td>
                                <td colSpan={1} className="p-2 text-center ">
                                  Discount Points
                                </td>
                                <td
                                  colSpan={1}
                                  className="p-2 text-center border-r border-neutral100"
                                >
                                  Mo. P+I
                                </td>
                                <td
                                  colSpan={1}
                                  className="p-2 text-center border-l border-neutral100"
                                >
                                  Factor
                                </td>
                                <td colSpan={1} className="p-2 text-center">
                                  Rate
                                </td>
                                <td colSpan={1} className="p-2 text-center">
                                  Margin
                                </td>
                                <td
                                  colSpan={1}
                                  className="p-2 text-center border-r border-neutral100"
                                >
                                  Points
                                </td>
                              </tr>

                              {item?.more_rates &&
                                item?.adjustments &&
                                item?.more_rates.map((moreRate, rateIndex) => {
                                  const isLastElement =
                                    rateIndex === item.more_rates.length - 1;
                                  const isLastAdj =
                                    rateIndex === item.adjustments.length - 1;
                                  return (
                                    <motion.tr
                                      key={`more_rates-${index}-${rateIndex}`}
                                      className="p-xs-regular"
                                      initial={{ y: -10 }}
                                      animate={{
                                        x: 0,
                                        y: 0,
                                        scale: 1,
                                        rotate: 0,
                                      }}
                                    >
                                      <td
                                        colSpan={1}
                                        className={`p-2 px-3 text-start font-semibold border-x border-neutral100`}
                                      >
                                        {moreRate.guideline}
                                      </td>
                                      <td
                                        colSpan={1}
                                        className={`p-2 text-center border-l border-neutral100`}
                                      >
                                        <div className="flex flex-row gap-3">
                                          <Checkbox
                                            checked={checkedRates?.some(
                                              (rate) =>
                                                rate.productId ===
                                                  item?.product_id &&
                                                rate.rateId === moreRate.rate_id
                                            )}
                                            onClick={() =>
                                              handleCheckboxChange(
                                                item?.product_id,
                                                moreRate.rate_id
                                              )
                                            }
                                          />
                                          <div className="h-fit flex text-nowrap">
                                            <p>
                                              {moreRate.rate}% / {moreRate.APR}%
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        colSpan={1}
                                        className={`p-2 text-center text-nowrap border-neutral100`}
                                      >
                                        <div>
                                          <p>{moreRate.discount_point} %</p>
                                          <p className=" text-disabled">
                                            $ {moreRate.discount_amount}
                                          </p>
                                        </div>
                                      </td>
                                      <td
                                        colSpan={1}
                                        className={`p-2 text-center text-nowrap border-r border-neutral100`}
                                      >{`$ ${moreRate.p_and_i}`}</td>

                                      {/* Adjustments Data */}
                                      {item?.adjustments[rateIndex] && (
                                        <>
                                          <td
                                            colSpan={1}
                                            className={`p-2 text-start border-neutral100 ${item?.adjustments[rateIndex].factor.includes('Final Price') ? 'p-xs-bold' : ''} ${isLastAdj ? 'rounded-bl-md border-b' : ''} border-l border-neutral100`}
                                          >
                                            {
                                              item?.adjustments[rateIndex]
                                                .factor
                                            }
                                          </td>
                                          <td
                                            colSpan={1}
                                            className={`p-2 text-center ${isLastAdj ? 'border-b' : ''}  border-neutral100 ${item?.adjustments[rateIndex].factor.includes('Final Price') ? 'p-xs-bold' : ''}`}
                                          >
                                            {item?.adjustments[rateIndex].rate}
                                          </td>
                                          <td
                                            colSpan={1}
                                            className={`p-2 text-center  ${isLastAdj ? 'border-b ' : ''}  border-neutral100 ${item?.adjustments[rateIndex].factor.includes('Final Price') ? 'p-xs-bold' : ''}`}
                                          >
                                            {
                                              item?.adjustments[rateIndex]
                                                .margin
                                            }
                                          </td>
                                          <td
                                            colSpan={1}
                                            className={`p-2 text-center  ${item?.adjustments[rateIndex].factor.includes('Final Price') ? 'p-xs-bold' : ''} ${isLastAdj ? 'rounded-br-md border-b' : ''} border-r border-neutral100`}
                                          >
                                            {
                                              item?.adjustments[rateIndex]
                                                .points
                                            }
                                          </td>
                                        </>
                                      )}
                                    </motion.tr>
                                  );
                                })}
                              <tr>
                                <td
                                  className="border-b border-x border-neutral100 rounded-b-md h-2"
                                  colSpan={1}
                                ></td>
                                <td
                                  className="border-b border-x border-neutral100 rounded-b-md h-2"
                                  colSpan={3}
                                ></td>
                              </tr>
                            </table>
                          </td>
                        </>
                      )}
                    </>
                  );
                })}
              </table>
            )}
          </div>
        </div>
      </div>
      {assignRateVisiblity && (
        <ApplyProduct
          resetStates={handleResetState}
          productDetails={productDetails}
          allProducts={productData ?? []}
        />
      )}
      {editClosingCostVisiblity && (
        <EditClosingCost
          refetch={refetch}
          setEditClosingCostVisiblity={setEditClosingCostVisiblity}
          editClosingCostRow={editClosingCostRow}
        />
      )}
    </>
  );
};

export default ProductAndPricing;
