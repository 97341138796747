/**
 * Returns the initials of a given title.
 *
 * @param {string} title - The title to extract initials from.
 * @returns {string} The initials of the title.
 */

export function getInitials(title) {
  const names = title?.split(' ');
  let firstInitial = '';
  let lastInitial = '';

  if (names?.length > 0 && names[0]?.length > 0) {
    firstInitial = names[0][0].toUpperCase();
  }

  if (names?.length > 1 && names[1]?.length > 0) {
    lastInitial = names[1][0].toUpperCase();
  }

  return firstInitial + lastInitial;
}
