import react, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

//components
import H1 from 'components/h1-typography';
import Button from 'components/button';
//icons
import arrowDown from 'assets/icons/arrowDown.svg';
import successIcon from 'assets/icons/successIcon.svg';
import warningIcon from 'assets/icons/warningIcon.svg';
import errorIcon from 'assets/icons/errorIcon.svg';
import preUnderwritingIcon from 'assets/icons/preUnderwriting.svg';
import loader from 'assets/icons/loader.svg';

//child & states
//import remarks from './preUnderwriting-remarks';
//rtk query
import { useGetUnderWriteQuery, usePostUnderWriteMutation } from '../../../redux/services/admin/pipeline';
import { data } from 'autoprefixer';
const PreUnderwriting = ()=>{
    const [remarksIndics, setRemarskIndics] = useState([])
    const [runPreUnderwritingClicked, setRunPreUnderwritingClicked] = useState(false)
    const [checkPreUnderwriting, setCheckPreUnderwriting] = useState(false)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const applicationId = searchParams.get('applicationId');
    const timerIdRef = useRef(null)
    const [buttonState, setButtonState] = useState("Run Pre-underwriting")
        const {
            data: underWireData,
            isLoading: underWireDataLoading,
            //refetch: pipelineRefetch,
            //error: underWireError,
            isError: underWireError,
          } = useGetUnderWriteQuery({
            applicationId,
            checkPreUnderwriting
        },
        { 
            refetchOnMountOrArgChange: true,
            //pollingInterval: 3000,
            //skipPollingIfUnfocused: true,
        }
    );

    //consoling data based on the data fetched
    useEffect(()=>{
        if(underWireData?.Status === 'initiation pending'){
            //console.log("Get status",underWireData?.Status)
            setButtonState('Run Pre-underwriting')
        } else if(underWireData?.Status === 'in Progress') {
            setButtonState('Pre-underwriting in Progress')
            getPreUnderWritingPolling()
        } else{
            setButtonState('Pre-underwriting in Progress')
            if(timerIdRef.current) {
                clearInterval(timerIdRef.current)
            }
            //console.log("get data",underWireData)
        }
        return () => {
            if(timerIdRef.current) {
                clearInterval(timerIdRef.current)
            }
        }
    },[underWireData])


    //resetting button state after it changes every time
    const getPreUnderWritingPolling = ()=>{
        timerIdRef.current =  setInterval(()=>{
            runPreUnderwritingClicked &&
            setCheckPreUnderwriting(prev=>(!prev))
        }, 30 * 1000)
    }
    
    const [postUnderWrite] = usePostUnderWriteMutation();
    const postUnderWriteResponse = async ()=>{
        setButtonState("Loading...");
        if(underWireData?.Status) {
            setRunPreUnderwritingClicked(true);
        }else{
            setRunPreUnderwritingClicked(false);
        }
        getPreUnderWritingPolling()
        try{
        let res =  await postUnderWrite({
            applicationId,
        })
        if(res.data.Status){
            //console.log("Post response",res)
            setButtonState(`Status: ${res.data.Status}`)
            setCheckPreUnderwriting(prev=>(!prev))
        }
        setButtonState('Pre-underwriting in Progress')
        }
        catch(error){
            //console.log(error)
        }
    }


    const recommendationTable = underWireData?.recommendations ?? false;
    const riskSummaryTable = underWireData?.riskSummary ?? false;
    const remarks = underWireData?.remarks ?? false;

    const stateMapper = state =>{
    switch (state) {
        case 'success':
            return successIcon;
        case 'warning':
            return warningIcon;
        case 'error':
            return errorIcon;
        default:
            return successIcon;
    }
}
    return(<>
        <div className='max-h-[79vh] flex flex-col justify-center'>
            {
                underWireDataLoading 
                ?  <div className="flex justify-center mt-52 w-[67vw]">
                        <img src={loader} alt="" className="animate-spin" />
                    </div>
                : underWireData?.Status
                ? (
                    <div className='h-[79vh] flex flex-col justify-center gap-3'>
                        <img src={preUnderwritingIcon} className='h-[150px] w-[150px] self-center' alt="" />
                        <h1 className='font-medium-bold mt-8 self-center'>
                        Pre-underwriting
                        </h1>
                        <p className='mb-8 p-xs-med self-center w-[50%] text-center text-secondary900'>
                        Our pre-underwriting process meticulously gathers comprehensive details of the application's risk profile to ensure a smooth loan application.
                        </p>
                        <Button
                            variant="primary_sm"
                            onClick={postUnderWriteResponse}
                            type="button"
                            btnClassName={`self-center rounded-md ${ 'cursor-pointer'}`}
                            //disabled={!isReissueSelected}
                            >
                            {buttonState}
                        </Button>
                    </div>
                )
                : (
                    <div className='h-[100%] w-[100%] self-center p-3 overflow-y-scroll'>
                        <H1>
                        Pre-underwriting Analysis Report
                        </H1>
                        <div className='flex flex-1 flex-row gap-3 mt-3'>
                            <div className='min-h-[100%] flex flex-col gap-3 w-[50%] overflow-hidden'>
                                <p className='font-medium-bold'>
                                    Recommendations
                                </p>
                                <motion.div 
                                initial={{
                                    //scale: 0,
                                    opacity: 0,
                                }}
                                animate={{
                                    x: 0,
                                    y: 0,
                                    scale: 1,
                                    opacity: 1,
                                    rotate: 0,
                                }}
                                className={`h-[100%] rounded-lg border-2 border-secondary200 p-3 flex flex-col justify-between gap-3`}>
                                {
                                    recommendationTable &&
                                    recommendationTable.map((item,index)=>{
                                        return (<>
                                        <div className='flex flex-row' key={index+1}>
                                        <div className='w-[50%] flex flex-col justify-start'>
                                            <p className='p-xs-semibold text-secondary900'>
                                                {item.name}
                                            </p>
                                            </div>
                                            <div className='w-[50%] flex flex-col justify-start'>
                                                <p className='p-xs-regular text-secondary800'>
                                                    {item.value}
                                                </p>
                                            </div>
                                        </div>
                                        </>)
                                    })
                                }
                                </motion.div>
                            </div>
                            {
                                //Risk summary
                            }
                            <div className='min-h-[100%] flex flex-col gap-3 w-[50%]' key={90}>
                                <p className='font-medium-bold'>
                                    Risk Summary
                                </p>
                                <motion.div 
                                initial={{
                                    //scale: 0,
                                    opacity: 0,
                                }}
                                animate={{
                                    x: 0,
                                    y: 0,
                                    scale: 1,
                                    opacity: 1,
                                    rotate: 0,
                                }}
                                className={`h-[100%] rounded-lg border-2 border-secondary200 p-3 flex flex-col justify-start gap-3`}>
                                {   riskSummaryTable &&
                                    riskSummaryTable.map((item,index)=>{
                                        return (<>
                                        <div className='flex flex-row' key={index+2}>
                                        <div className='w-[50%] flex flex-col justify-start'>
                                            <p className='p-xs-semibold text-secondary900'>
                                                {item.name}
                                            </p>
                                            </div>
                                            <div className='w-[50%] flex flex-col justify-start'>
                                                <p className='p-xs-regular text-secondary800'>
                                                    {item.value}
                                                </p>
                                            </div>
                                        </div>
                                        </>)
                                    })
                                }
                                </motion.div>
                            </div>
                        </div>
                    <p className='font-medium-bold mt-2'>
                        Remarks
                    </p>
                    <div className='w-[100%] h-fit rounded-lg border-2 border-secondary200 p-3 my-3 flex flex-col gap-1'>
                        {   remarks &&
                            remarks?.map((item,index)=>{
                                return(<>
                                    <div className='w-[100%] p-3 py-3 flex flex-row justify-between bg-chatbot_bg cursor-pointer' key={index}
                                    onClick={()=>{
                                        if(remarksIndics.includes(index)){
                                            const newArr = remarksIndics.filter(element => element!== index);
                                            setRemarskIndics(newArr)
                                        }else{
                                            setRemarskIndics(prev=>([...prev, index]))
                                        }
                                    }}
                                    >
                                        <div className='flex flex-row gap-3'>
                                            <img className={`${remarksIndics.includes(index) ? "rotate-0"  : "  -rotate-90 " }`} src={arrowDown} alt="" />
                                            <p className='p-xs-semibold text-secondary900'>
                                                {item.name}
                                            </p>
                                        </div>
                                        <img src={stateMapper(item.titleState)} alt="" />
                                    </div>
                                    <div>
                                    <table>
                                        {remarksIndics.includes(index) && item.children && 
                                        item.children.map((child,cIndex)=>{
                                            return (<>
                                                <motion.tr 
                                                initial={{
                                                    y: -10,
                                                }}
                                                animate={{
                                                    x: 0,
                                                    y: 0,
                                                    scale: 1,
                                                    rotate: 0,
                                                }}
                                                className='' key={cIndex+index}>
                                                    <td className='flex flex-row gap-3 p-3 w-[15vw]'>
                                                        <img src={stateMapper(child.state)} alt="" />
                                                        <p className='p-xs-semibold text-secondary900'>
                                                            {child.name}
                                                        </p>
                                                    </td>
                                                    <td className=''>
                                                        <p className='p-xs-semibold  text-secondary600'>
                                                            {child.value}
                                                        {
                                                            child.points && 
                                                            child.points.map((points,remarksIndics)=>{
                                                                return (<>
                                                                    <br />
                                                                    &#8226; &nbsp; &nbsp;{points}
                                                                </>)
                                                            })
                                                        }
                                                        </p>

                                                    </td>
                                                </motion.tr>
                                            </>)
                                        })
                                        }
                                    </table>
                                    </div>
                                </>)
                            })
                        }
                    </div>
                    </div>
                )
            }
        </div>
    </>)
}
export default PreUnderwriting;