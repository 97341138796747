import React from 'react';
import Card from 'components/card';
import {
  formatNumberIntoCurrency,
  formatNumberIntoPercentage,
} from 'constants/formatNumber';

import { format, parse } from 'date-fns';

const LoanAmountBuydown = ({ loanProgramData }) => {
  const getDate = (inputDate) => {
    if (!inputDate) {
      return '';
    }
    const formattedDate = format(
      parse(inputDate, 'yyyy-MM-dd', new Date()),
      'MM-dd-yyyy'
    );
    return formattedDate;
  };
  return (
    <Card className="w-1/3 ml-2 flex flex-col justify-between p-4">
      <h4 className="text-neutral1000 font-bold">Amortization Schedule</h4>

      <div className="flex items-center" data-testid="loan_amount">
        <p className="w-1/2 text-sm">Loan Amount Buydown</p>
        <p> {formatNumberIntoCurrency(loanProgramData?.loan_amount, '-')}</p>
      </div>
      <div className="flex items-center" data-testid="interest_rate">
        <p className="w-1/2 text-sm">Interest Rate</p>
        <p>{formatNumberIntoPercentage(loanProgramData?.interest_rate, '-')}</p>
      </div>
      <div className="flex items-center" data-testid="due_in">
        <p className="w-1/2 text-sm">Terms/Due in</p>
        <p>
          {loanProgramData &&
            (loanProgramData?.term ??
              '-' + '/' + (loanProgramData?.due_in || '-'))}
          {!loanProgramData && '-'}
        </p>
      </div>
      <div className="flex items-center" data-testid="first_payment_date">
        <p className="w-1/2 text-sm">1st PMT Date</p>
        <p>
          {loanProgramData?.first_payment_date
            ? getDate(loanProgramData?.first_payment_date)
            : '-'}
        </p>
      </div>
      <div className="flex items-center" data-testid="qual_rate">
        <p className="w-1/2 text-sm">Qual Rate</p>
        <p> {formatNumberIntoPercentage(loanProgramData?.qual_rate, '-')}</p>
      </div>
      <div className="flex items-center" data-testid="apr">
        <p className="w-1/2 text-sm">APR</p>
        <p> {formatNumberIntoPercentage(loanProgramData?.apr, '-')}</p>
      </div>
    </Card>
  );
};

export default LoanAmountBuydown;
