import Modal from 'components/modal';
import React from 'react';
//components
import Button from 'components/button';
import Loader from 'wrapper/loader';

//icons
import closeIcon from 'assets/icons/closeIcon.svg';
import User from 'components/userV2';

//rtk query
import { useGetPipelineStatusQuery } from 'redux_store/services/client/pipeline';
import {
  useGetLoanProgramQuery,
  useGetLoanTermQuery,
  useGetLoanTypeQuery,
  useGetLoanPurposeQuery,
} from 'redux_store/services/admin/pipeline';

//constants
import { summaryMapping, summaryCards } from 'pages/loan-information/constants';
import { useGetPropertyTypeQuery } from 'redux_store/services/client/profile';
import {
  formatNumberIntoCurrency,
  formatNumberIntoPercentage,
} from 'constants/formatNumber';
/**
 * Function: UserSummary
 *
 * Description: Renders a modal with user summary details based on selected row data.
 *
 * @param {Object} openSummary - Boolean flag to control the visibility of the modal.
 * @param {Function} setOpenSummary - Function to set the state of openSummary.
 * @param {Object} selectedRowData - Data of the selected row containing user details.
 * @param {Boolean} isLoading - Boolean flag to indicate if data is loading.
 * @param {Boolean} isError - Boolean flag to indicate if an error occurred.
 * @param {Function} handleSelectedRowData - Function to handle selected row data.
 *
 * @returns {JSX.Element} A modal displaying user summary details.
 */
const UserSummary = ({
  openSummary,
  setOpenSummary,
  selectedRowData,
  isLoading,
  isError,
  handleSelectedRowData,
  selectedData,
  buttonText,
}) => {
  // fetch lead status
  const { data: pipelineStatusData, isError: pipelineStatusError } =
    useGetPipelineStatusQuery(
      {},
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const { data: loanProgram } = useGetLoanProgramQuery();
  const { data: loanType } = useGetLoanTypeQuery();
  const { data: loanTerm } = useGetLoanTermQuery();
  const { data: loanPurpose } = useGetLoanPurposeQuery();
  const { data: propertyType } = useGetPropertyTypeQuery();

  const formatValue = (value, format) => {
    if (value === '-' || value === null || value === undefined) return '-';

    switch (format) {
      case 'currency':
        return formatNumberIntoCurrency(value);
      case 'percentage':
        return formatNumberIntoPercentage(value);
      case 'date':
        return new Date(value).toLocaleDateString('en-US');
      default:
        return value;
    }
  };

  //status mapping
  const statusObject = pipelineStatusData?.data?.find(
    (data) => data.applicationStatusId === selectedData?.user_info?.statusId
  );
  const statusText = statusObject ? statusObject.name : '';
  //selected row data
  let data = selectedRowData?.application_details;

  const getMappedValue = (key, value) => {
    switch (key) {
      case 'loan_program_id':
        return (
          loanProgram?.data?.find((program) => program.loanProgramId === value)
            ?.name || value
        );
      case 'property_type_id':
        return (
          propertyType?.data?.find(
            (program) => program.propertyTypeId === value
          )?.name || value
        );
      case 'loan_term_id':
        return (
          Number(
            loanTerm?.data?.find((program) => program.loanTermId === value)
              ?.name || value
          ) * 12
        );
      case 'loan_type_id':
        return (
          loanType?.data?.find((program) => program.loanTypeId === value)
            ?.name || value
        );
      case 'purchase_type_id':
        return (
          loanPurpose?.data?.find((program) => program.loanPurposeId === value)
            ?.name || value
        );
      default:
        return value;
    }
  };

  return (
    <Modal show={openSummary} width="max-w-[550px]">
      <div className="p-3">
        <div
          className={`flex ${isError ? 'justify-end' : 'justify-between bg-chatbot_bg'} items-start p-2 rounded`}
        >
          {!isError && (
            <User
              size="md"
              title={selectedRowData?.user_details?.first_name}
              phone={selectedRowData?.user_details?.phone_number}
              email={selectedRowData?.user_details?.email}
              status={statusText}
            />
          )}
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-3"
            onClick={() => {
              setOpenSummary(false);
            }}
          />
        </div>
        {!isLoading && !isError ? (
          <div>
            <div className="border border-grey rounded mt-4 mb-4 truncate">
              {summaryCards.gridA?.map((name, index) => {
                const { key, format } = summaryMapping[name];
                const rawValue =
                  data[key] !== null && data[key] !== undefined
                    ? getMappedValue(key, data[key])
                    : '-';
                const value = formatValue(rawValue, format);

                return (
                  <div key={index} className="p-3 flex flex-col">
                    <span className="text-disabled p-xs-med">{name}</span>
                    <span className="p-xs-med">{value}</span>
                  </div>
                );
              })}
              <div className=" rounded mb-4 grid grid-cols-3 gap-2 truncate">
                {summaryCards.gridB?.map((name, index) => {
                  const { key, format } = summaryMapping[name];
                  const rawValue =
                    data[key] !== null && data[key] !== undefined
                      ? getMappedValue(key, data[key])
                      : '-';
                  const value = formatValue(rawValue, format);

                  return (
                    <div key={index} className="p-3 flex flex-col">
                      <span className="text-disabled p-xs-med">{name}</span>
                      <span className="p-xs-med">
                        <p className="text-wrap break-words">{value}</p>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="border border-grey rounded mt-2 mb-2 grid grid-cols-3 gap-2 truncate">
              {summaryCards.gridC?.map((name, index) => {
                const { key, format } = summaryMapping[name];

                const rawValue =
                  data[key] !== null && data[key] !== undefined
                    ? getMappedValue(key, data[key])
                    : '-';
                const value = formatValue(rawValue, format);
                return (
                  <div key={index} className="p-3 flex flex-col">
                    <span className="text-disabled p-xs-med">
                      <p className="text-wrap break-words">{name}</p>
                    </span>
                    <span className="p-xs-med">
                      <p className="text-wrap break-words">{value}</p>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : isLoading ? (
          <div className="mt-44">
            <Loader />
          </div>
        ) : (
          <div className="text-center m-10">Application not found</div>
        )}
        {!isError && (
          <div className="flex justify-end gap-2 mt-4">
            <Button
              variant="primary"
              type="submit"
              onClick={() => handleSelectedRowData('edit', selectedData)}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UserSummary;
