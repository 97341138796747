import { api } from 'constants/constant';
import { getHeaders, getUserDetails } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const onboardingAPI = createApi({
  reducerPath: 'onboarding',
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  tagTypes: ['getOnboarding'],
  endpoints: (builder) => ({
    // generate application id after successful onboarding
    createApplication: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/loan/application`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
    }),
    // post onboarding data to API
    createOnboarding: builder.mutation({
      query: ({ data }) => {
        return {
          url: `user/onboarding_profile`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getOnboarding'],
    }),
    // update/put onboarding data to API
    updateOnboarding: builder.mutation({
      query: ({ data }) => {
        return {
          url: `user/onboarding_profile`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getOnboarding'],
    }),
    // fetch onboarding data from API
    getOnboardingData: builder.query({
      query: () => {
        return {
          url: `user/onboarding_profile/`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['getOnboarding'],
    }),
    getOnboarding: builder.query({
      query: () => {
        return {
          url: `loan/application_dropdowns/application_status`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    isFirstTimeUser: builder.mutation({
      query: () => {
        const { userId, userEmail, firstName, lastName } = getUserDetails();
        return {
          url: `/user/is_first_time_user`,
          method: 'POST',
          headers: getHeaders(),
          body: {
            user_id: String(userId),
            source: 'auth0',
            email: userEmail,
            first_name: firstName,
            last_name: lastName,
          },
        };
      },
    }),
    //get list of loan officers
    getLoanOfficers: builder.query({
      query: () => {
        return {
          url: `/user/loan_officers/`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    //get user details
    getUserInfo: builder.query({
      query: () => {
        const { userId } = getUserDetails();
        return {
          url: `/user/user_profiles/${userId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    getLoanOfficerDetails: builder.query({
      query: () => {
        return {
          url: `user/get_loan_officer_details`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
  }),
});

export const {
  useCreateApplicationMutation,
  useGetLoanOfficersQuery,
  useIsFirstTimeUserMutation,
  useGetUserInfoQuery,
  useCreateOnboardingMutation,
  useUpdateOnboardingMutation,
  useLazyGetOnboardingQuery,
  useGetOnboardingDataQuery,
  useGetLoanOfficerDetailsQuery,
} = onboardingAPI;
