export const formatNumberIntoCurrency = (input, placeholder = '$0') => {
  if (input !== undefined && input !== null && input !== '') {
    // Try converting the input to a number if it isn't already
    const number = typeof input === 'number' ? input : parseFloat(input);

    // If the conversion results in NaN, return the placeholder
    if (isNaN(number)) {
      return placeholder;
    }

    // Use Intl.NumberFormat for locale-aware formatting
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    }).format(number);
  } else {
    return placeholder; // Return the placeholder for undefined, null, or empty string inputs
  }
};

export const formatNumberIntoPercentage = (input, placeholder = '0%') => {
  if (input !== undefined && input !== null && input !== '') {
    // Try converting the input to a number if it isn't already
    let number = typeof input === 'number' ? input : parseFloat(input);

    // If the conversion results in NaN, return '0%'
    if (isNaN(number)) {
      return placeholder;
    }
    // the division is cause Intl.NumberFormat multiplies value by 100
    number = number / 100;
    // Use Intl.NumberFormat for locale-aware formatting
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    }).format(number);
  } else {
    return placeholder; // Return '0%' for undefined, null, or empty string inputs
  }
};
