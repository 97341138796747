import React from 'react';
/**
 * Renders a badge component with a given title and variant.
 *
 * @param {Object} props - The props object containing the title and variant.
 * @param {string} props.title - The title of the badge.
 * @param {string} props.variant - The variant of the badge.
 * @returns {JSX.Element} The rendered badge component.
 */
const Badge = ({ title, variant }) => {
  return (
    <div
      className={`p-xs-regular px-2.5 py-1 rounded-md ${
        (variant === 'primary' && 'text-custom-green bg-chatbot_bg') ||
        (variant === 'secondary' && 'text-warning300 bg-warning_light') ||
        (variant === 'tertiary' && 'text-error300 bg-error_light') ||
        (variant === 'success' && 'text-success300 bg-success_light') ||
        (variant === 'verifying' && 'text-secondary900 bg-primary200') ||
        (variant === 'rejected' && 'text-white bg-error100')
      }`}
      data-testid={variant}
    >
      {title}
    </div>
  );
};

export default Badge;
