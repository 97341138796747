import { useState, useEffect } from 'react';
//libs
import { motion } from 'framer-motion';
//form data
import quickPriceFormData from './formData/quickPrice';
//components
import Button from 'components/button/index.jsx';
import DynamicForm from 'pages/profile/DynamicForm.jsx';
import { customToast } from 'components/toast/index.jsx';
import Loader from 'wrapper/loader/index.jsx';
import Breadcrumb from 'components/breadcrumb';
//utils
import {
  checkValidations,
  createFormObject,
  formatData,
} from 'pages/profile/utils/index.js';
//logs
import * as Sentry from '@sentry/browser';
//RTK for dropdowns
import {
  useGetCommitmentPeriodQuery,
  useGetCompensationPayerTypeQuery,
  useGetEscrowQuery,
  useGetFirstTimeHomebuyerQuery,
  useGetFlexTermQuery,
  useGetLoanPurposeQuery,
  useGetLoanTermsQuery,
  useGetLoanTypeQuery,
  useGetOccupancyQuery,
  useGetProcessingOptionQuery,
  useGetPropertyTypeQuery,
  useGetRefinancePurposeQuery,
  useGetBuydownTypeQuery,
  useGetCountryOptionQuery,
  useGetRateVendorsQuery,
  useGetStatesQuery,
} from 'redux_store/services/admin/product-pricing.js';
import { useGetQuickPriceMutation } from 'redux_store/services/admin/quickPrice';
//import MortageQuote from 'pages/borrower-profile/product-pricing/MortageQuote';
import ManageScenarios from './manageScenarios';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';
const QuickPrice = () => {
  const [filteredVendorData, setFilteredVendorData] = useState();
  const [formData, setFormData] = useState({
    ...createFormObject(quickPriceFormData),
  });
  const [quoteData, setQuoteData] = useState();
  const [isManageScenarioActive, setIsManageScenarioActive] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { data: vendorsData } = useGetRateVendorsQuery();
  const { data: commitmentPeriod } = useGetCommitmentPeriodQuery();
  const { data: compensationPayerType } = useGetCompensationPayerTypeQuery();
  const { data: escrow } = useGetEscrowQuery();
  const { data: firstTimeHomebuyer } = useGetFirstTimeHomebuyerQuery();
  const { data: flexTerm } = useGetFlexTermQuery();
  const { data: loanPurpose } = useGetLoanPurposeQuery();
  const { data: loanTerms } = useGetLoanTermsQuery();
  const { data: loanType } = useGetLoanTypeQuery();
  const { data: occupancy } = useGetOccupancyQuery();
  const { data: processingOption } = useGetProcessingOptionQuery();
  const { data: propertyType } = useGetPropertyTypeQuery();
  const { data: refinancePurpose } = useGetRefinancePurposeQuery();
  const { data: buydownType } = useGetBuydownTypeQuery();
  const { data: countryType } = useGetCountryOptionQuery();
  const { data: stateType } = useGetStatesQuery();
  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Quick Price',
      path: '/quickPrice',
    },
  ]);
  const [quickPrice, { isLoading, error: errorData }] =
    useGetQuickPriceMutation();
  useEffect(() => {
    if (vendorsData?.success) {
      const filteredVendorData = vendorsData?.data?.map((item) => {
        return { lenderTypeId: item.vendorId, name: item.vendorName };
      });
      /* filteredVendorData[1] = {
        lenderTypeId: 3,
        name: 'Provident Funding',
        isdisabled: true,
      };
      filteredVendorData[2] = {
        lenderTypeId: 4,
        name: 'Rocket Mortgage',
        isdisabled: true,
      }; */
      setFilteredVendorData([filteredVendorData[0]]);
    }
  }, [vendorsData]);
  //map dropdowns with its id's
  const dropdowns = {
    lenderType: formatData(filteredVendorData, 'lenderTypeId'),
    loanType: formatData(loanType?.data, 'loanTypeId'),
    purposeType: formatData(loanPurpose?.data, 'loanPurposeId'),
    refinancePurposeType: formatData(
      refinancePurpose?.data,
      'refinancePurposeId'
    ),
    occupancyType: formatData(occupancy?.data, 'occupancyTypeId'),
    propertyType: formatData(propertyType?.data, 'propertyTypeId'),
    commitmentPeriodType: formatData(
      commitmentPeriod?.data,
      'commitmentPeriodId'
    ),
    compensationPayerType: formatData(
      compensationPayerType?.data,
      'compensationPayerTypeId'
    ),
    escrowImpoundWaiverType: formatData(escrow?.data, 'escrowWaiverTypeId'),
    loanTermsType: formatData(loanTerms?.data, 'loanTermId'),
    flexTermsType: formatData(flexTerm?.data, 'flexTermId'),
    firstTimeHomebuyerType: formatData(
      firstTimeHomebuyer?.data,
      'firstTimeHomeBuyerOptionId'
    ),
    processingOptionType: formatData(
      processingOption?.data,
      'processingOptionId'
    ),
    buydownType: formatData(buydownType?.data, 'buyDownTypeId'),
    countryType: formatData(countryType?.data, 'countryId'),
    propertyState: formatData(stateType?.data, 'stateId'),
  };
  // formdata calculations and conditional rendering of certain fields
  useEffect(() => {
    if (formData.loanAmount > 0 && formData.appraisedValue > 0) {
      setFormData((prev) => {
        const loanAmount = parseFloat(formData.loanAmount);
        const appraisedValue = parseFloat(formData.appraisedValue);
        const calculation = (loanAmount / appraisedValue) * 100;
        return { ...prev, ltv: parseFloat(calculation).toFixed(3) };
      });
    }
    if (formData.secondLoanAmount === 0 || !formData.secondLoanAmount) {
      setFormData((prev) => {
        return { ...prev, cltv: formData.ltv };
      });
    } else {
      setFormData((prev) => {
        const loanAmountPlusSecondLoanAmount =
          parseFloat(formData.loanAmount) +
          parseFloat(formData.secondLoanAmount);
        const appraisedValue = parseFloat(formData.appraisedValue);
        const calculation =
          (loanAmountPlusSecondLoanAmount / appraisedValue) * 100;
        return { ...prev, cltv: parseFloat(calculation).toFixed(3) };
      });
    }
    if (formData?.purposeType?.id === 1) {
      setFormData((formData) => ({
        ...formData,
        appraisedValue: formData.salesPrice,
      }));
    }
  }, [
    formData.loanAmount,
    formData.appraisedValue,
    formData.secondLoanAmount,
    formData.salesPrice,
    formData?.purposeType,
    formData?.ltv,
  ]);
  //auto select of compensation payer type
  useEffect(() => {
    if (formData.correspondent) {
      setFormData((formData) => ({
        ...formData,
        compensationPayerType: { id: 1 },
      }));
    }
  }, [formData.correspondent]);
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const errors = checkValidations(quickPriceFormData, formData);
    if (Object.keys(errors)?.length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
    } else {
      setIsFormSubmitted(false);
      setErrors({});
      try {
        let data = {
          isCorrespondent: formData?.isCorrespondent ?? false,
          vendorId: formData.lenderType.id,
          companyName: formData?.companyName,
          loanOfficer: formData?.loanOfficer,
          borrowerName: formData?.borrowerName,
          loanTypeId: formData?.loanType?.id,
          buyDownTypeId: formData?.buydownType?.buyDownTypeId ?? 5,
          loanAmount: formData?.loanAmount,
          secondLoanAmount: formData?.secondLoanAmount,
          salesPrice: formData?.salesPrice !== '' ? formData?.salesPrice : 0,
          appraisedValue: formData?.appraisedValue,
          LTV: formData?.ltv,
          CLTV: formData?.cltv,
          loanPurposeId: formData?.purposeType?.id,
          refinancePurposeId: formData?.refinancePurposeType?.id,
          occupancyTypeId: formData?.occupancyType?.id,
          propertyTypeId: formData?.propertyType?.id,
          numberOfUnits: Number(formData?.noOfUnits),
          county: formData?.county,
          propertyState: formData?.propertyState?.name,
          propertyStateId: formData?.propertyState?.stateId,
          zipCode: formData?.zipCode,
          countryId: formData?.countryType?.id,
          financedProperties: formData?.financedProperties,
          creditScore: formData?.creditScore,
          monthlyIncome: formData?.monthlyIncome || 0,
          isHigherDTI: formData?.isHigherDTI,
          isNewConstruction: formData?.isNewConstruction ?? 0,
          isSelfEmployed: formData?.isSelfEmployed ?? 0,
          commitmentPeriodId: formData?.commitmentPeriodType?.id,
          compensationPayerTypeId: formData?.compensationPayerType?.id,
          escrowWaiverTypeId: formData?.escrowImpoundWaiverType?.id,
          loanTermId: formData?.loanTermsType?.id,
          flexTermId: formData?.flexTermsType?.id,
          firstTimeHomeBuyerOptionId: formData?.firstTimeHomebuyerType?.id,
          processingOptionId: formData?.processingOptionType?.id,
          emailId: formData?.emailId,
        };
        const resp = await quickPrice({
          data: replaceEmptyStringWithNull(data),
        }).unwrap();
        if (resp?.status === 200) {
          customToast.success(resp?.message);
          setQuoteData(resp?.data);
          setIsManageScenarioActive(true);
          setBreadCrumbs((prev) => {
            return [
              ...prev,
              {
                name: 'Manage Scenarios',
                path: '/quickPrice',
              },
            ];
          });
        }
      } catch (error) {
        customToast.error(error?.data?.message);
        Sentry.captureException(error);
        //console.log(error);
      } finally {
      }
    }
  };
  return (
    <>
      <div className="h-[85vh] w-[100%] flex flex-col justify-between">
        <Breadcrumb
          breadcrumbs={breadCrumbs}
          clearSwitchComponent={() => {
            setBreadCrumbs([
              {
                name: 'Dashboard',
                path: '/dashboard',
              },
              {
                name: 'Quick Price',
                path: '/quickPrice',
              },
            ]);
            setIsManageScenarioActive(false);
          }}
        />
        {isManageScenarioActive ? (
          <ManageScenarios
            quoteData={quoteData}
            formData={formData}
            setBreadCrumbs={setBreadCrumbs}
          />
        ) : (
          <>
            {' '}
            <div className="h-[100%] w-[100%] overflow-y-scroll my-3 ">
              <motion.div
                initial={{
                  opacity: 0,
                  //scale: 0,
                }}
                animate={{
                  opacity: 1,
                  // scale: 1
                }}
              >
                <DynamicForm
                  data={quickPriceFormData}
                  formData={formData}
                  setFormData={setFormData}
                  dropdowns={dropdowns}
                  errors={errors}
                  isSubmitted={isFormSubmitted}
                  //additionInfo={applicationData?.data}
                />
              </motion.div>
            </div>
            <div className="h-fit flex flex-row justify-end p-3">
              <Button
                variant="primary"
                onClick={onSubmitHandler}
                type="button"
                disabled={isLoading}
                btnClassName="px-6"
              >
                {isLoading ? (
                  <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                    <Loader loaderText="Generating" FullScreen={false} />
                  </div>
                ) : (
                  'Quick Price'
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default QuickPrice;
