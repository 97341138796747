import react from 'react';
import { motion } from 'framer-motion';
//components
import Button from 'components/button';
import H1 from 'components/h1-typography';
//dummy data
import duTableData from './duTableData';
import lpaTableData from './lpaTableData';

const CreditHistory = () => {
  const tableHeadings = [
    'Request Date',
    'Time',
    'Status',
    'Recommendation',
    'Findings',
    'Credit Report',
  ];
  return (
    <>
      <div className="h-[70vh] w-[100%] flex flex-col py-3 overflow-y-scroll overflow-x-scroll px-3">
        <H1>Desktop Underwriting Results</H1>
        <table>
          <tr className="w-[100%] p-3 bg-chatbot_bg">
            {tableHeadings.map((heading, index) => {
              return (
                <td className="w-[16%] p-3" key={index}>
                  <p className="mx-2 p-xs-bold">{heading}</p>
                </td>
              );
            })}
          </tr>
          {duTableData?.map((item, index) => {
            return (
              <motion.tr
                initial={{
                  y: -10,
                }}
                animate={{
                  x: 0,
                  y: 0,
                  scale: 1,
                  rotate: 0,
                }}
                className="w-[100%] px-3 border-t border-t-secondary100"
                key={index}
              >
                <td className="w-[16%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.requestDate}</p>
                </td>
                <td className="w-[16%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.time}</p>
                </td>
                <td className="w-[16%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.status}</p>
                </td>
                <td className="w-[16%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.recommendation}</p>
                </td>
                <td className="w-[16%] p-3">
                  {item?.findings && (
                    <p className="mx-2 text-primary500 p-xs-med">{item.findings}</p>
                  )}
                </td>
                <td className="w-[16%] p-3">
                  {item?.creditReport && (
                    <p className="mx-2 text-primary500 p-xs-med">{item.creditReport}</p>
                  )}
                </td>
              </motion.tr>
            );
          })}
        </table>

        <div className="mt-40"></div>
        <H1>Loan Product Advisor Results</H1>
        <table>
          <tr className="w-[100%] p-3 bg-chatbot_bg">
            {tableHeadings.map((heading, index) => {
              return (
                <td className="w-[16%] p-3" key={index}>
                  <p className="mx-2 p-xs-bold">{heading}</p>
                </td>
              );
            })}
          </tr>
          {lpaTableData?.map((item, index) => {
            return (
              <motion.tr
                initial={{
                  y: -10,
                }}
                animate={{
                  x: 0,
                  y: 0,
                  scale: 1,
                  rotate: 0,
                }}
                className="w-[100%] px-3 border-t border-t-secondary100"
                key={index}
              >
                <td className="w-[16%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.requestDate}</p>
                </td>
                <td className="w-[16%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.time}</p>
                </td>
                <td className="w-[16%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.status}</p>
                </td>
                <td className="w-[16%] p-3 py-6">
                  <p className="mx-2 p-xs-med">{item.recommendation}</p>
                </td>
                <td className="w-[16%] p-3">
                  {item?.findings && (
                    <p className="mx-2 text-primary500 p-xs-med">{item.findings}</p>
                  )}
                </td>
                <td className="w-[16%] p-3">
                  {item?.creditReport && (
                    <p className="mx-2 text-primary500 p-xs-med">{item.creditReport}</p>
                  )}
                </td>
              </motion.tr>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default CreditHistory;
