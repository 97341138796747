// React and Components
import React, { useEffect, useLayoutEffect, useState } from 'react';
import DynamicForm from '../DynamicForm';
import Button from 'components/button';
import H1 from 'components/h1-typography';
import TermsAndConditions from 'wrapper/terms-and-conditions';
import Checkbox from 'components/checkbox';
import Modal from 'components/modal';
import FormWrapper from 'wrapper/form-wrapper';
import DynamicTabs from 'components/dynamicTabs';
import { customToast } from 'components/toast';
import ConfirmDeleteTab from 'wrapper/deleteTab';
//icons
import plus from 'assets/icons/bluePlus.svg';
// Utils
import { realEstateOwned } from '../consumerFormFields/realEstateOwned';
import {
  checkValidations,
  createFormObject,
  formatData,
  removeEmptyStrings,
} from '../utils';
// RTK Queries
import { useGetOccupancyQuery } from 'redux_store/services/client/profile';
import {
  useGetDynamicApplicationQuery,
  useCreateRealEstateDetailsV1Mutation,
  useUpdateRealEstateDetailsV1Mutation,
  useDeleteRealEstateByIdMutation,
  useGetStatesQuery,
} from 'redux_store/services/client/profile';
// Sentry Logs
import * as Sentry from '@sentry/browser';
import { useSelector, useDispatch } from 'react-redux';
import { REODataSelector, resetPageInfo } from 'redux_store/slices/application';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from 'auth/auth';
const RealEstateOwned = ({ setPage, setPreviousPage, token }) => {
  // States adn Refs
  const [formData, setFormData] = React.useState({
    ...createFormObject(realEstateOwned),
    occupancyRate: 75,
    isTermsAccepted: '',
  });
  const dispatch = useDispatch();
  const userId = getUserDetails()?.userId;
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const isEdit = React.useRef(false);
  const [showTermsAndConditions, setShowTermsAndConditions] =
    React.useState(false);
  const [tab, setTab] = useState(0);
  const [isError, setIsError] = useState(false);
  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false);
  const [allReoArray, setAllReoArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabsArray, setTabsArray] = useState([
    {
      id: 0,
      name: 'Primary Property',
      isActive: true,
      isError: false,
    },
  ]);
  //Selectors
  const REODataAI = useSelector(REODataSelector);
  const navigate = useNavigate();
  // RTK Queries
  const { data: occupancyData } = useGetOccupancyQuery();
  const { data: stateType } = useGetStatesQuery();
  const [createRealEstateData, { isLoading: isCreateRealEstateDataLoading }] =
    useCreateRealEstateDetailsV1Mutation();
  const [updateRealEstateData, { isLoading: isUpdateRealEstateDataLoading }] =
    useUpdateRealEstateDetailsV1Mutation();
  const { data: applicationData, refetch } = useGetDynamicApplicationQuery(
    {
      application_id: token,
      userId,
    },
    {
      skip: !token,
    }
  );
  const [deleteREOsDetails, { isLoading: isREOsDeleteLoading }] =
    useDeleteRealEstateByIdMutation();
  const dropdowns = {
    occupancyTypeId: formatData(occupancyData?.data, 'occupancyTypeId'),
    stateName: formatData(stateType?.data, 'stateId'),
  };
  const formHasNoErrors = () => {
    const errors = checkValidations(realEstateOwned, formData, {
      ...applicationData?.data,
      personal_details: applicationData?.data?.personal_details,
    });
    if (tab === 0 && !formData?.isTermsAccepted) {
      setIsSubmitted(true);
      setIsError(true);
      return false;
    }
    if (Object.keys(errors)?.length > 0) {
      setIsSubmitted(true);
      setErrors(errors);
      setIsError(true);
      return false;
    } else {
      setErrors({});
      setIsSubmitted(false);
      setIsError(false);
      return true;
    }
  };
  const getNextOrder = () => {
    return allReoArray.reduce((max, obj) => Math.max(max, obj.order), 0) + 1;
  };
  const ApiFormatData = (type) => {
    return {
      ...formData,
      occupancy: String(formData?.occupancyTypeId.id),
      isRentalForIncome: formData?.isRentalForIncome?.id === 1 ? true : false,
      escrowedPayment:
        formData?.escrowedPayment?.id === 1
          ? true
          : formData?.escrowedPayment?.id === 2
            ? false
            : null,
      rentalIncome: formData?.rentalIncome ? formData?.rentalIncome : 0,
      mortgageBalance: formData?.mortgageBalance
        ? formData?.mortgageBalance
        : 0,
      monthlyPayment: formData?.monthlyPayment ? formData?.monthlyPayment : 0,
      occupancyRate: 75,
      netRentalIncome: formData?.netRentalIncome
        ? formData?.netRentalIncome
        : 0,
      mortgagePayment: formData?.mortgagePayment
        ? formData?.mortgagePayment
        : 0,
      taxes: formData?.taxes ? formData?.taxes : 0,
      insurance: formData?.insurance ? formData?.insurance : 0,
      HOA: formData?.HOA ? formData?.HOA : 0,
      zipCode: String(formData?.zipCode ?? ''),
      stateName: formData?.stateName?.name,
      stateId: formData?.stateName?.id ?? null,
      tandcCompleted: formData?.isTermsAccepted ? '1' : '0',
      order: formData?.order ?? getNextOrder(),
      userId,
      applicationId: token,
      userProfileId: applicationData?.data?.personal_details?.userProfileId,
    };
  };
  // form submit handler
  const handleUpdateFunction = async (e) => {
    if (formHasNoErrors()) {
      setIsSubmitted(false);
      try {
        if (isLoading) {
          return false;
        }
        //condition to update the Form when AI is involved in form filling
        if (Object.keys(REODataAI)?.length > 0) {
          const refetchedData = await refetch();
          if (refetchedData?.isSuccess) {
            const uniqueId =
              refetchedData?.data?.data?.user_reos_details?.[0]?.userREOId;
            const data = {
              ...ApiFormatData('update'),
              userREOId: uniqueId,
            };
            const resp = await customToast.promise(
              updateRealEstateData({
                data: removeEmptyStrings(data),
              }),
              'Saving',
              tab === 0
                ? `Primary Property tab updated successfully.`
                : `Additional Property ${tab} tab updated successfully.`,
              'Error While Saving'
            );
            if (resp?.data?.status === 200) {
              setIsLoading(false);
              dispatch(
                resetPageInfo({
                  name: 'REOData',
                })
              );
              return true;
            }
            return false;
          }
        }
        if (formData?.userREOId) {
          setIsLoading(true);
          // if reo's id exist then update the existing employee
          let resp = await customToast.promise(
            updateRealEstateData({
              data: removeEmptyStrings(ApiFormatData('update')),
            }),
            'Saving',
            tab === 0
              ? `Primary Property tab updated successfully.`
              : `Additional Property ${tab} tab updated successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        } else {
          //create reos if id does'nt exist
          let resp = await customToast.promise(
            createRealEstateData({
              data: removeEmptyStrings(ApiFormatData('create')),
            }),
            'Saving',
            tab === 0
              ? `Primary Property tab added successfully.`
              : `Additional Property ${tab} tab added successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };
  const UpdateFormDataOnLoad = (data, currentTab) => {
    let personalDetails = applicationData?.data?.personal_details;
    isEdit.current = true;
    const formData = data;
    if (currentTab > 0) {
      setFormData({
        ...formData,
        occupancyRate: 75,
        cityName: formData?.cityName ?? null,
        stateName: { id: formData?.stateId ?? null },
        propertyAddress: formData?.propertyAddress ?? null,
        zipCode: formData?.zipCode ?? null,
        ownedBy: formData?.ownedBy,
        occupancyTypeId: { id: Number(formData?.occupancy) },
        isTermsAccepted: formData?.tandcCompleted === '1' ? true : false,
        ...REODataAI,
        escrowedPayment:
          REODataAI?.escrowedPayment === 'Yes' ||
          REODataAI?.escrowedPayment === 'No' ||
          REODataAI?.escrowedPayment === true ||
          REODataAI?.escrowedPayment === false
            ? {
                id:
                  REODataAI?.escrowedPayment === 'Yes' ||
                  REODataAI?.escrowedPayment === true
                    ? 1
                    : 2,
              }
            : formData?.escrowedPayment
              ? {
                  id:
                    formData?.escrowedPayment === '1'
                      ? 1
                      : formData?.escrowedPayment === '0'
                        ? 2
                        : '',
                }
              : '',

        isRentalForIncome:
          REODataAI?.isRentalForIncome === 'Yes' ||
          REODataAI?.isRentalForIncome === 'No' ||
          REODataAI?.isRentalForIncome === true ||
          REODataAI?.isRentalForIncome === false
            ? {
                id:
                  REODataAI?.isRentalForIncome === 'Yes' ||
                  REODataAI?.isRentalForIncome === true
                    ? 1
                    : 2,
              }
            : formData?.isRentalForIncome
              ? {
                  id: formData?.isRentalForIncome === '1' ? 1 : 2,
                }
              : '',
        isTermsAccepted:
          REODataAI?.tandcCompleted === true ||
          REODataAI?.tandcCompleted === false
            ? REODataAI?.tandcCompleted
            : formData?.tandcCompleted === '1'
              ? true
              : false,
      });
      return 0;
    }
    setFormData({
      ...formData,
      occupancyRate: 75,
      cityName:
        personalDetails?.ownershipId === 1
          ? personalDetails?.cityName
          : formData?.cityName ?? '',
      stateName:
        personalDetails?.ownershipId === 1
          ? { id: personalDetails?.stateId ?? null }
          : { id: formData?.stateId ?? null },
      propertyAddress:
        personalDetails?.ownershipId === 1
          ? personalDetails?.currentAddress
          : formData?.propertyAddress ?? null,
      zipCode:
        personalDetails?.ownershipId === 1
          ? personalDetails?.zipCode
          : formData?.zipCode ?? null,
      ownedBy: `${personalDetails?.firstName ?? ''} ${
        personalDetails?.middleName ? personalDetails?.middleName + ' ' : ''
      }${personalDetails?.lastName ?? ''} `,
      occupancyTypeId:
        personalDetails?.ownershipId === 1
          ? { id: 1 }
          : { id: Number(formData?.occupancy) },
      isTermsAccepted: formData?.tandcCompleted === '1' ? true : false,
      ...REODataAI,
      escrowedPayment:
        REODataAI?.escrowedPayment === 'Yes' ||
        REODataAI?.escrowedPayment === 'No' ||
        REODataAI?.escrowedPayment === true ||
        REODataAI?.escrowedPayment === false
          ? {
              id:
                REODataAI?.escrowedPayment === 'Yes' ||
                REODataAI?.escrowedPayment === true
                  ? 1
                  : 2,
            }
          : formData?.escrowedPayment
            ? {
                id:
                  formData?.escrowedPayment === '1'
                    ? 1
                    : formData?.escrowedPayment === '0'
                      ? 2
                      : '',
              }
            : '',

      isRentalForIncome:
        REODataAI?.isRentalForIncome === 'Yes' ||
        REODataAI?.isRentalForIncome === 'No' ||
        REODataAI?.isRentalForIncome === true ||
        REODataAI?.isRentalForIncome === false
          ? {
              id:
                REODataAI?.isRentalForIncome === 'Yes' ||
                REODataAI?.isRentalForIncome === true
                  ? 1
                  : 2,
            }
          : formData?.isRentalForIncome
            ? {
                id: formData?.isRentalForIncome === '1' ? 1 : 2,
              }
            : '',
      isTermsAccepted:
        REODataAI?.tandcCompleted === true ||
        REODataAI?.tandcCompleted === false
          ? REODataAI?.tandcCompleted
          : formData?.tandcCompleted === '1'
            ? true
            : false,
    });
  };
  React.useEffect(() => {
    if (applicationData?.success) {
      const reoArray = applicationData?.data?.user_reos_details;
      const persistedTab = tab ?? 0;
      setAllReoArray(reoArray);
      if (reoArray?.length > 1) {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Property',
            isActive: false,
            isError: false,
          },
        ];
        if (tab === reoArray.length) {
          for (let i = 1; i <= tab; i++) {
            newTabsArray.push({
              id: i,
              name: `Additional Property ${i}`,
              isActive: false,
              isError: false,
            });
          }
          setTab(persistedTab);
          newTabsArray[persistedTab].isActive = true;
          setTabsArray(newTabsArray);
          UpdateFormDataOnLoad(reoArray[persistedTab], persistedTab);
          return;
        }
        for (let i = 1; i <= reoArray.length - 1; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Property ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(persistedTab);
        newTabsArray[persistedTab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(reoArray[persistedTab], persistedTab);
      } else {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Property',
            isActive: false,
            isError: false,
          },
        ];
        for (let i = 1; i <= tab; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Property ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(persistedTab);
        newTabsArray[persistedTab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(reoArray[persistedTab], persistedTab);
      }
    }
  }, [applicationData]);
  const UpdateFormOnAi = () => {
    let personalDetails = applicationData?.data?.personal_details;
    setFormData((formData) => ({
      ...formData,
      occupancyRate: 75,
      cityName:
        personalDetails?.ownershipId === 1 ? personalDetails?.cityName : '',
      stateName:
        personalDetails?.ownershipId === 1
          ? { id: personalDetails?.stateId }
          : '',
      propertyAddress:
        personalDetails?.ownershipId === 1
          ? personalDetails?.currentAddress
          : '',
      zipCode:
        personalDetails?.ownershipId === 1 ? personalDetails?.zipCode : '',
      ownedBy: `${personalDetails?.firstName ?? ' '} ${
        personalDetails?.middleName ? personalDetails?.middleName + ' ' : ''
      }${personalDetails?.lastName ?? ''} `,
      occupancyTypeId:
        personalDetails?.ownershipId === 1
          ? { id: 1 }
          : { id: Number(formData?.occupancy) },
      isTermsAccepted: formData?.tandcCompleted === '1' ? true : false,
      ...REODataAI,
      escrowedPayment:
        REODataAI?.escrowedPayment === 'Yes' ||
        REODataAI?.escrowedPayment === 'No' ||
        REODataAI?.escrowedPayment === true ||
        REODataAI?.escrowedPayment === false
          ? {
              id:
                REODataAI?.escrowedPayment === 'Yes' ||
                REODataAI?.escrowedPayment === true
                  ? 1
                  : 2,
            }
          : formData?.escrowedPayment ?? '',

      isRentalForIncome:
        REODataAI?.isRentalForIncome === 'Yes' ||
        REODataAI?.isRentalForIncome === 'No' ||
        REODataAI?.isRentalForIncome === true ||
        REODataAI?.isRentalForIncome === false
          ? {
              id:
                REODataAI?.isRentalForIncome === 'Yes' ||
                REODataAI?.isRentalForIncome === true
                  ? 1
                  : 2,
            }
          : formData?.isRentalForIncome ?? '',
      isTermsAccepted:
        REODataAI?.tandcCompleted === true ||
        REODataAI?.tandcCompleted === false
          ? REODataAI?.tandcCompleted
          : formData?.tandcCompleted === '1'
            ? true
            : false,
    }));
  };
  useEffect(() => {
    if (Object.keys(REODataAI)?.length > 0) {
      UpdateFormOnAi();
    }
  }, [REODataAI]);
  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);
  const handleAddTab = async () => {
    if (isCreateRealEstateDataLoading || isUpdateRealEstateDataLoading) return;
    if (tabsArray.length < 5) {
      if (await handleUpdateFunction()) {
        setTabsArray((prev) => {
          let newIndex = prev.length;
          setTab(newIndex);
          tabsArray.map((item) => {
            if (item.isActive) item.isActive = false;
            allReoArray[newIndex]
              ? UpdateFormDataOnLoad(allReoArray[newIndex], newIndex)
              : setFormData({
                  ...createFormObject(realEstateOwned),
                  occupancyRate: 75,
                });
          });
          return [
            ...prev,
            {
              id: newIndex,
              name: `Additional Property ${newIndex}`,
              isActive: true,
              isError: false,
            },
          ];
        });
      }
    } else {
      customToast.error("Maximum 5 Property's allowed !");
    }
  };
  const handleDeleteREOs = async () => {
    setIsError(false);
    setConfirmDeleteVisibility(false);
    const userREOId = allReoArray[tab]?.userREOId ?? null;
    if (userREOId > 0) {
      try {
        let resp = await customToast.promise(
          deleteREOsDetails({
            reosId: userREOId,
          }),
          'Deleting',
          tab === 0
            ? `Primary Property tab removed successfully.`
            : `Additional Property ${tab} tab removed successfully.`,
          'Error While Deleting'
        );
        if (resp?.data?.status === 200) {
          setTab(tab - 1);
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
      return;
    }
    let newTabArray = tabsArray?.filter((tabs) => {
      return tabs.id !== tab;
    });
    setTab(tab - 1);
    newTabArray[tab - 1].isActive = true;
    setTabsArray(newTabArray);
    UpdateFormDataOnLoad(allReoArray[tab - 1], tab - 1);
    setIsError(false);
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (await handleUpdateFunction()) {
      navigate('/pipeline');
    }
  };
  return (
    <div className=" overflow-auto">
      <H1>Real Estate Owned</H1>
      <p className="antialiased">
        Provide any real estate assets owned by the borrower and its associated
        details.
      </p>
      <FormWrapper
        className={
          'my-3 mx-2 max-h-custom-profile-forms overflow-hidden overflow-y-scroll max-w-[65vw] pb-3'
        }
      >
        <div className="flex flex-row justify-between border-b border-grey">
          <DynamicTabs
            selectedTab={tab}
            isError={isError}
            handleUpdateFunction={handleUpdateFunction}
            onClickTab={async (selectedTab) => {
              //updated the current tab data and then switch's to new one
              setTab(selectedTab);
              allReoArray[selectedTab]
                ? UpdateFormDataOnLoad(allReoArray[selectedTab], selectedTab)
                : setFormData({
                    ...createFormObject(realEstateOwned),
                    occupancyRate: 75,
                  });
              return true;
            }}
            tabsArray={tabsArray}
            variant="primary"
            onDelete={() => {
              if (formData?.userREOId) {
                setConfirmDeleteVisibility(true);
                return false;
              }
              handleDeleteREOs();
            }}
          />
          <div
            className="flex flex-col justify-center py-2 cursor-pointer p-sm-regular text-primary500 rounded-md"
            onClick={handleAddTab}
          >
            <>
              <div className="flex flex-row justify-center gap-3 min-w-[150px]">
                <img src={plus} alt="+" className="h-[16px]" />
                <p className="font-medium">Add Property</p>
              </div>
            </>
          </div>
        </div>
        <div className="w-full p-3 rounded-lg">
          <form onSubmit={onSubmitHandler}>
            <DynamicForm
              data={realEstateOwned}
              formData={{ ...formData, tab }}
              setFormData={setFormData}
              errors={errors}
              isSubmitted={isSubmitted}
              additionInfo={applicationData?.data ?? {}}
              dropdowns={dropdowns}
            />
            {tab === 0 && (
              <div className={`px-2 flex items-center`}>
                <Checkbox
                  id={`form-field=Terms-and-conditions`}
                  checked={formData?.isTermsAccepted === true}
                  value={'isTermsAccepted'}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      isTermsAccepted: e.target.checked,
                    });
                  }}
                  isError={isSubmitted && !formData?.isTermsAccepted}
                />

                <label
                  className={`${
                    isSubmitted && !formData?.isTermsAccepted
                      ? 'text-error'
                      : 'text-neutral700'
                  } pl-2 font-medium `}
                  for={`form-field=Terms-and-conditions`}
                >
                  I authorize to credit check and agree to the{' '}
                  <Button
                    variant="link"
                    style={{ textDecoration: 'underline', padding: 0 }}
                    type="button"
                    onClick={() => {
                      setShowTermsAndConditions(true);
                    }}
                  >
                    Terms & Conditions.
                  </Button>
                </label>
              </div>
            )}
            <div className="text-right mt-2">
              <Button
                variant="tertiary"
                onClick={() => {
                  setPreviousPage();
                }}
                style={{ marginRight: '10px' }}
                type="button"
              >
                Back: Liabilities
              </Button>
              <Button type="submit" variant="primary">
                {'Complete Profile'}
              </Button>
            </div>
          </form>
          <Modal show={showTermsAndConditions} width="max-w-[50vw]">
            <div className="p-5">
              <TermsAndConditions />
              <div className="text-right">
                <Button
                  type="button"
                  variant={'primary'}
                  onClick={() => setShowTermsAndConditions(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </FormWrapper>
      {confirmDeleteVisibility && (
        <ConfirmDeleteTab
          setConfirmDeleteVisibility={setConfirmDeleteVisibility}
          tabName={`Additional Property ${tab}`}
          selectedTab={tab}
          onDelete={handleDeleteREOs}
        />
      )}
    </div>
  );
};

export default RealEstateOwned;
