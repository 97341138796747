/**
 * Button function
 *
 * This function returns a button element with different styles based on the variant prop.
 *
 * @param {Object} props - The props object containing the variant, children, and other props.
 * @param {string} props.variant - The variant of the button (primary_sm, primary, secondary, tertiary, disabled, enabled, danger).
 * @param {ReactNode} props.children - The content of the button.
 * @param {Object} props... - Additional props to be spread onto the button element.
 * @param {string} props.btnClassName
 * @returns {ReactElement} The button element with the specified variant and content.
 */
const Button = ({ variant, children, btnClassName, ...props }) => {
  const buttonStyles = {
    primary_sm:
      'primary-bg hover:bg-blue-700 text-tertiary p-xs-bold py-2 px-4 rounded-md w-fit flex gap-2 items-center',
    white_sm:
      'text-neutral700 p-xs-med py-2 px-4 rounded-md w-fit flex gap-2 items-center border border-secondary200',
    primary:
      'primary-bg hover:bg-blue-700 text-tertiary font-semibold py-2 px-4 rounded-md w-fit',
    secondary:
      'secondary-bg hover:bg-green-700 text-tertiary font-semibold py-2 px-4 rounded-md w-fit',
    tertiary:
      'bg-neutralGrey hover:bg-gray-700 text-neutral700 font-semibold py-2 px-4 rounded-md w-fit',
    tertiary_sm:
      'bg-neutralGrey hover:bg-gray-700 text-neutral700 p-xs-med py-2 px-4 rounded-md w-fit',
    disabled:
      'disabled-bg hover:bg-gray-700 text-tertiary font-semibold py-2 px-4 rounded-md w-fit',
    enabled:
      'enabled-bg hover:bg-gray-700 text-secondary font-semibold py-2 px-4 rounded w-fit',
    danger:
      'bg-danger100 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded w-fit',
    link: 'bg-white text-primary500 font-semibold py-3 pb-2 rounded w-fit',
  };

  return (
    <button
      className={`${buttonStyles[variant]} ${btnClassName || ''}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
