import React from 'react';
import { getInitials } from 'constants/getFirstChar';
import phoneIcon from 'assets/icons/phoneIcon.svg';
import mailIcon from 'assets/icons/mailIcon.svg';
/**
 * Renders a user component with a profile picture and user information.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The user's title.
 * @param {string} props.phone - The user's phone no.
 * @param {string} props.email - The user's email.
 * @param {string} [props.size='md'] - The size of the user component. Can be 'sm' or 'md'.
 * @returns {JSX.Element} The rendered user component.
 */
const User = ({
  title,
  status,
  phone = '-',
  email = '-',
  size = 'md',
  className,
}) => {
  // Define the sizes for different cases
  const sizes = {
    sm: {
      circle: 'w-[24px] h-[24px] p-xs-regular',
      marginRight: 'mr-0',
    },
    md: {
      circle: 'w-[40px] h-[40px] font-medium-2',
      marginRight: 'mr-0',
    },
  };

  // Retrieve the appropriate size values
  const { circle, marginRight } = sizes[size];
  const borderRadius = parseInt(circle.split('-')[1]) / 2 + 'px';

  return (
    <div className={`flex items-center ${className || ''}`}>
      <div
        className={`${circle} bg-profile rounded-full flex justify-center items-center mr-2 `}
        style={{
          borderRadius,
        }}
      >
        {getInitials(title)}
      </div>
      <div className="flex flex-col justify-between truncate gap-2 ">
        <span
          className={`font-medium-bold whitespace-nowrap py-2 ${marginRight}`}
        >
          {title} &nbsp;
          <span className="border border-primary500 px-1.5 py-0.5 rounded text-primary500 p-xs-med">
            {status}
          </span>
        </span>

        <div className="flex gap-4 mb-2">
          <div className={`flex gap-2 ${marginRight}`}>
            {' '}
            <img src={phoneIcon} alt="" />
            <p className="p-xs-med">{phone}</p>
          </div>

          <span className={`flex gap-2 ${marginRight}`}>
            {' '}
            <img src={mailIcon} alt="" />
            <p className="p-xs-med">{email}</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default User;
