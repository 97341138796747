import { toast } from 'react-toastify';
import './toast.css';
import toastSuccess from 'assets/icons/success.svg';
import toastFailed from 'assets/icons/failed.svg';
import { toastColor } from '../../themes/colors';
/**
 * Creates a custom close button for a toast notification.
 *
 * @param {function} closeToast - The function to close the toast notification.
 * @returns {ReactElement} The custom close button component.
 */
export const CloseButton = ({ closeToast }) => (
  <i
    className="font-medium-2 text-white not-italic px-2 flex items-center"
    onClick={closeToast}
  >
    DISMISS
  </i>
);

//create custom toast function to update toastify icons and color
export const customToast = {
  success: (string) =>
    toast.success(string, {
      icon: <img src={toastSuccess}></img>,
      style: {
        background: toastColor.success,
      },
    }),
  error: (string) =>
    toast.success(string, {
      icon: <img src={toastFailed}></img>,
      style: {
        background: toastColor.error,
      },
    }),
  loading: (message) =>
    toast.loading(message, {
      style: {
        background: toastColor.promise,
        color: 'white',
      },
      closeButton: <CloseButton />,
    }),
  promise: (func, pendingMsg, successMsg, errorMessage) =>
    toast.promise(func, {
      pending: {
        render() {
          return pendingMsg;
        },
        style: {
          background: toastColor.promise,
        },
      },
      success: {
        render() {
          return successMsg;
        },
        icon: <img src={toastSuccess}></img>,
        style: {
          background: toastColor.success,
        },
      },
      error: {
        render() {
          return errorMessage;
        },
        icon: <img src={toastFailed}></img>,
        style: {
          background: toastColor.error,
        },
      },
    }),
};
