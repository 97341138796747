// React and Components
import React, { useEffect, useLayoutEffect } from 'react';
import Button from 'components/button';
import Select from 'react-select';
import Loader from 'wrapper/loader';
import FormWrapper from 'wrapper/form-wrapper';
// Slices
import {
  useGetLoanProgramQuery,
  useGetLoanPurposeQuery,
  useGetLoanTermYearsQuery,
  useGetLoanTypeQuery,
  useGetMannerHeldQuery,
  useGetOccupancyQuery,
  useGetPropertyRightQuery,
  useGetPropertyTypeQuery,
  useGetRefinancePurposeQuery,
  useGetStatesQuery,
} from '../../../../redux/services/client/profile';
//themes
import { bgColors, textColors, borderColors } from '../../../../themes/colors';
// Utils
import * as Sentry from '@sentry/browser';
import { addDays, parse } from 'date-fns';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';

// libraries
import { format } from 'date-fns';
import {
  loanInformation,
  refinanceInformation,
} from 'pages/profile/formfields/loanInformation';
import {
  useGetBorrowerProfileAdminQuery,
  useUpdateLoanDetailsMutation,
  useCreateLoanDetailsMutation,
} from '../../../../redux/services/borrower-profile';
import { useLocation } from 'react-router-dom';
import DynamicForm from 'pages/profile/DynamicForm';
import {
  checkValidations,
  formatData,
  createFormObject,
} from 'pages/profile/utils';
import { customToast } from 'components/toast';
/**
 * Function: LoanInformation
 *
 * Description: This function handles the form submission for loan information. It updates or creates loan details based on the form data provided. It also handles form validations, error handling, and updating the UI based on user input.
 *
 * @param {Object} props - The props object containing readOnly, setReadOnly, resetId, and setResetId properties.
 * @returns {JSX.Element} - Returns the JSX element for the LoanInformation component.
 */
const LoanInformation = ({ readOnly, setReadOnly, resetId, setResetId }) => {
  // States
  const [formData, setFormData] = React.useState({
    ...createFormObject(loanInformation),
    loanPurposeId: 1,
    numberOfUnits: 1,
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  // Selectors

  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState(false);
  const isEdit = React.useRef(false);

  // RTK Queries
  const [updateLoanInformation, { isLoading: isUpdateLoanInformationLoading }] =
    useUpdateLoanDetailsMutation();
  const [creteLoanInformation, { isLoading: isCreteLoanInformationLoading }] =
    useCreateLoanDetailsMutation();
  const { data: loanPurposeData } = useGetLoanPurposeQuery();
  const { data: loanTypeData } = useGetLoanTypeQuery();
  const { data: refinancePurposeData } = useGetRefinancePurposeQuery();
  const { data: occupancyData } = useGetOccupancyQuery();
  const { data: propertyTypeData } = useGetPropertyTypeQuery();
  const { data: loanProgramData } = useGetLoanProgramQuery();
  const { data: lonTermYears } = useGetLoanTermYearsQuery();
  const { data: mannerHeldData } = useGetMannerHeldQuery();
  const { data: propertyRightsData } = useGetPropertyRightQuery();
  const { data: stateType } = useGetStatesQuery();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!formData?.loanPurposeId) {
      setIsFormSubmitted(true);
    } else if (
      formData?.loanPurposeId &&
      Object.keys(
        checkValidations(
          formData?.loanPurposeId === 1
            ? loanInformation
            : refinanceInformation,
          formData,
          {
            ...applicationData?.data,
            personal_details: applicationData?.data?.personal_details,
          }
        )
      ).length > 0
    ) {
      setIsFormSubmitted(true);
      const errors = checkValidations(
        formData?.loanPurposeId === 1 ? loanInformation : refinanceInformation,
        formData
      );
      setErrors(errors);
    } else {
      try {
        const data = {
          ...formData,
          refinancePurposeId: formData?.refinancePurposeId
            ? formData?.refinancePurposeId?.id
            : null,
          occupancyTypeId: formData?.occupancyTypeId
            ? formData?.occupancyTypeId?.id
            : null,
          propertyTypeId: formData?.propertyTypeId
            ? formData?.propertyTypeId?.id
            : null,
          loanProgramId: formData?.loanProgramId
            ? formData?.loanProgramId?.id
            : null,
          propertyRightsId: formData?.propertyRightsId
            ? formData?.propertyRightsId?.id
            : null,
          mannerHeldId: formData?.mannerHeldId
            ? formData?.mannerHeldId?.id
            : null,
          loanTermId: formData?.loanTermId ? formData?.loanTermId?.id : null,
          loanTypeId: formData?.loanTypeId ? formData?.loanTypeId?.id : null,
          salePrice: formData?.salePrice ? formData?.salePrice : 0,
          downpayment: formData?.downpayment ? formData?.downpayment : 0,
          closingDate: formData?.closingDate ? formData?.closingDate : null,
          escrowed: formData?.escrowed?.id === 1 ? true : false,
          firstTimeBuying:
            formData?.firstTimeBuying?.id === 1
              ? true
              : formData?.firstTimeBuying?.id === 2
                ? false
                : null,
          isMixUseProperty: formData?.isMixUseProperty?.id === 1 ? true : false,
          applicationId,
          numberOfUnits: formData?.numberOfUnits ? formData?.numberOfUnits : 1,
          LTV: parseFloat(formData?.LTV) < 0 ? 0 : formData?.LTV,
          zipCode: String(formData?.zipCode ?? ''),
          stateName: formData?.stateName?.name,
          stateId: formData?.stateName ? formData?.stateName?.id : null,
          userProfileId: applicationData?.data?.personal_details?.userProfileId,
          userId: applicationData?.data?.personal_details?.userId,
        };
        if (!data?.closingDate) {
          const currentDate = new Date();
          const futureDate = addDays(currentDate, 45);
          data.closingDate = futureDate;
        }

        if (
          applicationData &&
          Object.keys(applicationData?.data?.loan_details)?.length
        ) {
          const resp = await updateLoanInformation({
            applicationId,
            data: replaceEmptyStringWithNull(data),
          });
          if (resp?.data?.status === 200) {
            customToast.success('Profile Updated Successfully');
          } else {
            customToast.error('Error while updating the Profile');
          }
        } else {
          const resp = await creteLoanInformation({
            data: replaceEmptyStringWithNull(data),
          });
          if (resp?.data?.status === 200) {
            customToast.success('Profile Updated Successfully');
          } else {
            customToast.error('Error while updating the Profile');
          }
        }

        await refetchBorrowerInfo();
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      } finally {
        setIsFormSubmitted(false);
        setReadOnly(true);
      }
    }
  };

  const {
    data: applicationData,
    refetch: refetchBorrowerInfo,
    isLoading,
  } = useGetBorrowerProfileAdminQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  React.useLayoutEffect(() => {
    setIsFormSubmitted(false);
    if (
      applicationData &&
      Object.keys(applicationData?.data?.loan_details)?.length
    ) {
      isEdit.current = true;
      const formData = applicationData?.data?.loan_details;
      setFormData({
        ...formData,
        refinancePurposeId: formData?.refinancePurposeId
          ? {
              id: formData?.refinancePurposeId,
            }
          : '',

        occupancyTypeId: formData?.occupancyTypeId
          ? {
              id: formData?.occupancyTypeId,
            }
          : '',

        propertyTypeId: formData?.propertyTypeId
          ? {
              id: formData?.propertyTypeId,
            }
          : '',

        loanProgramId: formData?.loanProgramId
          ? {
              id: formData?.loanProgramId,
            }
          : '',

        loanTypeId: formData?.loanTypeId
          ? {
              id: formData?.loanTypeId,
            }
          : '',
        stateName: formData?.stateId ? { id: formData?.stateId } : 0,
        propertyRightsId: formData?.propertyRightsId
          ? {
              id: formData?.propertyRightsId,
            }
          : '',

        mannerHeldId: formData?.mannerHeldId
          ? {
              id: formData?.mannerHeldId,
            }
          : '',

        loanTermId: formData?.loanTermId
          ? {
              id: formData?.loanTermId,
            }
          : '',

        escrowed: formData?.escrowed
          ? {
              id: formData?.escrowed === '1' ? 1 : 2,
            }
          : '',

        firstTimeBuying: formData?.firstTimeBuying
          ? {
              id: formData?.firstTimeBuying === '1' ? 1 : 2,
            }
          : '',

        isMixUseProperty: formData?.isMixUseProperty
          ? {
              id: formData?.isMixUseProperty === '1' ? 1 : 2,
            }
          : '',
        closingDate: formData?.closingDate ?? '',
      });
    } else if (applicationData?.data?.personal_details) {
      setFormData((prevData) => ({
        ...(!readOnly
          ? {
              ...prevData,
              loanPurposeId:
                applicationData?.data?.user_onboarding_details?.loanPurposeId,
            }
          : {
              ...createFormObject(loanInformation),
              loanPurposeId:
                applicationData?.data?.user_onboarding_details?.loanPurposeId,
              numberOfUnits: 1,
            }),
        purchaseHomeVestIn:
          (applicationData?.data?.personal_details?.firstName ?? '') +
          ' ' +
          (applicationData?.data?.personal_details?.middleName ?? '') +
          ' ' +
          (applicationData?.data?.personal_details?.lastName ?? ''),
      }));
    }
  }, [applicationData, readOnly]);

  useEffect(() => {
    if (resetId === 2) {
      setFormData(() => ({
        ...createFormObject(loanInformation),
        loanPurposeId: 1,
        numberOfUnits: 1,
      }));
      setResetId(0);
    }
  }, [resetId]);

  useLayoutEffect(() => {
    if (
      formData?.loanPurposeId === 1 &&
      formData?.salePrice &&
      formData?.downpayment &&
      !isNaN(+formData?.salePrice) &&
      !isNaN(+formData?.downpayment)
    ) {
      const LTVValue = Number(
        ((parseFloat(formData?.salePrice) - parseFloat(formData?.downpayment)) /
          parseFloat(formData?.salePrice)) *
          100
      ).toFixed(3);
      setFormData((formData) => ({
        ...formData,
        LTV: parseFloat(LTVValue) < 0 ? 0 : LTVValue,
        loanAmount:
          parseFloat(formData?.salePrice) - parseFloat(formData?.downpayment),
        appraisedValue: formData?.salePrice,
      }));
    } else if (
      formData?.loanPurposeId !== 1 &&
      formData?.loanAmount &&
      formData?.appraisedValue &&
      !isNaN(formData?.loanAmount) &&
      !isNaN(formData?.appraisedValue)
    ) {
      const LTVValue = Number(
        (parseFloat(formData?.loanAmount) /
          parseFloat(formData?.appraisedValue)) *
          100
      ).toFixed(3);
      setFormData((formData) => ({
        ...formData,
        LTV: parseFloat(LTVValue) < 0 ? 0 : LTVValue,
      }));
    }
  }, [
    formData?.salePrice,
    formData?.downpayment,
    formData?.loanPurposeId,
    formData?.loanAmount,
    formData?.appraisedValue,
  ]);

  // dropdown for form fields
  const dropdowns = {
    loanTypeId: formatData(loanTypeData?.data, 'loanTypeId'),
    refinancePurposeId: formatData(
      refinancePurposeData?.data,
      'refinancePurposeId'
    ),
    occupancyTypeId: formatData(occupancyData?.data, 'occupancyTypeId'),
    propertyTypeId: formatData(propertyTypeData?.data, 'propertyTypeId'),
    loanProgramId: formatData(loanProgramData?.data, 'loanProgramId'),
    propertyRightsId: formatData(propertyRightsData?.data, 'propertyRightsId'),
    mannerHeldId: formatData(mannerHeldData?.data, 'mannerHeldId'),
    loanTermId: formatData(lonTermYears?.data, 'loanTermId'),
    stateName: formatData(stateType?.data, 'stateId'),
  };
  const placeholderMapper = (id) => {
    if (loanPurposeData?.data) {
      let placeholder = loanPurposeData?.data.filter((item) => {
        return item.loanPurposeId === id;
      });
      return placeholder[0]?.name ?? 'Select';
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <FormWrapper
          className={
            'my-2 mx-2 max-h-borrower-profile overflow-hidden overflow-y-scroll pr-3 pb-3'
          }
        >
          <form onSubmit={onSubmitHandler}>
            <div className={`px-2 flex items-center my-2 w-2/4`}>
              <label
                className="font-bold text-custom-greengray block w-2/4"
                htmlFor={`form-field-loan-purpose`}
              >
                Purpose of Loan
              </label>
              <Select
                menuShouldScrollIntoView={true}
                menuShouldBlockScroll={true}
                menuPlacement="auto"
                menuPosition="fixed"
                defaultValue={formData?.loanPurposeId}
                onChange={(e) => {
                  setFormData((old) => ({
                    ...createFormObject(
                      e.loanPurposeId === 1
                        ? loanInformation
                        : refinanceInformation
                    ),
                    loanPurposeId: parseInt(e.loanPurposeId),
                    purchaseHomeVestIn: old?.purchaseHomeVestIn,
                    numberOfUnits: 1,
                    userProfileId: old?.userProfileId,
                    userId: old?.userId,
                  }));
                  setErrors({});
                  setIsFormSubmitted(false);
                }}
                options={loanPurposeData?.data}
                isDisabled
                isClearable={false}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionValue={(value) => {
                  return value.loanPurposeId;
                }}
                placeholder={placeholderMapper(formData?.loanPurposeId)}
                className="w-full"
                noOptionsMessage={() => 'No Data found'}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    fontSize: '12px',
                    position: 'relative',
                    fontWeight: '400',
                    borderRadius: '5px',
                    boxShadow: 'none',
                    paddingRight: '9px',
                    border: `2px solid ${
                      isFormSubmitted && !!!formData?.loanPurposeId
                        ? borderColors.errorColor
                        : borderColors.grey
                    }`,
                    '&:hover': {
                      cursor: 'pointer',
                      border: `2px solid ${
                        isFormSubmitted && !!!formData?.loanPurposeId
                          ? borderColors.errorColor
                          : borderColors.grey
                      }`,
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: bgColors.tertiary_bg,
                    color: textColors.black,
                    fontSize: '12px',
                    fontWeight: '400',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: bgColors.primary50,
                    },
                    '&:active': {
                      backgroundColor: bgColors.primary50,
                    },
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    borderRadius: '5px',
                    color: textColors.black,
                    fontSize: '12px',
                    fontWeight: '400',
                  }),
                  indicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral80: textColors.black,
                  },
                })}
              />
            </div>
            {formData?.loanPurposeId === 1 ? (
              <DynamicForm
                data={loanInformation}
                formData={formData}
                setFormData={setFormData}
                dropdowns={dropdowns}
                errors={errors}
                isSubmitted={isFormSubmitted}
                additionInfo={
                  applicationData?.data
                    ? {
                        ...applicationData?.data,
                        personal_details:
                          applicationData?.data?.personal_details,
                      }
                    : {}
                }
                readOnly={readOnly}
              />
            ) : (
              formData?.loanPurposeId !== 1 && (
                <DynamicForm
                  data={refinanceInformation}
                  formData={formData}
                  setFormData={setFormData}
                  dropdowns={dropdowns}
                  errors={errors}
                  isSubmitted={isFormSubmitted}
                  additionInfo={
                    applicationData?.data
                      ? {
                          ...applicationData?.data,
                          personal_details:
                            applicationData?.data?.personal_details,
                        }
                      : {}
                  }
                  readOnly={readOnly}
                />
              )
            )}
            {!readOnly && (
              <div className="text-right mt-2">
                <Button
                  variant="tertiary"
                  style={{ marginRight: '10px' }}
                  type="button"
                  onClick={() => {
                    setReadOnly(true);
                  }}
                >
                  {' '}
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  {isUpdateLoanInformationLoading ||
                  isCreteLoanInformationLoading ? (
                    <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                      <Loader loaderText="Saving" FullScreen={false} />
                    </div>
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            )}
          </form>
        </FormWrapper>
      )}
    </>
  );
};

export default LoanInformation;
