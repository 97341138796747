import React, { useEffect, useState } from 'react';
// components
import H1 from 'components/h1-typography';
import Checkbox from 'components/checkbox';
import Radio from 'components/radio';
import DynamicForm from 'pages/profile/DynamicForm';
import Button from 'components/button';
import { createFormObject } from 'pages/profile/utils';
// RTK query
import {
  useGetCreditDetailsQuery,
  usePostCreditDetailsMutation,
  useGetCreditCompanyQuery,
} from 'redux_store/services/admin/pipeline';
// child imports
import DuformFields from './DuformFields';
import LpaformFields from './LpaFormFields';
// libs
import { useLocation } from 'react-router-dom';
import { customToast } from 'components/toast';

import { formatData } from 'pages/profile/utils';

//logs
import * as Sentry from '@sentry/browser';
const CreditSetup = ({ setPage }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');

  const [formData, setFormData] = useState({
    ...createFormObject(DuformFields),
    duCreditCompanyId: 1,
  });
  const [LpaformData, setLpaFormData] = useState({
    ...createFormObject(LpaformFields),
    lpCreditCompanyId: 1,
  });

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const [isReissueSelected, setIsReissueSelected] = useState(true);
  const [isUseExistingDisabled, setIsUseExistingDisabled] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [hasRunAUS, setHasRunAUS] = useState(false); // To track if AUS has been run

  // RTK queries
  const { data: creditData } = useGetCreditDetailsQuery(
    { applicationId: applicationId },
    { refetchOnMountOrArgChange: true }
  );
  const { data: creditCompany } = useGetCreditCompanyQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [addCredit, { isLoading }] = usePostCreditDetailsMutation();

  useEffect(() => {
    // Scenario 1: First time navigation or AUS not run
    if (
      creditData?.data?.credit_setup &&
      Object.keys(creditData.data.credit_setup).length === 0
    ) {
      setIsCheckboxChecked(false);
      setIsCheckboxDisabled(true);
      setIsReissueSelected(true);
      setIsUseExistingDisabled(true);
      setReadOnly(false);
      setHasRunAUS(false);
    } else {
      const setupData = creditData?.data?.credit_setup;
      setFormData({
        duCreditCompanyId: setupData?.duCreditCompanyId,
        fhaCaseNumber: setupData?.fhaCaseNumber,
        creditUsername: setupData?.creditUsername,
        creditPassword: setupData?.creditPassword,
        duCreditReference: setupData?.duCreditReference,
      });
      setLpaFormData({
        lpaCreditCompanyId: setupData?.lpaCreditCompanyId,
        lpaCreditReference: setupData?.lpaCreditReference,
      });
      // Scenario 2: Post running AUS
      setIsCheckboxChecked(false);
      setIsCheckboxDisabled(false);
      setIsReissueSelected(false);
      setIsUseExistingDisabled(false);
      setReadOnly(true);
      setHasRunAUS(true);
    }
  }, [creditData]);

  useEffect(() => {
    if (isCheckboxChecked) {
      setFormData({
        duCreditCompanyId: '',
        fhaCaseNumber: '',
        creditUsername: '',
        creditPassword: '',
        duCreditReference: '',
      });
      setLpaFormData({
        lpaCreditCompanyId: '',
        lpaCreditReference: '',
      });
    } else {
      const setupData = creditData?.data?.credit_setup;
      setFormData({
        duCreditCompanyId: setupData?.duCreditCompanyId,
        fhaCaseNumber: setupData?.fhaCaseNumber,
        creditUsername: setupData?.creditUsername,
        creditPassword: setupData?.creditPassword,
        duCreditReference: setupData?.duCreditReference,
      });
      setLpaFormData({
        lpaCreditCompanyId: setupData?.lpaCreditCompanyId,
        lpaCreditReference: setupData?.lpaCreditReference,
      });
    }
  }, [isCheckboxChecked, creditData]);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsCheckboxChecked(checked);

    if (checked) {
      setIsReissueSelected(true);
      setIsUseExistingDisabled(true);
      setReadOnly(false);
    } else {
      setIsReissueSelected(false);
      setIsUseExistingDisabled(false);
      setReadOnly(true);
    }
  };

  const handleOneClickAUS = async () => {
    const postData = {
      duCreditCompanyId: formData.duCreditCompanyId?.id || 1,
      fhaCaseNumber: formData.fhaCaseNumber || '',
      creditUsername: formData.creditUsername || '',
      creditPassword: formData.creditPassword || '',
      duCreditReference: formData.duCreditReference || '',
      lpaCreditCompanyId: LpaformData.lpaCreditCompanyId?.id || 1,
      lpaCreditReference: LpaformData.lpaCreditReference || '',
    };

    try {
      const response = await addCredit({
        data: postData,
        applicationId: Number(applicationId),
      });

      if (response?.data?.status === 200) {
        customToast.success('Submitted successfully');
        // Update state to indicate AUS has been run
        setHasRunAUS(true);
        // Update form state if necessary based on response
        setIsCheckboxChecked(false);
        setIsCheckboxDisabled(false);
        setIsReissueSelected(false);
        setIsUseExistingDisabled(false);
        setReadOnly(true);
        setPage(6);
      } else {
        customToast.error('Failed to submit');
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  //populate form fields of credit company
  const dropdowns = {
    duCreditCompanyId: formatData(creditCompany?.data, 'creditCompanyId'),
    lpaCreditCompanyId: formatData(creditCompany?.data, 'creditCompanyId'),
  };

  return (
    <>
      <div className="h-[78vh] w-[100%] self-center flex flex-col p-6 pr-[18%] bg-white overflow-y-scroll">
        <div className="h-fit flex flex-row justify-start gap-4 border-b border-grey pb-6">
          <Checkbox
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
            disabled={isCheckboxDisabled}
          />
          <p className="p-xs-semibold">Re-issue Hard Credit</p>
        </div>
        <div className="h-fit flex flex-col">
          <H1>Desktop Underwriting</H1>
          <div className="h-fit flex flex-row justify-start gap-6">
            <div className="flex flex-row gap-2">
              <Radio
                checked={isReissueSelected}
                onChange={() => setIsReissueSelected(true)}
                disabled={!isCheckboxChecked && hasRunAUS}
              />
              <p
                className={`${isReissueSelected ? 'text-primary500 p-xs-semibold' : 'p-xs-regular text-grey'}`}
              >
                Re-issue
              </p>
            </div>
            <div className="flex flex-row gap-2">
              <Radio
                checked={!isReissueSelected}
                onChange={() => setIsReissueSelected(false)}
                disabled={isUseExistingDisabled}
              />
              <p
                className={`${!isReissueSelected ? 'text-primary500 p-xs-semibold' : 'p-xs-regular text-grey'}`}
              >
                Use Existing
              </p>
            </div>
          </div>
          <div className="border-b pb-6 border-b-grey">
            <DynamicForm
              data={DuformFields}
              formData={formData}
              dropdowns={dropdowns}
              setFormData={setFormData}
              readOnly={isCheckboxChecked ? false : readOnly}
              // errors={errors}
              // isSubmitted={isSubmitted}
              // additionInfo={applicationData?.data}
            />
          </div>
        </div>
        <div className="h-fit flex flex-col">
          <H1>Loan Product Advisor</H1>
          <div className="h-fit flex flex-row justify-start gap-6">
            <div className="flex flex-row gap-2">
              <Radio
                checked={isReissueSelected}
                onChange={() => setIsReissueSelected(true)}
                disabled={!isCheckboxChecked && hasRunAUS}
              />
              <p
                className={` ${isReissueSelected ? 'text-primary500 p-xs-semibold' : 'p-xs-regular text-grey'}`}
              >
                Re-issue
              </p>
            </div>
            <div className="flex flex-row gap-2">
              <Radio
                checked={!isReissueSelected}
                onChange={() => setIsReissueSelected(false)}
                disabled={isUseExistingDisabled}
              />
              <p
                className={`${!isReissueSelected ? 'text-primary500 p-xs-semibold' : 'p-xs-regular text-grey'}`}
              >
                Use Existing
              </p>
            </div>
          </div>
          <div className="pb-6">
            <DynamicForm
              data={LpaformFields}
              formData={LpaformData}
              dropdowns={dropdowns}
              setFormData={setLpaFormData}
              readOnly={isCheckboxChecked ? false : readOnly}
              // errors={errors}
              // isSubmitted={isSubmitted}
              // additionInfo={applicationData?.data}
            />
          </div>
        </div>
        <div className="h-fit w-[100%] flex flex-row justify-end p-3 mb-3">
          <div className="h-[100%] flex flex-row justify-end gap-5">
            <Button
              variant="primary_sm"
              onClick={handleOneClickAUS}
              type="button"
              btnClassName={`rounded-md ${!isReissueSelected ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              disabled={!isReissueSelected}
            >
              Run One Click AUS
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditSetup;
