import react, { useState } from 'react';
import DoughnutGraph from 'components/doughnut';

const CurrentOffer = ({ currentOfferData = [] }) => {
  return (
    <>
      <div className="w-[100%] flex flex-row border-b-2 border-b-[#E5E5E5] py-2">
        {currentOfferData?.map((item, index) => {
          return (
            <div
              className="h-[100%] flex flex-col overflow-hidden justify-start px-3 w-1/2"
              key={index + 2}
            >
              <div className="">
                <p className="font-bold text-[15px]">{item.title}</p>
              </div>
              <div className=" self-center w-[150%] ml-12 h-fit">
                <DoughnutGraph graphData={item?.dataMock} key={index} />
              </div>
              <div className="w-[100%] flex flex-row justify-start">
                <table>
                  <tbody>
                    {item?.currentOfferValue?.map((offer, index) => {
                      return (
                        <>
                          <tr key={index + 'tr'}>
                            <td className="text-start p-[6px]">
                              <p className="font-normal text-[12px] text-[#151314]">
                                {offer.name ?? '-'}
                              </p>
                            </td>
                            <td className="text-start p-[6px]">
                              <p className="font-normal text-[12px] text-[#151314]">
                                {offer.value ?? '-'}
                              </p>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default CurrentOffer;
