import React from 'react';

const Purchase = ({ variant }) => {
  switch (variant) {
    case 'primary':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="25"
          fill="none"
          viewBox="0 0 20 25"
        >
          <path
            stroke="#2B2829"
            d="M14.74 3.194c0 1.303-3.184 2.36-7.11 2.36-3.927 0-7.11-1.057-7.11-2.36m14.22 0c0-1.304-3.184-2.36-7.11-2.36C3.703.833.52 1.89.52 3.193m14.22 0v4.72M.52 3.194v4.72c0 1.304 3.183 2.36 7.11 2.36 3.926 0 7.11-1.056 7.11-2.36m4.739 2.226c0 1.303-3.183 2.36-7.11 2.36-3.926 0-7.109-1.057-7.109-2.36v4.72m14.219-4.72c0-1.028-1.979-1.902-4.74-2.226m4.74 2.226v4.72c0 1.304-3.183 2.36-7.11 2.36-3.926 0-7.109-1.056-7.109-2.36M.52 17.086c0 1.304 3.183 2.36 7.11 2.36 3.926 0 7.11-1.056 7.11-2.36v4.72c0 1.304-3.184 2.36-7.11 2.36-3.927 0-7.11-1.056-7.11-2.36v-4.72zm0 0c0-1.027 1.979-1.902 4.74-2.226"
          ></path>
        </svg>
      );
    case 'secondary':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="25"
          fill="none"
          viewBox="0 0 20 25"
        >
          <path
            stroke="#1340FF"
            d="M14.74 3.193c0 1.304-3.184 2.36-7.11 2.36-3.927 0-7.11-1.056-7.11-2.36m14.22 0c0-1.303-3.184-2.36-7.11-2.36C3.703.833.52 1.89.52 3.193m14.22 0v4.72M.52 3.194v4.72c0 1.304 3.183 2.36 7.11 2.36 3.926 0 7.11-1.056 7.11-2.36m4.739 2.226c0 1.304-3.183 2.36-7.11 2.36-3.926 0-7.109-1.056-7.109-2.36v4.72m14.219-4.72c0-1.027-1.979-1.901-4.74-2.225m4.74 2.225v4.72c0 1.304-3.183 2.361-7.11 2.361-3.926 0-7.109-1.057-7.109-2.36M.52 17.086c0 1.303 3.183 2.36 7.11 2.36 3.926 0 7.11-1.057 7.11-2.36v4.72c0 1.304-3.184 2.36-7.11 2.36-3.927 0-7.11-1.056-7.11-2.36v-4.72zm0 0c0-1.028 1.979-1.902 4.74-2.226"
          ></path>
        </svg>
      );
    case 'tertiary':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="25"
          fill="none"
          viewBox="0 0 20 25"
        >
          <path
            fill="#1340FF"
            d="M14.74 3.193c0 1.304-3.184 2.36-7.11 2.36-3.927 0-7.11-1.056-7.11-2.36C.52 1.89 3.704.833 7.63.833c3.926 0 7.11 1.057 7.11 2.36z"
          ></path>
          <path
            stroke="#1340FF"
            d="M14.74 3.193c0 1.304-3.184 2.36-7.11 2.36-3.927 0-7.11-1.056-7.11-2.36m14.22 0c0-1.303-3.184-2.36-7.11-2.36C3.703.833.52 1.89.52 3.193m14.22 0v4.72M.52 3.194v4.72c0 1.304 3.183 2.36 7.11 2.36 3.926 0 7.11-1.056 7.11-2.36m4.739 2.226c0 1.304-3.183 2.36-7.11 2.36-3.926 0-7.109-1.056-7.109-2.36v4.72m14.219-4.72c0-1.027-1.979-1.901-4.74-2.225m4.74 2.225v4.72c0 1.304-3.183 2.361-7.11 2.361-3.926 0-7.109-1.057-7.109-2.36M.52 17.086c0 1.303 3.183 2.36 7.11 2.36 3.926 0 7.11-1.057 7.11-2.36v4.72c0 1.304-3.184 2.36-7.11 2.36-3.927 0-7.11-1.056-7.11-2.36v-4.72zm0 0c0-1.028 1.979-1.902 4.74-2.226"
          ></path>
        </svg>
      );
    default:
      return null; // Or you can return a default SVG if none of the variants match
  }
};

export default Purchase;
