import { getInitials } from 'constants/getFirstChar';
import { profileBgMapping } from '../../themes/colors';

/**
 * Renders a user component with a profile picture and user information.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The user's title.
 * @param {string} props.subtitle - The user's subtitle.
 * @param {string} [props.size='md'] - The size of the user component. Can be 'sm' or 'md'.
 * @returns {JSX.Element} The rendered user component.
 */
const User = ({ title, subtitle, size = 'md', className, image = null }) => {
  // Define the sizes for different cases
  const sizes = {
    sm: {
      circle: 'w-[24px] h-[24px] p-xs-regular',
      marginRight: 'mr-0',
    },
    md: {
      circle: 'w-[40px] h-[40px] font-medium-2',
      marginRight: 'mr-0',
    },
  };
  // Retrieve the appropriate size values
  const { circle, marginRight } = sizes[size];
  const borderRadius = parseInt(circle.split('-')[1]) / 2 + 'px';

  // Determine background color based on the title
  const getColorForTitle = (title) => {
    // hash the title and use it to pick a color from the mapping
    const index = title?.charCodeAt(0) % profileBgMapping?.length;
    return profileBgMapping[index];
  };

  const backgroundColor = getColorForTitle(title);

  return (
    <div
      className={`flex items-center ${className || ''} min-w-fit max-w-[15vw]`}
    >
      <div
        className={`${circle} rounded-full flex justify-center items-center mr-2 overflow-hidden`}
        style={{
          borderRadius,
          backgroundColor,
        }}
      >
        {image ? (
          <img src={image} className="h-[100%] w-[100%] object-fit" />
        ) : (
          getInitials(title)
        )}
      </div>
      <div className={`flex flex-col items-start`}>
        <span
          className={`font-medium-bold whitespace-nowrap ${marginRight} text-secondary900 max-w-[10vw] overflow-hidden text-ellipsis`}
        >
          {title}
        </span>
        <span
          className={`font-medium whitespace-nowrap ${marginRight} text-secondary600 max-w-[10vw] overflow-hidden text-ellipsis`}
        >
          {subtitle}
        </span>
      </div>
    </div>
  );
};

export default User;
