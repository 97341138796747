import React, { useState } from 'react';

//components
import Modal from 'components/modal';
import Button from 'components/button';

//assets
import closeIcon from 'assets/icons/closeIcon.svg';
import fileIcon from 'assets/icons/file.svg';

//utils
import { convertUnixToCustomDate } from 'constants/unixToDate';
/**
 * Function: BatchDelete
 *
 * Description:
 * This function renders a modal component for batch deletion of documents. It displays a list of documents with options to select and delete a specific document.
 *
 * @param {Object} batchPopup - Boolean state to control the visibility of the batch deletion modal.
 * @param {Function} setBatchPopup - Function to update the batchPopup state.
 * @param {Function} setDeletePopup - Function to set the deletePopup state to true.
 * @param {Object} documents - Object containing the list of documents to be displayed for deletion.
 * @param {Function} setDocumentId - Function to set the documentId state with the selected document's ID.
 * @param {Function} resetState - Function to reset the state of the component.
 *
 * @returns {JSX.Element} A modal component displaying the list of documents with options to select and delete a specific document.
 */
const BatchDelete = ({
  batchPopup,
  setBatchPopup,
  setDeletePopup,
  documents,
  setDocumentId,
  resetState,
}) => {
  //state
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const handleDelete = async () => {
    if (selectedDocumentId) {
      setBatchPopup(false);
      setDeletePopup(true);
      setDocumentId(selectedDocumentId?.userDocumentId);
    }
  };

  return (
    <Modal show={batchPopup} width="max-w-[392px]">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div className="p-sm-bold mb-3 w-[15rem]">Delete Document</div>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-3"
            onClick={() => {
              setBatchPopup(false);
              resetState();
            }}
          />
        </div>
        <p className="font-medium mb-4 text-disabled">
          There are {documents?.documents?.length || 0} files uploaded in the
          document. Please select the file that you want to delete.
        </p>

        {documents?.documents?.map((data, index) => (
          <div
            key={data.userDocumentId}
            className={`rounded-md border-grey border flex items-center p-3 gap-2 mb-4 cursor-pointer ${
              selectedDocumentId?.userDocumentId === data?.userDocumentId
                ? 'bg-chatbot_bg'
                : ''
            }`}
            onClick={() => setSelectedDocumentId(data)}
          >
            <img src={fileIcon} alt="" />
            <div className="flex-grow">
              <div className="flex justify-between">
                <div className="font-medium-2 truncate overflow-hidden max-w-[252px]">
                  {data?.name}
                </div>
              </div>
              <p className="p-xs-regular text-neutral600">{data?.size}</p>
              <p className="p-xs-regular text-neutral600">
                Uploaded on &nbsp;
                {convertUnixToCustomDate(data.timestamp, 'MM-dd-yy, KK:mm bb')}
              </p>
            </div>
          </div>
        ))}

        <div className="flex justify-end gap-2 mt-12">
          <Button
            variant="tertiary"
            onClick={() => {
              setBatchPopup(false);
              resetState();
            }}
          >
            Cancel
          </Button>
          <Button
            variant={selectedDocumentId ? 'primary' : 'disabled'}
            disabled={selectedDocumentId ? false : true}
            onClick={handleDelete}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BatchDelete;
