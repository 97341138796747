import React, { useEffect, useState, useRef } from 'react';
//components
import Checkbox from 'components/checkbox';
import Button from 'components/button';
import productAndPricing from 'pages/borrower-profile/fees-worksheet/formData/productAndPricing.js';
import ClosingCostForm from './EditClosingCostRawForm';
import DoughnutGraph from 'components/doughnut';
import CompareOffer from './CompareOffer.jsx';
import User from 'components/user';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
//assets
import closeBlue from 'assets/icons/closeBlue.svg';
//libs
import * as Sentry from '@sentry/browser';
//utils
import { formatNumberIntoCurrency } from 'constants/formatNumber';
//query
import {
  useGetQuoteQuery,
  useSendQuoteMutation,
  useGetLoanPurposeQuery,
  useGetPropertyTypeQuery,
  useGetEscrowQuery,
  useGetOccupancyQuery,
  useGetStatesQuery,
} from 'redux_store/services/admin/product-pricing';
import { downloadQuote } from 'redux_store/services/documentDownloads';

//custom hook
import useDownloadPDFHandler from 'hooks/pdfViewer';

//static keys
const customKeys = [
  { originalKey: 'Quote Number', customKey: 'Quote Number' },
  { originalKey: 'Loan Purpose', customKey: 'Loan Purpose' },
  { originalKey: 'Estimated FICO', customKey: 'Estimated FICO' },
  { originalKey: 'Lien Position', customKey: 'Lien Position' },
  { originalKey: 'Property Type', customKey: 'Property Type' },
  { originalKey: ['StateId', 'Zip'], customKey: 'State / Zip' },
  { originalKey: 'Escrow', customKey: 'Escrow' },
  { originalKey: 'Occupancy', customKey: 'Occupancy' },
  { originalKey: 'Unit', customKey: 'Unit' },
];

const MortageQuote = ({ quoteData = null }) => {
  // States
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const [editFees, setEditFees] = useState(false);
  const [feesAdded, setFeesAdded] = useState(false);
  const [compareOfferVisiblity, setCompareOfferVisibility] = useState(false);
  const [allQuotes, setAllQuotes] = useState(quoteData);
  const [customQuote, setCustomQuote] = useState([]);
  const [didFeesRender, setDidFeesRender] = useState(false);
  const [updatedFeeJson_A, setUpdatedFeeJson_A] = useState([]);
  const [updatedFeeJson_B, setUpdatedFeeJson_B] = useState([]);
  const [updatedFeeJson_C, setUpdatedFeeJson_C] = useState([]);
  const [updatedQuotation_A, setUpdatedQuotation_A] = useState({});
  const [updatedQuotation_B, setUpdatedQuotation_B] = useState({});
  const [updatedQuotation_C, setUpdatedQuotation_C] = useState({});
  const updatedQuotation_ARef = useRef(updatedQuotation_A);
  const updatedQuotation_BRef = useRef(updatedQuotation_B);
  const updatedQuotation_CRef = useRef(updatedQuotation_C);
  const isFeesAvailable = useRef(didFeesRender);

  //RTK query
  const { data: escrow } = useGetEscrowQuery();
  const { data: loanPurpose } = useGetLoanPurposeQuery();
  const { data: occupancy } = useGetOccupancyQuery();
  const { data: propertyType } = useGetPropertyTypeQuery();
  const { data: state } = useGetStatesQuery();

  const [sendQuote, { isLoading: sendQuoteLoading }] = useSendQuoteMutation();

  const extractProductDetails = (
    productDetails,
    includeLoanBalance = false
  ) => {
    return productDetails?.map((item) => {
      const rateObj = item.children.find((child) => child.key === 'Rate');
      const loanBalanceObj = includeLoanBalance
        ? item.children.find((child) => child.key === 'Loan Balance')
        : null;

      return {
        product_id: item.product_id,
        rate: rateObj ? rateObj.value : 0,
        ...(includeLoanBalance && {
          loan_balance: loanBalanceObj ? loanBalanceObj.value : 0,
        }),
      };
    });
  };

  //custom hook to view/download pdf
  const { downloadPDF } = useDownloadPDFHandler();

  useEffect(() => {
    updatedQuotation_ARef.current = updatedQuotation_A;
    updatedQuotation_BRef.current = updatedQuotation_B;
    updatedQuotation_CRef.current = updatedQuotation_C;
  }, [updatedQuotation_A, updatedQuotation_B, updatedQuotation_C]);

  const mapDropdownValue = (key, value) => {
    switch (key) {
      case 'Loan Purpose':
        return (
          loanPurpose?.data?.find((item) => item.loanPurposeId === value)
            ?.name || value
        );
      case 'Property Type':
        return (
          propertyType?.data?.find((item) => item.propertyTypeId === value)
            ?.name || value
        );
      case 'Escrow':
        return (
          escrow?.data?.find((item) => item.escrowWaiverTypeId === value)
            ?.name || value
        );
      case 'Occupancy':
        return (
          occupancy?.data?.find((item) => item.occupancyTypeId === value)
            ?.name || value
        );
      case 'StateId':
        return (
          state?.data?.find((item) => item.stateId === value)?.name || value
        );
      default:
        return value;
    }
  };

  // update format to match send and download payload
  const transformData = (data) => {
    return data?.map((item) => ({
      title: item.heading,
      quote_data: item.children.map((child) => ({
        key: child.key,
        value: child.value,
        unit: child.unit,
      })),
      fees_data: [],
    }));
  };

  //download quote
  const handleDownloadQuote = async () => {
    const payload = transformData(allQuotes?.quote_details?.product_details);

    if (payload.length > 0 && updatedFeeJson_A.length > 0) {
      payload[0].fees_data = updatedFeeJson_A;
    }
    if (payload.length > 1 && updatedFeeJson_B.length > 0) {
      payload[1].fees_data = updatedFeeJson_B;
    }
    if (payload.length > 2 && updatedFeeJson_C.length > 0) {
      payload[2].fees_data = updatedFeeJson_C;
    }

    try {
      customToast.promise(
        downloadPDF(
          downloadQuote,
          {
            applicationId: applicationId,
            data: payload,
          },
          { download: true }
        ),
        'Downloading your file',
        'Downloaded successfully'
      );
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error downloading the PDF:', error);
    }
  };

  //send quote as mail
  const handleSendMail = async () => {
    const payload = transformData(allQuotes?.quote_details?.product_details);

    if (payload.length > 0 && updatedFeeJson_A.length > 0) {
      payload[0].fees_data = updatedFeeJson_A;
    }
    if (payload.length > 1 && updatedFeeJson_B.length > 0) {
      payload[1].fees_data = updatedFeeJson_B;
    }
    if (payload.length > 2 && updatedFeeJson_C.length > 0) {
      payload[2].fees_data = updatedFeeJson_C;
    }

    try {
      const response = await sendQuote({
        applicationId: applicationId,
        quoteNumber: allQuotes?.quote_details?.basic_details[0]?.value,
        data: payload,
      });

      if (response?.data?.status === 200) {
        customToast.success('Please check your mail for your Mortgage quote');
      } else {
        customToast.error('Failed to send quote');
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const uniqueKeys = new Set();
  allQuotes?.quote_details?.product_details?.forEach((child) => {
    child.children?.forEach((grandChild) => {
      uniqueKeys.add(grandChild.key);
    });
  });
  const daysMapper = (unit, value) => {
    if (unit === 'days' && value === 1) {
      return `${value} day`;
    }
    if (unit === 'year(s)' && value === 1) {
      return `${value} year`;
    }
    if (value > 1) {
      return `${value} ${unit}`;
    }
  };

  // fetch and update fees in quotation
  const handleApplyQuoteUpdate = async () => {
    const checkAndApplyUpdate = () => {
      const Quotation_A = updatedQuotation_ARef.current;
      const Quotation_B = updatedQuotation_BRef.current;
      const Quotation_C = updatedQuotation_CRef.current;

      if (Object.keys(Quotation_A).length === 0) {
        setTimeout(checkAndApplyUpdate, 300); // Retry after 1 second
        return;
      }
      if (allQuotes?.quote_details?.product_details) {
        const updatedProductDetails =
          allQuotes.quote_details.product_details.map((product, index) => {
            let updatedQuotation = {};
            if (index === 0 && Quotation_A) {
              updatedQuotation = Quotation_A;
            } else if (index === 1 && Quotation_B) {
              updatedQuotation = Quotation_B;
            } else if (index === 2 && Quotation_C) {
              updatedQuotation = Quotation_C;
            } else {
              // If the corresponding quotation is not available, return the original product
              return product;
            }

            let totalInterest, loanBalance, term;

            const updatedChildren = product.children.map((child) => {
              switch (child.key) {
                case 'Lender Fees':
                  return { ...child, value: updatedQuotation.lenderFees };
                case 'Other Closing Costs':
                  return { ...child, value: updatedQuotation.otherClosingCost };
                case 'Estimated Cash to Close':
                  return { ...child, value: updatedQuotation.estimatedCash };
                case 'Hazard Ins':
                  return { ...child, value: updatedQuotation.hazardIns };
                case 'Taxes':
                  return { ...child, value: updatedQuotation.tax };
                case 'Total Interest':
                  totalInterest = child.value;
                  return child;
                case 'Loan Balance':
                  loanBalance = child.value;
                  return child;
                case 'Term':
                  term = child.value;
                  return child;
                default:
                  return child;
              }
            });

            // Calculate APR
            if (
              totalInterest !== undefined &&
              loanBalance !== undefined &&
              term !== undefined
            ) {
              const F = updatedQuotation.totalAPR;
              const I = totalInterest;
              const P = loanBalance;
              const T = term;

              const calculatedAPR = ((I + F) / P) * (1 / T) * 100;

              // Find and update the APR in the children array
              const aprIndex = updatedChildren.findIndex(
                (child) => child.key === 'APR'
              );
              if (aprIndex !== -1) {
                updatedChildren[aprIndex] = {
                  ...updatedChildren[aprIndex],
                  value: calculatedAPR,
                };
              }
            }

            return { ...product, children: updatedChildren };
          });

        // Update the state
        setAllQuotes((prevQuotes) => ({
          ...prevQuotes,
          quote_details: {
            ...prevQuotes.quote_details,
            product_details: updatedProductDetails,
          },
        }));
        setEditFees(false);
      }
    };

    // Start the check and apply process
    checkAndApplyUpdate();
  };
  const graphData = (item) => {
    let data = [
      {
        title: 'P&I: ',
        value: parseFloat(item?.children[1]?.value?.toFixed(3)) ?? 0,
        color: '#A4D9D9',
      },
      {
        title: 'PMI: ',
        value: parseFloat(item?.children[0]?.value?.toFixed(3)) ?? 0,
        color: '#AFBEFF',
      },
      {
        title: 'Taxes: ',
        value: parseFloat(item?.children[2]?.value?.toFixed(3)) ?? 0,
        color: '#3F66FB',
      },
      {
        title: 'HAZ Ins: ',
        value: parseFloat(item?.children[3]?.value?.toFixed(3)) ?? 0,
        color: '#FFDAB9',
      },
    ];
    if (!quoteData.show_pmi) {
      return data.filter((item) => item.title !== 'PMI: ');
    }
    return data;
  };
  // reset the  updated fees to original state
  const handleFeesUpdate = () => {
    if (!isFeesAvailable.current) {
      setTimeout(() => {
        handleApplyQuoteUpdate();
        handleFeesUpdate();
      }, 300);
    }
  };
  const valueMapper = (value) => {
    if (value.unit === '$') {
      return (
        <div className="flex flex-row justify-center">
          <p className="p-xs-semibold text-secondary900">
            {`${formatNumberIntoCurrency(value.value)}`}
          </p>
        </div>
      );
    }
    if (value.unit === '%') {
      return (
        <div className="flex flex-row justify-center">
          <p className="p-xs-semibold text-secondary900 w-fit flex flex-row gap-4">
            <div className="w-fit p-2 bg-secondary100 flex flex-row gap-2 rounded-md">
              <p className="text-secondary600">{value.value?.toFixed(3)}</p>
              {value.unit}
            </div>
          </p>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row justify-center">
          <p className="p-xs-semibold text-secondary900 flex flex-row gap-3">
            {daysMapper(value.unit, value.value)}
          </p>
        </div>
      );
    }
  };
  const uniqueKeysArray = Array.from(uniqueKeys);

  return (
    <>
      <div className="h-[76vh] w-[100%] flex flex-col justify-center">
        <div className=" h-[100%] w-[100%] self-center flex flex-col bg-white">
          <div className="h-fit w-[100%] flex flex-row justify-between p-2">
            <div className="w-fit flex flex-row gap-4">
              <h1 className="p-md-bold">Mortgage Quote</h1>
            </div>
            <div className="flex flex-row gap-3">
              {feesAdded && (
                <Button
                  variant="white_sm"
                  onClick={() => {
                    setEditFees((prev) => !prev);
                  }}
                  type="button"
                  btnClassName={`hover:border hover:border-primary500 hover:text-primary500 ${editFees && 'border border-primary500 bg-primary100 text-primary500'}  rounded-md`}
                >
                  Edit Fees
                </Button>
              )}
              <Button
                variant="white_sm"
                onClick={() => {
                  if (!feesAdded) {
                    setFeesAdded(true);
                    setTimeout(() => {
                      handleApplyQuoteUpdate();
                    }, 100);
                  } else {
                    setFeesAdded(false);
                    const emptyHeadingProduct =
                      customQuote?.quote_details?.product_details?.find(
                        (product) => product.heading === ''
                      );
                    if (emptyHeadingProduct) {
                      // If found, add it to the quoteData
                      const updatedQuoteData = {
                        ...quoteData,
                        quote_details: {
                          ...quoteData.quote_details,
                          product_details: [
                            ...quoteData.quote_details.product_details,
                            emptyHeadingProduct,
                          ],
                        },
                      };

                      setAllQuotes(updatedQuoteData);
                    } else {
                      // If not found, just update with quoteData as before
                      setAllQuotes(quoteData);
                    }
                  }
                }}
                type="button"
                btnClassName={`hover:border hover:border-primary500 hover:text-primary500 ${feesAdded && 'border border-primary500 bg-primary100 text-primary500'}  rounded-md`}
              >
                <div className="flex flex-row gap-3">
                  {feesAdded ? (
                    <>
                      <p className="">Fees Added</p>
                      <img
                        className={`w-[10px] h-[10px] self-center`}
                        src={closeBlue}
                        alt="x"
                      />
                    </>
                  ) : (
                    <p>Add Fees</p>
                  )}
                </div>
              </Button>
              <Button
                variant={`${quoteData?.quote_details?.product_details.length >= 3 ? 'tertiary_sm' : 'primary_sm'}`}
                onClick={() => setCompareOfferVisibility(true)}
                type="button"
                btnClassName={`${quoteData?.quote_details?.product_details.length >= 3 ? 'cursor-not-allowed' : ''}`}
                disabled={
                  quoteData?.quote_details?.product_details.length >= 3
                    ? true
                    : false
                }
              >
                Compare Offer
              </Button>
            </div>
          </div>
          <div className="h-fit w-[100%] bg-chatbot_bg flex flex-row p-4 ">
            <div className="w-[100%] flex flex-row gap-2 justify-start border-r border-neutral200 px-5">
              <User
                title={allQuotes?.loan_officer_details?.name ?? '-'}
                subtitle={allQuotes?.loan_officer_details?.email ?? '-'}
                size="md"
              />
            </div>
            <div className="w-[100%] flex flex-row gap-2 justify-start border-r border-neutral200 px-5">
              <div className="flex flex-col">
                <p className="p-xs-regular text-disabled">NMLS</p>
                <p className="p-xs-bold">
                  {allQuotes?.loan_officer_details?.nmlsId ?? '-'}
                </p>
              </div>
            </div>
            <div className="w-[100%] flex flex-row gap-2 justify-start border-r border-neutral200 px-5">
              <div className="flex flex-col self-start">
                <p className="p-xs-regular text-disabled">License Number</p>
                <p className="p-xs-bold">
                  {allQuotes?.loan_officer_details?.licenseNumber ?? '-'}
                </p>
              </div>
            </div>
            <div className="w-[100%] flex flex-row gap-2 justify-start px-5">
              <div className="flex flex-col">
                <p className="p-xs-regular text-disabled">Mobile Number</p>
                <p className="p-xs-bold">
                  {allQuotes?.loan_officer_details?.phoneNumber ?? '-'}
                </p>
              </div>
            </div>
          </div>
          <div className="h-fit w-[100%] flex flex-row">
            {customKeys?.map(({ originalKey, customKey }) => {
              if (Array.isArray(originalKey)) {
                // Handle combined StateId and Zip
                const stateItem = allQuotes?.quote_details?.basic_details?.find(
                  (data) => data.key === 'StateId'
                );
                const zipItem = allQuotes?.quote_details?.basic_details?.find(
                  (data) => data.key === 'Zip'
                );
                if (stateItem && zipItem) {
                  return (
                    <div
                      key={customKey}
                      className="w-[100%] flex flex-row justify-start border-r border-neutral200 px-3 my-2"
                    >
                      <div className="flex flex-col justify-between">
                        <p className="p-xs-regular text-disabled">
                          {customKey}
                        </p>
                        <p className="p-xs-bold self-start">
                          {`${mapDropdownValue(stateItem.key, stateItem.value)} / ${zipItem.value}`}
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              } else {
                // Handle other single key items
                const item = allQuotes?.quote_details?.basic_details?.find(
                  (data) => data.key === originalKey
                );
                if (item) {
                  return (
                    <div
                      key={customKey}
                      className="w-[100%] flex flex-row justify-start border-r border-neutral200 px-3 my-2"
                    >
                      <div className="flex flex-col justify-between">
                        <p className="p-xs-regular text-disabled">
                          {customKey}
                        </p>
                        <p className="p-xs-bold self-start">
                          {mapDropdownValue(item.key, item.value)}
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              }
            })}
          </div>
          {/* you can add this class to hide scrollbar: hiddenScroll */}

          <div className="h-[80%] w-[100%] overflow-y-scroll hiddenScroll">
            <table className="w-[100%]">
              <tr className="test-start">
                <td className="min-w-[25%] w-[25%] max-w-[25%]"></td>
                {allQuotes?.quote_details?.product_details?.map(
                  (item, index) => {
                    return (
                      <>
                        <td
                          className="border border-[#F5F5F5]  text-start"
                          key={index}
                        >
                          <div className="h-fit w-[100%] flex flex-col">
                            <p className="p-xs-bold text-center text-secondary900 bg-chatbot_bg p-4 ">
                              {item?.heading ? item.heading : '-'}
                            </p>
                            <div className="self-center w-[250px]">
                              <DoughnutGraph
                                graphData={graphData(item)}
                                position={'bottom'}
                              />
                            </div>
                          </div>
                        </td>
                      </>
                    );
                  }
                )}
              </tr>
              {uniqueKeysArray.slice(4)?.map((key, idx) => (
                <tr key={idx}>
                  <td className="min-w-[25%] w-[25%] max-w-[25%] border border-[#F5F5F5] p-4">
                    <div className="flex flex-row gap-3">
                      <p className="p-xs-semibold text-secondary900">{key}</p>
                    </div>
                  </td>
                  {allQuotes?.quote_details?.product_details?.map(
                    (child, childIndex) => {
                      const grandChild = child.children?.find(
                        (gc) => gc.key === key
                      );
                      return (
                        <td
                          key={`value-${childIndex}`}
                          className="border border-[#F5F5F5] p-4 text-center"
                        >
                          {valueMapper(grandChild)}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))}
            </table>
            <div className="flex flex-row w-[100%] gap-2">
              {feesAdded &&
                allQuotes?.quote_details?.product_details?.length > 0 && (
                  <div
                    className={`${allQuotes?.quote_details?.product_details?.length === 3 ? 'w-[33%]' : allQuotes?.quote_details?.product_details?.length === 2 ? 'w-[50%]' : 'w-[100%]'}`}
                  >
                    <ClosingCostForm
                      editFees={editFees}
                      setUpdatedFeeJson={setUpdatedFeeJson_A}
                      length={allQuotes?.quote_details?.product_details?.length}
                      calculateQuotationUpdate={setUpdatedQuotation_A}
                      quoteLoanAmount={
                        extractProductDetails(
                          allQuotes?.quote_details?.product_details,
                          true
                        )[0]?.loan_balance
                      }
                      quoteRate={
                        extractProductDetails(
                          allQuotes?.quote_details?.product_details,
                          true
                        )[0]?.rate
                      }
                      setDidFeesRender={setDidFeesRender}
                    />
                  </div>
                )}
              {feesAdded &&
                allQuotes?.quote_details?.product_details?.length > 1 && (
                  <div
                    className={`${allQuotes?.quote_details?.product_details?.length === 3 ? 'w-[33%]' : allQuotes?.quote_details?.product_details?.length === 2 ? 'w-[50%]' : 'w-[100%]'}`}
                  >
                    <ClosingCostForm
                      editFees={editFees}
                      setUpdatedFeeJson={setUpdatedFeeJson_B}
                      length={allQuotes?.quote_details?.product_details?.length}
                      calculateQuotationUpdate={setUpdatedQuotation_B}
                      quoteLoanAmount={
                        extractProductDetails(
                          allQuotes?.quote_details?.product_details,
                          true
                        )[1]?.loan_balance
                      }
                      quoteRate={
                        extractProductDetails(
                          allQuotes?.quote_details?.product_details,
                          true
                        )[1]?.rate
                      }
                      setDidFeesRender={setDidFeesRender}
                    />
                  </div>
                )}
              {feesAdded &&
                allQuotes?.quote_details?.product_details?.length > 2 && (
                  <div
                    className={`${allQuotes?.quote_details?.product_details?.length === 3 ? 'w-[33%]' : allQuotes?.quote_details?.product_details?.length === 2 ? 'w-[50%]' : 'w-[100%]'}`}
                  >
                    <ClosingCostForm
                      editFees={editFees}
                      setUpdatedFeeJson={setUpdatedFeeJson_C}
                      length={allQuotes?.quote_details?.product_details?.length}
                      calculateQuotationUpdate={setUpdatedQuotation_C}
                      quoteLoanAmount={
                        extractProductDetails(
                          allQuotes?.quote_details?.product_details,
                          true
                        )[2]?.loan_balance
                      }
                      quoteRate={
                        extractProductDetails(
                          allQuotes?.quote_details?.product_details,
                          true
                        )[2]?.rate
                      }
                      setDidFeesRender={setDidFeesRender}
                    />
                  </div>
                )}
            </div>
          </div>
          <div className="h-fit w-[100%] flex flex-row justify-end p-2">
            {!editFees ? (
              <div className="h-[100%] flex flex-row justify-end gap-5">
                <Button
                  variant="white_sm"
                  onClick={handleDownloadQuote}
                  type="button"
                  btnClassName="hover:border hover:border-primary500 hover:text-primary500"
                >
                  Download
                </Button>
                <Button
                  variant="primary_sm"
                  onClick={handleSendMail}
                  type="button"
                  btnClassName="rounded-md"
                >
                  {sendQuoteLoading ? (
                    <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                      <Loader loaderText="Sending" FullScreen={false} />
                    </div>
                  ) : (
                    'Send'
                  )}
                </Button>
              </div>
            ) : (
              <div className="h-[100%] flex flex-row justify-end gap-5">
                <Button
                  variant="white_sm"
                  onClick={() => setEditFees(false)}
                  type="button"
                  btnClassName="border border-disabled text-disabled rounded-md"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary_sm"
                  onClick={handleApplyQuoteUpdate}
                  type="button"
                  btnClassName="rounded-md"
                >
                  Update
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {compareOfferVisiblity && (
        <CompareOffer
          setCompareOfferVisibility={setCompareOfferVisibility}
          setAllQuotes={setAllQuotes}
          setCustomQuote={setCustomQuote}
          products={extractProductDetails(
            quoteData?.quote_details?.product_details
          )}
        />
      )}
    </>
  );
};

export default MortageQuote;
