/* istanbul ignore file */
import {
  checkAlphanumeric,
  checkTextInput,
  withValidations,
  checkEmailValidation,
} from '../utils';
import { months, years } from './personalInformation';

const employmentAndIncomeData = [
  {
    id: 1,
    name: 'Name of Employer',
    type: 'text',
    placeholder: 'Enter Name of Employer',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'employerName',
    validator: checkTextInput,
  },
  {
    id: 2,
    name: 'Designation',
    type: 'text',
    placeholder: 'Enter Designation',
    customClass: 'w-1/3',
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    isRequired: (formData) => {
      return formData?.employmentTypeId !== 1;
    },
    propertyName: 'designation',
    validator: checkTextInput,
  },
  {
    id: 3,
    name: 'Address of Employer',
    type: 'text',
    placeholder: 'Enter Address of Employer',
    customClass: 'w-1/3',
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    isRequired: false,
    propertyName: 'employerAddress',
  },
  {
    id: 4,
    name: 'City',
    type: 'text',
    placeholder: 'Enter City',
    customClass: 'w-1/3',
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    isRequired: false,
    propertyName: 'cityName',
    validator: checkTextInput,
  },
  {
    id: 5,
    name: 'State',
    type: 'select',
    placeholder: 'Select State',
    customClass: 'w-1/3',
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    isRequired: false,
    propertyName: 'stateId',
  },
  {
    id: 6,
    name: 'HR or Company Email (For Verification)',
    type: 'text',
    placeholder: 'Enter HR or Company Email',
    customClass: 'w-1/3',
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    isRequired: (formData) => {
      return formData?.companyEmail ? true : false;
    },
    propertyName: 'companyEmail',
    checkValidation: [checkEmailValidation],
  },
  {
    id: 7,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'Years Working with Employer',
        type: 'select',
        placeholder: 'Year',
        customClass: 'w-1/2',
        disabled: (formData) => {
          return formData?.employmentTypeId === 1;
        },
        isRequired: (formData) => {
          return formData?.employmentTypeId !== 1;
        },
        propertyName: 'employerExperienceYears',
        options: years,
      },
      {
        id: 2,
        name: '',
        type: 'select',
        placeholder: 'Month',
        customClass: 'w-1/2',
        disabled: (formData) => {
          return formData?.employmentTypeId === 1;
        },
        isRequired: (formData) => {
          return formData?.employmentTypeId !== 1;
        },
        propertyName: 'employerExperienceMonths',
        options: months,
      },
    ],
  },
  {
    id: 8,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'LOB Work Years',
        type: 'select',
        placeholder: 'Year',
        customClass: 'w-1/2',
        isRequired: false,
        propertyName: 'LOBWorkYears',
        options: years,
      },
      {
        id: 2,
        name: '',
        type: 'select',
        placeholder: 'Month',
        customClass: 'w-1/2',
        isRequired: false,
        propertyName: 'LOBWorkMonths',
        options: months,
      },
    ],
  },
  {
    id: 9,
    name: 'Base Income',
    type: 'number',
    placeholder: 'Enter Base Income',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'baseIncome',
  },
  {
    id: 10,
    name: 'OT Income',
    type: 'number',
    placeholder: 'Enter OT Income',
    customClass: 'w-1/3',
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    isRequired: false,
    propertyName: 'OTIncome',
  },
  {
    id: 11,
    name: 'Bonus Income',
    type: 'number',
    placeholder: 'Enter Bonus Income',
    customClass: 'w-1/3',
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    isRequired: false,
    propertyName: 'bonusIncome',
  },
  {
    id: 12,
    name: 'Other Income',
    type: 'number',
    placeholder: 'Enter Other Income',
    customClass: 'w-1/3',
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    isRequired: false,
    propertyName: 'otherIncome',
  },
  {
    id: 13,
    name: 'Income Type',
    type: 'select',
    placeholder: 'Select Income Type',
    customClass: 'w-1/3',
    isRequired: (formData, additionInfo) => {
      return formData.otherIncome > 0;
    },
    propertyName: 'incomeTypeId',
    disabled: (formData, additionInfo) => {
      return formData.otherIncome == 0;
    },
  },
  {
    id: 14,
    name: 'If employed with current employer for less than 2 years, please provide details of previous employer.',
    type: 'checkbox',
    placeholder: '',
    customClass: 'w-full',
    isRequired: false,
    disabled: (formData) => {
      return formData?.employmentTypeId === 1;
    },
    propertyName: 'presentEmployerLessThanTwoYears',
  },
  {
    id: 15,
    name: 'Name of Employer',
    type: 'text',
    placeholder: 'Enter Name of Employer',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      return formData?.presentEmployerLessThanTwoYears;
    },
    propertyName: 'previousEmployerName',
    disabled: (formData) => {
      return !formData?.presentEmployerLessThanTwoYears;
    },
    validator: checkTextInput,
  },
  {
    id: 16,
    name: 'Designation',
    type: 'text',
    placeholder: 'Enter Designation',
    customClass: 'w-1/3',
    isRequired: (formData) => {
      return formData?.presentEmployerLessThanTwoYears;
    },
    propertyName: 'previousEmployerDesignation',
    disabled: (formData) => {
      return !formData?.presentEmployerLessThanTwoYears;
    },
    validator: checkTextInput,
  },
  {
    id: 17,
    name: 'Address of Employer',
    type: 'text',
    placeholder: 'Enter Address of Employer',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'previousEmployerAddress',
    disabled: (formData) => {
      return !formData?.presentEmployerLessThanTwoYears;
    },
  },
  {
    id: 18,
    name: 'City',
    type: 'text',
    placeholder: 'Enter City',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'previousEmployerCityName',
    disabled: (formData) => {
      return !formData?.presentEmployerLessThanTwoYears;
    },
    validator: checkTextInput,
  },
  {
    id: 19,
    name: 'State',
    type: 'select',
    placeholder: 'Select State',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'previousEmployerStateName',
    disabled: (formData) => {
      return !formData?.presentEmployerLessThanTwoYears;
    },
  },
  {
    id: 20,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'Years Working with Employer',
        type: 'select',
        placeholder: 'Year',
        customClass: 'w-1/2',
        isRequired: (formData) => {
          return formData?.presentEmployerLessThanTwoYears;
        },
        propertyName: 'previousEmployerExperienceYears',
        options: years,
        disabled: (formData) => {
          return !formData?.presentEmployerLessThanTwoYears;
        },
      },
      {
        id: 2,
        name: '',
        type: 'select',
        placeholder: 'Month',
        customClass: 'w-1/2',
        isRequired: (formData) => {
          return formData?.presentEmployerLessThanTwoYears;
        },
        propertyName: 'previousEmployerExperienceMonths',
        options: months,
        disabled: (formData) => {
          return !formData?.presentEmployerLessThanTwoYears;
        },
      },
    ],
  },
];

export const employmentAndIncome = withValidations(employmentAndIncomeData);

export const selfEmployedData = [
  {
    id: 21,
    name: 'Name of Employer',
    type: 'text',
    placeholder: 'Enter Name of Employer',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'employerName',
    validator: checkTextInput,
  },
  {
    id: 22,
    name: 'Designation',
    type: 'text',
    placeholder: 'Enter Designation',
    customClass: 'w-1/3',
    isRequired: true,
    propertyName: 'designation',
    validator: checkTextInput,
  },
  {
    id: 23,
    name: 'Address of Employer',
    type: 'text',
    placeholder: 'Enter Address of Employer',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'employerAddress',
  },
  {
    id: 24,
    name: 'City',
    type: 'text',
    placeholder: 'Enter City',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'cityName',
    validator: checkTextInput,
  },
  {
    id: 25,
    name: 'State',
    type: 'select',
    placeholder: 'Select State',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'stateId',
  },
  {
    id: 26,
    type: 'container',
    customClass: 'w-1/3 items-end',
    formFields: [],
  },
  {
    id: 27,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'LOB Work Years',
        type: 'select',
        placeholder: 'Year',
        customClass: 'w-1/2',
        isRequired: false,
        propertyName: 'LOBWorkYears',
        options: years,
      },
      {
        id: 2,
        name: '',
        type: 'select',
        placeholder: 'Month',
        customClass: 'w-1/2',
        isRequired: false,
        propertyName: 'LOBWorkMonths',
        options: months,
      },
    ],
  },
  {
    id: 28,
    type: 'container',
    customClass: 'w-1/3 flex items-end',
    formFields: [
      {
        id: 1,
        name: 'Employer Work Years',
        type: 'select',
        placeholder: 'Year',
        customClass: 'w-1/2',
        disabled: (formData) => {
          return formData?.employmentTypeId === 1;
        },
        isRequired: (formData) => {
          return formData?.employmentTypeId !== 1;
        },
        propertyName: 'employerExperienceYears',
        options: years,
      },
      {
        id: 2,
        name: '',
        type: 'select',
        placeholder: 'Month',
        customClass: 'w-1/2',
        disabled: (formData) => {
          return formData?.employmentTypeId === 1;
        },
        isRequired: (formData) => {
          return formData?.employmentTypeId !== 1;
        },
        propertyName: 'employerExperienceMonths',
        options: months,
      },
    ],
  },
  {
    id: 29,
    name: 'Base Income',
    type: 'number',
    placeholder: 'Enter Base Income',
    customClass: 'w-1/3',
    isRequired: true,

    propertyName: 'baseIncome',
  },
  {
    id: 30,
    name: 'OT Income',
    type: 'number',
    placeholder: 'Enter OT Income',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'OTIncome',
  },
  {
    id: 31,
    name: 'Bonus Income',
    type: 'number',
    placeholder: 'Enter Bonus Income',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'bonusIncome',
  },
  {
    id: 32,
    name: 'Other Income',
    type: 'number',
    placeholder: 'Enter Other Income',
    customClass: 'w-1/3',
    isRequired: false,
    propertyName: 'otherIncome',
  },
  {
    id: 33,
    name: 'I am employed by a family member, property seller, real estate agent, or other party to the transaction.',
    type: 'checkbox',
    placeholder: '',
    customClass: 'w-full',
    isRequired: true,
    propertyName: 'employedByOtherParty',
  },
  {
    id: 34,
    name: 'Self Employed with more than 25% ownership',
    type: 'checkbox',
    placeholder: '',
    customClass: 'w-full',
    isRequired: true,
    propertyName: 'selfEmployed',
  },
];

export const selfEmployed = withValidations(selfEmployedData);
