import React from 'react';
/**
 * Renders an H1 heading element with specified children and props.
 *
 * @param {Object} props - The props object containing additional attributes for the H1 element.
 * @param {ReactNode} props.children - The children elements to be rendered within the H1 element.
 * @returns {ReactElement} The rendered H1 heading element.
 */
const H1 = ({ children, ...props }) => {
  return (
    <h1 className="font-bold text-lg py-2" {...props}>
      {children}
    </h1>
  );
};

export default H1;
