import React, { useState, useEffect } from 'react';
//component's
import Tabs from 'components/tabs';
import InputGroup from 'components/input-group';
import Button from 'components/button';
import Badge from 'components/badge';
import Loader from 'wrapper/loader';
import Select from 'react-select';
import { customToast } from 'components/toast/index.jsx';
import Breadcrumb from 'components/breadcrumb';
//constant's
import { statusVariantMap } from '../loan-information/constants';
import useDebounce from 'hooks/useDebounce';

//Library imports
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import formateDate from './date-formate';
import { saveAs } from 'file-saver';
import { addDays, subDays } from 'date-fns';
import { motion } from 'framer-motion';
//Icon's
import searchIcon from 'assets/icons/search.svg';
import download from 'assets/images/download.svg';
import arrowDown from 'assets/icons/arrowDown.svg';
import closeIcon from 'assets/icons/closeIcon.svg';
import arrowUp from 'assets/icons/arrowUp.svg';

//RTK import's
import {
  useDownloadReportsDataMutation,
  useGetReportsDataQuery,
  useGetPurposeInformationQuery,
} from 'redux_store/services/admin/reports';
import { useGetPipelineStatusQuery } from 'redux_store/services/client/pipeline';
import { useGetLoanProgramQuery } from 'redux_store/services/client/profile';
import { useGetStatesQuery } from 'redux_store/services/admin/product-pricing.js';
const Reports = () => {
  const formatDateRange = (startDate, endDate) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };

    const start = new Date(startDate);
    const end = new Date(endDate);

    const startDay = start.getDate();
    const endDay = end.getDate();
    const month = start.toLocaleDateString('en-US', { month: 'short' });
    const eMonth = end.toLocaleDateString('en-US', { month: 'short' });
    const year = start.getFullYear();
    const eyear = end.getFullYear();
    if (month === eMonth && year === eyear) {
      return `(${startDay}-${endDay}) ${month} ${year}`;
    } else if (year === eyear) {
      return `(${startDay} ${month} - ${endDay} ${eMonth}) ${year}`;
    } else {
      return `(${startDay} ${month} - ${endDay} ${month}) ${year} - ${eyear}`;
    }
  };
  const getLableForLast = () => {
    const { sDate, eDate } = getDayMonthRange(7);
    return formatDateRange(sDate, eDate);
  };
  const allTabs = [{ id: 1, name: 'MCR' }];
  const [label, setLable] = useState('Custom');
  const [selectedOption, setSelectionOption] = useState({
    value: getDayMonthRange(7),
    label: 'Last 7 days ' + getLableForLast(),
  });
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [dates, setDates] = useState({
    startDate: getDayMonthRange(7).sDate,
    endDate: getDayMonthRange(7).eDate,
  });
  const [tab, setTab] = useState(1);

  const dropDownList = [
    { value: getDayMonthRange(7), label: 'Last 7 days' },
    { value: getLastNMonthsDates(1), label: 'This Month' },
    { value: getLastNMonthsDates(3), label: 'Past 3 months' },
    { value: getLastNMonthsDates(6), label: 'Past 6 months' },
    { value: 'custom', label: label },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#E3E3E3',
      '&:hover': {
        borderColor: '#00b23b',
      },
      width: '100%',
      fontFamily: 'gilroy, sans-serif', // Set the font family
      fontSize: '12px', // Set the font size
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#007bff'
        : state.isFocused
          ? '#EBEFF3'
          : null,
      color: state.isSelected ? '#ffffff' : '#333333',
      zIndex: '9999',
      padding: '8px',
      fontFamily: 'gilroy, sans-serif', // Set the font family
      fontSize: '12px', // Set the font size
    }),
  };

  const trClass = 'h-[40px] bg-[F5F5F5] py-2 relative';
  const tdClass =
    'font-gilroy text-xs text-neutral1000 text-center max-w-32 text-ellipsis overflow-hidden';
  const thClass =
    'h-[35px] p-2.5 text-neutral700 align-center gap-2 font-gilroy-bold text-center font-gilroy-bold text-xs font-bold leading-none ';

  const [expandedRowId, setExpandedRowId] = useState(null);
  const [reportsData, setReportsData] = useState();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    itemsCount: 0,
    totalItemsCount: 0,
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(false);
  const [downloadSkip, setDownloadSkip] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: subDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const breadCrumbs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: "Report's",
      path: '/reports',
    },
  ];
  const { data: pipelineStatusData, isError: pipelineStatusError } =
    useGetPipelineStatusQuery(
      {},
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: purposeData, isError: purposeError } =
    useGetPurposeInformationQuery(
      {},
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: loanData, isError: loanError } = useGetLoanProgramQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: stateType } = useGetStatesQuery();
  const searchFilter = useDebounce(globalFilter, 700);
  const [downloadfile, setDownloadFile] = useState();

  const onPageChange = (page) => {
    setPagination({ currentPage: page });
    setPage({ page: page });
  };
  const handleNextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      onPageChange(pagination.currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      onPageChange(pagination.currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handleLastPage = () => {
    onPageChange(pagination.totalPages);
  };

  const {
    data: reportsList,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetReportsDataQuery(
    {
      paging: {
        pageSize: 10,
        page: Number(pagination.currentPage),
      },
      searchFilter: globalFilter ?? '',
      date: {
        sDate: dates.startDate,
        eDate: dates.endDate,
      },
      dateString:
        dates.startDate && dates.endDate
          ? `&start_date=${dates.startDate}&end_date=${dates.endDate}`
          : '',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skip,
    }
  );

  const [
    downloadClick,
    { data: downloadData, isLoading: downloadLoading, refetch },
  ] = useDownloadReportsDataMutation(
    {},
    {
      refetchOnMountOrArgChange: true,
      skip: !downloadSkip,
    }
  );

  const getStatus = ({ row }) => {
    const statusId = row?.application_status_id;
    const statusObject = pipelineStatusData?.data?.find(
      (data) => data.applicationStatusId === statusId
    );
    const statusText = statusObject ? statusObject.name : '';
    return statusText;
  };

  const getPurpose = ({ row }) => {
    const statusId = row?.purpose;
    const statusObject = purposeData?.data?.find(
      (data) => data.loanPurposeId === statusId
    );
    const statusText = statusObject ? statusObject.name : '';
    return statusText;
  };

  function getLoanProgram({ row }) {
    const statusId = row?.loan_details.loan_program_id;
    const statusObject = loanData?.data?.find(
      (data) => data.loanProgramId === statusId
    );
    const statusText = statusObject ? statusObject.name : '';
    return statusText;
  }

  const buttonClick = async () => {
    setDownloadSkip(true);
    const response = await downloadClick({
      paging: {
        pageSize: 10,
        page: Number(pagination.currentPage),
      },
      searchFilter,
      date: {
        sDate: dates.startDate,
        eDate: dates.endDate,
      },
      dateString:
        dates.startDate && dates.endDate
          ? `&start_date=${dates.startDate}&end_date=${dates.endDate}`
          : '',
    });
    if (response) {
      const fileName = `clients.${'csv'}`;
      saveAs(response.data, fileName);
    }
  };

  useEffect(() => {
    if (reportsList?.success) {
      const reports = reportsList?.data?.data.filter(
        (item) => item.lender_loan_id
      );
      const pagination = reportsList?.data?.pagination;
      setReportsData(reports);
      setPagination({
        currentPage: pagination.currentPage,
        totalItemsCount: pagination.totalItemsCount,
        totalPages: pagination.totalPages,
        pageSize: pagination.pageSize,
      });

      setDownloadFile(downloadData);
    }
  }, [reportsList]);

  // skip api calls
  useEffect(() => {
    if (globalFilter === '' && page === 1) {
      setSkip(false);
    }
  }, [searchFilter, page]);

  const toggleRow = (id) => {
    setExpandedRowId(expandedRowId === id ? null : id);
  };

  const handleSelect = (event) => {
    if (event.label === selectedOption.label) {
      return;
    }
    if (
      substringReturn('Last', event.label) &&
      substringReturn('Last', selectedOption.label)
    ) {
      return;
    }
    if (event.value === 'custom') {
      setDropDownOpen(true);
      setSelectionOption(event.value);
    } else {
      if (event.label.includes('Last 7 days')) {
        setSelectionOption({
          value: getDayMonthRange(7),
          label: 'Last 7 days ' + getLableForLast(),
        });
      } else {
        setSelectionOption(event);
      }
      setPagination({ currentPage: 1 });
      setDates({
        startDate: event.value.sDate,
        endDate: event.value.eDate,
      });
    }
  };

  const substringReturn = (searchString, mainString) => {
    if (mainString.includes(searchString)) {
      return true;
    }

    return false;
  };

  const handleRangeChange = (item, selectedOption) => {
    setRange([item.selection]);
  };

  function getDayMonthRange(period) {
    const today = new Date();

    // Calculate start date (7 days before today)
    const startDate = new Date();
    startDate.setDate(today.getDate() - (period - 1));

    // Calculate end date (7 days from today)
    const endDate = new Date();
    endDate.setDate(today.getDate());

    return { sDate: formateDate(startDate), eDate: formateDate(endDate) };
  }

  function getLastNMonthsDates(n) {
    const now = new Date();
    const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
    const startDate = new Date(now.getFullYear(), now.getMonth() - n + 1, 1); // First day of N months ago

    return {
      sDate: formateDate(startDate), // Format as YYYY-MM-DD
      eDate: formateDate(endDate),
    };
  }

  const saveCustom = () => {
    setDates({
      startDate: formateDate(range[0].startDate),
      endDate: formateDate(range[0].endDate),
    });
    setDropDownOpen(false);
    setSelectionOption({
      label: formatDateRange(range[0].startDate, range[0].endDate),
      value: {},
    });
    customToast.success('Date Range applied successfully');
  };

  const cancelCustom = () => {
    setRange([
      {
        startDate: new Date(),
        endDate: subDays(new Date(), 7),
        key: 'selection',
      },
    ]);

    setDropDownOpen(false);
    setSelectionOption({
      value: getDayMonthRange(7),
      label: 'Last 7 days ' + getLableForLast(),
    });
    setDates({
      startDate: getDayMonthRange(7).sDate,
      endDate: getDayMonthRange(7).eDate,
    });
  };

  function extractDate(input) {
    if (input) {
      // Parse the input string as a Date object
      const date = new Date(input);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        return null; // Return null if the input string is not a valid date
      }

      // Format the date to YYYY-MM-DD
      return date.toLocaleDateString('en-US');
    }
  }

  function getLableforCustom() {
    if (selectedOption.label == 'custom' && !dropDownOpen && dates.startDate) {
      setLabel(`(${startDate}-${endDate})`);
    }
    if (selectedOption.lable == 'Last 7 days') {
      return formatDateRange(dates.startDate, dates.endDate);
    }
  }
  const stateCodeMapper = (id) => {
    if (!id) return 'NA';
    const stateCode = stateType?.data.filter((state) => {
      return state.stateId === id;
    });
    return stateCode[0].code;
  };
  
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-[100%] h-[80vh] self-center">
          <Breadcrumb breadcrumbs={breadCrumbs} />
          <div className="w-[100%] flex flex-col overflow-y-scroll p-3 max-h-custom-pipeline relative">
            <h1 className="font-bold text-lg">Reports</h1>

            <div className="mt-5">
              <Tabs
                tabs={allTabs}
                variant="secondary"
                onClickTab={(tab) => {
                  setTab(tab?.id);
                }}
                // tabs={tabs}
                selectedTabId={tab}
              ></Tabs>
            </div>

            {tab === 1 && (
              <>
                <div
                  className={`flex w-[100%] py-4 pr-2.5  justify-between items-center mt-1 h-[66px] gap-2 relative `}
                >
                  <div className="w-[16rem] relative">
                    <InputGroup
                      size="sm_2"
                      position="center"
                      placeholder="Search"
                      svg={searchIcon}
                      value={globalFilter}
                      onChange={(e) => {
                        setGlobalFilter(e.target.value);
                        setPagination({ currentPage: 1 });
                        setPage(1);
                      }}
                    />

                    {globalFilter && (
                      <img
                        src={closeIcon}
                        alt=""
                        className="absolute top-3 right-4 cursor-pointer"
                        onClick={() => {
                          setGlobalFilter('');
                          setPagination({ currentPage: 1 });
                          setPage(1);
                        }}
                      />
                    )}
                  </div>

                  <div className="flex gap-2 flex-row justify-end w-[50%]">
                    <div className="w-[80%]">
                      <Select
                        value={selectedOption}
                        options={dropDownList}
                        styles={customStyles}
                        onChange={(event) => handleSelect(event)}
                        placeholder="Select Date Range"
                        menuShouldScrollIntoView={true}
                        menuShouldBlockScroll={true}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>

                    <Button
                      variant="primary"
                      type="submit"
                      btnClassName="text-sm"
                      onClick={() => buttonClick()}
                      disabled={!downloadLoading ? false : true}
                    >
                      <div className="flex flex-row space-between gap-2">
                        <img src={download} alt=""></img>{' '}
                        {downloadLoading ? 'Loading' : 'Download'}
                      </div>
                    </Button>
                  </div>

                  {selectedOption === 'custom' && dropDownOpen && (
                    <span
                      className={`flex flex-col calenderContainer bg-[#ffffff]`}
                    >
                      <DateRange
                        onChange={(item) =>
                          handleRangeChange(item, selectedOption)
                        }
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        months={2}
                        direction="horizontal"
                        className="calendarElement"
                        maxDate={new Date()}
                      />
                      <div className="flex flex-row gap-2 justify-end w-inherit bg-[#ffffff]">
                        <Button
                          variant="tertiary"
                          type="button"
                          onClick={cancelCustom}
                          btnClassName="rounded-md text-sm"
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="primary"
                          type="button"
                          onClick={saveCustom}
                          btnClassName="rounded-md text-sm"
                        >
                          Save
                        </Button>
                      </div>
                    </span>
                  )}
                </div>
                <div className="tableContainer min-h-[60vh]">
                  <table
                    className="w-[100%] h-auto relative"
                    id="reports-table"
                  >
                    <thead>
                      <tr className="h-[40px] bg-custom-primary400 self-stretch p-[5px] px-[0px] relative">
                        <th className={thClass}>Lender Loan #</th>
                        <th className={thClass}>Borower Name</th>
                        <th className={thClass}>Purpose</th>
                        <th className={thClass}>Loan Amount</th>
                        <th className={thClass}>Mortgage Broker</th>
                        <th className={thClass}>Status</th>
                        <th className={thClass}>Total Fees</th>
                      </tr>
                    </thead>
                    {!isLoading && reportsData?.length > 0 ? (
                      <tbody>
                        {reportsData?.map((row) => (
                          <React.Fragment key={row.id}>
                            <motion.tr
                              initial={{
                                y: -20,
                              }}
                              animate={{
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotate: 0,
                              }}
                              className={
                                trClass +
                                ' table-report-border cursor-pointer hover:bg-primary50'
                              }
                              onClick={() => toggleRow(row.lender_loan_id)}
                            >
                              <td className={tdClass}>
                                <span className="flex flex-row align-center gap-3 pl-[30px]">
                                  {' '}
                                  <img
                                    src={
                                      expandedRowId === row.lender_loan_id
                                        ? arrowUp
                                        : arrowDown
                                    }
                                  ></img>
                                  {row.lender_loan_id || 'NA'}
                                </span>
                              </td>
                              <td className={tdClass}>
                                {row.borrower_name || 'NA'}
                              </td>
                              <td className={tdClass}>
                                {getPurpose({ row }) || 'NA'}
                              </td>
                              <td className={tdClass}>
                                {row.loan_amount
                                  ? `$ ${row.loan_amount}`
                                  : 'NA'}
                              </td>
                              <td className={tdClass}>
                                {row.loan_officer || 'NA'}
                              </td>
                              <td className={tdClass}>
                                <span className="">
                                  <Badge
                                    title={getStatus({ row }) || 'NA'}
                                    variant={
                                      statusVariantMap[
                                        row?.application_status_id
                                      ] || 'primary'
                                    }
                                  />
                                </span>
                              </td>
                              <td className={tdClass}>
                                {row.total_fee ? `$ ${row.total_fee}` : 'NA'}
                              </td>
                            </motion.tr>
                            {expandedRowId === row.lender_loan_id && (
                              <motion.tr
                                initial={{
                                  y: -20,
                                }}
                                animate={{
                                  x: 0,
                                  y: 0,
                                  scale: 1,
                                  rotate: 0,
                                }}
                              >
                                <td className="bg-custom-primary300" colSpan={10}>
                                  <div className="w-[100%] flex p-[15px] flex-col items-start gap-[20px] ">
                                    <span className="text-primary500 text-center font-gilroy text-xs font-bold mb-[20px]">
                                      {' '}
                                      Loan Details{' '}
                                    </span>

                                    <div className="w-[100%] border-2 border-green"></div>

                                    <div className="flex flex-col w-[100%]">
                                      <div className="flex flex row items-start mt-[20px]">
                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          LO License ID
                                        </span>

                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          Loan Program
                                        </span>

                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          Channel
                                        </span>

                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          State
                                        </span>
                                      </div>

                                      <div className="flex flex row items-start mt-[9px]">
                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {row.loan_details.license_number ||
                                            'NA'}
                                        </span>

                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {getLoanProgram({ row }) || 'NA'}
                                        </span>

                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {row.loan_details.channel || 'NA'}
                                        </span>

                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {stateCodeMapper(
                                            row.loan_details.state
                                          )}
                                        </span>
                                      </div>

                                      <div className="flex flex row items-start mt-[25px]">
                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          Application Start Date
                                        </span>

                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          Closed Date
                                        </span>
                                      </div>

                                      <div className="flex flex row items-start mt-[9px]">
                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {(row.loan_details
                                            .application_start_date &&
                                            extractDate(
                                              row.loan_details
                                                .application_start_date
                                            )) ||
                                            'NA'}
                                        </span>

                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {extractDate(
                                            row.loan_details
                                              .application_close_date
                                          ) || 'NA'}
                                        </span>
                                      </div>

                                      <span className="text-primary500 font-gilroy text-xs font-bold mb-[20px] mt-6">
                                        {' '}
                                        Fees{' '}
                                      </span>

                                      <div className="w-[100%] border-2 border-green"></div>

                                      <div className="flex flex row items-start mt-[20px]">
                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          Origination Fee
                                        </span>

                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          Proc Fees
                                        </span>

                                        <span className="text-disabled font-gilroy text-xs font-normal w-[25%]">
                                          Other Fees
                                        </span>
                                      </div>

                                      <div className="flex flex row items-start mt-[9px]">
                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {row.fees_detail.origination_fee}
                                        </span>

                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {row.fees_detail.processing_fee}
                                        </span>

                                        <span className="text-neutral1000 font-gilroy text-xs font-normal w-[25%]">
                                          {row.fees_detail.other_fees}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </motion.tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    ) : (
                      <div className="flex justify-center absolute left-[40%] top-[50%] mt-10 font-medium">
                        {'No relevant data found'}
                      </div>
                    )}
                  </table>

                  {!isFetching && isSuccess && reportsData?.length > 0 && (
                    <section className="flex flex-row w-[50%] justify-between py-4">
                      <span className="flex flex row ">
                        <div className="p-xs-regular">
                          Showing{' '}
                          {pagination.currentPage * pagination.pageSize -
                            pagination.pageSize +
                            1}{' '}
                          -{' '}
                          {Math.min(
                            pagination.currentPage * pagination.pageSize,
                            pagination.totalItemsCount
                          )}
                        </div>

                        <span className="p-xs-regular pl-1">
                          of {pagination.totalItemsCount}
                        </span>
                      </span>

                      <div className="flex items-center gap-6">
                        <button
                          onClick={handleFirstPage}
                          disabled={pagination.currentPage === 1}
                        >
                          <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.293701 5.29365C-0.0969238 5.68428 -0.0969238 6.31865 0.293701 6.70928L5.2937 11.7093C5.68433 12.0999 6.3187 12.0999 6.70933 11.7093C7.09995 11.3187 7.09995 10.6843 6.70933 10.2937L2.41558 5.9999L6.7062 1.70615C7.09683 1.31553 7.09683 0.681152 6.7062 0.290527C6.31558 -0.100098 5.6812 -0.100098 5.29058 0.290527L0.290576 5.29053L0.293701 5.29365ZM11.2937 0.293652L6.2937 5.29365C5.90308 5.68428 5.90308 6.31865 6.2937 6.70928L11.2937 11.7093C11.6843 12.0999 12.3187 12.0999 12.7093 11.7093C13.1 11.3187 13.1 10.6843 12.7093 10.2937L8.41558 5.9999L12.7062 1.70615C13.0968 1.31553 13.0968 0.681152 12.7062 0.290527C12.3156 -0.100098 11.6812 -0.100098 11.2906 0.290527L11.2937 0.293652Z"
                              fill={
                                pagination.currentPage === 1
                                  ? '#9C9C9C'
                                  : '#151314'
                              }
                            />
                          </svg>
                        </button>
                        <button
                          onClick={handlePreviousPage}
                          disabled={pagination.currentPage === 1}
                        >
                          <svg
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.293701 5.29365C-0.0969238 5.68428 -0.0969238 6.31865 0.293701 6.70928L5.2937 11.7093C5.68433 12.0999 6.3187 12.0999 6.70933 11.7093C7.09995 11.3187 7.09995 10.6843 6.70933 10.2937L2.41558 5.9999L6.7062 1.70615C7.09683 1.31553 7.09683 0.681152 6.7062 0.290527C6.31558 -0.100098 5.6812 -0.100098 5.29058 0.290527L0.290576 5.29053L0.293701 5.29365Z"
                              fill={
                                pagination.currentPage === 1
                                  ? '#9C9C9C'
                                  : '#151314'
                              }
                            />
                          </svg>
                        </button>
                        <button
                          onClick={handleNextPage}
                          disabled={
                            pagination.currentPage === pagination.totalPages
                          }
                        >
                          <svg
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.70627 5.29365C7.0969 5.68428 7.0969 6.31865 6.70627 6.70928L1.70627 11.7093C1.31565 12.0999 0.681274 12.0999 0.290649 11.7093C-0.0999756 11.3187 -0.0999756 10.6843 0.290649 10.2937L4.5844 5.9999L0.293774 1.70615C-0.0968506 1.31553 -0.0968506 0.681152 0.293774 0.290527C0.684399 -0.100098 1.31877 -0.100098 1.7094 0.290527L6.7094 5.29053L6.70627 5.29365Z"
                              fill={
                                pagination.currentPage === pagination.totalPages
                                  ? '#9C9C9C'
                                  : '#151314'
                              }
                            />
                          </svg>
                        </button>
                        <button
                          onClick={handleLastPage}
                          disabled={
                            pagination.currentPage === pagination.totalPages
                          }
                        >
                          <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.7063 6.70615C13.0969 6.31553 13.0969 5.68115 12.7063 5.29053L7.70627 0.290527C7.31565 -0.100098 6.68127 -0.100098 6.29065 0.290527C5.90002 0.681152 5.90002 1.31553 6.29065 1.70615L10.5844 5.9999L6.29377 10.2937C5.90315 10.6843 5.90315 11.3187 6.29377 11.7093C6.6844 12.0999 7.31877 12.0999 7.7094 11.7093L12.7094 6.70928L12.7063 6.70615ZM1.70627 11.7062L6.70627 6.70615C7.0969 6.31553 7.0969 5.68115 6.70627 5.29053L1.70627 0.290527C1.31565 -0.100098 0.681274 -0.100098 0.290649 0.290527C-0.0999756 0.681152 -0.0999756 1.31553 0.290649 1.70615L4.5844 5.9999L0.293774 10.2937C-0.0968506 10.6843 -0.0968506 11.3187 0.293774 11.7093C0.684399 12.0999 1.31877 12.0999 1.7094 11.7093L1.70627 11.7062Z"
                              fill={
                                pagination.currentPage === pagination.totalPages
                                  ? '#9C9C9C'
                                  : '#151314'
                              }
                            />
                          </svg>
                        </button>
                      </div>
                    </section>
                  )}
                </div>
              </>
            )}

            {tab === 2 && <></>}
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
