import { api } from 'constants/constant';
import { getHeaders, getUserDetails } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const adminToolsAPI = createApi({
  reducerPath: 'adminToolsAPI',
  tagTypes: ['adminTools'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    getCurrentOffer: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `loan/tools/get_current_offer/application/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    compareLoanOfficer: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/tools/compare_offer_by_user_input`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    getUsers: builder.query({
      query: () => {
        return {
          url: `loan/tools/get_users`,
          method: 'POST',
          body: {},
          headers: getHeaders(),
        };
      },
    }),
    getAmortizationSchedule: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `loan/tools/get_amortization_schedule/application/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    compareOfferDocumentUpload: builder.mutation({
      query: ({ data }) => {
        const { token } = getUserDetails();

        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        return {
          url: `loan/tools/compare_offer_document_upload`,
          body: data,
          headers: headers,
          method: 'POST',
        };
      },
    }),
    downloadQuoteApplication: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/tools/download_quote`,
          body: data,
          headers: getHeaders(),
          method: 'POST',
        };
      },
    }),
    getAmortizationScheduleConsumer: builder.query({
      query: () => {
        return {
          url: `loan/tools/get_amortization_schedule_consumer`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    getCurrentOfferConsumer: builder.query({
      query: ({}) => {
        return {
          url: `loan/tools/get_current_offer_consumer`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),

    compareUserInputConsumer: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/tools/compare_offer_by_user_input_consumer`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),

    consumerOfferDocument: builder.mutation({
      query: ({ data }) => {
        const { token } = getUserDetails();

        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        return {
          url: `loan/tools/compare_offer_document_upload_consumer`,
          body: data,
          headers: headers,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetCurrentOfferQuery,
  useCompareLoanOfficerMutation,
  useGetUsersQuery,
  useGetAmortizationScheduleQuery,
  useCompareOfferDocumentUploadMutation,
  useDownloadQuoteApplicationMutation,
  useGetAmortizationScheduleConsumerQuery,
  useGetCurrentOfferConsumerQuery,
  useCompareUserInputConsumerMutation,
  useConsumerOfferDocumentMutation,
} = adminToolsAPI;
