import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
//components
import Button from 'components/button/index';
import Modal from 'components/modal/index';
import InputGroup from 'components/input-group';
import Select from 'react-select';
import { bgColors, textColors, borderColors } from '../../../themes/colors';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
//RTK query
import {
  useGetLoanCostTypesQuery,
  useGetUserFeesWorksheetQuery,
  useSaveUserFeesWorksheetMutation,
  useUpdateUserFeesWorksheetMutation,
} from 'redux_store/services/admin/feesworksheet';
import { useGetBorrowerProfileQuery } from 'redux_store/services/borrower-profile';

//fees data
import {
  addObjectToChildren,
  restructureData,
  initializeInputValues,
  mixBothData,
  removeObjectFromChildren,
  updateCombinedArray,
  calculateAndFormatSum,
  validateCustomTypes,
} from 'pages/borrower-profile/fees-worksheet/formData/feesWorksheet';

//utils
import { v4 as uuidv4 } from 'uuid';
import { customToast } from 'components/toast';
import { formatNumberIntoCurrency } from 'constants/formatNumber';

//icons
import expandVector from 'assets/icons/expandVector.svg';
import shrinkVector from 'assets/icons/shrinkVector.svg';
import { RxCross2 } from 'react-icons/rx';

const ClosingCost = ({
  setEditClosingCostVisiblity,
  refetch,
  editClosingCostRow,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const loanAmount = editClosingCostRow?.loanAmount || 0;
  const interestRate = editClosingCostRow?.rate || 0;
  // States
  const [combinedData, setCombinedData] = useState([]);
  const [closingDate, setClosingDate] = useState({
    date: '',
    uniqueId: '',
  });
  const [firstPaymentDate, setFirstPaymentDate] = useState({
    date: '',
    uniqueId: '',
  });
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [childrenIndices, setChildrenIndices] = useState([]);
  const [tableCellValues, setTableCellValues] = useState({});
  const [cashFromBorrower, setCashFromBorrower] = useState('-');
  const [updatedFeeJson, setUpdatedFeeJson] = useState([]);
  //calling RTK query
  const { data: loanCostTypes } = useGetLoanCostTypesQuery();
  const [saveFeesData, { isLoading: isSaveFeesDataLoading }] =
    useSaveUserFeesWorksheetMutation();
  const [updateFeesData, { isLoading: isUpdateFeesDataLoading }] =
    useUpdateUserFeesWorksheetMutation();
  const {
    data: feesWorksheetData,
    error: errorMessage,
    isLoading: isFeesworkSheetLoading,
  } = useGetUserFeesWorksheetQuery(
    {
      application_Id: applicationId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: applicationData, refetch: refetchBorrowerInfo } =
    useGetBorrowerProfileQuery(
      { applicationId },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  // converts strings with characters to number
  function convertToNumber(input) {
    const cleanedInput = input.replace(/[^0-9.-]/g, '');
    // Convert the cleaned input to a floating point number
    const number = parseFloat(cleanedInput);
    if (isNaN(number)) {
      return 0;
    }

    return parseInt(number.toFixed(3));
  }

  useEffect(() => {
    if (loanCostTypes?.data && feesWorksheetData?.data) {
      const combineBothData = mixBothData(
        feesWorksheetData?.data?.fees_json ?? []
      );

      let customInputData = combineBothData.map((data) => ({
        ...data,
        children:
          data.children?.map((child) => ({
            ...child,
            chargeDropdown: child.chargeDropdown ?? true, // Set chargeDropdown to true if it doesn't exist
          })) || [],
      }));
      combineBothData.forEach((data) => {
        if (data?.children[data?.children?.length - 1]?.name) {
          customInputData = addObjectToChildren(
            customInputData,
            data?.uniqueId,
            {
              uniqueId: uuidv4(),
              parentId: 0,
              name: '',
              isCollapsible: false,
              children: [],
              type: 'custom',
              borrowerPaidAtClosingValue: 0,
              borrowerPaidBeforeClosingValue: 0,
              otherPaidAtClosingValue: 0,
              otherPaidBeforeClosingValue: 0,
              creditType: 1,
              chargeDropdown: true,
            }
          );
        }
      });
      const inputValues = initializeInputValues(customInputData);
      setTableCellValues(inputValues);
      setCombinedData(customInputData);
    } else if (errorMessage?.status === 404 && loanCostTypes?.data) {
      const combineBothData = mixBothData(loanCostTypes?.data ?? []);
      let customInputData = combineBothData.map((data) => ({
        ...data,
        children:
          data.children?.map((child) => ({
            ...child,
            chargeDropdown: child.chargeDropdown ?? true, // Set chargeDropdown to true if it doesn't exist
          })) || [],
      }));
      combineBothData.forEach((data) => {
        if (data?.children[data?.children?.length - 1]?.name) {
          customInputData = addObjectToChildren(
            customInputData,
            data?.uniqueId,
            {
              uniqueId: uuidv4(),
              parentId: 0,
              name: '',
              isCollapsible: false,
              children: [],
              type: 'custom',
              borrowerPaidAtClosingValue: 0,
              borrowerPaidBeforeClosingValue: 0,
              otherPaidAtClosingValue: 0,
              otherPaidBeforeClosingValue: 0,
              creditType: 1,
              chargeDropdown: true,
            }
          );
        }
      });

      const inputValues = initializeInputValues(customInputData);
      setTableCellValues(inputValues);
      setCombinedData(customInputData);
    }
  }, [loanCostTypes?.data, feesWorksheetData, errorMessage]);

  const credChargeOptions = [
    { id: 1, name: 'Charge' },
    { id: 2, name: 'Credit' },
  ];

  const onChangeTableCell = (uniqueId, propertyName, value, type) => {
    setTableCellValues({
      ...tableCellValues,
      [uniqueId]: {
        ...tableCellValues[uniqueId],
        [propertyName]: type === 'text' ? value : parseFloat(value),
      },
    });
  };

  const saveFeesWorksheetData = async () => {
    const feesData = updateCombinedArray(combinedData, tableCellValues);
    const hasError = validateCustomTypes(feesData);

    if (hasError) {
      customToast.error(
        'It looks like the fee name is missing. Please check again and try again.'
      );
      return;
    }
    const loadingId = customToast.loading('Updating Fees Worksheet...');
    try {
      let resp;
      if (errorMessage?.status === 404) {
        resp = await saveFeesData({
          data: {
            userId: applicationData?.data?.user_profile?.userId,
            userProfileId: applicationData?.data?.user_profile?.userProfileId,
            fees_json: feesData,
            cash_from_borrower: convertToNumber(cashFromBorrower),
          },
          applicationId,
        });
      } else {
        resp = await updateFeesData({
          data: {
            userId: applicationData?.data?.user_profile?.userId,
            userProfileId: applicationData?.data?.user_profile?.userProfileId,
            fees_json: feesData,
            cash_from_borrower: convertToNumber(cashFromBorrower),
          },
          applicationId,
        });
      }
      if (resp?.data?.status === 200) {
        refetch();
        setEditClosingCostVisiblity(false);
        customToast.success('Smart Fees updated successfully');
      } else {
        customToast.error(resp?.error?.data?.message);
      }
    } catch (error) {
    } finally {
      // setEditable(false);
      toast.dismiss(loadingId);
    }
  };

  useEffect(() => {
    const totalValue = calculateAndFormatSum(
      combinedData,
      tableCellValues,
      [],
      'borrowerPaidAtClosingValue',
      'borrowerPaidBeforeClosingValue',
      'otherPaidBeforeClosingValue',
      'otherPaidAtClosingValue'
    );
    setCashFromBorrower(totalValue);
  }, [tableCellValues, combinedData]);

  // calculate date difference
  const calculateDifferenceInDays = (date1, date2) => {
    if (!date1 || !date2) return 0; // Return 0 if either date is invalid
    const timeDiff = date2.getTime() - date1.getTime();

    // Check if timeDiff is NaN or less than zero, return 0 if true
    if (isNaN(timeDiff)) return 0;

    return Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
  };

  // calculate prepaid interest
  useEffect(() => {
    const closingDateObj = new Date(closingDate?.date);
    const firstPaymentDateObj = new Date(firstPaymentDate?.date);
    const days = calculateDifferenceInDays(closingDateObj, firstPaymentDateObj);

    // Calculate the daily interest rate
    const dailyInterestRate = interestRate / 100 / 365;
    // Calculate the prepaid interest for the number of days
    const prepaidInterest = loanAmount * dailyInterestRate * days;
    setNumberOfDays(days);
    setTableCellValues((prevValues) => ({
      ...prevValues,
      [closingDate?.uniqueId || firstPaymentDate.uniqueId]: {
        ...prevValues[closingDate?.uniqueId || firstPaymentDate.uniqueId],
        borrowerPaidAtClosingValue: Math.abs(prepaidInterest),
        borrowerPaidBeforeClosingValue: 0,
        otherPaidAtClosingValue: 0,
        otherPaidBeforeClosingValue: 0,
        creditType: prepaidInterest > 0 ? 1 : 2,
        numberOfDays: days,
        estimatedClosing: closingDate.date,
        firstPayment: firstPaymentDate.date,
      },
    }));
  }, [closingDate.date, firstPaymentDate.date]);

  // get values based on percentage
  const calculateLoanAmountPercent = (inputValue, uniqueId) => {
    const percentageValue = (inputValue / 100) * loanAmount;

    setTableCellValues((prevValues) => {
      return {
        ...prevValues,
        [uniqueId]: {
          ...prevValues[uniqueId],
          borrowerPaidAtClosingValue: percentageValue,
          borrowerPaidBeforeClosingValue: 0,
          otherPaidAtClosingValue: 0,
          otherPaidBeforeClosingValue: 0,
          percentageValue: inputValue,
        },
      };
    });
  };

  // calculate totals
  useEffect(() => {
    const fees_json = updateCombinedArray(combinedData, tableCellValues);
    const formattedData = restructureData(fees_json ?? []);
    setUpdatedFeeJson(formattedData);
  }, [combinedData, tableCellValues]);

  const form = (
    <>
      <div className=" bg-white h-[90vh] self-center rounded-md flex flex-col justify-between p-3  gap-2">
        <div className="h-fit w-[100%] flex flex-row justify-between border-b border-b-grey100">
          <div className="h-[100%] flex flex-col justify-end p-2">
            <h1 className="p-md-bold">Edit Closing Cost</h1>
          </div>
          <div className="h-[100%] flex flex-col justify-end p-2 cursor-pointer">
            <RxCross2
              size={20}
              className="p-xs-semibold self-center"
              onClick={() => setEditClosingCostVisiblity(false)}
            />
          </div>
        </div>

        <div className="h-[86%] w-[100%] overflow-y-scroll">
          {combinedData &&
            combinedData
              ?.filter((_, index) => index !== 3)
              ?.map((parent, Pindex) => {
                const totalAmount = updatedFeeJson?.find(
                  (p) => p.heading === parent.name
                );
                const sumTotalofD =
                  updatedFeeJson[0]?.total +
                  updatedFeeJson[1]?.total +
                  updatedFeeJson[2]?.total;
                return (
                  <>
                    {Pindex === 3 && (
                      <div
                        className="h-fit w-[100%] flex flex-col justify-between py-3 px-3 bg-chatbot_bg cursor-pointer my-2"
                        key={Pindex}
                      >
                        <div className="flex flex-row items-center gap-3">
                          <img src={shrinkVector} alt="" />

                          <h1 className="p-xs-bold">
                            D. Total Loan Costs (A+B+C):
                          </h1>
                          <span className="p-xs-bold">
                            {formatNumberIntoCurrency(sumTotalofD || 0)}
                          </span>
                        </div>
                      </div>
                    )}
                    <div
                      className="h-fit w-[100%] flex flex-col justify-between py-3 px-3 bg-chatbot_bg cursor-pointer my-2"
                      key={Pindex}
                      onClick={() => {
                        if (childrenIndices.includes(Pindex)) {
                          let filteredIndices = childrenIndices.filter(
                            (item) => {
                              return item !== Pindex;
                            }
                          );
                          setChildrenIndices(filteredIndices);
                        } else {
                          setChildrenIndices((prev) => {
                            return [...prev, Pindex];
                          });
                        }
                      }}
                    >
                      <div className="flex flex-row items-center gap-3">
                        {childrenIndices.includes(Pindex) ? (
                          <img src={shrinkVector} alt="" />
                        ) : (
                          <img src={expandVector} alt="" />
                        )}
                        <h1 className="p-xs-bold">{parent.name}:</h1>
                        <span className="p-xs-bold">
                          {formatNumberIntoCurrency(totalAmount?.total || 0)}
                        </span>
                      </div>
                    </div>

                    {childrenIndices.includes(Pindex) &&
                      parent.children.length > 0 &&
                      parent.children
                        .filter((child) => child.type !== 'custom')
                        .map((child, Cindex) => {
                          return (
                            <>
                              <motion.div
                                initial={{
                                  y: -10,
                                }}
                                animate={{
                                  x: 0,
                                  y: 0,
                                  scale: 1,
                                  rotate: 0,
                                }}
                                className="h-fit w-[100%] flex flex-col justify-between py-2 px-4 bg-white"
                                key={Cindex}
                              >
                                <div className="w-[100%] flex flex-row justify-between ">
                                  {child.children.length === 0 && (
                                    <div
                                      className={`w-[100%] ${!child?.calendar ? 'flex flex-row' : ''} justify-start gap-2 items-center`}
                                    >
                                      {child?.isAPR ? (
                                        <p className={`w-fit p-xs-bold pl-2`}>
                                          {'APR '}
                                        </p>
                                      ) : (
                                        <p
                                          className={`w-fit p-xs-bold ml-8`}
                                        ></p>
                                      )}
                                      <label
                                        className={`${child?.calendar ? 'ml-10 p-xs-regular' : 'p-xs-regular'} whitespace-nowrap `}
                                      >
                                        {child.name}
                                      </label>
                                      {child?.percentage && (
                                        <div className="ml-3 flex items-center gap-2 font-bold">
                                          <InputGroup
                                            size="xsm"
                                            position="left"
                                            type="number"
                                            value={
                                              tableCellValues[child?.uniqueId]
                                                ?.percentageValue || ''
                                            }
                                            // disabled={!editable}
                                            // readOnly={!editable}
                                            placeholder="Enter Percent"
                                            onKeyDown={(e) => {
                                              if (
                                                e.key === '-' ||
                                                e.key === '+' ||
                                                e.key === 'e' ||
                                                e.key === 'E'
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                            onChange={(e) => {
                                              calculateLoanAmountPercent(
                                                e.target.value,
                                                child.uniqueId
                                              );
                                            }}
                                          />
                                          %
                                        </div>
                                      )}
                                      {child?.calendar && (
                                        <>
                                          {' '}
                                          <div className="flex gap-4 mt-3 ml-10">
                                            <div>
                                              {' '}
                                              <label
                                                className={`block text-custom-greengray p-xs-bold whitespace-nowrap mb-4 `}
                                              >
                                                Estimated Closing Date
                                              </label>
                                              <DatePicker
                                                selected={
                                                  closingDate?.date ||
                                                  tableCellValues[
                                                    child?.uniqueId
                                                  ].estimatedClosing
                                                }
                                                wrapperClassName="w-full"
                                                onChange={(e) =>
                                                  setClosingDate({
                                                    date:
                                                      e ||
                                                      tableCellValues[
                                                        child?.uniqueId
                                                      ].estimatedClosing,
                                                    uniqueId: child.uniqueId,
                                                  })
                                                }
                                                dropdownMode={'select'}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                placeholderText={'select date'}
                                                className={`cursor-pointer border-2 w-[6rem] p-xs-regular max-h-[39px] focus:border-2 focus:outline-none rounded-lg text-base px-3 py-1.5 text-black border-primary focus:border-secondary`}
                                                scrollableYearDropdown
                                                onKeyDown={(e) => {
                                                  e.preventDefault();
                                                }}
                                              />
                                            </div>
                                            <div>
                                              {' '}
                                              <label
                                                className={`block text-custom-greengray p-xs-bold whitespace-nowrap mb-4`}
                                              >
                                                First Payment
                                              </label>
                                              <DatePicker
                                                selected={
                                                  firstPaymentDate?.date ||
                                                  tableCellValues[
                                                    child?.uniqueId
                                                  ].firstPayment
                                                }
                                                wrapperClassName="w-full"
                                                onChange={(e) =>
                                                  setFirstPaymentDate({
                                                    date:
                                                      e ||
                                                      tableCellValues[
                                                        child?.uniqueId
                                                      ].firstPayment,
                                                    uniqueId: child.uniqueId,
                                                  })
                                                }
                                                dropdownMode={'select'}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                placeholderText={'select date'}
                                                className={`cursor-pointer border-2 w-[6rem] p-xs-regular max-h-[39px] focus:border-2 focus:outline-none rounded-lg text-base px-3 py-1.5 text-black border-primary focus:border-secondary`}
                                                scrollableYearDropdown
                                                onKeyDown={(e) => {
                                                  e.preventDefault();
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="flex mt-3 ml-10">
                                            <div>
                                              {' '}
                                              <label
                                                className={`block text-custom-greengray p-xs-bold`}
                                              >
                                                Number of days
                                              </label>
                                              <p
                                                className={`block text-custom-greengray p-xs-bold`}
                                              >
                                                {numberOfDays
                                                  ? numberOfDays
                                                  : tableCellValues[
                                                      child?.uniqueId
                                                    ].numberOfDays}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  )}
                                  <div className="w-[100%] h-[100%] flex flex-col justify-between gap-5">
                                    {child.children &&
                                      child.children.map((mChild, Mindex) => {
                                        return (
                                          <>
                                            <div
                                              className={`w-[100%] flex flex-row  justify-between  gap-2`}
                                              key={Mindex + Pindex + Cindex}
                                            >
                                              <div className="w-fit flex flex-col justify-center">
                                                <div className="w-fit flex justify-center">
                                                  {mChild?.isAPR ? (
                                                    <p
                                                      className={`w-fit p-xs-bold px-2`}
                                                    >
                                                      {'APR '}
                                                    </p>
                                                  ) : (
                                                    <p
                                                      className={`w-fit p-xs-bold ml-10`}
                                                    ></p>
                                                  )}
                                                  <label className="p-xs-regular">
                                                    {mChild.name}
                                                  </label>
                                                </div>
                                                {mChild.chargeDropdown && (
                                                  <div className="mt-2 ml-10">
                                                    <Select
                                                      menuShouldScrollIntoView={
                                                        true
                                                      }
                                                      menuShouldBlockScroll={
                                                        true
                                                      }
                                                      menuPlacement="auto"
                                                      menuPosition="fixed"
                                                      name="creditType"
                                                      value={
                                                        credChargeOptions.find(
                                                          (option) =>
                                                            option.id ===
                                                            tableCellValues[
                                                              mChild?.uniqueId
                                                            ]?.creditType
                                                        ) ||
                                                        credChargeOptions[0]
                                                      }
                                                      onChange={(e) => {
                                                        onChangeTableCell(
                                                          mChild?.uniqueId,
                                                          'creditType',
                                                          e.id,
                                                          'text'
                                                        );
                                                      }}
                                                      options={
                                                        credChargeOptions
                                                      }
                                                      getOptionLabel={(
                                                        option
                                                      ) => {
                                                        return option.name;
                                                      }}
                                                      getOptionValue={(
                                                        value
                                                      ) => {
                                                        return value.id;
                                                      }}
                                                      className="w-fit"
                                                      styles={{
                                                        control: (
                                                          provided
                                                        ) => ({
                                                          ...provided,
                                                          fontSize: '12px',
                                                          position: 'relative',
                                                          fontWeight: '400',
                                                          borderRadius: '5px',
                                                          boxShadow: 'none',
                                                          paddingRight: '9px',
                                                          backgroundColor:
                                                            bgColors.primary50,
                                                          border: 'none',
                                                        }),
                                                        option: (
                                                          provided,
                                                          state
                                                        ) => ({
                                                          ...provided,
                                                          backgroundColor:
                                                            bgColors.tertiary_bg,
                                                          color:
                                                            textColors.black,
                                                          fontSize: '12px',
                                                          fontWeight: '400',
                                                          '&:hover': {
                                                            cursor: 'pointer',
                                                            backgroundColor:
                                                              bgColors.primary50,
                                                          },
                                                          '&:active': {
                                                            backgroundColor:
                                                              bgColors.primary50,
                                                          },
                                                        }),
                                                        menu: (
                                                          provided,
                                                          state
                                                        ) => ({
                                                          ...provided,
                                                          borderRadius: '5px',
                                                          color:
                                                            textColors.black,
                                                          fontSize: '12px',
                                                          fontWeight: '400',
                                                        }),
                                                        indicatorSeparator:
                                                          () => null,
                                                      }}
                                                      theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                          ...theme.colors,
                                                          neutral80:
                                                            textColors.black,
                                                        },
                                                      })}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                              <div className="mr-9 flex items-center gap-2 font-bold">
                                                <InputGroup
                                                  type={'number'}
                                                  placeholder={'$'}
                                                  value={
                                                    tableCellValues[
                                                      mChild?.uniqueId
                                                    ]
                                                      ?.borrowerPaidAtClosingValue ||
                                                    ''
                                                  }
                                                  name="borrowerPaidAtClosingValue"
                                                  onKeyDown={(e) => {
                                                    if (
                                                      e.key === '-' ||
                                                      e.key === '+' ||
                                                      e.key === 'e' ||
                                                      e.key === 'E'
                                                    ) {
                                                      e.preventDefault();
                                                    }
                                                  }}
                                                  onChange={(e) => {
                                                    onChangeTableCell(
                                                      mChild?.uniqueId,
                                                      e.target.name,
                                                      e.target.value
                                                    );
                                                  }}
                                                  size="sm"
                                                  style={{
                                                    width: '150px',
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                  </div>
                                  {child.children.length === 0 && (
                                    <div className="w-[100%] flex flex-row justify-end pr-9 gap-4">
                                      <InputGroup
                                        type={'number'}
                                        value={
                                          tableCellValues[child?.uniqueId]
                                            ?.borrowerPaidAtClosingValue || ''
                                        }
                                        name="borrowerPaidAtClosingValue"
                                        onChange={(e) => {
                                          onChangeTableCell(
                                            child?.uniqueId,
                                            e.target.name,
                                            e.target.value
                                          );
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            child?.percentage ||
                                            child.calendar
                                          ) {
                                            e.preventDefault();
                                          } else if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        placeholder={'$'}
                                        size="sm"
                                        style={{
                                          width: '150px',
                                        }}
                                      />
                                      {/* <RiDeleteBin5Line
                                    color="#5A5555"
                                    className="self-center"
                                    size={20}
                                  /> */}
                                    </div>
                                  )}
                                </div>
                              </motion.div>
                            </>
                          );
                        })}
                    {childrenIndices.includes(Pindex) &&
                      parent.children.length > 0 &&
                      parent.children
                        .filter((child) => child.type === 'custom')
                        .map((child, Cindex, childArray) => {
                          return (
                            <>
                              {' '}
                              <div className="w-[100%] flex flex-row justify-between mt-2">
                                <div className="w-[50%] flex flex-row justify-around gap-3">
                                  <InputGroup
                                    type={'text'}
                                    value={
                                      tableCellValues[child?.uniqueId]?.name ||
                                      ''
                                    }
                                    placeholder={'Provide Fee type'}
                                    name="name"
                                    onChange={(e) => {
                                      onChangeTableCell(
                                        child?.uniqueId,
                                        e.target.name,
                                        e.target.value,
                                        'text'
                                      );
                                    }}
                                    style={{
                                      width: '150px',
                                    }}
                                    size="sm"
                                  />
                                  <Select
                                    name="creditType"
                                    menuShouldScrollIntoView={true}
                                    menuShouldBlockScroll={true}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    value={
                                      credChargeOptions.find(
                                        (option) =>
                                          option.id ===
                                          tableCellValues[child?.uniqueId]
                                            ?.creditType
                                      ) || credChargeOptions[0]
                                    }
                                    onChange={(e) => {
                                      onChangeTableCell(
                                        child?.uniqueId,
                                        'creditType',
                                        e.id,
                                        'text'
                                      );
                                    }}
                                    options={credChargeOptions}
                                    getOptionLabel={(option) => {
                                      return option.name;
                                    }}
                                    getOptionValue={(value) => {
                                      return value.id;
                                    }}
                                    className="w-fit"
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        fontSize: '12px',
                                        position: 'relative',
                                        fontWeight: '400',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                        paddingRight: '9px',
                                        backgroundColor: bgColors.primary50,
                                        border: 'none',
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: bgColors.tertiary_bg,
                                        color: textColors.black,
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        '&:hover': {
                                          cursor: 'pointer',
                                          backgroundColor: bgColors.primary50,
                                        },
                                        '&:active': {
                                          backgroundColor: bgColors.primary50,
                                        },
                                      }),
                                      menu: (provided, state) => ({
                                        ...provided,
                                        borderRadius: '5px',
                                        color: textColors.black,
                                        fontSize: '12px',
                                        fontWeight: '400',
                                      }),
                                      indicatorSeparator: () => null,
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        neutral80: textColors.black,
                                      },
                                    })}
                                  />
                                </div>
                                <div className="w-[50%] flex flex-row justify-end  gap-4">
                                  <InputGroup
                                    placeholder={'$'}
                                    value={
                                      tableCellValues[child?.uniqueId]
                                        ?.borrowerPaidAtClosingValue || ''
                                    }
                                    name="borrowerPaidAtClosingValue"
                                    type="number"
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === '-' ||
                                        e.key === '+' ||
                                        e.key === 'e' ||
                                        e.key === 'E'
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      onChangeTableCell(
                                        child?.uniqueId,
                                        e.target.name,
                                        e.target.value
                                      );
                                    }}
                                    size="sm"
                                    style={{
                                      width: '150px',
                                    }}
                                  />
                                  <div className="flex flex-col justify-center h-[35px] w-[35px]">
                                    {Cindex !== childArray.length - 1 && (
                                      <Button
                                        variant="link"
                                        style={{ padding: 0 }}
                                        btnClassName="w-[10%] p-0"
                                        onClick={() => {
                                          setCombinedData([
                                            ...removeObjectFromChildren(
                                              combinedData,
                                              parent?.uniqueId,
                                              child?.uniqueId
                                            ),
                                          ]);
                                        }}
                                      >
                                        <svg
                                          width="14"
                                          height="16"
                                          viewBox="0 0 14 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M5.32812 1.6125L4.73438 2.5H9.26562L8.67188 1.6125C8.625 1.54375 8.54688 1.5 8.4625 1.5H5.53438C5.45 1.5 5.37187 1.54062 5.325 1.6125H5.32812ZM9.92188 0.78125L11.0688 2.5H11.5H13H13.25C13.6656 2.5 14 2.83437 14 3.25C14 3.66563 13.6656 4 13.25 4H13V13.5C13 14.8813 11.8813 16 10.5 16H3.5C2.11875 16 1 14.8813 1 13.5V4H0.75C0.334375 4 0 3.66563 0 3.25C0 2.83437 0.334375 2.5 0.75 2.5H1H2.5H2.93125L4.07812 0.778125C4.40312 0.29375 4.95 0 5.53438 0H8.4625C9.04688 0 9.59375 0.29375 9.91875 0.778125L9.92188 0.78125ZM2.5 4V13.5C2.5 14.0531 2.94688 14.5 3.5 14.5H10.5C11.0531 14.5 11.5 14.0531 11.5 13.5V4H2.5ZM5 6V12.5C5 12.775 4.775 13 4.5 13C4.225 13 4 12.775 4 12.5V6C4 5.725 4.225 5.5 4.5 5.5C4.775 5.5 5 5.725 5 6ZM7.5 6V12.5C7.5 12.775 7.275 13 7 13C6.725 13 6.5 12.775 6.5 12.5V6C6.5 5.725 6.725 5.5 7 5.5C7.275 5.5 7.5 5.725 7.5 6ZM10 6V12.5C10 12.775 9.775 13 9.5 13C9.225 13 9 12.775 9 12.5V6C9 5.725 9.225 5.5 9.5 5.5C9.775 5.5 10 5.725 10 6Z"
                                            fill="#5A5555"
                                          />
                                        </svg>
                                      </Button>
                                    )}
                                    {Cindex === childArray.length - 1 && (
                                      <Button
                                        variant="link"
                                        style={{ padding: 0 }}
                                        btnClassName="w-[10%] p-0"
                                        onClick={() => {
                                          setCombinedData([
                                            ...addObjectToChildren(
                                              combinedData,
                                              parent?.uniqueId,
                                              {
                                                uniqueId: uuidv4(),
                                                parentId: 0,
                                                name: '',
                                                isCollapsible: false,
                                                children: [],
                                                type: 'custom',
                                                borrowerPaidAtClosingValue: '-',
                                                borrowerPaidBeforeClosingValue:
                                                  '-',
                                                otherPaidAtClosingValue: '-',
                                                otherPaidBeforeClosingValue:
                                                  '-',
                                              }
                                            ),
                                          ]);
                                        }}
                                      >
                                        <svg
                                          width="15"
                                          height="15"
                                          viewBox="0 0 100 100"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <line
                                            x1="10"
                                            y1="50"
                                            x2="90"
                                            y2="50"
                                            stroke="black"
                                            stroke-width="10"
                                          />
                                          <line
                                            x1="50"
                                            y1="10"
                                            x2="50"
                                            y2="90"
                                            stroke="black"
                                            stroke-width="10"
                                          />
                                        </svg>
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                  </>
                );
              })}
        </div>

        <div className="h-fit w-[100%] flex flex-row justify-end">
          <div className="h-[100%] flex flex-row justify-end gap-3">
            <Button
              variant="white_sm"
              onClick={() => setEditClosingCostVisiblity(false)}
              type="button"
              btnClassName="border border-disabled text-disabled rounded-md"
            >
              Cancel
            </Button>
            <Button
              variant="primary_sm"
              onClick={saveFeesWorksheetData}
              type="button"
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  );
  return <Modal show={true} children={form} width="max-w-[692px]" />;
};

export default ClosingCost;
