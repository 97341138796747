import { useState, useRef, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { motion } from 'framer-motion';
//child components
import Typewriter from './Typewritter';
//constants
import { chat_end_point } from 'constants/constant.js';
import { hasPermissions } from 'constants/permissionChecker';
//libs
import { Resizable } from 're-resizable';
//utils
import { token } from 'auth/auth';
//icons
import selectDocumentIcon from 'assets/icons/selectDocument.svg';
import sendMessage from 'assets/icons/sendMessage.svg';
import { useUploadChatDocsMutation } from 'redux_store/services/client/documentUpload';
import fundmoreai from 'assets/fundmoreai2.png';
import fundmoreailogo from 'assets/fundmoreai.png';
import maximize from 'assets/icons/maximize.svg';
import minimize from 'assets/icons/minimize.svg';
import fundmoreaiIcon from 'assets/fundmoreai.png'
//RTK
import { useSelector, useDispatch } from 'react-redux';
import { chatbotData } from 'redux_store/slices/chatMessage';
import { setChatMessage } from 'redux_store/slices/chatMessage';
import { setPageInfo } from 'redux_store/slices/application';
import { chatQuery, removeMarkersAndJson } from 'constants/chatQuery';
import { getUserDetails } from 'auth/auth';
//components
import {
  useGetUserInfoQuery,
  useIsFirstTimeUserMutation,
} from 'redux_store/services/client/onboarding';
//components
import loader from 'assets/icons/loader.svg';
//redux
import { userRoleData } from 'redux_store/slices/userRole';
const ChatModel = () => {
  const { userId, pendingApplicationId, firstName, permissions } =
    getUserDetails();
  const role = useSelector(userRoleData);
  const userRole = role?.userRole ?? null;
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [inputValue, setInputValue] = useState({
    sender: 'user',
    content: '',
  });
  const chatRequest = useSelector(chatbotData);
  const dispatch = useDispatch();

  const [isTyping, setIsTyping] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isTypewriterActive, setIsTypeWriterActive] = useState(0);
  const socketRef = useRef(null);
  const [isMessageStreaming, setIsMessageStreaming] = useState(false);
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [isConnectionOpen, setIsConnectionOpened] = useState(false);
  const [applicationId, setApplicationId] = useState('');
  const messageStreamRef = useRef(false);
  const chatId = useRef(0);
  const isConnectedRef = useRef(false);
  const skipDataRef = useRef(true);
  const startJsonParseRef = useRef(false);
  const lastFourMessagesRef = useRef([]);
  const lastThreeMessageRef = useRef([]);
  const lastFiveMessageRef = useRef([]);
  const lastTwoMessageRef = useRef([]);
  const chatPromptRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  const assistantIdRef = useRef('');
  const threadIdRef = useRef('');
  const websocketConnectCountRef = useRef(0);
  const [resizedWidth, setResizedWidth] = useState(50);
  const [isMaximized, setIsMaximized] = useState(false);
  const dataRef = useRef([]);
  const jsonObject = useRef('');
  const websocketMessage = '%S%Can you please try entering that again.%J%{}%E%';
  const connectionRef = useRef();

  const instructionMessages = [
    `I can run an eligibility check on your profile. Would you like
    to start now?`,
    `Looking to move forward? I can Request a pre-approval based on
    your profile information.`,
    `Ready to apply for a loan? Let me guide you through the loan
    application process.`,
    `Compare different loan options to find what best suits your
    needs. Want to give it a try?`,
    ,
  ];

  const [uploadData, { data, isLoading, error }] = useUploadChatDocsMutation();

  // const [messages, setMessages] = useState([
  //   {
  //     sender: "AI",
  //     content: `Hello! I am MIRA, I am your friendly mortgage assistant. I'm excited to guide you through the process of getting a mortgage. 🏠`,
  //   },
  //   { sender: "user", content: "I'm interested in getting a mortgage." },
  //   {
  //     sender: "AI",
  //     content:
  //       "That's fantastic! I'm here to help you every step of the way. Let's start by creating a profile for you. This will help me understand your needs and preferences better. May I ask you a few questions to get started?",
  //   },
  //   { sender: "user", content: "Yes, please go ahead." },
  //   {
  //     sender: "AI",
  //     content:
  //       "Great! Since you've already signed up, I have your name, phone number, and email address. Could you please provide your full current address, including the zip code?",
  //   },
  //   {
  //     sender: "user",
  //     content:
  //       "Yes, everything looks good. I'll enter the sensitive information and then click 'Next'.",
  //   },
  //   {
  //     sender: "AI",
  //     content:
  //       "Excellent! I'll be waiting on the next page to help you with the loan details. See you there!",
  //   },
  // ]);

  const [messages, setMessages] = useState([]);
  //const [isTextPasted, setIsTextPasted] = useState(false);
  const setInputValueFunction = (text) => {
    if (/\s/.test(text[0])) return false;
    setIsTyping(false);
    setIsTypeWriterActive((prev) => prev + 1);
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      content: text,
    }));
    setTimeout(() => {
      if (chatPromptRef.current) {
        chatPromptRef.current.style.height = 'auto';
        const height = chatPromptRef.current.scrollHeight;
        chatPromptRef.current.style.height = `${height < 100 ? height : 100}px`;
      }
    }, 0);
  };
  const handleInputChange = (event) => {
    //setIsTextPasted(false);
    setInputValueFunction(event.target.value);
  };
  /* later to be used to handle the on pase event in input 
  const handleOnPasteEvent = (event) => {
    event.preventDefault(); // Prevent the default paste action
    let pastedText = event.clipboardData.getData('text/plain');
    //pastedText.length > 25 && setIsTextPasted(true);
    setInputValueFunction(pastedText);
  }; */
  const [getUserData] = useIsFirstTimeUserMutation();
  const { data: profileData } = useGetUserInfoQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const handleOnline = () => {
      if (isConnectedRef.current !== true) {
        connectionRef?.current && connectionRef.current();
      }
      setIsOnline(true);
    };
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [isTypewriterActive, isTyping]);

  useEffect(() => {
    fetchUserData();
  }, [profileData]);

  const fetchUserData = async () => {
    try {
      const resp = await getUserData();
      if (resp?.data?.data?.pending_application_id) {
        setApplicationId(resp?.data?.data?.pending_application_id);
      } else if (profileData?.data?.user_profiles?.[0]?.applicationId) {
        setApplicationId(profileData.data.user_profiles[0].applicationId);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  useEffect(() => {
    try {
      /* const roleBasedEndpoint =
      role.userRole && role.userRole === 'consumer' ? 'ws2' : 'ws3'; */
      const roleBasedEndpoint =
        userRole === 'consumer' ? 'ws2_secure' : 'ws3_secure';
      const protocol = token;
      const connect = () => {
        socketRef.current = new WebSocket(
          `${chat_end_point}${roleBasedEndpoint}`,
          ['sp', protocol]
        );
        const socket = socketRef.current;
        socket.onopen = function (event) {
          console.log(event);
          console.log('Connection opened!');
          setIsMessageStreaming(false);
          setIsTyping(false);
          isConnectedRef.current = true;
          setIsConnectionOpened(true);
          reconnectAttemptsRef.current = 0;
          // websocketConnectCountRef.current = websocketConnectCountRef.current + 1;
          // if (
          //   websocketConnectCountRef.current > 1 &&
          //   assistantIdRef.current &&
          //   threadIdRef.current
          // ) {
          //   const message = {
          //     assistant_id: assistantIdRef.current,
          //     thread_id: threadIdRef.current,
          //   };
          //   socketRef?.current?.send(JSON.stringify(message));
          // }
        };

        socket.onmessage = function (event) {
          const response = JSON.parse(event.data);
          if (response?.response_type === 'AI_response') {
            setIsTyping(true);
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                sender: 'AI',
                content: response.data?.message,
              },
            ]);

            setIsMessageStreaming(false);
          } else if (response?.response_type === 'form_json') {
            const result = covertFormJSON(response?.data);
            dispatch(
              setPageInfo({
                name: pageNames[result?.page],
                data: result?.data,
              })
            );
          }
        };

        socket.onclose = (event) => {
          console.log(event);
          if (event.wasClean) {
            console.log('WebSocket connection closed cleanly.');
          } else {
            console.log('WebSocket connection closed unexpectedly.');
          }
          console.log('connection closed');
          console.log('Close code: ' + event.code);
          console.log('Close reason: ' + event.reason);
          isConnectedRef.current = false;
          if (reconnectAttemptsRef.current < 5) {
            setTimeout(connect, 1000 * (reconnectAttemptsRef.current + 1));
            reconnectAttemptsRef.current = reconnectAttemptsRef.current + 1;
          }
          setIsMessageStreaming(true);
          messageStreamRef.current = true;
        };
      };

      connectionRef.current = connect;

      connect();

      return () => {
        socketRef?.current?.close();
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  function covertFormJSON(input) {
    const result = {
      page: '',
      data: {},
    };

    for (const key in input) {
      const [page, field] = key.split('.');
      result.page = page;
      result.data[field] = input[key];
    }

    return result;
  }

  const pageNames = {
    UserProfiles: 'personalInfoData',
    ApplicationPurchaseHomes: 'loanInfoData',
    UserAssets: 'assetData',
    UserEmploymentAndIncomes: 'incomeData',
    UserREOs: 'REOData',
    ApplicationDeclarations: 'declarationData',
    UserDemographics: 'demographicsData',
    UserLiabilities: 'liabilitiesData',
  };

  const parseStringToObject = (str) => {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object') {
        const extractedObj = {};
        for (const key in obj) {
          const data = key.split('.');
          if (data?.length > 0) {
            extractedObj[data[data?.length - 1]] = obj[key];
          }
        }
        return extractedObj;
      }
    } catch (error) {
      // If parsing fails or the object does not match the expected format, return an empty object
    }
    return {};
  };

  const extractPageFromString = (str) => {
    const regex = /{"([^"]*)\.([^"]*)":.*}/;
    const match = str.match(regex);
    if (match && match.length > 2) {
      return match[1];
    }
    return null; // Return null if the pattern doesn't match
  };

  const addStringToChat = (respString) => {
    setMessages((prevMessages) => {
      if (
        prevMessages.length > 0 &&
        prevMessages[prevMessages.length - 1].id === chatId?.current
      ) {
        const lastMessage = prevMessages[prevMessages.length - 1];
        return [
          ...prevMessages.slice(0, -1),
          { ...lastMessage, content: lastMessage.content + respString },
        ];
      } else {
        return [
          ...prevMessages,
          { sender: 'AI', content: respString, id: chatId?.current },
        ];
      }
    });
  };

  const resetChatConfig = async () => {
    setIsMessageStreaming(true);
    messageStreamRef.current = true;
    if (!socketRef.current || socketRef.current.readyState !== WebSocket.OPEN) {
      await connectWebSocket();
    }
    lastFourMessagesRef.current = [];
    lastThreeMessageRef.current = [];
    lastFiveMessageRef.current = [];
    lastTwoMessageRef.current = [];
    jsonObject.current = '';
  };

  // const closeWebSocket = () => {
  //   if (socketRef.current) {
  //     socketRef.current.close();
  //     console.log('WebSocket connection closed manually.');
  //   } else {
  //     console.error('WebSocket connection is not established.');
  //   }
  // };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async (e, inputValue) => {
    e && e.preventDefault();
    if (inputValue.content === '') return;
    try {
      if (inputValue) {
        setIsTyping(true);
        setMessages([...messages, inputValue]);
        setIsTypeWriterActive((prev) => prev + 1);

        const input = inputValue.content;
        setInputValue({
          sender: 'user',
          content: '',
        });

        await resetChatConfig();
        let message = {
          user_input: input,
          userId: userId,
          user_name: firstName,
          application_id: applicationId,
        };
        if (assistantIdRef?.current) {
          message.assistant_id = assistantIdRef.current;
        }

        if (threadIdRef?.current) {
          message.thread_id = threadIdRef.current;
        }

        socketRef?.current?.send(JSON.stringify(message));

        // const res = await axios.post(
        //   `${chat_end_point}agents/orchestrate_agents`,
        //   {
        //     user_input: input,
        //     user_name: 'adom',
        //     userId: userId,
        //   }
        // );
        // if (res.data.agent_response || true) {
        //   setMessages((prevMessages) => [
        //     ...prevMessages,
        //     { sender: 'AI', content: res.data.agent_response },
        //   ]);
        // }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const documentParams = {
      applicationId: pendingApplicationId,
      documentTypeId: '',
      documentSubTypeId: '',
      documentTitle: 'chatbot upload',
    };
    if (selectedFile) {
      setIsDocumentUploading(true);
      try {
        const response = await uploadData({
          userRole,
          data: selectedFile,
          additionalParams: documentParams,
          userProfileId: profileData?.data?.user_profiles[0]?.userProfileId,
        });
        if (response?.data?.thread_id) {
          // setIsTyping(true);
          fileInputRef.current.value = null;
          setMessages([
            ...messages,
            {
              sender: 'user',
              content: chatQuery.documentUploadMessage,
            },
          ]);
          const input = response?.data?.thread_id;

          await resetChatConfig();

          setIsTyping(true);

          let message = {
            user_input: input,
            userId: userId,
            user_name: firstName,
            application_id: applicationId,
          };
          if (assistantIdRef?.current) {
            message.assistant_id = assistantIdRef.current;
          }

          if (threadIdRef?.current) {
            message.thread_id = threadIdRef.current;
          }

          socketRef?.current?.send(JSON.stringify(message));

          // const res = await axios.post(
          //   `${chat_end_point}agents/orchestrate_agents`,
          //   { user_input: input, user_name: 'adom', userId: userId }
          // );
          // if (res.data.agent_response || true) {
          //   setMessages((prevMessages) => [
          //     ...prevMessages,
          //     { sender: 'AI', content: res.data.agent_response },
          //   ]);
          // }
        } else {
          fileInputRef.current.value = null;
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      } finally {
        setIsDocumentUploading(false);
      }
    }
  };

  const connectWebSocket = () => {
    return new Promise((resolve, reject) => {
      if (
        !socketRef.current ||
        socketRef.current.readyState !== WebSocket.OPEN
      ) {
        // Create a new WebSocket connection if it's not already open
        socketRef.current = new WebSocket(`${chat_end_point}ws`);

        // WebSocket event handlers
        socketRef.current.onopen = () => {
          console.log('WebSocket connection opened!');
          isConnectedRef.current = true;
          resolve(); // Resolve the promise when the connection is established
        };

        socketRef.current.onclose = (event) => {
          console.log('WebSocket connection closed!');
          if (event.wasClean) {
            console.log('WebSocket connection closed cleanly.');
          } else {
            console.log('WebSocket connection closed unexpectedly.');
          }
          console.log('connection closed');
          console.log('Close code: ' + event.code);
          console.log('Close reason: ' + event.reason);
          isConnectedRef.current = false;
          setIsMessageStreaming(false);
          // Attempt to reconnect after a delay (e.g., 3 seconds)
          setTimeout(connectWebSocket, 3000);
        };

        socketRef.current.onerror = (error) => {
          console.error('WebSocket error:', error);
          reject(error); // Reject the promise if there's an error
        };
      } else {
        resolve(); // Resolve immediately if the connection is already open
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (chatRequest.chatMessage) {
        setIsTyping(true);
        setMessages([
          ...messages,
          {
            sender: 'user',
            content: chatRequest.chatMessage.message,
          },
        ]);
        const input = chatRequest.chatMessage.code;

        try {
          await resetChatConfig();
          if (
            socketRef.current &&
            socketRef.current.readyState === WebSocket.OPEN
          ) {
            let message = {
              user_input: input,
              user_name: firstName,
              userId: userId,
              userProfileId: chatRequest.chatMessage.userProfileId,
              application_id: chatRequest.chatMessage.applicationId,
            };

            if (assistantIdRef?.current) {
              message.assistant_id = assistantIdRef.current;
            }

            if (threadIdRef?.current) {
              message.thread_id = threadIdRef.current;
            }

            socketRef?.current?.send(JSON.stringify(message));
          }

          dispatch(setChatMessage(''));

          // const res = await axios.post(
          //   `${chat_end_point}agents/orchestrate_agents`,
          //   {
          //     user_input: input,
          //     user_name: 'adom',
          //     userId: userId,
          //     userProfileId: chatRequest.chatMessage.userProfileId,
          //     applicationId: chatRequest.chatMessage.applicationId,
          //   }
          // );

          // dispatch(setChatMessage(''));

          // if (res.data.agent_response) {
          //   setMessages((prevMessages) => [
          //     ...prevMessages,
          //     { sender: 'AI', content: res.data.agent_response },
          //   ]);
          // }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

    fetchData();
  }, [chatRequest]);
  //remove the json data flag if present
  const getParseText = (text) => {
    if (typeof text === 'string') {
      const newText = text?.replace(/%J%/g, '');
      return newText?.replace(/%J/g, '');
    }
    return text;
  };

  const handleKeyPress = (event) => {
    if (/\s/.test(event.target.value[0])) return false;
    if (isLoading || isMessageStreaming || !isConnectionOpen || isTyping)
      return false;

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      setIsTyping(false);
      if (chatPromptRef.current) {
        chatPromptRef.current.style.height = 'auto';
      }
      handleSendMessage(null, inputValue);
    }
  };

  return (
    (applicationId ||
      hasPermissions(permissions, 'loanOfficer') ||
      hasPermissions(permissions, 'organization')) && (
      <Resizable
        className={`ml-2 z-[49] ${
          isMaximized && 'shadow-xl shadow-secondary900'
        } rounded-lg border bg-white border-neutral100 bg-base-white shadow-lg flex flex-col justify-start pt-0 px-0 pb-1  text-center text-xl text-darkslategray font-gilroy-bold overflow-hidden`}
        size={{
          width: isMaximized ? `${resizedWidth}vw` : '26vw',
          height: '88vh',
        }}
        onResizeStop={(e, direction, ref, delta) => {
          const viewportWidth = window.innerWidth;
          const modifiedDeltaWidth = (delta.width / viewportWidth) * 100;

          setResizedWidth(
            resizedWidth + modifiedDeltaWidth < 26
              ? 26
              : resizedWidth + modifiedDeltaWidth
          );
        }}
        minWidth={!isMaximized && '26vw'}
        maxWidth={'90vw'}
        enable={{ left: isMaximized && true }}
      >
        <div className="flex flex-row justify-between pt-0 px-0  bg-custom-primary400">
          <div className="h-[100%] rounded-t-lg rounded-b-none flex flex-col justify-center mt-1 px-3 max-w-full z-[1]">
            <img
              className=""
              alt=""
              src={fundmoreai}
              style={{ width: '137px', height: '32px' }}
            />
            <div className="w-[54.1px] flex flex-col items-start justify-start pt-2 px-0 pb-0 "></div>
          </div>
          <div className="px-3 p-2 object-fill overflow-hidden cursor-pointer">
            {isMaximized ? (
              <img
                src={minimize}
                alt="resize"
                onClick={() => {
                  setIsMaximized(false);
                  setResizedWidth(55);
                }}
                className="h-[40px] w-[30px]"
              />
            ) : (
              <img
                src={maximize}
                alt="resize"
                onClick={() => setIsMaximized(true)}
                className="h-[40px] w-[30px]"
              />
            )}
          </div>
        </div>

        {messages && messages.length === 0 ? (
          <div className="w-full h-full  flex flex-col justify-between overflow-auto mt-0 p-4">
            <div className="flex flex-col gap-4 text-left">
              {instructionMessages.map((data, index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    handleSendMessage(null, {
                      content: data,
                      sender: 'user',
                    });
                  }}
                  className={`${isMaximized ? 'w-[85%]' : 'w-[85%]'} self-center rounded-md cursor-pointer box-border flex flex-row justify-start py-3 pr-[11.8px] pl-[11.9px] border border-primary text-base
                           ${
                             index % 2 === 0
                               ? 'bg-custom-primary300'
                               : ''
                           }`}
                >
                  <div className="relative rounded-lg hidden max-w-full border-[1px] border-solid border-gainsboro" />
                  <div className="relative leading-[24px] p-xs-regular max-w-full z-[1] !text-base">
                    {index % 2 !== 0 && (
                      <div className="flex items-center">
                        <div>
                          {index % 2 !== 0 && (
                            //   <div
                            //   style={{
                            //     width: '20px',
                            //     height: '20px',
                            //     borderRadius: '10px',
                            //     backgroundColor: '#5FD0B9',
                            //   }}
                            // ></div>

                            <img
                              src={fundmoreailogo}
                              alt="Icon"
                              className="mr-2 w-6 h-6"
                              style={{ width: '35px', height: '18px' }}
                            />
                          )}
                        </div>
                        <div style={{ marginLeft: '18px' }}>{data}</div>
                      </div>
                    )}

                    {index % 2 === 0 && (
                      <div className="flex items-center">
                        <div style={{ marginLeft: '18px' }}>{data}</div>
                        <div>
                          {index % 2 === 0 && (
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '10px',
                                backgroundColor: '#01B23C',
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="w-full h-full overflow-auto" ref={chatContainerRef}>
            {messages.map((message, index) => (
              <div key={index}>
                {message.sender === 'user' ? (
                  <div className="w-[97%]">
                    <div className="w-[85%] ml-auto mt-5 ">
                      <div className="px-3 py-3 flex justify-between items-start rounded-tl-lg rounded-tr-lg rounded-bl-lg bg-custom-primary200 text-tertiary">
                        <h1
                          className="text-black font-medium text-left !text-base word"
                          style={{ wordBreak: 'break-word' }}
                        >
                          {message.content}
                        </h1>
                        <div className="primary200-bg w-[24px] h-[24px] rounded-full flex-shrink-0"></div>
                      </div>
                    </div>
                    {isTyping && index === messages.length - 1 && (
                      <div className="flex items-start ml-3 mt-5">
                        <div className="enabled-bg flex w-3/4 gap-3 rounded-br-lg rounded-tr-lg rounded-bl-lg px-3 py-3 ml-2">
                          <img
                            src={fundmoreaiIcon}
                            alt="logo"
                            className="h-6 w-6 rounded-full"
                          />
                          <div className="flex w-full flex-col gap-4">
                            <div className="h-[10px] w-full rounded-full loader-gradient animate-pulse"></div>
                            <div className="h-[10px] w-3/4 rounded-full loader-gradient animate-pulse"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex items-start ml-3 mt-5">
                    <div className="w-[85%]">
                      <div className="enabled-bg w-full flex justify-start items-start rounded-br-lg rounded-tr-lg rounded-bl-lg px-3 py-3 ml-2">
                        <img
                          src={fundmoreaiIcon}
                          alt="logo"
                          className="rounded-full mr-4 w-[25px]"
                        />
                        <div
                          className={`prose font-medium text-left !text-base max-w-[90%] overflow-hidden`}
                        >
                          <Typewriter
                            text={message?.content}
                            delay={5}
                            setIsTypeWriterActive={setIsTypeWriterActive}
                            setTyping={() => {
                              setIsTyping(false);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {isDocumentUploading && (
          <div className="flex gap-2 justify-center items-center">
            <img
              src={loader}
              alt=""
              className="animate-spin"
              width="30px"
              height="30px"
            />
          </div>
        )}

        <form className="w-[100%] flex flex-row justify-between gap-2 box-border px-4 mt-2">
          <div
            className={`px-3 flex flex-row gap-2 justify-between overflow-hidden w-[100%] py-2 border  border-primary  rounded self-center`}
          >
            <textarea
              type="text"
              disabled={isTyping}
              value={inputValue.content}
              onChange={handleInputChange}
              ref={chatPromptRef}
              placeholder="Write a message"
              className="w-full !text-base resize-none ml-2 font-medium text-black outline-none overflow-hidden overflow-y-scroll hiddenScroll"
              onKeyDown={handleKeyPress}
              //onPaste={handleOnPasteEvent}
              rows={1}
            />
            <div className="flex flex-col justify-center">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <img
                src={selectDocumentIcon}
                alt="Select Document"
                className="cursor-pointer  max-h-[20px] max-w-[20px] min-h-[20px] min-w-[20px]"
                onClick={!isTyping && handleFileUpload}
              />
            </div>
          </div>

          <div className="h-full flex flex-col justify-center">
            <button
              onClick={(e) => {
                if (chatPromptRef.current) {
                  chatPromptRef.current.style.height = 'auto';
                }
                handleSendMessage(e, inputValue);
              }}
              disabled={isLoading || isMessageStreaming || !isConnectionOpen}
              className={`w-[2.6rem]  h-[40px] border-none ${
                isLoading || isMessageStreaming || !isConnectionOpen || isTyping
                  ? 'primary-bg'
                  : 'primary-bg'
              } rounded bg-white  flex  items-center justify-center   `}
            >
              <img src={sendMessage} alt="" />
            </button>
          </div>
        </form>
        <div className="flex justify-center p-xs-regular text-secondary600 mt-1">
          Your smart AI assistant
        </div>
      </Resizable>
    )
  );
};

export default ChatModel;
