import React, { useState, useRef, useEffect } from 'react';

//components
import HorizontalTimeline from 'components/horizontal-timeline';
import User from 'components/user';
import NotificationTab from 'pages/notification/index.jsx';

//libraries
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { motion, AnimatePresence } from 'framer-motion';

//slices
import {
  maxStageSelector,
  selectTimelineData,
  setApplicationTimeline,
  setProfileState,
} from 'redux_store/slices/application';

//query
import { useGetNotificationsQuery } from 'redux_store/services/notifications';
import { useGetLoanOfficerDetailsQuery } from 'redux_store/services/client/onboarding';

//constants
import { getUserDetails } from 'auth/auth';
import { hasPermissions } from 'constants/permissionChecker';

//icons
import notificationIcon from 'assets/icons/notification.svg';
import notificationUnreadIcon from 'assets/icons/notificationUnread.svg';
import LogoutIcon from 'assets/icons/Logout.jsx';

//import logoutIcon from 'assets/icons/logout.svg';
/**
 * Renders the top bar component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.logout - The logout function.
 * @param {Object} props.user - The user object.
 * @returns {JSX.Element} The rendered top bar component.
 */
const TopBar = ({ logout, user }) => {
  const location = useLocation();
  const data = useSelector(selectTimelineData);
  const maxStage = useSelector(maxStageSelector);
  const dispatch = useDispatch();
  const { firstName, lastName, permissions } = getUserDetails();
  const username = firstName + ' ' + lastName;
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const isConsumer = hasPermissions(permissions, 'consumer');
  const [notification, setNotification] = useState(false);
  const [showUnread, setShowUnread] = useState(false);

  //RTK to get loan officers details
  const { data: loanOfficerDetails, error: loanOfficersErrors } =
    useGetLoanOfficerDetailsQuery({
      skip: !isConsumer,
    });

  // notifications
  const {
    currentData: notificationData,
    isFetching,
    refetch,
  } = useGetNotificationsQuery(
    { showUnread: showUnread },
    { refetchOnMountOrArgChange: true }
  );
  // refetch notification on path change
  useEffect(() => {
    refetch();
  }, [location?.pathname]);

  //use ref to close popup on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest('.ignoreref')
      ) {
        setIsLogoutOpen(false);
        setNotification(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //handle logout
  const handleLogout = () => {
    // Clear cookies
    Cookies.remove('idToken');
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [name] = cookie.split('=');
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
    // Clear local storage
    localStorage.clear();
    // Clear session storage
    sessionStorage.clear();
    // Redirect to the base URL
    window.location.href = window.location.origin;
  };

  const dispatchTimelineStatus = (page) => {
    const pageNames = [
      'Personal Details',
      'Loan Info',
      'Income',
      'Assets',
      'Liabilities',
      'REO',
      // 'Declaration',
      // 'Demographics',
    ];
    const timeStatus = Array.from({ length: 6 }, () => ({}));
    const updatedTimeline = timeStatus.map((x, i) => {
      const name = pageNames[i] || '';
      return {
        ...x,
        state:
          page === i + 1
            ? 'progress'
            : i + 1 < page
              ? 'done'
              : i + 1 < maxStage
                ? 'done'
                : 'notDone',
        name: name,
      };
    });
    dispatch(setProfileState(page));
    dispatch(setApplicationTimeline(updatedTimeline));
  };

  const hasUnreadNotifications = notificationData?.data?.data.some(
    (item) => item.isRead === '0'
  );

  return (
    <div className=" border-b-2 border-primary h-[68px] flex flex-wrap items-start justify-between">
      {location?.pathname === '/profile' && (
        <div className="pl-4 w-[70%] flex justify-center mt-3 ">
          <HorizontalTimeline
            data={data}
            onClickTimeDot={(page) => {
              if (page <= maxStage) {
                dispatchTimelineStatus(page);
              }
            }}
            size="m"
          />
        </div>
      )}
      {location?.pathname !== '/profile' && <div></div>}
      <div className=" border-b-2 border-primary h-[68px] flex justify-end gap-6 items-center w-[30%]">
        <div className="relative">
          <img
            src={
              hasUnreadNotifications ? notificationUnreadIcon : notificationIcon
            }
            alt=""
            className="cursor-pointer ignoreref"
            onClick={() => {
              setNotification((prev) => !prev);
              setIsLogoutOpen(false);
            }}
          />
          <AnimatePresence>
            {notification && (
              <motion.div
                ref={dropdownRef}
                className="ignoreref"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{
                  position: 'absolute',
                  top: '10%',
                  right: 0,
                  zIndex: 100,
                }}
              >
                <NotificationTab
                  notifications={notificationData?.data?.data}
                  isFetching={isFetching}
                  showUnread={showUnread}
                  setShowUnread={setShowUnread}
                  setNotification={setNotification}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="relative flex flex-col mr-4 ignoreref">
          <div
            onClick={() => {
              setIsLogoutOpen(!isLogoutOpen);
              setNotification(false);
            }}
            className={`${isLogoutOpen ? 'bg-chatbot_bg' : ''} cursor-pointer hover p-2 rounded`}
          >
            <User
              title={username?.charAt(0).toUpperCase() + username?.slice(1)}
              subtitle={user?.email}
              size="md"
            />
          </div>
        </div>
      </div>
      {isLogoutOpen && (
        <div
          ref={dropdownRef}
          className="h-fit w-[270px] z-[100] flex flex-col justify-between gap-6 absolute top-[4rem] right-3 border rounded-md shadow-xl border-neutral100 p-3 bg-white"
        >
          {isConsumer && loanOfficerDetails?.success && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-col p-2 gap-1 bg-chatbot_bg rounded-md">
                <p className="font-medium text-disabled">Mortgage Broker</p>
                <p className="font-medium-2 text-secondary700">{`${loanOfficerDetails?.data?.first_name} ${loanOfficerDetails?.data?.last_name}`}</p>
              </div>
              <div className="flex flex-col p-2 gap-1 rounded-md">
                <p className="font-medium text-disabled">NMLS ID</p>
                <p className="font-medium-2 text-secondary700">
                  {loanOfficerDetails?.data.nmls_id ?? 'Not available'}
                </p>
              </div>
              <hr className="border-disabled" />
              <div className="flex flex-col p-2 gap-1 rounded-md">
                <p className="font-medium text-disabled">Organization</p>
                <p className="font-medium-2 text-secondary700">
                  {loanOfficerDetails?.data.organization ?? 'Not available'}
                </p>
              </div>
              <hr className="border-disabled" />
              <div className="flex flex-col p-2 gap-1 rounded-md">
                <p className="font-medium text-disabled">Email</p>
                <p className="font-medium-2 text-secondary700">
                  {loanOfficerDetails?.data.email ?? 'Not available'}
                </p>
              </div>
              <hr className="border-disabled" />
              <div className="flex flex-col p-2 gap-1 rounded-md">
                <p className="font-medium text-disabled">Mobile Number</p>
                <p className="font-medium-2 text-secondary700">
                  {loanOfficerDetails?.data.mobile ?? 'Not available'}
                </p>
              </div>
              <hr className="border-disabled" />
            </div>
          )}
          <div
            className="h-fit flex flex-col justify-center gap-3 rounded-md border border-disabled hover:bg-error_light hover:border-error-red cursor-pointer text-secondary700 hover:text-error-red"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleLogout}
          >
            <div className="flex flex-row justify-center">
              <div className="h-[40px] w-[40px] p-2">
                <LogoutIcon color={isHovered ? 'red' : 'grey'} />
              </div>
              <p className="font-medium-2 p-3 ">Logout</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar;
/* (
        <button
          onClick={handleLogout}
          ref={dropdownRef}
          className=" absolute bottom-[-2.5rem] w-[100%] flex  gap-3 flex-grow font-medium-2 border rounded shadow-xl z-10 border-neutral100 px-5 py-2 bg-white"
        >
          <img src={logoutIcon} alt="" />
          Logout
        </button>
      ) */
