// React and Components
import React, { useState } from 'react';
import Button from 'components/button';
import Select from 'components/select';
import Radio from 'components/radio';
import H1 from 'components/h1-typography';
// Slices
import {
  useCreateDemographicsDetailsMutation,
  useGetApplicationQuery,
  useGetEthnicityQuery,
  useGetUserRaceQuery,
  useUpdateDemographicsDetailsMutation,
} from 'redux_store/services/client/profile';
// Sentry for logging
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'components/checkbox';
import Modal from 'components/modal';
import TermsAndConditions from 'wrapper/terms-and-conditions';
import { useSelector } from 'react-redux';
import { demographicsDataSelector } from 'redux_store/slices/application';

const Demographics = ({ setPage, setPreviousPage, token }) => {
  // States and Refs
  const [formData, setFormData] = React.useState({
    ethnicityId: null,
    subEthnicity: '',
    otherEthnicity: '',
    raceId: null,
    subRace: '',
    otherRace: '',
    sex: '',
    infoMedium: '',
    isTermsAccepted: '',
  });
  const [btnTxt, setBtnTxt] = React.useState('Complete');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const navigate = useNavigate();
  const isEdit = React.useRef(false);

  //selector
  const demographicDataAI = useSelector(demographicsDataSelector);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!formData?.isTermsAccepted) {
      setFormSubmitted(true);
      return;
    }
    setBtnTxt('Please Wait...');
    setFormSubmitted(false);
    try {
      const data = {
        ...formData,
        ethnicityId: formData?.ethnicityId || null,
        raceId: formData?.raceId || null,
        userProfileId: applicationData?.data?.personal_details?.userProfileId,
        applicationId: token,
      };
      if (isEdit?.current) {
        const resp = await updateDemographics({
          applicationId: token,
          data: data,
        });
        await refetch();
        if (resp?.data?.status === 200) {
          navigate('/pipeline');
        }
      } else {
        const resp = await createDemographics(data);
        await refetch();
        if (resp?.data?.status === 200) {
          navigate('/pipeline');
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    } finally {
      setBtnTxt('Form Submitted.');
    }
  };

  // Sclices and RTK Query
  const { data: ethnicityData } = useGetEthnicityQuery();
  const { data: raceData } = useGetUserRaceQuery();
  const [createDemographics] = useCreateDemographicsDetailsMutation();
  const [updateDemographics] = useUpdateDemographicsDetailsMutation();
  const { data: applicationData, refetch } = useGetApplicationQuery(
    {
      application_id: token,
    },
    {
      skip: !token,
    }
  );

  React.useEffect(() => {
    if (applicationData?.data?.user_demographics_details) {
      isEdit.current = true;
      const formData = applicationData?.data?.user_demographics_details;
      setFormData({
        ...formData,
        isTermsAccepted: formData?.isTermsAccepted === '1',
      });
    } else {
      setFormData({
        ...formData,
        ...demographicDataAI,
      });
    }
  }, [applicationData, demographicDataAI]);

  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  const formatData = (data, property) => {
    return (
      data?.map((x) => {
        return {
          ...x,
          id: x[property],
        };
      }) ?? []
    );
  };

  return (
    <div>
      <form onSubmit={onSubmitHandler}>
        <H1>Demographics</H1>
        <p className="antialiased">
          Provide demographic information as required for the application.
        </p>
        <div className=" max-h-custom-declaration overflow-auto">
          <div className="bg-black text-tertiary w-full px-2 py-1 rounded-t-lg">
            <p className="mx-auto">Demographic Information of Borrower</p>
          </div>
          <p className="p-2 p-xs-medium">
            The purpose of collecting this information is to help ensure that
            all applicants are treated fairly and that the housing needs of
            communities and neighborhoods are being fulfilled. For residential
            mortgage lending, Federal law requires that we ask applicants for
            their demographic information (ethnicity, sex, and race) in order to
            monitor our compliance with equal credit opportunity, fair housing,
            and home mortgage disclosure laws. You are not required to provide
            this information, but are encouraged to do so. You may select one or
            more designations for "Ethnicity" and one or more designations for
            "Race." The law provides that we may not discriminate on the basis
            of this information, or on whether you choose to provide it.
            However, if you choose not to provide the information and you have
            made this application in person, Federal regulations require us to
            note your ethnicity, sex, and race on the basis of visual
            observation or surname. The law also provides that we may not
            discriminate on the basis of age or marital status information you
            provide in this application.  <br /> <b>Instructions</b>: You may
            select one or more "Hispanic or Latino" origins and one or more
            designations for "Race." If you do not wish to provide some or all
            of this information, select the applicable option. 
          </p>
          <hr className="text-grey" />
          <div className="flex items-center flex-wrap py-4 justify-evenly">
            <div className="">
              <p className="font-medium-bold">Ethnicity</p>
            </div>
            <div className="w-1/2">
              <div className="w-1/2">
                <Select
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      ethnicityId: parseInt(e.target.value),
                      subEthnicity: '',
                      otherEthnicity: '',
                    });
                  }}
                  value={formData?.ethnicityId}
                >
                  <option value="">Select</option>
                  {formatData(ethnicityData?.data, 'ethnicityId')?.map(
                    (ethnicity) => (
                      <option value={ethnicity?.id}>{ethnicity?.name}</option>
                    )
                  )}
                </Select>
              </div>
              {formData?.ethnicityId === 1 && (
                <div className="w-full flex flex-wrap gap-4 mt-3">
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subEthnicity: e.target.value,
                        });
                      }}
                      value={'Mexican'}
                      id={'Mexican'}
                      name={`subEthnicity`}
                      checked={formData?.subEthnicity === 'Mexican'}
                    />
                    <label htmlFor="Mexican" className="pl-1 font-medium">
                      Mexican
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      value={'Puerto Rican'}
                      id={'Puerto-Rican'}
                      name={`subEthnicity`}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subEthnicity: e.target.value,
                        });
                      }}
                      checked={formData?.subEthnicity === 'Puerto Rican'}
                    />
                    <label htmlFor="Puerto-Rican" className="pl-1 font-medium">
                      Puerto Rican
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      value="Cuban"
                      id={'Cuban'}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subEthnicity: e.target.value,
                        });
                      }}
                      name={`subEthnicity`}
                      checked={formData?.subEthnicity === 'Cuban'}
                    />
                    <label htmlFor="Cuban" className="pl-1 font-medium">
                      Cuban
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      value="Other"
                      id={'Other-ethnicity'}
                      name={`subEthnicity`}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subEthnicity: e.target.value,
                        });
                      }}
                      checked={formData?.subEthnicity === 'Other'}
                    />
                    <label
                      htmlFor="Other-ethnicity"
                      className="pl-1 font-medium"
                    >
                      Other
                    </label>
                  </div>
                </div>
              )}
              {formData?.subEthnicity === 'Other' && (
                <div className="w-full flex flex-wrap items-center gap-3 mt-3">
                  <div className="">
                    <p className="">Enter Origin:</p>
                  </div>
                  <div className="w-1/2">
                    <input
                      onChange={(e) => {
                        const filteredValue = e.target.value.replace(
                          /[^a-zA-Z ]/g,
                          ''
                        );
                        setFormData({
                          ...formData,
                          otherEthnicity: filteredValue,
                        });
                      }}
                      value={formData?.otherEthnicity}
                      className="outline outline-none w-1/2  border-dotted border-b-2"
                    />
                  </div>
                </div>
              )}
              {formData?.subEthnicity === 'Other' && (
                <div className="mt-3">
                  <p className="text-disabled">
                    Examples: Argentinean, Colombian, Dominican, Nicaraguan,
                    Salvadoran, Spaniard, etc. 
                  </p>
                </div>
              )}
            </div>
          </div>
          <hr className="text-grey" />
          <div className="flex items-center flex-wrap py-4 justify-evenly">
            <div className="">
              <p className="font-medium-bold">Race</p>
            </div>
            <div className="w-1/2">
              <div className="w-1/2 ml-2">
                <Select
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      raceId: parseInt(e.target.value),
                      subRace: '',
                      otherRace: '',
                    });
                  }}
                  value={formData?.raceId}
                >
                  <option value="">Select</option>
                  {formatData(raceData?.data, 'raceId')?.map((race) => (
                    <option value={race?.id}>{race?.name}</option>
                  ))}
                </Select>
              </div>
              {formData?.raceId === 4 && (
                <div className="w-full flex items-center gap-3 ml-2 mt-3">
                  <div className="">
                    <p className="">
                      Enter name of enrolled or principal tribe:
                    </p>
                  </div>
                  <div className="w-1/2">
                    <input
                      onChange={(e) => {
                        const filteredValue = e.target.value.replace(
                          /[^a-zA-Z ]/g,
                          ''
                        );
                        setFormData({
                          ...formData,
                          otherRace: filteredValue,
                        });
                      }}
                      checked={formData?.otherRace}
                      className="outline outline-none w-1/2  border-dotted border-b-2"
                    />
                  </div>
                </div>
              )}
              {formData?.raceId === 1 && (
                <div className="w-full flex flex-wrap gap-4 mt-3 ml-2">
                  <div className="flex items-center">
                    <Radio
                      id={'Asian-Indian'}
                      value="Asian Indian"
                      name={`subRace`}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      checked={formData?.subRace === 'Asian Indian'}
                    />
                    <label htmlFor="Asian-Indian" className="pl-1 font-medium">
                      Asian Indian
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      checked={formData?.subRace === 'Chinese'}
                      id={'Chinese'}
                      value="Chinese"
                      name={`subRace`}
                    />
                    <label htmlFor="Chinese" className="pl-1 font-medium">
                      Chinese
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      checked={formData?.subRace === 'Filipino'}
                      value="Filipino"
                      id={'Filipino'}
                      name={`subRace`}
                    />
                    <label htmlFor="Filipino" className="pl-1 font-medium">
                      Filipino
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      checked={formData?.subRace === 'Japanese'}
                      value="Japanese"
                      id={'Japanese'}
                      name={`subRace`}
                    />
                    <label htmlFor="Japanese" className="pl-1 font-medium">
                      Japanese
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      checked={formData?.subRace === 'Korean'}
                      value="Korean"
                      id={'Korean'}
                      name={`subRace`}
                    />
                    <label htmlFor="Korean" className="pl-1 font-medium">
                      Korean
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      id={'Vietnamese'}
                      value="Vietnamese"
                      name={`subRace`}
                      checked={formData?.subRace === 'Vietnamese'}
                    />
                    <label htmlFor="Vietnamese" className="pl-1 font-medium">
                      Vietnamese
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      value="Other"
                      id={'Other'}
                      name={`subRace`}
                      checked={formData?.subRace === 'Other'}
                    />
                    <label htmlFor="Other" className="pl-1 font-medium">
                      Other
                    </label>
                  </div>
                </div>
              )}
              {formData?.raceId === 3 && (
                <div className="w-full flex flex-wrap gap-4 mt-3 ml-2">
                  <div className="flex items-center">
                    <Radio
                      id={'Asian-Indian'}
                      value="Native Hawaiian"
                      name={`subRace-3`}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      checked={formData?.subRace === 'Native Hawaiian'}
                    />
                    <label
                      htmlFor="Native Hawaiian"
                      className="pl-1 font-medium"
                    >
                      Native Hawaiian
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      checked={formData?.subRace === 'Samoan'}
                      id={'Samoan'}
                      value="Samoan"
                      name={`subRace-3`}
                    />
                    <label htmlFor="Samoan" className="pl-1 font-medium">
                      Samoan
                    </label>
                  </div>
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      checked={formData?.subRace === 'Guamanian or Chamorro'}
                      value="Guamanian or Chamorro"
                      id={'Guamanian-or-Chamorro'}
                      name={`subRace-3`}
                    />
                    <label
                      htmlFor="Guamanian-or-Chamorro"
                      className="pl-1 font-medium"
                    >
                      Guamanian or Chamorro
                    </label>
                  </div>

                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subRace: e.target.value,
                        });
                      }}
                      value="Other"
                      id={'Other'}
                      name={`subRace-3`}
                      checked={formData?.subRace === 'Other'}
                    />
                    <label htmlFor="Other" className="pl-1 font-medium">
                      Other
                    </label>
                  </div>
                </div>
              )}

              {formData?.subRace === 'Other' && (
                <div className="w-full flex flex-wrap items-center gap-3 mt-3">
                  <div className="">
                    <p className="">Enter Origin:</p>
                  </div>
                  <div className="w-1/2">
                    <input
                      onChange={(e) => {
                        const filteredValue = e.target.value.replace(
                          /[^a-zA-Z ]/g,
                          ''
                        );
                        setFormData({
                          ...formData,
                          otherRace: filteredValue,
                        });
                      }}
                      value={formData?.otherRace}
                      className="outline outline-none w-1/2  border-dotted border-b-2"
                    />
                  </div>
                </div>
              )}
              {formData?.subRace === 'Other' && formData?.subRace === 1 && (
                <div className="mt-3 ml-2">
                  <p className="text-disabled">
                    Examples: Hmong, Laot ian, Thai, Pakistani, Cambodian, etc.
                  </p>
                </div>
              )}
              {formData?.subRace === 'Other' && formData?.subRace === 3 && (
                <div className="mt-3 ml-2">
                  <p className="text-disabled">
                    Examples: Fijian, Tongan, etc.
                  </p>
                </div>
              )}
            </div>
          </div>
          <hr className="text-grey" />
          <div className="flex items-center flex-wrap py-4 justify-evenly">
            <div className="">
              <p className="font-medium-bold">Sex</p>
            </div>
            <div className="w-1/2">
              <div className="w-full flex gap-4 ml-3">
                <div className="flex items-center">
                  <Radio
                    id={'Female'}
                    value="Female"
                    name={`radio-gender-data`}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        sex: e.target.value,
                      })
                    }
                    checked={formData?.sex === 'Female'}
                  />
                  <label htmlFor="Female" className="pl-1 font-medium">
                    Female
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'Male'}
                    value={'Male'}
                    name={`radio-gender-data`}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        sex: e.target.value,
                      })
                    }
                    checked={formData?.sex === 'Male'}
                  />
                  <label htmlFor="Male" className="pl-1 font-medium">
                    Male
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id={'iDoNotWish'}
                    value="I do not wish to provide this information"
                    name={`radio-gender-data`}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        sex: e.target.value,
                      })
                    }
                    checked={
                      formData?.sex ===
                      'I do not wish to provide this information'
                    }
                  />
                  <label htmlFor="iDoNotWish" className="pl-1 font-medium">
                    I do not wish to provide this information
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr className="text-grey" />
          <div className="flex items-center flex-wrap py-3 justify-evenly">
            <div>
              <p className="font-medium-bold">
                This Demographic
                <br /> Information was provided through
              </p>
            </div>
            <div className="w-2/3">
              <div
                className="flex items-center mt-2"
                style={{ marginLeft: '3.8rem' }}
              >
                <Radio
                  id={'Internet'}
                  value="Email or Internet"
                  name={`radio-demographics`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      infoMedium: e.target.value,
                    });
                  }}
                  checked={formData?.infoMedium === 'Email or Internet'}
                />
                <label htmlFor="Internet" className="pl-1 font-medium">
                  Email or Internet
                </label>
              </div>
              <div
                className="flex items-center mt-2"
                style={{ marginLeft: '3.8rem' }}
              >
                <Radio
                  id={'faceToFace'}
                  value="Face to Face Interview(includes Electronic Media w/Video Component)"
                  name={`radio-demographics`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      infoMedium: e.target.value,
                    });
                  }}
                  checked={
                    formData?.infoMedium ===
                    'Face to Face Interview(includes Electronic Media w/Video Component)'
                  }
                />
                <label htmlFor="faceToFace" className="pl-1 font-medium">
                  Face-to-Face Interview (includes Electronic Media w/Video
                  Component )
                </label>
              </div>
              <div
                className="flex items-center mt-2"
                style={{ marginLeft: '3.8rem' }}
              >
                <Radio
                  id={'TelephonicInterview'}
                  value="Telephonic Interview"
                  name={`radio-demographics`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      infoMedium: e.target.value,
                    });
                  }}
                  checked={formData?.infoMedium === 'Telephonic Interview'}
                />
                <label
                  htmlFor="TelephonicInterview"
                  className="pl-1 font-medium"
                >
                  Telephonic Interview
                </label>
              </div>
              <div
                className="flex items-center mt-2"
                style={{ marginLeft: '3.8rem' }}
              >
                <Radio
                  id={'faxOrMail'}
                  value="Fax or Mail"
                  name={`radio-demographics`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      infoMedium: e.target.value,
                    });
                  }}
                  checked={formData?.infoMedium === 'Fax or Mail'}
                />
                <label htmlFor="faxOrMail" className="pl-1 font-medium">
                  Fax or Mail
                </label>
              </div>
            </div>
          </div>
          <div className={`px-2 flex items-center`}>
            <Checkbox
              id={`form-field=Terms-and-conditions`}
              checked={formData?.isTermsAccepted === true}
              value={'isTermsAccepted'}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  isTermsAccepted: e.target.checked,
                });
              }}
              isError={formSubmitted && !formData?.isTermsAccepted}
            />

            <label
              className={`${formSubmitted && !formData?.isTermsAccepted ? 'text-error' : 'text-neutral700'} pl-2 font-medium `}
              for={`form-field=Terms-and-conditions`}
            >
              I authorize to credit check and agree to the{' '}
              <Button
                variant="link"
                style={{ textDecoration: 'underline', padding: 0 }}
                type="button"
                onClick={() => {
                  setShowTermsAndConditions(true);
                }}
              >
                Terms & Conditions.
              </Button>
            </label>
          </div>
        </div>

        <div className="text-right mt-2">
          <div className="text-right mt-2">
            <Button
              variant="tertiary"
              onClick={() => {
                setPreviousPage();
              }}
              style={{ marginRight: '10px' }}
              type="button"
            >
              Back: Declaration{' '}
            </Button>
            <Button type="submit" variant={'primary'}>
              {btnTxt}
            </Button>
          </div>
        </div>
      </form>
      <Modal show={showTermsAndConditions} width="max-w-[50vw]">
        <div className="p-5">
          <TermsAndConditions />
          <div className="text-right">
            <Button
              type="button"
              variant={'primary'}
              onClick={() => setShowTermsAndConditions(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Demographics;
