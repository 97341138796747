import react, { useEffect, useState } from 'react';
//componentes
import Modal from 'components/modal';
import H1 from 'components/h1-typography';
import closeIcon from 'assets/icons/closeIconSlim.svg';
import InputGroup from 'components/input-group';
import Button from 'components/button';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
import * as Sentry from '@sentry/browser';

//rtk query
import { useDeleteConsumerMutation } from 'redux_store/services/admin/adminConsumer';
const ConfrimDelete = ({ selectedRowData, setConfirmDeleteVisibility }) => {
  const [input, setInput] = useState('');
  const [isError, setIsError] = useState(false);
  const [handleDeleteConsumerAPI, { isLoading: deleteConsumerLoading }] =
    useDeleteConsumerMutation();

  const handeDelete = async () => {
    if (input !== 'Delete') {
      setIsError(true);
    } else {
      setIsError(false);
      try {
        let res = await handleDeleteConsumerAPI({
          userId: selectedRowData?.user_info?.userId,
        });
        if (res?.data?.success) {
          setInput('');
          setConfirmDeleteVisibility(false);
          customToast.success(
            `Profile for ${selectedRowData?.user_info?.firstName + ' ' + selectedRowData?.user_info?.lastName} has been deleted successfully`
          );
        }
        if (res.error.status === 400) {
          customToast.error('User deletion failed due to application status');
        }
        if (res.error.status === 404) {
          customToast.error('Application Not Found');
        }
      } catch (error) {
        Sentry.captureException(error);

        /* console.log("error catch :",error) */
      }
    }
  };
  const view = (
    <>
      <div className="h-[100%] w-[100%] flex flex-col py-4 px-8">
        <div className="h-fit w-[100%] flex flex-row justify-between gap-3 mb-1">
          <div className="h-[100%] flex flex-col justify-end">
            <H1>Delete Profile</H1>
          </div>
          <div className="h-[100%] flex flex-col justify-center py-2">
            <img
              src={closeIcon}
              alt=""
              className="self-center cursor-pointer h-[18px] w-[18px]"
              onClick={() => setConfirmDeleteVisibility(false)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-[12px] text-secondary600 font-normal">
            You are about to delete{' '}
            <b className=" text-[#5A5555]">
              {selectedRowData?.user_info?.firstName +
                ' ' +
                selectedRowData?.user_info?.lastName +
                "'s."}
            </b>{' '}
            profile. This profile will no longer be available. Are you sure you
            want to delete?
          </p>
          <div className="flex flex-col gap-2">
            <p
              htmlFor="input"
              className="text-[12px]  text-[#5A5555] font-bold"
            >
              Delete Profile <b className="text-error-red">*</b>
            </p>
            <InputGroup
              size="sm_2"
              position="center"
              placeholder="Enter Delete to remove the profile"
              value={input}
              isError={isError}
              onChange={(e) => {
                const filteredValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                setInput(filteredValue);
              }}
            />
            {isError && (
              <span className="text-[12px] font-light -my-2 text-error-red">
                Incorrect input. Please check and try again.
              </span>
            )}
          </div>
        </div>
        <div className="h-fit w-[100%] flex flex-row gap-5 justify-end mt-8">
          <Button
            variant="tertiary"
            btnClassName="text-disabled font-normal"
            onClick={() => {
              setConfirmDeleteVisibility(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant={input.length > 0 ? 'danger' : 'disabled'}
            btnClassName="border-primary500 bg-chatbot_bg text-primary500 px-6"
            onClick={() => {
              input.length > 0 && handeDelete();
            }}
          >
            {deleteConsumerLoading ? (
              <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                <Loader loaderText="Deleting" FullScreen={false} />
              </div>
            ) : (
              'Delete'
            )}
          </Button>
        </div>
      </div>
    </>
  );
  return (
    <>
      <Modal
        children={view}
        show={true}
        width="min-w-[300px] w-[29vw] max-w-[400px]"
      />
    </>
  );
};

export default ConfrimDelete;
