import React from 'react';
import Button from 'components/button';
import InputGroup from 'components/input-group';
import locationIcon from 'assets/icons/location.svg';
import closeIcon from 'assets/icons/closeIcon.svg';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { scaleMotionProps2 } from 'constants/animationProps';
/**
 * Renders the Page2 component.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.user - The user name.
 * @param {function} props.setAddress - The function to set the address.
 * @param {string} props.address - The current address.
 * @param {function} props.setPage - The function to set the current page.
 * @param {boolean} props.isLoading - Indicates if the page is loading.
 * @param {function} props.setDirection - The function to set the direction.
 * @param {boolean} props.direction - The current direction.
 * @returns {JSX.Element} The rendered Page2 component.
 */
const Page2 = ({
  user,
  setAddress,
  address,
  setPage,
  isLoading,
  setDirection,
  direction
}) => {
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  return (
    <div className="flex flex-col  justify-center items-center h-full">
      <AnimatePresence mode="wait">
        <motion.div
          {...scaleMotionProps2}
          className="flex flex-col items-center"
        >
          <h2 className="h5-b text-neutral mb-3">Hello {user}!</h2>
          <p className="h2 text-secondary w-[32rem] mb-6 text-center">
            Enter your area name or zip code
          </p>
          <div className="w-[20rem] relative">
            <InputGroup
              size="m"
              position="center"
              placeholder="Street Address, City"
              svg={locationIcon}
              value={address}
              onChange={handleAddressChange}
            />
            {address && (
              <img
                src={closeIcon}
                alt=""
                className="absolute top-4 right-4 cursor-pointer"
                onClick={() => setAddress('')}
              />
            )}
          </div>
          <div className="flex gap-4 mt-6">
            <Button
              variant="enabled"
              onClick={() => {
                setPage(1);
                setDirection(false);
              }}
            >
              Back
            </Button>
            <Button
              variant={address === '' ? 'disabled' : 'primary'}
              disabled={address === ''}
              onClick={() => {
                setPage(3);
                setDirection(true);
              }}
            >
              {isLoading && direction  ? 'Please wait...' : 'Next'}
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Page2;
