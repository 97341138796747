import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Loader from 'wrapper/loader';
//icons
import closeIcon from 'assets/icons/redCloseIcon.svg';
import warning from 'assets/icons/warning.svg';
const DynamicTabs = ({
  onClickTab,
  selectedTab,
  tabsArray = [],
  variant = 'primary',
  onDelete,
  handleUpdateFunction,
  isError,
  isLoading = false,
}) => {
  const variants = {
    primary: {
      active: 'text-white bg-black rounded-t-lg px-3 text-base font-medium',
      inActive: 'font-medium',
    },
    error: {
      active: 'text-white bg-[red] rounded-t-lg px-3 text-base font-medium',
      inActive: 'font-medium',
    },
    secondary: {
      active:
        'text-white bg-primary500 rounded-t-lg px-3 text-base font-medium',
      inActive: 'font-medium',
    },
    primary_sm: {
      active: 'text-white bg-black rounded-t-lg px-3 text-base p-xs-med',
      inActive: 'p-xs-med',
    },
    secondary_sm: {
      active: 'text-white bg-primary500 rounded-t-lg px-3 text-base p-xs-med',
      inActive: 'p-xs-med',
    },
  };

  return (
    <>
      <div className="w-full mr-1 rounded-md flex gap-4 overflow-x-scroll overflow-y-hidden">
        {tabsArray?.map((tab) => {
          return (
            <>
              <motion.button
                whileHover="animate"
                initial={{
                  x: -50,
                  y: 0,
                }}
                animate={{
                  x: 0,
                  y: 0,
                  scale: 1,
                  rotate: 0,
                }}
                className={`${
                  variants[isError ? 'error' : variant][
                    tab.isActive ? 'active' : 'inActive'
                  ]
                } py-2 group`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: 6,
                  width: 'fit',
                  //minWidth: 'fit',
                }}
                onClick={async () => {
                  if (tab.id === selectedTab) return false;
                  if (await handleUpdateFunction()) {
                    tabsArray?.map((item) => {
                      if (item.isActive) {
                        item.isActive = false;
                      }
                      if (item.id === tab.id) {
                        item.isActive = true;
                      }
                    });
                    onClickTab && onClickTab(tab?.id);
                  }
                }}
              >
                {isError && (
                  <div className="h-full w-fit flex flex-col justify-center">
                    <img className="min-h-[15px] min-w-[15px]" src={warning} />
                  </div>
                )}
                <div className="flex flex-col justify-center h-[100%] min-w-[170px] w-fit">
                  {tab?.name}
                </div>
                {tab?.isActive && tab.id != 0 && (
                  <div
                    className={`group-hover:opacity-100 opacity-0 flex flex-col justify-center h-[100%] w-[20px]`}
                  >
                    <img
                      src={closeIcon}
                      className="self-center h-[10px] w-[10px]"
                      alt="x"
                      onClick={() => {
                        onDelete(tab);
                      }}
                    />
                  </div>
                )}
              </motion.button>
            </>
          );
        })}
        {isLoading && (
          <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
            <Loader loaderText="Loading" FullScreen={false} />
          </div>
        )}
      </div>
    </>
  );
};

export default DynamicTabs;
