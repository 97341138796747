import { api } from 'constants/constant';
import { getHeaders, getUserDetails } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const documentAPI = createApi({
  reducerPath: 'Document',
  tagTypes: ['updateList'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    getDocumentList: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `document/file/documents/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['updateList'],
    }),
    getUserProperty: builder.query({
      query: () => {
        return {
          url: `document/file/user_info`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    deleteDocument: builder.mutation({
      query: ({ documentId, userId }) => {
        const { token } = getUserDetails();
        return {
          url: `document/file/document/${documentId}`,
          method: 'DELETE',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({ user_id: userId }).toString(),
        };
      },
      invalidatesTags: ['updateList'],
    }),
    uploadPredefinedDocs: builder.mutation({
      query: ({ data, additionalParams }) => {
        const { token } = getUserDetails();
        const formData = new FormData();
        formData.append('file', data);
        Object.keys(additionalParams).forEach((key) => {
          formData.append(key, additionalParams[key]);
        });
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        return {
          url: `/document/file/upload_file`,
          method: 'POST',
          headers: headers,
          body: formData,
        };
      },
      invalidatesTags: ['updateList'],
    }),
    updatePredefinedDocs: builder.mutation({
      query: ({ data, additionalParams, documentId }) => {
        const { token } = getUserDetails();
        const formData = new FormData();
        formData.append('file', data);
        Object.keys(additionalParams).forEach((key) => {
          formData.append(key, additionalParams[key]);
        });
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        return {
          url: `/document/file/update_file/${documentId}`,
          method: 'POST',
          headers: headers,
          body: formData,
        };
      },
      invalidatesTags: ['updateList'],
    }),
  }),
});

// api for view document is present in hooks > pdfViewer.js

export const {
  useGetDocumentListQuery,
  useUploadPredefinedDocsMutation,
  useUpdatePredefinedDocsMutation,
  useGetUserPropertyQuery,
  useDeleteDocumentMutation,
} = documentAPI;
