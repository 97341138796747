import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { scaleMotionProps3 } from 'constants/animationProps';
/**
 * Renders a page component with a greeting message.
 *
 * @param {Object} props - The component props.
 * @param {string} props.user - The user's name.
 * @returns {JSX.Element} The rendered page component.
 */
const Page0 = ({ user }) => {
  return (
    <div className="flex flex-col gap-10 justify-center items-center h-full">
      <AnimatePresence mode="wait">
        <motion.div
          {...scaleMotionProps3}
          className="flex flex-col items-center"
        >
          <h2 className="h2-b text-secondary">Hello {user}!</h2>
          <p className="h6 text-secondary mt-12">FundMore.ai Welcomes you</p>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Page0;
