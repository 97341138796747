import React, { useEffect, useState } from 'react';
/**
 * Renders a progress bar component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.value - The initial value of the progress bar (default: 0).
 * @param {string} props.color - The color of the progress bar (default: 'bg-primary500').
 * @param {string} props.backgroundColor - The background color of the progress bar (default: 'progressbar-bg').
 * @param {string} props.valuePosition - The position of the value string ('left' or 'right') (default: 'right').
 * @param {string} props.valueString - The string to display next to the progress bar.
 * @returns {JSX.Element} - The rendered progress bar component.
 */
const Progressbar = ({
  value: initialValue = 0,
  color = 'bg-primary500',
  backgroundColor = 'progressbar-bg',
  valuePosition = 'right',
  valueString,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    let startValue = initialValue;
    let targetValue = initialValue;
    const duration = 2000;
    const steps = duration / (1000 / 60);
    const stepValue = (targetValue - startValue) / steps;

    const interval = setInterval(() => {
      startValue += stepValue;
      setValue(Math.min(startValue, targetValue));
    }, 1000 / 60);

    return () => clearInterval(interval);
  }, [initialValue]);

  return (
    <div className="w-full flex items-center">
      {valuePosition === 'left' && (
        <div className="pr-2 flex items-center font-medium">{valueString}</div>
      )}
      <div
        className={`w-full items-center h-2 ${backgroundColor} rounded-full overflow-hidden`}
      >
        <div
          className={`h-full ${color} rounded-full`}
          style={{ width: `${value}%`, transition: 'width 0.2s ease-in-out' }}
        ></div>
      </div>
      {valuePosition === 'right' && (
        <div className="pl-2 flex items-center font-medium text-nowrap">{valueString}</div>
      )}
    </div>
  );
};

export default Progressbar;
