import React from 'react';
import { Link } from 'react-router-dom';
/**
 * Renders a breadcrumb navigation component.
 *
 * @param {Object} breadcrumbs - An array of breadcrumb objects.
 * @returns {JSX.Element} - The rendered breadcrumb navigation component.
 */
const Breadcrumb = ({
  breadcrumbs,
  activeIndex,
  clearSwitchComponent = () => {},
}) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      {breadcrumbs?.map((breadcrumb, index) => (
        <div key={breadcrumb.path} className="flex">
          <div className="flex items-center">
            <Link
              to={breadcrumb.path}
              onClick={() => {
                if (index === breadcrumbs.length - 2) {
                  clearSwitchComponent();
                }
              }}
              className={`${
                index === breadcrumbs.length - 1
                  ? 'text-primary font-bold'
                  : 'text-gray font-bold'
              }`}
            >
              {breadcrumb.name}
            </Link>
            {index < breadcrumbs.length - 1 && (
              <svg
                width="8"
                className="h-4 w-4 mx-2"
                height="15"
                viewBox="0 0 8 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.08203L6.29289 6.37492C6.68342 6.76545 6.68342 7.39861 6.29289 7.78914L1 13.082"
                  stroke="#808080"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            )}
          </div>
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumb;
