import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
//icons

import arrowUpWhite from 'assets/icons/arrowUpWhite.svg';
//utils & constants
import { domain, loan_officer_app_client_id } from 'constants/constant';
//children components
import Consumer from './Consumer';
import LoanOfficer from './LoanOfficer';
import { useUpdateProfileInfoMutation } from 'redux_store/services/update-password';
import { parseJwt } from 'auth/auth';
import { isValid } from 'date-fns';
import { formatPhoneNumber } from 'pages/profile/utils';
import fundmoreai from 'assets/fundmoreai2.png'

const createFormObject = (data) => {
  return data?.reduce((acc, value) => {
    if (value.type === 'container') {
      const formFields = value.formFields?.reduce(
        (acc, value) => ({ ...acc, [value.propertyName]: '' }),
        {}
      );
      return { ...acc, ...formFields };
    }
    return { ...acc, [value.propertyName]: '' };
  }, {});
};

const PersonalInfoCard = () => {
  //form states
  const [consumerState, setConsumerState] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  const [loanOfficerState, setLoanOfficerState] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    NMLSID: '',
  });
  /* inserting this field dynamically when the checkbox is clicked
  organizationNMLSID: '', */

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const stateId = searchParams.get('state');
  const sessionToken = searchParams.get('session_token');
  const clientId = searchParams.get('clientId');
  const [role] = useState(
    clientId === loan_officer_app_client_id ? 'loanOfficer' : 'consumer'
  );
  const [onSubmit, setOnSubmit] = useState(false);
  const [updateProfileInfo] = useUpdateProfileInfoMutation();

  const onSubmitButton = async () => {
    try {
      if (
        checkIfAnyError(
          role === 'consumer' ? consumerState : loanOfficerState,
          validationData
        )
      ) {
        setOnSubmit(true);
        setIsFormSubmitted(false);
        const decoded = parseJwt(sessionToken);
        const data = {
          ...(role === 'consumer' ? consumerState : loanOfficerState),
          userId: decoded.sub,
          NMLS: role === 'loanOfficer' ? loanOfficerState?.NMLSID ?? '' : '',
          isOrganization:
            role === 'loanOfficer' ? !!loanOfficerState?.isOrganization : false,
          organizationNMLS:
            role === 'loanOfficer' && loanOfficerState?.isOrganization
              ? loanOfficerState?.organizationNMLSID ?? ''
              : '',
          role:
            role === 'loanOfficer'
              ? loanOfficerState?.organizationNMLSID
                ? 'loan_officer_admin'
                : 'loan_officer'
              : 'consumer',
          stateToken: stateId,
        };
        const resp = await updateProfileInfo({
          data,
          token: sessionToken,
        });
        if (resp?.data?.status === 200) {
          window.location.href = `https://${domain}/continue?state=${stateId}`;
          setOnSubmit(false);
        } else if (resp?.error?.status === 401) {
          window.location.href = `${window.location.origin}/session-timeout`;
        } else {
          //Error Page here if we have one
          setOnSubmit(false);
        }
      } else {
        setIsFormSubmitted(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      setOnSubmit(false);
    }
  };
  const NanValidation = (data, property) => {
    if (isNaN(data[property]) || data[property].includes('-')) {
      return {
        isValid: false,
        hint: "Only Number's allowed.",
      };
    }
    return {
      isValid: true,
    };
  };
  const NMLSIdLengthValidation = (data, property) => {
    if (data[property].length === 6 || data[property].length === 7) {
      return {
        isValid: true,
      };
    }
    return {
      isValid: false,
      hint: 'Please enter a valid 6 or 7 digit NMLS ID',
    };
  };
  const nullValidation = (data, property) => {
    if (data[property]) {
      return {
        isValid: true,
      };
    }
    return {
      isValid: false,
      hint: 'Field cannot be empty',
    };
  };
  const phoneValidation = (data, property) => {
    if (data[property].length === 10) {
      return {
        isValid: true,
      };
    }
    return {
      isValid: false,
      hint: 'Please enter a valid phone number',
    };
  };

  const checkIfAnyError = (data, validationData) => {
    for (const key in data) {
      if (key in validationData) {
        const validators = validationData[key];
        for (const validator of validators) {
          if (!validator(data, key)?.isValid) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const validationData = {
    firstName: [nullValidation],
    lastName: [nullValidation],
    phoneNumber: [phoneValidation, NanValidation],
    NMLSID: [nullValidation, NanValidation, NMLSIdLengthValidation],
    organizationNMLSID: [nullValidation, NanValidation, NMLSIdLengthValidation],
  };

  // format phone number
  const handleMobileNumberValue = (value) => {
    let formattedValue;
    const cleanedValue = ('' + value).replace(/\D/g, '');
    if (cleanedValue?.length === 10) {
      formattedValue = formatPhoneNumber(value, '(###) ###-####');
    } else {
      formattedValue = cleanedValue;
    }
    return formattedValue;
  };

  // disable buttons
  const onKeyPressPhoneNumber = (e) => {
    const regex = /[0-9]/;
    const key = String.fromCharCode(e.keyCode || e.which);

    if (!regex.test(key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  return (
    <div
      className="bg-cover bg-center w-[100vw] h-screen flex flex-col justify-center items-center"
      style={{ backgroundImage: 'url("/mainbg.png")' }}
    >
      <div className="flex flex-col">
        <div className="w-[500px] h-[139px] bg-secondary100 flex flex-col justify-center items-center rounded-t-xl">
            <img
              src={fundmoreai}
              alt=""
              className="h-28"
              style={{width: '250px', height: '55px'}}
            />
          <h3 className="text-neutral900 h4-m mt-[-1rem]">Onboarding</h3>
        </div>
        <div className="w-[500px] h-fit flex flex-col justify-center bg-white relative overflow-hidden rounded-b-xl">
          {role === 'consumer' ? (
            <Consumer
              setConsumerState={setConsumerState}
              consumerState={consumerState}
              validationData={validationData}
              isFormSubmitted={isFormSubmitted}
              handleMobileNumberValue={handleMobileNumberValue}
              onKeyPressPhoneNumber={onKeyPressPhoneNumber}
            />
          ) : (
            <LoanOfficer
              setLoanOfficerState={setLoanOfficerState}
              loanOfficerState={loanOfficerState}
              validationData={validationData}
              isFormSubmitted={isFormSubmitted}
              handleMobileNumberValue={handleMobileNumberValue}
              onKeyPressPhoneNumber={onKeyPressPhoneNumber}
            />
          )}
          <button
            className="btn-primary p-sm-bold h-[65px] text-tertiary w-[110%] flex flex-col justify-center self-center "
            onClick={onSubmitButton}
          >
            <div className="self-center flex flex-row gap-2">
              {onSubmit ? 'Setting up Profile...' : 'Complete Sign Up'}
              {!onSubmit && (
                <img className="rotate-90" src={arrowUpWhite} alt="" />
              )}
            </div>
          </button>{' '}
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoCard;
