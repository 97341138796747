import { motion } from 'framer-motion';

const FormWrapper = ({ children, className }) => {
  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        className={`shadow-[0px_8px_25px_0px_#0000001A] h-fit w-fit rounded-lg ${className}`}
      >
        {children}
      </motion.div>
    </>
  );
};
export default FormWrapper;
