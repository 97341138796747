import React from 'react';
import TitleCard from 'components/title-card';
import { useDispatch } from 'react-redux';
import { setUserRole } from 'redux_store/slices/userRole';
import { useAuth0 } from '@auth0/auth0-react';
//icons
import Employed from 'assets/icons/Employed';
import angleDownWhite from 'assets/icons/angleDownWhite.svg';
import { audience } from 'constants/constant';
import { useGetAuth0Authorization } from 'hooks/useAuthentication';
const ConsumerCard = ({
  selectedUserRole,
  setSelectedUserRole,
  hoverStates,
  handleHover,
}) => {
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();

  useGetAuth0Authorization();

  const handleUserSelection = (type) => {
    setSelectedUserRole(type);
    dispatch(setUserRole(type));
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/auth0-callback-v2`,
        audience: audience,
      },
      role: 'consumer',
    });
  };

  return (
    <TitleCard
      selected={selectedUserRole === 'consumer'}
      onClick={() => handleUserSelection('consumer')}
      iconComponent={
        selectedUserRole !== 'consumer' ? (
          <Employed variant={hoverStates.consumer ? 'secondary' : 'primary'} />
        ) : (
          <Employed variant="secondary" />
        )
      }
      label="Consumer"
      onMouseEnter={() => handleHover('consumer', true)}
      onMouseLeave={() => handleHover('consumer', false)}
    />
  );
};

export default ConsumerCard;
