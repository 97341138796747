//React and is dependencies
import { useState, useEffect } from 'react';
//animation and css lib
import { motion } from 'framer-motion';
import { bgColors, borderColors, textColors } from '../../../../themes/colors';
//components
import H1 from 'components/h1-typography';
import InputGroup from 'components/input-group';
import Loader from 'wrapper/loader';
import Select from 'components/select';
import { customToast } from 'components/toast';
//form data
import {
  transactionDetailsArray,
  calculationDataArray,
  availableAssetsArray,
} from 'pages/profile/formfields/transactionDetails';
//RTK query
import { useGetTransactionDetailsQuery } from 'redux_store/services/borrower-profile';
const TransactionDetails = ({ applicationData }) => {
  const [expanded, setExpanded] = useState([]);
  const [transactionData, setData] = useState(transactionDetailsArray);
  const consumerUserId =
    applicationData?.success && applicationData?.data?.personal_details?.userId;
  const {
    data: transactionDetailsData,
    isLoading,
    error: transactionError,
  } = useGetTransactionDetailsQuery(
    { userId: consumerUserId ?? null },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [calculationData, setCalculationData] = useState(calculationDataArray);
  const [availableAssets, setAvailableAssets] = useState(availableAssetsArray);

  function updateAmounts(calculationData, calculation) {
    return calculationData.map((item) => {
      if (item.propertyName && calculation[item.propertyName] !== undefined) {
        return {
          ...item,
          amount: calculation[item.propertyName],
        };
      }
      return item; // Return unchanged if propertyName is not found
    });
  }
  useEffect(() => {
    if (transactionDetailsData?.success) {
      const obj = transactionDetailsData?.data;
      const updatedTransactionDetailsData = updateAmounts(
        transactionData,
        obj.transaction_details
      );
      setData(updatedTransactionDetailsData);
      const updatedCalculationData = updateAmounts(
        calculationData,
        obj.calculation
      );
      let assetCalculation = {
        available_assets: 0,
        cash_from_to_borrower:
          obj.calculation.cash_from_borrower_without_credit,
        surplus: obj.calculation.cash_from_borrower_without_credit + 0, //will be edited when assets are available
      };
      const updatedAssetCalculationData = updateAmounts(
        availableAssetsArray,
        assetCalculation
      );
      setAvailableAssets(updatedAssetCalculationData);
      setCalculationData(updatedCalculationData);
    } else {
      if (transactionError && transactionError?.status === 400) {
        customToast.error(transactionError?.data.message);
      }
    }
  }, [transactionDetailsData, transactionError]);

  return (
    <>
      {isLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <div className="overflow-y-scroll overflow-hidden w-full p-2 max-h-borrower-profile bg-white rounded-lg">
          <div className="h-full flex flex-col gap-3">
            <H1>Detail of Transaction</H1>
            {
              //transaction Data Table
            }
            <div className="w-[100%] bg-chatbot_bg flex flex-row p-3 py-4">
              <p className="w-[65%] p-xs-bold text-start">Description</p>
              <p className="w-[35%] p-xs-bold text-center">Amount</p>
            </div>
            <div className="flex flex-col gap-3">
              {transactionData?.map((item, index) => {
                return (
                  <>
                    <motion.div
                      initial={{
                        y: -10,
                      }}
                      animate={{
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotate: 0,
                      }}
                      onClick={() => {
                        if (expanded.includes(index)) {
                          setExpanded(expanded.filter((i) => i !== index));
                        } else {
                          setExpanded((prev) => [...prev, index]);
                        }
                      }}
                      className={`w-[100%] grey even:bg-secondary100 flex flex-row ${item.isInputTrue ? 'px-3 py-1 ' : 'p-4 '} cursor-pointer`}
                    >
                      <div className="w-[65%] flex flex-col justify-center">
                        <p className="p-xs-bold text-start">
                          {item?.description}
                        </p>
                      </div>
                      {item.isInputTrue ? (
                        <div className="w-[35%] flex flex-row justify-center">
                          <div className="w-[50%] p-xs-bold text-center">
                            <InputGroup
                              position="right"
                              size="sm_2"
                              disabled={false}
                              value={`$ ${item?.amount ?? 0}`}
                              //placeholder={`$ ${item?.amount}`}
                              className="text-right"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="w-[35%] flex flex-row justify-center">
                          <p className="w-[50%] px-2 p-xs-bold text-end">
                            $ {item?.amount ?? 0}
                          </p>
                        </div>
                      )}
                    </motion.div>
                    {expanded.includes(index) &&
                      item.children &&
                      item.children.map((child, index) => {
                        return (
                          <motion.div
                            initial={{
                              y: -10,
                            }}
                            animate={{
                              x: 0,
                              y: 0,
                              scale: 1,
                              rotate: 0,
                            }}
                            className="w-[100%] bg-white flex p-3 flex-row cursor-pointer"
                          >
                            {' '}
                            {item?.isChildTable && child.options ? (
                              <div className="w-[100%] flex flex-row justify-between px-3">
                                <div className="w-[40%] flex flex-row justify-center">
                                  <div className="w-[60%] h-fit p-xs-bold text-center">
                                    <InputGroup
                                      position="right"
                                      size="sm_2"
                                      disabled={true}
                                      value={`${child?.options?.[0] ?? ''}`}
                                      className="text-start"
                                    />
                                    {/*  <Select
                                      defaultValue={
                                        child?.options?.length &&
                                        child?.options?.[0]
                                      }
                                      value={
                                        child?.options?.length &&
                                        child?.options?.[0]
                                      }
                                      getOptionLabel={(e) => {
                                        return e;
                                      }}
                                      getOptionValue={(e) => {
                                        return e;
                                      }}
                                      onChange={(e) => {}}
                                      options={() => {
                                        return child?.options?.length
                                          ? child?.options
                                          : [];
                                      }}
                                      placeholder={'Select'}
                                      menuShouldScrollIntoView={true}
                                      menuShouldBlockScroll={true}
                                      menuPlacement="auto"
                                      menuPosition="fixed"
                                      className="w-full"
                                      noOptionsMessage={() =>
                                        'No Options matched!'
                                      }
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          fontSize: '12px',
                                          position: 'relative',
                                          fontWeight: '400',
                                          borderRadius: '8px',
                                          boxShadow: 'none',
                                          paddingRight: '9px',
                                          border: `2px solid ${borderColors.grey}`,
                                          '&:hover': {
                                            cursor: 'pointer',
                                            border: `2px solid ${borderColors.primary500}`,
                                          },
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: bgColors.tertiary_bg,
                                          color: textColors.black,
                                          fontSize: '12px',
                                          fontWeight: '400',
                                          '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: bgColors.primary50,
                                          },
                                          '&:active': {
                                            backgroundColor: bgColors.primary50,
                                          },
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          borderRadius: '8px',
                                          color: textColors.black,
                                          fontSize: '12px',
                                          fontWeight: '400',
                                        }),
                                        indicatorSeparator: () => null,
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          neutral80: textColors.black,
                                        },
                                      })}
                                    /> */}
                                  </div>
                                </div>
                                <div className="w-[40%] flex flex-row justify-center">
                                  <div className="w-[60%] p-xs-bold text-center">
                                    <InputGroup
                                      position="right"
                                      size="sm_2"
                                      disabled={true}
                                      value={`$ ${child?.amount ?? 0}`}
                                      className="text-start"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                <p className="w-[65%] p-xs-med text-start">
                                  {child?.description}
                                </p>
                                <div className="w-[35%] flex flex-row justify-center">
                                  <p className="w-[50%] px-3 p-xs-bold text-end">
                                    $ {child?.amount ?? 0}
                                  </p>
                                </div>
                              </>
                            )}
                          </motion.div>
                        );
                      })}
                  </>
                );
              })}
              {
                //Calculation Table
              }
              <div className="flex flex-col">
                <hr className="p-1" />
                {calculationData?.map((item, index) => {
                  return (
                    <motion.div
                      initial={{
                        y: -10,
                      }}
                      animate={{
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotate: 0,
                      }}
                      className={`w-[100%] grey even:bg-secondary100 flex flex-row p-4 cursor-pointer`}
                    >
                      <div className="w-[65%] flex flex-col justify-center">
                        <p className="p-xs-bold text-start">
                          {item?.description}
                        </p>
                      </div>
                      <div className="w-[35%] flex flex-row justify-center">
                        {item?.propertyName && (
                          <p className="w-[50%] px-2 p-xs-bold text-end">
                            $ {item?.amount ?? 0}
                          </p>
                        )}
                      </div>
                    </motion.div>
                  );
                })}
              </div>
              {
                //Available Assets Table
              }
              <hr className="" />
              <div className="flex flex-col rounded-md overflow-hidden">
                {availableAssets?.map((item, index) => {
                  return (
                    <>
                      <motion.div
                        initial={{
                          y: -10,
                        }}
                        animate={{
                          x: 0,
                          y: 0,
                          scale: 1,
                          rotate: 0,
                        }}
                        className={`w-[100%] grey odd:bg-secondary100 flex flex-row p-4 cursor-pointer ${item.isHighlighted && 'border-t-2 border-t-blue'}`}
                      >
                        <div className="w-[65%] flex flex-col justify-center">
                          <p
                            className={`p-xs-bold text-start ${item.isHighlighted && 'text-primary500'}`}
                          >
                            {item?.description}
                          </p>
                        </div>
                        <div className="w-[35%] flex flex-row justify-center">
                          <p
                            className={`w-[50%] px-2 p-xs-bold text-end ${item.isHighlighted && 'text-primary500'}`}
                          >
                            $ {item?.amount ?? 0}
                          </p>
                        </div>
                      </motion.div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TransactionDetails;
