//react and state's
import React, { useState, useEffect } from 'react';
//component's
import DynamicForm from '../DynamicForm';
import Button from 'components/button';
import Loader from 'wrapper/loader';
import H1 from 'components/h1-typography';
import DeletePopup from 'components/delete-popup';
import FormWrapper from 'wrapper/form-wrapper';
//icon's
import DeleteIcon from 'assets/icons/DeleteBin';
import plus from 'assets/icons/bluePlus.svg';
//formfield's
import {
  assetData,
  otherAssets as otherAssetsData,
  otherCredits,
} from '../consumerFormFields/assetAndOtherCredits';
//RTK query
import {
  useCreateAssetDetailsMutation,
  useDeleteAssetDetailsMutation,
  useGetDynamicApplicationQuery,
  useGetAssetTypeQuery,
} from 'redux_store/services/client/profile';
import { useSelector } from 'react-redux';
import {
  assetDataSelector,
  resetPageInfo,
} from 'redux_store/slices/application';
import { customToast } from 'components/toast';
//auth and util's
import { getUserDetails } from 'auth/auth';
import {
  checkValidations,
  createFormObject,
  removeEmptyStrings,
} from '../utils';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';
import { useDispatch } from 'react-redux';
//Sentry
import * as Sentry from '@sentry/browser';

const AssetsAndOtherCredits = ({ setPage, setPreviousPage, token }) => {
  const dispatch = useDispatch();
  const [assets, setAssets] = React.useState([createFormObject(assetData)]);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [otherAssets, setOtherAssets] = React.useState(
    createFormObject(otherAssetsData)
  );
  const userId = getUserDetails()?.userId;
  const isEdit = React.useRef(false);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [otherCreditsInfo, setOtherCreditsInfo] = React.useState(
    createFormObject(otherCredits)
  );

  //query
  const { data: applicationData, refetch } = useGetDynamicApplicationQuery(
    {
      application_id: token,
      userId,
    },
    {
      skip: !token,
    }
  );

  const assetsDataAI = useSelector(assetDataSelector);

  useEffect(() => {
    if (assetsDataAI && Object.keys(assetsDataAI).length > 0) {
      // Update the first asset with AI data
      const updatedAssets = [...assets];
      updatedAssets[0] = {
        ...updatedAssets[0],
        assetTypeId: { id: assetsDataAI.assetTypeId },
        bankName: assetsDataAI.bankName,
        accountNumber: assetsDataAI.accountNumber,
        balance: assetsDataAI.balance,
      };
      setAssets(updatedAssets);

      // Update other assets
      setOtherAssets({
        otherAssetTypeId: { id: assetsDataAI.otherAssetTypeId },
        otherAccountSource: assetsDataAI.otherAccountSource,
        otherAccountBalance: assetsDataAI.otherAccountBalance,
      });

      // Update other credits
      setOtherCreditsInfo({
        realtorCredit: assetsDataAI.realtorCredit,
        sellerCredit: assetsDataAI.sellerCredit,
        lenderCredit: assetsDataAI.lenderCredit,
        otherCredit: assetsDataAI.otherCredit,
      });
    }
  }, [assetsDataAI]);

  const addAsset = () => {
    if (assets?.length < 5) {
      customToast.success(
        `Additional asset ${assets.length} added successfully`
      );
      setAssets([...assets, createFormObject(assetData)]);
    } else {
      customToast.error('Cannot add more than 5 assets');
    }
  };
  const removeAsset = async (index, asset) => {
    if (index === 0) return;
    if (asset) {
      try {
        await deleteAssets({ assetId: asset });
        customToast.success(`Additional asset ${index} removed successfully.`);
        refetch();
        setIsDelete(false);
      } catch (error) {
        customToast.error('Failed to delete');
      }
    } else if (!asset) {
      const newAssets = assets.filter((_, i) => i !== index);
      customToast.success(`Additional asset ${index} removed successfully.`);
      setAssets(newAssets);
      setIsDelete(false);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let uniqueId;
    if (assetsDataAI && Object.keys(assetsDataAI).length > 0) {
      const refetchedData = await refetch();
      if (refetchedData?.isSuccess) {
        uniqueId = refetchedData?.data?.data?.user_asset_details?.userAssetId;
      }
    }
    let hasErrors = false;
    const allErrors = {};

    assets.forEach((asset, index) => {
      const assetErrors = checkValidations(assetData, asset);
      if (Object.keys(assetErrors).length > 0) {
        hasErrors = true;
        allErrors[`asset_${index}`] = assetErrors;
      }
    });

    const otherAssetErrors = checkValidations(otherAssetsData, otherAssets);
    if (Object.keys(otherAssetErrors).length > 0) {
      hasErrors = true;
      allErrors.otherAssets = otherAssetErrors;
    }

    const creditErrors = checkValidations(otherCredits, otherCreditsInfo);
    if (Object.keys(creditErrors).length > 0) {
      hasErrors = true;
      allErrors.credits = creditErrors;
    }

    if (hasErrors) {
      setErrors(allErrors);
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
      setErrors({});
      try {
        const incrementOrder = (arr) => {
          // Find the highest existing order
          const highestOrder = arr.reduce((max, obj) => {
            return obj.order ? Math.max(max, obj.order) : max;
          }, 0);

          // Start assigning orders from the next value after the highest existing order
          let nextOrder = highestOrder + 1;

          return arr.map((obj) => {
            if (!obj.order) {
              obj.order = nextOrder++;
            }
            return obj;
          });
        };

        const updatedArray = incrementOrder(assets);

        const data = updatedArray.map((asset) => ({
          ...asset,
          assetTypeId: asset?.assetTypeId?.id,
        }));

        const otherAssetsData = {
          ...otherAssets,
          otherAssetTypeId: otherAssets.otherAssetTypeId?.id,
        };

        const otherCredits = { ...otherCreditsInfo };

        const maxLength = Math.max(data.length);

        const mergedArray = Array.from({ length: maxLength }, (_, index) => {
          const baseObject = {
            assetTypeId: data[index]?.assetTypeId ?? null,
            bankName: data[index]?.bankName ?? null,
            accountNumber: data[index]?.accountNumber
              ? String(data[index].accountNumber)
              : null,
            balance: data[index]?.balance ? Number(data[index].balance) : 0,
            otherAssetTypeId: otherAssetsData?.otherAssetTypeId ?? null,
            otherAccountSource: otherAssetsData?.otherAccountSource ?? null,
            otherAccountBalance: otherAssetsData?.otherAccountBalance
              ? Number(otherAssetsData.otherAccountBalance)
              : 0,
            realtorCredit: otherCredits?.realtorCredit
              ? Number(otherCredits.realtorCredit)
              : 0,
            sellerCredit: otherCredits?.sellerCredit
              ? Number(otherCredits.sellerCredit)
              : 0,
            lenderCredit: otherCredits?.lenderCredit
              ? Number(otherCredits.lenderCredit)
              : 0,
            otherCredit: otherCredits?.otherCredit
              ? Number(otherCredits.otherCredit)
              : 0,
            applicationId: token,
            userProfileId:
              applicationData?.data?.personal_details?.userProfileId ?? null,
            userId: applicationData?.data?.personal_details?.userId ?? null,
            isCompleted: true,
            order: data[index].order,
          };

          // Conditionally add userAssetId if it exists
          if (index === 0 && uniqueId) {
            baseObject.userAssetId = assets[index].userAssetId || uniqueId;
          } else if (assets[index]?.userAssetId) {
            baseObject.userAssetId = assets[index].userAssetId;
          }

          return baseObject;
        });

        const resp = await createAssets(mergedArray);
        await refetch();

        if (resp?.data?.status === 200) {
          setPage();
          dispatch(
            resetPageInfo({
              name: 'assetData',
            })
          );
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };

  const { data: accountTypeData } = useGetAssetTypeQuery();
  const [createAssets, { isLoading: isCreateAssetsLoading }] =
    useCreateAssetDetailsMutation();
  const [deleteAssets] = useDeleteAssetDetailsMutation();

  React.useEffect(() => {
    if (applicationData?.data?.user_asset_details.length > 0) {
      isEdit.current = true;
      const formData = applicationData?.data?.user_asset_details[0];
      const assetsFormData = applicationData?.data?.user_asset_details;

      const structuredAssets = assetsFormData.map((formData) => ({
        ...formData,
        assetTypeId: formData.assetTypeId ? { id: formData.assetTypeId } : null,
      }));

      setAssets(structuredAssets);
      setOtherAssets({
        otherAssetTypeId: formData?.otherAssetTypeId
          ? { id: formData?.otherAssetTypeId }
          : '',
        otherAccountSource: formData?.otherAccountSource
          ? formData?.otherAccountSource
          : '',
        otherAccountBalance: formData?.otherAccountBalance
          ? formData?.otherAccountBalance
          : '',
      });
      setOtherCreditsInfo({
        realtorCredit: formData.realtorCredit ? formData.realtorCredit : '',
        sellerCredit: formData.sellerCredit ? formData.sellerCredit : '',
        lenderCredit: formData.lenderCredit ? formData.lenderCredit : '',
        otherCredit: formData.otherCredit ? formData.otherCredit : '',
      });
    }
  }, [applicationData]);

  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  const formatData = (data, property) => {
    return (
      data?.map((x) => {
        return {
          ...x,
          id: x[property],
        };
      }) ?? []
    );
  };

  const dropdowns = {
    assetTypeId: formatData(accountTypeData?.data, 'assetTypeId'),
    otherAssetTypeId: formatData(accountTypeData?.data, 'assetTypeId'),
  };

  return (
    <div className="overflow-auto">
      <H1>Assets and Other Credits</H1>
      <p className="antialiased">
        Provide Accounts, Balances, and other credit details.
      </p>
      <div className="flex flex-row justify-center">
        <FormWrapper
          className={
            'my-3 mx-2 max-h-custom-profile-forms overflow-hidden overflow-y-scroll min-w-[63vw] pb-3'
          }
        >
          <div className="p-3 rounded-lg self-center">
            <form onSubmit={onSubmitHandler}>
              <div className="flex flex-row justify-end">
                <div
                  className="mb-3 self-end w-fit flex flex-col justify-center py-2 cursor-pointer p-sm-regular text-primary500 rounded-md"
                  onClick={addAsset}
                >
                  <>
                    <div className="flex flex-row justify-center gap-3 w-fit px-2">
                      <img src={plus} alt="+" className="h-[16px]" />
                      <p className="font-medium">Add Additional Asset</p>
                    </div>
                  </>
                </div>
              </div>
              {/* <h3
                className="text-lg px-4 py-3 p-xs-med text-primary500 text-right flex items-center justify-end gap-1 cursor-pointer"
                onClick={addAsset}
              >
                <span className="text-2xl text-primary500">+</span> Add Assets
              </h3> */}

              {assets.map((asset, index) => {
                return (
                  <div key={index} className="mb-6">
                    <h1
                      className={`text-lg px-4 py-3 p-sm-bold flex justify-between ${index > 0 ? 'bg-secondary100' : 'bg-primary100'} `}
                    >
                      {index > 0 ? `Asset - ${index}` : 'Assets'}
                      {index > 0 && (
                        <>
                          <button
                            onClick={() => {
                              setIsDelete(true);
                              setDeleteId(index);
                            }}
                            type="button"
                          >
                            <DeleteIcon />
                          </button>
                          {isDelete && deleteId === index && (
                            <DeletePopup
                              onSubmitHandler={() =>
                                removeAsset(index, asset?.userAssetId)
                              }
                              deletePopup={isDelete}
                              setDeletePopup={setIsDelete}
                              data={{ name: `Asset - ${index}` }}
                            />
                          )}
                        </>
                      )}
                    </h1>
                    <DynamicForm
                      data={assetData}
                      formData={asset}
                      setFormData={(newData) => {
                        // Check if newData is a function and execute it to get the updated data
                        const updatedData =
                          typeof newData === 'function'
                            ? newData(assets[index])
                            : newData;

                        // Create a copy of the current assets array
                        const newAssets = [...assets];

                        // Update the specific asset at the given index with the new data
                        newAssets[index] = {
                          ...newAssets[index],
                          ...updatedData,
                        };

                        // Set the updated assets array back into the state
                        setAssets(newAssets);
                      }}
                      dropdowns={dropdowns}
                      isSubmitted={isFormSubmitted}
                    />
                  </div>
                );
              })}

              <h1 className="text-lg px-4 py-3 p-sm-bold bg-primary100">
                Other Assets
              </h1>

              <DynamicForm
                data={otherAssetsData}
                formData={otherAssets}
                setFormData={setOtherAssets}
                isSubmitted={isFormSubmitted}
                dropdowns={dropdowns}
              />

              <h1 className="text-lg px-4 mt-6 py-3 p-sm-bold bg-primary100">
                Other Credits
              </h1>
              <DynamicForm
                data={otherCredits}
                formData={otherCreditsInfo}
                setFormData={setOtherCreditsInfo}
                errors={errors.credits}
                isSubmitted={isFormSubmitted}
              />

              <div className="text-right mt-2">
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setPreviousPage();
                  }}
                  style={{ marginRight: '10px' }}
                  type="button"
                >
                  Back: Employment and Income
                </Button>
                <Button type="submit" variant="primary">
                  {isCreateAssetsLoading ? (
                    <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                      <Loader loaderText="Saving" FullScreen={false} />
                    </div>
                  ) : (
                    'Next: Liabilities'
                  )}
                </Button>
              </div>
            </form>
          </div>
        </FormWrapper>
      </div>
    </div>
  );
};

export default AssetsAndOtherCredits;
