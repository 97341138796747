import React, { useState } from 'react';
import InputGroup from '../../components/input-group';
import InputPhoneGroup from 'components/input-phone-group';
import Select from 'react-select';
import Radio from '../../components/radio';
import Checkbox from '../../components/checkbox';
import DatePicker from 'react-datepicker';
import eyeIcon from 'assets/icons/eyeIcon.svg';
import eyeOff from 'assets/icons/eyeOff.svg';
//themes
import { bgColors, textColors, borderColors } from '../../themes/colors';
/**
 * Renders a dynamic form based on the provided data and form configuration.
 *
 * @param {Object} props - The props object.
 * @param {Array} props.data - The array of form field objects.
 * @param {Object} props.formData - The object containing the form data.
 * @param {Function} props.setFormData - The function to update the form data.
 * @param {Object} [props.dropdowns={}] - The object containing dropdown options.
 * @param {boolean} [props.isSubmitted] - Indicates if the form has been submitted.
 * @param {Object} [props.errors={}] - The object containing form field errors.
 * @param {Object} [props.additionInfo] - Additional information for form field validation.
 * @returns {JSX.Element} The rendered dynamic form.
 */
const DynamicForm = ({
  data,
  formData,
  setFormData,
  dropdowns = {},
  isSubmitted,
  errors = {},
  additionInfo,
  readOnly = false,
}) => {
  const isFieldRequired = (formField) => {
    if (
      (typeof formField.isRequired === 'function' &&
        formField?.isRequired(formData, additionInfo)) ||
      (typeof formField.isRequired !== 'function' && formField?.isRequired)
    ) {
      return true;
    }
    return false;
  };
  const [isValueVisible, setIsValueVisible] = useState([]);
  // check the data entered is valid or not
  const isValid = (formData, fieldConfig) => {
    // check entered input data is valid or not
    if (isFieldRequired(fieldConfig)) {
      let defaultValid = true;
      fieldConfig?.checkValidation?.forEach((checkValidate) => {
        const resp = checkValidate(
          formData[fieldConfig?.propertyName],
          fieldConfig
        );
        if (!resp?.isValid && defaultValid) {
          defaultValid = false;
        }
      });
      return defaultValid;
    }
    return true;
  };

  // Error message for the fields that has validations.
  const getIsValidErrorMessage = (formData, fieldConfig) => {
    // check every validations for that form field and return the first error message.
    if (isFieldRequired(fieldConfig)) {
      let errorMessage = '';
      fieldConfig?.checkValidation?.forEach((checkValidate) => {
        const resp = checkValidate(
          formData[fieldConfig?.propertyName],
          fieldConfig
        );
        if (!resp?.isValid && !errorMessage) {
          errorMessage = resp?.errorMessage;
        }
      });
      return errorMessage;
    }
    return '';
  };

  // check if the field should be disabled
  const isDisabled = (formField) => {
    return typeof formField?.disabled === 'function'
      ? formField.disabled(formData, additionInfo)
      : formField?.disabled;
  };

  const fieldData = (formField) => {
    if (formField?.type === 'text' || formField?.type === 'number') {
      return (
        <div className={`px-2 my-2 ${formField?.customClass}`}>
          <label
            className={`p-xs-bold ${
              isDisabled(formField) ? 'text-disabled' : 'text-custom-greengray'
            } mb-1`}
            for={`form-field=${formField?.propertyName}`}
          >
            {formField?.name || <br />}
            {isFieldRequired(formField) && formField?.name && (
              <span className="text-error pl-1">*</span>
            )}
          </label>
          <InputGroup
            data-testid={formField?.propertyName}
            type={formField?.type}
            value={formData[formField?.propertyName] ?? ''}
            placeholder={`${formField?.placeholder}`}
            id={`form-field=${formField?.propertyName}`}
            onChange={(e) => {
              setFormData((old) => ({
                ...old,
                [formField?.propertyName]: formField?.validator
                  ? formField?.validator(e.target.value)
                  : e.target.value,
              }));
            }}
            isError={!isValid(formData, formField) && isSubmitted}
            disabled={
              typeof formField?.disabled === 'function'
                ? formField.disabled(formData, additionInfo)
                : formField?.disabled
            }
            readOnly={readOnly}
            min={0}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) &&
              formField?.type === 'number' &&
              evt.preventDefault()
            }
            // max={formField?.max || Infinity}
            style={{ maxHeight: '39px' }}
            maxLength="50"
          />
          {!isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red text-xs font-normal pt-1 h-[24px] error-message">
              {getIsValidErrorMessage(formData, formField)}
            </p>
          )}
          {isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red">&nbsp;</p>
          )}
        </div>
      );
    }
    if (formField?.type === 'password') {
      const propertyId = formField?.id;
      return (
        <div className={`px-2 my-2 ${formField?.customClass}`}>
          <label
            className={`p-xs-bold ${
              isDisabled(formField) ? 'text-disabled' : 'text-custom-greengray'
            } mb-1`}
            for={`form-field=${formField?.propertyName}`}
          >
            {formField?.name || <br />}
            {isFieldRequired(formField) && formField?.name && (
              <span className="text-error pl-1">*</span>
            )}
          </label>
          <InputGroup
            type={isValueVisible.includes(propertyId) ? 'text' : 'password'}
            setIsValueVisible={setIsValueVisible}
            propertyId={propertyId}
            svg={isValueVisible.includes(propertyId) ? eyeIcon : eyeOff}
            value={formData[formField?.propertyName] ?? ''}
            placeholder={`${formField?.placeholder}`}
            id={`form-field=${formField?.propertyName}`}
            onChange={(e) => {
              setFormData((old) => ({
                ...old,
                [formField?.propertyName]: formField?.validator
                  ? formField?.validator(e.target.value)
                  : e.target.value,
              }));
            }}
            isError={!isValid(formData, formField) && isSubmitted}
            disabled={
              typeof formField?.disabled === 'function'
                ? formField.disabled(formData, additionInfo)
                : formField?.disabled
            }
            readOnly={readOnly}
            min={0}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) &&
              formField?.type === 'number' &&
              evt.preventDefault()
            }
            // max={formField?.max || Infinity}
            style={{ maxHeight: '39px' }}
            maxLength="50"
          />
          {!isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red text-xs font-normal pt-1 h-[24px] error-message">
              {getIsValidErrorMessage(formData, formField)}
            </p>
          )}
          {isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red">&nbsp;</p>
          )}
        </div>
      );
    }
    if (formField?.type === 'phone') {
      return (
        <div className={`px-2 my-2 ${formField?.customClass}`}>
          <label
            className={`p-xs-bold ${
              isDisabled(formField) ? 'text-disabled' : 'text-custom-greengray'
            } mb-1`}
            for={`form-field=${formField?.propertyName}`}
          >
            {formField?.name || <br />}
            {isFieldRequired(formField) && formField?.name && (
              <span className="text-error pl-1">*</span>
            )}
          </label>
          <InputPhoneGroup
            data-testid={formField?.propertyName}
            type="text"
            value={formData[formField?.propertyName] ?? ''}
            placeholder={`${formField?.placeholder}`}
            id={`form-field=${formField?.propertyName}`}
            onChange={(e) => {
              const { value, original } = e.target;
              setFormData((old) => ({
                ...old,
                [formField?.propertyName]: value,
                [`${formField?.propertyName}_Unformatted`]: original,
              }));
            }}
            formatPattern={formField.pattern}
            isError={!isValid(formData, formField) && isSubmitted}
            disabled={
              typeof formField?.disabled === 'function'
                ? formField.disabled(formData, additionInfo)
                : formField?.disabled
            }
            readOnly={readOnly}
            min={0}
            style={{ maxHeight: '39px' }}
          />
          {!isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red text-xs font-normal pt-1 h-[24px] error-message">
              {getIsValidErrorMessage(formData, formField)}
            </p>
          )}
          {isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red">&nbsp;</p>
          )}
        </div>
      );
    }
    if (formField?.type === 'checkbox') {
      return (
        <div className={`px-2 my-2 ${formField?.customClass}`}>
          <div className={`flex items-center`}>
            <Checkbox
              checked={formData[formField?.propertyName]}
              id={`form-field=${formField?.propertyName}`}
              onChange={(e) => {
                if (readOnly) {
                  e.preventDefault();
                } else {
                  setFormData((old) => ({
                    ...old,
                    [formField?.propertyName]: e.target.checked,
                  }));
                }
              }}
              isError={!isValid(formData, formField) && isSubmitted}
            />

            <label
              className={`p-xs-bold block pl-2 ${
                isDisabled(formField)
                  ? 'text-disabled'
                  : !isValid(formData, formField) && isSubmitted
                    ? 'text-error'
                    : 'text-neutral700'
              }`}
              for={`form-field=${formField?.propertyName}`}
            >
              {formField?.name || <br />}{' '}
              {isFieldRequired(formField) && formField?.name && (
                <span className="text-error pl-1">*</span>
              )}
            </label>
          </div>
          {/* {!isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red text-xs font-normal pt-1 h-[24px]">
              {getIsValidErrorMessage(formData, formField)}
            </p>
          )} */}
        </div>
      );
    }
    if (formField?.type === 'checkbox-right') {
      return (
        <div className={`px-2 my-2 ${formField?.customClass}`}>
          <div className={`flex items-center`}>
            <label
              className={`p-xs-bold block pr-2 ${
                isDisabled(formField)
                  ? 'text-disabled'
                  : !isValid(formData, formField) && isSubmitted
                    ? 'text-error'
                    : 'text-neutral700'
              }`}
              for={`form-field=${formField?.propertyName}`}
            >
              {formField?.name || <br />}{' '}
              {isFieldRequired(formField) && formField?.name && (
                <span className="text-error pl-1">*</span>
              )}
            </label>
            <Checkbox
              checked={formData[formField?.propertyName]}
              id={`form-field=${formField?.propertyName}`}
              onChange={(e) => {
                if (readOnly) {
                  e.preventDefault();
                } else {
                  setFormData((old) => ({
                    ...old,
                    [formField?.propertyName]: e.target.checked,
                  }));
                }
              }}
              isError={!isValid(formData, formField) && isSubmitted}
            />
          </div>
          {/* {!isValid(formData, formField) && isSubmitted && (
        <p className="text-error-red text-xs font-normal pt-1 h-[24px]">
          {getIsValidErrorMessage(formData, formField)}
        </p>
      )} */}
        </div>
      );
    }
    if (formField?.type === 'radioBox-option') {
      return (
        <div className={formField?.customClass}>
          <div className="flex w-full  items-center gap-2">
            <div>
              <label
                className={`p-xs-bold ${
                  isDisabled(formField) ? 'text-disabled' : 'text-custom-greengray'
                }`}
                data-testid={formField?.name}
              >
                {formField?.name}{' '}
                {isFieldRequired(formField) && formField?.name && (
                  <span className="text-error pl-1">*</span>
                )}
              </label>
            </div>
            {formField?.options?.map((x) => {
              return (
                <div className={`px-2 my-2 flex items-center `} key={x?.id}>
                  <Radio
                    id={`form-field=${x?.name}`}
                    isError={!isValid(formData, formField) && isSubmitted}
                    className="w-4 h-4"
                    onChange={(e) => {
                      if (readOnly) {
                        e.preventDefault();
                      } else {
                        setFormData((old) => ({
                          ...old,
                          [formField?.propertyName]: e.target.checked
                            ? x
                            : null,
                        }));
                      }
                    }}
                    data-testid={x?.name}
                    checked={formData[formField?.propertyName]?.id === x?.id}
                  />
                  <label
                    className={`p-xs-bold pl-2 ${
                      !isValid(formData, formField) && isSubmitted
                        ? 'text-error-red'
                        : 'text-custom-greengray'
                    }`}
                    htmlFor={`form-field=${x?.name}`}
                  >
                    {x?.name || <br />}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (formField?.type === 'select') {
      const options = dropdowns[formField?.propertyName] ?? formField?.options;
      const id =
        formData[formField?.propertyName]?.id ??
        formData[formField?.propertyName];
      let placeholder = options?.filter((item) => {
        return id === item?.id;
      });
      if (options) {
        placeholder = placeholder[0]?.name;
      }

      return (
        <div
          className={`px-2 block my-2 ${formField?.customClass}`}
          data-testid="mock-react-select"
        >
          <label
            className={`p-xs-bold ${
              isDisabled(formField) ? 'text-disabled' : 'text-custom-greengray'
            } block`}
            htmlFor={`form-field=${formField?.propertyName}`}
          >
            {formField?.name || <br />}
            {isFieldRequired(formField) && formField?.name && (
              <span className="text-error pl-1">*</span>
            )}
          </label>
          <Select
            classNames={'react-select-mock'}
            defaultValue={() => {
              return (
                (typeof formData[formField?.propertyName] === 'object'
                  ? formData[formField?.propertyName]?.id
                  : formData[formField?.propertyName]) ?? ''
              );
            }}
            value={() => {
              return (
                (typeof formData[formField?.propertyName] === 'object'
                  ? formData[formField?.propertyName]?.id
                  : formData[formField?.propertyName]) ?? ''
              );
            }}
            isOptionDisabled={(option) => option.isdisabled}
            isDisabled={
              typeof formField?.disabled === 'function'
                ? formField.disabled(formData, additionInfo)
                : formField?.disabled
            }
            getOptionLabel={(option) => {
              return option.name;
            }}
            getOptionValue={(value) => {
              return value.id;
            }}
            onChange={(e) => {
              if (!readOnly) {
                const options = dropdowns[formField?.propertyName]
                  ? dropdowns[formField?.propertyName]
                  : formField?.options;
                const data = options?.find((x) => {
                  return String(x?.id) === String(e.id);
                });
                setFormData((old) => ({
                  ...old,
                  [formField?.propertyName]: data,
                }));
              }
            }}
            options={
              dropdowns[formField?.propertyName] ?? formField?.options ?? []
            }
            isClearable={false}
            placeholder={placeholder ?? formField.placeholder}
            menuShouldScrollIntoView={true}
            menuShouldBlockScroll={true}
            menuPlacement="auto"
            menuPosition="fixed"
            className="w-full"
            noOptionsMessage={() => 'No Options matched!'}
            styles={{
              control: (provided, state) => ({
                ...provided,
                fontSize: '12px',
                position: 'relative',
                fontWeight: '400',
                borderRadius: '5px',
                boxShadow: 'none',
                paddingRight: '9px',
                border: `2px solid ${
                  !isValid(formData, formField) && isSubmitted
                    ? borderColors.errorColor
                    : borderColors.grey
                }`,
                backgroundColor: state.isDisabled && bgColors.grey,
                '&:hover': {
                  cursor: 'pointer',
                  border: `2px solid ${
                    !isValid(formData, formField) && isSubmitted
                      ? borderColors.errorColor
                      : borderColors.primary500
                  }`,
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isDisabled
                  ? bgColors.disabled
                  : bgColors.tertiary_bg,
                color: textColors.black,
                fontSize: '12px',
                fontWeight: '400',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: state.isDisabled
                    ? bgColors.disabled
                    : bgColors.primary50,
                },
                '&:active': {
                  backgroundColor: bgColors.primary50,
                },
              }),
              menu: (provided, state) => ({
                ...provided,
                borderRadius: '5px',
                color: textColors.black,
                fontSize: '12px',
                fontWeight: '400',
              }),

              indicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral80: textColors.black,
              },
            })}
          />
          {/* <Select
            id={`form-field=${formField?.propertyName}`}
            onChange={(e) => {
              if (!readOnly) {
                const options = dropdowns[formField?.propertyName]
                  ? dropdowns[formField?.propertyName]
                  : formField?.options;
                const data = options?.find(
                  (x) => String(x?.id) === String(e.target.value)
                );
                setFormData((old) => ({
                  ...old,
                  [formField?.propertyName]: data,
                }));
              }
            }}
            readOnly={readOnly}
            isError={!isValid(formData, formField) && isSubmitted}
            value={
              (typeof formData[formField?.propertyName] === 'object'
                ? formData[formField?.propertyName]?.id
                : formData[formField?.propertyName]) ?? ''
            }
            disabled={
              typeof formField?.disabled === 'function'
                ? formField.disabled(formData, additionInfo)
                : formField?.disabled
            }
            style={{
              maxHeight: '39px',
            }}
          >
            <option value="" className="text-grey">
              {formField?.placeholder}
            </option>
            {dropdowns[formField?.propertyName] &&
              dropdowns[formField?.propertyName]?.map((option) => (
                <option value={option?.id} key={option?.id}>
                  {option?.name}
                </option>
              ))}
            {!dropdowns[formField?.propertyName] &&
              formField?.options?.map((option) => (
                <option key={option?.id} value={option?.id}>
                  {option?.name}
                </option>
              ))}
          </Select> */}
          {!isValid(formData, formField) && isSubmitted && (
            <p
              className="text-error-red text-xs font-normal pt-1 h-[24px] error-message"
              data-testid="error-message"
            >
              {getIsValidErrorMessage(formData, formField)}
            </p>
          )}
          {isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red">&nbsp;</p>
          )}{' '}
        </div>
      );
    }
    if (formField?.type === 'radio') {
      return formField?.radioOptions?.map((radio) => {
        return (
          <div className="flex items-center" key={radio?.id}>
            <Radio
              isError={true}
              data={'adsfdsa'}
              onChange={(e) => {
                if (readOnly) {
                  e.preventDefault;
                } else {
                  setFormData((old) => ({
                    ...old,
                    [formField?.propertyName]: radio,
                  }));
                }
              }}
              id={'radio1'}
              value={radio?.name}
              name={`radio-${formField?.propertyName}`}
            />
            <label
              htmlFor="radio1"
              data-testid={radio?.name}
              className={`pl-1  ${'text-custom-greengray'} p-xs-bold`}
            >
              {radio?.name}
            </label>
          </div>
        );
      });
    }
    if (formField?.type === 'date-encrypted') {
      const propertyId = formField?.id;
      let svgEyeIcon = isValueVisible.includes(propertyId) ? eyeIcon : eyeOff;

      return (
        <div className={formField?.customClass}>
          <label
            className={`block ${
              isDisabled(formField) ? 'text-disabled' : 'text-custom-greengray'
            } p-xs-bold`}
            data-testid={formField?.name}
          >
            {formField?.name}
            {isFieldRequired(formField) && formField?.name && (
              <span className="text-error pl-1">*</span>
            )}
          </label>
          <div
            className={`flex flex-row cursor-pointer border-2 w-full p-xs-regular max-h-[39px] focus:border-2 focus:outline-none rounded-lg text-base px-3 py-1.5 text-black ${
              isSubmitted && !isValid(formData, formField)
                ? 'border-error-red'
                : 'border-primary'
            } ${
              isSubmitted && !isValid(formData, formField)
                ? 'focus:border-error-red'
                : 'focus:border-secondary'
            }`}
          >
            {isValueVisible.includes(propertyId) ||
            !formData[formField?.propertyName] ? (
              <DatePicker
                selected={formData[formField?.propertyName]}
                wrapperClassName="w-full"
                onChange={(date) =>
                  setFormData((old) => ({
                    ...old,
                    [formField?.propertyName]: date,
                  }))
                }
                className="p-xs-regular h-fit w-full max-h-[39px] cursor-pointer border-none outline-none caret-white text-black"
                maxDate={formField?.disableFutureDate ? new Date() : null}
                dropdownMode={'select'}
                showMonthDropdown={true}
                showYearDropdown={true}
                placeholderText={formField?.placeholder}
                scrollableYearDropdown
                readOnly={readOnly}
              />
            ) : (
              <input
                type="password"
                value={'Hidden Text'}
                className="p-xs-regular h-fit w-full max-h-[39px] cursor-pointer border-none outline-none caret-white text-black"
              />
            )}
            <div
              className="flex flex-col justify-center"
              onClick={() =>
                setIsValueVisible((prev) => {
                  if (prev.includes(propertyId)) {
                    return prev.filter((item) => item !== propertyId);
                  }
                  return [...prev, propertyId];
                })
              }
            >
              <img src={svgEyeIcon} alt="on" />
            </div>
          </div>
          {!isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red text-xs font-normal pt-1 h-[24px] error-message">
              {getIsValidErrorMessage(formData, formField)}
            </p>
          )}
          {isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red">&nbsp;</p>
          )}
        </div>
      );
    }
    if (formField?.type === 'date') {
      return (
        <div className={formField?.customClass}>
          <label
            className={`block ${
              isDisabled(formField) ? 'text-disabled' : 'text-custom-greengray'
            } p-xs-bold`}
          >
            {formField?.name}
            {isFieldRequired(formField) && formField?.name && (
              <span className="text-error pl-1">*</span>
            )}
          </label>
          <DatePicker
            selected={formData[formField?.propertyName]}
            wrapperClassName="w-full"
            onChange={(date) =>
              setFormData((old) => ({
                ...old,
                [formField?.propertyName]: date,
              }))
            }
            maxDate={formField?.disableFutureDate ? new Date() : null}
            dropdownMode={'select'}
            showMonthDropdown={true}
            showYearDropdown={true}
            placeholderText={formField?.placeholder}
            className={`cursor-pointer border-2 custom-date-class w-full p-xs-regular max-h-[39px] focus:border-2 focus:outline-none rounded-lg text-base px-3 py-1.5 text-black ${
              isSubmitted && !isValid(formData, formField)
                ? 'border-error-red'
                : 'border-primary'
            } ${
              isSubmitted && !isValid(formData, formField)
                ? 'focus:border-error-red'
                : 'focus:border-primary500'
            }`}
            scrollableYearDropdown
            readOnly={readOnly}
          />
          {!isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red text-xs font-normal pt-1 h-[24px] error-message">
              {getIsValidErrorMessage(formData, formField)}
            </p>
          )}
          {isValid(formData, formField) && isSubmitted && (
            <p className="text-error-red">&nbsp;</p>
          )}
        </div>
      );
    }
  };
  return (
    <div className="flex flex-wrap border-animation items-end">
      {data?.map((formField) => {
        if (formField?.type === 'container') {
          return (
            <div className={`${formField.customClass}`}>
              {formField?.formFields?.map((x) => {
                return fieldData(x);
              })}
            </div>
          );
        }
        return fieldData(formField);
      })}
    </div>
  );
};

export default DynamicForm;
