import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const borrowerProfileAPI = createApi({
  reducerPath: 'borrower-profile',
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  tagTypes: ['checkProfileCompletion', 'getApplication', 'getHousingExpense'],
  endpoints: (builder) => ({
    updateStatus: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `/loan/application/status_admin/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),

    getBorrowerProfile: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `/loan/dashboard/borrower_profile/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['getApplication'],
    }),

    updatePersonalDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `/loan/dashboard/personal_details/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),

    updateLoanDetails: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `loan/dashboard/loan/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['checkProfileCompletion'],
    }),

    updateEmployeeAndIncome: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/employment_and_income`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    updateAssets: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/assets`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    updateLiabilities: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/liabilities`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    updateREOSDetails: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/reos`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    updateDeclaration: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `loan/dashboard/declaration/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),

    updateDemographics: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `loan/dashboard/demographics/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
    }),

    createPersonalDetails: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/personal_details`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),

    createLoanDetails: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/loan`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['checkProfileCompletion'],
    }),

    createEmploymentAndIncome: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/employment_and_income`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    createAssetDetails: builder.mutation({
      query: (data) => {
        return {
          url: `loan/dashboard/assets`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    createLiabilities: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/liabilities`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    createREOSData: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/reos`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    createDeclaration: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/declaration`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),

    createDemographics: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/dashboard/demographics`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
    }),
    checkProfileCompletion: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `loan/application/loan_info/complete/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['checkProfileCompletion'],
    }),
    //delete existing dynamic employee
    deleteEmploymentDetails: builder.mutation({
      query: ({ employmentIncomeId, userId }) => {
        return {
          url: `loan/dashboard/employment_and_income/${employmentIncomeId}?user_id=${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //delete existing dynamic liability
    deleteLiabilitiesDetails: builder.mutation({
      query: ({ userLiabilityId, userId }) => {
        return {
          url: `loan/dashboard/liabilities/${userLiabilityId}?user_id=${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //delete existing dynamic REO
    deleteReosDetails: builder.mutation({
      query: ({ reosId, userId }) => {
        return {
          url: `loan/dashboard/reos/${reosId}?user_id=${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //delete existing dynamic Assets
    deleteAssetsDetails: builder.mutation({
      query: ({ assetId, userId }) => {
        return {
          url: `loan/dashboard/assets/${assetId}?user_id=${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    getBorrowerProfileAdmin: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `/loan/loa/${applicationId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['getApplication'],
    }),

    updateEmployeeAndIncomeAdmin: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/loa/employment_and_income`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    updateAssetsAdmin: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/loa/assets`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    updateLiabilitiesAdmin: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/loa/liabilities`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    updateREOSDetailsAdmin: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/loa/reos`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    createEmploymentAndIncomeAdmin: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/loa/employment_and_income`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    createAssetDetailsAdmin: builder.mutation({
      query: (data) => {
        return {
          url: `loan/loa/assets`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    createLiabilitiesAdmin: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/loa/liabilities`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    createREOSDataAdmin: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/loa/reos`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),

    //delete existing dynamic employee
    deleteEmploymentDetailsAdmin: builder.mutation({
      query: ({ employmentIncomeId, userId }) => {
        return {
          url: `loan/loa/employment_and_income/${employmentIncomeId}?user_id=${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //delete existing dynamic liability
    deleteLiabilitiesDetailsAdmin: builder.mutation({
      query: ({ userLiabilityId, userId }) => {
        return {
          url: `loan/loa/liabilities/${userLiabilityId}?user_id=${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //delete existing dynamic REO
    deleteReosDetailsAdmin: builder.mutation({
      query: ({ reosId, userId }) => {
        return {
          url: `loan/loa/reos/${reosId}?user_id=${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    //delete existing dynamic Assets
    deleteAssetsDetailsAdmin: builder.mutation({
      query: ({ assetId, userId }) => {
        return {
          url: `loan/loa/assets/${assetId}?user_id=${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getApplication'],
    }),
    getHousingExpense: builder.query({
      query: ({ userId }) => {
        return {
          url: `loan/dashboard/housing_expense/${userId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['getHousingExpense'],
    }),
    getHousingExpenseOfficer: builder.query({
      query: ({ userId }) => {
        return {
          url: `loan/loa/housing_expense/${userId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['getHousingExpense'],
    }),

    saveHousingExpense: builder.mutation({
      query: ({ data, userId }) => {
        return {
          url: `loan/dashboard/housing_expense/${userId}`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getHousingExpense'],
    }),

    saveHousingExpenseOfficer: builder.mutation({
      query: ({ data, userId }) => {
        return {
          url: `loan/loa/housing_expense/${userId}`,
          method: 'POST',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['getHousingExpense'],
    }),

    getTransactionDetailsOfficer: builder.query({
      query: ({ userId }) => {
        return {
          url: `loan/dashboard/transaction_details/${userId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    getTransactionDetails: builder.query({
      query: ({ userId }) => {
        return {
          url: `loan/loa/transaction_details/${userId}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
  }),
});

export const {
  useCheckProfileCompletionQuery,
  useUpdateStatusMutation,
  //get api export LO
  useGetBorrowerProfileQuery,
  //update api export LO
  useUpdatePersonalDetailsMutation,
  useUpdateLoanDetailsMutation,
  useUpdateEmployeeAndIncomeMutation,
  useUpdateAssetsMutation,
  useUpdateLiabilitiesMutation,
  useUpdateREOSDetailsMutation,
  useUpdateDeclarationMutation,
  useUpdateDemographicsMutation,
  //create api export LO
  useCreatePersonalDetailsMutation,
  useCreateLoanDetailsMutation,
  useCreateAssetDetailsMutation,
  useCreateEmploymentAndIncomeMutation,
  useCreateREOSDataMutation,
  useCreateLiabilitiesMutation,
  useCreateDeclarationMutation,
  useCreateDemographicsMutation,
  //delete api export LO
  useDeleteEmploymentDetailsMutation,
  useDeleteLiabilitiesDetailsMutation,
  useDeleteReosDetailsMutation,
  useDeleteAssetsDetailsMutation,
  //get api export LOA
  useGetBorrowerProfileAdminQuery,
  //create api export LOA
  useCreateEmploymentAndIncomeAdminMutation,
  useCreateAssetDetailsAdminMutation,
  useCreateLiabilitiesAdminMutation,
  useCreateREOSDataAdminMutation,
  //update api export LOA
  useUpdateEmployeeAndIncomeAdminMutation,
  useUpdateAssetsAdminMutation,
  useUpdateLiabilitiesAdminMutation,
  useUpdateREOSDetailsAdminMutation,
  //delete api export LOA
  useDeleteEmploymentDetailsAdminMutation,
  useDeleteAssetsDetailsAdminMutation,
  useDeleteLiabilitiesDetailsAdminMutation,
  useDeleteReosDetailsAdminMutation,
  //Housing expense api export
  useGetHousingExpenseQuery,
  useGetHousingExpenseOfficerQuery,
  useSaveHousingExpenseMutation,
  useSaveHousingExpenseOfficerMutation,
  //transaction details api export
  useGetTransactionDetailsOfficerQuery,
  useGetTransactionDetailsQuery,
} = borrowerProfileAPI;
