/* istanbul ignore file */
const oneClickTableData = [
  {
    ausResult: 'Result',
    // desktopUnderwriting: 'Approve/ Eligible',
    // loanProductAdvisor: 'Accept/ Eligible',
  },
  {
    ausResult: 'Appraisal Waiver Eligible',
    // desktopUnderwriting: 'No',
    // loanProductAdvisor: 'No',
  },
  {
    ausResult: 'Months of Assets Required',
    // desktopUnderwriting: '2 Months',
    // loanProductAdvisor: '1 Months',
  },
  {
    ausResult: 'Years of TAX Returns Required For Self-Employment',
    // desktopUnderwriting: 'None Required',
    // loanProductAdvisor: 'None Required',
  },
  {
    ausResult: 'Reserves Required',
    // desktopUnderwriting: 'None Required',
    // loanProductAdvisor: 'None Required',
  },
  {
    ausResult: 'Trac Eligible',
    // desktopUnderwriting: 'No Eligible',
    // loanProductAdvisor: 'No Eligible',
  },
  {
    ausResult: 'Links',
    link: true,
    // desktopUnderwriting: 'View DU Findings Rebecca Hardison/ Credit Report',
    // loanProductAdvisor: 'View LPA Findings / Credit Reports',
  },
];
export default oneClickTableData;
