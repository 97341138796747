import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const pipelineAPI = createApi({
  reducerPath: 'pipeline',
  tagTypes: ['updatePipeline'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    getPipeline: builder.query({
      query: () => {
        return {
          url: `loan/pipeline/applications`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['updatePipeline'],
    }),
    getPipelineStatus: builder.query({
      query: () => {
        return {
          url: `loan/application_dropdowns/application_status`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    updatePipelineStatus: builder.mutation({
      query: ({ applicationId, data }) => {
        return {
          url: `loan/application/status/${applicationId}`,
          method: 'PUT',
          body: data,
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['updatePipeline'],
    }),
  }),
});

export const {
  useGetPipelineQuery,
  useGetPipelineStatusQuery,
  useUpdatePipelineStatusMutation,
} = pipelineAPI;
