import { createSlice } from '@reduxjs/toolkit';

const userRoleSlice = createSlice({
  name: 'userRole',
  initialState: {
    userRole: '',
  },
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

export const { setUserRole } = userRoleSlice.actions;

export const userRoleData = (state) => state.userRole;
export default userRoleSlice.reducer;
