/* istanbul ignore file */
export const statusVariantMap = {
  12: 'primary',
  8: 'primary',
  1: 'primary',
  2: 'secondary',
  3: 'secondary',
  6: 'secondary',
  9: 'secondary',
  10: 'secondary',
  4: 'tertiary',
  7: 'tertiary',
  11: 'tertiary',
};
