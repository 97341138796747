/* istanbul ignore file */
import { withValidations } from 'pages/profile/utils';
const compareOfferData = [
  {
    id: 1,
    name: 'Original Loan Amount',
    type: 'number',
    placeholder: 'Enter Original Loan Amount',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'originalLoanAmount',
  },
  {
    id: 2,
    name: 'Interest Rate',
    type: 'number',
    placeholder: 'Enter Interest Rate',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'interestRate',
  },
  {
    id: 3,
    name: 'Loan Start Date',
    type: 'date',
    placeholder: 'Enter Date',
    //customClass: 'w-1/4',
    isRequired: true,
    propertyName: 'loanStartDate',
    customClass: 'w-[20%] ml-2 mr-5 my-2',
  },
  {
    id: 4,
    name: 'No. of Payments',
    type: 'number',
    placeholder: 'Enter No. of Payments',
    customClass: 'w-1/4',
    isRequired: false,
    propertyName: 'noofPayments',
  },
  {
    id: 5,
    name: 'Mortgage Insurance (Monthly)',
    type: 'number',
    placeholder: 'Enter Mortgage Insurance',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'mortgageInsurance',
  },
  {
    id: 6,
    name: 'Original Loan Terms (In Years)',
    type: 'number',
    placeholder: 'Enter Original Loan Terms',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'originalLoanTerms',
  },
  {
    id: 7,
    name: 'Loan Program',
    type: 'select',
    placeholder: 'Select Loan Program',
    customClass: 'w-2/4',
    isRequired: true,
    propertyName: 'loanProgram',
    options: [
      {
        id: 1,
        name: 'Porgram 1',
      },
      {
        id: 2,
        name: 'Program 2',
      },
    ],
  },
];
const compareOffer = withValidations(compareOfferData);
export default compareOffer;
