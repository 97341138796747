import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chatbot',
  initialState: {
    chatMessage: '',
  },
  reducers: {
    setChatMessage: (state, action) => {
      state.chatMessage = action.payload;
    },
  },
});

export const { setChatMessage } = chatSlice.actions;

export const chatbotData = (state) => state.chatMessage;
export default chatSlice.reducer;
