import { v4 as uuidv4 } from 'uuid';

export const mixBothData = (loanCostTypeIds) => {
  const mixData = (costTypes) => {
    const combinedArray = [];

    // Combine objects from both arrays based on their indices
    for (let i = 0; i < costTypes.length && i < costTypes.length; i++) {
      const combinedObj = {
        ...costTypes[i],
        uniqueId: costTypes[i]?.uniqueId ?? uuidv4(),
      };

      // Recursively mix data for children if they exist
      if (costTypes[i].children) {
        combinedObj.children = mixData(costTypes[i].children);
      }

      combinedArray.push(combinedObj);
    }

    return combinedArray;
  };

  return mixData(loanCostTypeIds);
};

export const initializeInputValues = (array) => {
  const inputValues = {};

  const traverseAndInitialize = (obj) => {
    if (obj.uniqueId) {
      if (
        obj.borrowerPaidAtClosingValue !== undefined ||
        obj.borrowerPaidBeforeClosingValue !== undefined ||
        obj.otherPaidAtClosingValue !== undefined ||
        obj.otherPaidBeforeClosingValue !== undefined ||
        obj.type === 'custom'
      ) {
        inputValues[obj.uniqueId] = {
          borrowerPaidAtClosingValue: obj.borrowerPaidAtClosingValue || 0,
          borrowerPaidBeforeClosingValue:
            obj.borrowerPaidBeforeClosingValue || 0,
          otherPaidAtClosingValue: obj.otherPaidAtClosingValue || 0,
          otherPaidBeforeClosingValue: obj.otherPaidBeforeClosingValue || 0,
          name: obj.name || '',
          creditType: obj.creditType || 1,
          percentageValue: obj.percentageValue || '',
          numberOfDays: obj.numberOfDays || 0,
          estimatedClosing: obj.estimatedClosing || null,
          firstPayment: obj.firstPayment || null,
          credit: obj.credit || false,
        };
      } else {
        inputValues[obj.uniqueId] = {
          borrowerPaidValue: obj.borrowerPaidValue || 0,
          otherPaidValue: obj.otherPaidValue || 0,
          credit: obj.credit || false,
        };
      }
    }

    // Recursively initialize for children
    if (obj.children) {
      obj.children.forEach((child) => traverseAndInitialize(child));
    }
  };

  // Start traversing from the top level
  array.forEach((obj) => traverseAndInitialize(obj));

  return inputValues;
};

export const updateCombinedArray = (array, inputValues) => {
  const roundToTwoDecimals = (value) => {
    if (typeof value === 'number') {
      return Number(value.toFixed(3));
    }
    if (typeof value === 'string') {
      const parsed = parseFloat(value);
      return isNaN(parsed) ? value : Number(parsed.toFixed(3));
    }
    return value;
  };

  const traverseAndUpdate = (obj) => {
    if (obj.uniqueId && inputValues[obj.uniqueId]) {
      if (
        inputValues[obj.uniqueId].borrowerPaidAtClosingValue !== undefined ||
        inputValues[obj.uniqueId].borrowerPaidBeforeClosingValue !==
          undefined ||
        inputValues[obj.uniqueId].otherPaidAtClosingValue !== undefined ||
        inputValues[obj.uniqueId].otherPaidBeforeClosingValue !== undefined ||
        obj.type === 'custom'
      ) {
        obj.borrowerPaidAtClosingValue = roundToTwoDecimals(
          inputValues[obj.uniqueId].borrowerPaidAtClosingValue
        );
        obj.borrowerPaidBeforeClosingValue = roundToTwoDecimals(
          inputValues[obj.uniqueId].borrowerPaidBeforeClosingValue
        );
        obj.otherPaidAtClosingValue = roundToTwoDecimals(
          inputValues[obj.uniqueId].otherPaidAtClosingValue
        );
        obj.otherPaidBeforeClosingValue = roundToTwoDecimals(
          inputValues[obj.uniqueId].otherPaidBeforeClosingValue
        );
        obj.creditType = inputValues[obj.uniqueId].credit
          ? 2
          : Number(inputValues[obj.uniqueId].creditType) || 1;
        obj.percentageValue = roundToTwoDecimals(
          inputValues[obj.uniqueId].percentageValue
        );
        obj.numberOfDays = Number(inputValues[obj.uniqueId].numberOfDays) || 0;
        obj.estimatedClosing =
          inputValues[obj.uniqueId].estimatedClosing || null;
        obj.firstPayment = inputValues[obj.uniqueId].firstPayment || null;
      }
      obj.name = inputValues[obj.uniqueId]?.name || obj.name;
    }

    // Recursively update children
    if (obj.children) {
      obj.children.forEach((child) => traverseAndUpdate(child));
    }
  };

  // Start traversing from the top level
  array.forEach((obj) => traverseAndUpdate(obj));

  return array;
};

export const addObjectToChildren = (array, uniqueId, newObject) => {
  const traverseAndAdd = (obj) => {
    if (obj.uniqueId === uniqueId) {
      if (!obj.children) {
        obj.children = [];
      }
      obj.children.push(newObject);
      return true; // Return true to indicate the object was found and updated
    }

    if (obj.children) {
      for (let child of obj.children) {
        if (traverseAndAdd(child)) {
          return true; // Stop further traversal once the object is found and updated
        }
      }
    }

    return false; // Return false if the object is not found
  };

  // Start traversing from the top level
  for (let obj of array) {
    if (traverseAndAdd(obj)) {
      break; // Stop traversal if the object was found and updated
    }
  }

  return array; // Return the updated array
};

export const removeObjectFromChildren = (
  array,
  parentUniqueId,
  childUniqueId
) => {
  const traverseAndRemove = (obj) => {
    if (obj.uniqueId === parentUniqueId) {
      if (obj.children) {
        obj.children = obj.children.filter(
          (child) => child.uniqueId !== childUniqueId
        );
      }
      return true; // Return true to indicate the parent object was found and updated
    }

    if (obj.children) {
      for (let child of obj.children) {
        if (traverseAndRemove(child)) {
          return true; // Stop further traversal once the parent object is found and updated
        }
      }
    }

    return false; // Return false if the parent object is not found
  };

  // Start traversing from the top level
  for (let obj of array) {
    if (traverseAndRemove(obj)) {
      break; // Stop traversal if the parent object was found and updated
    }
  }

  return array; // Return the updated array
};

export const sumPropertyValues = (
  array,
  inputValues,
  propertyName,
  parentUniqueIds = []
) => {
  let sum = 0;

  const traverseAndSum = (obj, shouldSum) => {
    if (shouldSum) {
      if (obj.uniqueId && inputValues[obj.uniqueId]) {
        const value = parseFloat(inputValues[obj.uniqueId][propertyName]);
        if (!isNaN(value)) {
          if (
            inputValues[obj.uniqueId].creditType === 2 ||
            inputValues[obj.uniqueId].creditType === '2' ||
            inputValues[obj.uniqueId].credit
          ) {
            sum -= value;
          } else {
            sum += value;
          }
        }
      }
    }

    if (obj.children) {
      obj.children.forEach((child) =>
        traverseAndSum(
          child,
          shouldSum || parentUniqueIds?.includes(obj.uniqueId)
        )
      );
    }
  };

  array.forEach((obj) => traverseAndSum(obj, parentUniqueIds?.length === 0));

  return parseFloat(sum.toFixed(3));
};

export const getAppropriateValue = (
  child,
  propertyName,
  combinedArray,
  tableCellValues
) => {
  if (child?.children?.length > 0) {
    return sumPropertyValues(combinedArray, tableCellValues, propertyName, [
      child?.uniqueId,
    ]);
  }

  return isNaN(parseFloat(tableCellValues[child?.uniqueId][propertyName]))
    ? '-'
    : Number(tableCellValues[child?.uniqueId].creditType) === 2 ||
        (tableCellValues[child?.uniqueId].credit &&
          tableCellValues[child?.uniqueId][propertyName] !== 0)
      ? `-$${tableCellValues[child?.uniqueId][propertyName].toFixed(3)}`
      : `$${tableCellValues[child?.uniqueId][propertyName].toFixed(3)}`;
};

// this func calls another func and returns sum with $ or return - if no value
export const calculateAndFormatSum = (
  data,
  tableCellValues,
  uniqueIds,
  ...properties
) => {
  // here we are the rest parameter syntax (...properties). This allows you to pass any number of property names when calling the function
  const sum = properties.reduce((acc, prop) => {
    return acc + sumPropertyValues(data, tableCellValues, prop, uniqueIds);
  }, 0);
  return sum === 0
    ? '-'
    : sum > 0
      ? `$${sum.toFixed(3)}`
      : `-$${Math.abs(sum.toFixed(3))}`;
};

// generate totals for each section
export const updateLoanCosts = (initialData, amountsToAdd) => {
  const amountDict = amountsToAdd?.reduce((acc, item) => {
    acc[item.loan_cost_type_id] = item.amount;
    return acc;
  }, {});

  const traverse = (item) => {
    if (Array.isArray(item)) {
      return item.map(traverse);
    } else if (typeof item === 'object' && item !== null) {
      const newItem = { ...item };
      if ('loanCostTypeId' in newItem && newItem.loanCostTypeId in amountDict) {
        newItem.borrowerPaidBeforeClosingValue = 0;
        newItem.borrowerPaidAtClosingValue = amountDict[newItem.loanCostTypeId];
        newItem.otherPaidAtClosingValue = 0;
        newItem.otherPaidBeforeClosingValue = 0;
      }
      for (let key in newItem) {
        newItem[key] = traverse(newItem[key]);
      }
      return newItem;
    }
    return item;
  };

  return traverse(initialData);
};

// generate fees_json data for download and send quote
export function restructureData(originalData) {
  function processSection(section) {
    let fields = [];
    let total = 0;

    function processChildren(children) {
      children.forEach((child) => {
        let value = Number(child.borrowerPaidAtClosingValue) || 0;

        if (child.creditType === 2) {
          value = -value;
        }

        total += value;

        fields.push({
          key: child.name,
          value: value,
        });

        if (child.children && child.children.length > 0) {
          processChildren(child.children);
        }
      });
    }

    processChildren(section.children);

    return {
      heading: section.name,
      total: Number(total.toFixed(3)), // Round to 2 decimal places
      fields: fields,
    };
  }

  return originalData.map(processSection);
}

// generate data for quotation update
export function calculateQuotationValues(data, formattedTotals) {
  // total for apr
  let totalAPR = 0;
  let hazardIns = 0;
  let tax = 0;

  function processObject(obj) {
    if (obj.isAPR === true) {
      totalAPR += Number(obj.borrowerPaidAtClosingValue) || 0;
    }

    if (obj.children && obj.children.length > 0) {
      obj.children.forEach(processObject);
    }
  }

  // Process all objects in the data array
  data.forEach(processObject);

  // other totals
  const lenderFees = Number(formattedTotals[0]?.total) || 0;

  // Calculate otherClosingCost (total of all - 1st object)
  let otherClosingCost = 0;
  for (let i = 1; i < formattedTotals?.length; i++) {
    otherClosingCost += Number(formattedTotals[i]?.total) || 0;
  }

  // Calculate estimatedCash (total of all)
  const estimatedCash = lenderFees + otherClosingCost;

  // Extract "Hazard Insurance Premium" from "G. Initial Escrow Payment at Closing"
  const escrowSection = formattedTotals.find(
    (section) => section.heading === 'G. Initial Escrow Payment at Closing'
  );

  if (escrowSection) {
    const hazardInsuranceField = escrowSection.fields.find(
      (field) => field.key === 'Hazard Insurance Premium'
    );
    if (hazardInsuranceField) {
      hazardIns = Number(hazardInsuranceField.value) / 12 || 0;
    }

    // Sum the values of the specified fields and divide by 12 for tax
    const taxFields = [
      'City Property Taxes',
      'County Property Taxes',
      'Village/Town/School Tax',
      'Municipality Utility District (MUD) Tax',
    ];

    let taxSum = 0;
    const seenKeys = new Set();

    escrowSection.fields.forEach((field) => {
      if (taxFields.includes(field.key) && !seenKeys.has(field.key)) {
        taxSum += Number(field.value) || 0;
        seenKeys.add(field.key);
      }
    });

    tax = taxSum / 12;
  }

  const result = {
    lenderFees,
    otherClosingCost,
    estimatedCash,
    totalAPR,
    hazardIns,
    tax,
  };

  return result;
}

export function validateCustomTypes(data) {
  function checkNode(node) {
    if (Array.isArray(node.children)) {
      for (const child of node.children) {
        if (checkNode(child)) {
          return true;
        }
      }
    }

    if (node?.type === 'custom') {
      if (
        node.name === '' &&
        (node.borrowerPaidBeforeClosingValue > 0 ||
          node.otherPaidAtClosingValue > 0 ||
          node.otherPaidBeforeClosingValue > 0 ||
          node.borrowerPaidAtClosingValue > 0)
      ) {
        return true;
      }
    }

    return false;
  }

  return data.some(checkNode);
}
