import React, { useState, useEffect } from 'react';
import { RxCross2 } from 'react-icons/rx';
import Button from 'components/button/index';
import Modal from 'components/modal/index';
//query
import { useAssignRatesMutation } from 'redux_store/services/admin/product-pricing';
import { customToast } from 'components/toast';

const ApplyProduct = ({ resetStates, productDetails, allProducts }) => {
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const [assignRates] = useAssignRatesMutation();
  const [assignedProduct, setAssignedProduct] = useState('');
  const [assignedRate, setAssignedRate] = useState('');

  useEffect(() => {
    if (productDetails?.length > 0 && allProducts) {
      const matchingProduct = allProducts?.find(
        (product) =>
          product?.product_id === productDetails[0].selectedRate.productId
      );

      const rate = assignedProduct?.more_rates?.find((rates) => {
        return rates.rate_id === productDetails[0].selectedRate.rateId;
      });
      setAssignedRate(rate);
      setAssignedProduct(matchingProduct);
    }
  }, [productDetails, assignedProduct]);

  const handleApply = async () => {
    // Transform the adjustments
    const transformedAdjustments = assignedProduct.adjustments.map((adj) => ({
      name: adj.factor,
      adjustment: adj.points,
      amount: adj.amount,
    }));

    const transformedData = {
      application_id: applicationId,
      product_id: assignedProduct?.product_id,
      rate_id: assignedRate?.rate_id,
      rate: assignedRate?.rate,
      APR: assignedRate?.APR,
      discount_points: assignedRate?.discount_point,
      broker_compensation: assignedProduct?.broker_compensation_percent,
      estimated_closing_cost: assignedProduct?.estimated_total_fees,
      funds_to_close: assignedProduct?.funds_to_close,
      PITI: assignedProduct?.PITI,
      DTI: 0,
      adjustments: transformedAdjustments,
    };

    try {
      const response = await assignRates({ data: transformedData }).unwrap();
      if (response?.status === 200) {
        customToast.success('Updated Rate Successfully');
        resetStates();
      }
    } catch (error) {
      customToast.error('An error occurred');
    }
  };

  const form = (
    <>
      <div className="w-[530px] bg-white h-fit self-center rounded-md flex flex-col justify-center p-3">
        <div className="h-fit w-[100%] flex flex-row justify-between border-b border-b-grey100">
          <div className="h-[100%] flex flex-col justify-end p-2">
            <h1 className="font-bold text-[18px]">
              {`Apply Product: ${assignedProduct?.product_name}`}
            </h1>
          </div>
          <div
            className="h-[100%] flex flex-col justify-end p-2 cursor-pointer"
            onClick={() => resetStates()}
          >
            <RxCross2 size={20} className="font-semibold self-center" />
          </div>
        </div>

        <div className="h-[80%] w-[100%] p-2 flex flex-col gap-1 ">
          <div className="grid grid-cols-3 gap-4 w-[100%]">
            <>
              <div>
                <label className={`p-xs-regular text-neutral700 mb-1`}>
                  Rate/ APR
                </label>
                <p className="p-xs-semibold">
                  {assignedRate?.rate}%/{assignedRate?.APR}%
                </p>
              </div>
              <div>
                <label className={`p-xs-regular text-neutral700 mb-1`}>
                  Discount Points
                </label>
                <p className="p-xs-semibold">{assignedRate?.discount_point}%</p>
              </div>
              <div>
                <label className={`p-xs-regular text-neutral700 mb-1`}>
                  Est. Closing Costs
                </label>
                <p className="p-xs-semibold">
                  $ {assignedProduct?.estimated_total_fees}
                </p>
              </div>
              <div>
                <label className={`p-xs-regular text-neutral700 mb-1`}>
                  Appx Funds to close
                </label>
                <p className="p-xs-semibold">
                  $ {assignedProduct?.funds_to_close}
                </p>
              </div>
              <div>
                <label className={`p-xs-regular text-neutral700 mb-1`}>
                  Total PITI
                </label>
                <p className="p-xs-semibold">$ {assignedProduct?.PITI}</p>
              </div>
            </>
          </div>

          <>
            <div className="h-fit w-[100%]">
              <table className="h-fit w-[100%] p-xs-regular">
                <tr className="h-fit w-[100%] text-secondary bg-[#E9EDFF]">
                  <th className="text-start p-3">Adjustment Name</th>
                  <th className="text-start p-3">Adjustment</th>
                  <th className="text-start p-3">Amount</th>
                </tr>
                {assignedProduct?.adjustments?.map((Titem, Tindex) => {
                  return (
                    <tr
                      key={Tindex}
                      className={`${Titem.focus ? 'font-bold' : 'font-normal'}`}
                    >
                      <td className="p-3 font-normal">{Titem?.factor}</td>
                      <td className="p-3 font-normal">{Titem?.points}</td>
                      <td className="p-3 font-normal">${Titem?.amount}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </>
        </div>

        <div className="h-fit w-[100%] flex flex-row justify-end p-2">
          <div className="h-[100%] flex flex-row justify-end gap-3">
            <Button
              variant="white_sm"
              onClick={() => resetStates()}
              type="button"
              // btnClassName="border border-disabled text-disabled rounded-md"
            >
              Cancel
            </Button>
            <Button variant="primary_sm" onClick={handleApply} type="button">
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  );
  return <Modal show={true} children={form} width="!w-[530px]" />;
};

export default ApplyProduct;
