import { api } from 'constants/constant';
import { getHeaders, getUserDetails } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//admin API'S
export const adminEmployeeAPI = createApi({
  reducerPath: 'adminEmployee',
  tagTypes: ['fetchAdminEmployees', 'adminEmployeeUpdate'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    //get admin - consumer data
    getEmployee: builder.query({
      query: ({ paging, searchFilter }) => {
        const { limit, offset } = paging;
        return {
          url: `loan/employee/?page_number=${offset}&page_size=${limit}&search=${searchFilter || ''}`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
      providesTags: ['fetchAdminEmployees'],
    }),
    getEmployeeType: builder.query({
      query: () => {
        return {
          url: `loan/application_dropdowns/employee_types`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
    resetPassword: builder.mutation({
      query: ({ user_id }) => {
        return {
          url: `loan/employee/reset_password?user_id=${user_id}`,
          method: 'POST',
          headers: getHeaders(),
          //body: data,
        };
      },
    }),
    updateEmployee: builder.mutation({
      query: ({ image, user_id, data }) => {
        const { token } = getUserDetails();
        const formData = new FormData();

        formData.append('file', image);
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        formData.append('employeeTypeId', data.employeeTypeId);
        formData.append('NMLS', data.NMLSID);
        formData.append('company', data.companyName);

        formData.delete('NMLSID');
        formData.delete('companyName');
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);

        return {
          url: `loan/employee/${user_id}`,
          method: 'PUT',
          headers: headers,
          body: formData,
        };
      },
      invalidatesTags: ['fetchAdminEmployees'],
    }),

    createEmployee: builder.mutation({
      query: ({ data, image }) => {
        const { token } = getUserDetails();
        const formData = new FormData();
        formData.append('file', image);
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        formData.append('employeeTypeId', data.employeeTypeId);
        formData.append('NMLS', data.NMLSID);
        formData.append('company', data.companyName);

        formData.delete('NMLSID');
        formData.delete('companyName');

        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        return {
          url: `loan/employee/`,
          method: 'POST',
          headers: headers,
          body: formData,
        };
      },
      invalidatesTags: ['fetchAdminEmployees'],
    }),

    deleteEmployee: builder.mutation({
      query: ({ userId }) => {
        return {
          url: `loan/employee/${userId}`,
          method: 'DELETE',
          headers: getHeaders(),
        };
      },
      invalidatesTags: ['fetchAdminEmployees'],
    }),
    // get state
    getStates: builder.query({
      query: () => {
        return {
          url: `/loan/application_dropdowns/states`,
          method: 'GET',
          headers: getHeaders(),
        };
      },
    }),
  }),
});

export const {
  useGetEmployeeQuery,
  useGetEmployeeTypeQuery,
  useResetPasswordMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useCreateEmployeeMutation,
  useGetStatesQuery,
} = adminEmployeeAPI;
