import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
/**
 * Modal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether the modal is visible or not.
 * @param {function} props.onClose - The function to be called when the modal is closed.
 * @param {ReactNode} props.children - The content of the modal.
 * @param {string} props.width - The width of the modal.
 * @returns {ReactNode} The rendered modal component.
 */
const Modal = ({ show, onClose, children, width }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose && onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Define animation variants
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
  };

  return (
    <>
      {/* Background overlay */}
      {show && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-50"
          onClick={onClose}
        ></div>
      )}

      {/* Main modal */}
      <motion.div
        ref={modalRef}
        id="default-modal"
        tabIndex="-1"
        aria-hidden={!show}
        className={`${
          show ? 'fixed' : 'hidden'
        } overflow-y-auto overflow-x-hidden top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={modalVariants}
        transition={{ duration: 0.3 }}
      >
        <div className={`relative p-4 w-full ${width} max-h-full`}>
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {children}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Modal;
