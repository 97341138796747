import React, { useEffect, useState } from 'react';
import checkmark from 'assets/images/circle-check.png';

// icons
import fundmoreai from 'assets/fundmoreai2.png'

const EmailVerified = () => {
  const [countdown, setCountdown] = useState(10); // Start with 10 seconds

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      handleRedirect();
    }
  }, [countdown]);

  const handleRedirect = () => {
    window.history.replaceState(null, null, window.location.origin);
    window.location.href = window.location.origin;
  };

  return (
    <div
      className="bg-cover w-[100vw] bg-center h-screen flex flex-col justify-center items-center"
      style={{ backgroundImage: 'url("/mainbg.png")' }}
    >
      <div className="w-[500px] h-[134px] bg-secondary100 flex flex-col justify-center items-center rounded-t-xl">
        <img
          src={fundmoreai}
          alt=""
          className="h-28"
          style={{ width: '250px', height: '65px' }}
        />
        <h3 className="text-neutral900 h4-m mt-2">
          Email Verified Successfully!
        </h3>
      </div>
      <div className="w-[500px] h-[230px] bg-white py-6  relative">
        <div className="text-center flex flex-col justify-center items-center mb-4 px-7">
          <img className="mb-8" src={checkmark} alt="" />
          <div className="font-medium leading-none">
            You will be redirected to the login page in {countdown} seconds.
            Thank you for onboarding FundMore.ai.
          </div>
          <div className="font-medium leading-none mt-10">
            Click below if the page does not redirect automatically.
          </div>
        </div>
      </div>
      <div
        onClick={handleRedirect}
        className="w-[500px] h-[80px] bg-primary500 hover:bg-primary600 flex flex-col justify-center items-center rounded-b-xl cursor-pointer"
      >
        <h3 className="p-md-bold mt-2 text-white">Proceed to Login &gt;</h3>
      </div>
    </div>
  );
};

export default EmailVerified;
