import react, { useEffect, useState } from 'react';
//componentes
import Modal from 'components/modal';
import H1 from 'components/h1-typography';
import closeIcon from 'assets/icons/closeIconSlim.svg';
import InputGroup from 'components/input-group';
import Button from 'components/button';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';

const confirmDeleteTab = ({
  setConfirmDeleteVisibility,
  tabName,
  selectedTab,
  setDeletedTabIndex,
  onDelete,
}) => {
  const [input, setInput] = useState('');
  const [isError, setIsError] = useState(false);

  const handleDelete = async () => {
    if (input !== 'Delete') {
      setIsError(true);
    } else {
      onDelete(selectedTab);
    }
  };
  const handleKeyPress = (event) => {
    if (!input || (input && input?.length < 0)) return false;
    if (event.key === 'Enter') {
      handleDelete();
    }
  };
  const view = (
    <>
      <div className="h-[100%] w-[100%] flex flex-col py-4 px-8">
        <div className="h-fit w-[100%] flex flex-row justify-between gap-3 mb-1">
          <div className="h-[100%] flex flex-col justify-end">
            <H1>{`Remove ${tabName}`}</H1>
          </div>
          <div className="h-[100%] flex flex-col justify-center py-2">
            <img
              src={closeIcon}
              alt=""
              className="self-center cursor-pointer h-[18px] w-[18px]"
              onClick={() => setConfirmDeleteVisibility(false)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-[12px] text-secondary600 font-normal">
            You are about to remove <b className=" text-[#5A5555]">{tabName}</b>
            , all the fields of the <b className=" text-[#5A5555]">{tabName}</b>{' '}
            will be removed and this action cannot be undone. Are you sure you
            want to continue?
          </p>
          <div className="flex flex-col gap-2">
            <p
              htmlFor="input"
              className="text-[12px]  text-[#5A5555] font-bold"
            >
              Delete Tab <b className="text-error-red">*</b>
            </p>
            <InputGroup
              size="sm_2"
              position="center"
              placeholder="Enter Delete to remove the Tab"
              value={input}
              isError={isError}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              onKeyDown={handleKeyPress}
            />
            {isError && (
              <span className="text-[12px] font-light -my-2 text-error-red">
                Incorrect input. Please check and try again.
              </span>
            )}
          </div>
        </div>
        <div className="h-fit w-[100%] flex flex-row gap-5 justify-end mt-8">
          <Button
            variant="tertiary"
            btnClassName="text-disabled font-normal"
            onClick={() => {
              setConfirmDeleteVisibility(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant={input.length > 0 ? 'danger' : 'disabled'}
            btnClassName="border-primary500 bg-chatbot_bg text-primary500 px-6"
            onClick={() => {
              input.length > 0 && handleDelete();
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  );
  return (
    <>
      <Modal
        children={view}
        show={true}
        width="min-w-[300px] w-[29vw] max-w-[400px]"
      />
    </>
  );
};

export default confirmDeleteTab;
