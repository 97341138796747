import Modal from 'components/modal';
import React, { useEffect, useState } from 'react';

//components
import Button from 'components/button';
import Loader from 'wrapper/loader';
import DynamicForm from 'pages/profile/DynamicForm';
//utils
import {
  createFormObject,
  formatData,
  checkValidations,
} from 'pages/profile/utils/index.js';
import { customToast } from 'components/toast/index.jsx';
//icons
import closeIcon from 'assets/icons/closeIcon.svg';
import greenDot from 'assets/icons/greenDot.svg';
import successIcon from 'assets/icons/successIcon.svg';
import linkIcon from 'assets/icons/linkIcon.svg';
import redDot from 'assets/icons/redDot.svg';
//rtk query
import {
  useResetPasswordMutation,
  useUpdateConsumerMutation,
  useGetStatesQuery,
} from '../../../redux/services/admin/adminConsumer';
import { useGetLoanOfficersQuery } from 'redux_store/services/admin/loanInformation';
import * as Sentry from '@sentry/browser';

//form states
import userInfoFormFields from './userInfoFields.js';
import userDetailsFormFields from './userDetailsFields.js';

/**
 * Function: UserSummary
 *
 * Description: Renders a modal with user summary details based on selected row data.
 *
 * @param {Object} editProfile - Boolean flag to control the visibility of the modal.
 * @param {Function} setEditProfile - Function to set the state of editProfile.
 * @param {Object} selectedRowData - Data of the selected row containing user details.

 *
 * @returns {JSX.Element} A modal displaying user summary details.
 */
const EditProfile = ({ editProfile, setEditProfile, selectedRowData }) => {
  const [editProfileFlag, setEditProfileFlag] = useState(false);
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const formattedDate = () => {
    const dateString = selectedRowData?.user_info?.createdAt;
    if (!dateString) return 'NA';
    const date = formatter.format(Date.parse(blankSpaceChecker(dateString)));
    return date;
  };

  //form states
  const [userInfoForm, setUserInfoForm] = useState({
    ...createFormObject(userInfoFormFields),
    editProfileFlag: false,
  });
  const [userDetailsForm, setUserDetailsForm] = useState({
    ...createFormObject(userDetailsFormFields),
    editProfileFlag: false,
  });
  const [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [linkCopiedFlag, setLinkCopiedFlag] = useState(false);
  const [userInfoErrors, setUserInfoErrors] = useState({});
  const [userDetailsErrors, setUserDetailsErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

  // send reset link
  const [handleResetPasswordAPI, { isLoading: resetLinkLoading }] =
    useResetPasswordMutation();
  //update consumer
  const [handleUpdateConsumerAPI, { isLoading: updateConsumerLoading }] =
    useUpdateConsumerMutation();
  //get all loan officers
  const { data: loanOfficerData } = useGetLoanOfficersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: stateData } = useGetStatesQuery();
  // dropdown for form fields
  const dropDownLoanOfficersData = loanOfficerData?.data?.users?.map((item) => {
    //the condition here needs to be removed when all the LO have lender Id
    return {
      lenderId: item.userId,
      name: item.firstName + ' ' + item.lastName,
    };
  });
  const dropdowns = {
    loanOfficer: formatData(dropDownLoanOfficersData, 'lenderId'),
    stateName: formatData(stateData?.data, 'stateId'),
  };
  const blankSpaceChecker = (value) => {
    if (value === '' || value === ' ' || !value || value.length === 0)
      return null;

    return value;
  };
  const updateFormDataOnLoad = () => {
    setUserInfoForm({
      ...userInfoForm,
      email: blankSpaceChecker(selectedRowData?.user_info?.email) ?? null,
      phoneNumber:
        blankSpaceChecker(selectedRowData?.user_info?.phoneNumber) ?? null,
      mortgageBrokerName:
        blankSpaceChecker(selectedRowData?.user_info?.mortgageBrokerName) ??
        null,
      loanOfficer: {
        id:
          blankSpaceChecker(selectedRowData?.loan_officer_info?.userId) ?? null,
      },
      editProfileFlag: false,
    });
    setUserDetailsForm({
      ...userInfoForm,
      address: blankSpaceChecker(selectedRowData?.user_info?.address) ?? null,
      cityName: blankSpaceChecker(selectedRowData?.user_info?.cityName) ?? null,
      stateName: {
        id: blankSpaceChecker(selectedRowData?.user_info?.stateId) ?? null,
      },
      zipCode: blankSpaceChecker(selectedRowData?.user_info?.zipCode) ?? null,
      editProfileFlag: false,
    });
  };
  useEffect(() => {
    updateFormDataOnLoad();
  }, []);

  const handleResetPassword = async () => {
    try {
      let res = await handleResetPasswordAPI({
        email: selectedRowData?.user_info?.email,
      });
      if (res.data.success) {
        setPasswordResetLinkSent(true);
        setRedirectLink(res?.data?.data?.url);
        customToast.success(res.data.message);
      }
    } catch (error) {
      setPasswordResetLinkSent(false);
      customToast.error('Try again Later!');
      Sentry.captureException(error);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const userInfoErrorsTemp = checkValidations(
        userInfoFormFields,
        userInfoForm
      );
      const userDetailsErrorsTemp = checkValidations(
        userDetailsFormFields,
        userDetailsForm
      );

      if (
        Object.keys(userInfoErrorsTemp)?.length > 0 ||
        Object.keys(userDetailsErrorsTemp)?.length > 0
      ) {
        setUserInfoErrors(userInfoErrorsTemp);
        setUserDetailsErrors(userDetailsErrorsTemp);
        setIsFormSubmitted(true);
      } else {
        setIsFormSubmitted(false);
        setUserInfoErrors({});
        setUserDetailsErrors({});

        let data = {
          email: userInfoForm?.email,
          phoneNumber: userInfoForm?.phoneNumber_Unformatted,
          mortgageBrokerName: userInfoForm.mortgageBrokerName,
          lenderId: userInfoForm.loanOfficer.lenderId,
          address: userDetailsForm?.address,
          cityName: userDetailsForm?.cityName,
          stateName: userDetailsForm?.stateName?.name,
          stateId: userDetailsForm?.stateName?.id
            ? userDetailsForm?.stateName?.id
            : null,
          zipCode: userDetailsForm?.zipCode,
        };
        //console.log(data)
        let res = await handleUpdateConsumerAPI({
          data,
          userId: selectedRowData?.user_info?.userId,
        });
        if (res?.data?.success) {
          customToast.success(
            `${selectedRowData?.user_info.firstName + ' ' + selectedRowData?.user_info.lastName + "'s"} profile is updated successfully.`
          );
          setEditProfile(false);
        }
        if (res?.error?.status) {
          customToast.error(`${res?.error?.data?.message}`);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      customToast.error(`Try again later!`);
      //console.log("catched error", error)
    }
  };
  const statusMapper = () => {
    const statusId = selectedRowData?.user_info?.isActive;
    const statusObject = Number(statusId) === 1 ? true : false;
    const statusText = statusObject ? 'Active' : 'Inactive';
    if (statusObject) {
      return (
        <div className="h-fit w-fit flex flex-col justify-center bg-success_light rounded-md px-4">
          <p className="p-xs-regular py-[2px] text-success300 flex flex-row gap-2">
            <img src={greenDot} alt="" />
            {statusText}
          </p>
        </div>
      );
    } else {
      return (
        <div className="h-fit w-fit flex flex-col justify-center bg-error_light rounded-md px-4">
          <p className="p-xs-regular text-error300 flex flex-row gap-2">
            <img src={redDot} alt="" />
            {statusText}
          </p>
        </div>
      );
    }
  };
  const passwordResetView = (
    <>
      <div className="h-fit w-[100%] flex flex-col justify-start p-3">
        <div className="h-fit w-[100%] flex flex-row justify-end p-3 ">
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => {
              setPasswordResetLinkSent(false);
              setEditProfile(false);
            }}
          />
        </div>
        <div className="h-fit flex flex-col justify-center m-10">
          <div className="h-fit w-[100%] self-center flex flex-col justify-center">
            <img
              src={successIcon}
              alt=""
              className="self-center h-[50px] w-[50px]"
            />
            <h1 className="font-bold text-lg self-center">
              Password Reset Email Sent
            </h1>
            <p className="self-center text-center font-light text-[12px] text-secondary900 ">
              An email is sent to the consumer to Reset password. You can also
              copy the link and share to the user
            </p>
          </div>
        </div>
        <div className="h-fit w-[100%] flex flex-row justify-center mb-10">
          <Button
            variant={'primary'}
            btnClassName="border-primary500 bg-chatbot_bg text-primary500 px-6 rounded-md"
            onClick={() => {
              const link = redirectLink;
              navigator.clipboard
                .writeText(link)
                .then(() => {
                  setLinkCopiedFlag(true);
                  customToast.success('Link Copied');
                  setTimeout(() => {
                    setLinkCopiedFlag(false);
                  }, 3000);
                })
                .catch((err) => {
                  setLinkCopiedFlag(false);
                  customToast.error('Failed to copy link');
                });
            }}
          >
            <div className="flex flex-row justify-between gap-2">
              <img
                src={linkIcon}
                alt=""
                className="self-center h-[20px] w-[20px]"
              />
              {linkCopiedFlag ? (
                <>
                  Link Copied
                  <div className="flex flex-col justify-center">
                    <img
                      src={successIcon}
                      className="h-[15px] w-[15px]"
                      alt=""
                    />
                  </div>
                </>
              ) : (
                'Copy Link'
              )}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
  const editProfileView = (
    <>
      <div className="p-3 flex flex-col gap-3">
        <div
          className={`flex justify-between bg-chatbot_bg items-start p-2 rounded`}
        >
          <div className="h-[100%] w-fit flex flex-row gap-3">
            <div className="h-[100%] w-fit py-2 flex flex-col justify-center">
              <div className="rounded-full h-[30px] w-[30px] bg-[#C7B9DA]"></div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <h2 className="font-medium-bold w-fit">
                {selectedRowData?.user_info?.firstName +
                  ' ' +
                  selectedRowData?.user_info?.lastName}
              </h2>
              {statusMapper()}
              <p className="p-xs-regular text-secondary600 w-fit">
                {`Id- ${selectedRowData?.user_info?.userId}`}
              </p>
              <p className="p-xs-regular text-secondary400 w-fit">
                {`Created on - ${formattedDate()}`}
              </p>
            </div>
          </div>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-3"
            onClick={() => {
              setEditProfile(false);
            }}
          />
        </div>
        <div className="h-fit w-[100%] p-3 border border-secondary200 rounded-md">
          <DynamicForm
            key={2}
            data={userInfoFormFields}
            formData={userInfoForm}
            setFormData={setUserInfoForm}
            errors={userInfoErrors}
            isSubmitted={isFormSubmitted}
            readOnly={!editProfileFlag}
            dropdowns={dropdowns}
          />
        </div>
        <div className="h-fit w-[100%] p-3 border border-secondary200 rounded-md">
          <DynamicForm
            data={userDetailsFormFields}
            formData={userDetailsForm}
            setFormData={setUserDetailsForm}
            errors={userDetailsErrors}
            isSubmitted={isFormSubmitted}
            readOnly={!editProfileFlag}
            dropdowns={dropdowns}
          />
        </div>
        <div className="h-fit w-[100%] flex flex-row justify-end p-2">
          <div className="h-[100%] w-fit flex flex-row justify-end gap-3">
            {editProfileFlag ? (
              <Button
                variant="tertiary"
                onClick={() => {
                  setEditProfileFlag(false);
                  setUserInfoErrors({});
                  setUserDetailsErrors({});
                  setIsFormSubmitted(false);
                  updateFormDataOnLoad();
                }}
                btnClassName="p-xs-regular text-secondary900"
                type="button"
              >
                Cancel
              </Button>
            ) : (
              <Button
                variant="tertiary"
                onClick={handleResetPassword}
                type="button"
                btnClassName="border hover:bg-primary100 border-primary500 text-disabled rounded-md p-xs-regular text-primary500"
              >
                {resetLinkLoading ? (
                  <>
                    <div className="h-[100%] -mb-1 w-[100%] min-w-[100px] flex flex-col justify-center">
                      <Loader loaderText="Sending" FullScreen={false} />
                    </div>
                  </>
                ) : (
                  'Send Reset Password'
                )}
              </Button>
            )}
            {editProfileFlag ? (
              <Button
                variant="primary"
                onClick={handleUpdateProfile}
                btnClassName=""
                type="button"
              >
                {updateConsumerLoading ? (
                  <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                    <Loader loaderText="Updating" FullScreen={false} />
                  </div>
                ) : (
                  'Update'
                )}
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  setEditProfileFlag(true);
                  setUserInfoForm((prev) => ({
                    ...prev,
                    editProfileFlag: true,
                  }));
                  setUserDetailsForm((prev) => ({
                    ...prev,
                    editProfileFlag: true,
                  }));
                }}
                btnClassName="p-xs-bold"
                type="button"
              >
                Edit Profile
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
  return (
    <Modal
      show={editProfile}
      width="max-w-[550px]"
      children={passwordResetLinkSent ? passwordResetView : editProfileView}
    />
  );
};

export default EditProfile;
