// React and Components
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
// components
import Button from 'components/button/index.jsx';
import DynamicForm from 'pages/profile/DynamicForm.jsx';
import productPricing from '../fees-worksheet/formData/ProductPricing.js';
import { customToast } from 'components/toast/index.jsx';
import Loader from 'wrapper/loader/index.jsx';

//utils
import {
  checkValidations,
  createFormObject,
  formatData,
} from 'pages/profile/utils/index.js';
//logs
import * as Sentry from '@sentry/browser';
//query
import {
  useGetCommitmentPeriodQuery,
  useGetCompensationPayerTypeQuery,
  useGetEscrowQuery,
  useGetFirstTimeHomebuyerQuery,
  useGetFlexTermQuery,
  useGetLoanPurposeQuery,
  useGetLoanTermsQuery,
  useGetLoanTypeQuery,
  useGetOccupancyQuery,
  useGetProcessingOptionQuery,
  useGetPropertyTypeQuery,
  useGetRefinancePurposeQuery,
  useGetPricingDetailsQuery,
  useGenerateQuoteMutation,
  useGetBuydownTypeQuery,
  useGetCountryOptionQuery,
  useGetRateVendorsQuery,
  useGetStatesQuery,
} from 'redux_store/services/admin/product-pricing.js';
import {
  useGetLoanCostTypesQuery,
  useGetUserFeesWorksheetQuery,
  useSaveUserFeesWorksheetMutation,
  useUpdateUserFeesWorksheetMutation,
  useGetLoanAndRateQuery,
  useGetLoadstarDataQuery,
} from 'redux_store/services/admin/feesworksheet';

//fees data
import {
  addObjectToChildren,
  getAppropriateValue,
  initializeInputValues,
  mixBothData,
  removeObjectFromChildren,
  sumPropertyValues,
  updateCombinedArray,
  calculateAndFormatSum,
  updateLoanCosts,
} from 'pages/borrower-profile/fees-worksheet/formData/feesWorksheet';
import { useGetBorrowerProfileQuery } from 'redux_store/services/borrower-profile';
//utils
import { v4 as uuidv4 } from 'uuid';
import { replaceEmptyStringWithNull } from 'utils/stringToNull.js';
const ProductPricing = ({
  setComponentName,
  setUpdateChildPath,
  setComponentQuote = () => {},
}) => {
  // States
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');
  const [formData, setFormData] = useState({
    ...createFormObject(productPricing),
  });
  const [errors, setErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [filteredVendorData, setFilteredVendorData] = useState();
  const [isTexas, setIsTexas] = useState(false);
  //dynamic fees
  const [combinedData, setCombinedData] = useState([]);
  const [loadstarData, setLoadstarData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  //dropdown queries
  const { data: commitmentPeriod } = useGetCommitmentPeriodQuery();
  const { data: compensationPayerType } = useGetCompensationPayerTypeQuery();
  const { data: escrow } = useGetEscrowQuery();
  const { data: firstTimeHomebuyer } = useGetFirstTimeHomebuyerQuery();
  const { data: flexTerm } = useGetFlexTermQuery();
  const { data: loanPurpose } = useGetLoanPurposeQuery();
  const { data: loanTerms } = useGetLoanTermsQuery();
  const { data: loanType } = useGetLoanTypeQuery();
  const { data: occupancy } = useGetOccupancyQuery();
  const { data: processingOption } = useGetProcessingOptionQuery();
  const { data: propertyType } = useGetPropertyTypeQuery();
  const { data: refinancePurpose } = useGetRefinancePurposeQuery();
  const { data: buydownType } = useGetBuydownTypeQuery();
  const { data: countryType } = useGetCountryOptionQuery();
  const { data: stateType } = useGetStatesQuery();
  const { data: vendorsData, error: vendorError } = useGetRateVendorsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: pricingDetails, isFetching } = useGetPricingDetailsQuery(
    { applicationId: applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: applicationData } = useGetBorrowerProfileQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  //fees worksheet
  const { data: feesWorksheetData, error: errorMessage } =
    useGetUserFeesWorksheetQuery(
      {
        application_Id: applicationId,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const { data: loanCostTypes } = useGetLoanCostTypesQuery();
  const { data: loadstarAPIData, isError } = useGetLoadstarDataQuery(
    {
      applicationId: applicationId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: amountAndRate } = useGetLoanAndRateQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [saveFeesData, { isLoading: isSaveFeesDataLoading }] =
    useSaveUserFeesWorksheetMutation();

  useEffect(() => {
    if (amountAndRate?.status === 200 && stateType?.data) {
      const stateId = amountAndRate?.data?.state_id;
      const matchingState = stateType?.data?.find(
        (state) => state.stateId === stateId
      );

      if (matchingState?.code === 'TX') {
        setIsTexas(true);
      } else {
        setIsTexas(false);
      }
    }
  }, [amountAndRate, stateType]);

  // 3rd party fees
  useEffect(() => {
    setLoadstarData(loadstarAPIData?.data || []);
    setVendorData(vendorsData?.data[0]?.defaultFeesJson || []);
  }, [loadstarAPIData?.data, vendorsData?.data[0].defaultFeesJson]);

  useEffect(() => {
    if (errorMessage?.status === 404 && loanCostTypes?.data) {
      const combineBothData = mixBothData(loanCostTypes?.data ?? []);
      let customInputData = combineBothData.map((data) => ({
        ...data,
        children:
          data.children?.map((child) => ({
            ...child,
            chargeDropdown: child.chargeDropdown ?? true, // Set chargeDropdown to true if it doesn't exist
          })) || [], // Fallback to an empty array if children are undefined
      }));
      combineBothData.forEach((data) => {
        if (data?.children[data?.children?.length - 1]?.name) {
          customInputData = addObjectToChildren(
            customInputData,
            data?.uniqueId,
            {
              uniqueId: uuidv4(),
              parentId: 0,
              name: '',
              isCollapsible: false,
              children: [],
              type: 'custom',
              borrowerPaidAtClosingValue: 0,
              borrowerPaidBeforeClosingValue: 0,
              otherPaidAtClosingValue: 0,
              otherPaidBeforeClosingValue: 0,
              creditType: 1,
              chargeDropdown: true,
            }
          );
        }
      });

      setCombinedData(customInputData);
    }
  }, [loanCostTypes?.data, feesWorksheetData, errorMessage]);

  useEffect(() => {
    if (loadstarData.length && vendorData.length) {
      const combinedData = [...loadstarData, ...vendorData];

      const mergedDataResult = combinedData.reduce((acc, current) => {
        const existing = acc.find(
          (item) => item.loan_cost_type_id === current.loan_cost_type_id
        );
        if (existing) {
          existing.amount += current.amount;
        } else {
          acc.push({
            loan_cost_type_id: current.loan_cost_type_id,
            amount: current.amount,
          });
        }
        return acc;
      }, []);

      if (isTexas && amountAndRate?.status === 200 && stateType?.data) {
        setMergedData(mergedDataResult);
      } else if (amountAndRate?.status === 200 && stateType?.data) {
        const modifiedData = mergedDataResult?.map((cost) =>
          cost.loan_cost_type_id === 19 ? { ...cost, amount: 0 } : cost
        );
        setMergedData(modifiedData);
      }
    }
  }, [loadstarData, vendorData, isTexas, amountAndRate, stateType]);

  const getTotalAmount = (costs) => {
    return costs.reduce((total, cost) => total + cost.amount, 0);
  };

  useEffect(() => {
    if (
      errorMessage?.status === 404 &&
      mergedData.length > 0 &&
      loadstarAPIData?.data
    ) {
      const finalData = updateLoanCosts(combinedData, mergedData);
      saveFeesData({
        data: {
          userId: applicationData?.data?.user_profile?.userId,
          userProfileId: applicationData?.data?.user_profile?.userProfileId,
          fees_json: finalData,
          cash_from_borrower: getTotalAmount(mergedData),
        },
        applicationId,
      });
    }
  }, [mergedData, errorMessage, loadstarAPIData?.data]);

  useEffect(() => {
    if (vendorsData?.success) {
      const filteredVendorData = vendorsData?.data?.map((item) => {
        return { lenderTypeId: item.vendorId, name: item.vendorName };
      });
      /* filteredVendorData[1] = {
        lenderTypeId: 3,
        name: 'Provident Funding',
        isdisabled: true,
      };
      filteredVendorData[2] = {
        lenderTypeId: 4,
        name: 'Rocket Mortgage',
        isdisabled: true,
      }; */

      setFilteredVendorData([filteredVendorData[0]]);
    }
  }, [vendorsData]);
  //generate quote
  const [generateQuote, { isLoading, isSuccess, error: errorData }] =
    useGenerateQuoteMutation();

  //map dropdowns with its id's
  const dropdowns = {
    lenderType: formatData(filteredVendorData, 'lenderTypeId'),
    loanType: formatData(loanType?.data, 'loanTypeId'),
    purposeType: formatData(loanPurpose?.data, 'loanPurposeId'),
    refinancePurposeType: formatData(
      refinancePurpose?.data,
      'refinancePurposeId'
    ),
    occupancyType: formatData(occupancy?.data, 'occupancyTypeId'),
    propertyType: formatData(propertyType?.data, 'propertyTypeId'),
    commitmentPeriodType: formatData(
      commitmentPeriod?.data,
      'commitmentPeriodId'
    ),
    compensationPayerType: formatData(
      compensationPayerType?.data,
      'compensationPayerTypeId'
    ),
    escrowImpoundWaiverType: formatData(escrow?.data, 'escrowWaiverTypeId'),
    loanTermsType: formatData(loanTerms?.data, 'loanTermId'),
    flexTermsType: formatData(flexTerm?.data, 'flexTermId'),
    firstTimeHomebuyerType: formatData(
      firstTimeHomebuyer?.data,
      'firstTimeHomeBuyerOptionId'
    ),
    processingOptionType: formatData(
      processingOption?.data,
      'processingOptionId'
    ),
    buydownType: formatData(buydownType?.data, 'buyDownTypeId'),
    countryType: formatData(countryType?.data, 'countryId'),
    propertyState: formatData(stateType?.data, 'stateId'),
  };
  // update formdata (auto populate)
  useLayoutEffect(() => {
    setIsFormSubmitted(false);
    if (pricingDetails?.data) {
      const productData = pricingDetails?.data;
      setFormData({
        correspondent:
          productData.isCorrespondent === '0' || !productData.isCorrespondent
            ? false
            : true,
        companyName: productData?.companyName,
        loanOfficer: productData.loanOfficer,
        borrowerName: productData?.borrowerName,
        loanType: { id: productData?.loanTypeId },
        buydownType: { id: productData?.buyDownTypeId ?? 5 },
        loanAmount: productData?.loanAmount,
        secondLoanAmount: productData?.secondLoanAmount ?? 0,
        salesPrice: productData?.salesPrice,
        appraisedValue: productData?.appraisedValue,
        ltv: productData?.LTV,
        cltv: productData?.CLTV,
        purposeType: { id: productData?.loanPurposeId },
        refinancePurposeType: { id: productData?.refinancePurposeId },
        occupancyType: { id: productData?.occupancyTypeId },
        propertyType: { id: productData?.propertyTypeId },
        noOfUnits: productData?.numberOfUnits,
        propertyState: { id: productData?.propertyStateId },
        zipCode: productData?.zipCode,
        countryType: { id: productData?.countryId },
        financedProperties: productData?.financedProperties,
        creditScore: productData?.creditScore,
        county: productData?.county,
        monthlyIncome: productData?.monthlyIncome || 0,
        dtiOver45:
          productData?.isHigherDTI === '0' || !productData?.isHigherDTI
            ? false
            : true,
        newConstruction:
          productData?.isNewConstruction === '0' ||
          !productData?.isNewConstruction
            ? false
            : true,
        selfEmployed:
          productData?.isSelfEmployed === '0' || !productData?.isSelfEmployed
            ? false
            : true,
        commitmentPeriodType: { id: productData?.commitmentPeriodId },
        compensationPayerType: { id: productData?.compensationPayerTypeId },
        escrowImpoundWaiverType: { id: productData?.escrowWaiverTypeId },
        loanTermsType: { id: productData?.loanTermId },
        flexTermsType: { id: productData?.flexTermId },
        firstTimeHomebuyerType: { id: productData?.firstTimeHomeBuyerOptionId },
        processingOptionType: { id: productData?.processingOptionId },
        lenderType: { id: productData?.vendorId ?? null },
      });
    } else {
      setFormData({
        ...createFormObject(productPricing),
      });
    }
  }, [pricingDetails]);

  // formdata calculations and conditional rendering of certain fields
  useEffect(() => {
    if (formData.loanAmount > 0 && formData.appraisedValue > 0) {
      setFormData((prev) => {
        const loanAmount = parseFloat(formData.loanAmount);
        const appraisedValue = parseFloat(formData.appraisedValue);
        const calculation = (loanAmount / appraisedValue) * 100;
        return { ...prev, ltv: parseFloat(calculation).toFixed(3) };
      });
    }
    if (formData.secondLoanAmount === 0 || !formData.secondLoanAmount) {
      setFormData((prev) => {
        return { ...prev, cltv: formData.ltv };
      });
    } else {
      setFormData((prev) => {
        const loanAmountPlusSecondLoanAmount =
          parseFloat(formData.loanAmount) +
          parseFloat(formData.secondLoanAmount);
        const appraisedValue = parseFloat(formData.appraisedValue);
        const calculation =
          (loanAmountPlusSecondLoanAmount / appraisedValue) * 100;
        return { ...prev, cltv: parseFloat(calculation).toFixed(3) };
      });
    }
    if (formData?.purposeType?.id === 1) {
      setFormData((formData) => ({
        ...formData,
        appraisedValue: formData.salesPrice,
      }));
    }
  }, [
    formData.loanAmount,
    formData.appraisedValue,
    formData.secondLoanAmount,
    formData.salesPrice,
    formData?.purposeType,
    formData?.ltv,
  ]);

  // formdata calculations and conditional rendering of certain fieldsS
  useEffect(() => {
    if (formData.correspondent) {
      setFormData((formData) => ({
        ...formData,
        compensationPayerType: { id: 1 },
      }));
    }
  }, [formData.correspondent]);

  // form submission to generate quotation
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (isError) {
      return customToast.error(
        'Please fill loan information details in your profile'
      );
    }
    const errors = checkValidations(productPricing, formData);
    if (Object.keys(errors)?.length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
    } else {
      setIsFormSubmitted(false);
      setErrors({});
      try {
        let data = {
          isCorrespondent: formData?.isCorrespondent ?? false,
          vendorId: formData.lenderType.id,
          companyName: formData?.companyName,
          loanOfficer: formData?.loanOfficer,
          borrowerName: formData?.borrowerName,
          loanTypeId: formData?.loanType?.id,
          buyDownTypeId: formData?.buydownType?.buyDownTypeId,
          loanAmount: formData?.loanAmount,
          secondLoanAmount: formData?.secondLoanAmount,
          salesPrice: formData?.salesPrice,
          appraisedValue: formData?.appraisedValue,
          LTV: formData?.ltv,
          CLTV: formData?.cltv,
          loanPurposeId: formData?.purposeType?.id,
          refinancePurposeId: formData?.refinancePurposeType?.id,
          occupancyTypeId: formData?.occupancyType?.id,
          propertyTypeId: formData?.propertyType?.id,
          numberOfUnits: formData?.noOfUnits,
          propertyState: formData?.propertyState?.name,
          propertyStateId: formData?.propertyState?.id,
          zipCode: formData?.zipCode,
          countryId: formData?.countryType?.id,
          county: formData?.county,
          financedProperties: formData?.financedProperties,
          creditScore: formData?.creditScore,
          monthlyIncome: formData?.monthlyIncome || 0,
          isHigherDTI: formData?.dtiOver45,
          isNewConstruction: formData?.newConstruction,
          isSelfEmployed: formData?.selfEmployed,
          commitmentPeriodId: formData?.commitmentPeriodType?.id,
          compensationPayerTypeId: formData?.compensationPayerType?.id,
          escrowWaiverTypeId: formData?.escrowImpoundWaiverType?.id,
          loanTermId: formData?.loanTermsType?.id,
          flexTermId: formData?.flexTermsType?.id,
          firstTimeHomeBuyerOptionId: formData?.firstTimeHomebuyerType?.id,
          processingOptionId: formData?.processingOptionType?.id,
          applicationId: applicationId,
          vendorId: String(formData.lenderType.id),
        };
        const resp = await generateQuote({
          data: replaceEmptyStringWithNull(data),
        }).unwrap();

        if (resp?.status === 200) {
          setUpdateChildPath(true);
          setComponentName('Mortage Quote');
          setComponentQuote(resp?.data);
          customToast.success(resp?.message);
        }
      } catch (error) {
        customToast.error(error?.data?.message);
        Sentry.captureException(error);
      } finally {
      }
    }
  };

  const manageScenarios = async (e) => {
    e.preventDefault();

    if (isError) {
      return customToast.error(
        'Please fill loan information details in your profile'
      );
    }
    const errors = checkValidations(productPricing, formData);
    if (Object.keys(errors)?.length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
    } else {
      setIsFormSubmitted(false);
      setErrors({});
      try {
        let data = {
          isCorrespondent: formData?.isCorrespondent ?? false,
          vendorId: formData.lenderType.id,
          companyName: formData?.companyName,
          loanOfficer: formData?.loanOfficer,
          borrowerName: formData?.borrowerName,
          loanTypeId: formData?.loanType?.id,
          buyDownTypeId: formData?.buydownType?.buyDownTypeId,
          loanAmount: formData?.loanAmount,
          secondLoanAmount: formData?.secondLoanAmount,
          salesPrice: formData?.salesPrice,
          appraisedValue: formData?.appraisedValue,
          LTV: formData?.ltv,
          CLTV: formData?.cltv,
          loanPurposeId: formData?.purposeType?.id,
          refinancePurposeId: formData?.refinancePurposeType?.id,
          occupancyTypeId: formData?.occupancyType?.id,
          propertyTypeId: formData?.propertyType?.id,
          numberOfUnits: formData?.noOfUnits,
          propertyState: formData?.propertyState?.name,
          propertyStateId: formData?.propertyState?.id,
          zipCode: formData?.zipCode,
          countryId: formData?.countryType?.id,
          county: formData?.county,
          financedProperties: formData?.financedProperties,
          creditScore: formData?.creditScore,
          monthlyIncome: formData?.monthlyIncome || 0,
          isHigherDTI: formData?.dtiOver45,
          isNewConstruction: formData?.newConstruction,
          isSelfEmployed: formData?.selfEmployed,
          commitmentPeriodId: formData?.commitmentPeriodType?.id,
          compensationPayerTypeId: formData?.compensationPayerType?.id,
          escrowWaiverTypeId: formData?.escrowImpoundWaiverType?.id,
          loanTermId: formData?.loanTermsType?.id,
          flexTermId: formData?.flexTermsType?.id,
          firstTimeHomeBuyerOptionId: formData?.firstTimeHomebuyerType?.id,
          processingOptionId: formData?.processingOptionType?.id,
          applicationId: applicationId,
          vendorId: String(formData.lenderType.id),
        };
        setUpdateChildPath(true);
        setComponentName('Manage Scenarios');
        setComponentQuote(replaceEmptyStringWithNull(data));
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  return (
    <div className="h-[76vh] w-[100%] flex flex-col">
      {isFetching ? (
        <>
          {' '}
          <Loader loaderText="" FullScreen={true} />
        </>
      ) : (
        <>
          <div className="h-[100%] w-[100%] overflow-y-scroll hiddenScroll my-3">
            <form
            //</div>onSubmit={onSubmitHandler}
            >
              <motion.div
                initial={{
                  opacity: 0,
                  //scale: 0,
                }}
                animate={{
                  opacity: 1,
                  // scale: 1
                }}
              >
                <DynamicForm
                  data={productPricing}
                  formData={formData}
                  setFormData={setFormData}
                  dropdowns={dropdowns}
                  errors={errors}
                  isSubmitted={isFormSubmitted}
                  //additionInfo={applicationData?.data}
                />
              </motion.div>
            </form>
          </div>
          <div className="h-fit flex flex-row justify-end gap-5">
            <Button
              variant="white_sm"
              // onClick={() => {
              //   setComponentName('Mortage Quote');
              // }}

              onClick={onSubmitHandler}
              type="button"
              btnClassName={`hover:border hover:border-primary500 hover:text-primary ${isLoading ? 'cursor-not-allowed opacity-50' : '-'}`}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                  <Loader loaderText="Generating" FullScreen={false} />
                </div>
              ) : (
                'Get Quote'
              )}
            </Button>
            {/* <Button
          variant="tertiary"
          onClick={() => {
            alert('clicked');
          }}
          type="button"
          btnClassName="border border-disabled text-disabled"
        >
          Reset Form
        </Button> */}
            <Button
              variant="primary_sm"
              onClick={manageScenarios}
              type="button"
              // disabled={true}
            >
              Manage Scenarios
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductPricing;
