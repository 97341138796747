export const scaleMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    duration: 0.3,
    ease: 'easeInOut',
  },
};

export const scaleMotionProps2 = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    duration: 0.6,
    ease: 'easeInOut',
  },
};

export const scaleMotionProps3 = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0 },
  transition: {
    type: 'spring',
    duration: 0.5,
    stiffness: 300,
    damping: 90,
    ease: 'easeInOut',
  },
};
