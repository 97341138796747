import react from 'react';
//icons
import lockIcon from 'assets/icons/lockIcon.svg';
import user from 'assets/icons/user.svg';
import mobileIcon from 'assets/icons/mobileIcon.svg';
import InputGroup from 'components/input-group';

const Consumer = ({
  setConsumerState,
  consumerState,
  isFormSubmitted,
  validationData,
  handleMobileNumberValue,
  onKeyPressPhoneNumber,
}) => {
  const onChangeData = (e) => {
    const { name, value } = e.target;

    // Regex to allow only capital letters, small letters, and spaces
    const nameRegex = /^[A-Za-z\s]*$/;

    if (name === 'firstName' || name === 'lastName') {
      if (nameRegex.test(value) || value === '') {
        setConsumerState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setConsumerState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const isValid = (propertyName, validationData, data) => {
    const validationFuncs = validationData[propertyName];
    let isFound = false;
    let message = '';
    validationFuncs.forEach((validationFunc) => {
      if (!isFound) {
        const resp = validationFunc(data, propertyName);
        if (!resp?.isValid) {
          isFound = true;
          message = resp?.hint;
        }
      }
    });
    return { isValid: !isFound, message };
  };

  return (
    <>
      <div className="w-[78%] text-center self-center flex flex-col justify-center py-8 gap-6 ">
        <div className="flex flex-col gap-2">
          <div
            className={`flex flex-row border rounded-md border-secondary100 overflow-hidden ${
              isFormSubmitted &&
              !isValid('firstName', validationData, consumerState)?.isValid
                ? 'border-error'
                : 'border-neutral100'
            } `}
          >
            <div className=" flex flex-col justify-center">
              <img src={user} alt="user" />
            </div>
            <input
              type="text"
              className={`px-2 font-medium placeholder:font-medium w-[100%] relative outline-none`}
              onChange={onChangeData}
              value={consumerState?.firstName || ''}
              name={'firstName'}
              placeholder="First Name"
            />
          </div>
          {isFormSubmitted &&
            !isValid('firstName', validationData, consumerState)?.isValid && (
              <p className="text-error text-left p-xs-med p-0.5">
                {isValid('firstName', validationData, consumerState)?.message}
              </p>
            )}
        </div>
        <div className="flex flex-col gap-2">
          <div
            className={`flex flex-row border rounded-md border-secondary100 overflow-hidden ${
              isFormSubmitted &&
              !isValid('lastName', validationData, consumerState)?.isValid
                ? 'border-error'
                : 'border-neutral100'
            } `}
          >
            <div className=" flex flex-col justify-center">
              <img src={user} alt="" />
            </div>
            <input
              type="text"
              className={`px-2 font-medium placeholder:font-medium w-[100%] relative outline-none`}
              onChange={onChangeData}
              value={consumerState?.lastName || ''}
              name={'lastName'}
              placeholder="Last Name"
            />
          </div>
          {isFormSubmitted &&
            !isValid('lastName', validationData, consumerState)?.isValid && (
              <p className="text-error text-left p-xs-med p-0.5">
                {isValid('lastName', validationData, consumerState)?.message}
              </p>
            )}
        </div>
        <div className="flex flex-col gap-2">
          <div
            className={`flex flex-row border rounded-md border-secondary100 overflow-hidden ${
              isFormSubmitted &&
              !isValid('phoneNumber', validationData, consumerState)?.isValid
                ? 'border-error'
                : 'border-neutral100'
            } `}
          >
            <div className=" flex flex-col justify-center">
              <img src={mobileIcon} alt="" />
            </div>
            <input
              type="text"
              className={`px-2 font-medium placeholder:font-medium w-[100%] relative outline-none`}
              onChange={onChangeData}
              onKeyDown={onKeyPressPhoneNumber}
              value={handleMobileNumberValue(consumerState?.phoneNumber)}
              name={'phoneNumber'}
              placeholder="Mobile Number"
            />
          </div>
          {isFormSubmitted &&
            !isValid('phoneNumber', validationData, consumerState)?.isValid && (
              <p className="text-error text-left p-xs-med p-0.5">
                {isValid('phoneNumber', validationData, consumerState)?.message}
              </p>
            )}
        </div>
      </div>
    </>
  );
};

export default Consumer;
