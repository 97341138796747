import React, { useEffect, useState, useRef } from 'react';
//components
import Modal from 'components/modal';
import Button from 'components/button';
import Loader from '../../../../wrapper-component/loader';

//icons
import closeIcon from 'assets/icons/closeIcon.svg';
import tickIcon from 'assets/icons/tickIcon.svg';
import copyIcon from 'assets/icons/copyIcon.svg';
import search from 'assets/icons/search.svg';
import successIcon from 'assets/icons/successIcon.svg';

//libs
import Select, { components } from 'react-select';

//utils
import {
  createFormObject,
  checkValidations,
  removeEmptyStrings,
} from 'pages/profile/utils';
import DynamicForm from 'pages/profile/DynamicForm';
import { getInitials } from 'constants/getFirstChar';
import { leadsFormData } from './formFields';
import * as Sentry from '@sentry/browser';

//colors
import { bgColors, textColors, borderColors } from '../../../../themes/colors';

//query
import {
  useAddLeadsMutation,
  useGetLoanOfficersQuery,
} from 'redux_store/services/officer/loanInformation';
import { customToast } from 'components/toast';
import { getUserDetails } from 'auth/auth';

const AddLeads = ({ leadsPopup, setLeadsPopup }) => {
  const { userId } = getUserDetails();
  //states
  const [leadsInput, setLeadsInput] = useState(createFormObject(leadsFormData));
  const [errors, setErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [loanError, setLoanError] = useState(false);
  const [showCopyLink, setShowCopyLink] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [options, setOptions] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [linkCopiedFlag, setLinkCopiedFlag] = useState(false);

  const DropdownIndicator = (props) => {
    return selectedAction ? null : <components.DropdownIndicator {...props} />;
  };

  const [addLeads, { isLoading: addLeadLoading, isError }] =
    useAddLeadsMutation();
  const {
    data: loanOfficerData,
    isLoading: loanOfficerLoading,
    refetch: loanOfficerRefetch,
    isError: loanOfficerError,
  } = useGetLoanOfficersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  //submit function
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    //checks for validation
    if (!selectedAction) {
      setLoanError(true);
    }
    const leadsErrors = checkValidations(leadsFormData, leadsInput);
    if (Object.keys(leadsErrors)?.length > 0) {
      setErrors({
        ...leadsErrors,
      });
      setIsFormSubmitted(true);
    } else if (Object.keys(leadsErrors)?.length === 0 && selectedAction) {
      setLoanError(false);
      setErrors({});
      try {
        let data = {
          ...leadsInput,
        };
        data = removeEmptyStrings(data);
        data = {
          firstName: data?.firstName ?? '',
          lastName: data?.lastName ?? '',
          email: data?.email ?? '',
          phoneNumber: data?.phoneNumber_Unformatted ?? '',
          loanPurposeId: data?.loanPurpose?.id ?? 0,
          employmentTypeId: data?.employmentType?.id ?? 0,
          loanOfficerId: selectedAction?.userId,
        };
        //make api call here
        const response = await addLeads({ data });
        if (response?.data?.status === 200) {
          setRedirectLink(response?.data?.data?.url);
          setShowCopyLink(true);
        } else if (response?.error?.data?.status === 400) {
          customToast.error(response?.error?.data?.message);
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (selectedAction) {
      setLoanError(false);
    }
  }, [selectedAction]);
  useEffect(() => {
    setOptions(loanOfficerData?.data?.users ?? []);
    if (loanOfficerData?.data?.users) {
      const filteredOfficer = loanOfficerData?.data?.users?.find(
        (data) => data.userId === userId
      );

      setSelectedAction(filteredOfficer);
    }
  }, [loanOfficerData?.data?.users]);

  return (
    <Modal show={leadsPopup} width="max-w-[530px]">
      {!showCopyLink && (
        <div className="p-5">
          <div className="flex justify-between items-start">
            <div className="p-sm-bold mb-4 w-[15rem]">Add lead</div>
            <img
              src={closeIcon}
              alt=""
              className="cursor-pointer h-3"
              onClick={() => {
                setLeadsPopup(false);
              }}
            />
          </div>
          <div className="border-t border-grey my-4"></div>
          <form onSubmit={onSubmitHandler}>
            <DynamicForm
              data={leadsFormData}
              formData={leadsInput}
              setFormData={setLeadsInput}
              errors={errors}
              isSubmitted={isFormSubmitted}
            />
            <div className="px-2">
              <p className={`p-xs-bold text-custom-greengray`}>
              Mortgage Broker <span className="text-error">*</span>
              </p>
              <Select
                value={selectedAction}
                isDisabled={true}
                options={options}
                isClearable
                getOptionLabel={(e) => (
                  <div className="flex items-center">
                    <div className="w-6 h-6 bg-profile rounded-full flex justify-center items-center mr-2">
                      {getInitials(e.firstName)}
                    </div>
                    <span className="mt-0.5 whitespace-nowrap truncate w-[14rem]">
                      {e.firstName} {e.lastName}
                    </span>
                  </div>
                )}
                components={{ DropdownIndicator }}
                getOptionValue={(e) => `${e.firstName} ${e.lastName}`}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    fontSize: '12px',
                    position: 'relative',
                    fontWeight: '400',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    paddingRight: '9px',
                    border: `2px solid ${loanError ? borderColors.errorColor : borderColors.grey}`,
                    '&:hover': {
                      cursor: 'pointer',
                      border: `2px solid ${loanError ? borderColors.errorColor : borderColors.grey}`,
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: bgColors.tertiary_bg,
                    color: textColors.black,
                    fontSize: '12px',
                    fontWeight: '400',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: bgColors.primary50,
                    },
                    '&:active': {
                      backgroundColor: bgColors.primary50,
                    },
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    borderRadius: '8px',
                    color: textColors.black,
                    fontSize: '12px',
                    fontWeight: '400',
                  }),
                  indicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral80: textColors.black,
                  },
                })}
                placeholder={
                  menuIsOpen ? (
                    <img src={search} alt="" />
                  ) : (
                    'Select Loan Officer'
                  )
                }
                className="w-full"
                noOptionsMessage={() => 'No Officer found'}
              />
              {loanError && (
                <span className="text-error-red text-xs font-normal pt-1">
                  Field cannot be empty
                </span>
              )}
            </div>
            <div className="border-t border-grey my-4"></div>
            <div className="flex justify-end gap-2 mt-12">
              <Button
                variant="tertiary"
                onClick={() => {
                  setLeadsPopup(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                // onClick={handleSubmit}
                // disabled={input ? false : true}
              >
                {addLeadLoading ? (
                  <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                    <Loader loaderText="Adding" FullScreen={false} />
                  </div>
                ) : (
                  'Add Lead'
                )}
              </Button>
            </div>
          </form>
        </div>
      )}
      {showCopyLink && (
        <div className="p-5">
          <div className="flex  justify-end items-start">
            <img
              src={closeIcon}
              alt=""
              className="cursor-pointer h-3"
              onClick={() => {
                setLeadsPopup(false);
              }}
            />
          </div>

          <div className="h-[316px] flex flex-col justify-center items-center gap-4">
            <img src={tickIcon} alt="tick" />
            <h3 className="p-sm-bold">Lead added Successfully</h3>
            <p className="p-xs-med text-center">
              An email is sent to the consumer to setup password and create
              profile. You can also copy the link and share to the consumer.
            </p>
            <Button
              variant="primary"
              type="submit"
              btnClassName="flex items-center gap-3 mt-6"
              onClick={() => {
                const link = redirectLink;
                navigator.clipboard
                  .writeText(link)
                  .then(() => {
                    setLinkCopiedFlag(true);
                    customToast.success('Link Copied');
                    setTimeout(() => {
                      setLinkCopiedFlag(false);
                    }, 3000);
                  })
                  .catch((err) => {
                    setLinkCopiedFlag(false);
                    customToast.error('Failed to copy link');
                  });
              }}
            >
              <img src={copyIcon} alt="" />
              {linkCopiedFlag ? (
                <>
                  Link Copied
                  <div className="flex flex-col justify-center">
                    <img
                      src={successIcon}
                      className="h-[15px] w-[15px]"
                      alt=""
                    />
                  </div>
                </>
              ) : (
                'Copy Link'
              )}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddLeads;
