import React, { useState, useEffect } from 'react';
//components
import Modal from 'components/modal';
import Button from 'components/button';
import InputGroup from 'components/input-group';
//assets
import closeIcon from 'assets/icons/closeIcon.svg';

const DeletePopup = ({
  deletePopup,
  setDeletePopup,
  data,
  onSubmitHandler,
}) => {
  const [input, setInput] = useState('');
  const [error, setError] = useState(false);

  // update error state
  useEffect(() => {
    if (input === 'Delete') {
      setError(false);
    }
  }, [input]);

  return (
    <Modal show={deletePopup} width="max-w-[392px]">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div className="p-sm-bold mb-4 w-[15rem]">Delete {data?.name}</div>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer h-3"
            onClick={() => {
              setDeletePopup(false);
            }}
          />
        </div>
        <p className="p-xs-regular mb-2">
          You are about to delete{' '}
          <span className="p-xs-bold">{data?.name} </span>. This data will no
          longer be available on the portal and you will have to enter it again.
        </p>
        <div>
          <label className={`p-xs-bold text-custom-greengray mb-1`}>
            Delete Item
            <span className="text-error pl-1">*</span>
          </label>
          <InputGroup
            onChange={(e) => {
              const filteredValue = e.target.value.replace(/[^a-zA-Z]/g, '');
              setInput(filteredValue);
            }}
            value={input}
            isError={error}
            size="sm"
            placeholder="Enter Delete to remove the item"
          />
          {error && (
            <p className="text-error-red text-xs font-normal pt-1 h-[24px]">
              Incorrect input. Please check and try again.
            </p>
          )}
        </div>
        <div className="flex justify-end gap-2 mt-12">
          <Button
            variant="tertiary"
            type="button"
            onClick={() => {
              setDeletePopup(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant={input ? 'danger' : 'disabled'}
            disabled={input ? false : true}
            type="button"
            onClick={() => {
              if (input !== 'Delete') {
                setError(true);
              } else {
                onSubmitHandler();
              }
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePopup;
