import React from 'react';

function Compare({ color = '#000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      fill="none"
      viewBox="0 0 18 15"
    >
      <path
        fill={color}
        d="M5 14.49l-.708-.713 3.095-3.094H0v-1h7.387L4.292 6.59 5 5.875l4.308 4.308L5 14.49zm8-5.365L8.692 4.818 13 .51l.708.713-3.095 3.095H18v1h-7.387l3.095 3.094-.708.713z"
      ></path>
    </svg>
  );
}

export default Compare;
