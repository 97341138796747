import React from 'react';
import Radio from 'components/radio/index.jsx';
import Button from 'components/button';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { scaleMotionProps2 } from 'constants/animationProps';
/**
 * Renders the Page1 component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.user - The user name.
 * @param {boolean} props.selectedOption - The selected option.
 * @param {function} props.setPage - The function to set the current page.
 * @param {function} props.setSelectedOption - The function to set the selected option.
 * @param {function} props.setAddress - The function to set the address.
 * @param {function} props.setSelectedOfficer - The function to set the selected officer.
 * @param {function} props.setSelectedOfficerObj - The function to set the selected officer object.
 * @param {function} props.setLoanPurpose - The function to set the loan purpose.
 * @param {function} props.setEmployment - The function to set the employment.
 * @param {Object} props.data - The data object.
 * @param {boolean} props.isLoading - Indicates if the data is loading.
 * @param {boolean} props.setDirection - The function to set the direction.
 * @param {boolean} props.direction - The direction.
 * @returns {JSX.Element} The rendered Page1 component.
 */
const Page1 = ({
  user,
  selectedOption,
  setPage,
  setSelectedOption,
  setAddress,
  setSelectedOfficer,
  setSelectedOfficerObj,
  setLoanPurpose,
  setEmployment,
  data,
  isLoading,
  setDirection,
  direction
}) => {
  const handleRadioChange = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
  };
  const clearData = () => {
    if (data?.isWorkingWithAnyLoanOfficer !== selectedOption) {
      setAddress('');
      setSelectedOfficer('');
      setSelectedOfficerObj(null);
      setLoanPurpose('');
      setEmployment('');
      setDirection(null);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <AnimatePresence mode="wait">
        <motion.div
          {...scaleMotionProps2}
          className="flex flex-col items-center"
        >
          <h2 className="h5-b text-neutral mb-3">Hello {user}!</h2>
          <p className="h2 text-secondary w-[32rem] mb-6 text-center">
            Are you working with any of our Mortgage Brokers?
          </p>
          <div
            className={`w-[250px] h-[44px] transition-colors duration-300 border-primary border rounded flex gap-6 items-center p-4 mb-4 cursor-pointer ${
              selectedOption ? 'primary100-bg' : ''
            }`}
            onClick={() => handleRadioChange(true)}
          >
            <Radio checked={selectedOption === true} name="option" />{' '}
            <span className="font-medium text-black">Yes</span>
          </div>
          <div
            className={`w-[250px] h-[44px] transition-colors duration-300  border-primary border rounded flex gap-6 items-center p-4 mb-4 cursor-pointer ${
              !selectedOption && selectedOption !== null ? 'primary100-bg' : ''
            }`}
            onClick={() => handleRadioChange(false)}
          >
            <Radio checked={selectedOption === false} name="option" />{' '}
            <span className="font-medium text-black">No</span>
          </div>
          <div className="flex gap-4 mt-6">
            <Button
              variant="disabled"
              onClick={() => {
                setDirection(false);
              }}
            >
              Back
            </Button>
            <Button
              variant={selectedOption === null ? 'disabled' : 'primary'}
              disabled={selectedOption === null}
              onClick={
                selectedOption
                  ? () => {
                      clearData();
                      setPage(3);
                      setAddress('');
                      setDirection(true);
                    }
                  : () => {
                      clearData();
                      setPage(2);
                      setSelectedOfficer('');
                      setDirection(true);
                    }
              }
            >
              {isLoading && direction  ? 'Please wait...' : 'Next'}
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Page1;
