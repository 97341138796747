import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
//components
import H1 from 'components/h1-typography';
import Table from 'components/table';
import Badge from 'components/badge';
import User from 'components/user';
import Loader from 'wrapper/loader';
//utils and constans
import { formatNumberIntoCurrency } from 'constants/formatNumber';
import { statusVariantMap } from 'pages/loan-information/constants';
//icons
import rightPointingArrowBlue from 'assets/icons/rightPointingArrowBlue.svg';
//children
import Insights from './Insights';
import { useNavigate } from 'react-router-dom';
//RTK query
import { useGetDashboardInformationQuery } from 'redux_store/services/admin/loanInformation';
import { useGetPipelineStatusQuery } from 'redux_store/services/client/pipeline';
const convertToTitleCase = (text) => {
  let formattedText = text.replace(/_/g, ' ');

  formattedText = formattedText.replace(/\b\w/g, (char) => char.toUpperCase());

  return formattedText;
};
const Dashboard = () => {
  const navigate = useNavigate();
  const [pipelineData, setPipelineData] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [pipelineStatusInfo, setPipelineStatusInfo] = useState();
  const [borrowerInsights, setBorrowerInsights] = useState();

  //fetch Dashboard data
  const { data: pipelineStatusData } = useGetPipelineStatusQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: allDashboardData,
    isLoading: allDashboardDataLoading,
    error: dashError,
  } = useGetDashboardInformationQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  useEffect(() => {
    if (allDashboardData?.success) {
      setPipelineData(allDashboardData.data.pipeline);
      setLeadsData(allDashboardData.data.new_leads);

      const convertToObjectArray = (obj) => {
        return Object.keys(obj).map((key) => ({ name: key, value: obj[key] }));
      };
      setPipelineStatusInfo(
        convertToObjectArray(allDashboardData.data.pipeline_status)
      );
      setBorrowerInsights(
        convertToObjectArray(allDashboardData?.data?.borrower_insights)
      );
    }
  }, [allDashboardData]);
  //handle Leads table row navigation
  const handleLeadsNavigation = (row) => {
    navigate(`/loanInformation/lead?applicationId=${row?.application_id}`, {
      state: {
        userId: row?.user_profile_id,
        applicationId: row?.applicationId,
        isEdit: false,
        page: 1,
      },
    });
  };
  //handle pipeline navigation
  const handlePipelineNavigation = (row) => {
    navigate(`/pipeline/lead?applicationId=${row?.application_id}`, {
      state: {
        //userId: row?.loan_application_id,
        applicationId: row?.loan_application_id,
        isEdit: false,
        page: 1,
      },
    });
  };
  const LenderSpreadInsight = [
    {
      name: 'UWM',
      value: 1,
    },
    {
      name: 'Flagstar',
      value: 0,
    },
    {
      name: 'HPF',
      value: 0,
    },
    {
      name: 'Others',
      value: 0,
    },
  ];
  const columns = [
    {
      header: 'Loan Application ID',
      accessorKey: 'user_info.applicationId',
      cell: ({ row }) => {
        return (
          <div className="font-semibold text-secondary700 px-3">
            {row?.original?.application_id ?? 'Not available'}
          </div>
        );
      },
    },
    {
      header: 'Borrower Name',
      accessorKey: 'user_info.firstName',
      cell: ({ row }) => {
        return (
          <>
            <div className="w-fit flex flex-row justify-start">
              <User
                size="sm"
                title={row?.original?.borrower_name ?? 'Not available'}
              />
            </div>
          </>
        );
      },
    },
    {
      header: 'Phone',
      accessorKey: 'user_info.phoneNumber',
      cell: ({ row }) => {
        return (
          <>
            <p className="p-xs-med text-secondary700">
              {row?.original?.phone_number ?? 'Not available'}
            </p>
          </>
        );
      },
    },
    {
      header: 'Status',
      accessorKey: 'statusId',
      cell: ({ row }) => {
        const statusId = row?.original?.application_status_id;
        const statusObject = pipelineStatusData?.data?.find(
          (data) => data.applicationStatusId === statusId
        );
        const statusText = statusObject ? statusObject.name : '';
        return (
          <div className="w-full flex flex-row justify-center">
            <Badge
              title={statusText}
              variant={statusVariantMap[statusId] || 'primary'}
            />
          </div>
        );
      },
    },
    {
      header: 'Loan Officer',
      accessorKey: 'loan_officer_info.firstName',
      cell: ({ row }) => {
        return (
          <>
            <User
              size="sm"
              title={row?.original?.loan_officer_name ?? 'Not available'}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      {allDashboardDataLoading ? (
        <Loader />
      ) : (
        <div className="h-[85vh] w-[100%]">
          <div className="h-[100%] w-[100%] flex flex-wrap gap-3 overflow-y-scroll p-3">
            <h1 className="font-bold text-lg">Dashboard</h1>
            <div className="flex flex-row gap-4 justify-between">
              <Insights
                title="Borrower Insights"
                key={12}
                data={borrowerInsights ?? []}
              />
              <Insights
                data={LenderSpreadInsight}
                title="Lender Spread"
                key={13}
              />
            </div>
            {
              //second section
            }
            <motion.div
              initial={{
                y: -50,
              }}
              animate={{
                x: 0,
                y: 0,
                scale: 1,
                rotate: 0,
              }}
              className="w-[100%] flex flex-row justify-center gap-3"
            >
              <div className="h-full w-[50%] flex flex-col gap-2 p-3 border border-secondary200 rounded-lg overflow-x-scroll">
                <H1 className="font-semibold">New Leads</H1>
                <table>
                  <tr className="w-[100%] bg-custom-primary400">
                    <td className="p-xs-bold text-secondary900 py-3 px-[10px] text-center">
                      Profile Id
                    </td>
                    <td className="p-xs-bold text-secondary900 py-3 px-[10px] text-center">
                      Borrower Name
                    </td>
                    <td className="p-xs-bold text-secondary900 py-3 px-[10px] text-center">
                      Loan Amount
                    </td>
                    <td className="p-xs-bold text-secondary900 py-3 px-[10px] text-center">
                      Status
                    </td>
                  </tr>
                  {leadsData?.length > 0 &&
                    leadsData?.map((item, index) => {
                      const statusId = item.application_status_id;
                      const statusObject = pipelineStatusData?.data?.find(
                        (data) => data.applicationStatusId === statusId
                      );
                      const statusText = statusObject ? statusObject.name : '';
                      return (
                        <>
                          <tr
                            className="w-[100%] cursor-pointer hover:bg-primary50"
                            key={index}
                            onClick={() => handleLeadsNavigation(item)}
                          >
                            <td className="p-xs-regular text-center text-secondary900 p-3">
                              {item?.application_id ?? 'Not available'}
                            </td>
                            <td className="p-xs-regular text-center text-secondary900 p-3">
                              <User
                                size="sm"
                                title={item.borrower_name ?? 'Not available'}
                              />
                            </td>
                            <td className="p-xs-regular text-center text-secondary900 p-3">
                              {formatNumberIntoCurrency(item?.loan_amount) ??
                                'Not available'}
                            </td>
                            <td className="p-xs-regular text-center text-secondary900 p-3">
                              <Badge
                                variant={
                                  statusVariantMap[statusId] || 'primary'
                                }
                                title={statusText}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </table>
                <div className="flex flex-row gap-2 cursor-pointer">
                  <h1
                    className="p-xs-bold my-1 self-start text-primary500 "
                    onClick={() => navigate('/loanInformation')}
                  >
                    View All Leads
                  </h1>
                  <div className="flex flex-col justify-center">
                    <img
                      src={rightPointingArrowBlue}
                      alt=""
                      className="h-[15px]"
                    />
                  </div>
                </div>
              </div>
              {
                //second section 2nd table
              }
              <div className="h-full w-[50%] flex flex-col gap-2 p-3 border border-secondary200 rounded-lg overflow-x-scroll">
                <H1 className="font-semibold">Pipeline Status</H1>
                <table>
                  <tr className="w-[100%] bg-custom-primary400">
                    <td className="p-xs-bold text-secondary900 py-3 w-[40%] px-3 text-start">
                      Process
                    </td>
                    <td className="p-xs-bold text-secondary900 py-3 px-[10px] text-center">
                      Total
                    </td>
                    <td className="p-xs-bold text-secondary900 py-3 px-[10px] text-center">
                      Amount
                    </td>
                  </tr>
                  {pipelineStatusInfo?.map((status, i) => (
                    <tr className="w-[100%] ">
                      <td className="p-xs-regular text-secondary900 py-3 w-[40%] px-3 text-start">
                        {convertToTitleCase(status.name)}
                      </td>

                      <td className="p-xs-regular text-secondary900 py-3 px-[10px] text-center">
                        {status.value.count ?? 'Not Available'}
                      </td>
                      <td className="p-xs-regular text-secondary900 py-3 px-[10px] text-center">
                        {status.value.loan_amount ?? 'Not Available'}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </motion.div>
            {
              //3rd section which included pipeline table
            }
            <div className="w-[100%] h-fit rounded-lg border border-secondary200 overflow-x-scroll p-3">
              <H1 className="font-semibold mb-2">Pipeline</H1>
              <Table
                data={pipelineData}
                columns={columns}
                errorMessage="No relevant data found"
                height="h-fit"
                onNavigate={handlePipelineNavigation}
              />
              <div className="flex flex-row gap-2 cursor-pointer">
                <h1
                  className="p-xs-bold my-1 self-start text-primary500 "
                  onClick={() => navigate('/pipeline')}
                >
                  View All Pipeline
                </h1>
                <div className="flex flex-col justify-center">
                  <img
                    src={rightPointingArrowBlue}
                    alt=""
                    className="h-[15px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
