import React, { useState } from 'react';

//components
import Button from 'components/button';
import TitleCard from 'components/title-card';

//icons
import SelfEmployed from 'assets/icons/SelfEmployed';
import Employed from 'assets/icons/Employed';
import Retired from 'assets/icons/Retired';

// framer animations
import { scaleMotionProps2 } from 'constants/animationProps';

//API queries
import {
  useCreateApplicationMutation,
  useGetUserInfoQuery,
} from 'redux_store/services/client/onboarding';

//libraries
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
/**
 * Page5 function handles the rendering and functionality of the fifth page of the onboarding process.
 *
 * @param {Object} props - The props object containing the necessary data and functions for the component.
 * @param {string} props.user - The user's name.
 * @param {function} props.setPage - The function to set the current page of the onboarding process.
 * @param {string} props.employment - The current employment status.
 * @param {function} props.setEmployment - The function to set the employment status.
 * @param {function} props.setIsOnboarding - The function to set the onboarding status.
 * @param {Object} props.dataForSubmission - The data for submission.
 * @param {function} props.saveOnboardingData - The function to save the onboarding data.
 * @param {boolean} props.isLoading - The loading status.
 * @param {function} props.setDirection - The function to set the direction.
 * @param {boolean} props.direction - The direction status.
 * @returns {JSX.Element} - The rendered component.
 */
const Page5 = ({
  user,
  setPage,
  employment,
  setEmployment,
  setIsOnboarding,
  dataForSubmission,
  saveOnboardingData,
  isLoading,
  setDirection,
  direction,
}) => {
  //router hook
  const navigate = useNavigate();
  //states
  const [hoverStates, setHoverStates] = useState({
    employed: false,
    self: false,
    retired: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  //fetch user profiles
  const { data: profileData } = useGetUserInfoQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  //for creating application id
  const [createApplication] = useCreateApplicationMutation();

  //handle employment type
  const handleEmploymentSelection = (type) => {
    setEmployment(type);
  };

  //handle hover state for icon
  const handleHover = (type, isHovering) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [type]: isHovering,
    }));
  };

  //save onboarding data and create application id if user profile exists, else just save onboarding data
  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setDirection(true);
    try {
      const data = {
        lenderId: dataForSubmission.lenderId,
        loanPurposeId: dataForSubmission.loanPurpose === 'refinance' ? 2 : 1,
      };
      //check if user profile exists
      if (profileData?.data?.user_profiles?.length === 0) {
        const response = await createApplication({ data });
        if (response?.data?.status === 200) {
          setIsOnboarding(false);
          saveOnboardingData();
          //navigate to /profile
          navigate('/profile');
          setIsSubmitting(false);
        }
      } else if (profileData?.data?.user_profiles?.length > 0) {
        setIsOnboarding(false);
        saveOnboardingData();
        //navigate to /profile
        navigate('/profile');
        setIsSubmitting(false);
        //handle error
      }
    } catch (error) {
      Sentry.captureException(error);
      // Handle any errors that occurred during the submission process
      console.error('Error submitting application:', error);
    }
  };

  return (
    <div className="flex flex-col  justify-center items-center h-full">
      <AnimatePresence mode="wait">
        <motion.div
          {...scaleMotionProps2}
          className="flex flex-col items-center"
        >
          <h2 className="h5-b text-neutral mb-3">Hello {user}!</h2>
          <p className="h2 text-secondary w-[32rem] mb-6 text-center">
            What is your current employment status?
          </p>
          <div className="flex gap-3">
            <TitleCard
              selected={employment === 'employed'}
              onClick={() => handleEmploymentSelection('employed')}
              iconComponent={
                employment !== 'employed' ? (
                  <Employed
                    variant={hoverStates.employed ? 'secondary' : 'primary'}
                  />
                ) : (
                  <Employed variant="secondary" />
                )
              }
              label="Employed"
              type="employed"
              onMouseEnter={() => handleHover('employed', true)}
              onMouseLeave={() => handleHover('employed', false)}
            />
            <TitleCard
              selected={employment === 'self'}
              onClick={() => handleEmploymentSelection('self')}
              iconComponent={
                employment !== 'self' ? (
                  <SelfEmployed
                    variant={hoverStates.self ? 'secondary' : 'primary'}
                  />
                ) : (
                  <SelfEmployed variant="secondary" />
                )
              }
              label="Self Employed"
              type="self"
              onMouseEnter={() => handleHover('self', true)}
              onMouseLeave={() => handleHover('self', false)}
            />
            <TitleCard
              selected={employment === 'retired'}
              onClick={() => handleEmploymentSelection('retired')}
              iconComponent={
                employment !== 'retired' ? (
                  <Retired
                    variant={hoverStates.retired ? 'secondary' : 'primary'}
                  />
                ) : (
                  <Retired variant="secondary" />
                )
              }
              label="Retired or on SSN"
              type="retired"
              onMouseEnter={() => handleHover('retired', true)}
              onMouseLeave={() => handleHover('retired', false)}
            />
          </div>

          <div className="flex gap-4 mt-6">
            <Button
              variant="enabled"
              onClick={() => {
                setPage(4);
                setDirection(false);
              }}
            >
              Back
            </Button>
            <Button
              variant={employment === '' ? 'disabled' : 'primary'}
              disabled={employment === '' && isSubmitting}
              onClick={handleSubmit}
            >
              {isLoading && direction
                ? 'Please wait...'
                : 'Proceed to Setup Profile'}
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Page5;
