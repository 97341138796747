import React from 'react';
/**
 * Renders a loading card component.
 *
 * @returns {JSX.Element} The loading card component.
 */
const InviteExpire = () => {
  return (
    <div
      className="bg-cover w-[100vw] bg-center h-screen flex justify-center items-center w-[100vw]"
      style={{ backgroundImage: 'url("/mainbg.png")' }}
    >
      {' '}
      <div
        className={`w-[500px]   tertiary-bg rounded-lg flex flex-col items-center`}
      >
        <img src="https://i.ibb.co/L93tB14/mira.png" alt="" className="h-24" />
        <div className="pt-1 pl-8 pr-8 pb-8 flex flex-col items-center justify-between">
          <h3 className="h5-b text-secondary  mb-2 mt-1">
            Invite Link Expired
          </h3>
          <p className="p-sm-regular text-neutral600 mt-4">
            It appears that the link is no longer valid. If the account is
            already setup, please continue by logging to your account.
          </p>
          <button
            className="btn-primary p-sm-bold text-tertiary my-6 "
            onClick={() => {
              window.history.replaceState(null, null, window.location.origin);
              window.location.href = window.location.origin;
            }}
          >
            Continue to login &gt;
          </button>
          <p className="p-sm-regular text-neutral600">
            If you are facing trouble logging in, please reach out to us on
            <span className="text-primary500 p-sm-bold underline">
              {' '}
              info@miralabs.ai
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InviteExpire;
