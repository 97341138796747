//components
import H1 from 'components/h1-typography';
import { PieChart } from 'react-minimal-pie-chart';
import { motion } from 'framer-motion';

const Insights = ({ data, title }) => {
  const returnCss = (index) => {
    switch (index) {
      case 0:
        return 'font-medium-bold text-[#76C71E]';
      case 1:
        return 'font-medium-bold text-primary500';
      case 2:
        return 'font-medium-bold text-error300';
      case 3:
        return 'font-medium-bold text-primary400';
      default:
        return 'font-medium-bold text-[#76C71E]';
    }
  };
  const chartData = [
    { title: '', value: data[0]?.value, color: '#76C71E' },
    { title: '', value: data[1]?.value, color: '#1340FF' },
    { title: '', value: data[2]?.value, color: '#8C1823' },
  ];
  const keyNameMapper = (str) => {
    return str
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };
  return (
    <>
      <motion.div
        initial={{
          y: -50,
        }}
        animate={{
          x: 0,
          y: 0,
          scale: 1,
          rotate: 0,
        }}
        className="h-fit w-[50%] flex flex-col gap-2 p-3 border border-secondary200 rounded-lg"
      >
        <H1 className="font-semibold">{title}</H1>
        <div className="h-[50%] w-[100%] flex flex-row justify-between">
          <div className="flex flex-col justify-end">
            <div className="w-[100%] flex flex-row">
              {data?.map((item, index) => {
                return (
                  <div
                    className={`min-w-[5vw] p-2 flex flex-col justify-center
                                    ${
                                      index < data?.length - 1 &&
                                      ' border-r border-r-secondary200 '
                                    } items-center`}
                    key={index}
                  >
                    <h1 className={returnCss(index)}>{item?.value ?? 0}</h1>
                    <p className="p-xs-regular text-[#151314] self-center">
                      {keyNameMapper(item?.name ?? 'not available')}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-[10%] overflow-visible flex flex-col  mr-3 mb-3">
            <PieChart
              data={chartData}
              lineWidth={25}
              animate={true}
              radius={80}
              className="overflow-visible"
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};
export default Insights;
