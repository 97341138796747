// Components
import React, { useEffect, useState } from 'react';
import DynamicForm from '../DynamicForm';
import Button from 'components/button';
import Radio from 'components/radio';
import H1 from 'components/h1-typography';
import DynamicTabs from 'components/dynamicTabs';
import FormWrapper from 'wrapper/form-wrapper';
//icons
import plus from 'assets/icons/bluePlus.svg';
//child imports
import ConfirmDeleteTab from 'wrapper/deleteTab';
// RTK QUERY
import {
  useGetEmploymentTypeQuery,
  useGetIncomeTypeQuery,
  useGetStatesQuery,
  useGetDynamicApplicationQuery,
  useUpdateDynamicEmploymentDetailsMutation,
  useCreateDynamicEmploymentDetailsMutation,
  useDeleteDynamicEmploymentDetailsMutation,
} from 'redux_store/services/client/profile';
import { useSelector, useDispatch } from 'react-redux';
import {
  incomeDataSelector,
  resetPageInfo,
} from 'redux_store/slices/application';
import { useGetOnboardingDataQuery } from 'redux_store/services/client/onboarding';
//form fields
import {
  employmentAndIncome,
  selfEmployed,
} from '../consumerFormFields/employeeAndIncome';
// Utils
import {
  checkValidations,
  createFormObject,
  removeEmptyStrings,
} from '../utils';
// Sentry for logs
import * as Sentry from '@sentry/browser';
import { customToast } from 'components/toast';
import { getUserDetails } from 'auth/auth';
const EmployeeAndIncome = ({ setPage, setPreviousPage, token }) => {
  // States and Refs
  const [formData, setFormData] = React.useState({
    ...createFormObject(employmentAndIncome),
    employmentTypeId: 2,
  });
  const [allEmployeeArray, setAllEmployeeArray] = useState([]);
  const [tab, setTab] = React.useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const isEdit = React.useRef(false);
  const [employmentUpdateId, setEmploymentUpdateId] = useState(null);
  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tabsArray, setTabsArray] = useState([
    {
      id: 0,
      name: 'Primary Employment',
      isActive: true,
      isError: false,
    },
  ]);
  const formatData = (data, property) => {
    return (
      data?.map((x) => {
        return {
          ...x,
          id: x[property],
        };
      }) ?? []
    );
  };
  const userId = getUserDetails()?.userId;
  // Selectors
  const incomeData = useSelector(incomeDataSelector);
  const dispatch = useDispatch();
  // RTK QUERY
  const { data: employeeTypeData } = useGetEmploymentTypeQuery();
  const { data: incomeTypeData } = useGetIncomeTypeQuery();
  const { data: stateType } = useGetStatesQuery();
  const { data: onBoardingData } = useGetOnboardingDataQuery();
  //Query to Save data
  const [createEmployeeDetails, { isLoading: isCreateEmployeeDetailsLoading }] =
    useCreateDynamicEmploymentDetailsMutation();
  //Query to Update data
  const [updateEmployeeDetails, { isLoading: isUpdateEmployeeDetailsLoading }] =
    useUpdateDynamicEmploymentDetailsMutation();
  //Query to Delete data
  const [deleteEmployeeDetails, { isLoading: isDeleteEmployeeDetailsLoading }] =
    useDeleteDynamicEmploymentDetailsMutation();
  const {
    data: DynamicApplicationData,
    refetch,
    isLoading: isApplicationLoading,
  } = useGetDynamicApplicationQuery(
    {
      application_id: token,
      userId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !token,
    }
  );
  // dropdown for form fields
  const dropdowns = {
    incomeTypeId: formatData(incomeTypeData?.data, 'incomeTypeId'),
    previousEmployerStateName: formatData(stateType?.data, 'stateId'),
    stateId: formatData(stateType?.data, 'stateId'),
  };
  useEffect(() => {
    if (DynamicApplicationData?.success) {
      const employeeArray =
        DynamicApplicationData?.data?.employment_and_income_details;
      setAllEmployeeArray(employeeArray);
      if (employeeArray?.length > 1) {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Employment',
            isActive: false,
            isError: false,
          },
        ];
        for (let i = 1; i <= employeeArray.length - 1; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Employment ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(tab);
        newTabsArray[tab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(employeeArray[tab]);
      } else {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Employment',
            isActive: false,
            isError: false,
          },
        ];
        for (let i = 1; i <= tab; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Employment ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(tab);
        newTabsArray[tab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(employeeArray[tab]);
      }
    }
  }, [
    DynamicApplicationData,
    incomeData,
    onBoardingData?.data?.employmentTypeId,
  ]);
  React.useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        previousEmployerName:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerName
            : null,
        previousEmployerDesignation:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerDesignation
            : null,
        previousEmployerAddress:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerAddress
            : null,
        previousEmployerCityName:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerCityName
            : null,
        previousEmployerStateName:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerStateId
            : null,
        previousEmployerStateId:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerStateId
            : null,
        previousEmployerExperienceYears:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerExperienceYears
            : null,
        previousEmployerExperienceMonths:
          prev?.presentEmployerLessThanTwoYears === true ||
          prev?.presentEmployerLessThanTwoYears === '1'
            ? prev.previousEmployerExperienceMonths
            : null,
      };
    });
  }, [formData?.presentEmployerLessThanTwoYears]);

  const handleAddTab = async () => {
    if (isLoading) {
      return false;
    }
    if (tabsArray.length < 2) {
      if (await handleUpdateFunction()) {
        setTabsArray((prev) => {
          let newIndex = tab + 1;
          setTab(newIndex);
          tabsArray.map((item) => {
            if (item.isActive) item.isActive = false;
            allEmployeeArray[newIndex]
              ? UpdateFormDataOnLoad(allEmployeeArray[newIndex])
              : setFormData({
                  ...createFormObject(employmentAndIncome),
                  employmentTypeId: 2,
                });
          });
          return [
            ...prev,
            {
              id: newIndex,
              name: `Additional Employment ${newIndex}`,
              isActive: true,
            },
          ];
        });
      }
    } else {
      customToast.error("Maximum 2 Employee's allowed !");
    }
  };
  // Function to check if the current form has any error's
  const formHasNoErrors = () => {
    if (!formData?.employmentTypeId) {
      setIsFormSubmitted(true);
      setIsError(true);
      /* customToast.error("Form contain Error's !"); */
      return false;
    } else if (
      formData?.employmentTypeId &&
      Object.keys(
        checkValidations(
          formData?.employmentTypeId === 3 ? selfEmployed : employmentAndIncome,
          formData
        )
      )?.length > 0
    ) {
      setIsFormSubmitted(true);
      const errors = checkValidations(
        formData?.employmentTypeId === 3 ? selfEmployed : employmentAndIncome,
        formData
      );
      setIsError(true);
      setErrors(errors);
      return false;
    } else {
      setIsError(false);
      setIsFormSubmitted(false);
      setErrors({});
      return true;
      //statement to be executed white no errors
    }
  };
  const getNextOrder = () => {
    return (
      allEmployeeArray.reduce((max, obj) => Math.max(max, obj.order), 0) + 1
    );
  };
  // Function to return the Data as Per API format
  const ApiFormatData = (type) => {
    return {
      ...formData,
      userEmploymentAndIncomeId: type === 'create' ? null : employmentUpdateId,
      employerExperienceMonths: formData?.employerExperienceMonths
        ? formData?.employerExperienceMonths?.id - 1
        : 0,
      employerExperienceYears: formData?.employerExperienceYears
        ? formData?.employerExperienceYears?.id - 1
        : 0,
      presentEmployerLessThanTwoYears:
        !!formData?.presentEmployerLessThanTwoYears,
      previousEmployerExperienceYears: formData?.previousEmployerExperienceYears
        ? formData?.previousEmployerExperienceYears?.id - 1
        : 0,
      previousEmployerExperienceMonths:
        formData?.previousEmployerExperienceMonths
          ? formData?.previousEmployerExperienceMonths?.id - 1
          : 0,
      LOBWorkYears: formData?.LOBWorkYears
        ? formData?.LOBWorkYears?.id - 1
        : null,
      LOBWorkMonths: formData?.LOBWorkMonths
        ? formData?.LOBWorkMonths?.id - 1
        : null,
      OTIncome: formData?.OTIncome ? formData?.OTIncome : 0,
      incomeTypeId: formData?.incomeTypeId ? formData?.incomeTypeId?.id : null,
      stateName: formData?.stateId?.name,
      stateId: formData?.stateId ? formData?.stateId?.id : null,
      previousEmployerStateName: formData?.previousEmployerStateName?.name,
      previousEmployerStateId: formData?.previousEmployerStateName
        ? formData?.previousEmployerStateName?.id
        : null,
      bonusIncome: formData?.bonusIncome ? formData?.bonusIncome : 0,
      otherIncome: formData?.otherIncome ? formData?.otherIncome : 0,
      employedByOtherParty: !!formData?.employedByOtherParty,
      selfEmployed: !!formData?.selfEmployed,
      userId,
      order: formData.order ?? getNextOrder(),
      applicationId: token,
      userProfileId:
        type === 'create'
          ? DynamicApplicationData?.data?.personal_details?.userProfileId
          : null,
    };
  };
  const handleUpdateFunction = async () => {
    if (isLoading) {
      return false;
    }
    if (formHasNoErrors()) {
      setIsFormSubmitted(false);
      try {
        setIsLoading(true);
        //condition to update the Form when AI is involved in form filling
        if (Object.keys(incomeData)?.length > 0) {
          const refetchedData = await refetch();
          if (refetchedData?.isSuccess) {
            const uniqueId =
              refetchedData?.data?.data?.employment_and_income_details?.[0]
                ?.userEmploymentAndIncomeId;
            const data = {
              ...ApiFormatData('update'),
              userEmploymentAndIncomeId: uniqueId,
            };
            const resp = await customToast.promise(
              updateEmployeeDetails({
                data: removeEmptyStrings(data),
              }),
              'Saving',
              tab === 0
                ? `Primary employment tab updated successfully.`
                : `Additional employment ${tab} tab updated successfully.`,
              'Error While Saving'
            );
            if (resp?.data?.status === 200) {
              setIsLoading(false);
              dispatch(
                resetPageInfo({
                  name: 'incomeData',
                })
              );
              return true;
            }
            return false;
          }
        }
        //If employee update id does not exist then create an employee
        if (!employmentUpdateId) {
          //add new employee function's
          const resp = await customToast.promise(
            createEmployeeDetails({
              data: removeEmptyStrings(ApiFormatData('create')),
            }),
            'Saving',
            tab === 0
              ? `Primary employment tab added successfully.`
              : `Additional employment ${tab} tab added successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        } else {
          // if employee id exist then update the existing employee
          const resp = await customToast.promise(
            updateEmployeeDetails({
              data: removeEmptyStrings(ApiFormatData('update')),
            }),
            'Saving',
            tab === 0
              ? `Primary employment tab updated successfully.`
              : `Additional employment ${tab} tab updated successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        }
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
        setIsLoading(false);
        return false;
      }
    }
  };
  const onSubmitHandler = async () => {
    if (await handleUpdateFunction()) {
      setPage();
    }
  };
  const UpdateFormDataOnLoad = (data) => {
    if (data) {
      isEdit.current = true;
      const formData = data;
      const onboardingDetails =
        DynamicApplicationData?.data?.user_onboarding_details;
      setFormData({
        ...formData,
        presentEmployerLessThanTwoYears:
          formData?.presentEmployerLessThanTwoYears === '1',
        employedByOtherParty: formData?.employedByOtherParty === '1',
        selfEmployed: formData?.selfEmployed === '1',
        ...incomeData,
        employmentTypeId: parseInt(incomeData?.employmentTypeId)
          ? parseInt(incomeData?.employmentTypeId)
          : formData?.employmentTypeId || onboardingDetails?.employmentTypeId,
        incomeTypeId: incomeData?.incomeTypeId
          ? {
              id: parseInt(incomeData?.incomeTypeId),
            }
          : formData?.incomeTypeId
            ? {
                id: formData?.incomeTypeId,
              }
            : '',

        employerExperienceYears:
          incomeData?.employerExperienceYears ||
          incomeData?.employerExperienceYears === 0
            ? {
                id: parseInt(incomeData?.employerExperienceYears) + 1,
              }
            : formData?.employerExperienceYears ||
                formData?.employerExperienceYears === 0
              ? {
                  id: formData?.employerExperienceYears + 1,
                }
              : '',

        employerExperienceMonths:
          incomeData?.employerExperienceMonths ||
          incomeData?.employerExperienceMonths === 0
            ? {
                id: parseInt(incomeData?.employerExperienceMonths) + 1,
              }
            : formData?.employerExperienceMonths ||
                formData?.employerExperienceMonths === 0
              ? {
                  id: parseInt(formData?.employerExperienceMonths) + 1,
                }
              : '',

        previousEmployerExperienceYears:
          incomeData?.previousEmployerExperienceYears ||
          incomeData?.previousEmployerExperienceYears === 0
            ? {
                id: parseInt(incomeData?.previousEmployerExperienceYears) + 1,
              }
            : formData?.previousEmployerExperienceYears ||
                formData?.previousEmployerExperienceYears === 0
              ? {
                  id: formData?.previousEmployerExperienceYears + 1,
                }
              : '',

        previousEmployerExperienceMonths:
          incomeData?.previousEmployerExperienceMonths ||
          incomeData?.previousEmployerExperienceMonths === 0
            ? {
                id: parseInt(incomeData?.previousEmployerExperienceMonths) + 1,
              }
            : formData?.previousEmployerExperienceMonths ||
                formData?.previousEmployerExperienceMonths === 0
              ? {
                  id: formData?.previousEmployerExperienceMonths + 1,
                }
              : '',

        LOBWorkMonths:
          incomeData?.LOBWorkMonths || incomeData?.LOBWorkMonths === 0
            ? {
                id: parseInt(incomeData?.LOBWorkMonths) + 1,
              }
            : formData?.LOBWorkMonths || formData?.LOBWorkMonths === 0
              ? {
                  id: formData?.LOBWorkMonths + 1,
                }
              : '',

        LOBWorkYears:
          incomeData?.LOBWorkYears || incomeData?.LOBWorkYears === 0
            ? {
                id: parseInt(incomeData?.LOBWorkYears) + 1,
              }
            : formData?.LOBWorkYears || formData?.LOBWorkYears === 0
              ? {
                  id: formData?.LOBWorkYears + 1,
                }
              : '',
        stateId: incomeData?.stateId
          ? { id: incomeData?.stateId }
          : formData?.stateId
            ? { id: formData?.stateId }
            : '',
        previousEmployerStateName: incomeData?.previousStateId
          ? { id: incomeData?.previousStateId }
          : formData?.previousEmployerStateId
            ? { id: formData?.previousEmployerStateId }
            : '',
      });
    } else if (
      Object.keys(incomeData)?.length > 0 ||
      onBoardingData?.data?.employmentTypeId
    ) {
      const employmentTypeId = parseInt(incomeData?.employmentTypeId)
        ? parseInt(incomeData?.employmentTypeId)
        : onBoardingData?.data?.employmentTypeId || formData?.employmentTypeId;
      setFormData((formData) => ({
        ...formData,
        ...incomeData,
        employmentTypeId,
        employerName: incomeData?.employerName
          ? incomeData?.employerName
          : formData?.employerName ||
            (String(employmentTypeId) === '1' ? 'Retired' : ''),
        incomeTypeId: incomeData?.incomeTypeId
          ? {
              id: parseInt(incomeData?.incomeTypeId),
            }
          : formData?.incomeTypeId ?? '',

        employerExperienceYears:
          incomeData?.employerExperienceYears ||
          incomeData?.employerExperienceYears === 0
            ? {
                id: parseInt(incomeData?.employerExperienceYears) + 1,
              }
            : formData?.employerExperienceYears ?? '',

        employerExperienceMonths:
          incomeData?.employerExperienceMonths ||
          incomeData?.employerExperienceMonths === 0
            ? {
                id: parseInt(incomeData?.employerExperienceMonths) + 1,
              }
            : formData?.employerExperienceMonths ?? '',

        previousEmployerExperienceYears:
          incomeData?.previousEmployerExperienceYears ||
          incomeData?.previousEmployerExperienceYears === 0
            ? {
                id: parseInt(incomeData?.previousEmployerExperienceYears) + 1,
              }
            : formData?.previousEmployerExperienceYears ?? '',

        previousEmployerExperienceMonths:
          incomeData?.previousEmployerExperienceMonths ||
          incomeData?.previousEmployerExperienceMonths === 0
            ? {
                id: parseInt(incomeData?.previousEmployerExperienceMonths) + 1,
              }
            : formData?.previousEmployerExperienceMonths ?? '',

        LOBWorkMonths:
          incomeData?.LOBWorkMonths || incomeData?.LOBWorkMonths === 0
            ? {
                id: parseInt(incomeData?.LOBWorkMonths) + 1,
              }
            : formData?.LOBWorkMonths ?? '',

        LOBWorkYears:
          incomeData?.LOBWorkYears || incomeData?.LOBWorkYears === 0
            ? {
                id: parseInt(incomeData?.LOBWorkYears) + 1,
              }
            : formData?.LOBWorkYears ?? '',
        stateName: incomeData?.stateId
          ? { id: incomeData?.stateId }
          : formData?.stateId ?? '',
        previousEmployerStateName: incomeData?.previousStateId
          ? { id: incomeData?.previousStateId }
          : formData?.previousEmployerStateId ?? '',
      }));
    }
  };

  useEffect(() => {
    setEmploymentUpdateId(
      allEmployeeArray[tab]?.userEmploymentAndIncomeId ?? null
    );
  }, [formData]);
  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);
  const handleDeleteEmployee = async () => {
    setIsError(false);
    setConfirmDeleteVisibility(false);
    const employmentIncomeId =
      allEmployeeArray[tab]?.userEmploymentAndIncomeId ?? null;
    if (employmentIncomeId) {
      try {
        const resp = await customToast.promise(
          deleteEmployeeDetails({
            employmentIncomeId,
          }),
          'Deleting',
          tab === 0
            ? `Primary employment tab removed successfully.`
            : `Additional employment ${tab} tab removed successfully.`,

          'Error While Deleting'
        );
        if (resp?.data?.success) {
          setTab(tab - 1);
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
      return;
    }
    let newTabArray = tabsArray?.filter((tabs) => {
      return tabs.id !== tab;
    });
    setTab(tab - 1);
    newTabArray[tab - 1].isActive = true;
    setTabsArray(newTabArray);
    UpdateFormDataOnLoad(allEmployeeArray[tab - 1]);
    setIsError(false);
  };
  return (
    <div className="">
      <H1>Employment and Income</H1>
      <p className="antialiased mb-3">
        Provide Borrower's employment and income information.
      </p>
      <FormWrapper
        className={
          'my-3 mx-2 max-h-custom-profile-forms overflow-hidden  overflow-y-scroll pb-3'
        }
      >
        <div className="w-full rounded-lg">
          {/* tab wrapper division */}
          <div className="">
            <div className="flex flex-row justify-between border-b border-grey">
              <DynamicTabs
                isError={isError}
                selectedTab={tab}
                handleUpdateFunction={handleUpdateFunction}
                onClickTab={async (selectedTab) => {
                  setTab(selectedTab);
                  allEmployeeArray[selectedTab]
                    ? UpdateFormDataOnLoad(allEmployeeArray[selectedTab])
                    : setFormData({
                        ...createFormObject(employmentAndIncome),
                        employmentTypeId: 2,
                      });
                  return true;
                }}
                tabsArray={tabsArray}
                variant="primary"
                onDelete={() => {
                  if (employmentUpdateId) {
                    setConfirmDeleteVisibility(true);
                    return;
                  }
                  handleDeleteEmployee();
                }}
              />
              <div
                className="flex flex-col justify-center py-2 cursor-pointer p-sm-regular text-primary500 rounded-md"
                onClick={handleAddTab}
              >
                <>
                  <div className="flex flex-row justify-center gap-3 min-w-[230px]">
                    <img src={plus} alt="+" className="h-[16px]" />
                    <p className="font-medium">Add Additional Employment</p>
                  </div>
                </>
              </div>
            </div>
            <div className="flex gap-2 p-2">
              <label className="p-xs-bold pr-3">Employment Type</label>
              {formatData(employeeTypeData?.data, 'employmentTypeId')?.map(
                (radio) => (
                  <div className="flex items-center">
                    <Radio
                      onChange={(e) => {
                        if (
                          allEmployeeArray?.[tab]?.employmentTypeId ===
                          radio?.id
                        ) {
                          UpdateFormDataOnLoad(allEmployeeArray?.[tab]);
                          setIsFormSubmitted(false);
                          setErrors({});
                          return 0;
                        }
                        setFormData({
                          ...createFormObject(
                            radio?.id === 3 ? selfEmployed : employmentAndIncome
                          ),
                          employmentTypeId: radio?.id,
                          employerName: radio?.id === 1 ? 'Retired' : '',
                        });
                        setIsFormSubmitted(false);
                        setErrors({});
                      }}
                      id={`employment-${radio?.id}`}
                      name={`radio-employment-type`}
                      checked={radio?.id == formData?.employmentTypeId}
                    />
                    <label
                      htmlFor={`employment-${radio?.id}`}
                      className="pl-1 text-black p-xs-bold"
                    >
                      {radio?.name}
                    </label>
                  </div>
                )
              )}
            </div>
            {formData.employmentTypeId === 3 && (
              <DynamicForm
                data={selfEmployed}
                setFormData={setFormData}
                formData={formData ?? []}
                errors={errors}
                isSubmitted={isFormSubmitted}
                dropdowns={dropdowns}
              />
            )}
            {formData.employmentTypeId !== 3 && (
              <DynamicForm
                data={employmentAndIncome}
                setFormData={setFormData}
                formData={formData ?? []}
                errors={errors}
                isSubmitted={isFormSubmitted}
                dropdowns={dropdowns}
              />
            )}
          </div>
          <div className="text-right mt-2">
            <Button
              variant="tertiary"
              onClick={() => {
                setPreviousPage();
              }}
              style={{ marginRight: '10px' }}
              type="button"
            >
              {' '}
              Back: Loan Information
            </Button>
            <Button type="submit" variant="primary" onClick={onSubmitHandler}>
              {'Next: Assets and Other Credits'}
            </Button>
          </div>
        </div>
      </FormWrapper>
      {confirmDeleteVisibility && (
        <ConfirmDeleteTab
          setConfirmDeleteVisibility={setConfirmDeleteVisibility}
          tabName={`Additional Employment ${tab}`}
          selectedTab={tab}
          onDelete={handleDeleteEmployee}
        />
      )}
    </div>
  );
};

export default EmployeeAndIncome;
