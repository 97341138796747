// React and imports
import React, { useEffect, useState } from 'react';
//Components

import Button from 'components/button';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
import FormWrapper from 'wrapper/form-wrapper';
import DynamicTabs from 'components/dynamicTabs';
import ConfirmDeleteTab from 'wrapper/deleteTab';
//icons
import plus from 'assets/icons/bluePlus.svg';
// Sentry Logs
import * as Sentry from '@sentry/browser';
//Utils
import { realEstateOwned } from 'pages/profile/formfields/realEstateOwned';
import DynamicForm from 'pages/profile/DynamicForm';
import {
  checkValidations,
  createFormObject,
  formatData,
  removeEmptyStrings,
} from 'pages/profile/utils';

// RTK Queries
import {
  useGetBorrowerProfileQuery,
  useUpdateREOSDetailsMutation,
  useCreateREOSDataMutation,
  useDeleteReosDetailsMutation,
} from '../../../../redux/services/borrower-profile';
import {
  useGetOccupancyQuery,
  useGetStatesQuery,
} from 'redux_store/services/client/profile';
import { useLocation } from 'react-router-dom';
import { replaceEmptyStringWithNull } from 'utils/stringToNull';
/**
 * Function to handle the Real Estate Owned form component.
 *
 * @param {boolean} readOnly - Flag to indicate if the form is in read-only mode.
 * @param {function} setReadOnly - Function to set the read-only mode of the form.
 * @param {number} resetId - ID used to reset the form.
 * @param {function} setResetId - Function to set the reset ID.
 * @returns {JSX.Element} Returns the Real Estate Owned form component.
 */
const RealEstateOwned = ({ readOnly, setReadOnly, resetId, setResetId }) => {
  // States adn Refs
  const [formData, setFormData] = React.useState({
    ...createFormObject(realEstateOwned),
    occupancyRate: 75,
  });
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [tab, setTab] = useState(0);
  const [isError, setIsError] = useState(false);
  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false);
  const [allReoArray, setAllReoArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabsArray, setTabsArray] = useState([
    {
      id: 0,
      name: 'Primary Property',
      isActive: true,
      isError: false,
    },
  ]);
  const isEdit = React.useRef(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = searchParams.get('applicationId');

  // RTK Queries
  const { data: occupancyData } = useGetOccupancyQuery();
  const { data: stateType } = useGetStatesQuery();
  const {
    data: applicationData,
    refetch: refetchBorrowerInfo,
    isLoading: isGetApplicationDataLoading,
  } = useGetBorrowerProfileQuery(
    { applicationId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [updateRealEstateData, { isLoading: isUpdateRealEstateDataLoading }] =
    useUpdateREOSDetailsMutation();
  const [createRealEstateData, { isLoading: isCreateRealEstateDataLoading }] =
    useCreateREOSDataMutation();
  const [deleteREOsDetails, { isLoading: isREOsDeleteLoading }] =
    useDeleteReosDetailsMutation();
  const dropdowns = {
    occupancyTypeId: formatData(occupancyData?.data, 'occupancyTypeId'),
    stateName: formatData(stateType?.data, 'stateId'),
  };
  // form submit handler
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (await handleUpdateFunction()) {
      setReadOnly(true);
    }
  };

  const UpdateFormDataOnLoad = (data, currentTab) => {
    setIsSubmitted(false);
    let personalDetails = applicationData?.data?.user_profile;
    if (data) {
      isEdit.current = true;
      const formData = data;
      setFormData({
        ...formData,
        occupancyTypeId:
          personalDetails.ownershipId === 1
            ? { id: 1 }
            : { id: Number(formData.occupancy) },
        stateName: formData.stateId ? { id: formData.stateId } : 0,
        escrowedPayment: formData?.escrowedPayment
          ? {
              id:
                formData?.escrowedPayment === '1'
                  ? 1
                  : formData?.escrowedPayment === '0'
                    ? 2
                    : '',
            }
          : '',

        isRentalForIncome: formData?.isRentalForIncome
          ? {
              id: formData?.isRentalForIncome === '1' ? 1 : 2,
            }
          : '',
      });
    } else {
      let personalDetails = applicationData?.data?.user_profile;
      setFormData((formData) => ({
        ...(readOnly
          ? {
              ...createFormObject(realEstateOwned),
              occupancyRate: 75,
            }
          : formData),
        occupancyTypeId:
          personalDetails.ownershipId === 1
            ? { id: 1 }
            : { id: Number(formData.occupancy) },
        cityName:
          personalDetails?.ownershipId === 1 ? personalDetails.cityName : '',
        stateName:
          personalDetails?.ownershipId === 1
            ? { id: personalDetails.stateId }
            : 0,
        propertyAddress:
          personalDetails?.ownershipId === 1
            ? personalDetails.currentAddress
            : '',
        zipCode:
          personalDetails.ownershipId === 1 ? personalDetails.zipCode : '',
        ownedBy: `${personalDetails.firstName ?? ''} ${personalDetails.middleName ? personalDetails.middleName + ' ' : ''}${personalDetails.lastName ?? ''} `,
      }));
    }
  };
  React.useEffect(() => {
    if (applicationData?.success) {
      const reoArray = applicationData?.data?.user_reos;
      const persistedTab = tab ?? 0;
      setAllReoArray(reoArray);
      if (reoArray?.length > 1) {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Property',
            isActive: false,
            isError: false,
          },
        ];
        if (tab === reoArray.length) {
          for (let i = 1; i <= tab; i++) {
            newTabsArray.push({
              id: i,
              name: `Additional Property ${i}`,
              isActive: false,
              isError: false,
            });
          }
          setTab(persistedTab);
          newTabsArray[persistedTab].isActive = true;
          setTabsArray(newTabsArray);
          UpdateFormDataOnLoad(reoArray[persistedTab], persistedTab);
          return;
        }
        for (let i = 1; i <= reoArray.length - 1; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Property ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(persistedTab);
        newTabsArray[persistedTab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(reoArray[persistedTab], persistedTab);
      } else {
        let newTabsArray = [
          {
            id: 0,
            name: 'Primary Property',
            isActive: false,
            isError: false,
          },
        ];
        for (let i = 1; i <= tab; i++) {
          newTabsArray.push({
            id: i,
            name: `Additional Property ${i}`,
            isActive: false,
            isError: false,
          });
        }
        setTab(persistedTab);
        newTabsArray[persistedTab].isActive = true;
        setTabsArray(newTabsArray);
        UpdateFormDataOnLoad(reoArray[persistedTab], persistedTab);
      }
    }
  }, [applicationData, readOnly]);
  useEffect(() => {
    if (resetId === 6) {
      setFormData((formData) => ({
        ...createFormObject(realEstateOwned),
        occupancyRate: 75,
      }));
      setResetId(0);
    }
  }, [resetId]);
  const formHasNoErrors = () => {
    const errors = checkValidations(realEstateOwned, formData, {
      ...applicationData?.data,
      personal_details: applicationData?.data?.user_profile,
    });
    if (Object.keys(errors)?.length > 0) {
      setErrors(errors);
      setIsSubmitted(true);
      setIsError(true);
      return false;
    } else {
      setErrors({});
      setIsSubmitted(false);
      setIsError(false);
      return true;
    }
  };
  const getNextOrder = () => {
    return allReoArray.reduce((max, obj) => Math.max(max, obj.order), 0) + 1;
  };
  const ApiFormatData = (type) => {
    return {
      ...formData,
      occupancy: String(formData.occupancyTypeId.id),
      isRentalForIncome: formData?.isRentalForIncome?.id === 1 ? true : false,
      escrowedPayment:
        formData?.escrowedPayment?.id === 1
          ? true
          : formData?.escrowedPayment?.id === 2
            ? false
            : null,
      rentalIncome: formData?.rentalIncome ? formData?.rentalIncome : 0,
      mortgageBalance: formData?.mortgageBalance
        ? formData?.mortgageBalance
        : 0,
      monthlyPayment: formData?.monthlyPayment ? formData?.monthlyPayment : 0,
      occupancyRate: 75,
      netRentalIncome: formData?.netRentalIncome
        ? formData?.netRentalIncome
        : 0,
      mortgagePayment: formData?.mortgagePayment
        ? formData?.mortgagePayment
        : 0,
      taxes: formData?.taxes ? formData?.taxes : 0,
      insurance: formData?.insurance ? formData?.insurance : 0,
      HOA: formData?.HOA ? formData?.HOA : 0,
      stateName: formData?.stateName?.name,
      stateId: formData?.stateName ? formData?.stateName?.id : null,
      zipCode: String(formData?.zipCode ?? ''),
      tandcCompleted: formData?.isTermsAccepted ? '1' : '0',
      order: formData?.order ?? getNextOrder(),
      userProfileId: applicationData?.data?.user_profile?.userProfileId,
      userId: applicationData?.data?.user_profile?.userId,
      applicationId,
    };
  };
  const handleUpdateFunction = async (e) => {
    if (formHasNoErrors()) {
      setIsSubmitted(false);
      try {
        if (isLoading) {
          return false;
        }
        //condition to update the Form when AI is involved in form filling
        /* if (Object.keys(REODataAI)?.length > 0) {
          const refetchedData = await refetch();
          if (refetchedData?.isSuccess) {
            const uniqueId =
              refetchedData?.data?.data?.user_reos_details?.[0]?.userREOId;
            const data = {
              ...ApiFormatData('update'),
              userREOId: uniqueId,
            };
            const resp = await customToast.promise(
              updateRealEstateData({
                data: removeEmptyStrings(data),
              }),
              'Saving',
              tab === 0
                ? `Primary Property tab updated successfully.`
                : `Additional Property ${tab} tab updated successfully.`,
              'Error While Saving'
            );
            if (resp?.data?.status === 200) {
              setIsLoading(false);
              dispatch(
                resetPageInfo({
                  name: 'REOData',
                })
              );
              return true;
            }
            return false;
          }
        } */
        if (formData?.userREOId) {
          setIsLoading(true);
          // if reo's id exist then update the existing employee
          let resp = await customToast.promise(
            updateRealEstateData({
              data: removeEmptyStrings(ApiFormatData('update')),
            }),
            'Saving',
            tab === 0
              ? `Primary Property tab updated successfully.`
              : `Additional Property ${tab} tab updated successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        } else {
          //create reos if id does'nt exist
          let resp = await customToast.promise(
            createRealEstateData({
              data: removeEmptyStrings(ApiFormatData('create')),
            }),
            'Saving',
            tab === 0
              ? `Primary Property tab added successfully.`
              : `Additional Property ${tab} tab added successfully.`,
            'Error While Saving'
          );
          if (resp?.data?.status === 200) {
            setIsLoading(false);
            return true;
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };
  const handleAddTab = async () => {
    if (
      isCreateRealEstateDataLoading ||
      isUpdateRealEstateDataLoading ||
      readOnly
    )
      return;
    if (tabsArray.length < 5) {
      if (await handleUpdateFunction()) {
        setTabsArray((prev) => {
          let newIndex = prev.length;
          setTab(newIndex);
          tabsArray.map((item) => {
            if (item.isActive) item.isActive = false;
            allReoArray[newIndex]
              ? UpdateFormDataOnLoad(allReoArray[newIndex], newIndex)
              : setFormData({
                  ...createFormObject(realEstateOwned),
                  occupancyRate: 75,
                });
          });
          return [
            ...prev,
            {
              id: newIndex,
              name: `Additional Property ${newIndex}`,
              isActive: true,
              isError: false,
            },
          ];
        });
      }
    } else {
      customToast.error("Maximum 5 Property's allowed !");
    }
  };
  const handleDeleteREOs = async () => {
    setIsError(false);
    setConfirmDeleteVisibility(false);
    const userREOId = allReoArray[tab]?.userREOId ?? null;
    if (userREOId > 0) {
      try {
        let resp = await customToast.promise(
          deleteREOsDetails({
            reosId: userREOId,
            userId: applicationData?.data?.user_profile?.userId,
          }),
          'Deleting',
          tab === 0
            ? `Primary Property tab removed successfully.`
            : `Additional Property ${tab} tab removed successfully.`,
          'Error While Deleting'
        );
        if (resp?.data?.status === 200) {
          setTab(tab - 1);
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
      return;
    }
    let newTabArray = tabsArray?.filter((tabs) => {
      return tabs.id !== tab;
    });
    setTab(tab - 1);
    newTabArray[tab - 1].isActive = true;
    setTabsArray(newTabArray);
    UpdateFormDataOnLoad(allReoArray[tab - 1], tab - 1);
    setIsError(false);
  };
  return (
    <>
      {isGetApplicationDataLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <FormWrapper
          className={
            'my-3 mx-2 max-h-borrower-profile overflow-hidden overflow-y-scroll max-w-[65vw] pb-3'
          }
        >
          <div className="flex flex-row justify-between border-b border-grey">
            <DynamicTabs
              selectedTab={tab}
              isError={isError}
              handleUpdateFunction={handleUpdateFunction}
              onClickTab={async (selectedTab) => {
                //updated the current tab data and then switch's to new one
                setTab(selectedTab);
                allReoArray[selectedTab]
                  ? UpdateFormDataOnLoad(allReoArray[selectedTab], selectedTab)
                  : setFormData({
                      ...createFormObject(realEstateOwned),
                      occupancyRate: 75,
                    });
                return true;
              }}
              tabsArray={tabsArray}
              variant="primary"
              onDelete={() => {
                if (readOnly) return;
                if (formData?.userREOId) {
                  setConfirmDeleteVisibility(true);
                  return false;
                }
                handleDeleteREOs();
              }}
            />
            <div
              className="flex flex-col justify-center py-2 cursor-pointer p-sm-regular text-primary500 rounded-md"
              onClick={handleAddTab}
            >
              <>
                <div className="flex flex-row justify-center gap-3 min-w-[150px]">
                  <img src={plus} alt="+" className="h-[16px]" />
                  <p className="font-medium">Add Property</p>
                </div>
              </>
            </div>
          </div>
          <DynamicForm
            data={realEstateOwned}
            formData={{ ...formData, tab }}
            setFormData={setFormData}
            errors={errors}
            isSubmitted={isSubmitted}
            additionInfo={
              applicationData?.data
                ? {
                    ...applicationData?.data,
                    personal_details: applicationData?.data?.user_profile,
                  }
                : {}
            }
            dropdowns={dropdowns}
            readOnly={readOnly}
          />
          {!readOnly && (
            <div className="text-right mt-2">
              <Button
                variant="tertiary"
                style={{ marginRight: '10px' }}
                type="button"
                onClick={() => {
                  setReadOnly(true);
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" onClick={onSubmitHandler}>
                {' '}
                {'Save'}
              </Button>
            </div>
          )}
        </FormWrapper>
      )}
      {confirmDeleteVisibility && (
        <ConfirmDeleteTab
          setConfirmDeleteVisibility={setConfirmDeleteVisibility}
          tabName={`Additional Property ${tab}`}
          selectedTab={tab}
          onDelete={handleDeleteREOs}
        />
      )}
    </>
  );
};

export default RealEstateOwned;
