import { useCallback } from 'react';
import * as Sentry from '@sentry/browser';

/**
 * Custom React hook to handle downloading PDF documents.
 *
 * This hook provides a function to download a PDF document by fetching it from the server.
 * It can either open the PDF in a new tab or prompt the user to save it, based on the 'download' parameter.
 * It requires a service function that returns a promise resolving to a Blob.
 *
 * @returns {Object} An object containing the downloadPDF function.
 */
const useDownloadPDFHandler = () => {
  const downloadPDF = useCallback(
    async (fetchDocument, params, { download }, filename = 'document') => {
      try {
        const blob = await fetchDocument(params);

        if (download) {
          downloadBlob(blob, filename);
        } else {
          openBlobInNewTab(blob);
        }
      } catch (error) {
        handleError(error);
        Sentry.captureException(error);
      }
    },
    []
  );

  const openBlobInNewTab = (blob, fileName = 'download') => {
    const url = URL.createObjectURL(blob);
    const extension = blob.type.split('/')[1] || 'file';
    const newFileName = `${fileName}.${extension}`;

    // Attempt to open the file in a new tab
    const newWindow = window.open(url, '_blank');

    if (!newWindow) {
      throw new Error(`Failed to open ${extension.toUpperCase()} in new tab`);
    }

    // For files like images, you might need to explicitly trigger a download
    newWindow.onload = () => {
      newWindow.document.title = newFileName;
    };
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleError = (error) => {
    Sentry.captureException(error);
    console.error('Error downloading the PDF:', error);
  };

  return { downloadPDF };
};

export default useDownloadPDFHandler;
