import React from 'react';
/**
 * Renders a title card component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.selected - Indicates if the card is selected.
 * @param {string} props.type - The type of the card.
 * @param {string} props.label - The label of the card.
 * @param {React.Component} props.iconComponent - The icon component to display.
 * @param {string} props.iconSvg - The URL of the SVG icon to display.
 * @param {string} props.description - The description of the card.
 * @returns {React.Component} The rendered title card component.
 */
const TitleCard = ({
  selected,
  label,
  iconComponent,
  iconSvg,
  description,
  ...props
}) => {
  return (
    <div
      className={`w-[224px] h-[151px] ${
        selected ? 'primary100-bg transition-colors duration-300' : 'enabled-bg'
      } enabled-bg hover:bg-custom-primary200 hover:shadow-lg transition-shadow duration-300 rounded cursor-pointer flex flex-col items-center justify-center p-6 text-center group`}
      {...props}
    >
      {iconComponent}
      {iconSvg && <img src={iconSvg} alt="" />}
      <h3
        className={`${
          selected
            ? 'p-md-bold text-primary transition-colors duration-300'
            : 'p-md-medium text-black  group-hover:text-secondary500'
        } m-2 group-hover:p-md-bold`}
      >
        {label}
      </h3>
      <p className="font-medium">{description}</p>
    </div>
  );
};

export default TitleCard;
