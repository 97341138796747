import react, { useEffect, useState, useRef } from 'react';
//components
import Modal from 'components/modal';
import DynamicForm from 'pages/profile/DynamicForm';
import Button from 'components/button';
import H1 from 'components/h1-typography';
import { customToast } from 'components/toast';
import Loader from 'wrapper/loader';
//icons
import closeIcon from 'assets/icons/closeIconSlim.svg';
import addProfilePen from 'assets/icons/addProfilePen.svg';
import uploadIcon from 'assets/icons/uploadCloud.svg';
import successIcon from 'assets/icons/successCheckGreen.svg';
import successIocnFilled from 'assets/icons/successIcon.svg';
import linkIcon from 'assets/icons/linkIcon.svg';
//LIB imports
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import * as Sentry from '@sentry/browser';

//utils
import {
  createFormObject,
  formatData,
  checkValidations,
} from 'pages/profile/utils';
//form fields
import addNewEmployee from './addNewEmployeeFormFields';
//RTK
import {
  useCreateEmployeeMutation,
  useResetPasswordMutation,
  useUpdateEmployeeMutation,
  useGetStatesQuery,
} from 'redux_store/services/admin/adminEmployee';
import { format } from 'date-fns';
const AddNewEmployee = ({
  setAddNewModalVisibility,
  selectedRowData,
  addNewEmployeeFlag,
  employeeTypeData,
}) => {
  const [formData, setFormData] = react.useState({
    ...createFormObject(addNewEmployee),
  });
  //upload image states
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });
  const [uploadProfileVisiblity, setUploadProfileVisiblity] = useState(false);
  const [profileFormPreview, setProfileFormPreview] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [imageWhileCropping, setImageWhileCropping] = useState();
  const [rawImage, setRawImage] = useState();
  const [image, setImage] = useState(null);
  const canvasRef = useRef(null);
  const [imageError, setImageError] = useState(null);
  const [isImageCropped, setIsImageCropped] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  //dynamic form states
  const [errors, setErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // reset password link states
  const [resetPasswordFlag, setResetPasswordFlag] = useState(false);
  const [linkCopiedFlag, setLinkCopiedFlag] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');

  //RTK queries
  const [
    addEmployee,
    { isLoading: isAddEmployeeLoading, error: addEmployeeError },
  ] = useCreateEmployeeMutation();
  const [
    updateEmployee,
    { isLoading: isUpdateEmployeeLoading, error: updateEmployeeError },
  ] = useUpdateEmployeeMutation();
  const [resetPassword, { isLoading: isResetPasswordLoading }] =
    useResetPasswordMutation();
  const { data: stateData } = useGetStatesQuery();
  const dropdowns = {
    employeeTypeId: formatData(employeeTypeData?.data, 'employeeTypeId'),
    stateName: formatData(stateData?.data, 'stateId'),
  };
  const blankSpaceChecker = (value) => {
    if (value === '' || value === ' ' || !value || value.length === 0)
      return null;

    return value;
  };
  useEffect(() => {
    if (!addNewEmployeeFlag && selectedRowData) {
      let populateFormData = {
        ...formData,
        firstName: blankSpaceChecker(selectedRowData.firstName) ?? null,
        lastName: blankSpaceChecker(selectedRowData.lastName) ?? null,
        email: blankSpaceChecker(selectedRowData.email) ?? null,
        dateOfBirth: blankSpaceChecker(selectedRowData.dateOfBirth) ?? null,
        phoneNumber: blankSpaceChecker(selectedRowData.phoneNumber) ?? null,
        NMLSID: blankSpaceChecker(selectedRowData.NMLS) ?? null,
        companyName: blankSpaceChecker(selectedRowData.company) ?? null,
        employeeTypeId: {
          id: blankSpaceChecker(selectedRowData.employeeTypeId) ?? null,
        },
        branch: blankSpaceChecker(selectedRowData.branch) ?? null,
        address: blankSpaceChecker(selectedRowData.address) ?? null,
        cityName: blankSpaceChecker(selectedRowData.cityName) ?? null,
        stateName: { id: blankSpaceChecker(selectedRowData.stateId) ?? null },
        zipCode: blankSpaceChecker(selectedRowData.zipCode) ?? null,
        profileImage: blankSpaceChecker(selectedRowData.profilePicture) ?? null,
      };
      setFormData(populateFormData);
    } else {
      let populateBlankFormData = {
        ...formData,
        firstName: null,
        lastName: null,
        email: null,
        dateOfBirth: null,
        phoneNumber: null,
        NMLSID: null,
        companyName: null,
        employeeTypeId: null,
        branch: null,
        address: null,
        cityName: null,
        stateName: null,
        zipCode: null,
        profileImage: null,
      };
      setFormData(populateBlankFormData);
    }
    if (!addNewEmployeeFlag && selectedRowData.profilePicture) {
      setSelectedImage(blankSpaceChecker(selectedRowData.profilePicture));
    }
  }, []);
  //functions to validate file size and file type
  const validateFileType = (type) => {
    const allowedTypes = ['image/jpg', 'image/png', 'image/jpeg'];
    return allowedTypes.includes(type);
  };

  const validateFileSize = (size) => {
    const maxSize = 1024 * 1024; // 1MB
    return size <= 2 * maxSize; //2 MB
  };
  //handle the file selected and takes its url
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    const fileType = file.type;
    const fileSize = file.size;

    const fileTypeValidation = validateFileType(fileType);
    const fileSizeValidation = validateFileSize(fileSize);
    if (fileTypeValidation) {
      if (fileSizeValidation) {
        setImageError(null);
        setImageWhileCropping(URL.createObjectURL(file));
        setRawImage(file);
      } else {
        setImageError('File Size too large. Please upload image below 2 MB.');
      }
    } else {
      setImageError(
        'Unsupported image type. Please upload only JPG or PNG type image.'
      );
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    const fileType = file.type;
    const fileSize = file.size;

    const fileTypeValidation = validateFileType(fileType);
    const fileSizeValidation = validateFileSize(fileSize);

    if (fileTypeValidation && fileSizeValidation) {
      setImageError(null);
      setImageWhileCropping(URL.createObjectURL(file));
      setRawImage(file);
    } else {
      setImageError('Invalid file type or size');
    }
  };
  // handle the upload button
  const handleUploadProfile = () => {
    if (isImageCropped) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const croppedImage = canvas.toDataURL('image/jpeg');
      setSelectedImage(croppedImage);

      fetch(croppedImage)
        .then((res) => res.blob())
        .then((blob) => {
          setProfileFormPreview(blob);
        });
      //ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    } else {
      setSelectedImage(URL.createObjectURL(rawImage));
      setProfileFormPreview(rawImage);
    }
    customToast.success('Profile Image updated Successfully');
    setUploadProfileVisiblity(false);
    setIsImageCropped(false);
  };

  // reset the selected image state
  useEffect(() => {
    if (!uploadProfileVisiblity) {
      setCrop(null);
      setImage(null);
      setImageWhileCropping(null);
    }
  }, [uploadProfileVisiblity]);

  //function to handle reset password button
  const handleResetPassword = async () => {
    try {
      let resetPasswordResponse = await resetPassword({
        user_id: selectedRowData.user_id,
      });
      if (resetPasswordResponse?.data?.success) {
        setResetPasswordFlag(true);
        setRedirectLink(resetPasswordResponse?.data?.data?.url);
        customToast.success(resetPasswordResponse?.data?.message);
      }
      if (resetPasswordResponse?.error?.status === 500) {
        customToast.error('Unable to send mail try again later!');
      }
    } catch (error) {
      Sentry.captureException(error);
      //console.log(error);
    }
  };

  //handle submit function
  const handleSubmitEmployee = async (e) => {
    e.preventDefault();
    const errors = checkValidations(addNewEmployee, formData);
    if (Object.keys(errors).length > 0) {
      setIsFormSubmitted(true);
      setErrors(errors);
    } else {
      setErrors({});
      setIsFormSubmitted(false);
      const date = format(formData?.dateOfBirth, 'yyyy-MM-dd');
      if (addNewEmployeeFlag) {
        let data = {
          ...formData,
          employeeTypeId: formData?.employeeTypeId?.id,
          stateName: formData?.stateName?.name,
          stateId: formData?.stateName?.id ? formData?.stateName?.id : null,
          dateOfBirth: date,
          phoneNumber: String(formData?.phoneNumber_Unformatted ?? ''),
        };
        delete data?.phoneNumber_Unformatted;
        try {
          let addEmployeeResponse = await addEmployee({
            image: profileFormPreview ?? '',
            data,
          });
          if (addEmployeeResponse?.data?.success) {
            setAddNewModalVisibility(false);
            setProfileFormPreview();
            setSelectedImage();
            customToast.success(
              `Profile for ${
                data?.firstName + ' ' + data?.lastName
              } has been added successfully`
            );
          }
          if (addEmployeeResponse?.error?.status === 400) {
            customToast.error(addEmployeeResponse?.error?.data?.message);
          }
        } catch (error) {
          customToast.error(`Try again later`);
          Sentry.captureException(error);
        }
      } else {
        try {
          let data = {
            ...formData,
            employeeTypeId: formData.employeeTypeId.id,
            stateName: formData?.stateName?.name,
            stateId: formData?.stateName?.id ? formData?.stateName?.id : null,
            dateOfBirth: date,
            phoneNumber: String(formData?.phoneNumber_Unformatted ?? ''),
          };
          delete data?.phoneNumber_Unformatted;
          delete data?.profileImage;
          let updateEmployeeResponse = await updateEmployee({
            user_id: selectedRowData?.user_id,
            data,
            image: profileFormPreview ?? '',
          });
          if (updateEmployeeResponse?.data?.success) {
            setAddNewModalVisibility(false);
            setProfileFormPreview();
            setSelectedImage();
            setImage(null);
            //setImageUploaded(updateEmployeeResponse.data.data.profilePicture);
            customToast.success(
              `Profile for ${
                data?.firstName + ' ' + data?.lastName
              } has been updated successfully`
            );
          }
          if (updateEmployeeResponse?.error?.status === 400) {
            customToast.error(updateEmployeeResponse?.error?.data?.message);
          }
        } catch (error) {
          Sentry.captureException(error);
          //customToast.error('Try again later!')
          customToast.error('Try again later!');
          //console.log(error)
        }
      }
    }
  };
  // main form view to update or add employee
  const addOrUpdateView = () => (
    <>
      <div className="h-fit w-[100%] flex flex-col gap-3 p-3">
        <div className="h-fit w-[100%] flex flex-row justify-between px-3">
          <H1>{addNewEmployeeFlag ? 'Add Employee' : 'Edit Employee'}</H1>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => setAddNewModalVisibility(false)}
          />
        </div>
        <div className="h-fit flex flex-col justify-center">
          <h1 className="font-bold text-lg self-center -mt-3 mb-4">
            Profile Picture
          </h1>
          <div
            className={`h-[90px] w-[90px] self-center ${
              !selectedImage && ' bg-neutralGrey'
            } rounded-full overflow-hidden flex flex-col justify-end`}
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Profile"
                className="h-[100%] w-[100%] object-fit"
              />
            )}
            <div
              className="bg-white absolute z-99 top-25 h-[25px] w-[25px] rounded-full flex flex-col justify-between cursor-pointer self-end border border-secondary300 p-1"
              onClick={() => setUploadProfileVisiblity(true)}
            >
              <img src={addProfilePen} alt="" className="" />
            </div>
          </div>
        </div>
        <div className="h-fit w-[100%] p-3 border border-secondary200 rounded-md">
          <DynamicForm
            data={addNewEmployee}
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            isSubmitted={isFormSubmitted}
            dropdowns={dropdowns}
          />
        </div>
        <div className="h-fit w-[100%] flex flex-row gap-4 justify-end mt-2">
          {!addNewEmployeeFlag ? (
            <Button
              variant="white_sm"
              type="button"
              btnClassName="text-disabled font-normal rounded-md hover:border-primary500 hover:bg-primary50 hover:text-primary500"
              onClick={handleResetPassword}
            >
              {isResetPasswordLoading ? (
                <div className="h-[100%] -mb-1 w-[100%] min-w-[100px] flex flex-col justify-center">
                  <Loader loaderText="Sending" FullScreen={false} />
                </div>
              ) : (
                'Reset Password'
              )}
            </Button>
          ) : (
            <Button
              variant="white_sm"
              btnClassName="text-disabled font-normal rounded-md"
              onClick={() => {
                setFormData({});
                setErrors({});
                setIsFormSubmitted(false);
                setSelectedImage(null);
                setProfileFormPreview(null);
                setImageWhileCropping(null);
              }}
            >
              Reset
            </Button>
          )}
          <Button
            variant="white_sm"
            btnClassName="text-disabled font-normal rounded-md"
            onClick={() => {
              setAddNewModalVisibility(false);
            }}
          >
            Cancel
          </Button>
          {!addNewEmployeeFlag ? (
            <Button
              variant={'primary'}
              btnClassName="border-primary500 bg-chatbot_bg text-primary500 px-6 rounded-md"
              onClick={handleSubmitEmployee}
            >
              {isUpdateEmployeeLoading ? (
                <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                  <Loader loaderText="Updating" FullScreen={false} />
                </div>
              ) : (
                'Update'
              )}
            </Button>
          ) : (
            <Button
              variant={'primary'}
              btnClassName="border-primary500 bg-chatbot_bg text-primary500 px-6 rounded-md"
              type="submit"
              onClick={handleSubmitEmployee}
            >
              {isAddEmployeeLoading ? (
                <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                  <Loader loaderText="Adding" FullScreen={false} />
                </div>
              ) : (
                'Add'
              )}
            </Button>
          )}
        </div>
      </div>
    </>
  );
  // view to upload profile picture
  const uploadProfileView = () => (
    <>
      <div className="h-fit w-[100%] flex flex-col p-3 px-6">
        <div className="h-fit w-[100%] flex flex-row justify-between">
          {/* <canvas ref={canvasRef} className="hidden" /> */}
          <H1>Update Profile Image</H1>
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => setUploadProfileVisiblity(false)}
          />
        </div>
        <div className="h-fit flex flex-row justify-start">
          <p className="font-light text-[12px] text-secondary900">
            Drag and drop your image.
          </p>
        </div>
        <label
          htmlFor="openSelector"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className={`h-fit min-h-[25vh] w-[100%] p-3 border hover:border-primary500  hover:bg-primary50 ${
            imageError ? 'border-error-red' : 'border-secondary200'
          } rounded-md my-3 flex flex-col justify-center gap-8`}
        >
          {imageWhileCropping ? (
            <>
              <div className="flex flex-row justify-center">
                <ReactCrop
                  src={imageWhileCropping}
                  crop={crop}
                  aspect={1}
                  maxWidth={300}
                  maxHeight={300}
                  onChange={(c) => {
                    setCrop(c);
                    setIsImageCropped(true);
                  }}
                >
                  <div className="max-h-[50vh] flex flex-row justify-center self-center overflow-y-scroll">
                    <img
                      src={imageWhileCropping}
                      onLoad={(e) => {
                        setImage(e.target);
                      }}
                      className="self-center"
                    />
                  </div>
                </ReactCrop>
              </div>
            </>
          ) : (
            <>
              <div className="h-[50px] w-[50px] rounded-full self-center flex flex-col justify-center">
                <img
                  src={uploadIcon}
                  alt=""
                  className="h-[100%] w-[100%] self-center"
                />
              </div>
              <div className="flex flex-col ">
                <div className="self-center flex flex-row">
                  <p className="cursor-pointer font-light text-[12px] text-primary500">
                    <label className="cursor-pointer" htmlFor="openSelector">
                      Click to Upload
                    </label>
                  </p>
                  &nbsp;
                  <p className="font-light text-[12px] text-secondary900]">
                    or drag and drop JPG or PNG
                  </p>
                </div>
                <p className="self-center font-light text-[12px] text-secondary900 -mt-2">
                  Document (Max 2 MB)
                </p>
              </div>
              <input
                type="file"
                onChange={handleFileChange}
                name="openSelector"
                accept=".jpg, .png"
                id="openSelector"
                className="hidden"
              />
            </>
          )}
        </label>
        {imageError && (
          <p className="p-xs-regular text-error-red self-center">
            {imageError}
          </p>
        )}
        <div className="h-fit w-[100%] flex flex-row gap-4 justify-end mt-2 py-6">
          <Button
            variant="white_sm"
            btnClassName="text-disabled font-normal rounded-md"
            onClick={() => {
              setUploadProfileVisiblity(false);
            }}
          >
            Back
          </Button>
          <Button
            variant={'primary'}
            btnClassName="border-primary500 bg-chatbot_bg text-primary500 px-6 rounded-md"
            onClick={handleUploadProfile}
          >
            Upload
          </Button>
        </div>
      </div>
    </>
  );
  // reset password link send popup
  const passwordResetView = () => (
    <>
      <div className="h-fit w-[100%] flex flex-col justify-start p-3">
        <div className="h-fit w-[100%] flex flex-row justify-end p-3 ">
          <img
            src={closeIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => {
              setResetPasswordFlag(false);
              setAddNewModalVisibility(false);
            }}
          />
        </div>
        <div className="h-fit flex flex-col justify-center m-10">
          <div className="h-fit w-[100%] self-center flex flex-col justify-center">
            <img
              src={successIcon}
              alt=""
              className="self-center h-[50px] w-[50px]"
            />
            <h1 className="font-bold text-lg self-center">
              Password Reset Email Sent
            </h1>
            <p className="self-center text-center font-light text-[12px] text-secondary900 ">
              An email is sent to the consumer to Reset password. You can also
              copy the link and share to the user
            </p>
          </div>
        </div>
        <div className="h-fit w-[100%] flex flex-row justify-center mb-10">
          <Button
            variant={'primary'}
            btnClassName="border-primary500 bg-chatbot_bg text-primary500 px-6 rounded-md"
            onClick={() => {
              const link = redirectLink;
              navigator.clipboard
                .writeText(link)
                .then(() => {
                  setLinkCopiedFlag(true);
                  customToast.success('Link Copied');
                  setTimeout(() => {
                    setLinkCopiedFlag(false);
                  }, 3000);
                })
                .catch((err) => {
                  setLinkCopiedFlag(false);
                  customToast.error('Failed to copy link');
                });
            }}
          >
            <div className="flex flex-row justify-between gap-2">
              <img
                src={linkIcon}
                alt=""
                className="self-center h-[20px] w-[20px]"
              />
              {linkCopiedFlag ? (
                <>
                  Link Copied
                  <div className="flex flex-col justify-center">
                    <img
                      src={successIocnFilled}
                      className="h-[15px] w-[15px]"
                      alt=""
                    />
                  </div>
                </>
              ) : (
                'Copy Link'
              )}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
  return (
    /* all three views are being rendered in one single modal based on different conditions */
    <>
      <Modal
        children={
          uploadProfileVisiblity //check for profile modal visibility
            ? uploadProfileView() //Upload profile view
            : resetPasswordFlag // check for reset password success screen
              ? passwordResetView() // reset password success screen
              : addOrUpdateView() //View for add and update employee component
        }
        show={true}
        //width of all 3 views based on there view conditions
        width={`${
          uploadProfileVisiblity
            ? 'min-w-[400px] w-[40vw] max-w-[500px]'
            : resetPasswordFlag
              ? 'min-w-[300px] w-[40vw] max-w-[400px]'
              : 'min-w-[600px] w-[59vw] max-w-[1000px]'
        }`}
      />
    </>
  );
};
export default AddNewEmployee;
