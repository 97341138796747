import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
//components
import Tabs from 'components/tabs';
import Loader from 'wrapper/loader';
import Breadcrumb from 'components/breadcrumb';
import H1 from 'components/h1-typography';
//custom hooks
import usePageTitle from 'constants/PageTitle';
//Tabs or child components
import LoanComparison from 'pages/tools-admin/loanComparison';
import Amortization from 'pages/tools-admin/amortizationSchedule';
//constants
import { roles } from 'constants/constant';
//RTK query import's
import {
  useGetUserInfoQuery,
  useIsFirstTimeUserMutation,
} from 'redux_store/services/client/onboarding';
import * as Sentry from '@sentry/browser';

const Tools = () => {
  const location = useLocation();
  const data = location.state;
  const [selectedTabId, setSelectedTabId] = useState(data?.NavigateToTab ?? 1);
  const [applicationId, setApplicationId] = useState(null);
  usePageTitle(
    selectedTabId === 1
      ? 'Tools | Loan comparison'
      : 'Tools | Amortization schedule'
  );
  const breadCrumbs = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Tools',
      path: '/tools',
    },
  ];
  const [getUserData] = useIsFirstTimeUserMutation();
  const { data: profileData } = useGetUserInfoQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const fetchUserData = async () => {
    try {
      const resp = await getUserData();
      if (resp?.data?.data?.pending_application_id) {
        setApplicationId(resp?.data?.data?.pending_application_id);
      } else if (profileData?.data?.user_profiles?.[0]?.applicationId) {
        setApplicationId(profileData.data.user_profiles[0].applicationId);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchUserData();
  }, [profileData]);

  return applicationId ? (
    <div>
      <Breadcrumb breadcrumbs={breadCrumbs} />
      <H1>Tools</H1>
      <Tabs
        tabs={[
          {
            id: 1,
            name: 'Loan Comparison',
          },
          {
            id: 2,
            name: 'Amortization Schedule',
          },
        ]}
        selectedTabId={selectedTabId}
        onClickTab={(tab) => {
          setSelectedTabId(tab?.id);
        }}
      />
      {selectedTabId === 1 && (
        <LoanComparison applicationId={applicationId} role={roles.consumer} />
      )}
      {selectedTabId === 2 && (
        <Amortization applicationId={applicationId} role={roles.consumer} />
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default Tools;
