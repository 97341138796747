import React, { useRef, useState } from 'react';
/**
 * Function: DragAndDrop
 *
 * Description: A React component that provides drag and drop functionality for file uploads.
 *
 * Parameters:
 *   - onFilesChange (function): A callback function that is called when files are dropped or selected.
 *
 * Returns:
 *   - JSX Element: The rendered DragAndDrop component.
 *
 * Usage:
 *   <DragAndDrop onFilesChange={handleFilesChange} />
 *
 * Example:
 *   const handleFilesChange = (files) => {
 *     // Handle the dropped or selected files
 *   };
 *
 *   <DragAndDrop onFilesChange={handleFilesChange} />
 */
const DragAndDrop = ({ onFilesChange, containerClass = '', textElement }) => {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  if (!textElement) {
    textElement = (
      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
        <span className="text-custom-green font-semibold">Click to upload</span> or drag
        and drop PDF
      </p>
    );
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = [...e.dataTransfer.files];
    handleFiles(files);
  };

  const handleFileInputChange = (e) => {
    const files = [...e.target.files];
    handleFiles(files);
  };

  const handleFiles = (files) => {
    // Handle dropped files here
    onFilesChange && onFilesChange(files);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the selected files
    }
  };

  return (
    <div
      className={`flex items-center justify-center w-full ${
        isDragging ? 'border-blue-500' : 'border-gray-300'
      } border-2 border-dashed border-blue rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ${containerClass}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-56"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="3" width="40" height="40" rx="20" fill="#D7DFFF" />
            <rect
              x="3"
              y="3"
              width="40"
              height="40"
              rx="20"
              stroke="#E9EDFF"
              stroke-width="6"
            />
            <g clip-path="url(#clip0_3371_12594)">
              <path
                d="M26.3333 26.3334L23 23M23 23L19.6666 26.3334M23 23V30.5M29.9916 28.325C30.8044 27.8819 31.4465 27.1808 31.8165 26.3322C32.1866 25.4837 32.2635 24.5361 32.0351 23.6389C31.8068 22.7418 31.2862 21.9463 30.5555 21.3779C29.8248 20.8095 28.9257 20.5006 28 20.5H26.95C26.6977 19.5244 26.2276 18.6186 25.5749 17.8509C24.9222 17.0831 24.104 16.4732 23.1817 16.0672C22.2594 15.6612 21.2571 15.4695 20.2501 15.5066C19.243 15.5437 18.2575 15.8086 17.3676 16.2814C16.4777 16.7542 15.7066 17.4226 15.1122 18.2363C14.5177 19.0501 14.1155 19.988 13.9358 20.9795C13.756 21.9711 13.8034 22.9905 14.0743 23.9611C14.3452 24.9317 14.8327 25.8282 15.5 26.5834"
                stroke="#1340FF"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_3371_12594">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(13 13)"
                />
              </clipPath>
            </defs>
          </svg>

          {textElement}
          {/* <p className="text-xs text-gray-500 dark:text-gray-400">
            Document (Max 10 MB)
          </p> */}
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          onChange={handleFileInputChange}
          ref={fileInputRef}
        />
      </label>
    </div>
  );
};

export default DragAndDrop;
