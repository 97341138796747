import React from 'react';
import { navItems } from '.';
import mira from 'assets/mira.svg';
import fundmoreai from 'assets/fundmoreai.png'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userRoleData } from 'redux_store/slices/userRole';

/**
 * Renders a navigation bar component.
 *
 * @returns {JSX.Element} The rendered navigation bar component.
 */
const NavBar = () => {
  const role = useSelector(userRoleData);

  const filteredNavItems = navItems?.filter((item) =>
    item?.role?.includes(role?.userRole)
  );
  return (
    <div className="flex flex-col w-[88px] secondary-bg h-screen">
      <div className="flex justify-center mt-4 mb-6">
        <img className="w-[26px]" src={fundmoreai} alt="fundmoreai" style={{width: '50px', height: '40px'}}/>
      </div>

      <nav className="flex-1 overflow-y-auto scroll-hidden">
        {filteredNavItems?.map((item, index) => (
          <NavItem
            key={index}
            title={item.title}
            icon={item.icon}
            path={item.path}
            subPaths={item.subPaths}
          />
        ))}
      </nav>
    </div>
  );
};

const NavItem = ({ title, icon, path, subPaths }) => {
  return (
    <Link
      to={path}
      className={`flex flex-col items-center p-4  ${
        location.pathname.endsWith(path) ||
        subPaths?.some((path) => location.pathname.startsWith(path))
          ? 'bg-custom-primary transition duration-300 '
          : 'hover:bg-secondary900'
      }   `}
    >
      {icon && <div className="mb-2">{icon}</div>}
      <span
        className={`${
          location.pathname.endsWith(path) ||
          subPaths?.some((path) => location.pathname.startsWith(path))
            ? 'font-medium-bold text-base_white text-center'
            : 'font-medium text-base_white text-center'
        }`}
      >
        {title}
      </span>
    </Link>
  );
};

export default NavBar;
