//React and states
import { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
//components
import H1 from 'components/h1-typography';
import InputGroup from 'components/input-group';
import Button from 'components/button';
import Loader from 'wrapper/loader';
//RTK query imports
import {
  useGetHousingExpenseOfficerQuery,
  useSaveHousingExpenseOfficerMutation,
} from 'redux_store/services/borrower-profile';
import { customToast } from 'components/toast';

const HousingExpense = ({
  applicationData,
  readOnly,
  setReadOnly,
  resetId,
  setResetId,
}) => {
  const [data, setData] = useState([]);
  const [totalAmountOfProposedExpense, setTotalAmountOfProposedExpense] =
    useState(0);
  const [totalAmountOfPresentExpense, setTotalAmountOfPresentExpense] =
    useState(0);
  const [rawProposedExpense, setRawProposedExpense] = useState();

  //Retrieving userId and passing it as consumer id
  const consumerUserId =
    applicationData?.success && applicationData?.data?.personal_details?.userId;
  const {
    data: housingExpenseData,
    isLoading,
    error: housingExpenseError,
  } = useGetHousingExpenseOfficerQuery(
    { userId: consumerUserId ?? null },
    {
      refetchOnMountOrArgChange: true,
      skip: !consumerUserId,
      consumerUserId,
    }
  );
  const [saveHousingExpense, { isLoading: isSaveHousingExpenseLoading }] =
    useSaveHousingExpenseOfficerMutation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationId = +searchParams.get('applicationId');

  const createArray = (present, proposed) => {
    const result = [];

    Object.keys(present).forEach((key) => {
      const name = key;
      const totalPresentExpense = present[key];
      const proposedExpense = proposed[key];

      result.push({ name, totalPresentExpense, proposedExpense });
    });

    return result;
  };

  useLayoutEffect(() => {
    if (housingExpenseData && housingExpenseData?.success) {
      const presentExpense = housingExpenseData?.data?.present_expense;
      const proposedExpense = housingExpenseData?.data?.proposed_expense;
      setRawProposedExpense(proposedExpense);
      const combinedData = createArray(presentExpense, proposedExpense);
      //setting the combined data
      setData(combinedData);

      //adding all the expenses in its respected variable as total expenses
      let tempTotalAmountOfPresentExpense = 0;
      let tempTotalAmountOfProposedExpense = 0;
      combinedData.forEach((item) => {
        tempTotalAmountOfPresentExpense += parseInt(
          item.totalPresentExpense //.replace('$', '')
        );
        tempTotalAmountOfProposedExpense += parseInt(
          item.proposedExpense //.replace('$', '')
        );
      });
      //setting the total expenses in their useState
      setTotalAmountOfPresentExpense(tempTotalAmountOfPresentExpense || 0);
      setTotalAmountOfProposedExpense(tempTotalAmountOfProposedExpense || 0);
    } else {
      if (housingExpenseError?.status === 400) {
        customToast.error(housingExpenseError?.data.message);
      }
    }
  }, [housingExpenseData, readOnly, housingExpenseError]);
  const handleSaveHousingExpense = async () => {
    try {
      let data = {
        ...rawProposedExpense,
        userId: applicationData?.data?.personal_details?.userId,
        applicationId,
        userProfileId: applicationData?.data?.personal_details?.userProfileId,
      };
      const resp = await saveHousingExpense({
        data,
        userId: consumerUserId,
      });
      if (resp?.data?.success) {
        customToast.success(resp?.data?.message);
        setReadOnly(true);
      } else {
        customToast('Error in Saving Housing Expense');
        setReadOnly(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function breakCamelCase(str) {
    return str.replace(/^[a-z]|([A-Z])/g, (match, group1) => {
      return group1 ? ` ${group1}` : match.toUpperCase();
    });
  }

  return (
    <>
      {isLoading ? (
        <Loader FullScreen={true} />
      ) : (
        <div className="overflow-y-scroll overflow-hidden w-full p-2 max-h-borrower-profile bg-white rounded-lg">
          <div className="h-full flex flex-col gap-3">
            <H1>Total Present Housing Expense</H1>
            <hr className=" border-disabled" />
            <div className="w-[100%] bg-custom-primary400 flex flex-row justify-between p-3">
              <p className="w-[40%] p-xs-bold text-start">Expense</p>
              <p className="w-[30%] p-xs-bold text-start">
                Total Present Expense
              </p>
              <p className="w-[25%] p-xs-bold text-start">Proposed Expense</p>
            </div>
            {data?.map((item, index) => {
              return (
                <div
                  className="w-[100%] bg-white hover:bg-primary50 cursor-pointer flex flex-row justify-between p-3"
                  key={index}
                >
                  <p className="w-[35%] p-xs-med text-start">
                    {breakCamelCase(item.name)}
                  </p>
                  <div className="w-[25%] p-xs-bold text-center">
                    <InputGroup
                      size="sm_2"
                      disabled={true}
                      value={`$ ${item.totalPresentExpense ?? 0}`}
                      className="text-right"
                    />
                  </div>
                  <div className="w-[25%] p-xs-bold text-center">
                    <InputGroup
                      position="right"
                      size="sm_2"
                      disabled={readOnly}
                      placeholder={
                        item.proposedExpense ?? 'Enter Proposed Value'
                      }
                      value={
                        readOnly
                          ? `$ ${rawProposedExpense[item.name]}`
                          : rawProposedExpense[item.name] ?? null
                      }
                      className="text-right"
                      name={item.name}
                      onChange={(e) => {
                        const data = {
                          ...rawProposedExpense,
                          [item.name]: +e.target.value,
                        };
                        setRawProposedExpense(data);
                      }}
                      type={readOnly ? 'text' : 'number'}
                    />
                  </div>
                </div>
              );
            })}
            <hr className=" border-disabled" />
            {housingExpenseData?.success ? (
              <div className="w-[100%] bg-base_white flex flex-row p-3">
                <p className="w-[40%] p-xs-bold text-start">Total Amount</p>
                <p className="w-[30%] p-xs-bold text-center">
                  $ {totalAmountOfPresentExpense}
                </p>
                <p className="w-[25%] p-xs-bold text-center">
                  $ {totalAmountOfProposedExpense}
                </p>
              </div>
            ) : (
              <div className="flex flex-row justify-center">
                <h1 className="self-center">No Relevant Data Found</h1>
              </div>
            )}
            {!readOnly && (
              <div className="text-right mt-2">
                <Button
                  variant="tertiary"
                  style={{ marginRight: '10px' }}
                  type="button"
                  onClick={() => {
                    setReadOnly(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={handleSaveHousingExpense}
                >
                  {isSaveHousingExpenseLoading ? (
                    <div className="h-[100%] w-[100%] min-w-[100px] flex flex-col justify-center">
                      <Loader loaderText="Saving" FullScreen={false} />
                    </div>
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default HousingExpense;
