import React, { useState, useMemo, useEffect } from 'react';

//components
import Table from 'components/table';
import Badge from 'components/badge';
import User from 'components/user';
import InputGroup from 'components/input-group';
import Button from 'components/button';

//icons
import EditIcon from 'assets/icons/EditIcon';
import searchIcon from 'assets/icons/search.svg';
import closeIcon from 'assets/icons/closeIconSlim.svg';
import closeBlue from 'assets/icons/closeBlue.svg';
import DeleteIcon from 'assets/icons/DeleteBin.jsx';
import Filter from 'assets/icons/Filter';
//children components
import EditProfile from './EditProfile';
import FilterModal from 'wrapper/loan-officer-filter';
import ConfrimDelete from './ConfirmDelete';
//utils
import useDebounce from 'hooks/useDebounce';
import { Tooltip as ReactTooltip } from 'react-tooltip';

//RTK query
import { useGetConsumerQuery } from '../../../redux/services/admin/adminConsumer';
const AdminConsumer = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [filterPopup, setFilterPopup] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [page, setPage] = useState(1);
  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    itemsCount: 0,
    totalItemsCount: 0,
  });

  const searchFilter = useDebounce(globalFilter, 700);

  // fetch consumer data
  const {
    //data: allConsumerData,
    currentData: allConsumerData,
    isLoading: allConsumersLoading,
    isFetching: allConsumersFetching,
  } = useGetConsumerQuery(
    {
      data: {
        loanOfficerIds: [...checkedIds],
      },
      searchFilter: globalFilter ?? '',
      paging: {
        limit: 10,
        offset: page,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  //set pagination info to state
  useEffect(() => {
    if (allConsumerData) {
      setPagination({
        currentPage: allConsumerData?.data?.pagination.currentPage,
        totalPages: allConsumerData?.data?.pagination.totalPages,
        pageSize: allConsumerData?.data?.pagination.pageSize,
        itemsCount: allConsumerData?.data?.pagination.itemsCount,
        totalItemsCount: allConsumerData?.data?.pagination.totalItemsCount,
      });
    }
  }, [allConsumerData]);
  const handleSelectedRowData = (data) => {
    setSelectedRowData(data);
    //console.log("selected row data",data)
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const columns = [
    {
      header: 'Consumer ID',
      accessorKey: 'user_info.userId',
      cell: ({ row }) => {
        return (
          <div className="text-custom-green font-semibold ">
            {row?.original?.user_info?.userId}
          </div>
        );
      },
    },
    {
      header: 'Consumer Name',
      accessorKey: 'user_info.firstName',
      cell: ({ row }) => {
        return (
          <User
            size="sm"
            title={row?.original?.user_info.firstName}
            subtitle={row?.original?.user_info?.lastName}
          />
        );
      },
    },
    {
      header: 'Mortgage Broker Name',
      accessorKey: 'user_info.mortgageBrokerName',
      cell: ({ row }) => {
        return (
          <div className="h-fit flex flex-col gap-2 self-center">
            <p className="font-medium self-start text-secondary600">
              {row?.original?.user_info?.mortgageBrokerName}
            </p>
          </div>
        );
      },
    },
    {
      header: 'Address',
      accessorKey: 'user_info.propertyAddress',
      meta: {
        width: 'min-w-[200px] max-w-[200px]',
        style:
          'h-[2rem] text-ellipsis overflow-hidden  font-medium self-start text-secondary600',
      },
      cell: ({ row }) => {
        return (
          <>
            <div data-tooltip-id={`my-tooltip-${row.index}`}>
              {row?.original?.user_info?.address ?? 'Not available'}
            </div>
            <ReactTooltip
              id={`my-tooltip-${row.index}`}
              place="bottom"
              style={{ maxWidth: '15rem', wordBreak: 'break-all' }}
              content={row?.original?.user_info?.address ?? 'Not  Available'}
            />
          </>
        );
      },
    },
    {
      header: 'Loan Officer',
      accessorKey: 'loan_officer_info.firstName',
      cell: ({ row }) => {
        return (
          <User
            size="sm"
            title={row?.original?.loan_officer_info?.firstName}
            subtitle={row?.original?.loan_officer_info?.lastName}
          />
        );
      },
    },
    {
      header: 'Status',
      accessorKey: 'statusId',
      cell: ({ row }) => {
        const isActive = Number(row?.original?.user_info?.isActive);
        const statusText = isActive ? 'Active' : 'Inactive';
        return (
          <div className="w-[8rem]">
            <Badge
              title={statusText}
              variant={isActive ? 'success' : 'rejected'}
            />
          </div>
        );
      },
    },
    {
      header: 'Action',
      accessorKey: 'actions',
      meta: {
        className: 'sticky right-0',
        style: 'bg-w group-hover:bg-primary50',
        width: 'min-w-[90px]',
      },
      cell: ({ row }) => {
        const [isDeleteHovered, setIsDeleteHovered] = useState(false);
        const [isEditHovered, setIsEditHovered] = useState(false);

        return (
          <div
            className={`flex justify-evenly ${!Number(row?.original?.user_info?.isActive) && 'opacity-50'}`}
          >
            <div
              className="group cursor-pointer"
              onMouseEnter={() => setIsEditHovered(true)}
              onMouseLeave={() => setIsEditHovered(false)}
              onClick={(event) => {
                if (Number(row?.original?.user_info?.isActive)) {
                  handleSelectedRowData(row?.original);
                  setEditProfile(true);
                }
              }}
            >
              <EditIcon
                color={
                  Number(row?.original?.user_info?.isActive) && isEditHovered
                    ? '#1340FF'
                    : '#5A5555'
                }
              />
            </div>
            <div
              className="group cursor-pointer"
              onMouseEnter={() => setIsDeleteHovered(true)}
              onMouseLeave={() => setIsDeleteHovered(false)}
              onClick={(event) => {
                if (Number(row?.original?.user_info?.isActive)) {
                  handleSelectedRowData(row?.original);
                  setConfirmDeleteVisibility(true);
                }
              }}
            >
              <DeleteIcon
                color={
                  Number(row?.original?.user_info?.isActive) && isDeleteHovered
                    ? 'red'
                    : '#5A5555'
                }
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-[100%] h-[100vh] self-center">
      <div className="flex flex-col justify-between h-auto gap-6 overflow-auto  max-h-custom-pipeline ">
        <div>
          <div className="flex justify-between my-4 mx-2">
            <div className="w-[16rem] relative">
              <InputGroup
                size="sm_2"
                position="center"
                placeholder="Search"
                svg={searchIcon}
                value={globalFilter}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                  setPage(1);
                }}
              />
              {globalFilter && (
                <img
                  src={closeIcon}
                  alt=""
                  className="absolute top-3 right-4 cursor-pointer"
                  onClick={() => {
                    setGlobalFilter('');
                    setPage(1);
                  }}
                />
              )}
            </div>
            <div className="flex gap-4 relative">
              {checkedIds?.length === 0 && (
                <Button
                  variant="white_sm"
                  btnClassName={`${filterPopup ? 'border-primary500 bg-chatbot_bg text-primary500' : ''}`}
                  onClick={() => {
                    setFilterPopup(!filterPopup);
                  }}
                >
                  <Filter color={`${filterPopup ? 'primary' : 'secondary'}`} />{' '}
                  Filter
                </Button>
              )}
              {checkedIds?.length > 0 && (
                <Button
                  variant="white_sm"
                  btnClassName="border-primary500 bg-chatbot_bg text-primary500"
                  onClick={() => {
                    setFilterPopup(!filterPopup);
                  }}
                >
                  <Filter color="primary" /> Filter Applied
                  <img
                    src={closeBlue}
                    alt=""
                    className="pl-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCheckedIds([]);
                    }}
                  />
                </Button>
              )}
              {filterPopup && (
                <div className="absolute right-[0rem] top-[3rem] z-10">
                  <FilterModal
                    filterPopup={filterPopup}
                    setFilterPopup={setFilterPopup}
                    checkedLoanOfficerIds={checkedIds}
                    onApplyCheckedItems={(loanOfficerIds) => {
                      setCheckedIds([...loanOfficerIds]);
                      setPage(1);
                      setFilterPopup(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="max-h-[65vh] h-[100vh] w-[100%] overflow-y-scroll">
            <Table
              isLoading={allConsumersLoading || allConsumersFetching}
              data={
                allConsumerData?.status === 200
                  ? allConsumerData?.data?.consumer_information
                  : []
              }
              columns={columns}
              errorMessage="No relevant data found"
              height="min-h-[30vh]"
              showPagination={allConsumerData?.status === 200}
              pagination={pagination}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {confirmDeleteVisibility && (
          <ConfrimDelete
            setConfirmDeleteVisibility={setConfirmDeleteVisibility}
            selectedRowData={selectedRowData}
          />
        )}
        {editProfile && (
          <EditProfile
            selectedRowData={selectedRowData}
            editProfile={editProfile}
            setEditProfile={setEditProfile}
          />
        )}
      </div>
    </div>
  );
};

export default AdminConsumer;
