/**
 * Transforms an array of documents and updates their properties based on their sub_types.
 *
 * @param {Array} documents - The array of documents to be transformed.
 * @returns {Array} - The transformed array of documents.
 */
export function documentMapper(documents) {
  // Short-circuit condition if called with no parameters or empty arrays
  if (!Array.isArray(documents) || documents.length === 0) {
    return [];
  }

  // Initialize the final result array
  let result = documents.map((docType) => {
    // Initialize counters and flags
    let allPending = true;
    let allUploaded = true;
    let allPendingVerification = true;
    let allVerificationFailed = true;
    let anyPendingVerification = false;
    let anyVerificationFailed = false;
    let anyUploaded = false;
    let pendingCount = 0;
    let completedCount = 0;
    let maxTimestamp = 0;

    const subTypes = docType.sub_types.map((subType) => {
      let updatedSubType = { ...subType };
      let latestTimestamp = 0; // Variable to track the latest timestamp in the sub_type
      let latestStatus = 1; // Default status to pending
      let subTypeHasPending = false; // Flag to check if sub_type has any pending documents
      let subTypePendingVerification = false;
      let subTypeVerificationFailed = false;

      // Check if there are any documents within the sub_type
      if (Array.isArray(subType.documents) && subType.documents.length > 0) {
        subType.documents.forEach((doc) => {
          // Update counters and flags based on status
          switch (doc?.status) {
            case 1:
              subTypeHasPending = true; // Mark sub_type as having pending documents
              allUploaded = false;
              allPendingVerification = false;
              allVerificationFailed = false;
              break;
            case 2:
              completedCount++;
              anyUploaded = true;
              allPending = false;
              allPendingVerification = false;
              allVerificationFailed = false;
              break;
            case 3:
              allPending = false;
              allUploaded = false;
              allVerificationFailed = false;
              anyPendingVerification = true;
              subTypePendingVerification = true;
              break;
            case 4:
              subTypeHasPending = true; // Mark sub_type as having pending documents
              allUploaded = false;
              allPendingVerification = false;
              anyVerificationFailed = true;
              break;
            default:
              break;
          }

          // Update the latest timestamp and status for the current sub_type
          if (doc.timestamp > latestTimestamp) {
            latestTimestamp = doc.timestamp;
            // Update status to the latest document's status
          }
          if (subTypePendingVerification) {
            latestStatus = 3;
          } else {
            latestStatus = doc.status;
          }
        });

        // If the sub_type has any pending documents, increment the pendingCount
        if (subTypeHasPending) {
          pendingCount++;
        }

        updatedSubType = {
          ...subType,
          documentStatusId: latestStatus, // Use the latest document's status
          timestamp: latestTimestamp, // Set the latest timestamp for the sub_type
        };
      } else {
        updatedSubType.documentStatusId = 1; // Default to pending if no document
        updatedSubType.timestamp = latestTimestamp; // Set the latest timestamp for the sub_type
        pendingCount++;
        allUploaded = false;
        allPendingVerification = false;
        allVerificationFailed = false;
      }

      // Update the maximum timestamp for the document type
      if (latestTimestamp > maxTimestamp) {
        maxTimestamp = latestTimestamp;
      }

      return updatedSubType;
    });

    // Determine the document type status ID based on sub-types statuses
    let documentTypeStatusId;
    if (allPending) {
      documentTypeStatusId = 5; // All pending
    } else if (allUploaded) {
      documentTypeStatusId = 2; // All uploaded
    } else if (allVerificationFailed) {
      documentTypeStatusId = 5; // All verification failed
    } else if (pendingCount === 0) {
      documentTypeStatusId = 2; // no pending document
    } else if (anyPendingVerification || anyVerificationFailed) {
      documentTypeStatusId = 3;
    } else {
      documentTypeStatusId = 5; // Default to pending if mixed
    }

    // Return the transformed document type
    return {
      ...docType,
      documentStatusId: documentTypeStatusId,
      pending: pendingCount,
      completed: completedCount,
      timestamp: maxTimestamp,
      sub_types: subTypes,
    };
  });

  // Filter out document types without any sub_types
  result = result.filter((docType) => docType.sub_types.length > 0);

  return result;
}