import React, { useState } from 'react';
import NavBar from 'components/nav-bar/NavBar';
import TopBar from 'components/top-bar/TopBar';
import { Outlet } from 'react-router-dom';
import Onboarding from '../onboarding/Onboarding';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { scaleMotionProps } from 'constants/animationProps';
import CardLoader from 'wrapper/card-loader';
import ChatModel from '../chat-model';
import { CheckAuth } from 'auth/auth';

//this is the second main layout component that wraps the entire web app

export const AppShell = ({
  logout,
  isOnboarding,
  user,
  setIsOnboarding,
  data,
}) => {
  // Add event listener for beforeunload event
  // window.addEventListener('beforeunload', async (event) => {
  //   await logout({ logoutParams: { returnTo: window.location.origin } });

  // });
  return !isOnboarding && data && CheckAuth() ? (
    <AnimatePresence>
      <motion.div {...scaleMotionProps}>
        <div className="flex w-[100vw]">
          <NavBar />
          <div className="flex flex-col basis-full w-full overflow-x-hidden">
            <TopBar logout={logout} user={user} />
            <div className="p-4 flex">
              <div className="w-[72%]">
                <Outlet />
              </div>
              <div className="w-fit absolute bottom-2 right-2">
                <ChatModel />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  ) : data && CheckAuth() ? (
    <Onboarding setIsOnboarding={setIsOnboarding} />
  ) : (
    <CardLoader />
  );
};
