import React from 'react';
/**
 * Renders a horizontal timeline component.
 *
 * @param {Object} data - The data for the timeline.
 * @param {Function} onClickTimeDot - The function to be called when a time dot is clicked.
 * @param {Boolean} showFullText - to render the text as ellipsis or full
 * @returns {JSX.Element} The rendered timeline component.
 */
const HorizontalTimeline = ({
  data,
  onClickTimeDot,
  showFullText = false,
  align = 'bottom',
  size = 'sm',
}) => {
  // const exampleData = [
  //   {
  //     id: 1,
  //     name: '',
  //     state: 'progress',
  //   },
  //   {
  //     id: 2,
  //     name: '',
  //     state: 'done',
  //   },
  //   {
  //     id: 3,
  //     name: '',
  //     state: 'notDone',
  //   },
  // ];

  const variantData = {
    sm: {
      lineWidth: 'w-[4vw]',
      textWidth: 'w-[7vw]',
    },
    m: {
      lineWidth: 'w-[6vw]',
      textWidth: 'w-[9vw]',
    },
  };

  const { lineWidth, textWidth } = variantData[size];

  return (
    <ol className="items-start sm:flex ">
      {data?.map((x, index) => {
        if (x?.state === 'progress') {
          return (
            <li className="relative mb-6 sm:mb-0" key={x?.id ?? index}>
              <div className="flex items-center">
                <button
                  onClick={() => onClickTimeDot(index + 1)}
                  className="z-10 flex items-center cursor-pointer justify-center w-2 sm:w-4 md:w-2 lg:w-6 h-6 bg-white rounded-full ring-0 ring-white  sm:ring-8 dark:ring-gray-900 shrink-0"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 5C6.792 5 5 6.792 5 9C5 11.208 6.792 13 9 13C11.208 13 13 11.208 13 9C13 6.792 11.208 5 9 5ZM9 1C4.584 1 1 4.584 1 9C1 13.416 4.584 17 9 17C13.416 17 17 13.416 17 9C17 4.584 13.416 1 9 1ZM9 15.4C5.464 15.4 2.6 12.536 2.6 9C2.6 5.464 5.464 2.6 9 2.6C12.536 2.6 15.4 5.464 15.4 9C15.4 12.536 12.536 15.4 9 15.4Z"
                      fill="#646669"
                    />
                  </svg>
                </button>
                {index !== data.length - 1 && (
                  <div
                    className={`hidden sm:flex ${lineWidth} bg-custom-primary400 h-0.5 dark:bg-gray-700 min-w-10`}
                  ></div>
                )}
              </div>
              <div
                className={`mt-1 z-10 ${textWidth} text-xs sm:pe-8 min-w-10 break-words  text-primary500 ${!showFullText ? 'sm:truncate' : 'break-words'} absolute ${align === 'top' ? 'bottom-full' : 'top-full'}`}
              >
                {x.name}
              </div>
            </li>
          );
        }
        if (x?.state === 'done') {
          return (
            <li className="relative mb-6 sm:mb-0" key={x?.id ?? index}>
              <div className="flex items-center">
                <button
                  onClick={() => onClickTimeDot(index + 1)}
                  className="z-10  cursor-pointer  flex items-center justify-center w-2 sm:w-4 md:w-2 lg:w-6 h-6 bg-white rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2488_3888)">
                      <path
                        d="M9 17C11.1217 17 13.1566 16.1571 14.6569 14.6569C16.1571 13.1566 17 11.1217 17 9C17 6.87827 16.1571 4.84344 14.6569 3.34315C13.1566 1.84285 11.1217 1 9 1C6.87827 1 4.84344 1.84285 3.34315 3.34315C1.84285 4.84344 1 6.87827 1 9C1 11.1217 1.84285 13.1566 3.34315 14.6569C4.84344 16.1571 6.87827 17 9 17ZM12.5312 7.53125L8.53125 11.5312C8.2375 11.825 7.7625 11.825 7.47188 11.5312L5.47188 9.53125C5.17813 9.2375 5.17813 8.7625 5.47188 8.47188C5.76562 8.18125 6.24062 8.17813 6.53125 8.47188L8 9.94063L11.4688 6.46875C11.7625 6.175 12.2375 6.175 12.5281 6.46875C12.8187 6.7625 12.8219 7.2375 12.5281 7.52812L12.5312 7.53125Z"
                        fill="#3DC13C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2488_3888">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                {index !== data.length - 1 && (
                  <div
                    className={`hidden sm:flex ${lineWidth} bg-green h-0.5 dark:bg-gray-700 min-w-10`}
                  ></div>
                )}
              </div>
              <div
                className={`mt-1 text-xs z-10 ${textWidth} sm:pe-8 break-words ${!showFullText ? 'sm:truncate' : 'break-words'} absolute ${align === 'top' ? 'bottom-full' : 'top-full'}`}
              >
                {x.name}
              </div>
            </li>
          );
        }

        if (x?.state === 'notDone') {
          return (
            <li className="relative mb-6 sm:mb-0" key={x?.id ?? index}>
              <div className="flex items-center">
                <button
                  onClick={() => onClickTimeDot(index + 1)}
                  className="z-10 flex items-center cursor-pointer justify-center w-2 sm:w-4 md:w-2 lg:w-6 h-6 bg-white rounded-full ring-0 ring-white  sm:ring-8 dark:ring-gray-900 shrink-0"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="9" cy="9" r="9" fill="#E3E3E3" />
                  </svg>
                </button>
                {index !== data.length - 1 && (
                  <div
                    className={`hidden sm:flex ${lineWidth} bg-grey h-0.5 dark:bg-gray-700 min-w-10`}
                  ></div>
                )}
              </div>
              <div
                className={`mt-1 z-10 text-xs ${textWidth} sm:pe-8 break-words ${!showFullText ? 'sm:truncate' : 'break-words'} absolute ${align === 'top' ? 'bottom-full' : 'top-full'}`}
              >
                {x.name}
              </div>
            </li>
          );
        }
      })}
    </ol>
  );
};

export default HorizontalTimeline;
