import { api } from 'constants/constant';
import { getHeaders } from 'auth/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//admin API'S
export const quickPriceAPI = createApi({
  reducerPath: 'quickPrice',
  //tagTypes: ['fetchAdminEmployees', 'adminEmployeeUpdate'],
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  endpoints: (builder) => ({
    //API to get Quick price
    getQuickPrice: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/price/get_quick_price`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
    }),
    //API to compare Products
    compareProducts: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/product/compare_products_quick_price`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
    }),
    //API to compare offer when uploaded a offer or entered manually
    compareOfferByUserInput: builder.mutation({
      query: ({ data }) => {
        return {
          url: `loan/price/compare_offer_by_user_input`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
    }),
    //The download api is in documentDownload.js file
    //API to Send Quote on client email
    sendQuote: builder.mutation({
      query: ({ name, emailId, data }) => {
        return {
          url: `loan/price/send_quote?client_name=${name}&client_email=${emailId}`,
          method: 'POST',
          headers: getHeaders(),
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetQuickPriceMutation,
  useCompareProductsMutation,
  useCompareOfferByUserInputMutation,
  useSendQuoteMutation,
} = quickPriceAPI;
