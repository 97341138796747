/* istanbul ignore file */
import {
  withValidations,
  checkPhoneNumberValidation,
  checkEmailValidation,
  checkTextInput
} from 'pages/profile/utils';

// Dynamic form fields with their configurations.
const leadsDataInfo = [
  {
    id: 1,
    name: 'First Name',
    type: 'text',
    placeholder: 'Enter First Name',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'firstName',
    validator:  checkTextInput
  },
  {
    id: 2,
    name: 'Last Name',
    type: 'text',
    placeholder: 'Enter Last Name',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'lastName',
    validator:  checkTextInput
  },
  {
    id: 3,
    name: 'Email',
    type: 'text',
    placeholder: 'Enter Email',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'email',
    checkValidation: [checkEmailValidation],
  },
  {
    id: 4,
    name: 'Mobile Number',
    type: 'phone',
    placeholder: 'Enter Mobile Number',
    customClass: 'w-1/2',
    isRequired: true,
    propertyName: 'phoneNumber',
    checkValidation: [checkPhoneNumberValidation],
    pattern: '(###) ###-####',
  },
  {
    id: 5,
    name: 'Purpose of Loan',
    type: 'select',
    placeholder: 'Select Purpose of Loan',
    customClass: 'w-full',
    isRequired: true,
    propertyName: 'loanPurpose',
    options: [
      { id: 1, name: 'Purchase' },
      { id: 2, name: 'Refinance' },
    ],
  },
  {
    id: 6,
    name: 'Employment Type',
    type: 'select',
    placeholder: 'Select Employment Type',
    customClass: 'w-full',
    isRequired: true,
    propertyName: 'employmentType',
    options: [
      {
        id: 1,
        name: 'Current',
      },
      {
        id: 2,
        name: 'Employed',
      },
      {
        id: 3,
        name: 'Self Employed',
      },
    ],
  },
];

export const leadsFormData = withValidations(leadsDataInfo);
