import React from 'react';

/**
 * Renders a circular progress bar component.
 *
 * @param {Object} props - The component props.
 * @param {number} [props.strokeWidth=8] - The width of the progress bar stroke.
 * @param {number} [props.sqSize=50] - The size of the square container for the progress bar.
 * @param {number} props.percentage - The percentage value for the progress bar.
 * @param {boolean} [props.showPercentage=false] - Whether to show the percentage in the center of the circle.
 * @param {string} [props.percentageColor='black'] - The color of the progress bar stroke representing the percentage.
 * @param {string} [props.percentageFontWeight='normal'] - The font weight of the percentage text ('normal' or 'bold').
 * @param {string} [props.circleColor='stroke-grey'] - The color of the progress bar background circle.
 * @returns {JSX.Element} - The rendered circular progress bar component.
 */
const CircularProgressBar = ({
  strokeWidth = 8,
  sqSize = 50,
  percentage,
  showPercentage = false,
  textColor = 'black',
  percentageColor = 'stroke-green',
  percentageFontWeight = 'normal',
  circleColor = 'stroke-grey',
}) => {
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * (percentage || 0)) / 100;

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        className={`fill-none ${circleColor}`}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className={`fill-none ${percentageColor} transition-all ease-in delay-200`}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeLinecap="round"
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      {showPercentage && (
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="12px"
          fill={textColor}
          fontWeight={percentageFontWeight}
        >
          {percentage}%
        </text>
      )}
    </svg>
  );
};

export default CircularProgressBar;
