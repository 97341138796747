import { RxCross2 } from 'react-icons/rx';
import Button from 'components/button';
import H1 from 'components/h1-typography';
import CurrentOffer from './CurrentOffer';
const ComparedLoanInfo = ({
  setCompareVisibility,
  currentOfferData,
  onDownloadQuote,
  isLoading,
}) => {
  return (
    <>
      {' '}
      <div className=" h-fit self-center rounded-md flex flex-col justify-center p-2 px-4">
        <div className="h-fit w-[100%] flex flex-row justify-between border-b border-b-[#E5E5E5]">
          <div className="h-[100%] flex flex-col justify-end">
            <H1>Compared Loan Information</H1>
          </div>
          <div className="h-[100%] flex flex-col justify-end">
            <H1>
              <RxCross2
                size={25}
                className="font-semibold self-center cursor-pointer"
                onClick={() => setCompareVisibility(false)}
              />
            </H1>
          </div>
        </div>

        <div className="h-[80%] w-[100%] flex flex-col gap-1 ">
          <CurrentOffer currentOfferData={currentOfferData ?? []} />
        </div>

        <div className="h-fit w-[100%] flex flex-row justify-end p-2">
          <div className="h-[100%] flex flex-row justify-end gap-3">
            <Button
              variant="primary"
              //onClick={handleApply}
              type="button"
              onClick={onDownloadQuote}
            >
              {isLoading ? 'Downloading...' : 'Download'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparedLoanInfo;
